import {PermissionContext, PermissionService} from '../permissions/service/permission.service';
import {Observable, of} from 'rxjs';
import {AxPermissionRequest} from '../permissions/AxPermissionRequest';
import {CurrentUserService} from './current-user.service';
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {OperationExt} from '../model/utm/OperationExt';

const RolePermissions = {
  pilot: new Set([
    'view_operation',
    'modify_operation',
    'submit_operation',
    'activate_operation',
    'view_constraint',
    'view_platform',
    'submit_platform'
  ]),
  mcom: new Set([
    'view_operation',
    'modify_operation',
    'submit_operation',
    'activate_operation',
    'view_constraint',
    'submit_constraint',
    'view_platform',
    'view_user',
    'view_approvals'
  ]),
  atc: new Set([
    'view_approvals',
    'submit_approvals',
    'view_constraint',
    'submit_constraint',
    'view_operation',
    'view_platform',
    'view_user',
  ]),
  admin: new Set([
    'view_operation',
    'view_constraint',
    'view_platform',
    // 'submit_platform',
    'view_user',
    'submit_user',
    'delete_user',
    'submit_setting'
  ])
};

@Injectable({
  providedIn: 'root'
})
export class MockPermissionService extends PermissionService {
  evaluateRequest(req: AxPermissionRequest<any>, overrides: PermissionContext = {}): Observable<boolean> {
    if (this.canEvaluate(overrides)) {
      return of(this.evaluateWithContext(req, overrides));
    }
    return this.currentUserService.currentUser.pipe(map(curUser => {
      const user = overrides?.user || curUser;
      return this.evaluateWithContext(req, {
        user,
        roles: overrides?.roles || [user?.assertedRole]
      });

    }));
  }

  constructor(private currentUserService: CurrentUserService) {
    super();
  }

  private evaluateWithContext(req: AxPermissionRequest<any>, ctx: PermissionContext) {
    const user = ctx.user;
    const roles = ctx.roles;
    if (!user || !roles){
      return false;
    }

    // Additional checks for actions requiring that the user is the submitting user for the resource
    if (req.getResourceType() === 'operation' && (req.getAction() === 'modify' || req.getAction() === 'activate')) {
      const operation = req.getResource() as OperationExt | undefined;
      if (user.uid !== operation?.additional_data?.user_id) {
        return false;
      }
    }

    if (!roles) {
      return false;
    }
    return roles.some(role => {
      switch (role) {
        case 'pilot':
        case 'mcom':
        case 'atc':
        case 'admin':
          return RolePermissions[role].has(req.toString());
        case 'dev':
          return true;
        default:
          return false;
      }
    });

  }

  private canEvaluate(overrides: PermissionContext): boolean {
    return !!overrides && (!!overrides.user || (overrides.roles || []).length > 0);
  }
}

import {
  CotTelemetryIntegration,
  ICotTelemetryIntegration,
  TransportCotTelemetryIntegration
} from './CotTelemetryIntegration';
import {
  IRigitechTelemetryIntegration,
  RigitechTelemetryIntegration,
  TransportRigitechTelemetryIntegration
} from './RigitechTelemetryIntegration';
import {
  IMavlinkTelemetryIntegration,
  MavlinkTelemetryIntegration,
  TransportMavlinkTelemetryIntegration
} from './MavlinkTelemetryIntegration';


export interface TransportTelemetryIntegrations {
  cot: TransportCotTelemetryIntegration[];
  rigitech: TransportRigitechTelemetryIntegration[];
  mavlink: TransportMavlinkTelemetryIntegration[];
}

export interface ITelemetryIntegrations {
  cot: ICotTelemetryIntegration[];
  rigitech: IRigitechTelemetryIntegration[];
  mavlink: IMavlinkTelemetryIntegration[];
}

export class TelemetryIntegrations implements ITelemetryIntegrations {
  cot: CotTelemetryIntegration[];
  rigitech: RigitechTelemetryIntegration[];
  mavlink: MavlinkTelemetryIntegration[];

  constructor(values: Partial<TelemetryIntegrations>) {
    this.cot = (values.cot || []).map(cot => new CotTelemetryIntegration(cot));
    this.rigitech = (values.rigitech || []).map(rigitech => new RigitechTelemetryIntegration(rigitech));
    this.mavlink = (values.mavlink || []).map(mavlink => new MavlinkTelemetryIntegration(mavlink));
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {BaseEnvironment, EnvironmentInterface} from './environmentInterface';

export const environment: EnvironmentInterface = {
  // operationProvider: MockOperationService,
  // offNominalReportProvider: MockOffNominalReportService,
  //// authProvider: MockAuthService,
  // authProvider: RestAuthService,
  production: false,
  // parserProvider: MockParserService,
  // ussProvider: MockUssService,
  // uvrProvider: MockUvrService,
  // envProvider: MockEnvironmentService,
  baseUrl: '',
  ...BaseEnvironment
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

<button type="button" class="btn btn-outline btn-sm" (click)="addElement()">
  <cds-icon shape="plus"></cds-icon> Add {{inputName}}</button>

<form clrForm clrLayout="vertical">
  <table class="table table-compact" style="margin-top: 0;" aria-label="Edit items">
    <thead>
      <tr>
        <th style="width: 75%;">{{inputName}}</th>
        <th style="width: 25%;"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let control of formArray.controls; let i = index">
        <td>
          <ng-container [ngSwitch]="inputType">
            <ng-container *ngSwitchCase="inputTypeEnum.PHONE">
              <clr-input-container>
                <input clrInput [formControl]="control" type="{{inputTypeAttribute}}">
                <clr-control-error *clrIfError="'pattern'">Invalid phone format</clr-control-error>
              </clr-input-container>
            </ng-container>
            <ng-container *ngSwitchCase="inputTypeEnum.EMAIL">
              <clr-input-container>
                <input clrInput [formControl]="control" type="{{inputTypeAttribute}}">
                <clr-control-helper>{{formArray.controls[i]?.value?.length || 0}}/50</clr-control-helper>
                <clr-control-error *clrIfError="'email'">Invalid email format</clr-control-error>
                <clr-control-error *clrIfError="'minlength'">Minimum length is {{formArray.controls[i].errors.minlength.requiredLength}}
                  characters ({{formArray.controls[i].value.length || 0}}/{{formArray.controls[i].errors.minlength.requiredLength}})
                </clr-control-error>
                <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{formArray.controls[i].value.length || 0}}
                  /{{formArray.controls[i].errors.maxlength.requiredLength}})</clr-control-error>
              </clr-input-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <clr-input-container>
                <input clrInput [formControl]="control" type="{{inputTypeAttribute}}">
              </clr-input-container>
            </ng-container>
          </ng-container>
        </td>
        <td>
          <button type="button" class="btn btn-outline btn-sm" (click)="removeElement(i)">
            <cds-icon shape="times"></cds-icon>
          </button>
        </td>
      </tr>
      <tr *ngIf="!formArray.controls?.length">
        <td></td>
        <td></td>
      </tr>
    </tbody>
  </table>
</form>

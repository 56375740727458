<div class="clr-row" *ngIf="constraint">
  <div class="clr-col-md-6">
    <h3>General Information</h3>
    <table class="table table-vertical-custom" aria-label="General constraint information">
      <tbody>
        <tr>
          <th scope="row">State</th>
          <td>
            <app-constraint-state-badge [constraint]="constraint"></app-constraint-state-badge>
          </td>
        </tr>
        <tr>
          <th scope="row">Constraint ID</th>
          <td>{{constraint.message_id}}</td>
        </tr>
        <tr>
          <th scope="row">USS Name</th>
          <td>{{constraint.uss_name}}</td>
        </tr>
        <tr>
          <th scope="row">Permitted Operation Type</th>
          <td>{{getPermittedOperations(constraint.additional_data) | async}}</td>
        </tr>
        <tr>
          <th scope="row">Effective Time Begin</th>
          <td>{{constraint.effective_time_begin.toLocal().toISO()}}</td>
        </tr>
        <tr>
          <th scope="row">Effective Time End</th>
          <td>{{constraint.effective_time_end.toLocal().toISO()}}</td>
        </tr>
        <tr>
          <th scope="row">Actual Time End</th>
          <td>{{constraint.actual_time_end ? constraint.actual_time_end.toLocal().toISO() : ''}}</td>
        </tr>
        <tr>
          <th scope="row">Constraint Description</th>
          <td class="constraint_description">{{constraint.reason}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="clr-col-md-6">
    <ax-cesium-container [timeline]="false"
                         [projectionPicker]="true"
                         [sceneModePicker]="false">
      <app-constraint-volume-drawer [volumes]="constraint.volumes"></app-constraint-volume-drawer>
    </ax-cesium-container>
  </div>
</div>


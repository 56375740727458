import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HasPermissionDirective} from './has-permission.directive';
import {HasntPermissionDirective} from './hasnt-permission.directive';


@NgModule({
  declarations: [HasPermissionDirective, HasntPermissionDirective],
  exports: [
    HasPermissionDirective,
    HasntPermissionDirective
  ],
  imports: [
    CommonModule
  ],
  bootstrap: []
})
export class PermissionsModule {
}

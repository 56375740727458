import {Injectable} from '@angular/core';
import {
  AltitudeConversionResponse,
  AltitudeService,
  IAltitudeConversionParameters,
  TransportAltitudeConversionResponse
} from '../altitude.service';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {vertical_reference} from '../../model/gen/utm';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class RestAltitudeService extends AltitudeService {
  baseAltitudeConversionUrl = `${environment.baseUrl}/operator/altitude/convert`;
  private memoAltCache: ((altitudeConversionParams: IAltitudeConversionParameters) => Observable<AltitudeConversionResponse>);

  constructor(private http: HttpClient) {
    super();
    this.memoAltCache = _.memoize((altitudeConversionParams: IAltitudeConversionParameters) => {
      return this._convertAltitude(altitudeConversionParams);
    }, JSON.stringify);
  }

  _convertAltitude(altitudeConversionParams: IAltitudeConversionParameters): Observable<AltitudeConversionResponse> {
    const params = {
      lat: altitudeConversionParams.lat,
      lon: altitudeConversionParams.lon,
      altitude: altitudeConversionParams.altitude,
      input_reference: altitudeConversionParams.input_reference,
      output_reference: altitudeConversionParams.output_reference
    };
    return this.http.get(this.baseAltitudeConversionUrl, {params}).pipe(map((res: any) => {
      return this.parseAltitudeConversionResponse(res);
    }), shareReplay(1));
  }
  convertAltitude(altitudeConversionParams: IAltitudeConversionParameters): Observable<AltitudeConversionResponse> {
    return this.memoAltCache(altitudeConversionParams);
  }


  private parseAltitudeConversionResponse(response: TransportAltitudeConversionResponse): AltitudeConversionResponse {
    return {
      altitude: response.altitude,
      reference: vertical_reference[response.reference]
    };
  }
}

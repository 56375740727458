export class OrganizationUtil {
  static getPrettyOrganizationName(organization: string): string {
    switch (organization) {
      case 'af':
        return 'AF';
      case 'army':
        return 'Army';
      case 'axe':
        return 'AXE';
      case 'dhs':
        return 'DHS';
      case 'dod':
        return 'DoD';
      case 'fbi':
        return 'FBI';
      case 'doi':
        return 'DoI';
      case 'xyz':
        return 'XYZ';
      default:
        return organization || '';
    }
  }

  static getPrettyRoleName(role: string): string {
    switch (role) {
      case 'admin':
        return 'Administrator';
      case 'mcom':
        return 'Mission Commander';
      case 'atc':
        return 'Air Traffic Controller'
      case 'pilot':
        return 'Pilot';
      case 'dev':
        return 'Developer';
      default:
        return role || '';
    }
  }
}

import {Component} from '@angular/core';

@Component({
  selector: 'app-error-screen',
  templateUrl: './error-screen.component.html'
})
export class ErrorScreenComponent {

  constructor() {
    // Constructor intentionally left empty
  }

}

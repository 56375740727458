import {TelemetryIntegrations, TransportTelemetryIntegrations} from './TelemetryIntegrations';
import {DateTime} from 'luxon';


export interface TransportTelemetryIntegrationsEntry {
  registrationId: string;
  updated: string;
  integrations: TransportTelemetryIntegrations;
}

/** Integrations for a USS registration
 * @param registrationId USS registration ID
 * @param updated time of last update
 * @param integrations configured integrations
 */
export class TelemetryIntegrationsEntry {
  registrationId: string;
  updated: DateTime;
  integrations: TelemetryIntegrations;

  constructor(values: TelemetryIntegrationsEntry) {
    this.registrationId = values.registrationId;
    this.updated = values.updated;
    this.integrations = new TelemetryIntegrations(values.integrations);
  }
}

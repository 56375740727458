import {Contact, IContact} from './Contact';
import {AircraftSpecs, IAircraftSpecs, TransportAircraftSpecs} from './AircraftSpecs';
import {AdditionalRegistration, IAdditionalRegistration} from './AdditionalRegistration';
import {BaseModel} from './gen/utm/base-model';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OrganizationUtil} from '../utils/OrganizationUtil';
import {IUasStatistics, UasStatistics} from './UasStatistics';
import {
  ITelemetryIntegrations,
  TelemetryIntegrations,
  TransportTelemetryIntegrations
} from './TelemetryIntegrations/TelemetryIntegrations';

export interface IUasRegistrationInfo {
  uvin: string;
  archived: boolean;
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  registration_location: string;
  userId: string;
  organization: string;
  securityTags: string[];
  tailNumber: string;
  faaRegistrationNumber: string;
  ownerContact: IContact;
  ownerIsOrganization: boolean;
  aircraftSpecs: IAircraftSpecs;
  additionalRegistrations: IAdditionalRegistration[];
  statistics: IUasStatistics;
  integrations: ITelemetryIntegrations;
}

export interface TransportUasRegistrationInfo {
  uvin: string;
  archived: boolean;
  name: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  registration_location: string;
  userId: string;
  organization: string;
  securityTags: string[];
  tailNumber: string;
  faaRegistrationNumber: string;
  ownerContact: IContact;
  ownerIsOrganization: boolean;
  aircraftSpecs: TransportAircraftSpecs;
  additionalRegistrations: IAdditionalRegistration[];
  statistics: IUasStatistics;
  integrations: TransportTelemetryIntegrations;
}

export class UasRegistrationInfo extends BaseModel implements IUasRegistrationInfo {
  /* eslint-disable @typescript-eslint/naming-convention */
  static NAME_FIELD_NAME = 'name';
  static ADDITIONAL_REGISTRATIONS_FIELD_NAME = 'additionalRegistrations';
  static AIRCRAFT_SPECS_FIELD_NAME = 'aircraftSpecs';
  static FAA_REGISTRATION_NUMBER_FIELD_NAME = 'faaRegistrationNumber';
  static ORGANIZATION_FIELD_NAME = 'organization';
  static OWNER_CONTACT_FIELD_NAME = 'ownerContact';
  static OWNER_IS_ORGANIZATION_FIELD_NAME = 'ownerIsOrganization';
  static REGISTRATION_LOCATION_FIELD_NAME = 'registration_location';
  static SECURITY_TAGS_FIELD_NAME = 'securityTags';
  static STATISTICS_FIELD_NAME = 'statistics';
  static TAIL_NUMBER_FIELD_NAME = 'tailNumber';
  static USER_ID_FIELD_NAME = 'userId';
  static UVIN_FIELD_NAME = 'uvin';
  static ARCHIVED_FIELD_NAME = 'archived';
  static INTEGRATIONS_FIELD_NAME = 'integrations';

  name: string;
  additionalRegistrations: AdditionalRegistration[];
  aircraftSpecs: AircraftSpecs;
  faaRegistrationNumber: string;
  organization: string;
  ownerContact: Contact;
  ownerIsOrganization: boolean;
  // tslint:disable-next-line:variable-name
  registration_location: string;
  securityTags: string[];
  statistics: UasStatistics;
  tailNumber: string;
  userId: string;
  uvin: string;
  archived: boolean;
  integrations: TelemetryIntegrations;
  /* eslint-enable @typescript-eslint/naming-convention */

  constructor(values?: Partial<IUasRegistrationInfo>, useFormGroupValuesToModel = false) {
    super();
    this.securityTags = [];
    this.additionalRegistrations = [];
    this.aircraftSpecs = new AircraftSpecs();
    this.ownerContact = null;
    this.statistics = new UasStatistics();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IUasRegistrationInfo>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);

      this.name = this.getValue<string>(rawValues, UasRegistrationInfo.NAME_FIELD_NAME);
      this.fillModelArray<AdditionalRegistration>(this,
        UasRegistrationInfo.ADDITIONAL_REGISTRATIONS_FIELD_NAME,
        rawValues.additionalRegistrations,
        useFormGroupValuesToModel);
      this.aircraftSpecs.setValues(rawValues.aircraftSpecs, useFormGroupValuesToModel);
      this.faaRegistrationNumber = this.getValue<string>(rawValues, UasRegistrationInfo.FAA_REGISTRATION_NUMBER_FIELD_NAME);
      this.organization = this.getValue<string>(rawValues, UasRegistrationInfo.ORGANIZATION_FIELD_NAME);
      this.ownerContact = this.getValue<Contact>(rawValues, UasRegistrationInfo.OWNER_CONTACT_FIELD_NAME);
      this.ownerIsOrganization = this.getValue<boolean>(rawValues, UasRegistrationInfo.OWNER_IS_ORGANIZATION_FIELD_NAME);
      this.registration_location = this.getValue<string>(rawValues, UasRegistrationInfo.REGISTRATION_LOCATION_FIELD_NAME);
      this.fillModelArray<string>(this, UasRegistrationInfo.SECURITY_TAGS_FIELD_NAME, rawValues.securityTags, useFormGroupValuesToModel);
      this.statistics.setValues(rawValues.statistics, useFormGroupValuesToModel);
      this.tailNumber = this.getValue<string>(rawValues, UasRegistrationInfo.TAIL_NUMBER_FIELD_NAME);
      this.userId = this.getValue<string>(rawValues, UasRegistrationInfo.USER_ID_FIELD_NAME);
      this.uvin = this.getValue<string>(rawValues, UasRegistrationInfo.UVIN_FIELD_NAME);
      this.archived = this.getValue<boolean>(rawValues, UasRegistrationInfo.ARCHIVED_FIELD_NAME);
      this.integrations = rawValues.integrations ? new TelemetryIntegrations(rawValues.integrations) : null;
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[UasRegistrationInfo.NAME_FIELD_NAME].setValue(this.name);
    this.fillFormArray<AdditionalRegistration>(UasRegistrationInfo.ADDITIONAL_REGISTRATIONS_FIELD_NAME, this.additionalRegistrations);
    this.aircraftSpecs.setFormGroupValues();
    this.$formGroup.controls[UasRegistrationInfo.FAA_REGISTRATION_NUMBER_FIELD_NAME].setValue(this.faaRegistrationNumber);
    this.$formGroup.controls[UasRegistrationInfo.ORGANIZATION_FIELD_NAME].setValue(this.organization);
    this.ownerContact?.setFormGroupValues();
    this.$formGroup.controls[UasRegistrationInfo.OWNER_IS_ORGANIZATION_FIELD_NAME].setValue(this.ownerIsOrganization);
    this.$formGroup.controls[UasRegistrationInfo.REGISTRATION_LOCATION_FIELD_NAME].setValue(this.registration_location);
    this.fillFormArray<string>(UasRegistrationInfo.SECURITY_TAGS_FIELD_NAME, this.securityTags);
    this.statistics.setFormGroupValues();
    this.$formGroup.controls[UasRegistrationInfo.TAIL_NUMBER_FIELD_NAME].setValue(this.tailNumber);
    this.$formGroup.controls[UasRegistrationInfo.USER_ID_FIELD_NAME].setValue(this.userId);
    this.$formGroup.controls[UasRegistrationInfo.UVIN_FIELD_NAME].setValue(this.uvin);
    this.$formGroup.controls[UasRegistrationInfo.ARCHIVED_FIELD_NAME].setValue(this.archived);

    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  getPrettyOrganizationName() {
    return OrganizationUtil.getPrettyOrganizationName(this.organization);
  }

  protected getFormGroup(): UntypedFormGroup {
    /* eslint-disable no-underscore-dangle */
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        name: new UntypedFormControl(this.name, [Validators.required, Validators.maxLength(50)]),
        uvin: new UntypedFormControl(this.uvin, [Validators.required]),
        archived: new UntypedFormControl(this.archived, [Validators.required]),
        // eslint-disable-next-line @typescript-eslint/naming-convention
        registration_location: new UntypedFormControl(this.registration_location, [Validators.required]),
        userId: new UntypedFormControl(this.userId, [Validators.required]),
        organization: new UntypedFormControl(this.organization, [Validators.required]),
        securityTags: new UntypedFormArray([]),
        tailNumber: new UntypedFormControl(this.tailNumber),
        faaRegistrationNumber: new UntypedFormControl(this.faaRegistrationNumber, [Validators.required]),
        ownerIsOrganization: new UntypedFormControl(this.ownerIsOrganization, [Validators.required]),
        aircraftSpecs: this.aircraftSpecs.$formGroup,
        additionalRegistrations: new UntypedFormArray([]) ,
        statistics: this.statistics.$formGroup
      });
      // generate FormArray control elements
      this.fillFormArray<string>(UasRegistrationInfo.SECURITY_TAGS_FIELD_NAME, this.securityTags);
      // generate FormArray control elements
      this.fillFormArray<AdditionalRegistration>(UasRegistrationInfo.ADDITIONAL_REGISTRATIONS_FIELD_NAME, this.additionalRegistrations);
      if (this.ownerContact) {
        this._formGroup.addControl(UasRegistrationInfo.OWNER_CONTACT_FIELD_NAME, this.ownerContact.$formGroup);
      }
    }
    return this._formGroup;
    /* eslint-enable no-underscore-dangle */
  }

  get prettyName(){
    return `${this.name || 'Name missing'} (${this.faaRegistrationNumber})`;
  }
}



export class JwtUtil{
  static isTokenExpired(token: string): boolean{

    try {
      const body = token.split('.')[1];
      const obj = JSON.parse(atob(body));
      return (obj.expiration - Date.now()) <= 0;
    }catch (e) {
      return false;
    }
  }

}

import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {FormErrorService} from '../error-container/error-container.component';
import {BehaviorSubject, combineLatest, Subscription} from 'rxjs';
import {ValidationErrors} from '@angular/forms';

type errHandler = (err: ValidationErrors | null) => boolean;

@Component({
  selector: 'error-message',
  templateUrl: './error-message.component.html'
})
export class ErrorMessageComponent implements OnInit, OnChanges, OnDestroy {

  // @ViewChild('ref', {read: ElementRef}) ref: ElementRef;
  @Input() has: string = null;
  @Input() pred: errHandler = null;

  displayError = false;
  errorMessage = '';

  private hasSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private predicateSubject: BehaviorSubject<errHandler> = new BehaviorSubject<errHandler>(null);
  private sub: Subscription;

  constructor(private errorService: FormErrorService) {
    this.sub = combineLatest([this.errorService.errors, this.hasSubject, this.predicateSubject]).subscribe(([errors, has, pred]) => {
      this.displayError = !!errors && ((!has && !pred) || (!!has && has in errors && errors[has]) || (!!pred && pred(errors)));
      if (!!errors && has && has in errors) {
        this.errorMessage = errors[has];
      }
      else{
        this.errorMessage = '';
      }
    });

    this.hasSubject.next(this.has);
    this.predicateSubject.next(this.pred);

  }

  ngOnInit(): void {
    this.hasSubject.next(this.has);
    this.predicateSubject.next(this.pred);

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.hasSubject.next(this.has);
    this.predicateSubject.next(this.pred);
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }


}

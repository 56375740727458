<h1>Off Nominal Report Submission</h1>
<div class="clr-row">
  <clr-select-container class="clr-col-3">
    <label>Demo Options</label>
    <select autocomplete="off" clrSelect [(ngModel)]="demoOption" (change)="applyDemoValues()">
      <option value="no" selected disabled>No Demo</option>
      <option value="demo1">Demo1</option>
    </select>
  </clr-select-container>
</div>
<form clrForm [formGroup]="offNominalReportForm" style="width: 80%;">
  <div class="clr-row">
    <clr-input-container class="clr-col-6">
      <label>Full Name:</label>
      <input autocomplete="off" clrInput formControlName="fullName"/>
    </clr-input-container>


    <clr-input-container class="clr-col-6">
      <label>Primary Email:</label>
      <input autocomplete="off" clrInput type="email" formControlName="email">
    </clr-input-container>


    <clr-input-container class="clr-col-6">
      <label>Primary Phone Number:</label>
      <input autocomplete="off" clrInput type="tel" formControlName="phoneNumber" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}">
      <clr-control-helper>(Format: 012-345-6789)</clr-control-helper>
    </clr-input-container>

  </div>
  <div class="clr-row">
    <clr-select-container class="clr-col-6">
      <label>Describe your role and responsibility</label>
      <select autocomplete="off" clrSelect formControlName="roleResponsibility">
        <option value="" disabled selected>Select your option</option>
        <option *ngFor="let sType of roleResponsibilityOptions" [ngValue]="sType.key">{{sType.text}}</option>

      </select>
      <clr-control-error>Role and Responsibility needed!</clr-control-error>

    </clr-select-container>

    <clr-select-container class="clr-col-6">
      <label>What is the reason for this report?*</label>
      <select autocomplete="off" clrSelect formControlName="reportReason" required>
        <option value="" disabled selected>Select your option</option>
        <option *ngFor="let sType of reportReasonOptions" [ngValue]="sType.key">{{sType.text}}</option>

      </select>
    </clr-select-container>
    <clr-select-container class="clr-col-6">
      <label>Was this a scripted event?*</label>
      <select autocomplete="off" clrSelect formControlName="scriptedEvent" required>
        <option value="" disabled selected>Select your option</option>
        <option *ngFor="let sType of scriptedEventOptions" [ngValue]="sType.key">{{sType.text}}</option>
      </select>
    </clr-select-container>


    <div class="clr-col-6">
      <p style="display:inline">
        <i>
          Activated contingency plan id, if any.
          When more than one are used, please
          state so in the reporter narrative section.
        </i>
      </p>
      <clr-select-container>
        <label>Contingency ID (PH):</label>
        <select autocomplete="off" clrSelect formControlName="contingencyId">
          <option value="" disabled selected>Select your option</option>
          <option value="None">None</option>
          <option *ngFor="let sType of contingencyOptions" [ngValue]="sType.key">{{sType.text}}</option>
        </select>

      </clr-select-container>
    </div>

    <clr-select-container class="clr-col-6">
      <label>Autopilot Maker*:</label>
      <select autocomplete="off" clrSelect formControlName="autopilotMaker">
        <option value="" disabled selected>Select your option</option>
        <option *ngFor="let sType of autopilotMakerOptions" [ngValue]="sType.key">{{sType.text}}</option>
      </select>
    </clr-select-container>

    <clr-select-container class="clr-col-6">
      <label>Autopilot Model*:</label>
      <select autocomplete="off" clrSelect formControlName="autopilotModel">
        <option value="" disabled selected>Select your option</option>
        <option *ngFor="let sType of autopilotModelOptions" [ngValue]="sType.key">{{sType.text}}</option>
      </select>
    </clr-select-container>

    <clr-select-container class="clr-col-6">
      <label>GCS Being Utilized*:</label>
      <select autocomplete="off" clrSelect formControlName="gcsProgram">
        <option value="" disabled selected>Select your option</option>
        <option *ngFor="let sType of gcsProgramOptions" [ngValue]="sType.key">{{sType.text}}</option>

      </select>
    </clr-select-container>
    <div class="clr-col-6">
      <p style="display:inline">
        <i>
          Describe communications system including
          frequencies used for telemetry (aircraft to operator)
          and Command & Control (operator to aircraft).
        </i>
      </p>
      <clr-select-container>
        <label>Flight Communication System*:</label>

        <select autocomplete="off" clrSelect formControlName="commSystem">
          <option value="" disabled selected>Select your option</option>
          <option *ngFor="let sType of commSystemOptions" [ngValue]="sType.key">{{sType.text}}</option>

        </select>
      </clr-select-container>
    </div>
    <div class="clr-col-6">
      <p style="display:inline">
        <i>
          Keeping in mind the topics shown below, discuss those that you feel
          are relevant and anything else you think is important. Include what
          you believe really caused the problem, and what can be done to prevent
          a recurrence, or correct the situation. <br>CHAIN OF EVENTS – How the
          problem arose • How it was discovered • Contributing factors •
          Corrective actions. <br>HUMAN PERFORMANCE CONSIDERATIONS – Perceptions,
          judgments, decisions • Actions or inactions • Factors affecting the
          quality of human performance
        </i>
      </p>
      <clr-textarea-container>
        <label>Describe the event as you observed it*:</label>
        <textarea autocomplete="off" clrTextarea required maxlength="15000" rows="5" cols="60"
                  formControlName="reporterNarrative"></textarea>
      </clr-textarea-container>
    </div>
    <clr-select-container class="clr-col-6">
      <label>What was the rule this operation was flown under?</label>
      <select autocomplete="off" clrSelect formControlName="flightRule">
        <option value="" disabled selected>Select your option</option>
        <option *ngFor="let sType of flightRuleOptions" [ngValue]="sType.key">{{sType.text}}</option>

      </select>
    </clr-select-container>
    <clr-input-container class="clr-col-6">
      <label>When did this event occur?</label>
      <input autocomplete="off" clrInput type="datetime-local" formControlName="timeOccurance" min="2020-01-01T00:00" max="2030-12-31T23:59">
    </clr-input-container>

    <clr-select-container class="clr-col-6">
      <label>GUFI(PH)*:</label>
      <select autocomplete="off" clrSelect formControlName="gufi" required>
        <option value="" disabled selected>Select your option</option>
        <option *ngFor="let sType of gufiOptions" [ngValue]="sType.key">{{sType.text}}</option>
      </select>
    </clr-select-container>
  </div>
  <button class="btn btn-primary" [disabled]="offNominalReportForm.invalid" (click)="submitReport()">Submit Report
  </button>

  <h2>Metadata</h2>
  <div class="clr-row">
    <clr-select-container class="clr-col-6">
      <label>Data Collection*:</label>
      <select autocomplete="off" clrSelect formControlName="dataCollection" required>
        <option *ngFor="let sType of dataCollectionOptions" [ngValue]="sType.key">{{sType.text}}</option>
      </select>
    </clr-select-container>

    <clr-input-container class="clr-col-6">
      <label>Callsign*:</label>
      <input autocomplete="off" clrInput type="text" maxlength="100" formControlName="callSign" required>
    </clr-input-container>


    <clr-select-container class="clr-col-6">
      <label>USS Name*:</label>
      <select autocomplete="off" clrSelect formControlName="ussName" required>
        <option *ngFor="let sType of ussNameOptions" [ngValue]="sType.key">{{sType.text}}</option>
      </select>
    </clr-select-container>

    <clr-input-container class="clr-col-6">
      <label>Test Run*:</label>
      <input autocomplete="off" clrInput type="number" formControlName="testRun" min="1" max="999" style="width: 3em" required>
    </clr-input-container>
  </div>
</form>

<clr-modal [(clrModalOpen)]="submittingReport" [clrModalClosable]="true" [clrModalSize]="'md'">
  <div class="modal-body">
    <clr-spinner clrInline>
      Submitting Report
    </clr-spinner>
    <span>
      Submitting Report
  </span>
  </div>
</clr-modal>

import {Component, forwardRef, Input} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {SearchResult} from '../../model/SearchResult';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {ContactService} from '../../services/contact.service';
import {IControlValueAccessor} from '@rxweb/types';
import {Contact} from '../../model/Contact';

@Component({
  selector: 'app-owner-contact-selector',
  templateUrl: './contact-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ContactSelectorComponent),
    }
  ]
})
export class ContactSelectorComponent implements IControlValueAccessor<Contact> {

  isDisabled: boolean;
  @Input() contact: Contact;
  selectingContact: boolean;
  contacts: Contact[];
  currentPageSize: number;
  totalItems: number;
  loading = true;
  state: ClrDatagridStateInterface;
  previousState: ClrDatagridStateInterface;
  private onChange: (Contact) => void;

  constructor(private contactService: ContactService) {
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: Contact): void {
    this.contact = obj;
  }

  openContactSelector() {
    this.selectingContact = true;
  }

  refresh(statey: ClrDatagridStateInterface) {
    this.loading = true;
    if (!statey) {
      statey = this.state || {};
    }

    this.state = statey;

    if (!statey.page) {
      statey.page = {
        from: 0,
        to: 9,
        size: 10,
      };
    }


    const fetchCount = !this.totalItems;

    this.contactService.getContacts({}, statey.page.size, statey.page.from, fetchCount)
      .subscribe((results: SearchResult<Contact>) => {
        if (results.total >= 0) {
          this.totalItems = results.total;
        }
        this.loading = false;
        this.contacts = results.results;
        statey.page.from = results.offset;
        statey.page.to = results.offset + results.results.length;
      });

    this.previousState = statey;

  }

  selectContact(selectedContact: Contact) {
    this.contact = selectedContact;
    this.selectingContact = false;
    if (this.onChange) {
      this.onChange(this.contact);
    }
  }

  getContactText() {
    if (this.contact && !this.contact.isEmpty()) {
      return `${this.contact.title ? this.contact.title + ' - ' : ''}${this.contact.getName()}`;
    } else {
      return '';
    }
  }
}

import {Component, Input} from '@angular/core';
import {User} from '../../model/User';

@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html'
})
export class UserSummaryComponent {

  @Input() user: User;

  constructor() {
    // Constructor intentionally left empty
  }

  prettyRoleName(role: string) {
    switch (role) {
      case 'admin':
        return 'Administrator';
      case 'mcom':
        return 'Mission Commander';
      case 'atc':
        return 'Air Traffic Controller';
      case 'pilot':
        return 'Pilot';
      case 'dev':
        return 'Developer';
    }
  }
}

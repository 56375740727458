import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {ColorService} from '../../services/color.service';
import {UserService} from '../../services/user.service';
import {SituationalAwarenessService} from '../../services/situational-awareness.service';
import {OperationVolume, state, units_of_measure, vertical_reference} from '../../model/gen/utm';
import tinycolor from 'tinycolor2';
import {AltitudeUtilService} from '../../model/utm/altitude-util.service';
import {
  Cartesian3,
  Color,
  ColorMaterialProperty,
  ConstantProperty,
  CustomDataSource,
  Entity,
  PolygonGraphics,
  PolygonHierarchy
} from '@cesium/engine';
import {Viewer} from '@cesium/widgets';
import {BehaviorSubject, combineLatest, interval, Observable, ReplaySubject, Subscription} from 'rxjs';
import {OperationExt} from '../../model/utm/OperationExt';
import {DateTime} from 'luxon';
import {OperationUtil} from '../../model/OperationUtil';
import {Converter} from '../../utils/convert-units';
import {AltitudeService, IAltitudeConversionParameters} from '../../services/altitude.service';
import {AltitudeRange} from '../../model/gen/utm/altitude-range-model';
import {of} from 'rxjs/internal/observable/of';
import {debounceTime, distinctUntilChanged, map, switchMap} from 'rxjs/operators';
import {ConstraintTypeService} from '../../services/constraint-type.service';
import {LatLng} from "leaflet";
import {MeasurementsUtil} from "../../utils/MeasurementsUtil";

interface IAltitudeUnitsRefsPair {
  units: units_of_measure;
  ref: vertical_reference;
}

@Component({
  selector: 'app-operation-sitrep-drawer',
  template: '',
})
export class OperationSitrepDrawerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() altRef: vertical_reference;
  @Input() altUnits: units_of_measure;
  private operationCache: { [key: string]: OperationExt } = {};
  private datasource: CustomDataSource;
  private viewer: Viewer;
  private altRefSubject = new BehaviorSubject(null);
  private altUnitsSubject = new BehaviorSubject(null);
  private entitySubject = new ReplaySubject<Entity[]>(1);
  private viewerSub: Subscription;
  private colorConfigSub: Subscription;
  private opsSub: Subscription;
  private entitySub: Subscription;
  private opVolSubs: Subscription[] = [];
  private altitudeSubs: { [k: string]: Subscription[] } = {};
  private sitrepRefreshSubscription: Subscription;

  constructor(private drawerService: CesiumService,
              private sitrepService: SituationalAwarenessService,
              private colorService: ColorService,
              private userService: UserService,
              private altitudeService: AltitudeService,
              private altitudeUtilService: AltitudeUtilService,
              private constraintTypeService: ConstraintTypeService) {

  }

  private static getRegistrations(operation: OperationExt): string {
    let results = '';
    for (const reg of operation.uas_registrations) {
      results += '<tr><td>' + reg.registration_id + '</td><td>' + reg.registration_location + '</td></tr>';
    }
    return results;
  }

  ngOnInit(): void {
    this.viewerSub = this.drawerService.watchViewerInit().subscribe(viewer => {
      this.entitySub?.unsubscribe();
      this.opsSub?.unsubscribe();

      this.viewer = viewer;
      viewer.infoBox.frame.removeAttribute('sandbox');
      viewer.infoBox.frame.src = 'about:blank';
      this.datasource = new CustomDataSource();
      this.viewer.dataSources.add(this.datasource);

      this.entitySub = this.entitySubject.subscribe((entities: Entity[]) => {
        const _entities = [...this.datasource.entities.values];
        const activeIds = new Set(_entities.map(e => e.id));
        const currentIds = new Set(entities.map(e => e.id));
        for (const id of activeIds) {
          if (!currentIds.has(id)) {
            this.datasource.entities.removeById(id);
          }
        }
        for (const entity of entities) {
          if (activeIds.has(entity.id)) {
            const e = this.datasource.entities.getById(entity.id);
            e.description = entity.description;
          } else {
            this.datasource.entities.add(entity);
          }
        }
      });

      this.opsSub = this.sitrepService.watchOperations().pipe(distinctUntilChanged()).pipe(switchMap((ops) => {
        let src: Observable<Entity[][]> = of([]);
        if (ops.length) {
          src = combineLatest(ops.map(o => this.getOperationEntityObservables(o)));
        }
        return src.pipe(map((entityArrays) => {
          const ret: Entity[] = [];
          for (const entityArray of entityArrays) {
            ret.push(...entityArray);
          }
          return ret;
        }));
      })).subscribe((entities) => {
        this.entitySubject.next(entities);
      });
      this.sitrepRefreshSubscription?.unsubscribe();
      this.sitrepRefreshSubscription = interval(5000).subscribe(() => {
        this.sitrepService.refresh();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.altRef && changes.altRef.previousValue !== changes.altRef.currentValue) {
      this.altRefSubject.next(changes.altRef.currentValue);
    }
    if (changes.altUnits && changes.altUnits.previousValue !== changes.altUnits.currentValue) {
      this.altUnitsSubject.next(changes.altUnits.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.viewerSub?.unsubscribe();
    this.colorConfigSub?.unsubscribe();
    this.opsSub?.unsubscribe();
    this.entitySub?.unsubscribe();
    this.opVolSubs.forEach(sub => sub?.unsubscribe());
    if (this.datasource && this.viewer) {
      this.viewer.dataSources.remove(this.datasource);
    }
    for (const id of Object.keys(this.altitudeSubs)) {
      this.altitudeSubs[id].forEach(sub => sub?.unsubscribe());
    }
    this.sitrepRefreshSubscription?.unsubscribe();
  }

  private getOperationEntityObservables(operation: OperationExt): Observable<Entity[]> {
    return this.colorService.getColorForId(this.colorService.getIdForState(operation.state), true).pipe(switchMap(colorConfig => {
      const fill = colorConfig.fill.toRgb();
      const outline = (colorConfig.outline ? colorConfig.outline : tinycolor('black')).toRgb();
      const entityObservables: Observable<Entity>[] = [];

      for (const [ordinal, vol] of operation.operation_volumes.entries()) {
        const coordinates = new LatLng(vol.geography.coordinates[0][0][1], vol.geography.coordinates[0][0][0]);
        const maxAltitudeOffset = MeasurementsUtil.convertUnits(vol.min_altitude.units_of_measure, units_of_measure.M,
          (vol.max_altitude.altitude_value - vol.min_altitude.altitude_value));

        entityObservables.push(this.altitudeUtilService.convertToWGS84Meters(vol.min_altitude, coordinates)
          .pipe(switchMap((minAltitude) => {
            return combineLatest([
              this.getOperationVolumeDescriptionObservable(operation, vol),
              of(minAltitude)
            ]);
          }), map(([description, minAltitude]) => {
            const points: number[] = [];
            vol.geography.coordinates[0].forEach(point => {
              points.push(...point);
            });
            return new Entity({
              id: `${operation.operationId}-${ordinal}-${operation.version}`,
              name: `Operation Volume ${ordinal + 1}`,
              description,
              polygon: new PolygonGraphics({
                hierarchy: new PolygonHierarchy(Cartesian3.fromDegreesArray(points), []),
                height: new ConstantProperty(minAltitude.altitude_value),
                extrudedHeight: new ConstantProperty(minAltitude.altitude_value + maxAltitudeOffset),
                material: new ColorMaterialProperty(new Color(fill.r / 255, fill.g / 255, fill.b / 255, fill.a)),
                outline: true,
                outlineWidth: 2,
                outlineColor: new Color(outline.r / 255, outline.g / 255, outline.b / 255, outline.a),
              })
            });
          }))
        );
      }
      return combineLatest(entityObservables);
    }));
  }

  private getOperationVolumeDescriptionObservable(operation: OperationExt, vol: OperationVolume): Observable<string> {
    let altitudeRange = new AltitudeRange({
      min_altitude: vol.min_altitude.altitude_value,
      max_altitude: vol.max_altitude.altitude_value,
      altitude_vertical_reference: vol.min_altitude.vertical_reference,
      altitude_units: vol.min_altitude.units_of_measure,
      source: vol.min_altitude.source
    });

    const referencePoint = vol.circle ? [vol.circle.longitude, vol.circle.latitude]
      : vol.geography.coordinates[0][0];

    return combineLatest([
      this.constraintTypeService.getPrettyName(operation?.additional_data?.permitted_constraint_types[0]),
      this.convertAltitudeRefs(altitudeRange, referencePoint),
      this.altUnitsSubject
    ]).pipe(debounceTime(100), map(([operationType, convertedAltitudeRange, altUnits]) => {
      convertedAltitudeRange = this.convertAltitudeUnits(convertedAltitudeRange, altUnits);

      return `
        <table style="font-size: 12px;" border="1">
          <tr><th scope="row" style="text-align: left;">Name</th><td>${operation.flight_number}</td></tr>
          <tr><th scope="row" style="text-align: left;">State</th><td>${operation.state}</td></tr>
          ${operation.state === state.NONCONFORMING || operation.state === state.ROGUE ?
          '<tr><th scope="row" style="text-align: left;">Off Nominal</th><td>' + vol.off_nominal + '</td></tr>' : ''}
          <tr><th scope="row" style="text-align: left;">Priority</th><td>${operation.priority}</td></tr>
          <tr><th scope="row" style="text-align: left;">Operation Type</th><td>${operationType || 'None'}</td></tr>
          <tr><th scope="row" style="text-align: left;">Begin Time</th><td>${vol.effective_time_begin?.toLocaleString(DateTime.DATETIME_MED)}</td></tr>
          <tr><th scope="row" style="text-align: left;">End Time</th><td>${vol.effective_time_end?.toLocaleString(DateTime.DATETIME_MED)}</td></tr>
          <tr><th scope="row" style="text-align: left;">Min Altitude</th><td>${convertedAltitudeRange.getMinAlt().toString()}</td></tr>
          <tr><th scope="row" style="text-align: left;">Max Altitude</th><td>${convertedAltitudeRange.getMaxAlt().toString()}</td></tr>
          <tr><th scope="row" style="text-align: left;">USS</th><td>${operation.uss_name}</td></tr>
        </table>
        <p><a href="/fuss/operations/view-operation?operationId=${operation.operationId}" rel="noreferrer noopener"
            target="_blank">View Operation Details</a></p>`;
    }));
  }

  private haveOperationCached(op: OperationExt): boolean {
    return (op.operationId in this.operationCache) && OperationUtil.operationExtEqual(this.operationCache[op.operationId], op);
  }

  private convertAltitudeUnits(altitudeRange: AltitudeRange, convertTo: units_of_measure): AltitudeRange {
    const unitsFrom = this.altitudeUtilService.parseUnitForConversion(altitudeRange.altitude_units);
    const unitsTo = this.altitudeUtilService.parseUnitForConversion(convertTo);
    const convertedRange = altitudeRange;

    if (unitsFrom !== unitsTo) {
      convertedRange.min_altitude = new Converter(altitudeRange.min_altitude).from(unitsFrom).to(unitsTo);
      convertedRange.max_altitude = new Converter(altitudeRange.max_altitude).from(unitsFrom).to(unitsTo);
      convertedRange.altitude_units = convertTo;
    }

    return convertedRange;
  }

  private convertAltitudeRefs(altitudeRange: AltitudeRange, referencePoint: number[]): Observable<AltitudeRange> {
    return this.altRefSubject.pipe(switchMap(convertTo => {
      if (altitudeRange.altitude_vertical_reference !== convertTo) {
        // Convert units to meters before using the reference conversion endpoint
        if (altitudeRange.altitude_units !== units_of_measure.M) {
          altitudeRange = this.convertAltitudeUnits(altitudeRange, units_of_measure.M);
        }

        const minAlt: IAltitudeConversionParameters = {
          lat: referencePoint[1],
          lon: referencePoint[0],
          altitude: altitudeRange.min_altitude,
          input_reference: altitudeRange.altitude_vertical_reference,
          output_reference: convertTo
        };

        // Convert altitudes
        return this.altitudeService.convertAltitude(minAlt).pipe(map((min) => {
          const maxOffset = altitudeRange.max_altitude - altitudeRange.min_altitude;
          return new AltitudeRange({
            min_altitude: min.altitude,
            max_altitude: min.altitude + maxOffset,
            altitude_units: altitudeRange.altitude_units,
            altitude_vertical_reference: convertTo,
            source: altitudeRange.source
          });
        }));
      } else {
        return of(altitudeRange);
      }
    }));
  }


}

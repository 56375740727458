<div>
  <clr-timeline [clrLayout]="layout">
    <clr-timeline-step [clrState]="timelineState.UPLOAD_STEP">
      <clr-timeline-step-header>Upload Flight Plan</clr-timeline-step-header>
    </clr-timeline-step>
    <clr-timeline-step [clrState]="timelineState.REVIEW">
      <clr-timeline-step-header>Review</clr-timeline-step-header>
    </clr-timeline-step>

  </clr-timeline>
  @switch (CURRENT_STEP()) {
    @case ('UPLOAD') {
      <clr-input-container>
        <label>Load from file</label>
        <input autocomplete="off" #importFileControl clrInput type="file" [accept]="extensions"
               [formControl]="flightPlanInput"/>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
      </clr-input-container>
      <!--      <div class="footer">-->
        <!--        <button class="btn btn-primary" [disabled]="flightPlanInput.invalid" (click)="parseFlightPlan()">Next-->
        <!--        </button>-->
        <!--      </div>-->
    }
    @case ('REVIEW') {
      <ng-container *ngComponentOutlet="
        configComponent.component;
        inputs: configComponent.inputs;"/>

      <div class="footer">
        <button class="btn btn-secondary" (click)="reset()">Back</button>
      </div>
    }

  }
</div>

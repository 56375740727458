/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';

import {priority_status, severity} from './enums';

export interface IPriorityElements {
  priority_level: severity;
  priority_status: priority_status;
}


export class PriorityElements extends BaseModel implements IPriorityElements {

  static PRIORITY_LEVEL_FIELD_NAME = 'priority_level';
  static PRIORITY_STATUS_FIELD_NAME = 'priority_status';

  priority_level: severity;
  /** If a PUBLIC_SAFETY operation is PUT to any endpoint, the correct scope will be required.
   Any USS may PUT an EMERGENCY_* with non-priority scopes.
   EMERGENCY_AIRBORNE_IMPACT should be used when there is significant risk to other aircraft, but little risk to any people or property on the ground.
   EMERGENCY_GROUND_IMPACT should be used when there is significant risk to people and/or property on the ground, but little risk of airborne collision.
   EMERGENCY_AIR_AND_GROUND_IMPACT should be used when there is significant risk to aircraft AND people/property on the ground.
   NONE should only be used to indicate that an operation that previously had a priority status now has no priority status. */
  priority_status: priority_status;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IPriorityElements>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IPriorityElements>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.priority_level = this.getValue<severity>(rawValues, PriorityElements.PRIORITY_LEVEL_FIELD_NAME);
      this.priority_status = this.getValue<priority_status>(rawValues, PriorityElements.PRIORITY_STATUS_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        priority_level: new UntypedFormControl(this.priority_level, [Validators.required, enumValidator(severity),]),
        priority_status: new UntypedFormControl(this.priority_status, [Validators.required, enumValidator(priority_status),]),
      });
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[PriorityElements.PRIORITY_LEVEL_FIELD_NAME].setValue(this.priority_level);
    this.$formGroup.controls[PriorityElements.PRIORITY_STATUS_FIELD_NAME].setValue(this.priority_status);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

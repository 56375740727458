import {Injectable} from '@angular/core';
import {UVR_STATE, UVRSearchOptions, UvrService} from '../uvr.service';
import {Observable, Observer} from 'rxjs';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {LatLong} from '../../model/LatLong';
import {SearchResult} from '../../model/SearchResult';
import {UASVolumeReservation} from '../../model/gen/utm';

@Injectable({
  providedIn: 'root'
})
export class RestUvrService extends UvrService {

  constructor(private http: HttpClient) {
    super();
  }

  cancelUvr(uss: string, messageId: string, uasVolumeReservation?: UASVolumeReservation): Observable<boolean> {
    const url = `${environment.baseUrl}/uvrs/cancel/${uss}/${messageId}`;
    let success = false;
    return new Observable((observer: Observer<boolean>) => {
      let httpObservable: Observable<any>;
      httpObservable = this.http.delete(`${environment.baseUrl}/uvrs/${messageId}`).pipe(map((res) => {
          return true;
        }), catchError((err => of(false)))
      );

      if (uasVolumeReservation) {
        httpObservable = httpObservable.pipe(mergeMap(res => {
          success = res;
          return this.http.put(url, uasVolumeReservation).pipe(map((resy) => {
              return true;
            }), catchError((err => of(false)))
          );
        }));
      }

      httpObservable.subscribe((res) => {
        observer.next(success || res);
        observer.complete();
      }, () => {
        observer.next(success);
        observer.complete();
      });
    });
  }

  // eslint-disable-next-line max-len
  createUpdateUvr(uss: string, messageId: string, uasVolumeReservation: UASVolumeReservation): Observable<{ success: boolean, error?: any }> {
    const url = `${environment.baseUrl}/uvrs/${uss}/${messageId}`;
    return new Observable((observer) => {
      this.http.put(url, uasVolumeReservation).subscribe((response) => {
          observer.next({success: true});
          observer.complete();
        },
        (err: HttpErrorResponse) => {
          observer.next({success: false, error: err});
          observer.complete();
        });
    });

  }

  getUvrById(messageId: string): Observable<UASVolumeReservation> {
    const url = `${environment.baseUrl}/uvrs/${messageId}`;

    return this.http.get(url).pipe(map((res: any) => {

      return new UASVolumeReservation({
        reason: res.reason,
        message_id: res.message_id,
        uss_name: res.uss_name,
        //uss: res.uss || 'all',
        type: res.type,
        permitted_uas: res.permitted_uas || [],
        required_support: res.required_support || [],
        cause: res.cause,
        permitted_gufis: res.permitted_gufis || [],
        effective_time_begin: res.effective_time_begin,
        effective_time_end: res.effective_time_end,
        // min_altitude: res.min_altitude,
        // max_altitude: res.max_altitude,
        // geography: res.geography,
        volumes: res.volumes
      });
    }));
  }

  getUvrsForCoords(coords: LatLong[]): Observable<UASVolumeReservation[]> {
    const coordsStr = coords.map((point) => [point.Lat, point.Lon])
      .reduce((acc, val) => acc.concat(val), []).map(coord => coord.toFixed(5)).join(',');
    const url = `${environment.baseUrl}/uvrs?coords=${coordsStr}`;
    return this.http.get(url).pipe(map((resp: UASVolumeReservation[]) => {

      return resp;
    }));

  }

  getUssFromUvr(messageId: string, hardCodedUSSs?: string[]): Observable<string[]> {

    const url = `${environment.baseUrl}/uvrs/${messageId}/uss`;

    return this.http.get(url).pipe(map((res: string[]) => {
      if (hardCodedUSSs) {
        return [...hardCodedUSSs, ...res];
      } else {
        return res;
      }
    }));
  }

  getUvrs(options: UVRSearchOptions): Observable<SearchResult<UASVolumeReservation>> {

    const baseUrl = `${environment.baseUrl}/uvrs/list`;


    return this.http.get(this.buildGetUvrsUrl(baseUrl, options)).pipe(map((res: any) => {
      return new SearchResult(res.results, res.total, res.offset);
    }));
  }

  getUvrCount(options: UVRSearchOptions): Observable<SearchResult<UASVolumeReservation>> {

    const baseUrl = `${environment.baseUrl}/uvrs/list/count`;

    return this.http.get(this.buildGetUvrsUrl(baseUrl, options)).pipe(map((res: any) => {
      return new SearchResult(res.results, res.total, res.offset);
    }));
  }

  getUvrStatus(uvr: UASVolumeReservation, refreshMillis?: number): Observable<UVR_STATE> {
    const url = `${environment.baseUrl}/uvrs/${uvr.message_id}/status`;

    return this.http.get(url).pipe(map((res: string) => {
      //TODO: Fix rest UVR status
      return UVR_STATE[res];

    }));
  }

  createUvr(uasVolumeReservation: UASVolumeReservation,
            uss: string,
            messageId?: string): Observable<{ success: true; uvr_id: string } | { success: false; error?: any }> {
    const url = `${environment.baseUrl}/uvrs/${uss}/${messageId || ''}`;
    return new Observable((observer) => {
      this.http.post(url, uasVolumeReservation).subscribe((response: any) => {
          if (response.uvr_id) {
            observer.next({success: true, uvr_id: response.uvr_id});
          } else {
            observer.next({success: false, error: 'Invalid UVR ID given'});
          }

          observer.complete();
        },
        (err: HttpErrorResponse) => {
          observer.next({success: false, error: err});
          observer.complete();
        });
    });
  }

  exists(messageId: string): Observable<boolean> {
    const url = `${environment.baseUrl}/uvrs/${messageId}`;

    return this.http.head(url).pipe(map(() => {
      return true;
    })).pipe(catchError(() => {
      return of(false);
    }));
  }

  private buildGetUvrsUrl(baseUrl: string, options: UVRSearchOptions): string {
    const queryParams = [
      ...Object.entries(options.contains || {}).map(pair => ({param: `contains[${pair[0]}]`, value: pair[1]})),
      ...(options.sort || []).map(pair => ({param: `sort[${pair.by}]`, value: pair.direction})),
      {param: 'offset', value: options.offset},
      {param: 'limit', value: options.limit},
    ].filter(q => q.value != null)
      .map(q => `${encodeURIComponent(q.param)}=${encodeURIComponent(q.value.toString())}`)
      .join('&');

    return `${baseUrl}?${queryParams}`;
  }
}

import { IStandardResponse, StandardResponse } from './StandardResponse';
import { Contact, IContact } from './Contact';
import { Parser } from './utm/parser/OperationParser';

export interface IToken {
  sub: string;
  issuer: string;
  audience: string;
  subject: string;
  expiration: number;
  category: string;
  arole: string;
  assertedRole: string;
  org: string;
  organization: string;
  manyRoles: boolean;
  uid: string;
  roles: string[];
  contact: IContact;
}

export class Token {
  sub: string;
  issuer: string;
  audience: string;
  subject: string;
  expiration: number;
  category: string;
  arole: string;
  assertedRole: string;
  org: string;
  organization: string;
  manyRoles: boolean;
  uid: string;
  title: string;
  division: string;
  roles: string[];
  contact: Contact;

  constructor(token: IToken) {
    this.sub = token.sub;
    this.issuer = token.issuer;
    this.audience = token.audience;
    this.subject = token.subject;
    this.expiration = token.expiration;
    this.category = token.category;
    this.arole = token.arole;
    this.assertedRole = token.assertedRole;
    this.org = token.org;
    this.organization = token.organization;
    this.manyRoles = token.manyRoles;
    this.uid = token.uid;
    this.roles = token.roles;
    this.contact = token.contact ? Parser.parseContact(token.contact) : null;
  }

  static parseJWT(rawToken: string): Token {
    const payload = rawToken.split('.')[1];
    return new Token(JSON.parse(atob(payload)));
  }
}

export interface ILoginResponse extends IStandardResponse {
  access_token: string;
  roles: string[];
}

export class LoginResponse extends StandardResponse implements ILoginResponse {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  access_token: string;
  roles: string[];
  private parsedToken: Token;

  constructor(loginResponse: ILoginResponse) {
    super(loginResponse);
    this.access_token = loginResponse.access_token;
    this.roles = loginResponse.roles;
    this.parsedToken = Token.parseJWT(this.access_token);
  }

  getParsedToken(): Token {
    return this.parsedToken;
  }

}

import {Component, OnInit} from '@angular/core';
import {User} from '../../../shared/model/User';
import {AdminUserService} from '../../../shared/services/admin/admin-user.service';
import {ActivatedRoute} from '@angular/router';
import {SubmissionMode} from '../../../shared/model/SubmissionStatus';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html'
})
export class UpdateUserComponent implements OnInit {
  user: User;
  submissionMode = SubmissionMode;

  constructor(private userService: AdminUserService, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          if (params.userid) {
            this.userService.getUser(params.userid).subscribe((response) => {
              this.user = response.user;
            });
          }
        }
      );
  }
}

<form [formGroup]="fg">
  <div *ngIf="!inline">
    <clr-input-container>
      <input clrInput autocomplete="off" formControlName="altitude_value" type="number">
    </clr-input-container>
    <div class="clr-form-control clr-row">
      <div class="clr-control-container clr-col-md-8 clr-col-12">
        <div class="clr-select-wrapper">
          <select autocomplete="off" formControlName="vertical_reference" class="clr-select">
            <option *ngFor="let option of VerticalReferenceIter" [value]="option.value">{{option.label}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="clr-form-control clr-row">
      <div class="clr-control-container clr-col-md-8 clr-col-12">
        <div class="clr-select-wrapper">
          <select autocomplete="off" formControlName="units_of_measure" class="clr-select">
            <option *ngFor="let option of UnitsOfMeasureIter" [value]="option.value">{{option.label}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="inline">
    <input autocomplete="off" formControlName="altitude_value" type="number" class="clr-input inline-altitude_value">
    <div class="clr-select-wrapper">
      <select autocomplete="off" formControlName="units_of_measure" class="clr-select">
        <option *ngFor="let option of UnitsOfMeasureIter" [value]="option.value">{{option.label}}</option>
      </select>
    </div>
    <div class="clr-select-wrapper">
      <select autocomplete="off" formControlName="vertical_reference" class="clr-select">
        <option *ngFor="let option of VerticalReferenceIter" [value]="option.value">{{option.label}}</option>
      </select>
    </div>
  </div>
</form>

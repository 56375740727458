import {DateTime} from "luxon";


export class TimeRange {
  constructor(public start?: DateTime, public end?: DateTime|null) {

  }

  private static dtEquals(a?: DateTime, b?: DateTime): boolean {
    if (!!a !== !!b) {
      return false;
    }
    if (!a) {
      return true;
    }
    return a.equals(b);

  }

  public equals(r2: TimeRange): boolean {
    return this === r2 || (
      r2 && TimeRange.dtEquals(this.start, r2.start) && TimeRange.dtEquals(this.end, r2.end)
    );
  }

  static equals(range1?: TimeRange, range2?: TimeRange): boolean {
    return range1 && range2
      && TimeRange.dtEquals(range1?.start, range2.start)
      && TimeRange.dtEquals(range1?.end, range2.end);
  }

}

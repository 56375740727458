import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OperationExt} from '../../../shared/model/utm/OperationExt';
import {
  CreateOperationComponent
} from '../../../shared/components/operation/create-operation/create-operation.component';
import {OperationService} from '../../../shared/services/operation.service';
import * as validator from 'validator';
import {Subscription} from 'rxjs';
import {User} from '../../../shared/model/User';
import {CurrentUserService} from '../../../shared/services/current-user.service';
import {PermissionService} from '../../../shared/permissions/service/permission.service';

export enum CreateOperationMode {
  none = 'none',
  clone = 'clone',
  replan = 'replan',
  rerouteAccepted = 'reroute-accepted',
  rerouteActive = 'reroute-active'
}

@Component({
  selector: 'app-new-operations',
  templateUrl: './new-operations.component.html'
})

export class NewOperationsComponent implements OnInit, OnDestroy {
  @ViewChild('createOperationComponent') createOperationComponent: CreateOperationComponent;
  operation: OperationExt;
  draftId: string = null;
  opModificationType: CreateOperationMode = CreateOperationMode.none;

  private currentUser: User;
  private currentUserSub: Subscription;
  private subscriptions: Subscription[] = [];

  constructor(private route: ActivatedRoute,
              private operationService: OperationService,
              private currentUserService: CurrentUserService,
              private permissionService: PermissionService) {

    this.currentUserSub = this.currentUserService.currentUser.subscribe(user => this.currentUser = user);
  }

  // @HostListener('window:beforeunload')
  // canDeactivate(): boolean {
  //   return !this.createOperationComponent.formGroup.dirty;
  // }

  ngOnInit(): void {
  this.subscriptions.push(this.route.queryParams
    .subscribe(params => {
      if (params.mode) {
        this.opModificationType = params.mode;
      }
      if (params.operationId) {
          this.subscriptions.push(this.operationService.getOperation(params.operationId).subscribe((operation) => {
            this.operation = operation;
          }));
        }
      if (params.draftId && validator.isUUID(params.draftId, 4)) {
          this.draftId = params.draftId;
        } else {
          this.draftId = null;
        }
      }
    ));
  }

  ngOnDestroy(): void {
    this.currentUserSub?.unsubscribe();
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }

}

<button type="button" class="btn btn-outline btn-sm" (click)="createRegistration()">Add Registration</button>

<table class="table table-compact" style="margin-top: 0;" aria-label="Edit registrations">
  <thead>
    <tr>
      <th>Registrar</th>
      <th>Registration Id</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let registration of registrations; index as i">
      <td><input class="clr-input"  autocomplete="off" [ngModel]="registration.registrar" (ngModelChange)="updateRegistrar(registration, $event)" /></td>
      <td><input class="clr-input"  autocomplete="off" [ngModel]="registration.registrationId" (ngModelChange)="updateRegistrationId(registration, $event)" /></td>
      <td><button type="button" class="btn btn-outline btn-sm" (click)="removeRegistration(registration, i)">Remove</button></td>
    </tr>
  </tbody>
</table>

export class Metadata {
  constructor(private dataCollection: string,
              private callSign: string,
              private ussName: string,
              private testRun: number) {

  }

  static fromValue(rawReportValue: any) {
    return new Metadata(
      rawReportValue.dataCollection || null,
      rawReportValue.callSign || null,
      rawReportValue.ussName || null,
      rawReportValue.testRun || null,
    );
  }

  toValue(): any {
    return {
      dataCollection: this.dataCollection,
      callSign: this.callSign,
      ussName: this.ussName,
      testRun: this.testRun,
    };
  }
}

export class OffNominalReport {
  constructor(private fullName: string,
              private email: string,
              private phoneNumber: string,
              private roleResponsibility: string,
              private reportReason: string,
              private scriptedEvent: string,
              private contingencyId: string,
              private autopilotMaker: string,
              private autopilotModel: string,
              private gcsProgram: string,
              private commSystem: string,
              private reporterNarrative: string,
              private flightRule: string,
              private timeOccurance: Date,
              private gufi: string,
              private metadata: Metadata) {

  }


  static fromValue(rawReportValue: any): OffNominalReport {
    return new OffNominalReport(
      rawReportValue.fullName || null,
      rawReportValue.email || null,
      rawReportValue.phoneNumber || null,
      rawReportValue.roleResponsibility || null,
      rawReportValue.reportReason || null,
      rawReportValue.scriptedEvent || null,
      rawReportValue.contingencyId || null,
      rawReportValue.autopilotMaker || null,
      rawReportValue.autopilotModel || null,
      rawReportValue.gcsProgram || null,
      rawReportValue.commSystem || null,
      rawReportValue.reporterNarrative || null,
      rawReportValue.flightRule || null,
      rawReportValue.timeOccurance || null,
      rawReportValue.gufi || null,
      Metadata.fromValue(rawReportValue),
    );
  }

  toValue(): any {
    return {
      fullName: this.fullName,
      email: this.email,
      phoneNumber: this.phoneNumber,
      roleResponsibility: this.roleResponsibility,
      reportReason: this.reportReason,
      scriptedEvent: this.scriptedEvent,
      contingencyId: this.contingencyId,
      autopilotMaker: this.autopilotMaker,
      autopilotModel: this.autopilotModel,
      gcsProgram: this.gcsProgram,
      commSystem: this.commSystem,
      reporterNarrative: this.reporterNarrative,
      flightRule: this.flightRule,
      timeOccurance: this.timeOccurance,
      gufi: this.gufi,
      metadata: this.metadata.toValue(),
    };
  }
}

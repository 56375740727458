import {Component, forwardRef, Input, OnDestroy} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import * as L from 'leaflet';
import {LatLng, LeafletEvent} from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import {LeafletDrawerService} from '@ax/ax-angular-map-leaflet';
import {Subscription} from 'rxjs';
import {LatLngPoint} from '../../../model/WaypointParser';
import * as _ from 'lodash';

@Component({
  selector: 'app-leaflet-point-creator',
  template: ``,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LeafletPointCreatorComponent),
    }
  ],
  standalone: true
})
export class LeafletPointCreatorComponent implements OnDestroy, ControlValueAccessor {
  @Input() name = '_Point';
  @Input() title = 'Point';
  @Input() controlClassName = '';
  @Input() icon: L.Icon = null;
  private map: L.Map;
  private onChange: (v: LatLngPoint | null) => void;
  private onTouched: () => void;
  private layerGroup: L.LayerGroup;
  private value: LatLngPoint | null;
  private cb: (e: (LeafletEvent & { shape: string })) => void;
  private leafletInitSub: Subscription;
  private layer: L.Marker;

  constructor(private leafletDrawerService: LeafletDrawerService) {
    this.layerGroup = L.layerGroup([]);

    this.leafletInitSub = this.leafletDrawerService.watchViewerInit().subscribe((mapy => {
      this.map = mapy;

      mapy.pm.Toolbar.copyDrawControl('Marker', {
        name: this.name, block: 'draw', title: this.title, className: this.controlClassName
      });
      mapy.pm.Draw[this.name].setPathOptions({markerStyle: {icon: this.icon}, limitMarkersToCount: 1});
      mapy.pm.Draw[this.name].options = _.cloneDeep(mapy.pm.Draw[this.name].options);
      mapy.pm.Draw[this.name].options.markerStyle.icon = this.icon;

      this.cb = (e: LeafletEvent & { shape: string }) => {
        if (e.shape !== this.name) {
          return;
        }
        this.setLayer(e.layer);


      };
      mapy.on('pm:create', this.cb);

      this.refreshLeaflet();
    }));

  }

  ngOnDestroy(): void {
    this.layerGroup.remove();
  }

  registerOnChange(fn: (v: LatLngPoint | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Method not implemented
  }

  writeValue(obj: LatLngPoint | null): void {
    this.layerGroup.clearLayers();
    this.value = obj;

    if (obj === null) {
      if (this.layer) {
        this.layer.remove();
      }
      this.layer = null;
      this.emitChange();
      return;
    }
    const newMarker = new L.Marker(new LatLng(obj.lat, obj.lng, obj.alt), {icon: this.icon});
    this.setLayer(newMarker);
    this.refreshLeaflet();
  }

  private emitChange() {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  private getPointGeometryFromMarker(layer: L.Marker): LatLngPoint {

    const ll = layer.getLatLng();
    return {
      lat: ll.lat,
      lng: ll.lng,
      alt: ll.alt || 0
    };
  }

  private refreshLeaflet() {
    if (!this.map) {
      return;
    }
    this.layerGroup.addTo(this.map);
    if (this.layer) {
      this.layer.addTo(this.layerGroup);
    }
  }

  private enablePmEdit(l: L.Marker, cb: (e: L.LeafletEvent, eventType: 'pm:update' | 'pm:dragend' | 'pm:remove') => void, enableIt = false) {
    if (enableIt) {
      l.pm.enable({
        allowSelfIntersection: false,
      });
    }
    l.on('pm:update', (e: L.LeafletEvent) => {
      cb(e, 'pm:update');
      this.touched();
    });
    l.on('pm:dragend', (e: L.LeafletEvent) => {
      cb(e, 'pm:dragend');
      this.touched();
    });
    l.on('pm:remove', (e: L.LeafletEvent) => {
      cb(e, 'pm:remove');
      this.touched();
    });
  }

  private touched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private setLayer(layer: L.Marker) {
    if (this.layer && layer !== this.layer) {
      this.layer.remove();
    }
    this.layer = layer;
    this.value = this.getPointGeometryFromMarker(this.layer);
    this.enablePmEdit(layer, (e, eventType) => {
      if (eventType === 'pm:remove') {
        this.value = null;
        this.emitChange();
        return;
      }
      const newPoint = this.getPointGeometryFromMarker(layer);
      if (_.isEqual(this.value, newPoint)) {
        return;
      }
      this.value = newPoint;
      this.emitChange();
    });

    this.emitChange();
  }
}

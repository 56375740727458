import {Injectable} from '@angular/core';
import {
  OperationDraft,
  OperationDraftResponse,
  OperationDraftSearchParameters,
  OperationDraftSearchResults,
  OperationDraftService
} from '../operation-draft.service';
import {HttpClient} from '@angular/common/http';
import {concat, Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {catchError, map, mergeMap, reduce} from 'rxjs/operators';
import {IOperationSubmission, TransportOperationSubmission} from '../../model/OperationSubmission';
import * as _ from 'lodash';
import {UTMSerializer} from '../../model/UTMSerializer';

export interface TransportOperationDraft{
  id: string;
  user_id: string;
  submission: Partial<TransportOperationSubmission>;
}

export interface TransportOperationDraftSearchResults{
  offset: number;
  count: number;
  drafts: TransportOperationDraft[];
}

@Injectable({
  providedIn: 'root'
})
export class RestOperationDraftService extends OperationDraftService {
  baseOperationDraftsUrl = `${environment.baseUrl}/operator/v2/operations/drafts`;
  operationDraftsSearchUrl = `${environment.baseUrl}/operator/v2/operations/drafts/search`;

  constructor(private http: HttpClient) {
    super();
  }

  deleteDraft(draftId: string): Observable<boolean> {
    return this.http.delete(this.baseOperationDraftsUrl + '/' + draftId)
      .pipe(catchError(() => of({success: false})), map((res: OperationDraftResponse) => res.success));
  }

  getDraftFromUUID(draftId: string): Observable<OperationDraft | null> {
    return this.http.get(this.baseOperationDraftsUrl + '/' + draftId)
      .pipe(catchError(() => of(null)), map((res: TransportOperationDraft) => {
      if (!res){
        return null;
      }
      return {
        id: res.id,
        user_id: res.user_id,
        submission: UTMSerializer.deserializePartialOperationSubmission(res.submission)
      };
    }));
  }

  _getDrafts(searchParameters: OperationDraftSearchParameters): Observable<OperationDraftSearchResults>{
    return this.http.post(this.operationDraftsSearchUrl, searchParameters).pipe(map((res: TransportOperationDraftSearchResults) => {
      const ret: OperationDraftSearchResults = {
        count: res.count,
        offset: res.offset,
        drafts: (res.drafts || []).map((draft) => ({
            id: draft.id,
            user_id: draft.user_id,
            submission: UTMSerializer.deserializePartialOperationSubmission(draft.submission)
          }))
      };
      return ret;
    }));
  }
  getDrafts(): Observable<OperationDraft[]> {
    const stepSize = 100;
    const initialSearch = _.cloneDeep(OperationDraftSearchParameters.defaultSearch());
    initialSearch.limit = stepSize;
    initialSearch.offset = 0;
    initialSearch.fetchCount = true;

    return this._getDrafts(initialSearch).pipe(
      mergeMap(res => {
        const obs: Observable<OperationDraftSearchResults>[] = [of(res)];

        if (res.drafts.length < res.count) {
          const baseSearch = _.cloneDeep(initialSearch);
          baseSearch.fetchCount = false;

          for (let i = res.offset + res.drafts.length; i < res.count; i += stepSize) {
            const thisSearch = _.cloneDeep(baseSearch);
            thisSearch.offset = i;
            obs.push(this._getDrafts(thisSearch));
          }
        }
        return concat(...obs).pipe(
          reduce((all, res) => all.concat([res]), [])
        );
      })
    ).pipe(
      map((results: OperationDraftSearchResults[]) => _.flatten(results.map(r => r.drafts)))
    );
  }

  saveDraftOperation(draftId: string|null, draft: Partial<IOperationSubmission>): Observable<OperationDraftResponse> {
    const transportDraft = UTMSerializer.serializePartialOperationSubmission(draft);
    if (draftId){
      return this.http.put(this.baseOperationDraftsUrl + '/' + draftId, transportDraft)
        .pipe(catchError(() => of({success: false})), map((res: OperationDraftResponse) => res));
    }else{
      return this.http.post(this.baseOperationDraftsUrl, transportDraft)
        .pipe(catchError(() => of({success: false})), map((res: OperationDraftResponse) => res));
    }
  }

}

import {Component, effect, forwardRef, input} from '@angular/core';
import {IControlValueAccessor} from '@rxweb/types';
import {SelectOption} from '../../select-option';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-label-select',
  templateUrl: './label-select.component.html',
  styleUrls: ['./label-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LabelSelectComponent),
    }
  ]
})
export class LabelSelectComponent implements IControlValueAccessor<string[]> {
  options = input.required<SelectOption[]>();
  prettyName = input.required<string>();
  required = input<boolean>(false);
  helperText = input<string>();

  optionLabels: {};
  value: string[] = [];
  unusedOptions: SelectOption[] = [];

  private onChange: (value: string[]) => void;

  constructor() {
    // When this.options() changes, update the option labels and available options accordingly
    effect(() => {
      this.optionLabels = {};
      for (const option of this.options()) {
        this.optionLabels[option.value] = option.label;
      }

      this.unusedOptions = this.options().filter(opt => !(this.value.includes(opt.value) || opt.hidden));
    });
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  setDisabledState(isDisabled: boolean): void {
    // Method not implemented
  }

  writeValue(obj: string[]): void {
    this.value = obj;

    this.unusedOptions = this.options().filter(opt => !(this.value.includes(opt.value) || opt.hidden));
  }

  unselect(v: string, index: number) {
    let availableOption: SelectOption = this.options().find(opt => opt.value === v);
    if (!availableOption) {
      availableOption = {label: v, value: v, hidden: false};
    }
    this.unusedOptions.push(availableOption);
    this.value.splice(index, 1);
    this.emitChange();
  }

  select(f: SelectOption, index: number) {
    this.unusedOptions.splice(index, 1);
    this.value.push(f.value);
    this.emitChange();
  }

  emitChange() {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }
}

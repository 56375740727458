<clr-modal *ngIf="!!user" [clrModalOpen]="!!user" (clrModalOpenChange)="clearUser()">
  <h3 class="modal-title">{{requestStatus.modalHeading}}</h3>
  <div class="modal-body">
    <ng-container [ngSwitch]="requestStatus.state">
      <ng-container *ngSwitchCase="submissionStateEnum.PROCESSING">
        <span class="spinner spinner-inline"> {{requestStatus.summary}}</span>
        <span> {{requestStatus.summary}}</span>
      </ng-container>
      <clr-alerts *ngSwitchCase="submissionStateEnum.ERROR">
        <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
          <clr-alert-item>
            <span class="alert-text">Error: {{requestStatus.summary}}</span>
          </clr-alert-item>
        </clr-alert>
      </clr-alerts>
      <p *ngSwitchDefault>{{requestStatus.summary}}</p>
    </ng-container>
  </div>
  <div class="modal-footer" *ngIf="requestStatus.state === submissionStateEnum.NONE">
    <button type="button" class="btn btn-primary" (click)="clearUser()">Cancel</button>
    <button type="button" class="btn btn-primary btn-danger" (click)="confirmRemoveUser()">Delete</button>
  </div>
</clr-modal>

import {BaseModel} from '../gen/utm/base-model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OrganizationUtil} from '../../utils/OrganizationUtil';

export interface SwaggerAdditionalConstraintData {
  user: string;
  user_id: string;
  category: string;
  division: string;
  organization: string;
  security_tags: Array<string>;
  constraint_type?: string;
}

export interface IAdditionalConstraintData {
  user: string;
  user_id: string;
  category: string;
  division: string;
  organization: string;
  security_tags: Array<string>;
  constraint_type?: string;
}

export class AdditionalConstraintData extends BaseModel implements IAdditionalConstraintData {
  /* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
  /* eslint-disable max-len */
  static USER_FIELD_NAME = 'user';
  static USERID_FIELD_NAME = 'user_id';
  static CATEGORY_FIELD_NAME = 'category';
  static DIVISION_FIELD_NAME = 'division';
  static ORGANIZATION_FIELD_NAME = 'organization';
  static SECURITYTAGS_FIELD_NAME = 'security_tags';
  static CONSTRAINT_TYPE_FIELD_NAME = 'constraint_type';

  user: string;
  user_id: string;
  category: string;
  division: string;
  organization: string;
  security_tags: Array<string>;
  constraint_type?: string;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IAdditionalConstraintData>, useFormGroupValuesToModel = false) {
    super();
    this.security_tags = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }


  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IAdditionalConstraintData>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.user = this.getValue<string>(rawValues, AdditionalConstraintData.USER_FIELD_NAME);
      this.user_id = this.getValue<string>(rawValues, AdditionalConstraintData.USERID_FIELD_NAME);
      this.category = this.getValue<string>(rawValues, AdditionalConstraintData.CATEGORY_FIELD_NAME);
      this.division = this.getValue<string>(rawValues, AdditionalConstraintData.DIVISION_FIELD_NAME);
      this.organization = this.getValue<string>(rawValues, AdditionalConstraintData.ORGANIZATION_FIELD_NAME);
      this.fillModelArray<string>(this, AdditionalConstraintData.SECURITYTAGS_FIELD_NAME, rawValues.security_tags, useFormGroupValuesToModel);
      this.constraint_type = this.getValue<string>(rawValues, AdditionalConstraintData.CONSTRAINT_TYPE_FIELD_NAME);

      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        user: new UntypedFormControl(this.user, [Validators.required]),
        user_id: new UntypedFormControl(this.user_id, [Validators.required]),
        category: new UntypedFormControl(this.category, [Validators.required]),
        division: new UntypedFormControl(this.division, [Validators.required]),
        organization: new UntypedFormControl(this.organization, [Validators.required]),
        security_tags: new UntypedFormControl(this.security_tags, [Validators.required]),
        constraint_type: new UntypedFormControl(this.constraint_type, []),
      });
      // generate FormArray control elements
      this.fillFormArray<string>(AdditionalConstraintData.SECURITYTAGS_FIELD_NAME, this.security_tags);
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[AdditionalConstraintData.USER_FIELD_NAME].setValue(this.user);
    this.$formGroup.controls[AdditionalConstraintData.USERID_FIELD_NAME].setValue(this.user_id);
    this.$formGroup.controls[AdditionalConstraintData.CATEGORY_FIELD_NAME].setValue(this.category);
    this.$formGroup.controls[AdditionalConstraintData.DIVISION_FIELD_NAME].setValue(this.division);
    this.$formGroup.controls[AdditionalConstraintData.ORGANIZATION_FIELD_NAME].setValue(this.organization);
    this.fillFormArray<string>(AdditionalConstraintData.SECURITYTAGS_FIELD_NAME, this.security_tags);
    this.$formGroup.controls[AdditionalConstraintData.CONSTRAINT_TYPE_FIELD_NAME].setValue(this.constraint_type);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  getPrettyOrganizationName(): string {
    return OrganizationUtil.getPrettyOrganizationName(this.organization);
  }
}

import {integer} from '../DataTypes';

export interface TransportRigitechTelemetryIntegration {
  rigitechDroneId: integer;
  rigitechSerialNumber: string;
}

export interface IRigitechTelemetryIntegration {
  rigitechDroneId: integer;
  rigitechSerialNumber: string;
}

/**
 * Rigitech telemetry integration
 * @param rigitechDroneId Rigitech drone ID. Required if missing rigitechSerialNumber.
 * @param rigitechSerialNumber Rigitech serial number. Required if missing rigitechDroneId.
 */
export class RigitechTelemetryIntegration implements IRigitechTelemetryIntegration {
  rigitechDroneId: integer;
  rigitechSerialNumber: string;

  constructor(values: RigitechTelemetryIntegration) {
    this.rigitechDroneId = values.rigitechDroneId;
    this.rigitechSerialNumber = values.rigitechSerialNumber;
  }
}

import {Component, forwardRef} from '@angular/core';
import {IControlValueAccessor} from '@rxweb/types';
import {IAdditionalRegistration} from '../../model/AdditionalRegistration';
import {AbstractControl, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn} from '@angular/forms';


export function AdditionalRegistrationInputValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const ret: ValidationErrors = {};
    const value = control.value as IAdditionalRegistration[];

    if (!value) {
      ret.empty = 'Empty value';
      return ret;
    }


    return ret;
  };
}

@Component({
  selector: 'app-additional-registration-input',
  templateUrl: './additional-registration-input.component.html',
  styleUrls: ['./additional-registration-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AdditionalRegistrationInputComponent),
    }
  ]
})
export class AdditionalRegistrationInputComponent implements IControlValueAccessor<IAdditionalRegistration[]> {
  registrations: IAdditionalRegistration[] = [];
  private onChange: (value: IAdditionalRegistration[]) => void;
  private onTouched: () => void;
  private isDisabled: boolean;


  constructor() {
    // Method not implemented
  }

  registerOnChange(fn: (value: IAdditionalRegistration[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: IAdditionalRegistration[]): void {

    this.registrations = JSON.parse(JSON.stringify(obj || []));
  }

  updateRegistrar(registration: IAdditionalRegistration, $event: string) {
    registration.registrar = $event;
    this.emitChange();
  }

  updateRegistrationId(registration: IAdditionalRegistration, $event: string) {
    registration.registrationId = $event;
    this.emitChange();
  }

  private emitChange() {

    if (this.onChange) {
      this.onChange(this.registrations);
    }
  }

  removeRegistration(registration: IAdditionalRegistration, i: number) {
    this.registrations.splice(i, 1);
    this.emitChange();
  }

  createRegistration() {
    this.registrations.push({registrar: '', registrationId: ''});
    this.emitChange();
  }
}

export interface IStandardResponse {
  success: boolean;
  message: string;
}

export class StandardResponse implements IStandardResponse {
  success: boolean;
  message: string;
  constructor(standardResponse: IStandardResponse) {
    this.success = standardResponse.success;
    this.message = standardResponse.message;
  }
}

import {Component, Input} from '@angular/core';
import {UvrExt} from '../../../model/utm/UvrExt';
import {Observable} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {AdditionalConstraintData} from '../../../model/utm/AdditionalConstraintData';
import {ConstraintTypeService} from '../../../services/constraint-type.service';

@Component({
  selector: 'app-constraint-summary',
  templateUrl: './constraint-summary.component.html',
  styleUrls: ['./constraint-summary.component.scss']
})
export class ConstraintSummaryComponent {
  @Input() constraint: UvrExt;

  constructor(private constraintTypeService: ConstraintTypeService) {
  }

  getPermittedOperations(additionalConstraintData: AdditionalConstraintData): Observable<string> {
    if (
      additionalConstraintData && additionalConstraintData.constraint_type) {
      return this.constraintTypeService.getPrettyName(additionalConstraintData.constraint_type);
    } else {
      return of('');
    }
  }
}

import {AxPermissionRequest} from './AxPermissionRequest';

export class PermissionParser{
  public static parseAxPermissionRequest(val: string): AxPermissionRequest<any> {
    const ret = new AxPermissionRequest<any>();
    const [action, resourceType, ...misc] = val.split('_');
    ret.setAction(action);
    ret.setResourceType(resourceType);

    return ret;
  }
}

import {
  Altitude,
  cause,
  contingency_cause,
  contingency_location_description,
  contingency_response,
  event_source,
  faa_rule,
  GeoCircle,
  IAltitude,
  IContingencyPlan,
  IEventMetadata,
  IGeoCircle,
  IPoint,
  IPolygon,
  IPriorityElements,
  IUvrVolumeSubmission,
  OperationActivationPolicy,
  permitted_uas,
  Point,
  point_type,
  Polygon,
  polygon_type,
  priority_status,
  required_support,
  setting,
  severity,
  source,
  state,
  test_type,
  uasvolumereservation_type,
  units_of_measure,
  vertical_reference,
  volume_type
} from './gen/utm';
import {
  IAltitude as TransportIAltitude,
  IContingencyPlan as TransportIContingencyPlan,
  IEventMetadata as TransportIEventMetadata,
  IOperation,
  IOperation as TransportIOperation,
  IOperationVolume as TransportIOperationVolume,
  IOperationVolumeSubmission as TransportIOperationVolumeSubmission,
  IPoint as TransportIPoint,
  IPolygon as TransportIPolygon,
  IPriorityElements as TransportIPriorityElements,
  ISeverity as TransportISeverity,
  IUASVolumeReservation as TransportIUASVolumeReservation,
  IUvrVolumeSubmission as TransportUvrVolumesubmission,
  TransportUnitsOfMeasure
} from './gen/transport/utm';
import {IOperationSubmission, IOperationVolumeSubmission, TransportOperationSubmission} from './OperationSubmission';
import {ConstraintSubmission, TransportConstraintSubmission} from './ConstraintSubmission';
import {IFederationPolicy, TransportFederationPolicy} from './FederationPolicy';
import {DateTime} from 'luxon';
import * as _ from 'lodash';
import {IOperationModification, TransportOperationModification} from './OperationModification';
import {IOperationSearchParameters} from '../services/operation.service';
import {Volume4dQueryUtil} from '../models/Volume4dQuery';
import {UserSubmission} from './UserSubmission';
import {Contact} from './Contact';
import {TransportGeoCircle} from "./gen/transport/utm/model/circle";
import {DEFAULT_OPERATION_ACTIVATION} from "../constants";
import TransportConstraintTypeEnum = TransportIUASVolumeReservation.TypeEnum;
import TransportConstraintCauseEnum = TransportIUASVolumeReservation.CauseEnum;
import TransportConstraintRequiredSupportEnum = TransportIUASVolumeReservation.RequiredSupportEnum;
import TransportConstraintPermittedUasEnum = TransportIUASVolumeReservation.PermittedUasEnum;

export class UTMSerializer {

  public static serializeAltitude(altitude: IAltitude): TransportIAltitude {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      altitude_value: altitude.altitude_value,
      source: altitude.source ? UTMSerializer.serializeSource(altitude.source) : undefined,
      units_of_measure: UTMSerializer.serializeUnitsOfMeasure(altitude.units_of_measure),
      vertical_reference: UTMSerializer.serializeVerticalReference(altitude.vertical_reference)
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  public static serializePolygon(polygon: IPolygon): TransportIPolygon {
    return {
      type: UTMSerializer.serializePolygonType(polygon.type),
      coordinates: polygon.coordinates
    };
  }

  public static serializeCircle(circle: IGeoCircle): TransportGeoCircle {
    return {
      latitude: circle.latitude,
      longitude: circle.longitude,
      radius: circle.radius,
      units_of_measure: this.serializeUnitsOfMeasure(circle.units)
    };
  }

  public static serializePoint(ipoint: IPoint): TransportIPoint {
    if ((ipoint?.coordinates?.length || 0) === 0 || ipoint?.coordinates.findIndex(c => _.isNil(c)) >= 0){
      return undefined;
    }
    return {
      type: UTMSerializer.serializePointType(ipoint.type),
      coordinates: ipoint.coordinates
    };
  }

  public static serializePartialOperationSubmission(opSub: Partial<IOperationSubmission>): TransportOperationSubmission {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      permitted_constraint_types: opSub?.permitted_constraint_types || [],
      aircraft_comments: opSub?.aircraft_comments,
      airspace_authorization: opSub?.airspace_authorization,
      contact: opSub?.contact,
      // contingency_plans: (opSub.contingency_plans || []).map(UTMSerializer.serializeContingencyPlan),
      controller_location: opSub?.controller_location ? UTMSerializer.serializePoint(opSub?.controller_location) : undefined,
      takeoff_location: opSub?.takeoff_location ? UTMSerializer.serializePoint(opSub?.takeoff_location) : undefined,
      faa_rule: opSub?.faa_rule ? UTMSerializer.serializeFaaRule(opSub?.faa_rule) : undefined,
      flight_comments: opSub?.flight_comments,
      flight_number: opSub?.flight_number,
      gcs_location: opSub?.gcs_location ? UTMSerializer.serializePoint(opSub?.gcs_location) : undefined,
      operation_type: opSub?.operation_type ? UTMSerializer.serializePermittedUas(opSub?.operation_type) : undefined,
      altitude_vertical_reference: opSub?.altitude_vertical_reference ?
        UTMSerializer.serializeVerticalReference(opSub.altitude_vertical_reference) : undefined,
      altitude_units: opSub?.altitude_units_of_measure ?
        UTMSerializer.serializeUnitsOfMeasure(opSub.altitude_units_of_measure) : undefined,
      volumes: opSub?.volumes ?
        (opSub?.volumes || []).map(opVol => UTMSerializer.serializePartialOperationVolume(opVol)) : undefined,
      priority_elements: opSub?.priority_elements ? UTMSerializer.serializePriorityElements(opSub?.priority_elements) : undefined,
      public_access: opSub?.public_access,
      // state: UTMSerializer.serializeState(opSub?.state),
      uas_registrations: opSub?.uas_registrations,
      volumes_description: opSub?.volumes_description,
      vlos: opSub?.vlos,
      federation_policy: opSub?.federation_policy ? UTMSerializer.serializeFederationPolicy(opSub?.federation_policy) : undefined,
      contact_id: opSub?.contact_id,
      priority: opSub?.priority
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  public static deserializePartialOperationSubmission(opSub: Partial<TransportOperationSubmission>): Partial<IOperationSubmission> {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      permitted_constraint_types: opSub?.permitted_constraint_types || [],
      aircraft_comments: opSub?.aircraft_comments,
      airspace_authorization: opSub?.airspace_authorization,
      contact: opSub?.contact,
      contact_id: opSub.contact_id,
      // contingency_plans: (opSub.contingency_plans || []).map(UTMSerializer.serializeContingencyPlan),
      controller_location: opSub?.controller_location ? UTMSerializer.deserializePoint(opSub?.controller_location) : undefined,
      takeoff_location: opSub?.takeoff_location ? UTMSerializer.deserializePoint(opSub?.takeoff_location) : undefined,
      faa_rule: opSub?.faa_rule ? UTMSerializer.deserializeFaaRule(opSub?.faa_rule) : undefined,
      flight_comments: opSub?.flight_comments,
      flight_number: opSub?.flight_number,
      gcs_location: opSub?.gcs_location ? UTMSerializer.deserializePoint(opSub?.gcs_location) : undefined,
      operation_type: opSub?.operation_type ? UTMSerializer.deserializePermittedUas(opSub?.operation_type) : undefined,
      altitude_vertical_reference: opSub?.altitude_vertical_reference ?
        UTMSerializer.deserializeVerticalReference(opSub.altitude_vertical_reference) : undefined,
      altitude_units_of_measure: opSub?.altitude_units ?
        UTMSerializer.deserializeUnitsOfMeasure(opSub.altitude_units) : undefined,
      volumes: opSub?.volumes ?
        (opSub?.volumes || []).map(opVol => UTMSerializer.
        deserializePartialOperationVolume(opVol, opSub.altitude_vertical_reference, opSub.altitude_units)) : undefined,
      priority_elements: opSub?.priority_elements ? UTMSerializer.deserializePriorityElements(opSub?.priority_elements) : undefined,
      public_access: opSub?.public_access,
      // state: UTMSerializer.deserializeState(opSub?.state),
      uas_registrations: opSub?.uas_registrations,
      volumes_description: opSub?.volumes_description,
      vlos: opSub?.vlos,
      federation_policy: opSub?.federation_policy ? UTMSerializer.deserializeFederationPolicy(opSub?.federation_policy) : undefined,
      priority: opSub?.priority
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  public static serializeOperationSubmission(opSub: IOperationSubmission): TransportOperationSubmission {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      permitted_constraint_types: opSub.permitted_constraint_types || [],
      aircraft_comments: opSub.aircraft_comments,
      airspace_authorization: opSub.airspace_authorization,
      contact: opSub.contact,
      // contingency_plans: (opSub.contingency_plans || []).map(UTMSerializer.serializeContingencyPlan),
      controller_location: opSub.controller_location ? UTMSerializer.serializePoint(opSub.controller_location) : undefined,
      takeoff_location: opSub.takeoff_location ? UTMSerializer.serializePoint(opSub.takeoff_location) : undefined,
      faa_rule: UTMSerializer.serializeFaaRule(opSub.faa_rule),
      flight_comments: opSub.flight_comments,
      flight_number: opSub.flight_number,
      gcs_location: opSub.gcs_location ? UTMSerializer.serializePoint(opSub.gcs_location) : undefined,
      operation_type: UTMSerializer.serializePermittedUas(opSub.operation_type),
      altitude_vertical_reference: UTMSerializer.serializeVerticalReference(opSub.altitude_vertical_reference),
      altitude_units: UTMSerializer.serializeUnitsOfMeasure(opSub.altitude_units_of_measure),
      volumes: (opSub.volumes || []).map(opVol => UTMSerializer.serializeOperationVolume(opVol)),
      priority_elements: opSub.priority_elements ? UTMSerializer.serializePriorityElements(opSub.priority_elements) : undefined,
      public_access: opSub.public_access,
      // state: UTMSerializer.serializeState(opSub.state),
      uas_registrations: opSub.uas_registrations,
      volumes_description: opSub.volumes_description,
      vlos: opSub.vlos,
      federation_policy: UTMSerializer.serializeFederationPolicy(opSub.federation_policy),
      contact_id: opSub.contact_id,
      priority: opSub.priority,
      activation: UTMSerializer.serializeOperationActivationPolicy(opSub.activation),
      previous_intent_id: opSub.previous_intent_id,
      previous_entity_id: opSub.previous_entity_id
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  public static serializeOperationModification(opSub: IOperationModification): TransportOperationModification {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      altitude_vertical_reference: UTMSerializer.serializeVerticalReference(opSub.altitude_vertical_reference),
      altitude_units: UTMSerializer.serializeUnitsOfMeasure(opSub.altitude_units),
      volumes: (opSub.volumes || []).map(opVol => UTMSerializer.serializeOperationVolume(opVol)),
      controller_location: UTMSerializer.serializePoint(opSub.controller_location),
      takeoff_location: UTMSerializer.serializePoint(opSub.takeoff_location),
      previous_intent_id: opSub.previous_intent_id,
      previous_entity_id: opSub.previous_entity_id
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  public static serializeOperationSearchParameters(operationSearchParameters: IOperationSearchParameters) {
    return {
      name: operationSearchParameters.flight_number,
      description: operationSearchParameters.flight_comments,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      uss_name: operationSearchParameters.uss_name,
      managed: operationSearchParameters.managed,
      categories: operationSearchParameters.categories,
      scopes: operationSearchParameters.scopes,
      volume4d: Volume4dQueryUtil.serializeVolume4DQuery(operationSearchParameters.volume4d),
      registrationId: operationSearchParameters.registrationId,
      submitTimeAfter: operationSearchParameters.submitTimeAfter?.toUTC()?.toISO(),
      submitTimeBefore: operationSearchParameters.submitTimeBefore?.toUTC()?.toISO(),
      state: operationSearchParameters.state,
      sort: operationSearchParameters.sort,
      sortIncreasing: operationSearchParameters.sortIncreasing,
      limit: operationSearchParameters.limit,
      offset: operationSearchParameters.offset,
      fetchCount: operationSearchParameters.fetchCount,
    };
  }

  static serializeFaaRule(faaRule: faa_rule): TransportIOperation.FaaRuleEnum {
    switch (faaRule) {
      case faa_rule.PART_107:
        return TransportIOperation.FaaRuleEnum.PART107;
      case faa_rule.PART_107X:
        return TransportIOperation.FaaRuleEnum.PART107X;
      case faa_rule.PART_101E:
        return TransportIOperation.FaaRuleEnum.PART101E;
      case faa_rule.OTHER:
        return TransportIOperation.FaaRuleEnum.OTHER;
    }
  }

  static serializePermittedUas(permittedUas: permitted_uas): TransportIUASVolumeReservation.PermittedUasEnum {
    switch (permittedUas) {
      case permitted_uas.RECONNAISSANCE:
        return TransportIUASVolumeReservation.PermittedUasEnum.RECONNAISSANCE;
      case permitted_uas.TRAINING:
        return TransportIUASVolumeReservation.PermittedUasEnum.TRAINING;
      case permitted_uas.SEARCH_AND_RESCUE:
        return TransportIUASVolumeReservation.PermittedUasEnum.SEARCHANDRESCUE;
      case permitted_uas.RESUPPLY:
        return TransportIUASVolumeReservation.PermittedUasEnum.RESUPPLY;
      case permitted_uas.NOT_SET:
        return TransportIUASVolumeReservation.PermittedUasEnum.NOTSET;
      case permitted_uas.PUBLIC_SAFETY:
        return TransportIUASVolumeReservation.PermittedUasEnum.PUBLICSAFETY;
      case permitted_uas.SECURITY:
        return TransportIUASVolumeReservation.PermittedUasEnum.SECURITY;
      case permitted_uas.NEWS_GATHERING:
        return TransportIUASVolumeReservation.PermittedUasEnum.NEWSGATHERING;
      case permitted_uas.VLOS:
        return TransportIUASVolumeReservation.PermittedUasEnum.VLOS;
      case permitted_uas.SUPPORT_LEVEL:
        return TransportIUASVolumeReservation.PermittedUasEnum.SUPPORTLEVEL;
      case permitted_uas.PART_107:
        return TransportIUASVolumeReservation.PermittedUasEnum.PART107;
      case permitted_uas.PART_101E:
        return TransportIUASVolumeReservation.PermittedUasEnum.PART101E;
      case permitted_uas.PART_107X:
        return TransportIUASVolumeReservation.PermittedUasEnum.PART107X;
      case permitted_uas.RADIO_LINE_OF_SIGHT:
        return TransportIUASVolumeReservation.PermittedUasEnum.RADIOLINEOFSIGHT;
    }
  }

  static serializeOperationActivationPolicy(policy: OperationActivationPolicy): IOperation.OperationActivationPolicy {
    switch (policy) {
      case OperationActivationPolicy.MANUAL:
        return IOperation.OperationActivationPolicy.MANUAL;
      case OperationActivationPolicy.ON_OPERATION_START:
        return IOperation.OperationActivationPolicy.ON_OPERATION_START;
      case OperationActivationPolicy.ON_TELEMETRY_START:
        return IOperation.OperationActivationPolicy.ON_TELEMETRY_START;
      default:
        return IOperation.OperationActivationPolicy[DEFAULT_OPERATION_ACTIVATION];
    }
  }

  static serializeState(s: state): TransportIOperation.StateEnum {
    switch (s) {
      case state.PROPOSED:
        return TransportIOperation.StateEnum.PROPOSED;
      case state.ACCEPTED:
        return TransportIOperation.StateEnum.ACCEPTED;
      case state.ACTIVATED:
        return TransportIOperation.StateEnum.ACTIVATED;
      case state.CLOSED:
        return TransportIOperation.StateEnum.CLOSED;
      case state.NONCONFORMING:
        return TransportIOperation.StateEnum.NONCONFORMING;
      case state.ROGUE:
        return TransportIOperation.StateEnum.ROGUE;
      case state.REJECTED:
        return TransportIOperation.StateEnum.REJECTED;

    }
  }

  static serializePriorityStatus(priorityStatus: priority_status): TransportIPriorityElements.PriorityStatusEnum {
    switch (priorityStatus) {
      case priority_status.NONE:
        return TransportIPriorityElements.PriorityStatusEnum.NONE;
      case priority_status.PUBLIC_SAFETY:
        return TransportIPriorityElements.PriorityStatusEnum.PUBLICSAFETY;
      case priority_status.EMERGENCY_AIRBORNE_IMPACT:
        return TransportIPriorityElements.PriorityStatusEnum.EMERGENCYAIRBORNEIMPACT;
      case priority_status.EMERGENCY_GROUND_IMPACT:
        return TransportIPriorityElements.PriorityStatusEnum.EMERGENCYGROUNDIMPACT;
      case priority_status.EMERGENCY_AIR_AND_GROUND_IMPACT:
        return TransportIPriorityElements.PriorityStatusEnum.EMERGENCYAIRANDGROUNDIMPACT;

    }
  }

  static serializeContingencyResponse(contingencyResponse: contingency_response): TransportIContingencyPlan.ContingencyResponseEnum {
    switch (contingencyResponse) {
      case contingency_response.LANDING:
        return TransportIContingencyPlan.ContingencyResponseEnum.LANDING;
      case contingency_response.LOITERING:
        return TransportIContingencyPlan.ContingencyResponseEnum.LOITERING;
      case contingency_response.RETURN_TO_BASE:
        return TransportIContingencyPlan.ContingencyResponseEnum.RETURNTOBASE;
      case contingency_response.OTHER:
        return TransportIContingencyPlan.ContingencyResponseEnum.OTHER;
    }
  }

  static serializeVolumeType(volumeType: volume_type): TransportIOperationVolumeSubmission.VolumeTypeEnum {
    switch (volumeType) {
      case volume_type.TBOV:
        return TransportIOperationVolumeSubmission.VolumeTypeEnum.TBOV;
      case volume_type.ABOV:
        return TransportIOperationVolumeSubmission.VolumeTypeEnum.ABOV;
    }
  }

  static serializeSeverity(priorityLevel: severity): TransportISeverity {
    switch (priorityLevel) {
      case severity.EMERGENCY:
        return TransportISeverity.EMERGENCY;
      case severity.ALERT:
        return TransportISeverity.ALERT;
      case severity.CRITICAL:
        return TransportISeverity.CRITICAL;
      case severity.WARNING:
        return TransportISeverity.WARNING;
      case severity.NOTICE:
        return TransportISeverity.NOTICE;
      case severity.INFORMATIONAL:
        return TransportISeverity.INFORMATIONAL;
    }
  }

  static serializeEventMetadataTestType(testType: test_type): TransportIEventMetadata.TestTypeEnum {
    switch (testType) {
      case test_type.GROUND:
        return TransportIEventMetadata.TestTypeEnum.GROUND;
      case test_type.FLIGHT:
        return TransportIEventMetadata.TestTypeEnum.FLIGHT;
    }
  }

  static serializeSource(s: source): TransportIAltitude.SourceEnum {
    switch (s) {
      case source.ONBOARD_SENSOR:
        return TransportIAltitude.SourceEnum.ONBOARDSENSOR;
      case source.OTHER:
        return TransportIAltitude.SourceEnum.OTHER;

    }
  }

  static serializeUnitsOfMeasure(unitsOfMeasure: units_of_measure): TransportUnitsOfMeasure.LengthUnits {
    switch (unitsOfMeasure) {
      case units_of_measure.FT:
        return TransportUnitsOfMeasure.LengthUnits.FT;
      case units_of_measure.M:
        return TransportUnitsOfMeasure.LengthUnits.M;
      case units_of_measure.MI:
        return TransportUnitsOfMeasure.LengthUnits.MILE;
      case units_of_measure.NM:
        return TransportUnitsOfMeasure.LengthUnits.NM;
    }
  }

  static serializeVerticalReference(verticalReference: vertical_reference): TransportIAltitude.VerticalReferenceEnum {
    switch (verticalReference) {
      case vertical_reference.W84:
        return TransportIAltitude.VerticalReferenceEnum.W84;
      case vertical_reference.MSL:
        return TransportIAltitude.VerticalReferenceEnum.MSL;
      case vertical_reference.AGL:
        return TransportIAltitude.VerticalReferenceEnum.AGL;
    }
  }

  static serializePolygonType(polygonType: polygon_type): TransportIPolygon.TypeEnum {
    if (polygonType === polygon_type.Polygon ) {
      return TransportIPolygon.TypeEnum.Polygon;
    }
  }

  static serializePointType(pointType: point_type): TransportIPoint.TypeEnum {
    if (pointType === point_type.Point) {
      return TransportIPoint.TypeEnum.Point;
    }
  }

  static serializeContingencyCause(contingencyCause: contingency_cause): TransportIContingencyPlan.ContingencyCauseEnum {
    switch (contingencyCause) {
      case contingency_cause.LOST_C2_UPLINK:
        return TransportIContingencyPlan.ContingencyCauseEnum.LOSTC2UPLINK;
      case contingency_cause.LOST_C2_DOWNLINK:
        return TransportIContingencyPlan.ContingencyCauseEnum.LOSTC2DOWNLINK;
      case contingency_cause.LOST_NAV:
        return TransportIContingencyPlan.ContingencyCauseEnum.LOSTNAV;
      case contingency_cause.LOST_SAA:
        return TransportIContingencyPlan.ContingencyCauseEnum.LOSTSAA;
      case contingency_cause.LOW_FUEL:
        return TransportIContingencyPlan.ContingencyCauseEnum.LOWFUEL;
      case contingency_cause.NO_FUEL:
        return TransportIContingencyPlan.ContingencyCauseEnum.NOFUEL;
      case contingency_cause.MECHANICAL_PROBLEM:
        return TransportIContingencyPlan.ContingencyCauseEnum.MECHANICALPROBLEM;
      case contingency_cause.SOFTWARE_PROBLEM:
        return TransportIContingencyPlan.ContingencyCauseEnum.SOFTWAREPROBLEM;
      case contingency_cause.ENVIRONMENTAL:
        return TransportIContingencyPlan.ContingencyCauseEnum.ENVIRONMENTAL;
      case contingency_cause.SECURITY:
        return TransportIContingencyPlan.ContingencyCauseEnum.SECURITY;
      case contingency_cause.TRAFFIC:
        return TransportIContingencyPlan.ContingencyCauseEnum.TRAFFIC;
      case contingency_cause.LOST_USS:
        return TransportIContingencyPlan.ContingencyCauseEnum.LOSTUSS;
      case contingency_cause.OTHER:
        return TransportIContingencyPlan.ContingencyCauseEnum.OTHER;
      case contingency_cause.ANY:
        return TransportIContingencyPlan.ContingencyCauseEnum.ANY;
    }
  }

  // eslint-disable-next-line max-len
  static serializeContingencyLocationDescription(contingencyLocationDescription: contingency_location_description):
    TransportIContingencyPlan.ContingencyLocationDescriptionEnum {
    switch (contingencyLocationDescription) {
      case contingency_location_description.PREPROGRAMMED:
        return TransportIContingencyPlan.ContingencyLocationDescriptionEnum.PREPROGRAMMED;
      case contingency_location_description.OPERATOR_UPDATED:
        return TransportIContingencyPlan.ContingencyLocationDescriptionEnum.OPERATORUPDATED;
      case contingency_location_description.UA_IDENTIFIED:
        return TransportIContingencyPlan.ContingencyLocationDescriptionEnum.UAIDENTIFIED;
      case contingency_location_description.OTHER:
        return TransportIContingencyPlan.ContingencyLocationDescriptionEnum.OTHER;
    }
  }

  static serializeEventMetadataSetting(eventMetadataSetting: setting): TransportIEventMetadata.SettingEnum {
    switch (eventMetadataSetting) {
      case setting.LAB:
        return TransportIEventMetadata.SettingEnum.LAB;
      case setting.FIELD:
        return TransportIEventMetadata.SettingEnum.FIELD;
    }
  }

  static serializeEventMetadataSource(eventMetadataSource: event_source): TransportIEventMetadata.SourceEnum {
    switch (eventMetadataSource) {
      case event_source.HWITL:
        return TransportIEventMetadata.SourceEnum.HWITL;
      case event_source.SWITL:
        return TransportIEventMetadata.SourceEnum.SWITL;
    }
  }

  static serializeConstraintSubmission(constraint: ConstraintSubmission): TransportConstraintSubmission {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      type: constraint.type, // UTMSerializer.serializeConstraintTypeEnum(constraint.type),
      permitted_uas: (constraint.permitted_uas || []).map(UTMSerializer.serializeConstraintPermittedUasEnum),
      required_support: (constraint.required_support || []).map(UTMSerializer.serializeConstraintRequiredSupportEnum),
      permitted_gufis: constraint.permitted_gufis,
      cause: UTMSerializer.serializeConstraintCauseEnum(constraint.cause),
      altitude_vertical_reference: UTMSerializer.serializeVerticalReference(constraint.altitude_vertical_reference),
      altitude_units: UTMSerializer.serializeUnitsOfMeasure(constraint.altitude_units_of_measure),
      volumes: (constraint.volumes || []).map(volume => UTMSerializer.serializeConstraintVolume(volume)),
      reason: constraint.reason,
      constraint_type: constraint.constraint_type
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  static serializeContingencyPlan(contingencyplan: IContingencyPlan): TransportIContingencyPlan {
    const ret: TransportIContingencyPlan = {
      /* eslint-disable @typescript-eslint/naming-convention */
      contingency_cause: (contingencyplan.contingency_cause || []).map(c => UTMSerializer.serializeContingencyCause(c)),
      contingency_id: contingencyplan.contingency_id,
      contingency_location_description: UTMSerializer.serializeContingencyLocationDescription(contingencyplan.contingency_location_description),
      contingency_polygon: UTMSerializer.serializePolygon(contingencyplan.contingency_polygon),
      contingency_response: UTMSerializer.serializeContingencyResponse(contingencyplan.contingency_response),
      free_text: contingencyplan.free_text,
      loiter_altitude: contingencyplan.loiter_altitude ? UTMSerializer.serializeAltitude(contingencyplan.loiter_altitude) : undefined,
      relative_preference: contingencyplan.relative_preference,
      relevant_operation_volumes: contingencyplan.relevant_operation_volumes,
      valid_time_begin: contingencyplan.valid_time_begin.toISO(),
      valid_time_end: contingencyplan.valid_time_end.toISO(),
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    (ret as any).cid = (contingencyplan as any).cid;
    return ret;
  }

  static serializeMetadata(metadata: IEventMetadata): TransportIEventMetadata {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      call_sign: metadata.call_sign,
      data_collection: metadata.data_collection,
      data_quality_engineer: metadata.data_quality_engineer,
      event_id: metadata.event_id,
      free_text: metadata.free_text,
      location: metadata.location,
      modified: metadata.modified,
      scenario: metadata.scenario,
      setting: UTMSerializer.serializeEventMetadataSetting(metadata.setting),
      source: UTMSerializer.serializeEventMetadataSource(metadata.source),
      test_card: metadata.test_card,
      test_run: metadata.test_run,
      test_type: UTMSerializer.serializeEventMetadataTestType(metadata.test_type),
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  static serializePriorityElements(priorityElements: IPriorityElements): TransportIPriorityElements {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      priority_level: UTMSerializer.serializeSeverity(priorityElements.priority_level),
      priority_status: UTMSerializer.serializePriorityStatus(priorityElements.priority_status)
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  static serializePartialOperationVolume(operationVolume: IOperationVolumeSubmission): TransportIOperationVolumeSubmission {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      beyond_visual_line_of_sight: operationVolume.beyond_visual_line_of_sight,
      effective_time_begin: operationVolume.effective_time_begin?.toUTC().toISO(),
      effective_time_end: operationVolume.effective_time_end?.toUTC().toISO(),
      min_altitude: operationVolume.min_altitude,
      max_altitude: operationVolume.max_altitude,
      // altitude_vertical_reference: UTMSerializer.serializeVerticalReference(operationVolume.min_altitude.vertical_reference),
      // altitude_units: UTMSerializer.serializeUnitsOfMeasure(operationVolume.min_altitude.units_of_measure),
      near_structure: operationVolume.near_structure,
      geography: operationVolume.geography ? UTMSerializer.serializePolygon(operationVolume.geography) : undefined,
      circle: operationVolume.circle ?  UTMSerializer.serializeCircle(operationVolume.circle) : undefined,
      volume_type: UTMSerializer.serializeVolumeType(operationVolume.volume_type),
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  static serializeOperationVolume(operationVolume: IOperationVolumeSubmission): TransportIOperationVolumeSubmission {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      beyond_visual_line_of_sight: operationVolume.beyond_visual_line_of_sight,
      effective_time_begin: operationVolume.effective_time_begin?.toUTC().toISO(),
      effective_time_end: operationVolume.effective_time_end.toUTC().toISO(),
      min_altitude: operationVolume.min_altitude,
      max_altitude: operationVolume.max_altitude,
      // altitude_vertical_reference: UTMSerializer.serializeVerticalReference(operationVolume.min_altitude.vertical_reference),
      // altitude_units: UTMSerializer.serializeUnitsOfMeasure(operationVolume.min_altitude.units_of_measure),
      near_structure: operationVolume.near_structure,
      geography: operationVolume.geography ? UTMSerializer.serializePolygon(operationVolume.geography) : undefined,
      circle: operationVolume.circle ? UTMSerializer.serializeCircle(operationVolume.circle) : undefined,
      volume_type: UTMSerializer.serializeVolumeType(operationVolume.volume_type),
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  static serializeConstraintTypeEnum(t: uasvolumereservation_type): TransportConstraintTypeEnum {
    switch (t) {
      case uasvolumereservation_type.DYNAMIC_RESTRICTION:
        return TransportConstraintTypeEnum.DYNAMICRESTRICTION;
      case uasvolumereservation_type.STATIC_ADVISORY:
        return TransportConstraintTypeEnum.STATICADVISORY;
    }
  }

  static serializeConstraintPermittedUasEnum(permittedUas: permitted_uas): TransportConstraintPermittedUasEnum {

    switch (permittedUas) {
      case permitted_uas.NOT_SET:
        return TransportConstraintPermittedUasEnum.NOTSET;
      case permitted_uas.PUBLIC_SAFETY:
        return TransportConstraintPermittedUasEnum.PUBLICSAFETY;
      case permitted_uas.SECURITY:
        return TransportConstraintPermittedUasEnum.SECURITY;
      case permitted_uas.NEWS_GATHERING:
        return TransportConstraintPermittedUasEnum.NEWSGATHERING;
      case permitted_uas.VLOS:
        return TransportConstraintPermittedUasEnum.VLOS;
      case permitted_uas.SUPPORT_LEVEL:
        return TransportConstraintPermittedUasEnum.SUPPORTLEVEL;
      case permitted_uas.PART_107:
        return TransportConstraintPermittedUasEnum.PART107;
      case permitted_uas.PART_101E:
        return TransportConstraintPermittedUasEnum.PART101E;
      case permitted_uas.PART_107X:
        return TransportConstraintPermittedUasEnum.PART107X;
      case permitted_uas.RADIO_LINE_OF_SIGHT:
        return TransportConstraintPermittedUasEnum.RADIOLINEOFSIGHT;
      case permitted_uas.RESTRICTED:
        return TransportConstraintPermittedUasEnum.RESTRICTED;
      case permitted_uas.ADVISORY:
        return TransportConstraintPermittedUasEnum.ADVISORY;
      case permitted_uas.APPROVAL_REQUIRED:
        return TransportConstraintPermittedUasEnum.APPROVAL_REQUIRED;
    }

  }

  static serializeConstraintRequiredSupportEnum(requiredSupport: required_support): TransportConstraintRequiredSupportEnum {
    switch (requiredSupport) {
      case required_support.V2V:
        return TransportConstraintRequiredSupportEnum.V2V;
      case required_support.DAA:
        return TransportConstraintRequiredSupportEnum.DAA;
      case required_support.ADSB_OUT:
        return TransportConstraintRequiredSupportEnum.ADSBOUT;
      case required_support.ADSB_IN:
        return TransportConstraintRequiredSupportEnum.ADSBIN;
      case required_support.CONSPICUITY:
        return TransportConstraintRequiredSupportEnum.CONSPICUITY;
      case required_support.ENHANCED_NAVIGATION:
        return TransportConstraintRequiredSupportEnum.ENHANCEDNAVIGATION;
      case required_support.ENHANCED_SAFE_LANDING:
        return TransportConstraintRequiredSupportEnum.ENHANCEDSAFELANDING;
    }

  }

  static serializeConstraintCauseEnum(c: cause): TransportConstraintCauseEnum {
    switch (c) {
      case cause.WEATHER:
        return TransportConstraintCauseEnum.WEATHER;
      case cause.ATC:
        return TransportConstraintCauseEnum.ATC;
      case cause.SECURITY:
        return TransportConstraintCauseEnum.SECURITY;
      case cause.SAFETY:
        return TransportConstraintCauseEnum.SAFETY;
      case cause.MUNICIPALITY:
        return TransportConstraintCauseEnum.MUNICIPALITY;
      case cause.OTHER:
        return TransportConstraintCauseEnum.OTHER;
    }
  }

  static serializeConstraintVolume(volume: IUvrVolumeSubmission): TransportUvrVolumesubmission {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      effective_time_begin: volume.effective_time_begin?.toUTC().toISO(),
      effective_time_end: volume.effective_time_end.toUTC().toISO(),
      min_altitude: volume.min_altitude,
      max_altitude: volume.max_altitude,
      geography: volume.geography ? UTMSerializer.serializePolygon(volume.geography) : undefined,
      circle: volume.circle ? UTMSerializer.serializeCircle(volume.circle) : undefined
      /* eslint-enable @typescript-eslint/naming-convention */
    } as TransportUvrVolumesubmission;
  }

  static serializeFederationPolicy(policy: IFederationPolicy): TransportFederationPolicy {
    return {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      allow_all: policy.allowAll,
      domains: policy.domains
    };
  }

  static serializeUserSubmission(user: UserSubmission): UserSubmission {
    return {
      email: user.email || null,
      password: user.password || null,
      roles: user.roles || [],
      org: user.org || null,
      contact: UTMSerializer.serializeContactSubmission(user.contact)
    };
  }

  static serializeContactSubmission(contact: Contact): Contact {
    return new Contact({
      prefixName: contact.prefixName || null,
      firstName: contact.firstName || null,
      middleName: contact.middleName || null,
      lastName: contact.lastName || null,
      suffixName: contact.suffixName || null,
      division: contact.division || null,
      phones: contact.phones || [],
      emails: contact.emails || [],
      title: contact.title || null,
      hasPart107: contact.hasPart107 || false
    });
  }

  static deserializePoint(point: TransportIPoint | undefined): IPoint {
    return new Point({
      coordinates: point.coordinates
    });

  }

  static deserializeFaaRule(rule: TransportIOperation.FaaRuleEnum | undefined): faa_rule {
    switch (rule) {
      case 'PART_107':
        return faa_rule.PART_107;
      case 'PART_107X':
        return faa_rule.PART_107X;
      case 'PART_101E':
        return faa_rule.PART_101E;
      case 'OTHER':
        return faa_rule.OTHER;
      default:
        return undefined;
    }
  }

  static deserializeState(s: TransportIOperation.StateEnum | undefined): state {
    switch (s) {
      case 'PROPOSED':
        return state.PROPOSED;
      case 'ACCEPTED':
        return state.ACCEPTED;
      case 'ACTIVATED':
        return state.ACTIVATED;
      case 'CLOSED':
        return state.CLOSED;
      case 'NONCONFORMING':
        return state.NONCONFORMING;
      case 'ROGUE':
        return state.ROGUE;
      case 'REJECTED':
        return state.REJECTED;
      default:
        return undefined;

    }
  }

  static deserializeMetadata(metadata: TransportIEventMetadata | undefined): IEventMetadata {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      call_sign: metadata.call_sign,
      data_collection: metadata.data_collection,
      data_quality_engineer: metadata.data_quality_engineer,
      event_id: metadata.event_id,
      free_text: metadata.free_text,
      location: metadata.location,
      modified: metadata.modified,
      scenario: metadata.scenario,
      setting: UTMSerializer.deserializeSetting(metadata.setting),
      source: UTMSerializer.deserializeSource(metadata.source),
      test_type: UTMSerializer.deserializeTestType(metadata.test_type),
      test_card: metadata.test_card,
      test_run: metadata.test_run
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  static deserializePermittedUas(p: TransportIUASVolumeReservation.PermittedUasEnum | undefined): permitted_uas {
    switch (p) {
      case 'NOT_SET':
        return permitted_uas.NOT_SET;
      case 'PUBLIC_SAFETY':
        return permitted_uas.PUBLIC_SAFETY;
      case 'SECURITY':
        return permitted_uas.SECURITY;
      case 'NEWS_GATHERING':
        return permitted_uas.NEWS_GATHERING;
      case 'VLOS':
        return permitted_uas.VLOS;
      case 'SUPPORT_LEVEL':
        return permitted_uas.SUPPORT_LEVEL;
      case 'PART_107':
        return permitted_uas.PART_107;
      case 'PART_101E':
        return permitted_uas.PART_101E;
      case 'PART_107X':
        return permitted_uas.PART_107X;
      case 'RADIO_LINE_OF_SIGHT':
        return permitted_uas.RADIO_LINE_OF_SIGHT;
      case 'RECONNAISSANCE':
        return permitted_uas.RECONNAISSANCE;
      case 'TRAINING':
        return permitted_uas.TRAINING;
      case 'SEARCH_AND_RESCUE':
        return permitted_uas.SEARCH_AND_RESCUE;
      case 'RESUPPLY':
        return permitted_uas.RESUPPLY;
      default:
        return undefined;
    }

  }

  /* eslint-disable @typescript-eslint/naming-convention */
  // tslint:disable-next-line:variable-name
  static deserializePriorityElements(priority_elements: TransportIPriorityElements | undefined): IPriorityElements {
    if (!priority_elements) {
      return undefined;
    }
    return {
      priority_level: priority_elements.priority_level ? UTMSerializer.deserializePriorityLevel(priority_elements.priority_level) : undefined,
      priority_status: priority_elements.priority_status ? UTMSerializer.deserializePriorityStatus(priority_elements.priority_status) : undefined,
    };
  }
  /* eslint-enable @typescript-eslint/naming-convention */

  static deserializePartialOperationVolume(opVol: TransportIOperationVolumeSubmission, altRef?: TransportIAltitude.VerticalReferenceEnum,
                                           altUnits?: TransportUnitsOfMeasure.LengthUnits): IOperationVolumeSubmission {
    const minAltitude: TransportIAltitude = {
      altitude_value: opVol?.min_altitude,
      vertical_reference: altRef ? altRef : TransportIAltitude.VerticalReferenceEnum.W84,
      units_of_measure: altUnits ? altUnits : TransportUnitsOfMeasure.LengthUnits.FT
    };
    const maxAltitude: TransportIAltitude = {
      altitude_value: opVol?.max_altitude,
      vertical_reference: altRef ? altRef : TransportIAltitude.VerticalReferenceEnum.W84,
      units_of_measure: altUnits ? altUnits : TransportUnitsOfMeasure.LengthUnits.FT
    };
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      beyond_visual_line_of_sight: opVol?.beyond_visual_line_of_sight,
      effective_time_begin: opVol?.effective_time_begin ? DateTime.fromISO(opVol.effective_time_begin) : undefined,
      effective_time_end: opVol?.effective_time_end ? DateTime.fromISO(opVol.effective_time_end) : undefined,
      min_altitude: UTMSerializer.deserializeAltitude(minAltitude).altitude_value,
      max_altitude: UTMSerializer.deserializeAltitude(maxAltitude).altitude_value,
      near_structure: opVol?.near_structure,
      geography: opVol?.geography ? UTMSerializer.deserializeOperationGeography(opVol?.geography) : undefined,
      circle: opVol?.circle ? UTMSerializer.deserializeOperationCircle(opVol?.circle) : undefined,
      // Ordinal is not a member of the OperationVolumeSubmission back-end model
      volume_type: UTMSerializer.deserializeVolumeType(opVol?.volume_type),
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }

  /* eslint-disable @typescript-eslint/naming-convention */
  // tslint:disable-next-line:variable-name
  static deserializeFederationPolicy(federation_policy: TransportFederationPolicy | undefined): IFederationPolicy {
    /* eslint-enable @typescript-eslint/naming-convention */

    return {
      allowAll: federation_policy.allow_all,
      domains: federation_policy.domains
    };
  }

  static deserializeSetting(s: TransportIEventMetadata.SettingEnum): setting {
    switch (s) {
      case 'LAB':
        return setting.LAB;
      case 'FIELD':
        return setting.FIELD;
      default:
        return undefined;
    }
  }

  static deserializeSource(s: TransportIEventMetadata.SourceEnum): event_source {
    switch (s) {
      case 'HWITL':
        return event_source.HWITL;
      case 'SWITL':
        return event_source.SWITL;
      default:
        return undefined;
    }
  }

  static deserializeTestType(t: TransportIEventMetadata.TestTypeEnum): test_type {
    switch (t) {
      case 'GROUND':
        return test_type.GROUND;
      case 'FLIGHT':
        return test_type.FLIGHT;
      default:
        return undefined;
    }
  }

  static deserializePriorityLevel(l: TransportISeverity): severity {
    switch (l) {
      case 'EMERGENCY':
        return severity.EMERGENCY;
      case 'ALERT':
        return severity.ALERT;
      case 'CRITICAL':
        return severity.CRITICAL;
      case 'WARNING':
        return severity.WARNING;
      case 'NOTICE':
        return severity.NOTICE;
      case 'INFORMATIONAL':
        return severity.INFORMATIONAL;
      default:
        return undefined;
    }
  }

  static deserializePriorityStatus(s: TransportIPriorityElements.PriorityStatusEnum): priority_status {
    switch (s) {
      case 'NONE':
        return priority_status.NONE;
      case 'PUBLIC_SAFETY':
        return priority_status.PUBLIC_SAFETY;
      case 'EMERGENCY_AIRBORNE_IMPACT':
        return priority_status.EMERGENCY_AIRBORNE_IMPACT;
      case 'EMERGENCY_GROUND_IMPACT':
        return priority_status.EMERGENCY_GROUND_IMPACT;
      case 'EMERGENCY_AIR_AND_GROUND_IMPACT':
        return priority_status.EMERGENCY_AIR_AND_GROUND_IMPACT;
      default:
        return undefined;
    }
  }

  static deserializeVolumeType(v: TransportIOperationVolume.VolumeTypeEnum | undefined): volume_type {
    switch (v) {
      case 'TBOV':
        return volume_type.TBOV;
      case 'ABOV':
        return volume_type.ABOV;
      default:
        return undefined;
    }
  }

  static deserializeAltitude(alt: TransportIAltitude | undefined): IAltitude {
    return new Altitude({
      /* eslint-disable @typescript-eslint/naming-convention */      altitude_value: alt.altitude_value,
      vertical_reference: alt?.vertical_reference ? UTMSerializer.deserializeVerticalReference(alt.vertical_reference) : undefined,
      units_of_measure: alt?.units_of_measure ? UTMSerializer.deserializeUnitsOfMeasure(alt.units_of_measure) : undefined,
      source: alt?.source ? UTMSerializer.deserializeAltSource(alt.source) : undefined,
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  static deserializeVerticalReference(v: TransportIAltitude.VerticalReferenceEnum): vertical_reference {
    switch (v) {
      case 'MSL':
        return vertical_reference.MSL;
      case 'AGL':
        return vertical_reference.AGL;
      case 'W84':
      default:
        return vertical_reference.W84;
    }
  }

  static deserializeUnitsOfMeasure(u: TransportUnitsOfMeasure.LengthUnits): units_of_measure {
    switch (u) {
      case 'M':
        return units_of_measure.M;
      case 'FT':
      default:
        return units_of_measure.FT;
    }
  }

  static deserializeAltSource(s: TransportIAltitude.SourceEnum): source {
    switch (s) {
      case 'ONBOARD_SENSOR':
        return source.ONBOARD_SENSOR;
      case 'OTHER':
        return source.OTHER;
      default:
        return undefined;
    }
  }

  // tslint:disable-next-line:variable-name
  private static deserializeOperationGeography(operation_geography: TransportIPolygon | undefined): IPolygon {
    return new Polygon({
      coordinates: operation_geography.coordinates
    });
  }

  private static deserializeOperationCircle(circle: TransportGeoCircle | undefined): IGeoCircle {
    return new GeoCircle({
      latitude: circle.latitude,
      longitude: circle.longitude,
      radius: circle.radius,
      units: units_of_measure[circle.units_of_measure]
    });
  }
}

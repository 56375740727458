import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject, merge, Subscription} from 'rxjs';
import {ColorId, ColorService} from '../../../services/color.service';
import {ConstraintService} from '../../../services/constraint.service';
import {ConstraintState, UvrExt} from '../../../model/utm/UvrExt';
import {map} from 'rxjs/operators';
import {SmartChanges} from '../../../../../typing';

@Component({
  selector: 'app-constraint-state-badge',
  templateUrl: './constraint-state-badge.component.html',
  styleUrls: ['./constraint-state-badge.component.scss']
})
export class ConstraintStateBadgeComponent implements OnChanges, OnDestroy {
  @Input() constraint: UvrExt;
  @Input() watchState = true;
  @Input() state: ConstraintState;
  @Output() constraintEnded: EventEmitter<UvrExt> = new EventEmitter<UvrExt>();

  /* eslint-disable @typescript-eslint/naming-convention */
  ACCEPTED = ConstraintState.ACCEPTED;
  ACTIVE = ConstraintState.ACTIVE;
  ENDED = ConstraintState.ENDED;
  /* eslint-enable @typescript-eslint/naming-convention */
  acceptedStyle: any;
  activeStyle: any;
  endedStyle: any;
  styles: {};
  currentStyle: any;

  private readonly stateSubject: BehaviorSubject<ConstraintState>;
  private watchStateSub: Subscription;

  constructor(private constraintService: ConstraintService, private colorService: ColorService) {
    this.styles = {};

    this.styles[ConstraintState.ACCEPTED] = undefined;
    this.styles[ConstraintState.ACTIVE] = undefined;
    this.styles[ConstraintState.ENDED] = undefined;

    this.stateSubject = new BehaviorSubject<ConstraintState>(ConstraintState.ACCEPTED);

    this.stateSubject.subscribe(s => {
      this.state = s;
      if (s === ConstraintState.ENDED) {
        this.clearWatchStateSub();
        this.constraintEnded.emit(this.constraint);
      }
      if (!this.styles[s]) {
        let colorId: ColorId = 'constraint';
        switch (s) {
          case ConstraintState.ACCEPTED:
            colorId = 'constraint-accepted';
            break;
          case ConstraintState.ACTIVE:
            colorId = 'constraint-active';
            break;
          case ConstraintState.ENDED:
            colorId = 'constraint-ended';
            break;
        }

        this.colorService.getColorForId(colorId, true).subscribe(color => {
          this.styles[s] = {
            'background-color': color.fill.clone().brighten().lighten().toHexString(), // '#ffffff',
            color: color.fill.clone().darken().toHexString(),
            border: `${color.fill.toHexString()} .05rem solid`,
            // 'border-color': color.fill.toHexString(),
            'font-size': '13px'
          };
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.smartChanges(changes as SmartChanges<this>);
  }

  smartChanges(changes: SmartChanges<this>): void {
    if (changes.constraint) {
      this.stateSubject.next(this.constraint.getState());
    }
    if (changes.state) {
      this.stateSubject.next(this.state);
    }
    this.watchConstraintState();

  }

  ngOnDestroy(): void {
    this.clearWatchStateSub();
  }

  private watchConstraintState() {
    this.clearWatchStateSub();
    if (this.watchState && this.constraint && this.constraint.getState() !== ConstraintState.ENDED) {
      this.watchStateSub = merge(
        this.constraintService.watchConstraint(this.constraint.message_id),
        this.constraintService.pollConstraint(this.constraint.message_id, 5000, 100)
      ).pipe(map((constraint) => constraint.getState()))
        .subscribe(this.stateSubject);
    }
  }

  private clearWatchStateSub() {
    this.watchStateSub?.unsubscribe();
  }
}

import {Injectable, Type} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import {PermissionParser} from './PermissionParser';
import {PermissionService} from './service/permission.service';
import {Observable, of} from 'rxjs';
import {AxPermissionRequest} from './AxPermissionRequest';
import {mergeMap} from 'rxjs/operators';

export class PermissionGuardService {
  static forPermission(permission: string | AxPermissionRequest<any>): Type<{
    canActivate: CanActivateFn;
}> {
    const requiredPermission: AxPermissionRequest<any> = typeof permission === 'string' ? PermissionParser.parseAxPermissionRequest(permission) : permission;

    @Injectable({
      providedIn: 'root'
    })
    class PermissionGuardServiceWithPermission  {
      constructor(private permissionService: PermissionService,
                  private router: Router) {

      }

      canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.permissionService.evaluateRequest(requiredPermission).pipe(
          mergeMap((can) => {
            if (!can) {
              this.router.navigate(['/permission-error']);
            }
            return of(can);
          })
        );
      }

    }

    return PermissionGuardServiceWithPermission;
  }
}

import {Observable} from 'rxjs';

// Waypoint constants
// export const WaypointConstants = {
//   /* eslint-disable @typescript-eslint/naming-convention */
//   WAYPOINT_BEGIN_FILE_HEADER: 'QGC WPL',
//   ACCEPTED_WAYPOINT_FILE_VERSIONS: [110, 120],
//   MAX_VERTICES_IN_OPERATION_VOLUME: 100,
//   SIMPLIFIED_POLYGON_TOLERANCE_METERS: 3, // this can greatly reduce the size of polygon
//   DEFAULT_OPERATION_VOLUME_BUFFER_DISTANCE_METERS: 50,
//   MINIMUM_OPERATION_VOLUME_BUFFER_DISTANCE_METERS: 20,
//   /* eslint-enable @typescript-eslint/naming-convention */
// };

export enum WaypointCommandEnum {
  /* eslint-disable @typescript-eslint/naming-convention */
  NAV_WAYPOINT = 16,
  NAV_LOITER_UNLIM = 17,
  NAV_LOITER_TURNS = 18,
  NAV_LOITER_TIME = 19,
  NAV_RETURN_TO_LAUNCH = 20,
  NAV_LAND = 21,
  NAV_TAKEOFF = 22,
  NAV_LOITER_TO_ALT = 31,
  DO_JUMP = 177,
  NAV_SPLINE_WAYPOINT= 82,
  /* eslint-enable @typescript-eslint/naming-convention */
}

export class WaypointCommand {
  readonly index: number;
  /**
   * Current Waypoint
   */
  readonly wp: number;
  readonly coordFrame: number;
  readonly command: number;
  readonly param1: number;
  readonly param2: number;
  readonly param3: number;
  readonly param4: number;
  readonly param5: number;
  readonly param6: number;
  readonly param7: number;
  readonly autocontinue: boolean;

  constructor(private segments: string[]) {
    this.index = +this.segments[0];
    this.wp = +this.segments[1];
    this.coordFrame = +this.segments[2];
    this.command = +this.segments[3];
    this.param1 = +this.segments[4];
    this.param2 = +this.segments[5];
    this.param3 = +this.segments[6];
    this.param4 = +this.segments[7];
    this.param5 = +this.segments[8];
    this.param6 = +this.segments[9];
    this.param7 = +this.segments[10];
    this.autocontinue = (+this.segments[11]) === 1;
  }


  get x(): number {
    return this.param5;
  }

  get y(): number {
    return this.param6;
  }

  get z(): number {
    return this.param7;
  }

  get lat(): number {
    return this.param5;
  }

  get lng(): number {
    return this.param6;
  }

  get alt(): number {
    return this.param7;
  }

  static fromLine(line: string): WaypointCommand|null {
    const segments = line.split('\t');
    if(segments.length < 12){
      return null;
    }
    return new WaypointCommand(segments);
  }
}

export interface LatLngPoint {
  lng: number;
  alt: number;
  lat: number;
}

export class WaypointInfo {
  lines: string[];
  commands: WaypointCommand[];
  version: string;
  homeLocation: LatLngPoint;


  constructor(private rawLines: string[]) {
    this.lines = rawLines.filter(l => l.trim().length > 0);
    this.version = this.lines[0].replace('QGC WPL', '').trim();
    this.commands = this.lines.slice(1).map(WaypointCommand.fromLine).filter(c => c);

    this.homeLocation = {lat: this.commands[0].lat, lng: this.commands[0].lng, alt: this.commands[0].alt};
    if(this.homeLocation.lat === 0 && this.homeLocation.lng === 0 && this.homeLocation.alt === 0){
      throw new Error("Invalid home location");
    }
  }

  static fromText(wpText: string): WaypointInfo {
    return new WaypointInfo(wpText.split('\n'));
  }

  static fromFile(f: File): Observable<WaypointInfo> {
    return new Observable(subscriber => {
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        subscriber.next(WaypointInfo.fromText(event.target.result as string));
        subscriber.complete();
      });
      reader.readAsText(f);
    });

  }

  getCommandsOfType(desiredCommand: WaypointCommandEnum | number): WaypointCommand[] {
    return this.commands.filter(cmd => cmd.command === desiredCommand);
  }

  getPoints(): LatLngPoint[] {
    return this.getCommandsOfType(WaypointCommandEnum.NAV_WAYPOINT).map(cmd => {
      if (cmd.coordFrame === 3) {
        return {lat: cmd.lat, lng: cmd.lng, alt: cmd.alt + this.homeLocation.alt};
      } else {
        return {lat: cmd.lat, lng: cmd.lng, alt: cmd.alt};
      }
    });
  }
}

/*
export class WaypointUtil {
  private bufferDistance: number;

  //  private resultingOperationVolumes: Feature<Polygon>[] = [];
  constructor(private rawPoints: [number, number, number][], bufferDistance: number) {
    this.setBufferDistance(bufferDistance);
    this.parseAndSetStartingAltitude();

    // ArrayList<Geometry> resultingOperationVolumes = new ArrayList<>();

    // First we take the raw list of waypoints coodinates, and split them if needed
    // An example would be:
    // input: [1,2,3,4,5,6] --> [[1,2,3],[3,4,5],[5,6]]
    /*    ArrayList<ArrayList<Coordinate>> splitCoords = GeometryUtils.splitGeometryUtm(this.getCoordinates());

        if(splitCoords != null) {
          for (ArrayList<Coordinate> splitCoord : splitCoords) {
            Geometry tmp = GeometryUtils.getOptimalBoundingPolygon(splitCoord, this.getBufferDistance());
            resultingOperationVolumes.add(tmp);
          }
        }

        setUtmBoundingPolygons(resultingOperationVolumes);* /
  }

  static fromFile(f: File): Observable<WaypointUtil> {
    return new Observable(subscriber => {
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        const text: string = event.target.result as string;
        const lines = text.split('\n');
        const points: [number, number, number][] = lines.slice(1).map(line => {
          const segments = line.split('\t');
          const cmd = +segments[3];
          const lat = +segments[8];
          const lon = +segments[9];
          const alt = +segments[10];
          return [cmd, lat, lon, alt];
        }).filter(l => !l.includes(NaN) && !(l[1] === 0.0 || l[2] === 0.0) && l[0] === 16)
          .map(l => [l[1], l[2], l[3]]);
        subscriber.next(new WaypointUtil(points));
      });
      reader.readAsText(f);
    });

  }

  private parseAndSetStartingAltitude() {

  }

  private setBufferDistance(bufferDistance: number) {
    if (bufferDistance < 0) { // this will be a special case where we can set -1 to indicate we want the default.
      // < 0 is never valid in any scenario, and no one would purposefully, legitimately ask for it.
      this.bufferDistance = WaypointConstants.DEFAULT_OPERATION_VOLUME_BUFFER_DISTANCE_METERS;
      return;
    }

    if (bufferDistance < WaypointConstants.MINIMUM_OPERATION_VOLUME_BUFFER_DISTANCE_METERS) {
      this.bufferDistance = WaypointConstants.MINIMUM_OPERATION_VOLUME_BUFFER_DISTANCE_METERS;
      return;
    }
    this.bufferDistance = bufferDistance;
  }
}
*/

import {Injectable} from '@angular/core';
import {UVR_STATE, UVRSearchOptions, UvrService} from '../uvr.service';
import {interval, Observable, of, throwError} from 'rxjs';
import {delay, first, flatMap, map, startWith} from 'rxjs/operators';
import {LatLong} from '../../model/LatLong';
import * as helpers from '@turf/turf';
import {featureCollection} from '@turf/turf';
import intersect from '@turf/intersect';
import {Feature, Polygon} from 'geojson';
import {SearchResult} from '../../model/SearchResult';
import {UASVolumeReservation} from '../../model/gen/utm';
import {Parser} from '../../model/utm/parser/OperationParser';
import {IAltitude, TransportUnitsOfMeasure} from '../../model/gen/transport/utm';
import VerticalReferenceEnum = IAltitude.VerticalReferenceEnum;

const LengthUnitsEnum = TransportUnitsOfMeasure.LengthUnits;

const MOCK_UVRS: UASVolumeReservation[] = [
  Parser.parseUASVolumeReservation({
    reason: 'Test',
    managed: true,
    message_id: '18f2a7e9-89f4-4751-87cc-4e3d22b18ff1',
    uss_name: 'RIMS',
    type: 'STATIC_ADVISORY',
    permitted_uas: [
      'NOT_SET'
    ],
    cause: 'OTHER',
    permitted_gufis: [],
    effective_time_begin: '2019-06-20T18:53:46.000Z',
    effective_time_end: '2019-06-20T18:58:46.000Z',
    volumes: [{
      effective_time_begin: '2019-06-20T18:53:46.000Z',
      effective_time_end: '2019-06-20T18:58:46.000Z',
      min_altitude: {
        altitude_value: 300,
        vertical_reference: VerticalReferenceEnum.W84,
        units_of_measure: LengthUnitsEnum.FT,
        source: 'OTHER'
      },
      max_altitude: {
        altitude_value: 1000,
        vertical_reference: VerticalReferenceEnum.W84,
        units_of_measure: LengthUnitsEnum.FT,
        source: 'OTHER'
      },
      geography: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4406261444092, 43.209555040027794],
            [-75.43427467346193, 43.23082107851708],
            [-75.37161827087404, 43.21931331975039],
            [-75.40389060974121, 43.190158952280974],
            [-75.4406261444092, 43.209555040027794]
          ]
        ]
      },
      "circle": {
        "latitude": 43.233072,
        "longitude": -75.416654,
        "radius": 500.0,
        "units_of_measure": LengthUnitsEnum.M
      }
    }],
    // uss: 'all',
    required_support: []
  }),
  Parser.parseUASVolumeReservation({
    reason: 'Test2',
    managed: true,
    message_id: '47685ce3-a1d4-44d3-9338-96eb03e0864c',
    uss_name: 'RIMS',
    type: 'STATIC_ADVISORY',
    permitted_uas: [
      'NOT_SET'
    ],
    cause: 'OTHER',
    permitted_gufis: [],
    effective_time_begin: '2019-06-20T18:55:21.000Z',
    effective_time_end: '2019-06-20T19:00:21.000Z',
    volumes: [{
      effective_time_begin: '2019-06-20T18:55:21.000Z',
      effective_time_end: '2019-06-20T19:00:21.000Z',
      min_altitude: {
        altitude_value: 300,
        vertical_reference: VerticalReferenceEnum.W84,
        units_of_measure: LengthUnitsEnum.FT,
        source: 'OTHER'
      },
      max_altitude: {
        altitude_value: 1000,
        vertical_reference: VerticalReferenceEnum.W84,
        units_of_measure: LengthUnitsEnum.FT,
        source: 'OTHER'
      },
      geography: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -75.40200233459474,
              43.21931331975039
            ],
            [
              -75.39599418640138,
              43.23444807390198
            ],
            [
              -75.36972999572755,
              43.215059902617995
            ],
            [
              -75.3834629058838,
              43.20429993495172
            ],
            [
              -75.40200233459474,
              43.21931331975039
            ]
          ]
        ]
      },
      "circle": {
        "latitude": 43.233072,
        "longitude": -75.416654,
        "radius": 500.0,
        "units_of_measure": LengthUnitsEnum.M
      }
    }],
    // uss: 'all',
    required_support: []
  }),
  Parser.parseUASVolumeReservation({
    reason: 'Test 3',
    managed: true,
    message_id: 'ad74f15c-609b-4512-9ad2-8f2d14b05619',
    uss_name: 'RIMS',
    type: 'STATIC_ADVISORY',
    permitted_uas: [
      'NOT_SET'
    ],
    cause: 'OTHER',
    permitted_gufis: [],
    effective_time_begin: '2019-06-20T18:56:00.000Z',
    effective_time_end: '2019-06-20T19:01:00.000Z',
    volumes: [{
      effective_time_begin: '2019-06-20T18:56:00.000Z',
      effective_time_end: '2019-06-20T19:01:00.000Z',
      min_altitude: {
        altitude_value: 300,
        vertical_reference: VerticalReferenceEnum.W84,
        units_of_measure: LengthUnitsEnum.FT,
        source: 'OTHER'
      },
      max_altitude: {
        altitude_value: 1000,
        vertical_reference: VerticalReferenceEnum.W84,
        units_of_measure: LengthUnitsEnum.FT,
        source: 'OTHER'
      },
      // source: 'OTHER',
      geography: {
        type: 'Polygon',
        coordinates: [
          [
            [
              -75.4299831390381,
              43.18552800683452
            ],
            [
              -75.4255199432373,
              43.2032989112264
            ],
            [
              -75.38998603820802,
              43.19866896283793
            ],
            [
              -75.3970241546631,
              43.188281584310346
            ],
            [
              -75.4299831390381,
              43.18552800683452
            ]
          ]
        ]
      },
      "circle": {
        "latitude": 43.233072,
        "longitude": -75.416654,
        "radius": 500.0,
        "units_of_measure": LengthUnitsEnum.M
      }
    }],
    // uss: 'all',
    required_support: []
  })
];

const UVR_STATES = [
  UVR_STATE.PROPOSED,
  UVR_STATE.ACCEPTED,
  UVR_STATE.ACTIVE,
  UVR_STATE.CLOSED,
  UVR_STATE.EXPIRED,
  UVR_STATE.FAILED_PROPOSE,
  UVR_STATE.FAILED_CLOSE
];

function getRandomUvrState(): UVR_STATE {
  return UVR_STATES[Math.floor(Math.random() * UVR_STATES.length)];
}

@Injectable({
  providedIn: 'root'
})
export class MockUvrService extends UvrService {

  private static generateMessageId(): string {
    let dt = new Date().getTime();

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // eslint-disable-next-line no-bitwise
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  private uvrs: Map<string, UASVolumeReservation>;

  constructor() {
    super();

    this.uvrs = new Map();
    for (const uvr of MOCK_UVRS) {
      this.uvrs.set(uvr.message_id, uvr);
    }
  }

  static makeMockStatus(usss: string): UVR_STATE {
    return getRandomUvrState();
  }

  cancelUvr(uss: string, messageId: string, uasVolumeReservation?: UASVolumeReservation): Observable<boolean> {
    this.uvrs.delete(messageId);
    return of(true);
  }

  createUpdateUvr(uss: string, messageId: string, uasVolumeReservation: UASVolumeReservation):
    Observable<{ success: boolean, error?: any }> {
    uasVolumeReservation.required_support = uasVolumeReservation.required_support || [];
    this.uvrs.set(messageId, uasVolumeReservation);
    return of({success: true});
  }

  getUvrById(messageId: string): Observable<UASVolumeReservation> {
    const uvr = this.uvrs.get(messageId);
    if (uvr) {
      return of(uvr);
    } else {
      return throwError(`'${messageId}' not sound`);
    }
  }

  getUvrsForCoords(coords: LatLong[]): Observable<UASVolumeReservation[]> {

    // TODO: fix to filter by these coordinates

    const viewPoly: Feature<Polygon> = helpers.polygon([coords.map(coord => [coord.Lat, coord.Lon])]);

    let minLat = coords[0].Lat;
    let maxLat = coords[0].Lat;
    let minLon = coords[0].Lon;
    let maxLon = coords[0].Lon;

    for (const coord of coords) {
      if (minLat > coord.Lat) {
        minLat = coord.Lat;
      }
      if (minLon > coord.Lon) {
        minLon = coord.Lon;
      }
      if (maxLat < coord.Lat) {
        maxLat = coord.Lat;
      }
      if (maxLon < coord.Lon) {
        maxLon = coord.Lon;
      }

    }

    const uvrs: UASVolumeReservation[] = [];

    for (const uvr of Array.from(this.uvrs.values())) {
      for (const vol of uvr.volumes) {
        const curPolygon: Feature<Polygon> = helpers.polygon([vol.geography.coordinates[0].map(coord => [coord[1], coord[0]])]);
        if (intersect(featureCollection([viewPoly, curPolygon])) !== null) {
          uvrs.push(uvr);
        }
      }
    }

    return of(uvrs);
  }

  getUssFromUvr(messageId: string, hardCodedUSSs?: string[]): Observable<string[]> {
    return of(['all']);
  }

  getUvrs(options: UVRSearchOptions): Observable<SearchResult<UASVolumeReservation>> {
    let sort = options.sort;
    if (!sort || sort.length === 0) {
      sort = [{by: 'effective_time_begin', direction: 'desc'}];
    }

    let rawValues = Array.from(this.uvrs.values()).sort((a, b) => {
      for (const field of sort) {
        if (a[field.by] !== b[field.by]) {
          return ((a[field.by] < b[field.by]) ? 1 : -1) * ((field.direction === 'asc') ? 1 : -1);
        }
      }
      return 0;

    });

    const offset = options.offset;
    const limit = options.limit;

    if (options.contains) {
      rawValues = rawValues.filter(uvr => {
        for (const key in options.contains) {
          if (!uvr[key].includes(options.contains[key])) {
            return false;
          }

        }
        return true;
      });
    }

    const values = rawValues.slice(offset, offset + limit);
    const results = new SearchResult(values, rawValues.length, offset);
    return of(results).pipe(delay(10));
  }

  getUvrCount(options: UVRSearchOptions): Observable<SearchResult<UASVolumeReservation>> {
    return this.getUvrs(options);
  }

  getUvrStatus(uvr: UASVolumeReservation, refreshMillis?: number): Observable<UVR_STATE> {
    let usss: Observable<string>;

    usss = of(uvr.uss_name);


    usss = usss.pipe(first());

    if (refreshMillis !== undefined) {
      usss = usss.pipe(flatMap((tmp: string) => interval(refreshMillis).pipe(startWith(0)).pipe(map(() => {
        return tmp;
      }))));
    }
    return usss.pipe(map(MockUvrService.makeMockStatus));
  }

  createUvr(uasVolumeReservation: UASVolumeReservation,
            uss: string,
            messageId?: string): Observable<{ success: true; uvr_id: string } | { success: false; error?: any }> {
    uasVolumeReservation.required_support = uasVolumeReservation.required_support || [];
    if (!messageId) {
      messageId = MockUvrService.generateMessageId();
    }
    uasVolumeReservation.message_id = messageId;
    this.uvrs.set(messageId, uasVolumeReservation);
    return of({success: true, uvr_id: messageId} as { success: true, uvr_id: string });
  }

  exists(messageId: string): Observable<boolean> {
    return of(this.uvrs.has(messageId));
  }

}

import {BaseModel} from '../gen/utm/base-model';
import {UntypedFormGroup} from '@angular/forms';


// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserMessageData {
  // type: string;
}

export class UserMessageData extends BaseModel implements IUserMessageData {
  // static TYPE_FIELD_NAME = 'type';
  // type: string;

  constructor(values?: Partial<IUserMessageData>, useFormGroupValuesToModel = false) {
    super();
    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IUserMessageData>, useFormGroupValuesToModel = false): void {
    if (values) {
      this.getValuesToUse(values, useFormGroupValuesToModel);
//      this.type = this.getValue<string>(rawValues, UserMessageData.TYPE_FIELD_NAME);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
//    this.$formGroup.controls[UserMessageData.TYPE_FIELD_NAME].setValue(this.type);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
//        type: new FormControl(this.type, [Validators.required]),
      });
    }
    return this._formGroup;
  }

}

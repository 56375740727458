/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */

/* eslint-disable */


export class SubTypeFactory {
  /**
   * create subType based on the value if the typeSelector property
   */
  static createSubTypeInstance(value: any, useFormGroupValuesToModel = false): object {
    switch (value.$type) {
      default:
        throw new Error(`${value.typeSelector} not supported here`);
    }
  }
}

import {Observable} from 'rxjs';
import {OperationGeometry} from '../../../../../model/operation.geometry';
import {map, shareReplay, startWith} from 'rxjs/operators';
import {Type} from '@angular/core';
import {UTMFlightPlanCustomizer} from './common';
import {UGCSUTMFlightPlan} from './formats/UGCSFlightPlan';
import {AltitudeService} from '../../../../altitude.service';
import {BufferStepCustomizerComponent} from './BufferStepCustomizer';


export class UGCSFlightPlanCustomizer extends UTMFlightPlanCustomizer {

  constructor(private flightPlan: UGCSUTMFlightPlan) {
    super();

  }

  status$: Observable<boolean> = this.flightPlan.options.statusChanges.pipe(
    map(() => this.flightPlan.options.valid),
    startWith(this.flightPlan.options.valid),
    shareReplay(1)
  );


  static fromText(text: string, altitudeService: AltitudeService): UGCSFlightPlanCustomizer {
    return new UGCSFlightPlanCustomizer(UGCSUTMFlightPlan.fromText(text, altitudeService));
  }

  getConfigurationComponent(): { component: Type<any>; inputs: any } {
    return {
      component: BufferStepCustomizerComponent as Type<any>,
      inputs: {
        formGroup: this.flightPlan.options
      }
    };
  }

  toOperationGeometry(): Observable<OperationGeometry> {
    return this.flightPlan.toOperationGeometry();
  }


}

/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';

import {negotiation_agreement} from './enums';

export interface INegotiationAgreement {
  message_id: string;
  negotiation_id: string;
  uss_name?: string;
  uss_name_of_originator: string;
  uss_name_of_receiver: string;
  gufi_originator: string;
  gufi_receiver: string;
  free_text?: string;
  discovery_reference?: string;
  type: negotiation_agreement;
}


export class NegotiationAgreement extends BaseModel implements INegotiationAgreement {

  static MESSAGE_ID_FIELD_NAME = 'message_id';
  static NEGOTIATION_ID_FIELD_NAME = 'negotiation_id';
  static USS_NAME_FIELD_NAME = 'uss_name';
  static USS_NAME_OF_ORIGINATOR_FIELD_NAME = 'uss_name_of_originator';
  static USS_NAME_OF_RECEIVER_FIELD_NAME = 'uss_name_of_receiver';
  static GUFI_ORIGINATOR_FIELD_NAME = 'gufi_originator';
  static GUFI_RECEIVER_FIELD_NAME = 'gufi_receiver';
  static FREE_TEXT_FIELD_NAME = 'free_text';
  static DISCOVERY_REFERENCE_FIELD_NAME = 'discovery_reference';
  static TYPE_FIELD_NAME = 'type';

  /** An identifier unique to this particular message. */
  message_id: string;
  /** An identifier held constant across multiple message_ids.  This value represents a single negotiation between two USSs for two specific operations.  Will be same value as used in NegotiationMessage for this particular negotiation between these operations. */
  negotiation_id: string;
  /** A name identifying the originator of this message. The maximum and minimum character length is based on a usable domain name, and considering the maximum in RFC-1035. NegotiationAgreement uss_name MUST be a duplicate of either uss_name_of_originator or uss_name_of_receiver. This field is required for security and data quality purposes. */
  uss_name: string;
  /** Name of the USS originating the negotation. NegotiationAgreement uss_name_of_originator MUST be the uss_name as known by FIMS authorization server. The maximum and minimum character length is based on a usable domain name, and considering the maximum in RFC-1035. */
  uss_name_of_originator: string;
  /** Name of the USS receiving the original negotiation request. NegotiationAgreement uss_name_of_receiver MUST be the uss_name as known by FIMS authorization server. The maximum and minimum character length is based on a usable domain name, and considering the maximum in RFC-1035. */
  uss_name_of_receiver: string;
  /** The GUFI of the relevant operation of the originating USS. Note that the origin and receiver roles are strictly dependent on which USS is generating this message. May be empty in the case of ATC communication to USS via FIMS. */
  gufi_originator: string;
  /** The GUFI of the relevant operation of the receiving USS. Note that the origin and receiver roles are strictly dependent on which USS is generating this message. May be empty in the case of ATC communication to USS via FIMS.
   */
  gufi_receiver: string;
  free_text: string;
  /** An identifier that supplies information about the USS from the discovery perspecitve.  This field is currently vague due to research on the appropriate discovery approach.  It will be tightened when an operational system decides on the approach to discovery.
   For Gridded USS Discovery, this should be an x,y,z string where x and y are the coords of the grid and z is the zoom level.  For example:  "110,117,10"
   For FIMS Discovery, this should be the uss_instance_id which is a UUID and represents the instance id tied to the USS identified by the uss_name field's instance in this negotiation. */
  discovery_reference: string;
  /** The type of negotation result.
   INTERSECTION: both USSs agreed to intersect their operation plans. REPLAN: both USSs agreed on replan of receiving operation.  In this
   case there would be no planned intersection of these operations. */
  type: negotiation_agreement;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<INegotiationAgreement>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<INegotiationAgreement>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.message_id = this.getValue<string>(rawValues, NegotiationAgreement.MESSAGE_ID_FIELD_NAME);
      this.negotiation_id = this.getValue<string>(rawValues, NegotiationAgreement.NEGOTIATION_ID_FIELD_NAME);
      this.uss_name = this.getValue<string>(rawValues, NegotiationAgreement.USS_NAME_FIELD_NAME);
      this.uss_name_of_originator = this.getValue<string>(rawValues, NegotiationAgreement.USS_NAME_OF_ORIGINATOR_FIELD_NAME);
      this.uss_name_of_receiver = this.getValue<string>(rawValues, NegotiationAgreement.USS_NAME_OF_RECEIVER_FIELD_NAME);
      this.gufi_originator = this.getValue<string>(rawValues, NegotiationAgreement.GUFI_ORIGINATOR_FIELD_NAME);
      this.gufi_receiver = this.getValue<string>(rawValues, NegotiationAgreement.GUFI_RECEIVER_FIELD_NAME);
      this.free_text = this.getValue<string>(rawValues, NegotiationAgreement.FREE_TEXT_FIELD_NAME);
      this.discovery_reference = this.getValue<string>(rawValues, NegotiationAgreement.DISCOVERY_REFERENCE_FIELD_NAME);
      this.type = this.getValue<negotiation_agreement>(rawValues, NegotiationAgreement.TYPE_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        message_id: new UntypedFormControl(this.message_id, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        negotiation_id: new UntypedFormControl(this.negotiation_id, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        uss_name: new UntypedFormControl(this.uss_name, [Validators.minLength(4), Validators.maxLength(250),]),
        uss_name_of_originator: new UntypedFormControl(this.uss_name_of_originator, [Validators.required, Validators.minLength(4), Validators.maxLength(250),]),
        uss_name_of_receiver: new UntypedFormControl(this.uss_name_of_receiver, [Validators.required, Validators.minLength(4), Validators.maxLength(250),]),
        gufi_originator: new UntypedFormControl(this.gufi_originator, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        gufi_receiver: new UntypedFormControl(this.gufi_receiver, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        free_text: new UntypedFormControl(this.free_text, [Validators.maxLength(1000),]),
        discovery_reference: new UntypedFormControl(this.discovery_reference, [Validators.minLength(5), Validators.maxLength(36),]),
        type: new UntypedFormControl(this.type, [Validators.required, enumValidator(negotiation_agreement),]),
      });
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[NegotiationAgreement.MESSAGE_ID_FIELD_NAME].setValue(this.message_id);
    this.$formGroup.controls[NegotiationAgreement.NEGOTIATION_ID_FIELD_NAME].setValue(this.negotiation_id);
    this.$formGroup.controls[NegotiationAgreement.USS_NAME_FIELD_NAME].setValue(this.uss_name);
    this.$formGroup.controls[NegotiationAgreement.USS_NAME_OF_ORIGINATOR_FIELD_NAME].setValue(this.uss_name_of_originator);
    this.$formGroup.controls[NegotiationAgreement.USS_NAME_OF_RECEIVER_FIELD_NAME].setValue(this.uss_name_of_receiver);
    this.$formGroup.controls[NegotiationAgreement.GUFI_ORIGINATOR_FIELD_NAME].setValue(this.gufi_originator);
    this.$formGroup.controls[NegotiationAgreement.GUFI_RECEIVER_FIELD_NAME].setValue(this.gufi_receiver);
    this.$formGroup.controls[NegotiationAgreement.FREE_TEXT_FIELD_NAME].setValue(this.free_text);
    this.$formGroup.controls[NegotiationAgreement.DISCOVERY_REFERENCE_FIELD_NAME].setValue(this.discovery_reference);
    this.$formGroup.controls[NegotiationAgreement.TYPE_FIELD_NAME].setValue(this.type);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

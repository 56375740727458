import {Injectable, signal} from '@angular/core';

export enum AudioAlertType {
  PENDING_APPROVAL = "PENDING_APPROVAL"
}

export const AUDIO_ALERT_PATHS: {[key in AudioAlertType]: string} = {
  [AudioAlertType.PENDING_APPROVAL]: '/assets/audio/proposed-operation-alert.mp3'
}

@Injectable({
  providedIn: 'root'
})
export class AudioAlertsService {
  autoplayPermitted$ = signal<boolean|null>(null);

  /**
   * Checks whether the browser permits autoplaying audio and updates the value of the 'autoplayPermitted$' signal
   * accordingly
   */
  refreshAutoplayPermitted() {
    if (this.autoplayPermitted$()) {
      return;
    }

    const audio = new Audio(AUDIO_ALERT_PATHS[AudioAlertType.PENDING_APPROVAL]);
    audio.muted = true;
    audio.play().then(() => {
      this.autoplayPermitted$.set(true);
    }).catch(() => {
      this.autoplayPermitted$.set(false);
    });
  }

  /**
   * Plays the audio alert
   * @param type The type of audio alert to play.
   */
  playAudioAlert(type: AudioAlertType) {
    const path = AUDIO_ALERT_PATHS[type];
    if (!path) {
      return;
    }

    const audio = new Audio(path);
    audio.play().then(() => {
      this.autoplayPermitted$.set(true);
    }).catch((err) => {
      this.autoplayPermitted$.set(false);
      console.error(err);
    });
  }
}

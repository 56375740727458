/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseModel} from './base-model';


export interface IUasRegistration {
  registration_id: string;
  registration_location: string;
}


export class UasRegistration extends BaseModel implements IUasRegistration {

  static REGISTRATION_ID_FIELD_NAME = 'registration_id';
  static REGISTRATION_LOCATION_FIELD_NAME = 'registration_location';

  /** A unique registration identifier, minted by the registration authority as a UUIDv4. */
  registration_id: string;
  /** An Internet-reachable URL for the registration authority. More details to come, however, it is thought that this should be an endpoint allowing an unauthenticated GET to obtain metadata about the registrar. */
  registration_location: string;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IUasRegistration>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IUasRegistration>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.registration_id = this.getValue<string>(rawValues, UasRegistration.REGISTRATION_ID_FIELD_NAME);
      this.registration_location = this.getValue<string>(rawValues, UasRegistration.REGISTRATION_LOCATION_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        registration_id: new UntypedFormControl(this.registration_id, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        registration_location: new UntypedFormControl(this.registration_location, [Validators.required,]),
      });
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[UasRegistration.REGISTRATION_ID_FIELD_NAME].setValue(this.registration_id);
    this.$formGroup.controls[UasRegistration.REGISTRATION_LOCATION_FIELD_NAME].setValue(this.registration_location);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ConstraintService} from '../../../shared/services/constraint.service';
import {UvrExt} from '../../../shared/model/utm/UvrExt';
import {ImportExportServiceModule} from '../../../shared/services/import-export/import-export-service.module';
import {ComponentsModule} from '../../../shared/components/components.module';

@Component({
  selector: 'app-new-constraint',
  templateUrl: './new-constraint.component.html',
  imports: [
    ImportExportServiceModule,
    ComponentsModule
    ],
  standalone: true
})
export class NewConstraintComponent implements OnInit {
  constraint: UvrExt;

  constructor(private route: ActivatedRoute, private constraintService: ConstraintService) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          if (params.constraintId) {
            this.constraintService.getConstraint(params.constraintId).subscribe((constraint) => {
              this.constraint = constraint;
            });
          }
        }
      );
  }

}

import {BaseModel} from './gen/utm/base-model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export interface IAdditionalRegistration {
  registrar: string;
  registrationId: string;
}

export class AdditionalRegistration extends BaseModel implements IAdditionalRegistration {
  static REGISTRAR_FIELD_NAME = 'registrar';
  static REGISTRATION_ID_FIELD_NAME = 'registrationId';

  registrar: string;
  registrationId: string;

  constructor(values?: Partial<IAdditionalRegistration>, useFormGroupValuesToModel = false) {
    super();
    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<AdditionalRegistration>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.registrar = this.getValue<string>(rawValues, AdditionalRegistration.REGISTRAR_FIELD_NAME);
      this.registrationId = this.getValue<string>(rawValues, AdditionalRegistration.REGISTRATION_ID_FIELD_NAME);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[AdditionalRegistration.REGISTRAR_FIELD_NAME].setValue(this.registrar);
    this.$formGroup.controls[AdditionalRegistration.REGISTRATION_ID_FIELD_NAME].setValue(this.registrationId);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        registrar: new UntypedFormControl(this.registrar, [Validators.required]),
        registrationId: new UntypedFormControl(this.registrationId, [Validators.required]),
      });
    }
    return this._formGroup;
  }


}

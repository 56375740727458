import {
  AfterViewInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList
} from '@angular/core';
import {LeafletDrawerService} from '@ax/ax-angular-map-leaflet';
import {combineLatest, Subscription} from 'rxjs';
import {GeomanControlComponent} from '../geoman-control/geoman-control.component';
import * as L from 'leaflet';
import {LeafletEvent} from 'leaflet';

@Component({
  selector: 'geoman-container',
  template: ''
})
export class GeomanContainerComponent implements AfterViewInit, OnDestroy {
  @Output() drawstart: EventEmitter<LeafletEvent & { shape: string }> = new EventEmitter<LeafletEvent & { shape: string }>();
  @Output() editstart: EventEmitter<LeafletEvent & { shape: string }> = new EventEmitter<LeafletEvent & { shape: string }>();
  @Input() allowSelfIntersection = true;

  @ContentChildren(GeomanControlComponent) controls: QueryList<GeomanControlComponent>;
  private mapSub: Subscription;

  constructor(private leafletDrawerService: LeafletDrawerService) {
    // Method not implemented
  }

  initMap(map: L.Map & { pm }): void {
    map.pm.removeControls();
    map.pm.setGlobalOptions({
      allowSelfIntersection: this.allowSelfIntersection
    });
    map.pm.addControls({
      position: 'topleft',
      drawControls: true,
      drawCircle: false,
      drawMarker: false,
      drawRectangle: false,
      drawCircleMarker: false,
      drawPolygon: false,
      drawPolyline: false,

      editControls: true,
      cutPolygon: false,
      pinningOption: false,
      snappingOption: false,
      optionsControls: false,
      customControls: true,
      oneBlock: false
    });

    for (let i = 0; i < this.controls.length; ++i) {
      const control = this.controls.get(i);
      control.handlePmInit(map);
    }

    map.on('pm:drawstart', (e: LeafletEvent & { shape: string }) => {
      this.drawstart.emit(e);
    });

    map.on('pm:enable', (e: LeafletEvent & { shape: string }) => {
      this.editstart.emit(e);
    });

  }

  ngOnDestroy(): void {
    this.mapSub?.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.mapSub = combineLatest([this.leafletDrawerService.watchViewerInit()/*, this.controls.changes*/]).subscribe(([map/*, tmp*/]) => {
      this.initMap(map as L.Map & { pm });
    });
  }

}

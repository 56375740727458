<h1 cds-text="title">View Constraints</h1>
@if (deviceSize$() !== 'sm') {
  <div class="btn-row-wrapper">
    <button type="button" (click)="refresh()" class="btn btn-primary">Refresh</button>
    <button class="btn btn-primary" (click)="resetFilters()">Reset Filters</button>
  </div>
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="datagrid-compact">
    <!-- Datagrid header row -->
    <clr-dg-column class="col-state"  [clrDgField]="'state'">State
      <clr-dg-filter [clrDgFilter]="statesFilter">
        <div [formGroup]="filterFG">
          <app-constraint-states-filter #statesFilter [formControlName]="'states'">
          </app-constraint-states-filter>
        </div>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'reason'" [clrDgSortBy]="'reason'" [clrDgSortOrder]="sortDirections['reason']">Name</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'effective_time_begin'" [clrDgSortOrder]="sortDirections['effective_time_begin']">
      <ng-container *clrDgHideableColumn="{hidden: false}">Begin Time</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'effective_time_end'" [clrDgSortOrder]="sortDirections['effective_time_end']">
      <ng-container *clrDgHideableColumn="{hidden: false}">End Time</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'additional_data.constraint_type'" [clrDgSortBy]="'additional_data.constraint_type'"
                   [clrDgSortOrder]="sortDirections['additional_data.constraint_type']">
      <ng-container *clrDgHideableColumn="{hidden: false}">Constraint Type</ng-container>
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'uss_name'" [clrDgSortBy]="'uss_name'" [clrDgSortOrder]="sortDirections['uss_name']">USS</clr-dg-column>
    <clr-dg-column class="datagrid-fixed-column datagrid-expandable-caret"></clr-dg-column>

    <!-- Datagrid body rows -->
    <clr-dg-placeholder>No results found. Please try adjusting the filters in the table column headers.</clr-dg-placeholder>
    <clr-dg-row *ngFor="let constraint of constraints" [clrDgItem]="constraint" class="datagrid-body-row">
      <clr-dg-cell>
        <app-constraint-state-badge [constraint]="constraint" [watchState]="false"></app-constraint-state-badge>
      </clr-dg-cell>
      <clr-dg-cell>
        {{constraint.managed ? truncateWithEllip(constraint.reason || '', 50, false) :
        'External Constraint (' + truncateWithEllip(constraint.reason || '', 50, false) + ')'}}
      </clr-dg-cell>
      <clr-dg-cell>{{formatDateTime(constraint.effective_time_begin)}}</clr-dg-cell>
      <clr-dg-cell>{{formatDateTime(getEndTime(constraint))}}</clr-dg-cell>
      <clr-dg-cell>{{getPermittedOperations(constraint.additional_data) | async}}</clr-dg-cell>
      <clr-dg-cell>{{constraint.uss_name}}</clr-dg-cell>
      <clr-dg-cell class="datagrid-fixed-column datagrid-expandable-caret">
        <a (click)="viewConstraint(constraint)"><cds-icon shape="link"></cds-icon></a>
      </clr-dg-cell>

      <clr-dg-action-overflow>
        <button class="action-item" (click)="viewConstraint(constraint)">View</button>
        <button class="action-item" *hasPermission="'submit_constraint'" (click)="cloneConstraint(constraint)">Clone</button>
        <ng-container *ngIf="constraint.getState() !== 'ENDED' && constraint.managed">
          <button class="action-item" *hasPermission="'submit_constraint'" (click)="closeConstraint(constraint)">Close</button>
        </ng-container>
      </clr-dg-action-overflow>
  <!--    Uncomment this code to enable expandable details section
      <clr-dg-row-detail *clrIfExpanded>
        <app-constraint-ext-summary [constraint]="constraint" style="width:100%;"></app-constraint-ext-summary>
      </clr-dg-row-detail>-->
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize" [clrDgPage]="currentPage"
                         [clrDgTotalItems]="totalItems"></clr-dg-pagination>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}} |
      <clr-dg-page-size [clrPageSizeOptions]="pageSizeOptions">Constraints per page</clr-dg-page-size>
    </clr-dg-footer>
  </clr-datagrid>
} @else {
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <!-- Datagrid header rows -->
    <clr-dg-column class="col-state"  [clrDgField]="'state'">State
      <clr-dg-filter [clrDgFilter]="statesFilter">
        <div [formGroup]="filterFG">
          <app-constraint-states-filter #statesFilter [formControlName]="'states'">
          </app-constraint-states-filter>
        </div>
      </clr-dg-filter>
    </clr-dg-column>
    <clr-dg-column class="col-name" [clrDgField]="'reason'" [clrDgSortBy]="'reason'" [clrDgSortOrder]="sortDirections['reason']">
      Name</clr-dg-column>

    <!-- Datagrid body rows -->
    <clr-dg-placeholder>No results found. Please try adjusting the filters in the table column headers.</clr-dg-placeholder>
    <clr-dg-row *ngFor="let constraint of constraints" [clrDgItem]="constraint" class="datagrid-body-row">
      <clr-dg-cell>
        <app-constraint-state-badge [constraint]="constraint" [watchState]="false"></app-constraint-state-badge>
      </clr-dg-cell>
      <clr-dg-cell>
        {{constraint.managed ? truncateWithEllip(constraint.reason || '', 50, false) :
        'External Constraint (' + truncateWithEllip(constraint.reason || '', 50, false) + ')'}}
      </clr-dg-cell>

      <clr-dg-action-overflow>
        <button class="action-item" (click)="viewConstraint(constraint)">View</button>
        <button class="action-item" *hasPermission="'submit_constraint'" (click)="cloneConstraint(constraint)">Clone</button>
        <ng-container *ngIf="constraint.getState() !== 'ENDED' && constraint.managed">
          <button class="action-item" *hasPermission="'submit_constraint'" (click)="closeConstraint(constraint)">Close</button>
        </ng-container>
      </clr-dg-action-overflow>
      <!--    Uncomment this code to enable expandable details section
          <clr-dg-row-detail *clrIfExpanded>
            <app-constraint-ext-summary [constraint]="constraint" style="width:100%;"></app-constraint-ext-summary>
          </clr-dg-row-detail>-->
    </clr-dg-row>

    <!-- Datagrid footer -->
    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize" [clrDgPage]="currentPage"
                         [clrDgTotalItems]="totalItems"></clr-dg-pagination>
      <span class="datagrid-footer-description">{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}}</span>
    </clr-dg-footer>
  </clr-datagrid>
}

<!-- Constraint closure confirmation modals -->
<clr-modal *ngIf="constraintToClose" [(clrModalOpen)]="!!constraintToClose" [clrModalSize]="'md'">
  <h3 class="modal-title">Close Constraint</h3>
  <div class="modal-body">
    <span class="spinner" *ngIf="waitingForConstraintToClose">
      Closing...
    </span>
    <p *ngIf="!waitingForConstraintToClose">Are you sure you want to close constraint `{{constraintToClose.message_id}}`?</p>
  </div>
  <div class="modal-footer" *ngIf="!waitingForConstraintToClose">
    <button type="button" class="btn btn-primary" (click)="constraintToClose=null">Cancel</button>
    <button type="button" class="btn btn-primary btn-danger" (click)="confirmConstraintClose()">Close</button>
  </div>
</clr-modal>

<clr-modal *ngIf="constraintCloseSubmitted" [(clrModalOpen)]="constraintCloseSubmitted" [clrModalSize]="'md'"
           [clrModalStaticBackdrop]="false">
  <h3 class="modal-title">Constraint Closure Request Submitted</h3>
  <div class="modal-body">
    <p>The constraint closure request has been submitted.</p>
  </div>
</clr-modal>

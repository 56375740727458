import {Injectable} from '@angular/core';
import {UserMessageEntry} from '../model/UserMessage/UserMessageEntry';
import {concat, Observable, of} from 'rxjs';
import {SearchResult} from '../model/SearchResult';
import {DateTime} from 'luxon';
import * as _ from 'lodash';
import {map, mergeMap, reduce} from 'rxjs/operators';
import {ConflictInfo} from '../model/conflictInfo';
import {DirectMessageSubmission} from '../model/DirectMessageSubmission';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IUserMessageSearch {
  read?: boolean;
  afterTime?: DateTime;
  // sort?: string[];
  // sortIncreasing?: boolean;
}

export class UserMessageSearch implements IUserMessageSearch {
  read?: boolean;
  afterTime?: DateTime;
  // sort: string[];
  // sortIncreasing: boolean;

  public constructor() {
    // Method not implemented
  }

  public static defaultSearch(): UserMessageSearch {
    return new UserMessageSearch();
  }
}


@Injectable({
  providedIn: 'root'
})
export abstract class UserMessageService {

  protected constructor() {
    // Method not implemented
  }

  abstract getMessages(messageSearch: IUserMessageSearch,
                       limit?: number,
                       offset?: number,
                       fetchCount?: boolean): Observable<SearchResult<UserMessageEntry>>;

  getAllMessages(search: IUserMessageSearch): Observable<UserMessageEntry[]> {
    const stepSize = 100;
    const initialSearch = _.cloneDeep(search);

    return this.getMessages(initialSearch, stepSize, 0, true).pipe(
      mergeMap(res => {
        const obs: Observable<SearchResult<UserMessageEntry>>[] = [of(res)];

        if (res.results.length < res.total) {
          const baseSearch = _.cloneDeep(initialSearch);

          for (let i = res.offset + res.results.length; i < res.total; i += stepSize) {
            const thisSearch = _.cloneDeep(baseSearch);
            obs.push(this.getMessages(thisSearch, stepSize, i, false));
          }
        }
        return concat(...obs).pipe(
          reduce((all, cur) => all.concat([cur]), [])
        );
      })
    ).pipe(
      map((results: SearchResult<UserMessageEntry>[]) => _.flatten(results.map(r => r.results)))
    );
  }

  abstract watchMessages(): Observable<UserMessageEntry[]>;

  abstract getUnreadMessageCount(): Observable<number>;

  abstract watchUnreadMessageCount(watchInterval?: number): Observable<number>;

  abstract refreshUnreadMessageCount(): void;

  abstract markMessagesAsRead(uuids: string[]): Observable<boolean>;

  abstract deleteMessages(uuids: string[]): Observable<boolean>;

  abstract emitMessages(message: UserMessageEntry | UserMessageEntry[]): void;

  abstract sendMessageForOperation(operationID: string, message: DirectMessageSubmission): Observable<DirectMessageSubmission>;

  abstract sendMessageToUser(userID: string, message: DirectMessageSubmission): Observable<DirectMessageSubmission>;

  abstract getConflictingEntity(opId: string): Observable<ConflictInfo>;
}

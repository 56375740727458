export function getValueOrDefault<T>(f: () => T, defaultValue: T) {
  try {
    return f();
  } catch (e) {
    return defaultValue;
  }
}


export type Some<T> = { type: 'some', value: T };
export type Error = { type: 'error', message: string };

export type Errorable<T> = Some<T> | Error;

export function fail(message: string): Error {
  return {
    type: 'error',
    message
  };
}

export function some<T>(value: T): Some<T> {
  return {
    type: 'some',
    value
  };
}


export function tryIt<T>(f: () => T):Errorable<T> {
  try {
    return some(f());
  } catch (e) {
    return fail(e.message);
  }

}

export enum TelemetryIntegrationType {
  COT = <any> 'COT',
  MAVLINK = <any> 'MAVLINK',
  RIGITECH = <any> 'RIGITECH',
  INVALID = <any> 'INVALID'
}

export interface TransportSupportedTelemetryIntegrationsResponse {
  supportedIntegrations: TransportSupportedTelemetryIntegrationsResponse.TelemetryIntegrationType[];
}

export interface ISupportedTelemetryIntegrationsResponse {
  supportedIntegrations: TelemetryIntegrationType[];
}

export class SupportedTelemetryIntegrationsResponse implements ISupportedTelemetryIntegrationsResponse {
  supportedIntegrations: TelemetryIntegrationType[];

  constructor(values: ISupportedTelemetryIntegrationsResponse) {
    this.supportedIntegrations = values.supportedIntegrations;
  }

  /**
   * Convert the array of supported telemetry integrations to an object
   * This is useful for referencing the supported integrations within UI templates
   */
  get supportedIntegrationsObject() {
    const supportedIntegrations = {
      COT: false,
      RIGITECH: false,
      MAVLINK: false
    };
    this.supportedIntegrations.forEach(integration => {
      switch (integration) {
        case TelemetryIntegrationType.COT:
        case TelemetryIntegrationType.RIGITECH:
        case TelemetryIntegrationType.MAVLINK:
          supportedIntegrations[integration] = true;
          break;
        default:
          throw new Error(`Unknown supported telemetry integration type: ${integration}`);
      }
    });
    return supportedIntegrations;
  }
}

export namespace TransportSupportedTelemetryIntegrationsResponse {
  export type TelemetryIntegrationType = 'COT' | 'MAVLINK' | 'RIGITECH';
  export const TelemetryIntegrationType = {
    COT: 'COT' as TelemetryIntegrationType,
    MAVLINK: 'MAVLINK' as TelemetryIntegrationType,
    RIGITECH: 'RIGITECH' as TelemetryIntegrationType
  };
}

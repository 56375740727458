<div class="container">
  <h3>{{envService.getTitle() | async}}</h3>
  <br>
  <img *ngIf="user" alt="AX Enterprize" class="logo" src="{{envService.getLogoUrlForOrg(user.getOrg()) | async}}"
       width="160px">
</div>
<hr/>
<!--<clr-modal [(clrModalOpen)]="basic">
  <div class="modal-body">
    <form [formGroup]="fg" style="height:100%">
      <app-manual-polygon-input [formControlName]="'polygon'"></app-manual-polygon-input>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="basic = false">Cancel</button>
    <button type="button" class="btn btn-primary" [disabled]="fg.errors" (click)="add()">Add</button>
  </div>
</clr-modal>
-->

/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator, maxValueValidator, minValueValidator} from './validators';
import {BaseModel} from './base-model';

import {Altitude, IAltitude} from './altitude.model';
import {IPoint, Point} from './point.model';
import {track_bearing_reference, track_bearing_uom, track_ground_speed_units} from './enums';
import {DateTime} from "luxon";

export interface IPosition {
  altitude_gps: IAltitude;
  altitude_num_gps_satellites: number;
  comments?: string;
  enroute_positions_id: string;
  gufi: string;
  hdop_gps: number;
  location: IPoint;
  time_measured: DateTime;
  time_sent: DateTime;
  track_bearing: number;
  track_bearing_reference: track_bearing_reference;
  track_bearing_uom: track_bearing_uom;
  track_ground_speed: number;
  track_ground_speed_units: track_ground_speed_units;
  uss_name: string;
  discovery_reference?: string;
  vdop_gps: number;
}


export class Position extends BaseModel implements IPosition {

  static ALTITUDE_GPS_FIELD_NAME = 'altitude_gps';
  static ALTITUDE_NUM_GPS_SATELLITES_FIELD_NAME = 'altitude_num_gps_satellites';
  static COMMENTS_FIELD_NAME = 'comments';
  static ENROUTE_POSITIONS_ID_FIELD_NAME = 'enroute_positions_id';
  static GUFI_FIELD_NAME = 'gufi';
  static HDOP_GPS_FIELD_NAME = 'hdop_gps';
  static LOCATION_FIELD_NAME = 'location';
  static TIME_MEASURED_FIELD_NAME = 'time_measured';
  static TIME_SENT_FIELD_NAME = 'time_sent';
  static TRACK_BEARING_FIELD_NAME = 'track_bearing';
  static TRACK_BEARING_REFERENCE_FIELD_NAME = 'track_bearing_reference';
  static TRACK_BEARING_UOM_FIELD_NAME = 'track_bearing_uom';
  static TRACK_GROUND_SPEED_FIELD_NAME = 'track_ground_speed';
  static TRACK_GROUND_SPEED_UNITS_FIELD_NAME = 'track_ground_speed_units';
  static USS_NAME_FIELD_NAME = 'uss_name';
  static DISCOVERY_REFERENCE_FIELD_NAME = 'discovery_reference';
  static VDOP_GPS_FIELD_NAME = 'vdop_gps';

  /** The altitude as measured via a GPS device on the aircraft. */
  altitude_gps: Altitude;
  /** Number of satellites used in calculating the altitude. */
  altitude_num_gps_satellites: number;
  comments: string;
  /** Each position will be assigned a UUIDv4 by the USS managing the referenced operation.  Any system recieving this position object MUST NOT modify this identifier. */
  enroute_positions_id: string;
  /** Each operation has an GUFI assigned upon submission. Required upon PUTting a new position. It is a JSON string, but conforms to the UUID version 4 specification */
  gufi: string;
  /** The horizontal dilution of precision as provided by the onboard GPS. */
  hdop_gps: number;
  location: Point;
  /** The time the position was measured. Likely the time provided with the GPS position reading. Position time_measured < time_sent MUST be true. */
  time_measured: DateTime;
  /** The time the position was sent by the USS as measured by that USS's system time. Position time_measured <= time_sent MUST be true. */
  time_sent: DateTime;
  /** The direction of travel relative to track_bearing_reference in degrees. Position track_bearing value MUST be >= 0.0 and < 360.0. Note that FIXM allows for the value of 360.0, while UTM does not.  The reason is for clarity by not allowing two numbers (0.0 and 360.0) to represent the same measurement. */
  track_bearing: number;
  /** A code indicating the direction of the zero bearing.
   TRUE_NORTH  This value indicates that the direction is relative to True North. MAGNETIC_NORTH  This value indicates that the direction is relative to Magnetic North.
   This field is equivalent to */
  track_bearing_reference: track_bearing_reference;
  /** The reference quantities used to express the value of angles. [ISO 19103, chapter 6.5.7.10]
   As in FIXM, only a single option is provided (degrees). Including this field allows for clarity in the data provided and allows for the potential of other units of measure in the future. */
  track_bearing_uom: track_bearing_uom;
  /** Ground speed int the direction of travel, in units as specifed by track_ground_speed_units. Position track_ground_speed MUST be >= 0.0. */
  track_ground_speed: number;
  /** The reference quantities used to express the value of ground speed as provided in track_ground_speed.
   KT: Knots.
   FIXM allows KT (knots) and KM_H (kilometers per hour) but we only allow the KT choice. */
  track_ground_speed_units: track_ground_speed_units;
  /** The name of the entity providing UAS Support Services. Populated by the service discovery system based on credential information provided by the USS. The maximum and minimum character length is based on a usable domain name, and considering the maximum in RFC-1035. */
  uss_name: string;
  /** An identifier that supplies information about the USS from the discovery perspecitve.  This field is currently vague due to research on the appropriate discovery approach.  It will be tightened when an operational system decides on the approach to discovery.
   For Gridded USS Discovery, this should be an x,y,z string where x and y are the coords of the grid and z is the zoom level.  For example:  "110,117,10"
   For FIMS Discovery, this should be the uss_instance_id which is a UUID. */
  discovery_reference: string;
  /** The vertical dilution of precision as provided by the onboard GPS. */
  vdop_gps: number;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IPosition>, useFormGroupValuesToModel = false) {
    super();
    this.altitude_gps = new Altitude();
    this.location = new Point();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IPosition>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.altitude_gps.setValues(rawValues.altitude_gps, useFormGroupValuesToModel);
      this.altitude_num_gps_satellites = this.getValue<number>(rawValues, Position.ALTITUDE_NUM_GPS_SATELLITES_FIELD_NAME);
      this.comments = this.getValue<string>(rawValues, Position.COMMENTS_FIELD_NAME);
      this.enroute_positions_id = this.getValue<string>(rawValues, Position.ENROUTE_POSITIONS_ID_FIELD_NAME);
      this.gufi = this.getValue<string>(rawValues, Position.GUFI_FIELD_NAME);
      this.hdop_gps = this.getValue<number>(rawValues, Position.HDOP_GPS_FIELD_NAME);
      this.location.setValues(rawValues.location, useFormGroupValuesToModel);
      this.time_measured = this.getValue<DateTime>(rawValues, Position.TIME_MEASURED_FIELD_NAME);
      this.time_sent = this.getValue<DateTime>(rawValues, Position.TIME_SENT_FIELD_NAME);
      this.track_bearing = this.getValue<number>(rawValues, Position.TRACK_BEARING_FIELD_NAME);
      this.track_bearing_reference = this.getValue<track_bearing_reference>(rawValues, Position.TRACK_BEARING_REFERENCE_FIELD_NAME);
      this.track_bearing_uom = this.getValue<track_bearing_uom>(rawValues, Position.TRACK_BEARING_UOM_FIELD_NAME);
      this.track_ground_speed = this.getValue<number>(rawValues, Position.TRACK_GROUND_SPEED_FIELD_NAME);
      this.track_ground_speed_units = this.getValue<track_ground_speed_units>(rawValues, Position.TRACK_GROUND_SPEED_UNITS_FIELD_NAME);
      this.uss_name = this.getValue<string>(rawValues, Position.USS_NAME_FIELD_NAME);
      this.discovery_reference = this.getValue<string>(rawValues, Position.DISCOVERY_REFERENCE_FIELD_NAME);
      this.vdop_gps = this.getValue<number>(rawValues, Position.VDOP_GPS_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        altitude_gps: this.altitude_gps.$formGroup,
        altitude_num_gps_satellites: new UntypedFormControl(this.altitude_num_gps_satellites, [Validators.required, minValueValidator(0),]),
        comments: new UntypedFormControl(this.comments, [Validators.minLength(0), Validators.maxLength(1000),]),
        enroute_positions_id: new UntypedFormControl(this.enroute_positions_id, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        gufi: new UntypedFormControl(this.gufi, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        hdop_gps: new UntypedFormControl(this.hdop_gps, [Validators.required, minValueValidator(0),]),
        location: this.location.$formGroup,
        time_measured: new UntypedFormControl(this.time_measured, [Validators.required, Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'),]),
        time_sent: new UntypedFormControl(this.time_sent, [Validators.required, Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'),]),
        track_bearing: new UntypedFormControl(this.track_bearing, [Validators.required, minValueValidator(0), maxValueValidator(360),]),
        track_bearing_reference: new UntypedFormControl(this.track_bearing_reference, [Validators.required, enumValidator(track_bearing_reference),]),
        track_bearing_uom: new UntypedFormControl(this.track_bearing_uom, [Validators.required, enumValidator(track_bearing_uom),]),
        track_ground_speed: new UntypedFormControl(this.track_ground_speed, [Validators.required, minValueValidator(0),]),
        track_ground_speed_units: new UntypedFormControl(this.track_ground_speed_units, [Validators.required, enumValidator(track_ground_speed_units),]),
        uss_name: new UntypedFormControl(this.uss_name, [Validators.required, Validators.minLength(4), Validators.maxLength(250),]),
        discovery_reference: new UntypedFormControl(this.discovery_reference, [Validators.minLength(5), Validators.maxLength(36),]),
        vdop_gps: new UntypedFormControl(this.vdop_gps, [Validators.required, minValueValidator(0),]),
      });
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.altitude_gps.setFormGroupValues();
    this.$formGroup.controls[Position.ALTITUDE_NUM_GPS_SATELLITES_FIELD_NAME].setValue(this.altitude_num_gps_satellites);
    this.$formGroup.controls[Position.COMMENTS_FIELD_NAME].setValue(this.comments);
    this.$formGroup.controls[Position.ENROUTE_POSITIONS_ID_FIELD_NAME].setValue(this.enroute_positions_id);
    this.$formGroup.controls[Position.GUFI_FIELD_NAME].setValue(this.gufi);
    this.$formGroup.controls[Position.HDOP_GPS_FIELD_NAME].setValue(this.hdop_gps);
    this.location.setFormGroupValues();
    this.$formGroup.controls[Position.TIME_MEASURED_FIELD_NAME].setValue(this.time_measured);
    this.$formGroup.controls[Position.TIME_SENT_FIELD_NAME].setValue(this.time_sent);
    this.$formGroup.controls[Position.TRACK_BEARING_FIELD_NAME].setValue(this.track_bearing);
    this.$formGroup.controls[Position.TRACK_BEARING_REFERENCE_FIELD_NAME].setValue(this.track_bearing_reference);
    this.$formGroup.controls[Position.TRACK_BEARING_UOM_FIELD_NAME].setValue(this.track_bearing_uom);
    this.$formGroup.controls[Position.TRACK_GROUND_SPEED_FIELD_NAME].setValue(this.track_ground_speed);
    this.$formGroup.controls[Position.TRACK_GROUND_SPEED_UNITS_FIELD_NAME].setValue(this.track_ground_speed_units);
    this.$formGroup.controls[Position.USS_NAME_FIELD_NAME].setValue(this.uss_name);
    this.$formGroup.controls[Position.DISCOVERY_REFERENCE_FIELD_NAME].setValue(this.discovery_reference);
    this.$formGroup.controls[Position.VDOP_GPS_FIELD_NAME].setValue(this.vdop_gps);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

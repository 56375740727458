<div class="helper-wrapper">
  <p>Instructions: Enter either lat/lon coordinates in the fields below or paste a series of lat/lon, DMS, or KML
    coordinates into any of the fields and the values will be parsed into lat/lon coordinates.</p>
</div>

<error-container [control]="fa" [displayAlways]="true">
  <error-message [has]="'required'"><cds-icon [shape]="'exclamation-circle'"></cds-icon> At least one point is required</error-message>
  <error-message [has]="'min'"><cds-icon [shape]="'exclamation-circle'"></cds-icon> At least three points are required</error-message>
  <error-message [has]="'duplicateCoordinates'"><cds-icon [shape]="'exclamation-circle'"></cds-icon> Duplicate coordinates detected</error-message>
  <error-message [has]="'selfIntersectingPolygon'"><cds-icon [shape]="'exclamation-circle'"></cds-icon> Polygon is self-intersecting</error-message>
</error-container>
<button type="button" class="btn btn-sm btn-icon swap-btn" aria-label="swap" (click)="swapComponents()">
  <cds-icon shape="switch"></cds-icon> Swap Lat/Lon
</button>
<table class="table points-editor-table" aria-label="Manual polygon points editor">
    <thead>
      <tr>
          <th></th>
          <th>Latitude</th>
          <th>Longitude</th>
      </tr>
    </thead>

    <tbody class="coord-body">
      <tr *ngFor="let fg of fa.controls; let i = index">
          <td class="clr-row ordinal-col">
              <div class="clr-col-6 remove-icon">
                  <cds-icon shape="trash" size="sm" class="custom-btn-icon" (click)="removePoint(i)"></cds-icon>
              </div>
              <div class="clr-col-6 arrows">
                  <span class="arrow-icon"><cds-icon shape="arrow" size="sm" class="custom-btn-icon"
                                                     (click)="movePoint(-1, i)"></cds-icon></span>
                  <span class="arrow-icon arrow-down"><cds-icon shape="arrow" size="sm" class="custom-btn-icon"
                                                                (click)="movePoint(1, i)"></cds-icon></span>
              </div>
          </td>
          <td>
              <clr-input-container>
                  <input clrInput [formControl]="fg.controls.latitude" type="number" (paste)="handlePaste($event, i, fg.controls.latitude)"/>
                  <clr-control-error *clrIfError="'required'">Latitude is required</clr-control-error>
                  <clr-control-error *clrIfError="'min'">Latitude must be greater than -90</clr-control-error>
                  <clr-control-error *clrIfError="'max'">Latitude must be less than 90</clr-control-error>
              </clr-input-container>
          </td>
          <td>
              <clr-input-container>
                  <input clrInput [formControl]="fg.controls.longitude" type="number" (paste)="handlePaste($event,i, fg.controls.longitude)"/>
                  <clr-control-error *clrIfError="'required'">Longitude is required</clr-control-error>
                  <clr-control-error *clrIfError="'min'">Longitude must be greater than -180</clr-control-error>
                  <clr-control-error *clrIfError="'max'">Longitude must be less than 180</clr-control-error>
              </clr-input-container>
          </td>
      </tr>
    </tbody>
</table>

<button type="button" class="btn btn-sm btn-icon add-btn" aria-label="plus" (click)="add()">
  <cds-icon shape="plus"></cds-icon> Add New Coordinates
</button>

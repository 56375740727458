import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from '../model/User';
import {ILoginResponse} from '../model/LoginResponse';

export enum AuthenticationState{
  UNAUTHENTICATED,
  AUTHENTICATED
}

export enum LoginStatus {
  SUCCESS = 'success',
  ERROR = 'error'
}

export interface LoginFailure {
  status: LoginStatus.ERROR;
  msg: string;
}

export interface LoginSuccess {
  status: LoginStatus.SUCCESS;
  /**
   * @Deprecated
   */
  token: string;
  access_token: string;
}

export interface RoleResponse {
  // username: string;
  status: string;
  roles: string[];
}

export interface AssertRoleResponse {
  status: string;
  token: string;
}

@Injectable()
export abstract class AuthService {

  protected constructor() {
    // Method not implemented
  }

  /**
   * This method returns an observable that returns the current user if one is logged in, null otherwise.
   */
  abstract watchCurrentUser(): Observable<User|null>;

  /**
   * This method should be called to force the auth service to check the login status of the user. This is useful if
   * the auth service depends on a cookie or other external state that may change without the auth service knowing.
   */
  abstract checkLoginStatus(): void;

  abstract authenticationState(): Observable<AuthenticationState>;

  abstract refreshLogin(): Observable<boolean>;

  abstract login(email: string, password: string): Observable<User | ILoginResponse>;

  abstract logout(): Observable<boolean>;

  abstract getRoles(user: User): Observable<RoleResponse>;

  abstract assertRole(user: User, newRole: string): Observable<User | ILoginResponse>;
}

export enum SubmissionState {
  NONE = 'None',
  PROCESSING = 'Processing',
  SUCCESS = 'Success',
  ERROR = 'Error'
}

export enum SubmissionMode {
  CREATE = 'Create',
  EDIT = 'Edit'
}

export interface ISubmissionStatus {
  state: SubmissionState;
  modalHeading: string;
  summary: string;
}

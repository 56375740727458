import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Contact} from '../model/Contact';

@Injectable({
  providedIn: 'root'
})
export abstract class UserService {

  constructor() {
    // Method not implemented
  }

  abstract getUserContactInformation(userId: string): Observable<Contact | null>;
}

import {Injectable} from '@angular/core';
import {IUserSettings, UserSettings, UserSettingsService} from '../user-settings.service';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {IStandardResponse} from '../../model/StandardResponse';
import {MeasurementSystemType} from '../../model/MeasurementSystem';
import {OperationActivationPolicy, vertical_reference} from '../../model/gen/utm';
import {
  DEFAULT_CONSTRAINT_DURATION,
  DEFAULT_CONSTRAINT_START_OFFSET,
  DEFAULT_OPERATION_ACTIVATION,
  DEFAULT_OPERATION_DURATION,
  DEFAULT_OPERATION_START_OFFSET
} from '../../constants';

interface UserSettingsResponse extends IStandardResponse {
  setting?: IUserSettings;
}


const DEFAULT_CLEARFORM = true;

@Injectable({
  providedIn: 'root'
})
export class RestUserSettingsService extends UserSettingsService {
  private readonly rawSettingsUrl = `${environment.baseUrl}/userserv/api/settings/user`;
  private defaultSettings: UserSettings;
  private rawSettingsSubject: ReplaySubject<UserSettings> = new ReplaySubject<UserSettings>();

  private rawSettingsSub: Subscription;


  constructor(private http: HttpClient) {
    super();

    this.defaultSettings = {
      uiScalingFactor: 1,
      measurementSystemType: MeasurementSystemType.METRIC,
      defaultVerticalReference: vertical_reference.W84,
      clearFormAfterSubmission: {
        operationSubmission: false,
        constraintSubmission: false,
        platformSubmission: DEFAULT_CLEARFORM,
        userSubmission: DEFAULT_CLEARFORM
      },
      operationStartOffset: DEFAULT_OPERATION_START_OFFSET,
      operationDuration: DEFAULT_OPERATION_DURATION,
      constraintStartOffset: DEFAULT_CONSTRAINT_START_OFFSET,
      constraintDuration: DEFAULT_CONSTRAINT_DURATION,
      operationActivation: DEFAULT_OPERATION_ACTIVATION
    };
  }

  getRawSettings(): Observable<UserSettings> {
    return this.rawSettingsSubject;
  }

  refreshSettings(reset: boolean = false): void {
    if (reset) {
      this.rawSettingsSub?.unsubscribe();
      this.rawSettingsSubject.next(this.defaultSettings);
      this.rawSettingsSub = null;
      return;
    }
    if (!this.rawSettingsSub) {
      this.rawSettingsSub = this.http.get(this.rawSettingsUrl).pipe(map((rawSettings: UserSettingsResponse): UserSettings => {
        if (!rawSettings.success) {
          return this.defaultSettings;
        }
        return {
          uiScalingFactor: rawSettings.setting.uiScalingFactor,
          measurementSystemType: MeasurementSystemType.from(rawSettings.setting.measurementSystem) || this.defaultSettings.measurementSystemType,
          defaultVerticalReference: vertical_reference[rawSettings.setting.defaultVerticalReference] || this.defaultSettings.defaultVerticalReference,
          clearFormAfterSubmission: {
            operationSubmission: getDefault(rawSettings.setting?.clearFormAfterSubmission?.operationSubmission,
              this.defaultSettings.clearFormAfterSubmission.operationSubmission),
            constraintSubmission: getDefault(rawSettings.setting?.clearFormAfterSubmission?.constraintSubmission,
              this.defaultSettings.clearFormAfterSubmission.constraintSubmission),
            platformSubmission: getDefault(rawSettings.setting?.clearFormAfterSubmission?.platformSubmission, DEFAULT_CLEARFORM),
            userSubmission: getDefault(rawSettings.setting?.clearFormAfterSubmission?.userSubmission, DEFAULT_CLEARFORM)
          },
          operationStartOffset: getDefault(rawSettings.setting.operationStartOffset, this.defaultSettings.operationStartOffset),
          operationDuration: getDefault(rawSettings.setting.operationDuration, this.defaultSettings.operationDuration),
          constraintStartOffset: getDefault(rawSettings.setting.constraintStartOffset, this.defaultSettings.constraintStartOffset),
          constraintDuration: getDefault(rawSettings.setting.constraintDuration, this.defaultSettings.constraintDuration),
          operationActivation: OperationActivationPolicy[rawSettings.setting.operationActivation] || this.defaultSettings.operationActivation
        };
      })).subscribe((s) => {
        this.rawSettingsSubject.next(s);
        this.rawSettingsSub = null;
      }, () => {
        this.rawSettingsSubject.next(this.defaultSettings);
        this.rawSettingsSub = null;
      });

    }
  }

  updateSettings(settings: UserSettings): Observable<boolean> {
    return this.http.patch(`${environment.baseUrl}/userserv/api/settings/user`, {
      uiScalingFactor: settings.uiScalingFactor,
      measurementSystem: settings.measurementSystemType,
      defaultVerticalReference: settings.defaultVerticalReference,
      clearFormAfterSubmission: settings.clearFormAfterSubmission,
      operationStartOffset: settings.operationStartOffset,
      operationDuration: settings.operationDuration,
      constraintStartOffset: settings.constraintStartOffset,
      constraintDuration: settings.constraintDuration,
      operationActivation: settings.operationActivation
    }).pipe(map((response: UserSettingsResponse) => response.success));
  }

  createSettings(settings: UserSettings): Observable<boolean> {
    return this.updateSettings(settings);
  }

}

function getDefault<T>(v: T | null | undefined, def: T): T {
  if (v === null || v === undefined) {
    return def;
  }
  return v;
}


import {Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {AbstractControl, NG_VALUE_ACCESSOR, UntypedFormArray, UntypedFormBuilder, Validators} from '@angular/forms';
import {IControlValueAccessor, IFormBuilder, IFormGroup} from '@rxweb/types';
import {IFederationDomain, IFederationPolicy} from '../../model/FederationPolicy';
import {Subscription} from 'rxjs';

interface RawValues {
  allowAll: boolean;
  domains: boolean[];
  // domains: FederationDomain[];
}

@Component({
  selector: 'edit-federation-policy',
  templateUrl: './edit-federation-policy.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFederationPolicyComponent),
    }
  ]
})
export class EditFederationPolicyComponent implements IControlValueAccessor<IFederationPolicy>, OnDestroy, OnChanges {
  @Input() domains: IFederationDomain[] = [];
  fg: IFormGroup<RawValues>;
  isDisabled: boolean;
  private onChange: (value: IFederationPolicy) => void;
  private fgb: IFormBuilder;
  private changeSub: Subscription;
  // private written: IFederationPolicy;
  private onTouched: () => void;
  // tslint:disable-next-line:variable-name
  private _domains: IFederationDomain[];

  get hasDomains(): boolean {
    return this.domains && this.domains.length > 0;
  }

  get allDomainsOn(): boolean {
    return this.fg?.controls.allowAll.value || false;
  }

  get domainFgArray(): AbstractControl[] {
    return (this.fg.controls.domains as UntypedFormArray).controls;
  }

  constructor(fgb: UntypedFormBuilder) {
    this.fgb = fgb;
    this.fg = this.fgb.group<RawValues>({
      allowAll: this.fgb.control(false, [Validators.required]),
      domains: this.fgb.array<boolean>(this.domains.map(d => false))
    });
    this.changeSub = this.fg.valueChanges.subscribe(value => {
      this.emitChange(value);
    });
  }

  registerOnChange(fn: (value: IFederationPolicy) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: IFederationPolicy): void {
    if (!obj) {
      return;
    }

    const domainUpdates: IFederationDomain[] = [];
    for (const domain of (obj.domains || [])) {
      if (!this.domains.find(d => d.id === domain)) {
        domainUpdates.push({id: domain, prettyName: domain});
      }
    }
    this.domains = [...this.domains || [], ...domainUpdates];
    // eslint-disable-next-line no-underscore-dangle
    this._domains = this.domains;
    this.initDomainControls(this.domains.filter(d => !!(obj.domains || []).find(od => d.id === od)));
    this.fg.setValue({
      allowAll: obj.allowAll,
      domains: this.domains.map(d => obj.domains.includes(d.id))
    }, {emitEvent: false});

  }

  emitChange(value: RawValues): void {

    const change: IFederationPolicy = {
      allowAll: value.allowAll,
      domains: value.allowAll ? [] : this.domains.filter((v, i) => value.domains[i]).map(d => d.id)
    };

    if (this.onChange) {
      this.onChange(change);
    }
  }

  ngOnDestroy(): void {
    this.changeSub?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.domains) {
      if (!this.fg) {
        return;
      }
      const domainState = this.fg.value.domains;

      let domains: IFederationDomain[] = changes.domains.previousValue;
      /* eslint-disable no-underscore-dangle */
      if (this._domains) {
        domains = this._domains;
      }
      /* eslint-enable no-underscore-dangle */
      if (!domains) {
        return;
      }

      const curDomains = domains.filter((d, i) => domainState[i]);
      this.initDomainControls(curDomains);

    }
  }

  private initDomainControls(curDomains: IFederationDomain[]) {
    (this.fg.controls.domains as UntypedFormArray).clear({emitEvent: false});

    let i = 0;
    for (const control of this.domains.map((d) => this.fgb.control(!!curDomains.find(cd => cd.id === d.id)), [Validators.required])) {

      (this.fg.controls.domains as UntypedFormArray).insert(i, control, {emitEvent: false});
      ++i;
    }
  }
}

import {Injectable} from '@angular/core';
import {ApprovalService} from '../approval.service';
import {Observable} from 'rxjs';
import {SearchResult} from '../../model/SearchResult';
import {ApprovalInfo} from '../../model/gen/utm/approval-info';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {isNil} from 'lodash';
import {map} from 'rxjs/operators';
import {Parser} from '../../model/utm/parser/OperationParser';
import {OperationApproval} from '../../model/OperationApproval';
import {ApprovalStatus} from '../../model/gen/utm';

@Injectable({
  providedIn: 'root'
})
export class RestApprovalService extends ApprovalService {
  constructor(private http: HttpClient) {
    super();
  }

  getOperationApprovals(status: ApprovalStatus[], limit?: number, offset?: number, fetchCount?: boolean): Observable<SearchResult<ApprovalInfo>> {
    return this.http.get(`${environment.baseUrl}/operator/approvals/search`,
      {
        params: {
          status,
          limit: (!isNil(limit) ? limit : 10).toString(),
          offset: (offset || 0).toString(),
          fetch_count: (!isNil(fetchCount) ? fetchCount : true).toString()
        }
      }).pipe(map((response: any) => new SearchResult((response.approvals || [])
      .map(Parser.parseApprovalInfo), response.count, response.offset)));
  }

  approveOperation(data: OperationApproval): Observable<boolean> {
    return this.http.post(`${environment.baseUrl}/operator/approvals/approve`, data)
      .pipe(map(() => true));
  }
}

@if (!operation) {
  <div>
    <span class="spinner">
      Loading...
    </span>
  </div>
} @else {
  <!-- Uncomment the code below to enable the "requires rerouting" icon-
  <div *ngIf="operation?.replan_required" class="clr-row alert-row replan-warning">
    <div class="clr-col-md-12">
      <clr-alerts>
        <clr-alert [clrAlertType]="'warning'" [clrAlertClosable]="false">
          <clr-alert-item>
            <span class="alert-text">Operation requires replanning</span>
          </clr-alert-item>
        </clr-alert>
      </clr-alerts>
    </div>
  </div>-->

  <!-- Alerts Section -->
  @if (!operation.managed) {
    <div class="clr-row external-alert">
      <div class="clr-col-md-12">
        <clr-alerts>
          <clr-alert [clrAlertType]="'info'" [clrAlertClosable]="false">
            <clr-alert-item>
              <span class="alert-text">This operation was submitted by an external USS and has limited information available.</span>
            </clr-alert-item>
          </clr-alert>
        </clr-alerts>
      </div>
    </div>
  }

  <div class="clr-row">
    <div class="clr-col-md-6 clr-col-sm-12">
      <h2 cds-text="section">General Information</h2>
      <table class="table" aria-label="General operation information">
        <tbody>
          <tr>
            <th scope="row">State</th>
            <td>
              <app-operation-state-badge [operation]="operation" [watchState]="false"></app-operation-state-badge>
            </td>
          </tr>
          @if (conflictInfo?.failureReason) {
            <tr>
              <th scope="row">Rejection Reason</th>
              <td>{{conflictInfo.failureReason}}</td>
            </tr>
          }
          <tr>
            <th scope="row">Operation Name</th>
            <td>{{operation.flight_number}}</td>
          </tr>
          <tr>
            <th scope="row">Operation Description</th>
            <td>{{operation.flight_comments || fallbackText}}</td>
          </tr>
          <tr>
            <th scope="row">Operation Type</th>
            <td>
              {{prettyOperationType}}
            </td>
          </tr>
          <tr>
            <th scope="row">Priority</th>
            <td>
              {{operation.priority}}
            </td>
          </tr>
          <tr>
            <th scope="row">Operation ID</th>
            <td>{{operation.operationId}} <app-copy-to-clipboard [text]="operation.operationId"></app-copy-to-clipboard></td>
          </tr>
          <tr>
            <th scope="row">USS Name</th>
            <td>{{operation.uss_name}}</td>
          </tr>
          <tr>
            <th scope="row">Submit Time</th>
            <td>{{operation.submit_time?.toISO() || fallbackText}}</td>
          </tr>
          <tr>
            <th scope="row">Effective Time Begin</th>
            <td>
              {{operation.effective_time_begin.toISO()}}
            </td>
          </tr>
          <tr>
            <th scope="row">Effective Time End</th>
            <td>
              {{operation.effective_time_end.toISO()}}
            </td>
          </tr>
          @if (operation.additional_data) {
            <tr>
              <th scope="row">Public Access</th>
              <td>{{operation.additional_data.public_access ? 'Yes' : 'No'}}</td>
            </tr>
          }
          <tr>
            <th scope="row">Version</th>
            <td>
              {{operation.version || fallbackText}}
            </td>
          </tr>
          @if (operation.managed) {
            <tr>
              <th scope="row">Activation Policy</th>
              <td>
                {{prettyActivationPolicy || fallbackText}}
              </td>
            </tr>
          }
        </tbody>
      </table>

      @if (operation.managed) {
        <h2 cds-text="section">UAS Information</h2>
        <table class="table" aria-label="UAS information">
          <thead>
            <tr>
              <th>Name</th>
              <th>Model</th>
              <th>UVIN</th>
            </tr>
          </thead>
          <tbody>
            @for (registration of operationRegistrations; track registration.uvin) {
              <tr>
                <td>{{registration.name || fallbackText}}</td>
                <td>{{registration.aircraftSpecs.model || fallbackText}}</td>
                <td>{{registration.uvin || fallbackText}}</td>
              </tr>
            }
          </tbody>
        </table>

        <h2 cds-text="section">Pilot Contact Information</h2>
        <table class="table" aria-label="Pilot contact information">
          <tbody>
            <tr>
              <th scope="row">Title</th>
              <td>{{operation.contact.title || fallbackText}}</td>
            </tr>
            <tr>
              <th scope="row">Name</th>
              <td>{{operation.contact.name || fallbackText}}</td>
            </tr>
            <tr>
              <th scope="row">Phone Numbers</th>
              <td>
                <ul>
                  @for (phone of operation.contact.phone_numbers; track $index) {
                    <li style="list-style: none;">{{phone || fallbackText}}</li>
                  }
                </ul>
              </td>
            </tr>
            <tr>
              <th scope="row">Emails</th>
              <td>
                <ul>
                  @for (email of operation.contact.email_addresses; track $index) {
                    <li style="list-style: none;">{{email || fallbackText}}</li>
                  }
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        @if (operation.additional_data) {
          <h2 cds-text="section">Submitting User Information</h2>
          <table class="table table-vertical" aria-label="Submitting user information">
            <tbody>
              <tr>
                <th scope="row">Username</th>
                <td>{{operation.additional_data.user || fallbackText}}</td>
              </tr>
              @if (submittingUser) {
                <tr>
                  <th scope="row">Title</th>
                  <td>{{submittingUser.title || fallbackText}}</td>
                </tr>
                <tr>
                  <th scope="row">Name</th>
                  <td>{{submittingUser.getName() || fallbackText}}</td>
                </tr>
                <tr>
                  <th scope="row">Phone Numbers</th>
                  <td>
                    <ul>
                      @for (phone of submittingUser.phones || []; track $index) {
                        <li style="list-style: none;">{{phone || fallbackText}}</li>
                      }
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Emails</th>
                  <td>
                    <ul>
                      @for (email of submittingUser.emails; track $index)  {
                        <li style="list-style: none;">{{email || fallbackText}}</li>
                      }
                    </ul>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        }
      }
    </div>
    <div class="clr-col-md-6 clr-col-sm-12">
      <div class="cesium-wrapper">
        @if (enableExperimentalDirectMessagingSupport && operation.state !== operationState.CLOSED &&
        operation.state !== operationState.REJECTED && operation.managed && operation.additional_data?.user_id !== currentUser?.uid) {
          <div class="msg-btn-wrapper">
            <button class="btn btn-secondary" (click)="openSendMessageModal()">
              <cds-icon shape="chat-bubble" solid="true"></cds-icon>Message Owner
            </button>
          </div>
        }
        @if (operation.operation_volumes) {
          @defer (on timer(0)){
            <ax-cesium-container [timeline]="false"
                               [navigationInstructionsInitiallyVisible]="false"
                                 [projectionPicker]="true"
                               [toolbarZIndex]="'1000'"
                               [sceneModePicker]="false">
              <app-operation-volume-drawer [operation]="operation"></app-operation-volume-drawer>
            </ax-cesium-container>
          }
        }
      </div>

      @if (!!replanInfo?.replannedFrom.length) {
        <div class="replanned-from">
          <h2 cds-text="section">Replanned From</h2>
          <table class="table" aria-label="Replanned from operation information">
            <thead>
              <th>Operation ID</th>
              <th>State</th>
              @if (deviceSize$() !== 'sm') {
                <th>Begin Time</th>
                <th>End Time</th>
              }
              <th></th>
            </thead>
            <tbody>
              @for (replan of replanInfo.replannedFrom; track $index) {
                <tr>
                  <td>{{replan.operationId}}</td>
                  <td><app-operation-state-badge [state]="replan.state"></app-operation-state-badge></td>
                  @if (deviceSize$() !== 'sm') {
                    <td>{{replan.effective_time_begin}}</td>
                    <td>{{replan.effective_time_end}}</td>
                  }
                  <td>
                    <button type="button" class="btn btn-primary" [routerLink]="'/fuss/operations/view-operation'"
                            [queryParams]="{operationId: replan.operationId}">View Operation</button>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      }

      @if (replanInfo?.replannedTo.length) {
        <div class="replanned-from">
          <h2 cds-text="section">Replanned To</h2>
          <table class="table" aria-label="Replanned to operation information">
            <thead>
              <tr>
                <th>Operation ID</th>
                <th>State</th>
                @if (deviceSize$() !== 'sm') {
                  <th>Begin Time</th>
                  <th>End Time</th>
                }
                <th></th>
              </tr>
            </thead>
            <tbody>
              @for (replan of replanInfo.replannedTo; track $index) {
                <tr>
                  <td>{{replan.operationId}}</td>
                  <td><app-operation-state-badge [state]="replan.state"></app-operation-state-badge></td>
                  @if (deviceSize$() !== 'sm') {
                    <td>{{replan.effective_time_begin}}</td>
                    <td>{{replan.effective_time_end}}</td>
                  }
                  <td>
                    <button type="button" class="btn btn-primary" [routerLink]="'/fuss/operations/view-operation'"
                            [queryParams]="{operationId: replan.operationId}">View Operation</button>
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      }

      @if (operation.approval) {
        <h2 cds-text="section">Approval Information</h2>
        <table class="table" aria-label="Approval information">
          <tbody>
            <tr>
              <th scope="row">Approved</th>
              <td>{{operation.approval.approved}}</td>
            </tr>
            @if (operation.approval.approvedByUserName) {
              <tr>
                <th scope="row">Approved By</th>
                <td>{{operation.approval.approvedByUserName}}</td>
              </tr>
            }
          </tbody>
        </table>
      }
    </div>

    <div class="clr-col-md-12">
      <h2 cds-text="section">Flight Volumes</h2>
      @if (deviceSize$() === 'lg') {
        <table class="table" aria-label="Flight volumes information">
          <thead>
            <tr>
              <th>Ordinal</th>
              <th>Off Nominal</th>
              <th>Volume Type</th>
              <th>Beyond Visual Line Of Sight</th>
              <th>Effective Time Begin</th>
              <th>Effective Time End</th>
              <th>Min Altitude</th>
              <th>Max Altitude</th>
              <th>Submitted Min Altitude</th>
              <th>Submitted Max Altitude</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            @for (volume of operation.operation_volumes; track $index) {
              <tr>
                <td>{{$index + 1}}</td>
                <td>{{volume.off_nominal}}</td>
                <td>{{volume.volume_type}}</td>
                @if (operation.managed) {
                  <td>{{volume.beyond_visual_line_of_sight? 'Yes':'No'}}</td>
                } @else {
                  <td>{{fallbackText}}</td>
                }
                <td>{{volume.effective_time_begin.toISO()}}</td>
                <td>{{volume.effective_time_end.toISO()}}</td>
                <td>
                  <app-ax-altitude-summary [altitude]="volume.min_altitude"
                                           [referencePoint]="volume.circle ? [volume.circle.longitude, volume.circle.latitude]
                                            : volume.geography.coordinates[0][0]">
                  </app-ax-altitude-summary>
                </td>
                <td>
                  <app-ax-altitude-summary [altitude]="volume.max_altitude"
                                           [referencePoint]="volume.circle ? [volume.circle.longitude, volume.circle.latitude]
                                            : volume.geography.coordinates[0][0]">
                  </app-ax-altitude-summary>
                </td>
                <td>
                  @if (operation.managed && !volume.off_nominal) {
                    <app-ax-altitude-summary [convertToPreferredUnitRef]="false" [altitude]="volume.submitted_min_altitude">
                    </app-ax-altitude-summary>
                  } @else {
                    {{fallbackText}}
                  }
                </td>
                <td>
                  @if (operation.managed && !volume.off_nominal) {
                    <app-ax-altitude-summary [convertToPreferredUnitRef]="false" [altitude]="volume.submitted_max_altitude">
                    </app-ax-altitude-summary>
                  } @else {
                    {{fallbackText}}
                  }
                </td>
                <td>
                  <button type="button" class="btn btn-primary" (click)="viewVolume($index)">View Volume</button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      } @else {
        <clr-datagrid class="datagrid-compact">
          <clr-dg-column>Ordinal</clr-dg-column>
          <clr-dg-column>Effective Time Begin</clr-dg-column>
          <clr-dg-column>Effective Time End</clr-dg-column>

          @for (volume of operation.operation_volumes; track $index) {
            <clr-dg-row>
              <clr-dg-cell>{{$index + 1}}</clr-dg-cell>
              <clr-dg-cell>{{volume.effective_time_begin.toISO()}}</clr-dg-cell>
              <clr-dg-cell>{{volume.effective_time_end.toISO()}}</clr-dg-cell>

              <clr-dg-row-detail *clrIfExpanded>
                <table class="table table-vertical" aria-label="Volume details">
                  <tbody>
                    <tr>
                      <th scope="row">Off Nominal</th>
                      <td>{{volume.off_nominal}}</td>
                    </tr>
                    <tr>
                      <th scope="row">Volume Type</th>
                      <td>{{volume.volume_type}}</td>
                    </tr>
                    @if (operation.managed) {
                      <tr>
                        <th scope="row">Beyond Visual Line Of Sight</th>
                        <td>{{volume.beyond_visual_line_of_sight? 'Yes':'No'}}</td>
                      </tr>
                    }
                    <tr>
                      <th scope="row">Min Altitude</th>
                      <td>
                        <app-ax-altitude-summary [altitude]="volume.min_altitude"
                                                 [referencePoint]="volume.circle ? [volume.circle.longitude, volume.circle.latitude] : volume.geography.coordinates[0][0]">
                        </app-ax-altitude-summary>
                      </td>
                    </tr>
                    <tr>
                      <th scope="row">Max Altitude</th>
                      <td>
                        <app-ax-altitude-summary [altitude]="volume.max_altitude"
                                                 [referencePoint]="volume.circle ? [volume.circle.longitude, volume.circle.latitude] : volume.geography.coordinates[0][0]">
                        </app-ax-altitude-summary>
                      </td>
                    </tr>
                    @if (operation.managed && !volume.off_nominal) {
                      <tr>
                        <th scope="row">Submitted Min Altitude</th>
                        <td>
                          <app-ax-altitude-summary [convertToPreferredUnitRef]="false" [altitude]="volume.submitted_min_altitude">
                          </app-ax-altitude-summary>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Submitted Max Altitude</th>
                        <td>
                          <app-ax-altitude-summary [convertToPreferredUnitRef]="false" [altitude]="volume.submitted_max_altitude">
                          </app-ax-altitude-summary>
                        </td>
                      </tr>
                    }
                    <tr>
                      <th scope="row"></th>
                      <td>
                        <button type="button" class="btn btn-primary" (click)="viewVolume($index)">View Volume</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </clr-dg-row-detail>
            </clr-dg-row>
          }
        </clr-datagrid>
      }
    </div>

    @if (!!(conflictingOps | keyvalue).length) {
      <div class="clr-col-md-12">
        <h2 cds-text="section">Conflicting Operations</h2>
        <div class="clr-row">
          <div class="clr-col-md-12">
            <table class="table" aria-label="Conflicting operations information">
              <thead>
                <tr>
                  <th>Operation Name</th>
                  @if (deviceSize$() !== 'sm') {
                    <th>Effective Begin Time</th>
                    <th>Effective End Time</th>
                  }
                  <th></th>
                </tr>
              </thead>
              <tbody>
                @for (op of conflictingOps | keyvalue; track $index) {
                  <tr>
                    <td>{{op.value.flight_number}}</td>
                    @if (deviceSize$() !== 'sm') {
                      <td>{{op.value.effective_time_begin.toISO()}}</td>
                      <td>{{op.value.effective_time_end.toISO()}}</td>
                    }
                    <td><button type="button" class="btn btn-primary" (click)="viewOperationDetails(op.value)">View Details</button></td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    }

    @if (!!(conflictingConstraints | keyvalue).length) {
      <div class="clr-col-md-12">
        <h2 cds-text="section">Conflicting Constraints</h2>
        <div class="clr-row">
          `<div class="clr-col-md-12 clr-col-sm-12">
            <table class="table" aria-label="Conflicting constraints information">
              <thead>
                <tr>
                  <th>Constraint Name</th>
                  @if (deviceSize$() !== 'sm') {
                    <th>Effective Begin Time</th>
                    <th>Effective End Time</th>
                  }
                  <th></th>
                </tr>
              </thead>
              <tbody>
              @for (constraint of conflictingConstraints | keyvalue; track $index) {
                <tr>
                  <td>{{constraint.value.reason}}</td>
                  @if (deviceSize$() !== 'sm') {
                    <td>{{constraint.value.effective_time_begin.toISO()}}</td>
                    <td>{{constraint.value.effective_time_end.toISO()}}</td>
                  }
                  <td><button type="button" class="btn btn-primary" (click)="viewConstraintDetails(constraint.value)">View Details</button></td>
                </tr>
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    }
  </div>

  @for (volume of operation.operation_volumes; track $index) {
    <clr-modal [(clrModalOpen)]="showGeometry[$index]" [clrModalSize]="'xl'">
      <div class="modal-body">
        <div class="cesium-wrapper">
          <ax-cesium-container [timeline]="false"
                               [navigationInstructionsInitiallyVisible]="false"
                               [projectionPicker]="true"
                               [sceneModePicker]="false">
            <app-operation-volume-drawer [operation]="operation" [operationVolumeIndex]="$index"></app-operation-volume-drawer>
          </ax-cesium-container>
        </div>
      </div>
    </clr-modal>
  }

  <clr-modal [(clrModalOpen)]="showConflictingOp" [clrModalSize]="'xl'">
    <div class="modal-body">
      <app-ax-operation-summary [operation]="openOp"></app-ax-operation-summary>
    </div>
  </clr-modal>

  <clr-modal [(clrModalOpen)]="showConflictingConstraint" [clrModalSize]="'xl'">
    <div class="modal-body">
      <app-constraint-ext-summary [constraint]="openConstraint"></app-constraint-ext-summary>
    </div>
  </clr-modal>
}

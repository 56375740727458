/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';

import {point_type, uasvolumereservation_type} from './enums';

export interface IPoint {
  type?: point_type;
  coordinates?: Array<number>;
}


export class Point extends BaseModel implements IPoint {

  static TYPE_FIELD_NAME = 'type';
  static COORDINATES_FIELD_NAME = 'coordinates';

  /** The type of Geometry. In this case, must be 'Point' per GeoJSON spec.
   */
  type: point_type = point_type.Point;
  coordinates: Array<number>;
  get lat(): number{
    return this.coordinates[1];
  }
  get lng(): number{
    return this.coordinates[0];
  }
  get alt(): number{
    return this.coordinates[2];
  }

  get hasAltitude(): boolean{
    return this.coordinates.length >= 3;
  }

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IPoint>, useFormGroupValuesToModel = false) {
    super();
    this.coordinates = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  static fromLatLong(lat:number, lon: number){

      return new Point({
        coordinates: [lon, lat]
      });

  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IPoint>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.type = this.getValue<point_type>(rawValues, Point.TYPE_FIELD_NAME);
      this.fillModelArray<number>(this, Point.COORDINATES_FIELD_NAME, rawValues.coordinates, useFormGroupValuesToModel);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        type: new UntypedFormControl(this.type, [enumValidator(uasvolumereservation_type),]),
        coordinates: new UntypedFormArray([]),
      });
      // generate FormArray control elements
      this.fillFormArray<number>(Point.COORDINATES_FIELD_NAME, this.coordinates);
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[Point.TYPE_FIELD_NAME].setValue(this.type);
    this.fillFormArray<number>(Point.COORDINATES_FIELD_NAME, this.coordinates);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  getLatitude() {
    return this.coordinates[1];
  }

  getLongitude() {
    return this.coordinates[0];
  }
}

import {Component, forwardRef, HostListener, input, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-datetime-picker',
  templateUrl: './datetime-picker.component.html',
  styleUrls: ['./datetime-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => DatetimePickerComponent),
    }
  ]
})
export class DatetimePickerComponent implements ControlValueAccessor {
  @Input() label = 'Date & Time:';
  @Input() required: boolean = false;
  placeholder = input<string>('');

  disabled: boolean;
  internalValue: Date;
  value: DateTime;
  private onChange: (value: DateTime) => void;
  private onTouched: () => void;

  @HostListener('touchend')
  touched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  registerOnChange(fn: (value: DateTime) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: DateTime | string): void {
    if (obj) {
      if (typeof obj === 'string') {
        this.value = DateTime.fromISO(obj);
      } else {
        this.value = obj;
      }
      this.internalValue = this.value.toJSDate();
    } else {
      this.value = null;
      this.internalValue = null;
    }

  }

  /**
   * Clear the selected datetime value
   */
  clearDateTime() {
    this.value = null;
    this.internalValue = null;

    this.touched();
    this.onChange?.(this.value);
  }

  /**
   * Update the datetime value whenever the model change event is emitted from the datetime picker
   * @param $event The date object emitted from the datetime picker
   */
  onValueChange($event: Date) {
    this.internalValue = $event;
    this.value = DateTime.fromJSDate($event).set({millisecond: 0});
    this.touched();
    this.onChange?.(this.value);
  }
}

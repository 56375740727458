<div class="login-wrapper">
  <form [formGroup]="formGroup" class="login">
    <h1 style="width:100%;margin:0 auto;text-align:center; padding-bottom:50px;">{{ussName}}</h1>
    <div class="login-group">
      <clr-alert *ngFor="let alert of alerts" [clrAlertType]="alert.Level" [clrAlertClosable]="true">
        <clr-alert-item>
            <span class="alert-text">
              {{alert.Message}}
            </span>
        </clr-alert-item>
      </clr-alert>

      <clr-input-container>
        <input autocomplete="email" type="text" formControlName="email" clrInput placeholder="Email"/>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        <clr-control-error *clrIfError="'email'">Invalid email format</clr-control-error>
      </clr-input-container>
      <clr-password-container>
        <input autocomplete="password" type="password" formControlName="password" clrPassword placeholder="Password"/>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{formGroup.controls.password.errors.invalidCharacters.characters}}</clr-control-error>
      </clr-password-container>
      <button type="submit" class="btn btn-primary" (click)="handleLogin()" [disabled]="formGroup.invalid && formGroup.touched">
        Login</button>
    </div>
  </form>
</div>

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OperationsComponent} from './operations/operations.component';
import {NewOperationsComponent} from './new-operations/new-operations.component';
import {OperationComponent} from './operation/operation.component';
import {AuthGuard} from '../../auth/auth.guard';
import {PermissionGuardService} from '../../shared/permissions/PermissionGuardService';
import {OperationApprovalsComponent} from './operations-approvals/operation-approvals.component';


const routes: Routes = [
  // {path: '', component: OperationsComponent},
  {
    path: 'ops', component: OperationsComponent, children: [
      {path: ':operationId', component: OperationComponent}
    ], canActivate: [AuthGuard, PermissionGuardService.forPermission('view_operation')]
  },
  // {path: 'oquery', component: OperationsQueryComponent},
  {path: 'newop', component: NewOperationsComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('submit_operation')]}, // canDeactivate: [ChangesGuard]},
  {path: 'view-operation', component: OperationComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('view_operation')]},
  {path: 'approvals', component: OperationApprovalsComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('view_operation')]},
  // {path: 'contingencyplans', component: ContingencyPlansComponent},
  // {path: 'mops', component: MannedOperationsComponent},
  // {path: 'atmairreserv', component: MannedAirspaceManagementComponent},
  // {path: 'uvrs', component: UvrsComponent},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OperationsRoutingModule {
}

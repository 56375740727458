import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UTMFlightPlan} from '../common';

import {Command, Convert, MissionItem, MissionItemType, SwiftFlightPlan} from './SwiftParser';
import {LatLngPoint} from '../../../../../../model/WaypointParser';
import {
  FlightPlanComponent,
  FlightPlanComponentOptions,
  generateFlightPlanComponentVolumes,
  GeometricStuff,
  PolygonComponent,
  SegmentComponent
} from '../../../../../../utils/PointBufferer2';
import {OperationGeometry} from '../../../../../../model/operation.geometry';
import {OperationVolume, units_of_measure, vertical_reference} from '../../../../../../model/gen/utm';

import * as toGeoJSON from '@tmcw/togeojson';
import {AllGeoJSON, center} from '@turf/turf';
import {Polygon} from 'geojson';
import {Observable, of} from 'rxjs';

const zeroValidator = Validators.min(0);


export class SwiftUTMFlightPlan implements UTMFlightPlan {

  constructor(private flightPlan: SwiftFlightPlan) {
    const pos = flightPlan.mission.homePosition;
    if (pos && !(pos.latitude === 0 && pos.longitude === 0 && pos.altitude === 0)) {
      this.options.controls.homePosition.setValue({
        lat: pos.latitude,
        lng: pos.longitude,
        alt: pos.altitude
      });
    } else {
      const estimatedHome = this.estimateHomePosition();
      this.options.controls.homePosition.setValue(estimatedHome);

    }


  }

  readonly options = new FormGroup({
    homePosition: new FormControl<LatLngPoint>(null, [Validators.required]),
    horizontalBufferDistanceMeters: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_HORIZONTAL_BUFFER_DISTANCE_METERS, [
      Validators.required,
      Validators.min(1)
    ]),
    verticalBufferDistanceMeters: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_VERTICAL_BUFFER_DISTANCE_METERS, [
      Validators.required,
      Validators.min(1)
    ]),
    // bufferUnits: new FormControl<units_of_measure>(units_of_measure.M, [Validators.required]),
    steps: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_STEPS, [
      Validators.required,
      Validators.min(3)
    ])
  });

  static fromText(text: string): SwiftUTMFlightPlan {
    const rawFlightPlan = Convert.toSwiftFlightPlan(text);

    return new SwiftUTMFlightPlan(rawFlightPlan);
  }

  private getFlightPlanComponents(): FlightPlanComponent[] {
    const mission = this.flightPlan.mission;
    const home = this.options.controls.homePosition.value;
    let lastPoint = home;
    let volumes: FlightPlanComponent[] = [];
    let point: LatLngPoint;

    for (const [index, item] of mission.items.entries()) {
      if (item.type === MissionItemType.ComplexItem) {
        const [tmpVolumes, tmpPoint] = this.handleComplexItem(item, index, home, lastPoint);
        if (volumes.length === 0) {
          volumes.push({
            kind: 'segment',
            index,
            a: lastPoint,
            b: tmpPoint
          });
        }
        volumes = volumes.concat(tmpVolumes);
        lastPoint = tmpPoint;
      } else if (item.type === MissionItemType.SimpleItem) {
        switch (item.command) {
          case Command.MavCmdNavWaypoint:
            point = {
              lat: item.latitude,
              lng: item.longitude,
              alt: item.altitude
            };
            volumes.push({
              kind: 'segment',
              index,
              a: lastPoint,
              b: point
            });
            lastPoint = point;
            break;
          case Command.MavCmdNavLoiterUnlim:
            point = {
              lat: item.latitude,
              lng: item.longitude,
              alt: item.altitude
            };
            volumes.push({
              kind: 'segment',
              index,
              a: lastPoint,
              b: point
            });

            volumes.push({
              kind: 'loiter',
              index,
              center: point,
              radius: Math.abs(item.params[2])
            });
            lastPoint = point;
            break;
          case Command.MavCmdNavVtolTakeoff:
            point = {
              lat: item.latitude,
              lng: item.longitude,
              alt: item.altitude
            };
            if (point.lat === 0) {
              point.lat = lastPoint.lat;
            }
            if (point.lng === 0) {
              point.lng = lastPoint.lng;
            }
            volumes.push({
              kind: 'segment',
              index,
              a: lastPoint,
              b: point
            });
            lastPoint = point;
            break;
          case Command.MavCmdNavVtolLand:
            point = {
              lat: item.latitude,
              lng: item.longitude,
              alt: home.alt
            };
            if (point.lat === 0) {
              point.lat = lastPoint.lat;
            }
            if (point.lng === 0) {
              point.lng = lastPoint.lng;
            }
            volumes.push({
              kind: 'segment',
              index,
              a: lastPoint,
              b: point
            });
            lastPoint = point;
            break;
          case Command.MavCmdDoJump:
            const jumpToIndex = item.params[0] - 1;
            const i = volumes.findIndex((vol) => vol.index === jumpToIndex);
            if (i < 0) {
              console.error('Invalid jump detected');
              continue;
            }
            const targeCmd = mission.items[jumpToIndex];
            const segment: SegmentComponent = {
              kind: 'segment',
              index,
              a: lastPoint,
              b: {
                lat: targeCmd.latitude,
                lng: targeCmd.longitude,
                alt: targeCmd.altitude
              }
            };
            volumes.push(segment);
            volumes.push({
              kind: 'group',
              index,
              items: [...volumes.slice(i)]
            });


            break;
          default:
            console.error('Unknown command');
            break;
        }
      }


    }


    return volumes;
  }


  toOperationGeometry(): Observable<OperationGeometry> {

    const volumes = this.getFlightPlanComponents();
    const rawOptions = this.options.getRawValue();
    const options: FlightPlanComponentOptions = {
      steps: rawOptions.steps,
      bufferDistance: rawOptions.horizontalBufferDistanceMeters,
      bufferUnits: units_of_measure.M,
      endTime: undefined,
      startTime: undefined,
      verticalBufferMeters: rawOptions.verticalBufferDistanceMeters,
      verticalReference: vertical_reference.AGL,
      verticalUnits: units_of_measure.M
    };

    let results: OperationVolume[] = [];
    for (const segment of volumes) {
      results = results.concat(generateFlightPlanComponentVolumes(segment, options));
    }

    const res = new OperationGeometry(
      results,
      undefined,
      undefined,
      this.options.controls.homePosition.value
    );

    return of(res);
  }

  private handleComplexItem(item: MissionItem, index: number, home: LatLngPoint, lastPoint: LatLngPoint): [FlightPlanComponent[], LatLngPoint] {
    let ret: FlightPlanComponent[] = [];
    let retLastPoint = lastPoint;
    if (item.kml) {
      const dom = new DOMParser().parseFromString(item.kml, 'text/xml');
      const kmlDoc = toGeoJSON.kml(dom);
      const altitude = item.altitude;
      if (kmlDoc.features.length > 0) {
        const centerPoint = center(kmlDoc as AllGeoJSON);
        const point = {
          lat: centerPoint.geometry.coordinates[1],
          lng: centerPoint.geometry.coordinates[0],
          alt: altitude
        };


        ret = ret.concat(
          kmlDoc.features.map((feature) => {
            switch (feature.geometry.type) {
              case 'Polygon':
                const poly = feature.geometry as Polygon;
                return {
                  kind: 'polygon',
                  index,
                  maxAltitude: altitude,
                  minAltitude: altitude,
                  points: poly.coordinates[0].map((coord) => {
                    return {
                      lat: coord[1],
                      lng: coord[0],
                      alt: altitude
                    };
                  })
                } satisfies PolygonComponent as PolygonComponent;

              default:
                return null;
            }
          }).filter((x) => x !== null)
        );
        retLastPoint = point;
      }

    }

    return [ret, retLastPoint];
  }


  private estimateHomePosition(): LatLngPoint | null {
    const landingPoint = this.estimateHomepointFromLanding();
    if (landingPoint && !(landingPoint.latitude === 0 && landingPoint.longitude === 0 && landingPoint.altitude === 0)) {
      return {
        lat: landingPoint.latitude,
        lng: landingPoint.longitude,
        alt: landingPoint.altitude
      };
    }
    const items = this.flightPlan.mission.items;
    for (const item of items) {
      if (item.type === MissionItemType.SimpleItem &&
        item.command === Command.MavCmdNavWaypoint &&
        !(item.latitude === 0 && item.longitude === 0 && item.altitude === 0)
      ) {
        return {
          lat: item.latitude,
          lng: item.longitude,
          alt: item.altitude
        };
      }
      if (item.type === MissionItemType.ComplexItem && item.kml) {
        const dom = new DOMParser().parseFromString(item.kml, 'text/xml');
        const kmlDoc = toGeoJSON.kml(dom);
        if (kmlDoc.features.length > 0) {
          const point = center(kmlDoc as AllGeoJSON);
          return {
            lat: point.geometry.coordinates[1],
            lng: point.geometry.coordinates[0],
            alt: 0
          };
        }
      }

    }

    return null;
  }

  private estimateHomepointFromLanding() {
    const items = this.flightPlan.mission.items;
    for (let i = items.length - 1; i >= 0; i--) {
      const tmp = items[i];
      if (tmp.type === MissionItemType.SimpleItem && tmp.command === Command.MavCmdNavVtolLand) {
        return {
          latitude: tmp.latitude,
          longitude: tmp.longitude,
          altitude: tmp.altitude
        };

      }
    }
    return null;
  }
}

export type SwiftFlightPlanFormGroup = Pick<SwiftUTMFlightPlan, 'options'>['options'];

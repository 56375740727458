import {Altitude, units_of_measure, vertical_reference} from '../gen/utm';
import {unit} from '../../utils/convert-units/definitions/base';
import {AltitudeService, IAltitudeConversionParameters} from '../../services/altitude.service';
import {Converter} from '../../utils/convert-units';
import * as _ from 'lodash';
import {cloneDeep} from 'lodash';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {LatLng} from "leaflet";

@Injectable({
  providedIn: 'root'
})
export class AltitudeUtilService {
  constructor(private altitudeService: AltitudeService) {
  }

  convertToWGS84Meters(altitude: Altitude, coordinates: LatLng): Observable<Altitude> {
    const convertedAltitude = cloneDeep(altitude);
    if (_.isNil(convertedAltitude.altitude_value) || !coordinates) {
      return of(convertedAltitude);
    }
    if (convertedAltitude.units_of_measure !== units_of_measure.M) {
      const unitsFrom = this.parseUnitForConversion(convertedAltitude.units_of_measure);
      const unitsTo = this.parseUnitForConversion(units_of_measure.M);

      convertedAltitude.altitude_value = new Converter(convertedAltitude.altitude_value).from(unitsFrom).to(unitsTo);
      convertedAltitude.units_of_measure = units_of_measure.M;
    }

    if (convertedAltitude.vertical_reference !== vertical_reference.W84) {
      const altParams: IAltitudeConversionParameters = {
        lat: coordinates.lat,
        lon: coordinates.lng,
        altitude: convertedAltitude.altitude_value,
        input_reference: convertedAltitude.vertical_reference,
        output_reference: vertical_reference.W84
      };

      // Convert altitudes
      return this.altitudeService.convertAltitude(altParams).pipe(map((alt) => {
        convertedAltitude.altitude_value = alt.altitude;
        convertedAltitude.vertical_reference = alt.reference;
        return convertedAltitude;
      }));
    }
    else {
      return of(convertedAltitude);
    }
  }

  parseUnitForConversion(units: units_of_measure): unit {
    switch (units) {
      case units_of_measure.M:
        return 'm';
      case units_of_measure.FT:
      default:
        return 'ft';
    }
  }

}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OffNominalReportComponent} from './off-nominal-report/off-nominal-report.component';
import {AuthGuard} from '../auth/auth.guard';
import {DashboardComponent} from './dashboard/dashboard.component';


const routes: Routes = [
  {path: 'registration', loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule), canActivate: [AuthGuard]},
  {path: 'operations', loadChildren: () => import('./operations/operations.module').then(m => m.OperationsModule), canActivate: [AuthGuard]},
  {path: 'constraint', loadChildren: () => import('./constraint/constraint.module').then(m => m.ConstraintModule), canActivate: [AuthGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard]},
  {path: 'off-nominal-report', component: OffNominalReportComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FussRoutingModule {
}

import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {Directive, OnDestroy, OnInit} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class ManagedSearchComponent<T> implements OnInit, OnDestroy {
  defaultSearch: T;
  searchConfig: T;
  previousSearchConfig: T;
  private querySub: Subscription;
  private searchConfigSubject: ReplaySubject<T> = new ReplaySubject<T>(1);

  protected constructor(initialSearch: T, protected managedRouter: Router, private managedActivatedRoute: ActivatedRoute) {
    this.searchConfig = initialSearch;
    this.defaultSearch = initialSearch;
  }

  ngOnInit(): void {
    if (!this.managedActivatedRoute) {
      return;
    }

    this.querySub = this.managedActivatedRoute.queryParamMap.subscribe((params) => {
      if (params.keys.length === 0) {
        this.searchConfigSubject.next(this.defaultSearch);
        this.setSearchConfig(this.defaultSearch);
      } else {
        this.searchConfig = this.getSearchConfigFromParamMap(params);
        this.searchConfigSubject.next(this.searchConfig);
      }
    });

  }

  setSearchConfig(config: T, forceRefresh: boolean = false) {
    this.previousSearchConfig = this.searchConfig;
    this.searchConfig = config;
    this.managedRouter.navigate([],
      {
        relativeTo: this.managedActivatedRoute,
        queryParams: this.serializeSearchConfigToParams(config),
        queryParamsHandling: 'merge'
      });
    if (forceRefresh){
      this.searchConfigSubject.next(this.searchConfig);
    }

  }

  getSearchConfig(): T {
    return this.searchConfig;
  }

  watchSearchConfig(): Observable<T> {
    return this.searchConfigSubject;
  }

  ngOnDestroy(): void {
    this.querySub?.unsubscribe();
  }

  protected isSearchDifferent(curSearch: T, prevSearch: T) {
    return JSON.stringify(curSearch) !== JSON.stringify(prevSearch);
  }

  abstract getSearchConfigFromParamMap(params: ParamMap): T;

  abstract serializeSearchConfigToParams(searchConfig: T): Params;
}

<h1 cds-text="title">User Notifications</h1>
@if (deviceSize$() !== 'sm') {
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="datagrid-compact" [(clrDgSelected)]="selectedMessages">
    <clr-dg-action-bar>
      <div class="btn-group">
        <button type="button" class="btn btn-sm btn-secondary" (click)="refresh()" >
          Refresh
        </button>
  <!--  Uncomment this code to enable marking messages as read
        <button type="button" class="btn btn-sm btn-secondary" (click)="markMessagesAsRead()" [disabled]="selectedMessages.length === 0">
          <cds-icon shape="plus"></cds-icon>
          Mark as Read
        </button>-->
        <button type="button" class="btn btn-sm btn-secondary" (click)="deleteMessages()" [disabled]="selectedMessages.length === 0">
          <cds-icon shape="close"></cds-icon>
          Delete
        </button>
      </div>
    </clr-dg-action-bar>
    <clr-dg-column class="datagrid-fixed-column datagrid-expandable-caret"></clr-dg-column>
    <clr-dg-column>Name</clr-dg-column>
    <clr-dg-column>Summary</clr-dg-column>
    <clr-dg-column>Notes</clr-dg-column>
    <clr-dg-column>Type</clr-dg-column>
    <clr-dg-column>Timestamp</clr-dg-column>
    <clr-dg-column class="datagrid-fixed-column datagrid-expandable-caret"></clr-dg-column>

    <clr-dg-placeholder>No results found</clr-dg-placeholder>

  <clr-dg-row *ngFor="let message of messages" [clrDgItem]="message"
              [ngClass]="{'unread': !message.read, 'error-row': getMessageAlertType(message) === 'danger'}"
              class="datagrid-body-row">
    <clr-dg-cell class="icon_col datagrid-fixed-column datagrid-expandable-caret">
      <cds-icon *ngIf="getMessageAlertType(message) === 'danger'" role="img" shape="error-standard"
                aria-label="exclamation-icon"></cds-icon>
    </clr-dg-cell>
    <clr-dg-cell>{{message.message.data.entityName || 'Not Available'}}</clr-dg-cell>
    <clr-dg-cell class="normal-wrap">{{getMessageSummary(message)}}</clr-dg-cell>
    <clr-dg-cell>{{getMessageNotes(message)}}</clr-dg-cell>
    <clr-dg-cell class="normal-wrap">{{message.message.type}}</clr-dg-cell>
    <clr-dg-cell>{{message.timeCreated.toISO()}}</clr-dg-cell>
    <clr-dg-cell class="datagrid-fixed-column datagrid-expandable-caret">
      <cds-button [action]="'flat'" [size]="'icon'" [block]="false" (click)="viewMessage(message)">
        <cds-icon shape="link"></cds-icon>
      </cds-button>
    </clr-dg-cell>

<!--  Uncomment this code to enable expandable details section
    <clr-dg-row-detail *clrIfExpanded>
      <app-user-message-summary [messageEntry]="messageEntry"></app-user-message-summary>
    </clr-dg-row-detail>-->
  </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize"
                         [clrDgTotalItems]="totalItems"></clr-dg-pagination>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}}
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Messages per page</clr-dg-page-size>
    </clr-dg-footer>
  </clr-datagrid>
} @else {
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="datagrid-compact">
    <clr-dg-column>Name</clr-dg-column>
    <clr-dg-column>Summary</clr-dg-column>

    <clr-dg-placeholder>No results found</clr-dg-placeholder>

    <clr-dg-row *ngFor="let message of messages" [clrDgItem]="message"
                [ngClass]="{'unread': !message.read, 'error-row': getMessageAlertType(message) === 'danger'}"
                class="datagrid-body-row">
      <clr-dg-cell>{{message.message.data.entityName || 'Not Available'}}</clr-dg-cell>
      <clr-dg-cell class="normal-wrap">{{getMessageSummary(message)}}</clr-dg-cell>

      <!--  Uncomment this code to enable expandable details section -->
      <clr-dg-row-detail *clrIfExpanded>
        <app-user-message-summary-v2 [message]="message"></app-user-message-summary-v2>
      </clr-dg-row-detail>
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize" [clrDgTotalItems]="totalItems"></clr-dg-pagination>
      <span class="datagrid-footer-description">{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}}</span>
    </clr-dg-footer>
  </clr-datagrid>
}

@if (currentMessage) {
  <clr-modal [clrModalSize]="modalSize" [(clrModalOpen)]="!!currentMessage" [clrModalStaticBackdrop]="false">
    <h3 class="modal-title">{{getMessageSummary(currentMessage)}}</h3>
    <div class="modal-body">
      <app-user-message-summary-v2 [message]="currentMessage"></app-user-message-summary-v2>
    </div>
  </clr-modal>
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ServicesModule} from './services/services.module';
import {ComponentsModule} from './components/components.module';
import {HttpClientModule} from '@angular/common/http';
import {ClarityModule} from '@clr/angular';
import {FormsModule} from '@angular/forms';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ClarityModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [],
  exports: [
    ServicesModule,
    ComponentsModule
  ]
})
export class SharedModule {
}

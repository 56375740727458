<ng-template #baseTemplate>
  <div [ngClass]="{'cellText': showText, 'cell': !showText}">
    <button title="{{title}}" type="button" id="{{allBtn}}"
            [ngClass]="{'cesium-button': true, 'button-active': state === ON, 'formatted-buttons': showText, 'button-mixed': state === MIXED, 'btn-text': showText, 'button-width': displayMode=== CELL && btnIcon, 'menu-btn': true}"
            (click)="toggleState()">
      <img *ngIf="btnIcon" alt="{{title}}" class="button" src="{{btnIcon}}"/>
      <span style="font-size:11px;" *ngIf="showText">{{title}}</span>
    </button>
  </div>
</ng-template>

<ng-template #buttonTemplate *ngIf="!embedded">
  <ng-container *ngTemplateOutlet="baseTemplate"></ng-container>
</ng-template>

<ng-container *ngIf="embedded">
  <ng-container *ngTemplateOutlet="baseTemplate"></ng-container>
</ng-container>

<ng-template #childOutputTemplate>
  <ng-container *ngIf="state !== OFF">
    <ng-container [ngTemplateOutlet]="rawChildContentTemplate"></ng-container>
  </ng-container>
</ng-template>

import {Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {ColorConfig} from "../../../services/color.service";
import * as L from "leaflet";
import {LatLng} from "leaflet";
import {BehaviorSubject, Subscription} from "rxjs";
import {LeafletDrawerService} from "@ax/ax-angular-map-leaflet";
import * as _ from "lodash";
import {CIRCLE_LIMITS, GeoCircle, units_of_measure} from "../../../model/gen/utm";
import pointOnFeature from "@turf/point-on-feature";

@Component({
  selector: 'app-leaflet-circle-editor',
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LeafletCircleEditorComponent),
    }
  ]
})
export class LeafletCircleEditorComponent implements OnChanges, OnDestroy, ControlValueAccessor {
  @Input() colorConfig: ColorConfig;
  @Input() index: number;

  private map: L.Map;
  private onChange: (v: GeoCircle) => void;
  private onTouched: () => void;
  private layerGroup: L.LayerGroup;
  private value: GeoCircle | null;
  private selectedUnits: units_of_measure;
  private layer: L.Circle;
  private tooltip: L.Tooltip;
  private indexSubject = new BehaviorSubject<number>(null);
  private indexSub: Subscription;
  private leafletInitSub: Subscription;

  constructor(private leafletDrawerService: LeafletDrawerService) {
    this.layerGroup = L.layerGroup([]);

    this.leafletInitSub = this.leafletDrawerService.watchViewerInit().subscribe((mapy => {
      this.map = mapy;
      this.refreshLeaflet();
    }));
    if (this.colorConfig) {
      this.refreshLeaflet();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.colorConfig) {
      this.refreshLeaflet();
    }
    if (changes.index) {
      this.indexSubject.next(this.index);
    }
  }

  ngOnDestroy(): void {
    this.leafletInitSub.unsubscribe();
    this.indexSub?.unsubscribe();
    this.layerGroup.remove();
  }

  registerOnChange(fn: (v: GeoCircle | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Method not implemented
  }

  writeValue(obj: GeoCircle | null): void {
    this.layerGroup.clearLayers();
    if (!obj) {
      return;
    }
    this.selectedUnits = obj.units;
    this.value = obj;
    this.refreshLeaflet();
  }

  private refreshLeaflet() {
    if (!this.map) {
      return;
    }
    this.layerGroup.addTo(this.map);
    if (this.value && this.colorConfig) {
      const polyLayer = L.circle(new LatLng(this.value.latitude, this.value.longitude), {
        radius: this.value.radiusMeters,
        fillColor: this.colorConfig.fill.toHexString(),
        fillOpacity: this.colorConfig.fill.getAlpha(),
        color: this.colorConfig.fill.toHexString(),
      });
      polyLayer.pm.setOptions({
        templineStyle: {color: this.colorConfig.fill.toHexString()},
        hintlineStyle: {color: this.colorConfig.fill.toHexString()},
        minRadiusCircle: CIRCLE_LIMITS.MIN_RADIUS.METERS,
        maxRadiusCircle: CIRCLE_LIMITS.MAX_RADIUS.METERS
      } as any);
      this.refreshLayer(polyLayer);
    }
  }

  private refreshLayer(polyLayer: L.Circle) {
    this.layer = polyLayer;

    this.enablePmEdit(polyLayer, (e, eventType) => {
      if (eventType === 'pm:remove') {
        this.value = null;
        this.emitChange();
        return;
      }
      const newGeometry = this.extractGeometryFromLayer(polyLayer);
      if (_.isEqual(this.value, newGeometry)) {
        return;
      }
      this.value = newGeometry;
      this.setTooltip(polyLayer);
      this.emitChange();
    });

    polyLayer.addTo(this.layerGroup);
    this.setTooltip(polyLayer);
  }

  private enablePmEdit(l: L.Circle, cb: (e: L.LeafletEvent, eventType: 'pm:update' | 'pm:dragend' | 'pm:remove') => void) {
    l.on('pm:update', (e: L.LeafletEvent) => {
      cb(e, 'pm:update');
      this.touched();
    });
    l.on('pm:dragend', (e: L.LeafletEvent) => {
      cb(e, 'pm:dragend');
      this.touched();
    });
    l.on('pm:remove', (e: L.LeafletEvent) => {
      cb(e, 'pm:remove');
      this.touched();
    });
  }

  private touched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private extractGeometryFromLayer(target: L.Circle): GeoCircle {
    const latLng = target.getLatLng();
    const circle = new GeoCircle({
      latitude: latLng.lat,
      longitude: latLng.lng,
      radius: 0,
      units: this.value?.units || units_of_measure.M
    });
    circle.radiusMeters = target.getRadius();
    return circle;
  }

  private emitChange() {
    this.onChange?.(this.value);
  }

  private setTooltip(polyLayer: L.Circle) {
    this.indexSub?.unsubscribe();
    this.tooltip?.remove();

    // Add tooltip within the bounds of the polygon
    const centerFeature = pointOnFeature(polyLayer.toGeoJSON());
    const centerLatLng = new LatLng(centerFeature.geometry.coordinates[1], centerFeature.geometry.coordinates[0]);
    const tooltip = new L.Tooltip(centerLatLng, {permanent: true});
    this.tooltip = tooltip;
    tooltip.addTo(this.layerGroup);

    this.indexSub = this.indexSubject.subscribe(index => {
      tooltip.setContent((index + 1).toString());
      this.tooltip = tooltip;
    });
  }
}

import {Injectable} from '@angular/core';
import {ContactService, IContactSearch} from '../contact.service';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {IPersonOrOrganization} from '../../model/gen/transport/utm';
import {map} from 'rxjs/operators';
import {Parser} from '../../model/utm/parser/OperationParser';
import {Contact} from '../../model/Contact';
import {SearchResult} from '../../model/SearchResult';
import {ContactResponse, IContactResponse} from '../../model/ContactResponse';

interface IContactsResponse {
  contacts: IPersonOrOrganization[];
}

@Injectable({
  providedIn: 'root'
})
export class RestContactService extends ContactService {

  baseContactsUrl = `${environment.baseUrl}/userserv/api/contacts`;

  constructor(private http: HttpClient) {
    super();
  }

  getContacts(contactSearch: IContactSearch,
              limit?: number,
              offset?: number,
              fetchCount?: boolean): Observable<SearchResult<Contact>> {
    const params = {
      isPilot: (contactSearch.isPilot === undefined || contactSearch.isPilot === null) ? null : contactSearch.isPilot.toString(),
      isUserContact: (contactSearch.isUserContact === undefined || contactSearch.isUserContact === null) ? null : contactSearch.isUserContact.toString(),
      sort: contactSearch.sort,
      sort_increasing: (contactSearch.sortIncreasing !== undefined && contactSearch.sort?.length > 0) ? contactSearch.sortIncreasing.toString() : null,
      limit: limit ? limit.toString() : null,
      offset: offset ? offset.toString() : null,
      fetch_count: fetchCount !== undefined ? fetchCount.toString() : null,
    };

    Object.keys(params).forEach(key => (params[key] === undefined || params[key] === null) && delete params[key]);


    return this.http.get(this.baseContactsUrl, {
      params
    }).pipe(map((response: any) => {
      return new SearchResult((response.items || []).map(Parser.parseContact), response.count, response.offset);
    }));
  }

  getContactByUserID(userId: string): Observable<ContactResponse> {
    return this.http.get(`${environment.baseUrl}/userserv/api/user/${userId}/contact`)
      .pipe(map((res: IContactResponse) => new ContactResponse(res)));
  }

}

<form [formGroup]="fg">
  <clr-input-container>
    <label>Latitude:</label>
    <input clrInput type="number" formControlName="latitude"/>
  </clr-input-container>
  <clr-input-container>
    <label>Longitude:</label>
    <input clrInput type="number" formControlName="longitude"/>
  </clr-input-container>
</form>

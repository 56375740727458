import {Injectable} from '@angular/core';
import {AdminUserService} from '../admin-user.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {map} from 'rxjs/operators';
import {User} from '../../../model/User';
import {UserSubmission} from '../../../model/UserSubmission';
import {IUserResponse, UserResponse} from '../../../model/UserResponse';
import {IUserSearchResult, UserSearchResult} from '../../../model/UserSearchResult';
import {UTMSerializer} from '../../../model/UTMSerializer';

@Injectable({
  providedIn: 'root'
})
export class RestAdminUserService extends AdminUserService {
  baseGetUsersUrl = `${environment.baseUrl}/userserv/api/users`;
  baseGetUserUrl = `${environment.baseUrl}/userserv/api/user`;
  baseCreateUserUrl = `${environment.baseUrl}/userserv/api/user`;

  constructor(private http: HttpClient) {
    super();
  }

  getUsers(): Observable<UserSearchResult> {
    return this.http.get(this.baseGetUsersUrl).pipe(map((res: IUserSearchResult) => new UserSearchResult(res.offset, res.count, res.users)));
  }

  createUser(userSubmission: UserSubmission): Observable<UserResponse> {
    const transport = UTMSerializer.serializeUserSubmission(userSubmission);
    return this.http.post(this.baseCreateUserUrl, transport).pipe(map((ret: IUserResponse) => new UserResponse(ret)));
  }

  getUser(userId: string): Observable<UserResponse> {
    return this.http.get(`${this.baseGetUserUrl}/${userId}`).pipe(map((res: IUserResponse) => new UserResponse(res)));
  }

  updateUser(userId: string, userSubmission: UserSubmission): Observable<UserResponse> {
    const transport = UTMSerializer.serializeUserSubmission(userSubmission);
    return this.http.patch(`${this.baseGetUserUrl}/${userId}`, transport).pipe(map((res: IUserResponse) => new UserResponse(res)));
  }

  deleteUser(user: User): Observable<UserResponse> {
    return this.http.delete(`${this.baseGetUserUrl}/${user.uid}`).pipe(map((res: IUserResponse) => new UserResponse(res)));
  }
}

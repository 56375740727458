import {computed, Injectable, signal} from '@angular/core';

export type ScreenSize = 'sm' | 'md' | 'lg';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveScreenService {
  screenWidth$ = signal<number>(window.innerWidth);

  deviceSize$ = computed<ScreenSize>(() => {
    if (this.screenWidth$() < 768) {
      return 'sm';
    } else if (this.screenWidth$() >= 768 && this.screenWidth$() < 1200) {
      return 'md';
    } else if (this.screenWidth$() >= 1200) {
      return 'lg';
    }
  });
}

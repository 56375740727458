export const areDifferent = <T>(a: T, b: T): boolean => JSON.stringify(a) !== JSON.stringify(b);
export const areSame = <T>(a: T, b: T): boolean => JSON.stringify(a) === JSON.stringify(b);

export function* windowIterator<T>(arr: T[], size: number = 2) {
  let i: number;
  for (i = 0; i <= arr.length - size; i++) {
    yield arr.slice(i, i + size);
  }
  return i;
}

export const allTruthy = <T>(...args: T[]): boolean => {
  for (const arg of args) {
    if (!arg) {
      return false;
    }

  }
  return true;
};

export const truncateWithEllip = (str: string, maxLength: number = 120, useWordBoundary: boolean = true): string => {
  if (!str) {
    return '';
  }
  if (str.length <= maxLength) {
    return str;
  }
  const subString = str.substr(0, maxLength - 1);
  if (useWordBoundary) {
    return subString.substr(0, subString.lastIndexOf(' ')) + '...';
  } else {
    return subString + '...';
  }
};

<div cdkTrapFocus role="dialog" [attr.aria-modal]="true" [attr.aria-labelledby]="_dialogLabelId ?? undefined"
  class="mat-datepicker-content-container"
  [class.mat-datepicker-content-container-with-custom-header]="datepicker.calendarHeaderComponent"
  [class.mat-datepicker-content-container-with-actions]="_actionsPortal"
  [class.mat-datepicker-content-container-with-time]="!datepicker._hideTime"
  >
  <ngx-mat-calendar [id]="datepicker.id" [ngClass]="datepicker.panelClass" [startAt]="datepicker.startAt"
    [startView]="datepicker.startView" [minDate]="datepicker._getMinDate()" [maxDate]="datepicker._getMaxDate()"
    [dateFilter]="datepicker._getDateFilter()" [headerComponent]="datepicker.calendarHeaderComponent"
    [selected]="_getSelected()" [dateClass]="datepicker.dateClass" [comparisonStart]="comparisonStart"
    [comparisonEnd]="comparisonEnd" [@fadeInCalendar]="'enter'" [startDateAccessibleName]="startDateAccessibleName"
    [endDateAccessibleName]="endDateAccessibleName" (yearSelected)="datepicker._selectYear($event)"
    (monthSelected)="datepicker._selectMonth($event)" (viewChanged)="datepicker._viewChanged($event)"
    (_userSelection)="_handleUserSelection($event)" (_userDragDrop)="_handleUserDragDrop($event)"></ngx-mat-calendar>

  <ng-container *ngIf="isViewMonth">
    <div *ngIf="!datepicker._hideTime" class="time-container" [class.disable-seconds]="!datepicker._showSeconds">
      <ngx-mat-timepicker [selected]="_modelTime" [defaultTime]="datepicker.defaultTime"
                          [showSpinners]="datepicker._showSpinners" [showSeconds]="datepicker._showSeconds"
                          [disabled]="datepicker._disabled || !_modelTime" [stepHour]="datepicker._stepHour"
                          [stepMinute]="datepicker._stepMinute" [stepSecond]="datepicker._stepSecond" [ngModel]="_modelTime"
                          [color]="datepicker._color" [enableMeridian]="datepicker._enableMeridian"
                          [disableMinute]="datepicker._disableMinute" (ngModelChange)="onTimeChanged($event)">
      </ngx-mat-timepicker>
    </div>
  </ng-container>

  <ng-template [cdkPortalOutlet]="_actionsPortal"></ng-template>

  <!-- Invisible close button for screen reader users. -->
  <button type="button" mat-raised-button [color]="color || 'primary'" class="mat-datepicker-close-button"
    [class.cdk-visually-hidden]="!_closeButtonFocused" (focus)="_closeButtonFocused = true"
    (blur)="_closeButtonFocused = false" (click)="datepicker.close()">{{ _closeButtonText }}
  </button>
</div>

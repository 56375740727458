import {
  Component,
  ComponentFactoryResolver,
  ComponentRef, effect,
  EventEmitter,
  Inject, input,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import {ImportExportConfigHostDirective} from './import-export-config-host.directive';
import {ConfigComponent} from '../../config.component';
import {Subscription} from 'rxjs';
import {
  ExportService,
  ImportExportMode,
  ImportExportService,
  ImportExportServiceToken,
  ImportExportType,
  ImportService
} from '../../import-export.service';
import {FormControl, FormGroup} from '@angular/forms';


@Component({
  selector: 'app-import-export-util',
  templateUrl: './import-export-util.component.html'
})
export class ImportExportUtilComponent implements OnChanges {

  @ViewChild(ImportExportConfigHostDirective, {static: true}) importHost: ImportExportConfigHostDirective;
  @Input() mode: ImportExportMode = ImportExportMode.IMPORT;
  @Input() type: ImportExportType;
  @Input() exportData: any;
  @Output() importExportResult: EventEmitter<any> = new EventEmitter<any>();

  IMPORT: ImportExportMode = ImportExportMode.IMPORT;
  EXPORT: ImportExportMode = ImportExportMode.EXPORT;

  availableServices: ImportExportService[];
  fg: FormGroup;

  private configData: any;
  private defaultConfigData: any;
  private componentRef: ComponentRef<ConfigComponent>;
  private configSubscription: Subscription;
  private readonly serviceControl: FormControl;
  service = input<ImportExportService>();
  private serviceEffect = effect(() => {
    this.handleServiceChange(this.service());
  });
  constructor(private componentFactoryResolver: ComponentFactoryResolver, @Inject(ImportExportServiceToken) private services: ImportExportService[]) {
    this.serviceControl = new FormControl(null, []);
    this.fg = new FormGroup({
      service: this.serviceControl
    });
    this.availableServices = [];
    this.updateServices(null, null);
  }

  static compareServices(a: ImportExportService, b: ImportExportService) {
    return `${a.getPriority()}__${a.getFormatName()}`.localeCompare(`${b.getPriority()}__${b.getFormatName()}`);
  }


  updateServices(mode: ImportExportMode, type: string): void {
    this.availableServices = this.services
      .sort(ImportExportUtilComponent.compareServices)
      .filter(s => s.getMode() === mode && (s.getType() === type || s.getType() === ImportExportType.UNIVERSAL));
    if (this.availableServices.length > 0) {
      this.serviceControl.setValue(this.availableServices[0]);
    }
  }


  execute() {
    const service: ImportExportService = this.service();
    switch (service.getMode()) {
      case ImportExportMode.IMPORT:
        (service as ImportService<any>).doImport(this.configData).subscribe((res) => {
          this.importExportResult.emit(res);
          this.componentRef.instance.setConfig(this.defaultConfigData);
        });
        break;
      case ImportExportMode.EXPORT:
        (service as ExportService<any>).doExport(this.exportData, this.configData).subscribe((res) => {
          this.importExportResult.emit(res);
        });
        break;

    }
  }

  private handleServiceChange($event: ImportExportService) {
    if(!$event) {
      return;
    }
    const viewContainerRef = this.importHost.viewContainerRef;
    viewContainerRef.clear();
    this.defaultConfigData = $event.getDefaultConfig();
    this.configData = this.defaultConfigData;
    this.configSubscription?.unsubscribe();


    if ($event.getConfigComponent()) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory($event.getConfigComponent());
      this.componentRef = viewContainerRef.createComponent<ConfigComponent>(componentFactory);
      this.componentRef.instance.setConfig(this.configData);
      this.componentRef.instance.setService($event);

      this.configSubscription = this.componentRef.instance.getConfig().subscribe((config: any) => {
        this.configData = config;
      });
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateServices(this.mode, this.type);
  }
}

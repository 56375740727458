import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-work-in-progress',
  templateUrl: './work-in-progress.component.html'
})
export class WorkInProgressComponent {

  @Input() purpose: string;
  @Input() page = false;
  @Input() component = false;

  constructor() {
    // Method not implemented
  }

}

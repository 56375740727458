/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';

import {uasvolumereservation_type} from './enums';

export interface ILineString {
  type: uasvolumereservation_type;
  coordinates: Array<Array<number>>;
}


export class LineString extends BaseModel implements ILineString {

  static TYPE_FIELD_NAME = 'type';
  static COORDINATES_FIELD_NAME = 'coordinates';

  /** The type of Geometry. In this case, must be 'LineString' per GeoJSON spec. LineString can have 4 to 30 points.
   */
  type: uasvolumereservation_type;
  coordinates: Array<Array<number>>;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<ILineString>, useFormGroupValuesToModel = false) {
    super();
    this.coordinates = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<ILineString>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.type = this.getValue<uasvolumereservation_type>(rawValues, LineString.TYPE_FIELD_NAME);
      this.fillModelArray<Array<number>>(this, LineString.COORDINATES_FIELD_NAME, rawValues.coordinates, useFormGroupValuesToModel);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        type: new UntypedFormControl(this.type, [Validators.required, enumValidator(uasvolumereservation_type),]),
        coordinates: new UntypedFormArray([]),
      });
      // generate FormArray control elements
      this.fillFormArray<Array<number>>(LineString.COORDINATES_FIELD_NAME, this.coordinates);
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[LineString.TYPE_FIELD_NAME].setValue(this.type);
    this.fillFormArray<Array<number>>(LineString.COORDINATES_FIELD_NAME, this.coordinates);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

import {Injectable} from '@angular/core';
import {OperationExt} from '../model/utm/OperationExt';
import {SearchResult} from '../model/SearchResult';
import {concat, Observable, of} from 'rxjs';
import {Position, state} from '../model/gen/utm';
import {DateTime} from 'luxon';
import {IOperationSubmission} from '../model/OperationSubmission';
import {map, mergeMap, reduce} from 'rxjs/operators';
import * as _ from 'lodash';
import {Volume4dQuery} from '../models/Volume4dQuery';
import {OperationResponse} from './rest/rest-operation.service';
import {IOperationModification} from '../model/OperationModification';
import {OperationReplanInfo} from '../model/OperationReplanInfo';


export interface IOperationSearchParameters {
  categories?: string[];
  state?: state[];
  scopes?: OperationScope;
  volume4d?: Volume4dQuery;
  registrationId?: string[];
  submitTimeAfter?: DateTime;
  submitTimeBefore?: DateTime;
  /* eslint-disable @typescript-eslint/naming-convention */
  flight_number?: string;
  flight_comments?: string;
  effective_time_begin?: DateTime;
  effective_time_end?: DateTime;
  uss_name?: string;
  managed?: boolean;
  /* eslint-enable @typescript-eslint/naming-convention */
  organization?: string;
  name?: string;
  description?: string;
  ussName?: string;

  sort?: OperationSearchSortField[];
  sortIncreasing?: boolean;
  limit?: number;
  offset?: number;
  fetchCount?: boolean;
}


export enum OperationSearchSortField {
  /* eslint-disable @typescript-eslint/naming-convention */
  submit_time = 'submit_time',
  submit_time_after = 'submit_time_after',
  submit_time_before = 'submit_time_before',
  update_time = 'update_time',
  effective_time_begin = 'effective_time_begin',
  effective_time_end = 'effective_time_end',
  flight_comments = 'flight_comments',
  flight_number = 'flight_number',
  uss_name = 'uss_name',
  organization = 'organization',
  gufi = 'gufi',
  state = 'state',
  scope = 'scope',
  unknown = '__unknown__'
  /* eslint-enable @typescript-eslint/naming-convention */
}

export enum OperationScope {
  personal = 'personal',
  organization = 'organization',
  currentUSS = 'currentUSS',
  global = 'global',
}

export class OperationSearchSortFieldUtil {
  static fromString(s: string): OperationSearchSortField {
    if (!s) {
      return OperationSearchSortField.unknown;
    }
    switch (s.toLowerCase()) {
      case 'submit_time':
        return OperationSearchSortField.submit_time;
      case 'submit_time_after':
        return OperationSearchSortField.submit_time_after;
      case 'submit_time_before':
        return OperationSearchSortField.submit_time_before;
      case 'update_time':
        return OperationSearchSortField.update_time;
      case 'effective_time_begin':
        return OperationSearchSortField.effective_time_begin;
      case 'effective_time_end':
        return OperationSearchSortField.effective_time_end;
      case 'flight_comments':
        return OperationSearchSortField.flight_comments;
      case 'flight_number':
        return OperationSearchSortField.flight_number;
      case 'uss_name':
        return OperationSearchSortField.uss_name;
      case 'organization':
        return OperationSearchSortField.organization;
      case 'gufi':
        return OperationSearchSortField.gufi;
      case 'state':
        return OperationSearchSortField.state;
      default:
        console.error(`Invalid sort option : ${s}`);
        return OperationSearchSortField.unknown;
    }
  }
}

export class OperationSearchParameters implements IOperationSearchParameters {
  /* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
  /* tslint:disable:variable-name */
  categories: string[];
  state: state[];
  scopes: OperationScope;
  volume4d?: Volume4dQuery;
  registrationId?: string[];
  submitTimeAfter?: DateTime;
  submitTimeBefore?: DateTime;
  flight_number?: string;
  flight_comments?: string;
  uss_name?: string;
  managed?: boolean;

  sort?: OperationSearchSortField[];
  sortIncreasing?: boolean;
  limit?: number;
  offset?: number;
  fetchCount?: boolean;

  public constructor(raw?: IOperationSearchParameters) {

    if (raw) {
      this.categories = raw.categories;
      this.state = raw.state?.length ? raw.state : [state.PROPOSED, state.ACCEPTED, state.ACTIVATED, state.NONCONFORMING, state.ROGUE];
      this.scopes = raw.scopes || OperationScope.global;
      this.volume4d = raw.volume4d;
      this.registrationId = raw.registrationId;
      this.submitTimeAfter = raw.submitTimeAfter;
      this.submitTimeBefore = raw.submitTimeBefore;
      this.flight_number = raw.flight_number;
      this.flight_comments = raw.flight_comments;
      this.uss_name = raw.uss_name;
      this.managed = raw.managed;
      this.sort = raw.sort?.length ? raw.sort : [OperationSearchSortField.effective_time_begin];
      this.sortIncreasing = !_.isNil(raw.sortIncreasing) ? raw.sortIncreasing : false;
      this.limit = !_.isNil(raw.limit) ? raw.limit : 10;
      this.offset = !_.isNil(raw.offset) ? raw.offset : 0;
      this.fetchCount = !_.isNil(raw.fetchCount) ? raw.fetchCount : false;
    } else {
      this.categories = [];
      this.state = [state.PROPOSED, state.ACCEPTED, state.ACTIVATED, state.NONCONFORMING, state.ROGUE];
      this.scopes = OperationScope.global;
      this.volume4d = undefined;
      this.registrationId = undefined;
      this.submitTimeAfter = undefined;
      this.submitTimeBefore = undefined;
      this.flight_number = undefined;
      this.flight_comments = undefined;
      this.uss_name = undefined;
      this.managed = undefined;
      this.sort = [OperationSearchSortField.effective_time_begin];
      this.sortIncreasing = false;
      this.limit = 10;
      this.offset = 0;
      this.fetchCount = false;
    }

  }
  /* tslint:disable:variable-name */
  /* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */


  public static defaultSearch(): OperationSearchParameters {
    return new OperationSearchParameters();
  }
}


@Injectable({
  providedIn: 'root'
})
export abstract class OperationService {

  watchOperationState(operationId: string): Observable<state> {
    return this.watchOperation(operationId).pipe(map(op => op.state));
  }

  getAllOperations(search: IOperationSearchParameters): Observable<OperationExt[]> {
    const stepSize = 100;
    const initialSearch = _.cloneDeep(search);
    initialSearch.limit = stepSize;
    initialSearch.offset = 0;
    initialSearch.fetchCount = true;

    return this.getOperations(initialSearch).pipe(
      mergeMap(ops => {
        const obs: Observable<SearchResult<OperationExt>>[] = [of(ops)];

        if (ops.results.length < ops.total) {
          const baseSearch = _.cloneDeep(initialSearch);
          baseSearch.fetchCount = false;

          for (let i = ops.offset + ops.results.length; i < ops.total; i += stepSize) {
            const thisSearch = _.cloneDeep(baseSearch);
            thisSearch.offset = i;
            obs.push(this.getOperations(thisSearch));
          }
        }
        return concat(...obs).pipe(
          reduce((all, res) => all.concat([res]), [])
        );
      })
    ).pipe(
      map((results: SearchResult<OperationExt>[]) => _.flatten(results.map(r => r.results)))
    );

  }


  abstract createOperation(operation: IOperationSubmission): Observable<OperationResponse>;

  abstract submitOperation(data: any): Observable<boolean>;

  abstract exists(operationId: string): Observable<boolean>;

  abstract getOperations(operationSearchParameters: IOperationSearchParameters): Observable<SearchResult<OperationExt>>;

  abstract getOperation(operationId: string): Observable<OperationExt>;

  abstract getOperationState(operationId: string, refreshInterval?: number): Observable<state>;

  abstract getOperationReplans(operationId: string): Observable<OperationReplanInfo>;

  abstract modifyOperation(operationId: string, data: IOperationModification): Observable<OperationResponse>;

  abstract activateOperation(operationId: string): Observable<boolean>;

  abstract watchOperation(operationId: string): Observable<OperationExt>;

  abstract watchPositions(operationId: string): Observable<Position>;

  abstract closeOperation(operationId: string): Observable<boolean>;
}

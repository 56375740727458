import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {UasRegistrationInfo} from '../../../shared/model/UasRegistrationInfo';
import {Subscription} from 'rxjs';
import {catchError, switchMap} from 'rxjs/operators';
import {PlatformService} from '../../../shared/services/platform.service';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'app-platform',
  templateUrl: './platform.component.html'
})
export class PlatformComponent implements OnInit, OnDestroy {
  platform: UasRegistrationInfo;
  notFound: boolean;

  private queryParamsSub: Subscription;

  constructor(private route: ActivatedRoute,
              private platformService: PlatformService) {
  }

  ngOnInit(): void {
    this.queryParamsSub = this.route.paramMap
      .pipe(switchMap((paramMap: ParamMap) => {
        const platformId = paramMap.get('platformId');

        if (!platformId) {
          return of(null);
        }

        return this.platformService.getPlatform(platformId)
          .pipe(catchError(() => {
            return of(null);
          }))
      })).subscribe((platform: UasRegistrationInfo) => {
        this.notFound = !platform;
        this.platform = platform;
      });
  }

  ngOnDestroy(): void {
    this.queryParamsSub?.unsubscribe();
  }
}

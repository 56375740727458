import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ClrDatagridFilterInterface} from '@clr/angular';
import {Subject} from 'rxjs';
import {OperationScope} from '../../../../../shared/services/operation.service';
import {FormControlify} from '../../../../../shared/utils/forms';

interface ScopeFG {
  scope: OperationScope;
}

@Component({
  selector: 'app-operation-scopes-filter',
  templateUrl: './operation-scopes-filter.component.html',
  styleUrls: ['./operation-scopes-filter.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => OperationScopesFilterComponent),
  }]
})
export class OperationScopesFilterComponent implements ClrDatagridFilterInterface<any>, ControlValueAccessor {
  scopeFg: FormGroup<FormControlify<ScopeFG>>;
  changes = new Subject<any>();
  active = false;
  scopeOptions: { key: OperationScope; value: string }[];
  scope: OperationScope;

  private onChange: any;

  constructor() {
    this.scopeOptions = [
      {key: OperationScope.personal, value: 'Owned'},
      {key: OperationScope.organization, value: 'My Organization'},
      {key: OperationScope.currentUSS, value: 'My USS'},
      {key: OperationScope.global, value: 'Global'}
    ];
    this.scopeFg = new FormGroup<FormControlify<ScopeFG>>({
      scope: new FormControl<OperationScope>(OperationScope.global)
    });

    this.scopeFg.valueChanges.subscribe(() => {
      const rawValues = this.scopeFg.getRawValue();

      if (this.onChange) {
        this.onChange(rawValues.scope);
      }
    });
  }

  isActive(): boolean {
    return !!this.active;
  }

  accepts(val: any): boolean {
    return true;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  writeValue(scope: OperationScope): void {
    this.scopeFg.controls.scope.setValue(scope, {emitEvent: false, onlySelf: true});
    this.scope = scope;
  }
}

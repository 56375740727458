import {Component, forwardRef, OnDestroy} from '@angular/core';
import {
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {IControlValueAccessor} from '@rxweb/types';
import * as turf from '@turf/turf';
import {debounceTime, map} from 'rxjs/operators';
import {Polygon} from '../../model/gen/utm';
import * as _ from "lodash";
import {
  DMS_REGEX,
  KML_LATLON_REGEX,
  parseDMSCoordinates,
  parseKMLLatLonCoordinates
} from "../../utils/points-editor-utils";

type CoordinateFormGroup = FormGroup<{ latitude: FormControl<number>; longitude: FormControl<number> }>;
type CoordsFormArray = FormArray<CoordinateFormGroup>;

const NoDuplicateCoordinatesValidator: ValidatorFn = (formArray: FormArray<CoordinateFormGroup>) => {
  if (formArray.controls.map(c => c.valid).includes(false)) {
    return null;
  }
  const coordinates = formArray.controls.map(c => c.value);
  const uniqueCoordinates = new Set(coordinates.map(c => `${c.latitude},${c.longitude}`));
  if (uniqueCoordinates.size !== coordinates.length) {
    return {duplicateCoordinates: true};
  }
  return null;
};

const NoSelfIntersectingPolygonValidator: ValidatorFn = (formArray: FormArray<CoordinateFormGroup>) => {
  if (formArray.controls.length < 3) {
    return null;
  }

  if (formArray.controls.map(c => c.valid).includes(false)) {
    return null;
  }

  const coordinates = formArray.controls.map(c => c.value)
    .map(c => [c.longitude, c.latitude]);
  coordinates.push(coordinates[0]);
  const polygon = turf.polygon([coordinates]);
  const kinks = turf.kinks(polygon);
  if (kinks.features.length > 0) {
    return {selfIntersectingPolygon: true};
  }
  return null;
  // const vertices = coordinates.map((c, curIndex) => {
  //   let j = curIndex + 1;
  //   if (j === coordinates.length) {
  //     j = 0;
  //   }
  //   return turf.lineString([c, coordinates[j]]);
  // });
  // // let i = 0;
  // for (const vertex of vertices) {
  //   // i++;
  //   // if (i === vertices.length) {
  //   //   break;
  //   // }
  //   // for (const otherVertex of vertices.splice(i)) {
  //   for (const otherVertex of vertices) {
  //     if (vertex === otherVertex){
  //       continue;
  //     }
  //     if (booleanIntersects(vertex, otherVertex)) {
  //       return {selfIntersectingPolygon: true};
  //     }
  //   }
  // }
  // return null;

};

@Component({
  selector: 'app-manual-polygon-input',
  templateUrl: './manual-polygon-input.component.html',
  styleUrls: ['./manual-polygon-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ManualPolygonInputComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => ManualPolygonInputComponent),
    }
  ]
})
export class ManualPolygonInputComponent implements OnDestroy, IControlValueAccessor<Polygon>, Validator {
  fa: CoordsFormArray = new FormArray<CoordinateFormGroup>([], [
    Validators.minLength(3),
    NoDuplicateCoordinatesValidator,
    NoSelfIntersectingPolygonValidator
  ]);

  polygons$ = this.fa.valueChanges.pipe(
    debounceTime(100),
    map(points => {
      if (!this.fa.valid) {
        return null;
      }
      return new Polygon({
        coordinates: [
          [
            ...points.map(p => [p.longitude, p.latitude]),
            [points[0].longitude, points[0].latitude]
          ]
        ]
      });
    })
  );

  polygonSub = this.polygons$.subscribe((polygon) => {
    this.onChange?.(polygon);
  });

  private onChange: (value: Polygon) => void;
  private onTouch: () => void;
  private onValidatorChange: () => void;
  private latFirst = true;

  constructor() {

  }

  ngOnDestroy(): void {
    this.polygonSub?.unsubscribe();
  }

  registerOnChange(fn: (value: Polygon) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(obj: Polygon | null): void {
    this.fa.clear();
    const roundedCoords = obj?.coordinates[0].map(coord => [_.round(coord[0], 6), _.round(coord[1], 6)]);

    const points = (roundedCoords || []).map(pt => {
      return this.createCoordinateFormGroup(pt[1], pt[0]);
    });
    points.pop(); // Remove the last point, which is a duplicate of the first
    while (points.length < 3) {
      points.push(this.createCoordinateFormGroup(null, null));
    }

    for (const coord of points) {
      this.fa.push(coord);
    }
    this.fa.markAsPristine();
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(fa: CoordsFormArray): ValidationErrors | null {
    return fa.errors;
  }

  add() {
    this.fa.push(this.createCoordinateFormGroup(null, null));
    this.fa.markAsTouched();
  }

  movePoint(increment: number, index: number) {
    if (this.fa.length <= 1) {
      return;
    }

    const currentPoint = this.fa.at(index);
    let newIndex = index + increment;

    if (newIndex === -1) {
      // If moving the volume beyond the top, move it to the end of the array
      newIndex = this.fa.length - 1;
    } else if (newIndex === this.fa.length) {
      // If moving the volume beyond the end, move it to the beginning of the array
      newIndex = 0;
    }

    this.fa.removeAt(index);
    this.fa.insert(newIndex, currentPoint);
    this.fa.markAsTouched();
  }

  removePoint(index: number) {
    this.fa.removeAt(index);
    while (this.fa.length < 3) {
      this.fa.push(this.createCoordinateFormGroup(null, null));
    }
    this.fa.markAsTouched();
  }

  /**
   * Handles pasting of coordinates into the latitude and longitude input fields
   * @param $event Clipboard paste event
   * @param i The index of the form group that was targeted by the paste event
   * @param control The control that was targeted by the paste event
   */
  handlePaste($event: ClipboardEvent, i: number, control: FormControl<number>) {
    $event.preventDefault();
    if (!$event.clipboardData) {
      return;
    }

    const text = $event.clipboardData.getData('text');
    const filteredText = text.replace(/[\n\r]+/g,' ');
    let coordinates: {latitude?: number, longitude?: number}[] = [];

    DMS_REGEX.lastIndex = 0;
    KML_LATLON_REGEX.lastIndex = 0;

    // If the pasted values are in DMS, KML, or lat/lon format, parse them accordingly
    if (DMS_REGEX.exec(filteredText)?.length) {
      coordinates = parseDMSCoordinates(filteredText);
    } else if (KML_LATLON_REGEX.exec(filteredText)?.length) {
      coordinates = parseKMLLatLonCoordinates(filteredText);
    }


    // Remove the last coordinate if it is a duplicate of the first coordinate
    if(coordinates.length > 1) {
      let firstPoint = coordinates[0];
      let lastPoint = coordinates[coordinates.length - 1];
      if (firstPoint?.latitude == lastPoint?.latitude
        && firstPoint?.longitude == lastPoint?.longitude) {
        coordinates.pop();
      }
    }

    // If coordinates have successfully been parsed from the pasted data, set the form group's value to the coordinates.
    // Else, if the pasted data is a single floating number, set the value of the control targeted by the paste event to
    // the pasted value.
    if (coordinates?.length) {
      this.setCoordsFormArray(coordinates, i);
    } else {
      const value = parseFloat(text);
      if (!isNaN(value) && value !== null && value !== undefined) {
        control.setValue(value);
      }
    }
    this.fa.markAsTouched();
  }

  /**
   * Sets the value of the form array to the supplied coordinates if multiple coordinates are provided. Otherwise, if a
   * single coordinate pair is provided, the form group targeted by the paste event is updated with its value.
   * @param coords The coordinates to set the form group values to
   * @param i The index of the form group that was targeted by the paste event
   * @private
   */
  private setCoordsFormArray(coords: {latitude?: number, longitude?: number}[], i: number) {
    // If a single coordinate pair is provided, update the form group targeted by the paste event with its value.
    // Else, if multiple coordinates are provided, set the value of the entire form array to the provided coordinates.
    if (coords.length === 1) {
      const coord = coords[0];
      const latitude = this.latFirst ? coord.latitude : coord.longitude;
      const longitude = this.latFirst ? coord.longitude : coord.latitude;

      this.fa.removeAt(i);
      this.fa.insert(i, this.createCoordinateFormGroup(latitude, longitude));
    } else if (coords.length > 1) {
      const coordFormGroups = [];

      this.fa.clear();
      for (const coord of coords) {
        const latitude = this.latFirst ? coord.latitude : coord.longitude;
        const longitude = this.latFirst ? coord.longitude : coord.latitude;

        coordFormGroups.push(this.createCoordinateFormGroup(latitude, longitude));
      }

      // If the number of coordinates provided is less than 3, append additional empty form groups to the form array
      // until there are 3 form groups.
      while (coordFormGroups.length < 3) {
        coordFormGroups.push(this.createCoordinateFormGroup(null, null));
      }

      for (const fg of coordFormGroups) {
        this.fa.push(fg);
      }
    }
  }

  /**
   * Creates a FormGroup with two FormControls for latitude and longitude
   * @param latitude The value to set the latitude control to
   * @param longitude The value to set the longitude control to
   */
  private createCoordinateFormGroup(latitude: number | null, longitude: number | null): FormGroup<{
    latitude: FormControl<number>;
    longitude: FormControl<number>
  }> {
    return new FormGroup({
      latitude: new FormControl(latitude, [Validators.required, Validators.min(-90), Validators.max(90)]),
      longitude: new FormControl(longitude, [Validators.required, Validators.min(-180), Validators.max(180)])
    });
  }

  private touched(){
    this.onTouch?.();
  }

  swapComponents() {
    this.fa.controls.forEach((fg: FormGroup) => {
      const coords = fg.value;
      fg.setValue({latitude: coords.longitude, longitude: coords.latitude});

    });
  }
}

import {Injectable} from '@angular/core';
import {concat, Observable, of} from 'rxjs';
import {UasRegistrationInfo} from '../model/UasRegistrationInfo';
import {SearchResult} from '../model/SearchResult';
import {map, mergeMap, reduce} from 'rxjs/operators';
import * as _ from 'lodash';
import {TelemetryIntegrationsEntry} from '../model/TelemetryIntegrations/TelemetryIntegrationsEntry';
import {TelemetryIntegrations} from '../model/TelemetryIntegrations/TelemetryIntegrations';
import {
  SupportedTelemetryIntegrationsResponse
} from '../model/TelemetryIntegrations/SupportedTelemetryIntegrationsResponse';

export interface IPlatformSearch {
  archived?: boolean;
  name?: string;
  organization?: string;
  make?: string;
  model?: string;
  serialNumber?: string;
  faaRegistrationNumber?: string;
  tailNumber?: string;
  // registrationId?: string[];

  sort?: PlatformSearchSortField[];
  sortIncreasing?: boolean;
  pageSize?: number;
  pageOffset?: number;
}

export enum PlatformSearchSortField {
  name = 'name',
  make = 'make',
  model = 'model',
  faaRegistrationNumber = 'faaRegistrationNumber',
  organization = 'organization',
  unknown = '__unknown__'
}

export class PlatformSearchFieldUtil {
  static fromString(s: string): PlatformSearchSortField {
    if (!s) {
      return PlatformSearchSortField.unknown;
    }
    switch (s) {
      case 'name':
        return PlatformSearchSortField.name;
      case 'make':
        return PlatformSearchSortField.make;
      case 'model':
        return PlatformSearchSortField.model;
      case 'faaRegistrationNumber':
          return PlatformSearchSortField.faaRegistrationNumber;
      case 'organization':
        return PlatformSearchSortField.organization;
      default:
        console.error(`Invalid sort option: ${s}`);
        return PlatformSearchSortField.unknown;
    }
  }
}

export class PlatformSearch implements IPlatformSearch {
  archived: boolean;
  name: string;
  organization: string;
  make: string;
  model: string;
  serialNumber: string;
  faaRegistrationNumber: string;
  tailNumber: string;

  sort: PlatformSearchSortField[];
  sortIncreasing: boolean;
  pageSize: number;
  pageOffset: number;

  public constructor(raw?: IPlatformSearch) {
    if (raw) {
      this.archived = !_.isNil(raw.archived) ? raw.archived : false;
      this.name = raw.name;
      this.organization = raw.organization;
      this.make = raw.make;
      this.model = raw.model;
      this.serialNumber = raw.serialNumber;
      this.faaRegistrationNumber = raw.faaRegistrationNumber;
      this.tailNumber = raw.tailNumber;
      this.sort = raw.sort?.length ? raw.sort : [PlatformSearchSortField.name];
      this.sortIncreasing = !_.isNil(raw.sortIncreasing) ? raw.sortIncreasing : true;
      this.pageSize = !_.isNil(raw.pageSize) ? raw.pageSize : 10;
      this.pageOffset = !_.isNil(raw.pageOffset) ? raw.pageOffset : 0;
    } else {
      this.archived = false;
      this.name = null;
      this.organization = null;
      this.make = null;
      this.model = null;
      this.serialNumber = null;
      this.faaRegistrationNumber = null;
      this.tailNumber = null;
      this.sort = [PlatformSearchSortField.name];
      this.sortIncreasing = true;
      this.pageSize = 10;
      this.pageOffset = 0;
    }
  }

  public static defaultSearch(): PlatformSearch {
    return new PlatformSearch();
  }
}

@Injectable({
  providedIn: 'root'
})
export abstract class PlatformService {

  getAllPlatforms(search: IPlatformSearch, stepSize: number = 100): Observable<UasRegistrationInfo[]> {

    return this.getPlatforms(search, stepSize, 0, true).pipe(
      mergeMap(res => {
        const obs: Observable<SearchResult<UasRegistrationInfo>>[] = [of(res)];

        if (res.results.length < res.total) {
          for (let i = res.offset + res.results.length; i < res.total; i += stepSize) {
            obs.push(this.getPlatforms(search, stepSize, i, false));
          }
        }
        return concat(...obs).pipe(
          reduce((all, res2) => all.concat([res2]), [])
        );
      })
    ).pipe(
      map((results: SearchResult<UasRegistrationInfo>[]) => _.flatten(results.map(r => r.results)))
    );
  }

  abstract getPlatform(platformId: string): Observable<UasRegistrationInfo>;

  abstract getPlatforms(searchConfig: IPlatformSearch, size: number, from: number, fetchCount: boolean): Observable<SearchResult<UasRegistrationInfo>>;

  abstract updatePlatform(platformId: string, platform: UasRegistrationInfo): Observable<boolean>;

  abstract submitPlatform(submission: UasRegistrationInfo): Observable<boolean>;

  abstract archivePlatform(platformId: string): Observable<UasRegistrationInfo>;

  abstract unArchivePlatform(platformId: string): Observable<UasRegistrationInfo>;

  abstract getIntegrationsForPlatform(platformId: string): Observable<TelemetryIntegrationsEntry>;

  abstract submitIntegrationsForPlatform(platformId: string, integrations: TelemetryIntegrations): Observable<boolean>;

  abstract getSupportedIntegrations(): Observable<SupportedTelemetryIntegrationsResponse>;
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {MeasurementSystemType} from '../model/MeasurementSystem';
import {map} from 'rxjs/operators';
import {OperationActivationPolicy, vertical_reference} from '../model/gen/utm';

/* eslint-disable @typescript-eslint/naming-convention */
export const UISCALINGFACTOR_KEY = 'uiScalingFactor';
export const MEASUREMENTSYSTEM_KEY = 'measurementSystemType';

/* eslint-enable @typescript-eslint/naming-convention */

export interface IUserSettings {
  uiScalingFactor: number;
  measurementSystem: string;
  defaultVerticalReference: string;
  clearFormAfterSubmission?: {
    operationSubmission?: boolean;
    constraintSubmission?: boolean;
    platformSubmission?: boolean;
    userSubmission?: boolean;
  };
  operationStartOffset: number;
  operationDuration: number;
  constraintStartOffset: number;
  constraintDuration: number;
  operationActivation: string;
}

export interface ClearFormSettings {
  operationSubmission: boolean;
  constraintSubmission: boolean;
  platformSubmission: boolean;
  userSubmission: boolean;
}

export interface UserSettings {
  uiScalingFactor: number;
  measurementSystemType: MeasurementSystemType;
  defaultVerticalReference: vertical_reference;
  clearFormAfterSubmission: ClearFormSettings;
  operationStartOffset: number;
  operationDuration: number;
  constraintStartOffset: number;
  constraintDuration: number;
  operationActivation: OperationActivationPolicy;
}

@Injectable()
export abstract class UserSettingsService {

  protected constructor() {
    // Method not implemented
  }

  getMeasurementSystem(): Observable<MeasurementSystemType> {
    return this.getRawSettings().pipe(map(s => s.measurementSystemType || MeasurementSystemType.METRIC));
  }

  getUIScalingFactor(): Observable<number> {
    return this.getRawSettings().pipe(map(s => s.uiScalingFactor || 1));
  }

  getClearFormAfterSubmission(): Observable<ClearFormSettings> {
    const defaultSettings = {
      operationSubmission: false,
      constraintSubmission: false,
      platformSubmission: true,
      userSubmission: true
    };
    return this.getRawSettings().pipe(map(s => s.clearFormAfterSubmission || defaultSettings));
  }

  abstract getRawSettings(): Observable<UserSettings>;

  abstract refreshSettings(reset?: boolean): void;

  abstract updateSettings(settings: UserSettings): Observable<boolean>;

  abstract createSettings(settings: UserSettings): Observable<boolean>;
}

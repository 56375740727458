import {measure, measureDef, system, SYSTEMS, unit, unitDef, unitMap} from './definitions/base';
import {AreaDef} from './definitions/area';
import {MassDef} from './definitions/mass';
import {VolumeDef} from './definitions/volume';
import {LengthDef} from './definitions/length';
import {EachDef} from './definitions/each';
import {TemperatureDef} from './definitions/temperature';
import {PartsPerDef} from './definitions/partsPer';
import {DigitalDef} from './definitions/digital';
import {TimeDef} from './definitions/time';
import {SpeedDef} from './definitions/speed';
import {PaceDef} from './definitions/pace';
import {PressueDef} from './definitions/pressure';
import {VoltageDef} from './definitions/voltage';
import {CurrentDef} from './definitions/current';
import {ReactivePowerDef} from './definitions/reactivePower';
import {ApparentPowerDef} from './definitions/apparentPower';
import {EnergyDef} from './definitions/energy';
import {VolumeFlowRateDef} from './definitions/volumeFlowRate';
import {IlluminanceDef} from './definitions/illuminance';
import {FrequencyDef} from './definitions/frequency';
import {AngleDef} from './definitions/angle';
import {ChargeDef} from './definitions/charge';
import {ForceDef} from './definitions/force';
import {AccelerationDef} from './definitions/acceleration';
import {ReactiveEnergyDef} from './definitions/reactiveEnergy';
import {PowerDef} from './definitions/power';

const DEFAULT_MEASURES: { [key: string]: measureDef } = {
  length: LengthDef,
  area: AreaDef,
  mass: MassDef,
  volume: VolumeDef,
  each: EachDef,
  temperature: TemperatureDef,
  time: TimeDef,
  digital: DigitalDef,
  partsPer: PartsPerDef,
  speed: SpeedDef,
  pace: PaceDef,
  pressure: PressueDef,
  current: CurrentDef,
  voltage: VoltageDef,
  power: PowerDef,
  reactivePower: ReactivePowerDef,
  apparentPower: ApparentPowerDef,
  energy: EnergyDef,
  reactiveEnergy: ReactiveEnergyDef,
  volumeFlowRate: VolumeFlowRateDef,
  illuminance: IlluminanceDef,
  frequency: FrequencyDef,
  angle: AngleDef,
  charge: ChargeDef,
  force: ForceDef,
  acceleration: AccelerationDef
};

export class Converter<T> {
  private readonly val: number;
  private origin: { abbr: string; type: measure; system: system; unit: unitDef } | null;
  private destination: { abbr: string; type: measure; system: system; unit: unitDef } | null;


  constructor(numerator: number, denominator?: number, private measures: { [key: string]: measureDef } = DEFAULT_MEASURES) {
    if (denominator) {
      this.val = numerator / denominator;
    } else {
      this.val = numerator;
    }
  }

  private static _describe(resp: { abbr: string; type: measure; system: system; unit: unitDef }):
    { measure: unit; system: system; plural: string; singular: string; abbr: string } {
    return {
      abbr: resp.abbr,
      measure: resp.type as unit,
      system: resp.system,
      singular: resp.unit.name.singular,
      plural: resp.unit.name.plural,
    };
  }

  /**
   * Lets the converter know the source unit abbreviation
   */
  from(from: unit): this {
    if (this.destination) {throw new Error('.from must be called before .to');}

    this.origin = this.getUnit(from);

    if (!this.origin) {
      this.throwUnsupportedUnitError(from);
    }

    return this;
  }

  /**
   * Converts the unit and returns the value
   */
  to(to: unit): number {
    if (!this.origin) {throw new Error('.to must be called after .from');}

    this.destination = this.getUnit(to);

    let result: number;
    let transform: (num) => number;

    if (!this.destination) {
      this.throwUnsupportedUnitError(to);
    }

    // Don't change the value if origin and destination are the same
    if (this.origin.abbr === this.destination.abbr) {
      return this.val;
    }

    // You can't go from liquid to mass, for example
    if (this.destination.type !== this.origin.type) {
      throw new Error(
        'Cannot convert incompatible measures of ' +
        this.destination.type +
        ' and ' +
        this.origin.type
      );
    }

    /**
     * Convert from the source value to its anchor inside the system
     */
    result = this.val * this.origin.unit.to_anchor;

    /**
     * For some changes it's a simple shift (C to K)
     * So we'll add it when convering into the unit (later)
     * and subtract it when converting from the unit
     */
    if (this.origin.unit.anchor_shift) {
      result -= this.origin.unit.anchor_shift;
    }

    /**
     * Convert from one system to another through the anchor ratio. Some conversions
     * aren't ratio based or require more than a simple shift. We can provide a custom
     * transform here to provide the direct result
     */
    if (this.origin.system !== this.destination.system) {
      transform = this.measures[this.origin.type]._anchors[this.origin.system].transform;
      if (typeof transform === 'function') {
        result = transform(result);
      } else {
        result *=
          this.measures[this.origin.type]._anchors[this.origin.system].ratio;
      }
    }

    /**
     * This shift has to be done after the system conversion business
     */
    if (this.destination.unit.anchor_shift) {
      result += this.destination.unit.anchor_shift;
    }

    /**
     * Convert to another unit inside the destination system
     */
    return result / this.destination.unit.to_anchor;
  };

  /**
   * Converts the unit to the best available unit.
   */
  toBest(options): { val: number; unit?: any; singular?: any; plural?: any } {
    if (!this.origin) {throw new Error('.toBest must be called after .from');}

    options = Object.assign(
      {
        exclude: [],
        cutOffNumber: 1,
      },
      options
    );

    let best: { val: any; unit?: any; singular?: any; plural?: any };
    /**
     * Looks through every possibility for the 'best' available unit.
     * i.e. Where the value has the fewest numbers before the decimal point,
     * but is still higher than 1.
     */
    for (const possibility of this.possibilities()) {
      const unit = this.describe(possibility);
      const isIncluded = options.exclude.indexOf(possibility) === -1;

      if (isIncluded && unit.system === this.origin.system) {
        const result = this.to(possibility);
        if (!best || (result >= options.cutOffNumber && result < best.val)) {
          best = {
            val: result,
            unit: possibility,
            singular: unit.singular,
            plural: unit.plural,
          };
        }
      }
    }
    return best;
  };

  /**
   * Finds the unit
   */
  getUnit(abbr: unit): { abbr: string; type: measure; system: system; unit: unitDef } | null {
    for (const measureKey of Object.keys(this.measures)) {
      const measure = this.measures[measureKey];
      for (const system of SYSTEMS) {
        if (measure[system]) {
          for (const unitAbbr of Object.keys(measure[system])) {
            if (unitAbbr === abbr) {
              return {
                abbr,
                type: measure.type,
                system,
                unit: measure[system][unitAbbr],
              };
            }
          }
        }
      }
    }
    return null;
  }

  /**
   * An alias for getUnit
   */
  describe(abbr: unit) {
    const resp = this.getUnit(abbr);
    let desc = null;

    try {
      desc = Converter._describe(resp);
    } catch (err) {
      this.throwUnsupportedUnitError(abbr);
    }

    return desc;
  }

  /**
   * Detailed list of all supported units
   */
  list(measureType: measure): { measure: unit; system: system; plural: string; singular: string; abbr: string }[] {
    const list: { measure: unit; system: system; plural: string; singular: string; abbr: string }[] = [];
    for (const measureKey of Object.keys(this.measures)) {
      const measure = this.measures[measureKey];
      if (measure.type === measureType) {
        for (const system of SYSTEMS) {
          if (measure[system]) {
            const units: unitMap = measure[system];
            for (const abbr of Object.keys(units)) {
              list.push(Converter._describe({
                abbr,
                type: measure.type,
                system,
                unit: units[abbr],
              }));
            }
          }

        }
      }
    }

    return list;
  }

  throwUnsupportedUnitError(what: unit): never {
    let validUnits = [];

    for (const measureKey of Object.keys(this.measures)) {
      const measure = this.measures[measureKey];
      for (const system of SYSTEMS) {
        if (measure[system]) {
          validUnits = validUnits.concat(Object.keys(measure[system]));
        }
      }
    }

    throw new Error(
      'Unsupported unit ' + what + ', use one of: ' + validUnits.join(', ')
    );
  };

  /**
   * Returns the abbreviated measures that the value can be
   * converted to.
   */
  possibilities(measure?: measure) {
    let possibilities = [];
    if (!this.origin && !measure) {
      for (const measureKey of Object.keys(this.measures)) {
        const measure = this.measures[measureKey];
        for (const system of SYSTEMS) {
          if (measure[system]) {
            possibilities = possibilities.concat(Object.keys(measure[system]));
          }
        }
      }
    } else {
      const measureKey = measure || this.origin.type;
      const measureObj = this.measures[measureKey];
      for (const system of SYSTEMS) {
        if (measureObj[system]) {
          possibilities = possibilities.concat(Object.keys(measure[system]));
        }
      }
    }

    return possibilities;
  };

  /**
   * Returns the abbreviated measures that the value can be
   * converted to.
   */
  measureKeys(): string [] {
    return Object.keys(this.measures);
  };
}

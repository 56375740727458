export const KML_LATLON_REGEX = /\s*(-?\d+(?:\.\d+)?)\s*,\s*(-?\d+(?:\.\d+)?)(?:\s*,\s*(-?\d+(?:\.\d+)?))?/g;
export const DMS_REGEX = /\s*(\d{1,3})°\s*(\d{1,2})'\s*(\d{1,2}(?:\.\d+)?)(?:\"|\'\')\s*([NSEW]),\s*(\d{1,3})°\s*(\d{1,2})'\s*(\d{1,2}(?:\.\d+)?)(?:\"|\'\')\s*([NSEW])/g;

/**
 * Parses a string of coordinates in DMS format to a set of lat/lon coordinates
 * @param text The string of coordinates to be parsed
 * @private
 */
export function parseDMSCoordinates(text: string): { latitude?: number, longitude?: number }[] | [] {
  const DMSCoords: {latitude?: number, longitude?: number}[] = [];
  let match: RegExpMatchArray;

  DMS_REGEX.lastIndex = 0;
  while ((match = DMS_REGEX.exec(text)) !== null) {
    const ret = {latitude: null, longitude: null};

    // First coordinate calculation
    const firstDegrees = parseInt(match[1], 10);
    const firstMinutes = parseInt(match[2], 10);
    const firstSeconds = parseFloat(match[3]);
    const firstHemisphere = match[4];
    const firstSign = (firstHemisphere.toUpperCase() === 'N' || firstHemisphere.toUpperCase() === 'E') ? 1 : -1;
    const firstCoordinate = firstSign * (firstDegrees + (firstMinutes / 60) + (firstSeconds / 3600));

    if (firstHemisphere.toUpperCase() === 'N' || firstHemisphere.toUpperCase() === 'S') {
      ret.latitude = firstCoordinate;
    } else {
      ret.longitude = firstCoordinate;
    }

    if (match[5]) {
      // Second coordinate calculation
      const secondDegrees = parseInt(match[5], 10);
      const secondMinutes = parseInt(match[6], 10);
      const secondSeconds = parseFloat(match[7]);
      const secondHemisphere = match[8];
      const secondSign = (secondHemisphere.toUpperCase() === 'N' || secondHemisphere.toUpperCase() === 'E') ? 1 : -1;
      const secondCoordinate = secondSign * (secondDegrees + (secondMinutes / 60) + (secondSeconds / 3600));

      if (secondHemisphere.toUpperCase() === 'N' || secondHemisphere.toUpperCase() === 'S') {
        ret.latitude = secondCoordinate;
      } else {
        ret.longitude = secondCoordinate;
      }
    }
    DMSCoords.push(ret);
  }

  return DMSCoords;
}

/**
 * Parses a string of coordinates in KML or lat/lon format to a set of lat/lon coordinates
 * @param text The string of coordinates to be parsed
 * @private
 */
export function parseKMLLatLonCoordinates(text: string): {latitude?: number, longitude?: number}[] {
  const KMLCoords: {latitude?: number, longitude?: number}[] = [];
  let match: RegExpMatchArray;

  KML_LATLON_REGEX.lastIndex = 0;
  while ((match = KML_LATLON_REGEX.exec(text)) !== null) {
    // Parse the matched values into lat/lon coordinates:
    // - If the values are in lat/lon format, the first value is the latitude and the second is the longitude
    // - If the values are in KML format, the first value is the longitude and the second is the latitude
    // KML values will have a third match for the altitude datum
    const latitude = !!match[3] ? parseFloat(match[2]) : parseFloat(match[1]);
    const longitude = !!match[3] ? parseFloat(match[1]) : parseFloat(match[2]);

    KMLCoords.push({latitude, longitude});
  }

  return KMLCoords;
}

import {Component, Input, OnDestroy, OnInit, Self, SkipSelf} from '@angular/core';
import {FormErrorService} from '../error-container/error-container.component';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Subscription} from 'rxjs';
import * as _ from 'lodash';


@Component({
  selector: 'error-group',
  templateUrl: './error-group.component.html',
  providers: [FormErrorService]
})
export class ErrorGroupComponent implements OnInit, OnDestroy {

  @Input() prefix = '';
  @Input() control: AbstractControl;
  private superErrorSubscription: Subscription;
  private sub: Subscription;

  constructor(@SkipSelf() private superErrorService: FormErrorService, @Self() private errorService: FormErrorService) {
  }

  ngOnInit(): void {
    this.superErrorSubscription = this.superErrorService.errors.subscribe((errors: ValidationErrors) => {
      if (!errors) {
        this.errorService.next(errors);
        return;
      }
      const ret = Object.keys(errors)
        .filter(k => k.startsWith(this.prefix))
        .map(k => [_.trimStart(k.substring(this.prefix.length), '_'), errors[k]] as [string, any])
        .reduceRight((acc, cur) => {
          acc[cur[0]] = cur[1];
          return acc;
        }, {} as ValidationErrors);

      if (Object.keys(ret).length === 0) {
        this.errorService.next(null);
      } else {
        this.errorService.next(ret);
      }
    });
    if(this.control) {
      this.sub = this.control.valueChanges.subscribe(value => {
        this.errorService.next(this.control.errors);
      });
      this.errorService.next(this.control.errors);
    }
  }

  ngOnDestroy(): void {
    this.superErrorSubscription?.unsubscribe();
    this.sub?.unsubscribe();
  }

}

// To parse this data:
//
//   import { Convert, QGCSFlightPlan } from "./file";
//
//   const qGCSFlightPlan = Convert.toQGCSFlightPlan(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface QGCSFlightPlan {
  fileType:      string;
  geoFence:      GeoFence;
  groundStation: string;
  mission:       Mission;
  rallyPoints:   RallyPoints;
  version:       number;
}

export interface GeoFence {
  circles:  any[];
  polygons: Polygon[];
  version:  number;
}

export interface Polygon {
  inclusion: boolean;
  polygon:   Array<number[]>;
  version:   number;
}

export interface Mission {
  cruiseSpeed:            number;
  firmwareType:           number;
  globalPlanAltitudeMode: number;
  hoverSpeed:             number;
  items:                  Item[];
  plannedHomePosition:    number[];
  vehicleType:            number;
  version:                number;
}

export interface Item {
  amslAltAboveTerrain?:       number | null;
  altitude?:                  number;
  altitudeMode?:              number;
  autoContinue?:              boolean;
  command?:                   number;
  doJumpID?:                  number;
  frame?:                     number;
  params?:                    (number | null)[];
  type:                       Type;
  transectStyleComplexItem?:  TransectStyleComplexItem;
  angle?:                     number;
  complexItemType?:           string;
  entryLocation?:             number;
  flyAlternateTransects?:     boolean;
  polygon?:                   Array<number[]>;
  splitConcavePolygons?:      boolean;
  version?:                   number;
  altitudesAreRelative?:      boolean;
  landCoordinate?:            number[];
  landingApproachCoordinate?: number[];
  loiterClockwise?:           boolean;
  loiterRadius?:              number;
  stopTakingPhotos?:          boolean;
  stopVideoPhotos?:           boolean;
  useLoiterToAlt?:            boolean;
  valueSetIsDistance?:        boolean;
  corridorWidth?:             number;
  entryPoint?:                number;
  polyline?:                  Array<number[]>;
}

export interface TransectStyleComplexItem {
  cameraCalc:                CameraCalc;
  cameraShots:               number;
  cameraTriggerInTurnAround: boolean;
  hoverAndCapture:           boolean;
  items:                     ItemElement[];
  refly90Degrees:            boolean;
  turnAroundDistance:        number;
  visualTransectPoints:      Array<number[]>;
  version:                   number;
}

export interface CameraCalc {
  adjustedFootprintFrontal: number;
  adjustedFootprintSide:    number;
  cameraName:               string;
  distanceMode:             number;
  distanceToSurface:        number;
  version:                  number;
}

export interface ItemElement {
  autoContinue: boolean;
  command:      number;
  doJumpID:     number;
  frame:        number;
  params:       (number | null)[];
  type:         Type;
}

export enum Type {
  ComplexItem = "ComplexItem",
  SimpleItem = "SimpleItem",
}

export interface RallyPoints {
  points:  any[];
  version: number;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
  public static toQGCSFlightPlan(json: string): QGCSFlightPlan {
    return cast(JSON.parse(json), r("QGCSFlightPlan"));
  }

  public static qGCSFlightPlanToJson(value: QGCSFlightPlan): string {
    return JSON.stringify(uncast(value, r("QGCSFlightPlan")), null, 2);
  }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ''): never {
  const prettyTyp = prettyTypeName(typ);
  const parentText = parent ? ` on ${parent}` : '';
  const keyText = key ? ` for key "${key}"` : '';
  throw Error(`Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(val)}`);
}

function prettyTypeName(typ: any): string {
  if (Array.isArray(typ)) {
    if (typ.length === 2 && typ[0] === undefined) {
      return `an optional ${prettyTypeName(typ[1])}`;
    } else {
      return `one of [${typ.map(a => { return prettyTypeName(a); }).join(", ")}]`;
    }
  } else if (typeof typ === "object" && typ.literal !== undefined) {
    return typ.literal;
  } else {
    return typeof typ;
  }
}

function jsonToJSProps(typ: any): any {
  if (typ.jsonToJS === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
    typ.jsonToJS = map;
  }
  return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
  if (typ.jsToJSON === undefined) {
    const map: any = {};
    typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
    typ.jsToJSON = map;
  }
  return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = '', parent: any = ''): any {
  function transformPrimitive(typ: string, val: any): any {
    if (typeof typ === typeof val) return val;
    return invalidValue(typ, val, key, parent);
  }

  function transformUnion(typs: any[], val: any): any {
    // val must validate against one typ in typs
    const l = typs.length;
    for (let i = 0; i < l; i++) {
      const typ = typs[i];
      try {
        return transform(val, typ, getProps);
      } catch (_) {}
    }
    return invalidValue(typs, val, key, parent);
  }

  function transformEnum(cases: string[], val: any): any {
    if (cases.indexOf(val) !== -1) return val;
    return invalidValue(cases.map(a => { return l(a); }), val, key, parent);
  }

  function transformArray(typ: any, val: any): any {
    // val must be an array with no invalid elements
    if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
    return val.map(el => transform(el, typ, getProps));
  }

  function transformDate(val: any): any {
    if (val === null) {
      return null;
    }
    const d = new Date(val);
    if (isNaN(d.valueOf())) {
      return invalidValue(l("Date"), val, key, parent);
    }
    return d;
  }

  function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
    if (val === null || typeof val !== "object" || Array.isArray(val)) {
      return invalidValue(l(ref || "object"), val, key, parent);
    }
    const result: any = {};
    Object.getOwnPropertyNames(props).forEach(key => {
      const prop = props[key];
      const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
      result[prop.key] = transform(v, prop.typ, getProps, key, ref);
    });
    Object.getOwnPropertyNames(val).forEach(key => {
      if (!Object.prototype.hasOwnProperty.call(props, key)) {
        result[key] = transform(val[key], additional, getProps, key, ref);
      }
    });
    return result;
  }

  if (typ === "any") return val;
  if (typ === null) {
    if (val === null) return val;
    return invalidValue(typ, val, key, parent);
  }
  if (typ === false) return invalidValue(typ, val, key, parent);
  let ref: any = undefined;
  while (typeof typ === "object" && typ.ref !== undefined) {
    ref = typ.ref;
    typ = typeMap[typ.ref];
  }
  if (Array.isArray(typ)) return transformEnum(typ, val);
  if (typeof typ === "object") {
    return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
      : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
        : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
          : invalidValue(typ, val, key, parent);
  }
  // Numbers can be parsed by Date but shouldn't be.
  if (typ === Date && typeof val !== "number") return transformDate(val);
  return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
  return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
  return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
  return { literal: typ };
}

function a(typ: any) {
  return { arrayItems: typ };
}

function u(...typs: any[]) {
  return { unionMembers: typs };
}

function o(props: any[], additional: any) {
  return { props, additional };
}

function m(additional: any) {
  return { props: [], additional };
}

function r(name: string) {
  return { ref: name };
}

const typeMap: any = {
  "QGCSFlightPlan": o([
    { json: "fileType", js: "fileType", typ: "" },
    { json: "geoFence", js: "geoFence", typ: r("GeoFence") },
    { json: "groundStation", js: "groundStation", typ: "" },
    { json: "mission", js: "mission", typ: r("Mission") },
    { json: "rallyPoints", js: "rallyPoints", typ: r("RallyPoints") },
    { json: "version", js: "version", typ: 0 },
  ], false),
  "GeoFence": o([
    { json: "circles", js: "circles", typ: a("any") },
    { json: "polygons", js: "polygons", typ: a(r("Polygon")) },
    { json: "version", js: "version", typ: 0 },
  ], false),
  "Polygon": o([
    { json: "inclusion", js: "inclusion", typ: true },
    { json: "polygon", js: "polygon", typ: a(a(3.14)) },
    { json: "version", js: "version", typ: 0 },
  ], false),
  "Mission": o([
    { json: "cruiseSpeed", js: "cruiseSpeed", typ: 3.14 },
    { json: "firmwareType", js: "firmwareType", typ: 0 },
    { json: "globalPlanAltitudeMode", js: "globalPlanAltitudeMode", typ: 0 },
    { json: "hoverSpeed", js: "hoverSpeed", typ: 0 },
    { json: "items", js: "items", typ: a(r("Item")) },
    { json: "plannedHomePosition", js: "plannedHomePosition", typ: a(3.14) },
    { json: "vehicleType", js: "vehicleType", typ: 0 },
    { json: "version", js: "version", typ: 0 },
  ], false),
  "Item": o([
    { json: "AMSLAltAboveTerrain", js: "amslAltAboveTerrain", typ: u(undefined, u(3.14, null)) },
    { json: "Altitude", js: "altitude", typ: u(undefined, 3.14) },
    { json: "AltitudeMode", js: "altitudeMode", typ: u(undefined, 0) },
    { json: "autoContinue", js: "autoContinue", typ: u(undefined, true) },
    { json: "command", js: "command", typ: u(undefined, 0) },
    { json: "doJumpId", js: "doJumpID", typ: u(undefined, 0) },
    { json: "frame", js: "frame", typ: u(undefined, 0) },
    { json: "params", js: "params", typ: u(undefined, a(u(3.14, null))) },
    { json: "type", js: "type", typ: r("Type") },
    { json: "TransectStyleComplexItem", js: "transectStyleComplexItem", typ: u(undefined, r("TransectStyleComplexItem")) },
    { json: "angle", js: "angle", typ: u(undefined, 0) },
    { json: "complexItemType", js: "complexItemType", typ: u(undefined, "") },
    { json: "entryLocation", js: "entryLocation", typ: u(undefined, 0) },
    { json: "flyAlternateTransects", js: "flyAlternateTransects", typ: u(undefined, true) },
    { json: "polygon", js: "polygon", typ: u(undefined, a(a(3.14))) },
    { json: "splitConcavePolygons", js: "splitConcavePolygons", typ: u(undefined, true) },
    { json: "version", js: "version", typ: u(undefined, 0) },
    { json: "altitudesAreRelative", js: "altitudesAreRelative", typ: u(undefined, true) },
    { json: "landCoordinate", js: "landCoordinate", typ: u(undefined, a(3.14)) },
    { json: "landingApproachCoordinate", js: "landingApproachCoordinate", typ: u(undefined, a(3.14)) },
    { json: "loiterClockwise", js: "loiterClockwise", typ: u(undefined, true) },
    { json: "loiterRadius", js: "loiterRadius", typ: u(undefined, 0) },
    { json: "stopTakingPhotos", js: "stopTakingPhotos", typ: u(undefined, true) },
    { json: "stopVideoPhotos", js: "stopVideoPhotos", typ: u(undefined, true) },
    { json: "useLoiterToAlt", js: "useLoiterToAlt", typ: u(undefined, true) },
    { json: "valueSetIsDistance", js: "valueSetIsDistance", typ: u(undefined, true) },
    { json: "CorridorWidth", js: "corridorWidth", typ: u(undefined, 0) },
    { json: "EntryPoint", js: "entryPoint", typ: u(undefined, 0) },
    { json: "polyline", js: "polyline", typ: u(undefined, a(a(3.14))) },
  ], false),
  "TransectStyleComplexItem": o([
    { json: "CameraCalc", js: "cameraCalc", typ: r("CameraCalc") },
    { json: "CameraShots", js: "cameraShots", typ: 0 },
    { json: "CameraTriggerInTurnAround", js: "cameraTriggerInTurnAround", typ: true },
    { json: "HoverAndCapture", js: "hoverAndCapture", typ: true },
    { json: "Items", js: "items", typ: a(r("ItemElement")) },
    { json: "Refly90Degrees", js: "refly90Degrees", typ: true },
    { json: "TurnAroundDistance", js: "turnAroundDistance", typ: 0 },
    { json: "VisualTransectPoints", js: "visualTransectPoints", typ: a(a(3.14)) },
    { json: "version", js: "version", typ: 0 },
  ], false),
  "CameraCalc": o([
    { json: "AdjustedFootprintFrontal", js: "adjustedFootprintFrontal", typ: 0 },
    { json: "AdjustedFootprintSide", js: "adjustedFootprintSide", typ: 0 },
    { json: "CameraName", js: "cameraName", typ: "" },
    { json: "DistanceMode", js: "distanceMode", typ: 0 },
    { json: "DistanceToSurface", js: "distanceToSurface", typ: 0 },
    { json: "version", js: "version", typ: 0 },
  ], false),
  "ItemElement": o([
    { json: "autoContinue", js: "autoContinue", typ: true },
    { json: "command", js: "command", typ: 0 },
    { json: "doJumpId", js: "doJumpID", typ: 0 },
    { json: "frame", js: "frame", typ: 0 },
    { json: "params", js: "params", typ: a(u(3.14, null)) },
    { json: "type", js: "type", typ: r("Type") },
  ], false),
  "RallyPoints": o([
    { json: "points", js: "points", typ: a("any") },
    { json: "version", js: "version", typ: 0 },
  ], false),
  "Type": [
    "ComplexItem",
    "SimpleItem",
  ],
};

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {PlatformService} from '../../../shared/services/platform.service';
import {UasRegistrationInfo} from '../../../shared/model/UasRegistrationInfo';
import {SubmissionMode} from '../../../shared/model/SubmissionStatus';

@Component({
  selector: 'app-new-platform',
  templateUrl: './new-platform.component.html'
})
export class NewPlatformComponent implements OnInit {
  platform: UasRegistrationInfo;
  submissionMode = SubmissionMode.CREATE;
  titleText = "Create Platform";

  constructor(private route: ActivatedRoute,
              private platformService: PlatformService) {
    // Constructor intentionally left empty
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        const platformId = params.platformid || params.cloneid;
        if (platformId) {
          this.platformService.getPlatform(platformId).subscribe((platform) => {
            this.platform = platform;
            this.submissionMode = params.platformid ? SubmissionMode.EDIT : SubmissionMode.CREATE;
            this.setTitleText();
          });
        }
        }
      );
  }

  setTitleText(): void {
    if (this.submissionMode == SubmissionMode.EDIT) {
      this.titleText = 'Edit Platform';
    } else if (this.submissionMode == SubmissionMode.CREATE && this.platform) {
      this.titleText = 'Clone Platform';
    } else {
      this.titleText = 'Create Platform';
    }
  }

}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SearchResult} from '../model/SearchResult';
import {ApprovalInfo} from '../model/gen/utm/approval-info';
import {OperationApproval} from '../model/OperationApproval';
import {ApprovalStatus} from '../model/gen/utm';

@Injectable({
  providedIn: 'root'
})
export abstract class ApprovalService {
  abstract getOperationApprovals(status: ApprovalStatus[], limit?: number, offset?: number, fetchCount?: boolean): Observable<SearchResult<ApprovalInfo>>;

  abstract approveOperation(data: OperationApproval): Observable<boolean>;
}

import {Component, forwardRef, Input} from '@angular/core';
import {UasRegistrationInfo} from '../../../model/UasRegistrationInfo';
import {ClrDatagridStateInterface} from '@clr/angular';
import {SearchResult} from '../../../model/SearchResult';
import {PlatformService} from '../../../services/platform.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-platform-selector',
  templateUrl: './platform-selector.component.html',
  styleUrls: ['./platform-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PlatformSelectorComponent),
    }
  ]
})
export class PlatformSelectorComponent implements ControlValueAccessor {
  @Input() platform: UasRegistrationInfo;
  platformText = '';
  isDisabled: boolean;
  selectingPlatform: boolean;
  platforms: UasRegistrationInfo[];
  currentPageSize: number;
  totalItems: number;
  loading = true;
  state: ClrDatagridStateInterface;
  previousState: ClrDatagridStateInterface;
  private onChange: (selectedUas: UasRegistrationInfo) => void;
  private onTouched: () => void;

  constructor(private platformService: PlatformService) {
  }

  registerOnChange(fn: (selectedUas: UasRegistrationInfo) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: UasRegistrationInfo): void {
    this.platform = obj;
    this.setPlatformText();
  }

  openPlatformSelector($event: MouseEvent) {
    this.selectingPlatform = true;

    $event.stopPropagation();
  }

  refresh(statey: ClrDatagridStateInterface) {
    this.loading = true;
    if (!statey) {
      statey = this.state || {};
    }

    this.state = statey;

    if (!statey.page) {
      statey.page = {
        from: 0,
        to: 9,
        size: 10,
      };
    }


    const fetchCount = !this.totalItems;

    this.platformService.getPlatforms({archived: false, }, statey.page.size, statey.page.from, fetchCount)
      .subscribe((results: SearchResult<UasRegistrationInfo>) => {
        if (results.total >= 0) {
          this.totalItems = results.total;
        }
        this.loading = false;
        this.platforms = results.results;
        statey.page.from = results.offset;
        statey.page.to = results.offset + results.results.length;
      });

    this.previousState = statey;

  }

  selectPlatform(platform: UasRegistrationInfo) {
    this.platform = platform;
    this.setPlatformText();
    this.selectingPlatform = false;
    if (this.onChange) {
      this.onChange(this.platform);
    }
    if (this.onTouched) {
      this.onTouched();
    }
  }

  setPlatformText(): void {

    if (this.platform) {
      this.platformText = `${this.platform.aircraftSpecs.make} ${this.platform.aircraftSpecs.model}` +
        `${this.platform.faaRegistrationNumber ? ' -- ' + this.platform.faaRegistrationNumber : ''}`;
    } else {
      this.platformText = '';
    }
  }

}

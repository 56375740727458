import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class ConstraintTypeService {

  constructor() {
    // Method not implemented
  }

  abstract fetchConstraintTypes($event: string): Observable<{ name: string; value: string }[]>;

  abstract getPrettyName(constraintValue: string): Observable<string>;
}

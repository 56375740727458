/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';

import {event_source, setting, source, test_type} from './enums';

export interface IEventMetadata {
  data_collection: boolean;
  event_id: string;
  scenario: string;
  test_card: string;
  test_run: string;
  call_sign: string;
  test_type: test_type;
  source: event_source;
  location: string;
  setting: setting;
  free_text?: string;
  data_quality_engineer?: string;
  modified?: boolean;
}


export class EventMetadata extends BaseModel implements IEventMetadata {

  static DATA_COLLECTION_FIELD_NAME = 'data_collection';
  static EVENT_ID_FIELD_NAME = 'event_id';
  static SCENARIO_FIELD_NAME = 'scenario';
  static TEST_CARD_FIELD_NAME = 'test_card';
  static TEST_RUN_FIELD_NAME = 'test_run';
  static CALL_SIGN_FIELD_NAME = 'call_sign';
  static TEST_TYPE_FIELD_NAME = 'test_type';
  static SOURCE_FIELD_NAME = 'source';
  static LOCATION_FIELD_NAME = 'location';
  static SETTING_FIELD_NAME = 'setting';
  static FREE_TEXT_FIELD_NAME = 'free_text';
  static DATA_QUALITY_ENGINEER_FIELD_NAME = 'data_quality_engineer';
  static MODIFIED_FIELD_NAME = 'modified';

  /** If true these data are intended for Data Collection. Essentially stating if particular data should be ignored during analysis. This may be modified after submission in the case that there was an issue during execution of the test/experiment that would invalidate the data that were collected. */
  data_collection: boolean;
  /** A string tag indicating which event this operation is associated. Will be used to select a set of rules for validation. */
  event_id: string;
  /** Proper formatting for SOW scenarios is "SCENARIO_n" where n is 1-5.
   For other scenarios, the appropriate value should be provided by the POC for that activity. */
  scenario: string;
  /** The name or number of the test card.  In many cases, a scenario will have only one test_card, but it is possible that there are multiple test_cards for a given scenario. */
  test_card: string;
  /** An identifier for a specific run of a test_card.  In many cases, a test_card may be only run once.  However, it is possible that a test_card is run multiple times. */
  test_run: string;
  /** Expected values: a call sign, test card role, or flight ID that links this operation to a particular test card flight. As an example, in our TCL2 demo, "GCS3" could have been used here. For multiple flights emanating from one location on a test card, this data element could include further information to link the operation to the test card flight, such as GCS1A.
   Every operation requires a call_sign that is pre-determined in the full test card description. */
  call_sign: string;
  /** Test type. Note a GROUND test may use a physical vehicle and a FLIGHT test may use a simulated vehicle.  This is the reason for separating test_type from source. */
  test_type: test_type;
  /** Hardware-in-the-loop (HWITL) flights refer to ground or airborne flights. Software-in-the loop (SWITL) flights would be used to tag flights do not use a physical vehicle, whether or not there is a full-featured simulation involved. */
  source: event_source;
  /** Name of the testing location, such as NV, Moffett, Crows Landing, etc.  The supplier of the metadata should use a constant value for this field when the same location is used mulitple times across data submissions. E.g. "AMES" should always be "AMES" and not "ames" or "Ames" or "NASA Ames" at other various times. */
  location: string;
  /** Test setting. Note that a LAB setting may involve HWITL source and FIELD settings may involve SWITL sources.  LAB settings may have GROUND or FLIGHT tests, same with FIELD settings. */
  setting: setting;
  /** Free text may be included with the original submission and/or added by a data_quality_engineer.  In the latter case, all previous free_text should be preserved, i.e. the data_quality_engineer should only append to the free_text field. */
  free_text: string;
  /** Email address of author handling data quality issue. If multiple individuals modify this metadata, this field will only hold the last such individual.  In that case, the engineer should make an effort to document as much as possible in the free_text field.
   SHOULD NOT be included wth an operation.  This element is populated by NASA as needed. */
  data_quality_engineer: string;
  /** This metadata was modified from its original submission by a data_quality_engineer.
   SHOULD NOT be included wth an operation.  This element is populated by NASA as needed. */
  modified: boolean;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IEventMetadata>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IEventMetadata>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.data_collection = this.getValue<boolean>(rawValues, EventMetadata.DATA_COLLECTION_FIELD_NAME);
      this.event_id = this.getValue<string>(rawValues, EventMetadata.EVENT_ID_FIELD_NAME);
      this.scenario = this.getValue<string>(rawValues, EventMetadata.SCENARIO_FIELD_NAME);
      this.test_card = this.getValue<string>(rawValues, EventMetadata.TEST_CARD_FIELD_NAME);
      this.test_run = this.getValue<string>(rawValues, EventMetadata.TEST_RUN_FIELD_NAME);
      this.call_sign = this.getValue<string>(rawValues, EventMetadata.CALL_SIGN_FIELD_NAME);
      this.test_type = this.getValue<test_type>(rawValues, EventMetadata.TEST_TYPE_FIELD_NAME);
      this.source = this.getValue<event_source>(rawValues, EventMetadata.SOURCE_FIELD_NAME);
      this.location = this.getValue<string>(rawValues, EventMetadata.LOCATION_FIELD_NAME);
      this.setting = this.getValue<setting>(rawValues, EventMetadata.SETTING_FIELD_NAME);
      this.free_text = this.getValue<string>(rawValues, EventMetadata.FREE_TEXT_FIELD_NAME);
      this.data_quality_engineer = this.getValue<string>(rawValues, EventMetadata.DATA_QUALITY_ENGINEER_FIELD_NAME);
      this.modified = this.getValue<boolean>(rawValues, EventMetadata.MODIFIED_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        data_collection: new UntypedFormControl(this.data_collection, [Validators.required,]),
        event_id: new UntypedFormControl(this.event_id, [Validators.required, Validators.minLength(1), Validators.maxLength(100),]),
        scenario: new UntypedFormControl(this.scenario, [Validators.required, Validators.minLength(1), Validators.maxLength(100),]),
        test_card: new UntypedFormControl(this.test_card, [Validators.required, Validators.minLength(1), Validators.maxLength(100),]),
        test_run: new UntypedFormControl(this.test_run, [Validators.required, Validators.minLength(1), Validators.maxLength(100),]),
        call_sign: new UntypedFormControl(this.call_sign, [Validators.required, Validators.minLength(1), Validators.maxLength(100),]),
        test_type: new UntypedFormControl(this.test_type, [Validators.required, enumValidator(test_type),]),
        source: new UntypedFormControl(this.source, [Validators.required, enumValidator(source),]),
        location: new UntypedFormControl(this.location, [Validators.required, Validators.minLength(1), Validators.maxLength(100),]),
        setting: new UntypedFormControl(this.setting, [Validators.required, enumValidator(setting),]),
        free_text: new UntypedFormControl(this.free_text, [Validators.maxLength(3000),]),
        data_quality_engineer: new UntypedFormControl(this.data_quality_engineer, [Validators.maxLength(150),]),
        modified: new UntypedFormControl(this.modified),
      });
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[EventMetadata.DATA_COLLECTION_FIELD_NAME].setValue(this.data_collection);
    this.$formGroup.controls[EventMetadata.EVENT_ID_FIELD_NAME].setValue(this.event_id);
    this.$formGroup.controls[EventMetadata.SCENARIO_FIELD_NAME].setValue(this.scenario);
    this.$formGroup.controls[EventMetadata.TEST_CARD_FIELD_NAME].setValue(this.test_card);
    this.$formGroup.controls[EventMetadata.TEST_RUN_FIELD_NAME].setValue(this.test_run);
    this.$formGroup.controls[EventMetadata.CALL_SIGN_FIELD_NAME].setValue(this.call_sign);
    this.$formGroup.controls[EventMetadata.TEST_TYPE_FIELD_NAME].setValue(this.test_type);
    this.$formGroup.controls[EventMetadata.SOURCE_FIELD_NAME].setValue(this.source);
    this.$formGroup.controls[EventMetadata.LOCATION_FIELD_NAME].setValue(this.location);
    this.$formGroup.controls[EventMetadata.SETTING_FIELD_NAME].setValue(this.setting);
    this.$formGroup.controls[EventMetadata.FREE_TEXT_FIELD_NAME].setValue(this.free_text);
    this.$formGroup.controls[EventMetadata.DATA_QUALITY_ENGINEER_FIELD_NAME].setValue(this.data_quality_engineer);
    this.$formGroup.controls[EventMetadata.MODIFIED_FIELD_NAME].setValue(this.modified);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

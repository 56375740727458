import {DateTime} from 'luxon';
import {BaseModel} from './base-model';
import {IPolygon} from './polygon.model';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {maxValueValidator, minValueValidator} from './validators';
import {IGeoCircle} from '../../Circle.model';

export interface IUvrVolumeSubmission {
  effective_time_begin?: DateTime;
  effective_time_end: DateTime;
  min_altitude: number;
  max_altitude: number;
  geography: IPolygon;
  circle: IGeoCircle;
}

export class UvrVolumeSubmission extends BaseModel implements IUvrVolumeSubmission {
  static effectiveTimeBeginFieldName = 'effective_time_begin';
  static effectiveTimeEndFieldName = 'effective_time_end';
  static minAltitudeFieldName = 'min_altitude';
  static maxAltitudeFieldName = 'max_altitude';
  static geographyFieldName = 'geography';
  static circleFieldName = 'circle';

  /** Earliest time the constraint will use the constraint volume. It must be less than effectiveTimeEnd.
   * effectiveTimeBegin < effectiveTimeEnd MUST be true.
   */
  effective_time_begin?: DateTime;

  /** Latest time the constraint will use the constraint volume. It must be greater than effectiveTimeBegin.
   * effectiveTimeBegin < effectiveTimeEnd MUST be true.
   */
  effective_time_end: DateTime;

  /** The numeric value of the minimum altitude for this constraint in this constraint volume.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: min_altitude < max_altitude
   */
  min_altitude: number;

  /** The numeric value of the maximum altitude for this constraint in this constraint volume.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: min_altitude < max_altitude
   */
  max_altitude: number;

  // A code indicating the reference for a vertical distance. See AIXM 5.1 and FIXM 4.1.0.
  // Currently, UTM only allows WGS84 with no immediate plans to allow other options. FIXM and AIXM allow for 'SFC'
  // which is equivalent to AGL.
  //
  // altitude_vertical_reference: vertical_reference;

  // The reference quantities used to express the value of altitude. See FIXM 4.1.
  // Currently, UTM only allows feet with no immediate plans to allow other options.  FIXM allows for feet or meters.
  //
  // altitude_units: units_of_measure;

  geography: IPolygon;

  circle: IGeoCircle;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @param useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IUvrVolumeSubmission>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   *
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IUvrVolumeSubmission>, useFormGroupValuestoModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuestoModel);
      this.effective_time_begin = this.getValue<DateTime>(rawValues, UvrVolumeSubmission.effectiveTimeBeginFieldName);
      this.effective_time_end = this.getValue<DateTime>(rawValues, UvrVolumeSubmission.effectiveTimeEndFieldName);
      this.min_altitude = this.getValue<number>(rawValues, UvrVolumeSubmission.minAltitudeFieldName);
      this.max_altitude = this.getValue<number>(rawValues, UvrVolumeSubmission.maxAltitudeFieldName);
      this.geography = this.getValue<IPolygon>(rawValues, UvrVolumeSubmission.geographyFieldName);
      this.circle = this.getValue<IGeoCircle>(rawValues, UvrVolumeSubmission.circleFieldName);
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuestoModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[UvrVolumeSubmission.effectiveTimeBeginFieldName].setValue(this.effective_time_begin);
    this.$formGroup.controls[UvrVolumeSubmission.effectiveTimeEndFieldName].setValue(this.effective_time_end);
    this.$formGroup.controls[UvrVolumeSubmission.minAltitudeFieldName].setValue(this.min_altitude);
    this.$formGroup.controls[UvrVolumeSubmission.maxAltitudeFieldName].setValue(this.max_altitude);
    this.$formGroup.controls[UvrVolumeSubmission.geographyFieldName].setValue(this.geography);
    this.$formGroup.controls[UvrVolumeSubmission.circleFieldName].setValue(this.circle);
    super.setFormGroupValuesInAddedFormControls();
  }


  protected getFormGroup(): FormGroup {
    if (!this._formGroup) {
      this._formGroup = new FormGroup({
        effectiveTimeBegin: new FormControl(this.effective_time_begin, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24),
          Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$')]),
        effectiveTimeEnd: new FormControl(this.effective_time_end, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24),
          Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$')]),
        minAltitude: new FormControl(this.min_altitude, [Validators.required, minValueValidator(-8000), maxValueValidator(100000)]),
        maxAltitude: new FormControl(this.max_altitude, [Validators.required, minValueValidator(-8000), maxValueValidator(100000)]),
        geography: new FormControl(this.geography),
        circle: new FormControl(this.circle),
      });
    }
    return this._formGroup;
  }
}

/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';
import {cause, permitted_uas, required_support, uasvolumereservation_type} from './enums';
import {DateTime} from 'luxon';
import {EntityVolume4d, IEntityVolume4d} from './entity-volume4d.model';

export interface IUASVolumeReservation {
  managed: boolean;
  message_id: string;
  uss_name: string;
  type: uasvolumereservation_type;
  permitted_uas: Array<permitted_uas>;
  required_support?: Array<required_support>;
  permitted_gufis?: Array<string>;
  cause: cause;
  volumes: Array<IEntityVolume4d>;
  effective_time_begin: DateTime;
  effective_time_end: DateTime;
  actual_time_end?: DateTime;
  reason?: string;
  submit_time: DateTime;
  update_time: DateTime;
}

export class UASVolumeReservation extends BaseModel implements IUASVolumeReservation {
  static MANAGED_FIELD_NAME = 'managed';
  static MESSAGE_ID_FIELD_NAME = 'message_id';
  static USS_NAME_FIELD_NAME = 'uss_name';
  static TYPE_FIELD_NAME = 'type';
  static PERMITTED_UAS_FIELD_NAME = 'permitted_uas';
  static REQUIRED_SUPPORT_FIELD_NAME = 'required_support';
  static PERMITTED_GUFIS_FIELD_NAME = 'permitted_gufis';
  static CAUSE_FIELD_NAME = 'cause';
  static VOLUMES_FIELD_NAME = 'volumes';
  static EFFECTIVE_TIME_BEGIN_FIELD_NAME = 'effective_time_begin';
  static EFFECTIVE_TIME_END_FIELD_NAME = 'effective_time_end';
  static ACTUAL_TIME_END_FIELD_NAME = 'actual_time_end';
  static REASON_FIELD_NAME = 'reason';

  static SUBMIT_TIME_FIELD_NAME = 'submit_time';
  static UPDATE_TIME_FIELD_NAME = 'update_time';


  /**
   * Indicates whether the constraint is managed by an AX Enterprize USS.
   */
  managed: boolean;

  message_id: string;

  /** A name identifying the originator of this message. The maximum and minimum character length is based on a usable domain name, and considering the maximum in RFC-1035. */
  uss_name: string;

  /** The type of UVR.  It is expected this list will grow as use cases are identified and cataloged.
   Currently there is only a differentiation between a dynamic restriction and a static advisory.  There is no official guidance on the difference as of today.  Roughly, the assumption is that static advisories would have a longer lead time and/or longer duration than a dynamic restriction. */
  type: uasvolumereservation_type;

  /** The types of UAS operations that are permitted within the bound of the UVR. When used in conjunction with permitted_gufis, the filters are an 'OR' relationship. Any operation that matches either filter is allowed within the bounds of the UVR.  This list of permitted_uas types is not to be considered a final version.  It is provided to initiate discussion of how the filter should be defined.
   Duplicate strings not allowed in this array.
   If NOT_SET is included, then no other strings allowed in the array. */
  permitted_uas: Array<permitted_uas>;

  /** If the permitted_uas array contains "SUPPORT_LEVEL" this required_support field is required.  This field describes the support required by an operation to continue to operate within or to enter the UVR.  By remaining within or entering a UVR with the listed required support, the operator is attesting that it is using all elements listed in the required support array.
   To restate:  the array of values in this field is an AND relationship, thus all elements must be utilized by each operation within such a UVR.  If an operator does not have every support elements listed, then its operation must exit or avoid entry to the UVR. */
  required_support: Array<required_support>;

  /** The specific UAS operations that are permitted within the bounds of the UVR.  When used in conjunction with permitted_uas, the filters are an 'OR' relationship. Any operation that matches either filter is allowed within the bounds of the UVR.  The protocol for adding the appropriate GUFIs to this array needs to be discussed. This may be a chicken/egg problem that will have to be worked through. */
  permitted_gufis: Array<string>;

  /** The cause for the UVR. */
  cause: cause;

  volumes: Array<EntityVolume4d>;

  /** The time that the UVR will begin to be in effect. UASVolumeReservation MUST satisfy effective_time_begin < effective_time_end. */
  effective_time_begin: DateTime;

  /** The time that the UVR will cease to be in effect. UASVolumeReservation MUST satisfy effective_time_begin < effective_time_end. */
  effective_time_end: DateTime;

  /** Time that the constaint actually ceased being in effect. This value is likely used when a UVR ends early, but is not required. */
  actual_time_end: DateTime;

  /** The reason for the UVR. Meant for human consumption. */
  reason: string;


  submit_time: DateTime;
  update_time: DateTime;
  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @param useFormGroupValuesToModel If true, use formValues
   */
  constructor(values?: Partial<IUASVolumeReservation>, useFormGroupValuesToModel = false) {
    super();
    this.permitted_uas = [];
    this.required_support = [];
    this.permitted_gufis = [];
    this.volumes = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IUASVolumeReservation>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.managed = this.getValue<boolean>(rawValues, UASVolumeReservation.MANAGED_FIELD_NAME);
      this.message_id = this.getValue<string>(rawValues, UASVolumeReservation.MESSAGE_ID_FIELD_NAME);
      this.uss_name = this.getValue<string>(rawValues, UASVolumeReservation.USS_NAME_FIELD_NAME);
      this.type = this.getValue<uasvolumereservation_type>(rawValues, UASVolumeReservation.TYPE_FIELD_NAME);
      this.fillModelArray<permitted_uas>(this, UASVolumeReservation.PERMITTED_UAS_FIELD_NAME, rawValues.permitted_uas, useFormGroupValuesToModel);
      this.fillModelArray<required_support>(this, UASVolumeReservation.REQUIRED_SUPPORT_FIELD_NAME, rawValues.required_support, useFormGroupValuesToModel);
      this.fillModelArray<string>(this, UASVolumeReservation.PERMITTED_GUFIS_FIELD_NAME, rawValues.permitted_gufis, useFormGroupValuesToModel);
      this.cause = this.getValue<cause>(rawValues, UASVolumeReservation.CAUSE_FIELD_NAME);
      this.fillModelArray<IEntityVolume4d>(this, UASVolumeReservation.VOLUMES_FIELD_NAME, rawValues.volumes, useFormGroupValuesToModel);
      this.effective_time_begin = this.getValue<DateTime>(rawValues, UASVolumeReservation.EFFECTIVE_TIME_BEGIN_FIELD_NAME);
      this.effective_time_end = this.getValue<DateTime>(rawValues, UASVolumeReservation.EFFECTIVE_TIME_END_FIELD_NAME);
      this.actual_time_end = this.getValue<DateTime>(rawValues, UASVolumeReservation.ACTUAL_TIME_END_FIELD_NAME);
      this.reason = this.getValue<string>(rawValues, UASVolumeReservation.REASON_FIELD_NAME);
      this.submit_time = this.getValue<DateTime>(rawValues, UASVolumeReservation.SUBMIT_TIME_FIELD_NAME);
      this.update_time = this.getValue<DateTime>(rawValues, UASVolumeReservation.UPDATE_TIME_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        managed: new UntypedFormControl(this.managed),
        message_id: new UntypedFormControl(this.message_id, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'), ]),
        uss_name: new UntypedFormControl(this.uss_name, [Validators.required, Validators.minLength(4), Validators.maxLength(250), ]),
        type: new UntypedFormControl(this.type, [Validators.required, enumValidator(uasvolumereservation_type), ]),
        permitted_uas: new UntypedFormArray([]),
        required_support: new UntypedFormArray([]),
        permitted_gufis: new UntypedFormArray([]),
        cause: new UntypedFormControl(this.cause, [Validators.required, enumValidator(cause), ]),
        volumes: new UntypedFormArray([]),
        effective_time_begin: new UntypedFormControl(this.effective_time_begin, [Validators.required, Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'), ]),
        effective_time_end: new UntypedFormControl(this.effective_time_end, [Validators.required, Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'), ]),
        actual_time_end: new UntypedFormControl(this.actual_time_end, [Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'), ]),
        reason: new UntypedFormControl(this.reason, [Validators.maxLength(1000), ]),
        submit_time: new UntypedFormControl(this.submit_time, [Validators.required, Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'), ]),
        update_time: new UntypedFormControl(this.update_time, [Validators.required, Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'), ]),

      });
      // generate FormArray control elements
      this.fillFormArray<permitted_uas>(UASVolumeReservation.PERMITTED_UAS_FIELD_NAME, this.permitted_uas);
      // generate FormArray control elements
      this.fillFormArray<required_support>(UASVolumeReservation.REQUIRED_SUPPORT_FIELD_NAME, this.required_support);
      // generate FormArray control elements
      this.fillFormArray<string>(UASVolumeReservation.PERMITTED_GUFIS_FIELD_NAME, this.permitted_gufis);
      // generate FormArray control elements
      this.fillFormArray<EntityVolume4d>(UASVolumeReservation.VOLUMES_FIELD_NAME, this.volumes);
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[UASVolumeReservation.MANAGED_FIELD_NAME].setValue(this.managed);
    this.$formGroup.controls[UASVolumeReservation.MESSAGE_ID_FIELD_NAME].setValue(this.message_id);
    this.$formGroup.controls[UASVolumeReservation.USS_NAME_FIELD_NAME].setValue(this.uss_name);
    this.$formGroup.controls[UASVolumeReservation.TYPE_FIELD_NAME].setValue(this.type);
    this.fillFormArray<permitted_uas>(UASVolumeReservation.PERMITTED_UAS_FIELD_NAME, this.permitted_uas);
    this.fillFormArray<required_support>(UASVolumeReservation.REQUIRED_SUPPORT_FIELD_NAME, this.required_support);
    this.fillFormArray<string>(UASVolumeReservation.PERMITTED_GUFIS_FIELD_NAME, this.permitted_gufis);
    this.$formGroup.controls[UASVolumeReservation.CAUSE_FIELD_NAME].setValue(this.cause);
    this.fillFormArray<EntityVolume4d>(UASVolumeReservation.VOLUMES_FIELD_NAME, this.volumes);
    this.$formGroup.controls[UASVolumeReservation.EFFECTIVE_TIME_BEGIN_FIELD_NAME].setValue(this.effective_time_begin);
    this.$formGroup.controls[UASVolumeReservation.EFFECTIVE_TIME_END_FIELD_NAME].setValue(this.effective_time_end);
    this.$formGroup.controls[UASVolumeReservation.ACTUAL_TIME_END_FIELD_NAME].setValue(this.actual_time_end);
    this.$formGroup.controls[UASVolumeReservation.REASON_FIELD_NAME].setValue(this.reason);
    this.$formGroup.controls[UASVolumeReservation.SUBMIT_TIME_FIELD_NAME].setValue(this.submit_time);
    this.$formGroup.controls[UASVolumeReservation.UPDATE_TIME_FIELD_NAME].setValue(this.update_time);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

import {OperationVolume} from './gen/utm';
import {LatLngPoint} from './WaypointParser';


export class OperationGeometry {
  private readonly volumes: OperationVolume[];
  private readonly points: LatLngPoint[];
  private readonly controllerLocation: LatLngPoint;
  private readonly takeOffLocation: LatLngPoint;
  constructor(volumes: OperationVolume[], points?: LatLngPoint[], controllerLocation?: LatLngPoint, takeOffLocation?: LatLngPoint) {
    this.volumes = volumes;
    this.points = points;
    this.controllerLocation = controllerLocation;
    this.takeOffLocation = takeOffLocation;

  }
  getVolumes(): OperationVolume[]{
    return this.volumes;
  }
  getPoints(): LatLngPoint[] {
    return this.points;
  }

  getControllerLocation() {
    return this.controllerLocation;
  }

  getTakeOffLocation() {
    return this.takeOffLocation;
  }
}

import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PlatformsComponent} from './platforms/platforms.component';
import {PlatformComponent} from './platform/platform.component';
import {UsersComponent} from './users/users.component';
import {NewUserComponent} from './new-user/new-user.component';
import {AuthGuard} from '../../auth/auth.guard';
import {PermissionGuardService} from '../../shared/permissions/PermissionGuardService';
import {NewPlatformComponent} from './new-platform/new-platform.component';
import {ViewUserComponent} from './view-user/view-user.component';
import {UpdateUserComponent} from './update-user/update-user.component';


const routes: Routes = [
  {
    path: 'platforms', children: [
      {path: '', component: PlatformsComponent},
      {path: ':platformId', component: PlatformComponent}
    ], canActivate: [AuthGuard, PermissionGuardService.forPermission('view_platform')]
  },
  {path: 'edit-platform', component: NewPlatformComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('submit_platform')]},
  {path: 'new-platform', component: NewPlatformComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('submit_platform')]},
  {path: 'users', component: UsersComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('view_user')]},
  {path: 'new-user', component: NewUserComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('submit_user')]},
  {path: 'edit-user', component: UpdateUserComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('submit_user')]},
  {path: 'view-user', component: ViewUserComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('view_user')]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RegistrationRoutingModule {
}

/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {maxValueValidator, minValueValidator} from './validators';
import {BaseModel} from './base-model';


export interface IUTMRestResponse {
  http_status_code: number;
  message: string;
}


export class UTMRestResponse extends BaseModel implements IUTMRestResponse {

  static HTTP_STATUS_CODE_FIELD_NAME = 'http_status_code';
  static MESSAGE_FIELD_NAME = 'message';

  http_status_code: number;
  message: string;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IUTMRestResponse>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IUTMRestResponse>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.http_status_code = this.getValue<number>(rawValues, UTMRestResponse.HTTP_STATUS_CODE_FIELD_NAME);
      this.message = this.getValue<string>(rawValues, UTMRestResponse.MESSAGE_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        http_status_code: new UntypedFormControl(this.http_status_code, [Validators.required, minValueValidator(100), maxValueValidator(599),]),
        message: new UntypedFormControl(this.message, [Validators.required, Validators.maxLength(500),]),
      });
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[UTMRestResponse.HTTP_STATUS_CODE_FIELD_NAME].setValue(this.http_status_code);
    this.$formGroup.controls[UTMRestResponse.MESSAGE_FIELD_NAME].setValue(this.message);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

import {measureDef, unitMap} from "./base";


const metric: unitMap = {
  'g-force': {
    name: {
      singular: 'g-force',
      plural: 'g-forces',
    },
    to_anchor: 9.80665,
  },
  'm/s2': {
    name: {
      singular: 'Metre per second squared',
      plural: 'Metres per second squared',
    },
    to_anchor: 1,
  },
};

const imperial: unitMap = {
  'ft/s2': {
    name: {
      singular: 'Feet per second squared',
      plural: 'Feet per second squared',
    },
    to_anchor: 1,
  },
};
export const AccelerationDef: measureDef = {
  type: 'acceleration',
  metric: metric,
  imperial: imperial,
  _anchors: {
    metric: {
      unit: 'm/s2',
      ratio: 3.28084,
    },
    imperial: {
      unit: 'ft/s2',
      ratio: 1 / 3.28084,
    }
  }
};

export const unicodeControlChars: {[key: number]: string} = {
  0: 'Null',
  1: 'Start of Heading',
  2: 'Start of Text',
  3: 'End of Text',
  4: 'End of Transmission',
  5: 'Enquiry',
  6: 'Acknowledge',
  7: 'Bell, Alert',
  8: 'Backspace',
  9: 'Horizontal Tab',
  10:	'Line Feed',
  11:	'Vertical Tab',
  12:	'Form Feed',
  13:	'Carriage Return',
  14:	'Shift Out',
  15:	'Shift In',
  16:	'Data Link Escape',
  17:	'Device Control One (XON)',
  18:	'Device Control Two',
  19:	'Device Control Three (XOFF)',
  20:	'Device Control Four',
  21:	'Negative Acknowledge',
  22:	'Synchronous Idle',
  23:	'End of Transmission Block',
  24:	'Cancel',
  25:	'End of medium',
  26:	'Substitute',
  27:	'Escape',
  28:	'File Separator',
  29:	'Group Separator',
  30:	'Record Separator',
  31:	'Unit Separator',
  32:	'Space',
  127: 'Delete'
};

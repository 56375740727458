import {Injectable, Type} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {ImportExportType, ImportService} from '../../../../../import-export/import-export.service';
import {ConfigComponent} from '../../../../../import-export/config.component';
import {GeoCircle, polygon_type, units_of_measure, vertical_reference} from '../../../../../model/gen/utm';
import {FeatureCollection, GeometryCollection} from 'geojson';
import {IUvrGeoSubmissionFG} from '../../../../../components/constraint/edit-constraint/edit-constraint.component';
import {
  IUvrVolumeSubmissionFG
} from '../../../../../components/constraint/constraint-geometry-editor/constraint-geometry-editor.component';
import {AltitudeRange} from '../../../../../model/gen/utm/altitude-range-model';
import {DateTime} from 'luxon';
import {TimeRange} from '../../../../../model/TimeRange';
import {UserSettings, UserSettingsService} from '../../../../user-settings.service';
import {Errorable, fail, some} from '../../../../../utils/optional';
import {isNil} from 'lodash';
import {
  GeojsonVolumeImportConfigComponent
} from '../../geojson-volume-import-config/geojson-volume-import-config.component';
import {
  BaseImportConfigType
} from '../../../../../import-export/components/base-import-config/base-import-config.component';

interface ConstraintProperties {
  beginTime: string;
  endTime: string;
  min_altitude: number;
  max_altitude: number;
  altitude_vertical_reference: string;
  altitude_units: string;
  radius: number;
  radiusUnits: string;
}

@Injectable({
  providedIn: 'root'
})
export class GeojsonConstraintVolumeImportService extends ImportService<Errorable<IUvrGeoSubmissionFG>> {
  private userSettings: UserSettings;
  private userSettingsSub: Subscription;
  constructor(private userSettingsService: UserSettingsService) {
    super();

    this.userSettingsSub = userSettingsService.getRawSettings().subscribe(rawSettings => {
      this.userSettings = rawSettings;
    });
  }

  doImport(config: any): Observable<Errorable<IUvrGeoSubmissionFG>> {
    return this.readFileJson(config.file).pipe(map((value: FeatureCollection) => {
      if (value.type !== 'FeatureCollection' || !value.features?.length) {
        return fail('Invalid GeoJSON');
      }

      let featuresError: string = null;
      const volumes: IUvrVolumeSubmissionFG[] = [];

      value.features.forEach(feature => {
        if (!featuresError) {
          if (feature.properties.id === 'constraint') {
            const geometryCollection = feature.geometry as GeometryCollection;
            const properties = feature.properties as ConstraintProperties;

            // Volume error handling
            if (!geometryCollection || !geometryCollection.geometries?.length) {
              featuresError = 'Invalid geometry';
              return;
            } else if (isNil(properties.min_altitude) || isNil(properties.max_altitude) || !properties.altitude_vertical_reference ||
              !properties.altitude_units) {
              featuresError = 'Invalid altitude';
              return;
            }

            volumes.push({
              geography: geometryCollection.geometries[0].type === 'Polygon' ? {
                type: polygon_type.Polygon,
                coordinates: geometryCollection.geometries[0].coordinates
              } : undefined,
              circle: geometryCollection.geometries[0].type === 'Point' ? new GeoCircle({
                latitude: geometryCollection.geometries[0].coordinates[1],
                longitude: geometryCollection.geometries[0].coordinates[0],
                radius: properties.radius,
                units: units_of_measure[properties.radiusUnits]
              }) : undefined,
              altitudeRange: new AltitudeRange({
                min_altitude: properties.min_altitude,
                max_altitude: properties.max_altitude,
                altitude_vertical_reference: vertical_reference[properties.altitude_vertical_reference],
                altitude_units: units_of_measure[properties.altitude_units]
              }),
              timeRange: new TimeRange(DateTime.fromISO(properties.beginTime), DateTime.fromISO(properties.endTime))
            } as IUvrVolumeSubmissionFG);
          }
        }
      });
      if (featuresError) {
        return fail(featuresError);
      } else if (!volumes.length) {
        return fail('Invalid geometry');
      }
      return some({volumes} as IUvrGeoSubmissionFG);
    }));
  }

  getFormatName(): string {
    return 'GeoJSON';
  }

  getType(): ImportExportType {
    return ImportExportType.CONSTRAINT;
  }

  getFileExtensions(): string[] {
    return ['.json', '.geojson'];
  }

  getConfigComponent(): Type<ConfigComponent> {
    return GeojsonVolumeImportConfigComponent;
  }

  getDefaultConfig(): any {
    return {file: null} as BaseImportConfigType;
  }
}

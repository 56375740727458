import {IUserMessageData, UserMessageData} from './UserMessageData';
import {OperatorIntentFailureReason, OperatorIntentType} from './enums';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DateTime} from 'luxon';

export interface IOperatorIntentResult extends IUserMessageData {
  intentId: string;
  intentResultTime: DateTime;
  intentType: OperatorIntentType;
  success: boolean;
  failureReason: OperatorIntentFailureReason;
  entityId: string;
  conflictingOperations: string[];
  conflictingConstraints: string[];
  entityName: string;
  errorMessage: string;
  previous_intent_id: string;
  previous_entity_id: string;
}

export class OperatorIntentResult extends UserMessageData implements IOperatorIntentResult {
  /* eslint-disable @typescript-eslint/naming-convention */
  static INTENT_ID_FIELD_NAME = 'intentId';
  static INTENT_RESULT_TIME_FIELD_NAME = 'intentResultTime';
  static INTENT_TYPE_FIELD_NAME = 'intentType';
  static SUCCESS_FIELD_NAME = 'success';
  static FAILURE_REASON_FIELD_NAME = 'failureReason';
  static ENTITY_ID_FIELD_NAME = 'entityId';
  static CONFLICTING_OPERATIONS_FIELD_NAME = 'conflictingOperations';
  static CONFLICTING_CONSTRAINTS_FIELD_NAME = 'conflictingConstraints';
  static ENTITY_NAME_FIELD_NAME = 'entityName';
  static ERROR_MESSAGE_FIELD_NAME = 'errorMessage';
  static PREVIOUS_INTENT_ID_FIELD_NAME = 'previous_intent_id';
  static PREVIOUS_ENTITY_ID  = 'previous_entity_id';

  /* eslint-enable @typescript-eslint/naming-convention */

  intentId: string;
  intentResultTime: DateTime;
  intentType: OperatorIntentType;
  success: boolean;
  failureReason: OperatorIntentFailureReason;
  entityId: string;
  conflictingOperations: string[];
  conflictingConstraints: string[];
  entityName: string;
  errorMessage: string;
  previous_intent_id: string;
  previous_entity_id: string;

  constructor(values?: Partial<IOperatorIntentResult>, useFormGroupValuesToModel = false) {
    super();

    this.conflictingOperations = [];
    this.conflictingConstraints = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IUserMessageData>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.intentId = this.getValue<string>(rawValues, OperatorIntentResult.INTENT_ID_FIELD_NAME);
      this.intentResultTime = this.getValue<DateTime>(rawValues, OperatorIntentResult.INTENT_RESULT_TIME_FIELD_NAME);
      this.intentType = this.getValue<OperatorIntentType>(rawValues, OperatorIntentResult.INTENT_TYPE_FIELD_NAME);
      this.success = this.getValue<boolean>(rawValues, OperatorIntentResult.SUCCESS_FIELD_NAME);
      this.failureReason = this.getValue<OperatorIntentFailureReason>(rawValues, OperatorIntentResult.FAILURE_REASON_FIELD_NAME);
      this.entityId = this.getValue<string>(rawValues, OperatorIntentResult.ENTITY_ID_FIELD_NAME);
      this.fillModelArray<string>(this, OperatorIntentResult.CONFLICTING_OPERATIONS_FIELD_NAME, rawValues.conflictingOperations, useFormGroupValuesToModel);
      this.fillModelArray<string>(this, OperatorIntentResult.CONFLICTING_CONSTRAINTS_FIELD_NAME, rawValues.conflictingConstraints, useFormGroupValuesToModel);
      this.entityName = this.getValue<string>(rawValues, OperatorIntentResult.ENTITY_NAME_FIELD_NAME);
      this.errorMessage = this.getValue<string>(rawValues, OperatorIntentResult.ERROR_MESSAGE_FIELD_NAME);
      this.previous_intent_id = this.getValue<string>(rawValues, OperatorIntentResult.PREVIOUS_INTENT_ID_FIELD_NAME);
      this.previous_entity_id = this.getValue<string>(rawValues, OperatorIntentResult.PREVIOUS_ENTITY_ID);

      super.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[OperatorIntentResult.INTENT_ID_FIELD_NAME].setValue(this.intentId);
    this.$formGroup.controls[OperatorIntentResult.INTENT_RESULT_TIME_FIELD_NAME].setValue(this.intentResultTime);
    this.$formGroup.controls[OperatorIntentResult.INTENT_TYPE_FIELD_NAME].setValue(this.intentType);
    this.$formGroup.controls[OperatorIntentResult.SUCCESS_FIELD_NAME].setValue(this.success);
    this.$formGroup.controls[OperatorIntentResult.FAILURE_REASON_FIELD_NAME].setValue(this.failureReason);
    this.$formGroup.controls[OperatorIntentResult.ENTITY_ID_FIELD_NAME].setValue(this.entityId);
    this.fillFormArray<string>(OperatorIntentResult.CONFLICTING_OPERATIONS_FIELD_NAME, this.conflictingOperations);
    this.fillFormArray<string>(OperatorIntentResult.CONFLICTING_CONSTRAINTS_FIELD_NAME, this.conflictingConstraints);
    this.$formGroup.controls[OperatorIntentResult.ENTITY_NAME_FIELD_NAME].setValue(this.entityName);
    this.$formGroup.controls[OperatorIntentResult.ERROR_MESSAGE_FIELD_NAME].setValue(this.errorMessage);
    this.$formGroup.controls[OperatorIntentResult.PREVIOUS_INTENT_ID_FIELD_NAME].setValue(this.previous_intent_id);
    this.$formGroup.controls[OperatorIntentResult.PREVIOUS_ENTITY_ID].setValue(this.previous_entity_id);

    super.setFormGroupValues();
  }

  protected getFormGroup(): UntypedFormGroup {
    /* eslint-disable no-underscore-dangle */
    if (!this._formGroup) {
      this._formGroup = super.getFormGroup();
      this._formGroup.addControl('intentId', new UntypedFormControl(this.intentId, [Validators.required]));
      this._formGroup.addControl('intentResultTime', new UntypedFormControl(this.intentResultTime, [Validators.required]));
      this._formGroup.addControl('intentType', new UntypedFormControl(this.intentType, [Validators.required]));
      this._formGroup.addControl('success', new UntypedFormControl(this.success, [Validators.required]));
      this._formGroup.addControl('failureReason', new UntypedFormControl(this.failureReason, [Validators.required]));
      this._formGroup.addControl('entityId', new UntypedFormControl(this.entityId, [Validators.required]));
      this._formGroup.addControl('conflictingOperations', new UntypedFormArray([], [Validators.required]));
      this._formGroup.addControl('conflictingConstraints', new UntypedFormArray([], [Validators.required]));
      this._formGroup.addControl('entityName', new UntypedFormControl(this.entityName, [Validators.required]));
      this._formGroup.addControl('errorMessage', new UntypedFormControl(this.errorMessage, [Validators.required]));
      this._formGroup.addControl('previous_intent_id', new UntypedFormControl(this.previous_intent_id, [Validators.required]));
      this._formGroup.addControl('previous_entity_id', new UntypedFormControl(this.previous_entity_id, [Validators.required]));

      this.fillFormArray<string>(OperatorIntentResult.CONFLICTING_OPERATIONS_FIELD_NAME, this.conflictingOperations);
      this.fillFormArray<string>(OperatorIntentResult.CONFLICTING_CONSTRAINTS_FIELD_NAME, this.conflictingConstraints);
    }

    return this._formGroup;
    /* eslint-enable no-underscore-dangle */
  }


}


import {OperationGeometry} from '../../../../../../model/operation.geometry';
import {LatLngPoint, WaypointCommand, WaypointCommandEnum, WaypointInfo} from '../../../../../../model/WaypointParser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OperationVolume, units_of_measure, vertical_reference} from '../../../../../../model/gen/utm';
import {
  FlightPlanComponent,
  FlightPlanComponentOptions,
  generateFlightPlanComponentVolumes, GeometricStuff, SegmentComponent
} from '../../../../../../utils/PointBufferer2';
import {UTMFlightPlan} from '../common';
import {Observable, of} from 'rxjs';


export class MissionPlannerFlightPlan implements UTMFlightPlan {

  readonly options = new FormGroup({
    horizontalBufferDistanceMeters: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_HORIZONTAL_BUFFER_DISTANCE_METERS, [
      Validators.required,
      Validators.min(1)
    ]),
    verticalBufferDistanceMeters: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_VERTICAL_BUFFER_DISTANCE_METERS, [
      Validators.required,
      Validators.min(1)
    ]),
    // bufferUnits: new FormControl<units_of_measure>(units_of_measure.M, [Validators.required]),
    steps: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_STEPS, [
      Validators.required,
      Validators.min(3)
    ])
  });

  constructor(private waypointInfo: WaypointInfo) {

  }


  static parseFromText(text: string): MissionPlannerFlightPlan {
    return new MissionPlannerFlightPlan(WaypointInfo.fromText(text));
  }
  private getFlightPlanComponents(): [FlightPlanComponent[], LatLngPoint] {
    const waypointInfo = this.waypointInfo;

    const volumes: FlightPlanComponent[] = [];
    // Group
    const home = waypointInfo.homeLocation;


    let lastPoint = home;
    let point: LatLngPoint;

    for (const cmd of waypointInfo.commands) {
      if (cmd.index === 0) {
        continue;
      }

      switch (cmd.command) {
        case WaypointCommandEnum.NAV_WAYPOINT:
          point = this.getCoordinate(cmd, home);

          volumes.push({
            kind: 'segment',
            index: cmd.index,
            a: lastPoint,
            b: point
          });
          lastPoint = point;
          break;
        case WaypointCommandEnum.NAV_LOITER_TO_ALT:
          point = this.getCoordinate(cmd, home);
          volumes.push({
            kind: 'segment',
            index: cmd.index,
            a: lastPoint,
            b: point
          });
          volumes.push({
            kind: 'loiter',
            index: cmd.index,
            center: point,
            radius: Math.abs(cmd.param2)
          });
          lastPoint = point;
          break;
        case WaypointCommandEnum.NAV_LOITER_UNLIM:
        case WaypointCommandEnum.NAV_LOITER_TIME:

          point = this.getCoordinate(cmd, home);

          volumes.push({
            kind: 'segment',
            index: cmd.index,
            a: lastPoint,
            b: point
          });
          volumes.push({
            kind: 'loiter',
            index: cmd.index,
            center: point,
            radius: 0.0
          });
          lastPoint = point;
          break;
        case WaypointCommandEnum.NAV_LOITER_TURNS:
          point = this.getCoordinate(cmd, home);
          volumes.push({
            kind: 'segment',
            index: cmd.index,
            a: lastPoint,
            b: point
          });
          volumes.push({
            kind: 'loiter',
            index: cmd.index,
            center: point,
            radius: Math.abs(cmd.param3)
          });
          lastPoint = point;
          break;
        case WaypointCommandEnum.NAV_RETURN_TO_LAUNCH:
          point = home;
          //this.getCoordinate(cmd, home);

          volumes.push({
            kind: 'segment',
            index: cmd.index,
            a: lastPoint,
            b: point
          });
          lastPoint = point;
          break;
        case WaypointCommandEnum.NAV_LAND:
          point = this.getCoordinate(cmd, home);
          point.lat = point.lat || lastPoint.lat;
          point.lng = point.lng || lastPoint.lng;
          point.alt = home.alt;

          volumes.push({
            kind: 'segment',
            index: cmd.index,
            a: lastPoint,
            b: point
          });
          lastPoint = point;
          break;
        case WaypointCommandEnum.NAV_TAKEOFF:
          point = this.getCoordinate(cmd, home);
          point.lat = point.lat || lastPoint.lat;
          point.lng = point.lng || lastPoint.lng;
          volumes.push({
            kind: 'segment',
            index: cmd.index,
            a: lastPoint,
            b: point
          });
          lastPoint = point;
          break;
        case WaypointCommandEnum.DO_JUMP:
          const i = volumes.findIndex((vol) => vol.index === (cmd.param1 + 1));
          if (i < 0) {
            console.error('Invalid jump detected');
            break;
          }
          const subVolumes = volumes.slice(i);

          const targetCmd = waypointInfo.commands.find((tmp) => tmp.index === cmd.param1);
          if (!targetCmd) {
            console.error('Invalid jump detected');
            break;
          }
          const tmp = this.getCoordinate(targetCmd, home);
          const segment: SegmentComponent = {
            kind: 'segment',
            index: cmd.index,
            a: lastPoint,
            b: tmp
          };


          volumes.push({
            kind: 'group',
            index: cmd.index,
            items: [...volumes.slice(i), segment]
          });
          //
          // volumes.push({
          //   kind: 'group',
          //   index: cmd.index,
          //   items: [...volumes.slice(i),
          // });
          break;
        case WaypointCommandEnum.NAV_SPLINE_WAYPOINT:


          const last = volumes?.[volumes.length - 1];
          let splineComponent = null;
          if(last?.kind !== 'spline'){
            splineComponent = {
              kind: 'spline',
              index: cmd.index,
              points: []
            };
            volumes.push(splineComponent);
          }
          else {
            splineComponent = last;
          }
          point = this.getCoordinate(cmd, home);
          point.lat = point.lat || lastPoint.lat;
          point.lng = point.lng || lastPoint.lng;
          splineComponent.points.push(point);
          break;

        default:
          console.error('Unknown command: ' + cmd.command);
          continue;
      }
    }
    return [volumes, waypointInfo.homeLocation];
  }


  toOperationGeometry(): Observable<OperationGeometry> {

    const [volumes, home] = this.getFlightPlanComponents();
    const rawOptions = this.options.getRawValue();
    const options: FlightPlanComponentOptions = {
      steps: rawOptions.steps,
      bufferDistance: rawOptions.horizontalBufferDistanceMeters,
      bufferUnits: units_of_measure.M,
      endTime: undefined,
      startTime: undefined,
      verticalBufferMeters: rawOptions.verticalBufferDistanceMeters,
      verticalReference: vertical_reference.MSL,
      verticalUnits: units_of_measure.M
    };

    let results: OperationVolume[] = [];
    for (const segment of volumes) {
      results = results.concat(generateFlightPlanComponentVolumes(segment, options));
    }


    return of(new OperationGeometry(results, undefined, undefined, home));
  }

  private getCoordinate(cmd: WaypointCommand, home: LatLngPoint): LatLngPoint {
    if (cmd.coordFrame === 0){
      return {
        lat: cmd.lat,
        lng: cmd.lng,
        alt: cmd.alt
      };
    }
    if (cmd.coordFrame === 3) {
      return {
        lat: cmd.lat,
        lng: cmd.lng,
        alt: home.alt + cmd.alt
      };
    }
    if (cmd.coordFrame === 5){
      return {
        lat: cmd.lat / 1E7,
        lng: cmd.lng / 1E7,
        alt: cmd.alt
      };
    }

    if (cmd.coordFrame === 6){
      return {
        lat: cmd.lat / 1E7,
        lng: cmd.lng / 1E7,
        alt: home.alt + cmd.alt
      };
    }
    console.log('Unknown coordinate frame type');
    return {
      lat: cmd.lat,
      lng: cmd.lng,
      alt: cmd.alt
    };



  }
}

export type MissionPlannerFlightPlanFormGroup = Pick<MissionPlannerFlightPlan, 'options'>['options'];

import {Injectable} from '@angular/core';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {User} from '../model/User';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  readonly currentUser: Observable<User>;

  public constructor(private authService: AuthService) {
    this.currentUser = this.authService.watchCurrentUser();
  }

  isAdmin(): Observable<boolean> {
    return this.currentUser.pipe(map(u => {
      if (u) {
        return u.isAdmin();
      } else {
        return false;
      }
    }));
  }
}

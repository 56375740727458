import {Component, forwardRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR, ValidatorFn, Validators} from '@angular/forms';
import {SelectOption} from '../../select-option';
import {source, units_of_measure, vertical_reference} from '../../model/gen/utm';
import {AltitudeRange} from '../../model/gen/utm/altitude-range-model';
import {altitudeRangeAboveValidator} from '../../utils/Validators';
import {Subscription} from 'rxjs';

export interface HeightUnitsPair {
  height: number;
  units: units_of_measure;
}

@Component({
  selector: 'app-altitude-range-selector',
  templateUrl: './altitude-range-selector.component.html',
  styleUrls: ['./altitude-range-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AltitudeRangeSelectorComponent),
    }
  ]
})
export class AltitudeRangeSelectorComponent implements OnChanges, ControlValueAccessor, OnDestroy {
  @Input() editable = true;
  @Input() value: AltitudeRange;
  @Input() simpleRange = false;
  @Input() fgValidators: ValidatorFn[] = [];
  @Input() valueValidators: ValidatorFn[] = [];

  lastValue: AltitudeRange | null = undefined;
  fg: FormGroup;
  verticalReferenceIter: SelectOption[] = (() => {
    const ret = [];
    for (const option of Object.keys(vertical_reference)) {
      ret.push({label: option, value: vertical_reference[option]});
    }
    return ret;
  })();
  unitsOfMeasureIter: SelectOption[] = (() => {
    const ret = [];
    for (const option of Object.keys(units_of_measure)) {
      ret.push({label: option, value: units_of_measure[option]});
    }
    return ret;
  })();

  private onTouched: () => void;
  private onChange: (AltitudeRange) => void;
  private altitudeRange: AltitudeRange = new AltitudeRange();
  private changeSubscription: Subscription;

  constructor() {
    const validators = this.getFGValidators();
    this.fg = this.altitudeRange.$formGroup;
    this.fg.setValidators(validators);

    this.changeSubscription = this.fg.valueChanges.subscribe(() => this.handleChange());
  }

  @HostListener('touchend')
  touched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fgValidators) {
      const fgValidators = this.getFGValidators();
      this.fg.setValidators(fgValidators);
    }
    if (changes.valueValidators) {
      const valueValidators = this.getValueValidators();
      this.fg.controls.min_altitude.setValidators(valueValidators);
      this.fg.controls.max_altitude.setValidators(valueValidators);
    }
    if (changes.value) {
      this.writeValue(this.value);
    }
  }

  registerOnChange(fn: (AltitudeRange) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.editable = !isDisabled;
    if (!!isDisabled) {
      this.fg.disable();
    } else {
      this.fg.enable();
    }
  }

  writeValue(obj: AltitudeRange): void {
    if (!obj) {
      this.fg.reset({
        min_altitude: null,
        max_altitude: null,
        altitude_vertical_reference: null,
        altitude_units: null,
        source: null,
      });
    } else {
      this.fg.setValue({
        min_altitude: obj.min_altitude,
        max_altitude: obj.max_altitude,
        altitude_vertical_reference: obj.altitude_vertical_reference,
        altitude_units: obj.altitude_units,
        source: obj.source || source.ONBOARD_SENSOR,
      });
    }
    this.fg.updateValueAndValidity();
  }

  handleChange(): void {
    if (this.fg.disabled || this.fg.pending){
      return;
    }

    let value: AltitudeRange | null = null;
    if (this.fg.valid) {
      value = new AltitudeRange(this.fg.getRawValue());
    }
    if (this.lastValue === value || (value && AltitudeRange.equals(value, this.lastValue))) {
      return;
    }
    this.lastValue = value;
    this.touched();

    if (this.onChange) {
      this.onChange(value);
    }
  }

  ngOnDestroy() {
    this.changeSubscription?.unsubscribe();
  }

  private getFGValidators(): ValidatorFn[] {
    const ret = [Validators.required, altitudeRangeAboveValidator];
    if (this.fgValidators.length) {
      ret.push(...this.fgValidators);
    }
    return ret;
  }

  private getValueValidators(): ValidatorFn[] {
    const ret = [Validators.required];
    if (this.valueValidators.length) {
      ret.push(...this.valueValidators);
    }
    return ret;
  }
}

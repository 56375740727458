<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="datagrid-compact">
  <clr-dg-action-bar>
    <div class="btn-group">
      <button type="button" class="btn btn-sm btn-secondary" (click)="refresh()">
        Refresh
      </button>
    </div>
  </clr-dg-action-bar>
  <clr-dg-column>Operation</clr-dg-column>
  <clr-dg-column>Request Time</clr-dg-column>
  <clr-dg-column>
    <ng-container *clrDgHideableColumn="{ hidden: true }">
      Request Expiration
    </ng-container>
  </clr-dg-column>
  <clr-dg-column>Time of Approval</clr-dg-column>
  <clr-dg-column>Approval Status</clr-dg-column>
  <clr-dg-column>Approved By</clr-dg-column>
  <clr-dg-column class="col-approval">Approval Request Details</clr-dg-column>

  <clr-dg-placeholder>No results found</clr-dg-placeholder>

  <clr-dg-row *ngFor="let request of approvalRequests" [clrDgItem]="request" class="datagrid-body-row">
    <clr-dg-cell>{{ request.operation.flight_number }}</clr-dg-cell>
    <clr-dg-cell>{{ request.timeOfRequest }}</clr-dg-cell>
    <clr-dg-cell>{{ request.expiresAt }}</clr-dg-cell>
    <clr-dg-cell>{{ request.timeOfApproval || 'N/A' }}</clr-dg-cell>
    <clr-dg-cell>{{ request.approvalStatus }}</clr-dg-cell>
    <clr-dg-cell class="contact-username">{{ request.approvedByUserName || 'N/A' }}
      <a *ngIf="request.approvedByUserName && request.approvedByUserId" (click)="viewUser(request.approvedByUserId)">
        <cds-icon shape="id-badge"></cds-icon>
      </a>
    </clr-dg-cell>
    <clr-dg-cell>
      <button type="button" class="btn btn-sm btn-secondary" (click)="viewApprovalDetails(request)">View Request Details
      </button>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize"
                       [clrDgTotalItems]="totalItems"></clr-dg-pagination>
    {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} of {{ totalItems }}
    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Approval requests per page</clr-dg-page-size>
  </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="showUserDetails" [clrModalSize]="'xl'" *ngIf="showUserDetails">
  <div class="modal-title">User Details For: {{ selectedUser?.contact?.getName() }}</div>
  <div class="modal-body">
    <app-user-summary *ngIf="selectedUser" [user]="selectedUser"></app-user-summary>
    <clr-alerts *ngIf="!selectedUser">
      <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
        <clr-alert-item>
          <span>Error: {{ this.getUserErrorMsg }}</span>
        </clr-alert-item>
      </clr-alert>
    </clr-alerts>
  </div>
</clr-modal>

<!-- Approval Details Modal -->
<clr-modal [(clrModalOpen)]="showApprovalDetails" [clrModalSize]="'xl'" *ngIf="showApprovalDetails">
  <div class="modal-title">Approval Request Details</div>
  <div class="modal-body" style="min-height: 500px">
    <div class="cesium-wrapper">
      <ax-cesium-container [timeline]="false"
                           [navigationInstructionsInitiallyVisible]="false"
                           [toolbarZIndex]="'1000'"
                           [projectionPicker]="true"
                           [sceneModePicker]="false">
        <app-operation-volume-drawer [operation]="selectedApproval.operation"></app-operation-volume-drawer>
        <ng-container *ngFor="let constraintId of selectedApproval.constraintIds">
          <app-constraint-volume-drawer *ngIf="constraintCache[constraintId]"
                                        [volumes]="constraintCache[constraintId].volumes">
          </app-constraint-volume-drawer>
        </ng-container>
      </ax-cesium-container>
    </div>
    <h4>Proposed Operation</h4>
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>Operation</clr-dg-column>
      <clr-dg-row>
        <clr-dg-cell>{{ selectedApproval.operation.flight_number }}</clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          <app-ax-operation-ext-summary [operation]="selectedApproval.operation"
                                        style="width:100%;"></app-ax-operation-ext-summary>
        </clr-dg-row-detail>
      </clr-dg-row>
    </clr-datagrid>
    <h4>Conflicting Approval Areas</h4>
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>Approval Area</clr-dg-column>
      <clr-dg-column></clr-dg-column>
      <clr-dg-row *ngFor="let constraintId of selectedApproval.constraintIds"
                  [clrDgItem]="selectedApproval.constraintIds">
        <clr-dg-cell>{{ constraintCache[constraintId]?.reason || '' }}</clr-dg-cell>
        <clr-dg-cell>
          <a target="_blank" [routerLink]="'/fuss/constraint/view-constraint'"
             [queryParams]="{constraintId: constraintId}">
            <cds-icon shape="link"></cds-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          <app-constraint-ext-summary *ngIf="constraintCache[constraintId]" [constraint]="constraintCache[constraintId]"
                                      style="width:100%;"></app-constraint-ext-summary>
        </clr-dg-row-detail>
      </clr-dg-row>
    </clr-datagrid>
  </div>
</clr-modal>

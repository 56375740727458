import {Directive, ViewContainerRef} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[importExportConfigHost]'
})
export class ImportExportConfigHostDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}

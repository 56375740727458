import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OrganizationMetadata} from "../model/OrganizationMetadata";



@Injectable({
  providedIn: 'root'
})
export abstract class OrganizationService {

  constructor() {
    // Method not implemented
  }

  abstract organizationMetadata$: Observable<OrganizationMetadata[] | null>;
}

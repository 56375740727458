import {Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR, UntypedFormBuilder, Validators} from '@angular/forms';
import {IControlValueAccessor, IFormBuilder, IFormGroup} from '@rxweb/types';
import {Subscription} from 'rxjs';
import {IPlatformSearch, PlatformSearch} from '../../../../shared/services/platform.service';
import {SelectOption} from '../../../../shared/select-option';

@Component({
  selector: 'app-platform-search-options-config',
  templateUrl: './platform-search-options-config.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PlatformSearchOptionsConfigComponent),
    }
  ]
})
export class PlatformSearchOptionsConfigComponent implements IControlValueAccessor<IPlatformSearch>, OnDestroy, OnChanges {
  @Output() onSearchConfig: EventEmitter<IPlatformSearch> = new EventEmitter<IPlatformSearch>();
  @Input() searchConfig: IPlatformSearch;
  @Input() showUpdateButton = false;


  fg: IFormGroup<IPlatformSearch>;
  formBuilder: IFormBuilder;
  hasStartTime = false;
  sortOptions: SelectOption[] = [
    {value: 'name', label: 'Name'},
    {value: 'organization', label: 'Organization'},
    {value: 'make', label: 'Make'},
    {value: 'model', label: 'Model'},
    {value: 'serialNumber', label: 'Serial Number'},
    {value: 'faaRegistrationNumber', label: 'FAA Registration Number'},
    {value: 'tailNumber', label: 'Tail Number'},
  ];
  private change: (value: IPlatformSearch) => void;
  private fgSub: Subscription;

  constructor(formBuilder: UntypedFormBuilder) {
    this.formBuilder = formBuilder;
    this.fg = this.formBuilder.group<IPlatformSearch>({
      archived: [false, []],
      name: [null, []],
      organization: [null, []],
      make: [null, []],
      model: [null, []],
      serialNumber: [null, []],
      faaRegistrationNumber: [null, []],
      tailNumber: [null, []],
      sort: [[], []],
      sortIncreasing: [true, [Validators.required]],
    });

    this.fg.patchValue(PlatformSearch.defaultSearch());
    this.fgSub = this.fg.valueChanges.subscribe(() => this.emitUpdate());
  }

  private static toPlatformSearch(raw: IPlatformSearch): PlatformSearch {
    return new PlatformSearch({
        archived: raw.archived,
        name: raw.name,
        organization: raw.organization,
        make: raw.make,
        model: raw.model,
        serialNumber: raw.serialNumber,
        faaRegistrationNumber: raw.faaRegistrationNumber,
        tailNumber: raw.tailNumber,
        sort: raw.sort,
        sortIncreasing: raw.sortIncreasing,
      }
    );
  }

  private static toIPlatformSearchConfig(raw: PlatformSearch): IPlatformSearch {
    return {
      name: raw.name,
      organization: raw.organization,
      make: raw.make,
      model: raw.model,
      serialNumber: raw.serialNumber,
      faaRegistrationNumber: raw.faaRegistrationNumber,
      tailNumber: raw.tailNumber,
      sort: raw.sort,
      sortIncreasing: raw.sortIncreasing,
    };
  }

  ngOnDestroy(): void {
    if (this.fgSub && !this.fgSub.closed) {
      this.fgSub.unsubscribe();
    }
  }

  registerOnChange(fn: (value: PlatformSearch) => void): void {
    this.change = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.searchConfig) {
      this.writeValue(this.searchConfig);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.fg.disable();
    } else {
      this.fg.enable();
    }
  }

  writeValue(obj: IPlatformSearch): void {
    this.fg.setValue(obj, {emitEvent: false});
  }

  emitUpdate() {
    const update = this.getConfig();
    if (this.change) {
      this.change(update);
    }
    this.onSearchConfig.emit(update);
  }

  getConfig(): IPlatformSearch {
    return this.fg.getRawValue();
  }
}

import { Component, computed, inject, input, Type } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  ClarityModule,
  ClrAccordionModule,
  ClrFormsModule,
  ClrInputModule,
} from "@clr/angular";
import { BufferDistanceInput } from "./common";
import { MeasurementSystemType } from "../../../../../model/MeasurementSystem";
import { UserSettingsService } from "../../../../user-settings.service";

const formGroup = new FormGroup({
  horizontalBufferDistanceMeters: new FormControl(0),
  verticalBufferDistanceMeters: new FormControl(0),
  steps: new FormControl(0),
});

export type BufferStepCustomizerFormGroup = typeof formGroup;

@Component({
  selector: "app-flight-plan-buffer-step-customizer",
  styles: `
    .clr-accordion-custom {
      margin-top: 1rem;
    }
  `,
  template: `
    @if (formGroup()) {
      <form [formGroup]="formGroup()" clrForm clrLayout="vertical">
        <app-buffer-distance-input [formControl]="horizontalBufferDistanceControl$()"/>
        <app-buffer-distance-input [horizontal]="false" [formControl]="verticalBufferDistanceControl$()"/>
        <clr-accordion class="clr-accordion-custom">
          <clr-accordion-panel>
            <clr-accordion-title>Advanced Options</clr-accordion-title>
            <clr-accordion-content>
              <clr-range-container [clrRangeHasProgress]="true">
                <label>Buffer Smoothness</label>
                <input
                  [min]="3"
                  [max]="6"
                  type="range"
                  clrRange
                  name="two"
                  [formControl]="stepsControl$()"
                />
                <clr-control-helper>Degree of smoothness to use when creating the buffer. The
                  higher the smoothness degree, the smoother the boundaries of
                  the volumes will be, but the more computationally intensive
                  processing will be.
                </clr-control-helper>
                <clr-control-helper>Current Degree({{ stepsControl$().value }})</clr-control-helper>
                <clr-control-error *clrIfError="'required'">This field is required</clr-control-error>
                <clr-control-error *clrIfError="'min'">Value must be greater than or equal to 3</clr-control-error>
              </clr-range-container>
            </clr-accordion-content>
          </clr-accordion-panel>
        </clr-accordion>
      </form>
    }
  `,

  imports: [
    ReactiveFormsModule as Type<any>,
    ClrFormsModule as Type<any>,
    ClrInputModule as Type<any>,
    ClarityModule as Type<any>,
    ReactiveFormsModule as Type<any>,
    BufferDistanceInput as Type<any>,
    ClrAccordionModule,
  ],
  standalone: true,
})
export class BufferStepCustomizerComponent {
  protected readonly measurementSystemType = MeasurementSystemType;
  userSettingsService = inject(UserSettingsService);

  formGroup = input.required<BufferStepCustomizerFormGroup>();
  horizontalBufferDistanceControl$ = computed(
    () => this.formGroup().controls.horizontalBufferDistanceMeters,
  );
  verticalBufferDistanceControl$ = computed(
    () => this.formGroup().controls.verticalBufferDistanceMeters,
  );
  stepsControl$ = computed(() => this.formGroup().controls.steps);
}

import {Component, computed, inject, input, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {SelectOption} from '../../select-option';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {User} from '../../model/User';
import {AdminUserService} from '../../services/admin/admin-user.service';
import {UserSubmission} from '../../model/UserSubmission';
import {
  condVal,
  forbiddenPatternRegexes,
  invalidCharactersValidator,
  passwordMatchValidator
} from '../../utils/Validators';
import {UserResponse} from '../../model/UserResponse';
import {Contact} from '../../model/Contact';
import {ContactService} from '../../services/contact.service';
import {Subscription} from 'rxjs';
import {UserSettingsService} from '../../services/user-settings.service';
import {HttpErrorResponse} from '@angular/common/http';
import {ISubmissionStatus, SubmissionMode, SubmissionState} from '../../model/SubmissionStatus';
import {ContactResponse} from '../../model/ContactResponse';
import {OrganizationService} from "../../services/organization.service";
import {toSignal} from "@angular/core/rxjs-interop";
import {OrganizationMetadata} from "../../model/OrganizationMetadata";

const DEFAULT_ROLES: SelectOption[] = [
  // {value: 'op', name: 'Operator'},
  {value: 'pilot', label: 'Pilot'},
  // {value: 'pubsafety', name: 'Public Safety'},
  // {value: 'safetycoord', name: 'Safety Coordinater'},
  // {value: 'fleetman', name: 'Fleet Manager'},
  // {value: 'conman', name: 'Constraint Manager'},
  // {value: 'isr', name: 'Intelligence, Surveillance, and Reconnaissance'},
  // {value: 'com', name: 'Commander'},
  {value: 'mcom', label: 'Mission Commander'},
  {value: 'atc', label: 'Air Traffic Controller'},
  {value: 'admin', label: 'Administrator'},
  {value: 'dev', label: 'Developer', hidden: true},
  //   {value: 'dev', name: 'Developer'},
];

const DEFAULT_ORGANIZATIONS: SelectOption[] = [
  {value: 'af', label: 'Air Force'},
  {value: 'army', label: 'Army'},
  {value: 'axe', label: 'AX Enterprize'},
  {value: 'xyz', label: 'XYZ Organization'},
  {value: 'dhs', label: 'Department of Homeland Security'},
  {value: 'dod', label: 'Department of Defense'},
  {value: 'fbi', label: 'Federal Bureau of Investigations'},
  {value: 'doi', label: 'Department of the Interior'},
];

const DEFAULT_ORGANIZATION_METADATA: OrganizationMetadata[] = [
  {id: 'af', name: 'Air Force'},
  {id: 'army', name: 'Army'},
  {id: 'axe', name: 'AX Enterprize'},
  {id: 'xyz', name: 'XYZ Organization'},
  {id: 'dhs', name: 'Department of Homeland Security'},
  {id: 'dod', name: 'Department of Defense'},
  {id: 'fbi', name: 'Federal Bureau of Investigations'},
  {id: 'doi', name: 'Department of the Interior'},
]


interface RawValues {
  // username: string;
  email: string;
  password: string;
  roles: string[];
  org: string;
  contact: Contact;
}

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit, OnChanges, OnDestroy {
  @Input() full = false;
  @Input() user: User;
  submissionMode$ = input.required<SubmissionMode>();

  availableRoles: SelectOption[] = DEFAULT_ROLES;
  formGroup: UntypedFormGroup;
  submissionStateEnum = SubmissionState;
  submissionModeEnum = SubmissionMode;
  submissionStatus: ISubmissionStatus;
  displaySubmissionProgressModal = false;

  organizations$ = computed(() =>{
    const metadata = this.organizationMetadata$() || [];
    return metadata.map(org => ({value: org.id, label: org.name}));
  });

  private clearForm = false;
  private contactFetchSub: Subscription;
  private clearSubmissionSub: Subscription;
  private passwordMatchSub: Subscription;
  private organizationService = inject(OrganizationService);

  private organizationMetadata$ = toSignal(this.organizationService.organizationMetadata$, {
    initialValue: DEFAULT_ORGANIZATION_METADATA,
  });

  constructor(private userService: AdminUserService, private userSettingsService: UserSettingsService, private contactService: ContactService) {
    this.clearSubmissionSub = this.userSettingsService.getClearFormAfterSubmission().subscribe(clearFormSettings => {
      this.clearForm = clearFormSettings.userSubmission;
    });
  }

  ngOnDestroy(): void {
    this.contactFetchSub?.unsubscribe();
    this.clearSubmissionSub?.unsubscribe();
    this.passwordMatchSub?.unsubscribe();
  }

  ngOnInit(): void {
    const passwordField = new UntypedFormControl(null, [invalidCharactersValidator(forbiddenPatternRegexes.password),
        Validators.minLength(8), Validators.maxLength(64),
        condVal(() => this.submissionMode$() === SubmissionMode.CREATE, Validators.required)]);
    this.formGroup = new UntypedFormGroup({
      email: new UntypedFormControl(null, [Validators.required, Validators.email,
        Validators.minLength(5), Validators.maxLength(50)]),
      password: passwordField,
      passwordConfirmation: new UntypedFormControl(null, [invalidCharactersValidator(forbiddenPatternRegexes.password),
        Validators.minLength(8), Validators.maxLength(64),
        condVal(() => !!passwordField.value, Validators.required)]),
      roles: new UntypedFormControl([], [Validators.required]),
      org: new UntypedFormControl({value: null, disabled: this.submissionMode$() === SubmissionMode.EDIT},
        [condVal(() => this.submissionMode$() === SubmissionMode.CREATE, Validators.required)]),
      contact: new UntypedFormControl(null, [Validators.required])
    }, [passwordMatchValidator]);
    if (this.user) {
      this.setValues(this.user);
    }

    this.passwordMatchSub = this.formGroup.controls.password.valueChanges.subscribe(() => {
      this.formGroup.controls.passwordConfirmation.updateValueAndValidity();
    });
  }

  setValues(values: User) {
    const rawValues = values;
    this.formGroup.controls.email.setValue(rawValues.email);
    this.formGroup.controls.password.setValue(null); // rawValues.password);
    this.formGroup.controls.passwordConfirmation.setValue(null);
    this.formGroup.controls.roles.setValue(rawValues.roles);
    this.formGroup.controls.org.setValue(rawValues.org);
    this.contactFetchSub?.unsubscribe();
    this.contactFetchSub = this.contactService.getContactByUserID(rawValues.uid).subscribe((res: ContactResponse) => {
      if (res.contact) {
        this.formGroup.controls.contact.setValue(res.contact);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && changes.user.currentValue) {
      this.setValues((changes as any).user.currentValue as User);
    }
  }

  submitUser() {
    const rawValues: RawValues = this.formGroup.getRawValue();
    const contact = rawValues.contact.clone();
    contact.emails = [rawValues.email, ...contact.emails];
    const submission: UserSubmission = {
      email: rawValues.email,
      password: rawValues.password,
      roles: rawValues.roles,
      org: rawValues.org,
      contact
    };
    this.submissionStatus = {
      state: SubmissionState.PROCESSING,
      modalHeading: (this.submissionMode$() === SubmissionMode.CREATE ? 'Submitting ' : 'Updating ') + 'User',
      summary: (this.submissionMode$() === SubmissionMode.CREATE ? 'Submitting ' : 'Updating ') + 'User...'
    };
    this.displaySubmissionProgressModal = true;
    if (this.submissionMode$() === SubmissionMode.CREATE) {
      this.userService.createUser(submission).subscribe((res) => {
        this.handleResult(res);
        if (res.success && this.clearForm) {
          this.resetForm();
        }
      }, (error: HttpErrorResponse) => {
        this.handleResult(error);
      });
    } else if (this.submissionMode$() === SubmissionMode.EDIT) {
      this.userService.updateUser(this.user.uid, submission).subscribe((res) => {
        this.handleResult(res);
      }, (error: HttpErrorResponse) => {
        this.handleResult(error);
      });
    }
  }

  resetForm() {
    this.formGroup.reset({
      email: null,
      password: null,
      passwordConfirmation: null,
      roles: [],
      org: {value: null, disabled: this.submissionMode$() === SubmissionMode.EDIT},
      contact: null
    });
    this.formGroup.markAsUntouched({onlySelf: true});
    this.formGroup.markAsPristine({onlySelf: true});
  }

  private handleResult(res: UserResponse | HttpErrorResponse) {
    const statusPastTense = this.submissionMode$() === SubmissionMode.CREATE ? 'Created' : 'Updated';
    const statusPresentTense = this.submissionMode$() === SubmissionMode.CREATE ? 'Creation' : 'Update';

    if (res instanceof UserResponse) {
      if (res.success) {
        this.submissionStatus = {
          state: SubmissionState.SUCCESS,
          modalHeading: `User ${statusPastTense}`,
          summary: `User has been ${statusPastTense.toLowerCase()} successfully`
        };
      } else {
        this.submissionStatus = {
          state: SubmissionState.ERROR,
          modalHeading: `User ${statusPresentTense} Failed`,
          summary: res.message || `Unknown error during user ${statusPresentTense.toLowerCase()}`
        };
      }
    } else if (res instanceof HttpErrorResponse) {
      this.submissionStatus = {
        state: SubmissionState.ERROR,
        modalHeading: `User ${statusPresentTense} Failed`,
        summary: res?.error?.message || `Unknown error during user ${statusPresentTense.toLowerCase()}`
      };
    } else {
      this.submissionStatus = {
        state: SubmissionState.NONE,
        modalHeading: `User ${statusPresentTense} Status Unknown`,
        summary: 'No information available'
      };
    }
  }
}

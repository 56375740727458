<h1 cds-text="title">View Platforms</h1>
@if (deviceSize$() !== 'sm') {
  <button class="btn btn-primary" (click)="resetFilters()">Reset Filters</button>
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="datagrid-compact">
    <clr-dg-column [clrDgField]="'name'" [clrDgSortBy]="'name'" [clrDgSortOrder]="sortDirections['name']">Name</clr-dg-column>
    <clr-dg-column [clrDgField]="'aircraftSpecs.make'" [clrDgSortBy]="'aircraftSpecs.make'" [clrDgSortOrder]="sortDirections['aircraftSpecs.make']">Make</clr-dg-column>
    <clr-dg-column [clrDgField]="'aircraftSpecs.model'" [clrDgSortBy]="'aircraftSpecs.model'" [clrDgSortOrder]="sortDirections['aircraftSpecs.model']">Model</clr-dg-column>
    <clr-dg-column [clrDgField]="'aircraftSpecs.faaRegistrationNumber'" [clrDgSortBy]="'aircraftSpecs.faaRegistrationNumber'"  [clrDgSortOrder]="sortDirections['aircraftSpecs.faaRegistrationNumber']">
      FAA Registration Number</clr-dg-column>
  <!--  TODO: Discuss how to combine owner column data (org vs. contact) and sort server-side -->
    <clr-dg-column [clrDgField]="'organization'" [clrDgSortBy]="'organization'" [clrDgSortOrder]="sortDirections['organization']">Owner</clr-dg-column>
    <clr-dg-column class="datagrid-fixed-column datagrid-expandable-caret"></clr-dg-column>

    <clr-dg-placeholder>No results found. Please try adjusting the filters in the table column headers.</clr-dg-placeholder>

    <clr-dg-row *ngFor="let platform of platforms" [clrDgItem]="platform">
      <clr-dg-cell>{{platform.name}}</clr-dg-cell> <!--Name-->
      <clr-dg-cell>{{platform.aircraftSpecs.make}}</clr-dg-cell> <!--Make-->
      <clr-dg-cell>{{platform.aircraftSpecs.model}}</clr-dg-cell> <!--Model-->
      <clr-dg-cell>{{platform.faaRegistrationNumber}}</clr-dg-cell> <!--FAA Registration Number-->
      <clr-dg-cell>{{platform.getPrettyOrganizationName()}}</clr-dg-cell> <!--Owner-->
      <clr-dg-cell class="datagrid-fixed-column datagrid-expandable-caret">
        <a [routerLink]="[platform.uvin]">
          <cds-icon shape="link"></cds-icon>
        </a>
      </clr-dg-cell>
      <clr-dg-action-overflow>
        <button class="action-item" (click)="viewPlatform(platform)">View</button>
        <button class="action-item" *hasPermission="'submit_platform'" (click)="editPlatform(platform)">Edit</button>
        <button class="action-item" *hasPermission="'submit_platform'" (click)="clonePlatform(platform)">Clone</button>
        <button class="action-item" *hasPermission="'submit_platform'"
                (click)="toggleArchivePlatform(platform, platform.archived)">{{platform.archived ? 'Unarchive' : 'Archive'}}</button>
      </clr-dg-action-overflow>

  <!--    Uncomment this code to enable expandable details section
      <clr-dg-row-detail *clrIfExpanded>
        <app-view-platform [platform]="platform"></app-view-platform>
      </clr-dg-row-detail>-->
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize" [clrDgPage]="currentPage"
                         [clrDgTotalItems]="totalItems"></clr-dg-pagination>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}} |
      <clr-dg-page-size [clrPageSizeOptions]="pageSizeOptions">Platforms per page</clr-dg-page-size>
    </clr-dg-footer>
  </clr-datagrid>
} @else {
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <clr-dg-column [clrDgField]="'name'" [clrDgSortBy]="'name'" [clrDgSortOrder]="sortDirections['name']">Name</clr-dg-column>

    <clr-dg-placeholder>No results found. Please try adjusting the filters in the table column headers.</clr-dg-placeholder>

    <clr-dg-row *ngFor="let platform of platforms" [clrDgItem]="platform">
      <clr-dg-cell>{{platform.name}}</clr-dg-cell> <!--Name-->
      <clr-dg-action-overflow>
        <button class="action-item" (click)="viewPlatform(platform)">View</button>
        <button class="action-item" *hasPermission="'submit_platform'" (click)="editPlatform(platform)">Edit</button>
        <button class="action-item" *hasPermission="'submit_platform'" (click)="clonePlatform(platform)">Clone</button>
        <button class="action-item" *hasPermission="'submit_platform'"
                (click)="toggleArchivePlatform(platform, platform.archived)">{{platform.archived ? 'Unarchive' : 'Archive'}}</button>
      </clr-dg-action-overflow>

      <!--    Uncomment this code to enable expandable details section
          <clr-dg-row-detail *clrIfExpanded>
            <app-view-platform [platform]="platform"></app-view-platform>
          </clr-dg-row-detail>-->
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize" [clrDgPage]="currentPage"
                         [clrDgTotalItems]="totalItems"></clr-dg-pagination>
      <span class="datagrid-footer-description">{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}}</span>
    </clr-dg-footer>
  </clr-datagrid>
}

<clr-modal *ngIf="displayArchiveModal" [(clrModalOpen)]="displayArchiveModal">
  <h3 class="modal-title">{{requestStatus.modalHeading}}</h3>
  <div class="modal-body">
    <ng-container [ngSwitch]="requestStatus.state">
      <ng-container *ngSwitchCase="requestStateEnum.PROCESSING">
        <span class="spinner spinner-inline"> {{requestStatus.summary}}</span>
        <span> {{requestStatus.summary}}</span>
      </ng-container>
      <clr-alerts *ngSwitchCase="requestStateEnum.ERROR">
        <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
          <clr-alert-item>
            <span class="alert-text">Error: {{requestStatus.summary}}</span>
          </clr-alert-item>
        </clr-alert>
      </clr-alerts>
      <p *ngSwitchDefault>{{requestStatus.summary}}</p>
    </ng-container>
  </div>
</clr-modal>

import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';


@Injectable()
export class ErrorService {
  private errorBus: Subject<string>;

  constructor() {
    this.errorBus = new Subject<string>();
  }

  errors(): Observable<string> {
    return this.errorBus.asObservable();
  }

  throwError(err: string) {
    this.errorBus.next(err);
  }
}

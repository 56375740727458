import {MissionPlannerFlightPlan} from "./MissionPlannerFlightPlan";
import {QGCSUTMFlightPlan} from "./QGCSFlightPlan";
import {Convert, Item, QGCSFlightPlan, Type} from "./QGCSParser";
import {WaypointCommandEnum} from "../../../../../../model/WaypointParser";


function simpleItemToWaypoint(item:Item):string{
  const params = item.params;
  return [
    item.doJumpID,
    0,
    item.frame,
    item.command,
    ...params,
    item.autoContinue? 1:0,
  ].join('\t');
}

function complexItemToWaypoint(item: Item, nextId:number): string[]{
  const returnValue: string[] = [];
  switch (item.complexItemType){
    case "survey":
    case "CorridorScan":
      const transectStyle = item.transectStyleComplexItem;
      if(!transectStyle){
        console.error("Skipping complex item of type", item.complexItemType, "without transect style");
        return [];
      }


      for(const item of transectStyle.items){
        returnValue.push(...itemToWaypoint(item, nextId + returnValue.length));
      }
      return returnValue;
    case "fwLandingPattern":
      if(item.useLoiterToAlt){
        returnValue.push(
          [
            nextId,
            0,
            3,
            WaypointCommandEnum.NAV_LOITER_TO_ALT,
            0,
            item.loiterRadius,
            0,
            0,
            ...item.landingApproachCoordinate,
            1
          ].join('\t')
        );
      }
      returnValue.push([
        nextId + returnValue.length,
        0,
        3,
        WaypointCommandEnum.NAV_LAND,
        0,
        0,
        0,
        0,
        ...item.landCoordinate,
        1
      ].join('\t'))
      return returnValue;
      case "StructureScan":
    default:
      console.error("Skipping complex item of type", item.complexItemType);
      return [];
  }
}

function itemToWaypoint(item:Item, nextId:number):string[]{
  if(item.type === Type.SimpleItem){
    return [simpleItemToWaypoint(item)];
  }
  console.warn("Complex item found in QGCS flight plan");
  return complexItemToWaypoint(item, nextId);
}

export class QGCSFlightPlanConverter{
  static convertTextToWaypoints(text:string):MissionPlannerFlightPlan{
    const flightPlan = Convert.toQGCSFlightPlan(text);
    const output = [
      "QGC WPL"
    ];
    if(flightPlan.mission.plannedHomePosition){
      console.warn("planned home position found in QGCS flight plan");
      output.push(
        [0, 1, 0, 16, 0, 0, 0, 0, ...flightPlan.mission.plannedHomePosition, 1].join('\t')
      )
    }



    for(const item of flightPlan.mission.items){
      output.push(...itemToWaypoint(item, output.length));
    }


    return MissionPlannerFlightPlan.parseFromText(output.join("\n"));

  }
}

import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {User} from '../../model/User';
import {AdminUserService} from '../../services/admin/admin-user.service';
import {UserResponse} from '../../model/UserResponse';
import {ISubmissionStatus, SubmissionState} from '../../model/SubmissionStatus';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-remove-user',
  templateUrl: './remove-user.component.html',
  styleUrls: ['./remove-user.component.scss']
})
export class RemoveUserComponent implements OnChanges {

  @Input() user: User;
  @Output() userChange = new EventEmitter<User>();
  @Output() userRemoved = new EventEmitter<User>();

  requestStatus: ISubmissionStatus;
  submissionStateEnum = SubmissionState;

  constructor(private userService: AdminUserService) {
    // Constructor intentionally left empty
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && changes.user.currentValue) {
      this.requestStatus = {
        state: SubmissionState.NONE,
        modalHeading: 'User Deletion Confirmation',
        summary: 'Are you sure you want to delete user: ' + this.user.contact?.getName() + '?'
      };
    }
  }

  clearUser() {
    this.user = null;
    this.userChange.emit(null);
  }

  confirmRemoveUser() {
    this.requestStatus = {
      state: SubmissionState.PROCESSING,
      modalHeading: 'Deleting User',
      summary: 'Deleting user...'
    };
    this.userService.deleteUser(this.user).subscribe((res: UserResponse) => {
      this.handleResponse(res);
    }, (error: HttpErrorResponse) => {
      this.handleResponse(error);
    });
  }

  private handleResponse(res: UserResponse | HttpErrorResponse) {
    if (res instanceof UserResponse) {
      if (res.success) {
        this.requestStatus = {
          state: SubmissionState.SUCCESS,
          modalHeading: 'User Deleted',
          summary: 'User has been deleted successfully'
        };
        this.userRemoved.emit(this.user);
      } else {
        this.requestStatus = {
          state: SubmissionState.ERROR,
          modalHeading: 'User Deletion Failed',
          summary: res.message || 'Unknown error during user deletion'
        };
      }
    } else if (res instanceof HttpErrorResponse) {
      this.requestStatus = {
        state: SubmissionState.ERROR,
        modalHeading: 'User Deletion Failed',
        summary: res?.error?.message || 'Unknown error during user deletion'
      };
    } else {
      this.requestStatus = {
        state: SubmissionState.ERROR,
        modalHeading: `User Deletion Status Unknown`,
        summary: 'No information available'
      };
    }
  }
}

/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */

import {UntypedFormControl} from '@angular/forms';

export const maxValueValidator = (required: number) => {
  return (control: UntypedFormControl) => {
    if (control.value !== undefined) {
      const actual = Number(control.value);
      if (Number.isNaN(actual) || actual > required) {
        return {
          maxValue: {
            valid: false,
            required: required,
            actual: actual
          }
        };
      }
    }
    return null;
  };
};

export const minValueValidator = (required: number) => {
  return (control: UntypedFormControl) => {
    if (control.value !== undefined) {
      const actual = Number(control.value);
      if (Number.isNaN(actual) || actual < required) {
        return {
          minValue: {
            valid: false,
            required: required,
            actual: actual
          }
        };
      }
    }
    return null;
  };
};

// required must be the enum type to check
export const enumValidator = (required: any) => {
  return (control: UntypedFormControl) => {
    if (control.value !== undefined && control.value !== null && control.value !== '') {
      const actual = control.value;
      if (!required[actual]) {
        return {
          enum: {
            valid: false,
            required: required,
            actual: actual
          }
        };
      }
    }
    return null;
  };
};

import {IUserMessageData, UserMessageData} from './UserMessageData';
import {ApprovalInfo, IApprovalInfo} from '../gen/utm/approval-info';
import {UntypedFormGroup} from '@angular/forms';

export interface IApprovalAlertMessage extends IUserMessageData {
  approval: IApprovalInfo;
}

export class ApprovalAlertMessage extends UserMessageData implements IApprovalAlertMessage {
  static APPROVAL_FIELD_NAME = 'approval';

  approval: ApprovalInfo;

  constructor(values?: Partial<IApprovalAlertMessage>, useFormGroupValuesToModel = false) {
    super();
    this.approval = new ApprovalInfo();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IUserMessageData>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.approval.setValues(rawValues.approval, useFormGroupValuesToModel);

      super.setValues(values, useFormGroupValuesToModel);
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  setFormGroupValues() {
    this.approval.setFormGroupValues();

    super.setFormGroupValues();
    super.setFormGroupValuesInAddedFormControls();
  }

  protected  getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      super.getFormGroup();
      this._formGroup.addControl(ApprovalAlertMessage.APPROVAL_FIELD_NAME, this.approval.$formGroup);
    }
    return this._formGroup;
  }

}

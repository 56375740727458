// Offset values are in minutes
import {OperationActivationPolicy} from "./model/gen/utm";

export const OPERATION_START_OFFSET_LIMITS: {min: number; max: number} = {min: 1, max: 43200}; // 43200 mins = 30 days
export const OPERATION_DURATION_LIMITS: {min: number; max: number} = {min: 1, max: 1440}; // 1440 mins = 24 hours
export const CONSTRAINT_START_OFFSET_LIMITS: {min: number; max: number} = {min: .5, max: 80640}; // 80640 mins = 56 days
export const CONSTRAINT_DURATION_LIMITS: {min: number; max: number}  = {min: 1, max: null};
export const DEFAULT_OPERATION_START_OFFSET: number = 2;
export const DEFAULT_OPERATION_DURATION: number = 60;
export const DEFAULT_CONSTRAINT_START_OFFSET: number = 2;
export const DEFAULT_CONSTRAINT_DURATION: number  = 60;

export const DEFAULT_OPERATION_ACTIVATION: OperationActivationPolicy = OperationActivationPolicy.MANUAL;

import {Component, forwardRef} from '@angular/core';
import {ClrDatagridFilter, ClrDatagridFilterInterface} from '@clr/angular';
import {Subject} from 'rxjs';
import {state} from '../../../../../shared/model/gen/utm';
import {OperationUtil} from '../../../../../shared/model/OperationUtil';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder} from '@angular/forms';
import {IFormBuilder, IFormGroup} from '@rxweb/types';

@Component({
  selector: 'app-operation-states-filter',
  templateUrl: './operation-states-filter.component.html',
  styleUrls: ['./operation-states-filter.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => OperationStateFilterComponent),
  }]
})
export class OperationStateFilterComponent implements ClrDatagridFilterInterface<any>, ControlValueAccessor {
  statesFg: IFormGroup<any>;
  formBuilder: IFormBuilder;
  changes = new Subject<any>();
  active = false;
  stateOptions: state[];
  states: { [key: string]: boolean } = {};

  private onChange: any;

  constructor(private filterContainer: ClrDatagridFilter, formBuilder: UntypedFormBuilder) {
    this.formBuilder = formBuilder;
    this.stateOptions = [
      state.PROPOSED,
      state.ACCEPTED,
      state.ACTIVATED,
      state.CLOSED,
      state.REJECTED
    ];
    this.statesFg = this.formBuilder.group<any>({
      /* eslint-disable @typescript-eslint/naming-convention */
      state_PROPOSED: [false],
      state_ACCEPTED: [false],
      state_ACTIVATED: [false],
      state_CLOSED: [false],
      state_REJECTED: [false]
      /* eslint-enable @typescript-eslint/naming-convention */
    });

    this.statesFg.valueChanges.subscribe(() => {
      const rawValues = this.statesFg.getRawValue();

      // If activated is checked, set rogue and noncomforming to true
      this.states.ROGUE = !!this.states.ACTIVATED;
      this.states.NONCONFORMING = !!this.states.ACTIVATED;
      const states: string[] = [];

      Object.keys(rawValues).forEach(k => {
        const s = k.replace('state_', '');
        if (rawValues[k]) {
          states.push(s);
        }
      });

      // If only one checkbox is checked, disable it
      if (states.length === 1) {
        this.statesFg.controls['state_' + states[0]].disable({emitEvent: false});
      } else {
        // Otherwise, all controls should be enabled
        Object.keys(this.statesFg.controls).forEach(key => {
          this.statesFg.controls[key].enable({emitEvent: false});
        });
      }

      if (this.onChange) {
        this.onChange(states);
      }
    });
  }

  isActive(): boolean {
    return !!this.active;
  }

  accepts(val: any): boolean {
    return true;
  }

  getPrettyOperationStateName(s: state): string {
    return OperationUtil.getPrettyOperationStateName(s);
  }

  toggleSelectAll() {
    // This only works one way (enable). Disabling all checkboxes is prevented by the
    // minimum requirement of one box being checked at any given time.
    this.statesFg.setValue({
      /* eslint-disable @typescript-eslint/naming-convention */
      state_PROPOSED: true,
      state_ACCEPTED: true,
      state_ACTIVATED: true,
      state_CLOSED: true,
      state_REJECTED: true
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  writeValue(obj: state[]): void {
    this.states = {
      /* eslint-disable @typescript-eslint/naming-convention */
      PROPOSED: false,
      ACCEPTED: false,
      ACTIVATED: false,
      CLOSED: false,
      NONCONFORMING: false,
      ROGUE: false,
      REJECTED: false
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    let control: string;
    this.statesFg.reset({
      /* eslint-disable @typescript-eslint/naming-convention */
      state_PROPOSED: false,
      state_ACCEPTED: false,
      state_ACTIVATED: false,
      state_CLOSED: false,
      state_REJECTED: false
      /* eslint-enable @typescript-eslint/naming-convention */
    }, {emitEvent: false, onlySelf: true});
    for (const s of obj){
      control = 'state_' + s;

      // If rogue or nonconforming are passed as params, check the 'activated' box
      // since these options don't exist in the UI
      if (control === 'state_ROGUE' || control === 'state_NONCONFORMING') {
        control = 'state_ACTIVATED';
      }
      this.statesFg.controls[control].setValue(true, {emitEvent: false, onlySelf: true});
      this.states[s] = true;
    }
  }
}

import {measureDef, unitMap} from "./base";


const metric: unitMap = {
  ea: {
    name: {
      singular: 'Each',
      plural: 'Each',
    },
    to_anchor: 1,
  },
  dz: {
    name: {
      singular: 'Dozen',
      plural: 'Dozens',
    },
    to_anchor: 12,
  },
};
export const EachDef:measureDef = {
  type:'each',
  metric: metric,
  _anchors: {
    metric: {
      unit: 'ea',
      ratio: 1,
    },
  },
};

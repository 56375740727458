/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseModel} from './base-model';
import {DateTime} from "luxon";


export interface IVersion {
  title?: string;
  version?: string;
  build_time?: DateTime;
  api_docs?: Array<string>;
}


export class Version extends BaseModel implements IVersion {

  static TITLE_FIELD_NAME = 'title';
  static VERSION_FIELD_NAME = 'version';
  static BUILD_TIME_FIELD_NAME = 'build_time';
  static API_DOCS_FIELD_NAME = 'api_docs';

  /** Title of the API */
  title: string;
  /** Version number for the API */
  version: string;
  /** Time of the latest build */
  build_time: DateTime;
  /** URL(s) for API Documentation */
  api_docs: Array<string>;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IVersion>, useFormGroupValuesToModel = false) {
    super();
    this.api_docs = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IVersion>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.title = this.getValue<string>(rawValues, Version.TITLE_FIELD_NAME);
      this.version = this.getValue<string>(rawValues, Version.VERSION_FIELD_NAME);
      this.build_time = this.getValue<DateTime>(rawValues, Version.BUILD_TIME_FIELD_NAME);
      this.fillModelArray<string>(this, Version.API_DOCS_FIELD_NAME, rawValues.api_docs, useFormGroupValuesToModel);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        title: new UntypedFormControl(this.title),
        version: new UntypedFormControl(this.version),
        build_time: new UntypedFormControl(this.build_time, [Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'),]),
        api_docs: new UntypedFormArray([]),
      });
      // generate FormArray control elements
      this.fillFormArray<string>(Version.API_DOCS_FIELD_NAME, this.api_docs);
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[Version.TITLE_FIELD_NAME].setValue(this.title);
    this.$formGroup.controls[Version.VERSION_FIELD_NAME].setValue(this.version);
    this.$formGroup.controls[Version.BUILD_TIME_FIELD_NAME].setValue(this.build_time);
    this.fillFormArray<string>(Version.API_DOCS_FIELD_NAME, this.api_docs);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}


import {OperationGeometry} from '../../../../../../model/operation.geometry';
import {LatLngPoint, WaypointCommand, WaypointCommandEnum, WaypointInfo} from '../../../../../../model/WaypointParser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {OperationVolume, units_of_measure, vertical_reference} from '../../../../../../model/gen/utm';
import {
  FlightPlanComponent,
  FlightPlanComponentOptions,
  generateFlightPlanComponentVolumes, GeometricStuff, SegmentComponent
} from '../../../../../../utils/PointBufferer2';
import {UTMFlightPlan} from '../common';
import {Observable, of} from 'rxjs';



export class PiccoloCommand {
  index: number;
  next: number;
  latitude: number;
  longitude: number;
  alt: number;
  agl: number;
  windInterval: number;
  preturn: number;
  lights: number;
  slope: number;
  land: number;
  parachute: number;
  drop: number;
  camera: number;
  orbitDirection: number;
  orbitRadius: number;
  orbitTimes: number;
  user: number;
  orbitAboveAlt: number;
  orbitBelowAlt: number;
  hover: number;

  constructor(private segments: string[]) {
    this.index = +segments[0];
    this.next = +segments[1];
    this.latitude = +segments[2];
    this.longitude = +segments[3];
    this.alt = +segments[4];
    this.agl = +segments[5];
    this.windInterval = +segments[6];
    this.preturn = +segments[7];
    this.lights = +segments[8];
    this.slope = +segments[9];
    this.land = +segments[10];
    this.parachute = +segments[11];
    this.drop = +segments[12];
    this.camera = +segments[13];
    this.orbitDirection = +segments[14];
    this.orbitRadius = +segments[15];
    this.orbitTimes = +segments[16];
    this.user = +segments[17];
    this.orbitAboveAlt = +segments[18];
    this.orbitBelowAlt = +segments[19];
    this.hover = +segments[20];
  }



  static fromLine(line: string): PiccoloCommand|null {
    if (line.trim().startsWith('#') || line.trim().length === 0){
      return null;
    }
    const segments = line.split(' ');

    return new PiccoloCommand(segments);
  }
}
class PiccoloWaypointInfo {
  lines: string[];
  commands: PiccoloCommand[];
  homeLocation: LatLngPoint;


  constructor(private rawLines: string[]) {
    this.lines = rawLines.filter(l => l.trim().length > 0);
    this.commands = this.lines.slice(1).map(PiccoloCommand.fromLine).filter(c => c);

    // this.homeLocation = {lat: this.commands[0].lat, lng: this.commands[0].lng, alt: this.commands[0].alt};
  }

  static fromText(wpText: string): PiccoloWaypointInfo {
    return new PiccoloWaypointInfo(wpText.split('\n'));
  }

}


const zeroValidator = Validators.min(0);

export class PiccoloFlightPlan implements UTMFlightPlan {

  readonly options = new FormGroup({
    horizontalBufferDistanceMeters: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_HORIZONTAL_BUFFER_DISTANCE_METERS, [
      Validators.required,
      Validators.min(1)
    ]),
    verticalBufferDistanceMeters: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_VERTICAL_BUFFER_DISTANCE_METERS, [
      Validators.required,
      Validators.min(1)
    ]),
    // bufferUnits: new FormControl<units_of_measure>(units_of_measure.M, [Validators.required]),
    steps: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_STEPS, [
      Validators.required,
      Validators.min(3)
    ])
  });

  constructor(private waypointInfo: PiccoloWaypointInfo) {

  }


  static fromText(text: string): PiccoloFlightPlan {
    return new PiccoloFlightPlan(PiccoloWaypointInfo.fromText(text));
  }
  private getFlightPlanComponents(): [FlightPlanComponent[], LatLngPoint] {
    const waypointInfo = this.waypointInfo;

    const volumes: FlightPlanComponent[] = [];
    // Group
    let home = waypointInfo.homeLocation;


    let lastPoint = home;
    let point: LatLngPoint;

    for (const cmd of waypointInfo.commands) {
      // Home location
      if (cmd.index === 0){
        home = {
          lat: cmd.latitude,
          lng: cmd.longitude,
          alt: cmd.alt
        };
        lastPoint = home;
      }




      if (cmd.land !== 0){
        point = {
          lat: lastPoint.lat,
          lng: lastPoint.lng,
          alt: home.alt
        };
        volumes.push({
          kind: 'segment',
          index: cmd.index,
          a: lastPoint,
          b: point
        });
        lastPoint = point;
        continue;
      }

      if (cmd.orbitRadius !== 0){
        point = {
          lat: cmd.latitude,
          lng: cmd.longitude,
          alt: cmd.alt
        };
        if (volumes.length > 0) {
          volumes.push({
            kind: 'segment',
            index: cmd.index,
            a: lastPoint,
            b: point
          });
        }
        volumes.push({
          kind: 'loiter',
          index: cmd.index,
          center: point,
          radius: Math.abs(cmd.orbitRadius)
        });
        lastPoint = point;
        continue;
      }

      if (cmd.next === cmd.index + 1){
        point = {
          lat: cmd.latitude,
          lng: cmd.longitude,
          alt: cmd.alt
        };
        volumes.push({
          kind: 'segment',
          index: cmd.index,
          a: lastPoint,
          b: point
        });
        lastPoint = point;
        continue;
      }
      if (cmd.next !== 0){
        // Jump
        const i = volumes.findIndex((vol) => vol.index === (cmd.next));
        if (i < 0) {
          console.error('Invalid jump detected');
          continue;
        }

        const targetCmd = waypointInfo.commands.find((tmp) => tmp.index === cmd.next);
        if (!targetCmd) {
          console.error('Invalid jump detected');
          break;
        }

        const segment: SegmentComponent = {
          kind: 'segment',
          index: cmd.index,
          a: lastPoint,
          b: {
            lat: targetCmd.latitude,
            lng: targetCmd.longitude,
            alt: targetCmd.alt
          }
        };


        volumes.push({
          kind: 'group',
          index: cmd.index,
          items: [...volumes.slice(i), segment]
        });
      }

      if (cmd.next === 0){
        console.log('End of mission');
      }
    }
    return [volumes, waypointInfo.homeLocation];
  }


  toOperationGeometry(): Observable<OperationGeometry> {

    const [volumes, home] = this.getFlightPlanComponents();
    const rawOptions = this.options.getRawValue();
    const options: FlightPlanComponentOptions = {
      steps: rawOptions.steps,
      bufferDistance: rawOptions.horizontalBufferDistanceMeters,
      bufferUnits: units_of_measure.M,
      endTime: undefined,
      startTime: undefined,
      verticalBufferMeters: rawOptions.verticalBufferDistanceMeters,
      verticalReference: vertical_reference.MSL,
      verticalUnits: units_of_measure.M
    };

    let results: OperationVolume[] = [];
    for (const segment of volumes) {
      results = results.concat(generateFlightPlanComponentVolumes(segment, options));
    }


    return of(new OperationGeometry(results, undefined, undefined, home));
  }

}

export type PiccoloFlightPlanFormGroup = Pick<PiccoloFlightPlan, 'options'>['options'];

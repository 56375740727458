import {GeoCircle, units_of_measure} from "../model/gen/utm";
import {unit} from "./convert-units/definitions/base";
import {Converter} from "./convert-units";
import * as _ from "lodash";

export class MeasurementsUtil {

  static unitLabels: {[key in units_of_measure]: string } = {
    [units_of_measure.M]: 'M',
    [units_of_measure.FT]: 'FT',
    [units_of_measure.MI]: 'MI',
    [units_of_measure.NM]: 'NM'
  };

  static parseUnitForConversion(units: units_of_measure): unit {
      switch (units) {
        case units_of_measure.M:
          return 'm';
        case units_of_measure.FT:
          return 'ft';
        case units_of_measure.MI:
          return 'mi';
        case units_of_measure.NM:
          return 'nm';
        default:
          throw new Error(`Invalid units of measure: ${units}`);
      }
    }

  static convertUnits(from: units_of_measure, to: units_of_measure, value: number, precision?: number): number {
    if (to !== from) {
      const convertedVal = new Converter(value)
        .from(MeasurementsUtil.parseUnitForConversion(from))
        .to(MeasurementsUtil.parseUnitForConversion(to))
      return _.isNil(precision) ? convertedVal : _.round(convertedVal, precision);
    } else {
      return value;
    }
  }

  static convertCircle (circle: GeoCircle, toUnits: units_of_measure, precision = 6): GeoCircle {
    const convertedCircle = new GeoCircle({
      latitude: circle.latitude,
      longitude: circle.longitude,
      radius: circle.radius,
      units: circle.units
    });
    if (circle.units !== toUnits) {
      convertedCircle.radius = MeasurementsUtil.convertUnits(convertedCircle.units, toUnits, convertedCircle.radius, precision);
      convertedCircle.units = toUnits;
    }
    return convertedCircle;
  }

}

import {DateTime} from 'luxon';
import {IUserMessage, UserMessage} from './UserMessage';
import {BaseModel} from '../gen/utm/base-model';
import {IUasRegistrationInfo} from '../UasRegistrationInfo';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export interface IUserMessageEntry {
  id: string;
  timeCreated: DateTime;
  userId: string;
  priority: number;
  read: boolean;
  message: IUserMessage;
}

export class UserMessageEntry extends BaseModel implements IUserMessageEntry {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ID_FIELD_NAME = 'id';
  static TIME_CREATED_FIELD_NAME = 'timeCreated';
  static USER_ID_FIELD_NAME = 'userId';
  static PRIORITY_FIELD_NAME = 'priority';
  static READ_FIELD_NAME = 'read';
  static MESSAGE_FIELD_NAME = 'message';
  /* eslint-enable @typescript-eslint/naming-convention */
  id: string;
  timeCreated: DateTime;
  userId: string;
  priority: number;
  read: boolean;
  message: UserMessage;

  constructor(values?: Partial<IUserMessageEntry>,
              useFormGroupValuesToModel = false) {
    super();
    this.message = new UserMessage();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IUasRegistrationInfo>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);

      this.id = this.getValue<string>(rawValues, UserMessageEntry.ID_FIELD_NAME);
      this.timeCreated = this.getValue<DateTime>(rawValues, UserMessageEntry.TIME_CREATED_FIELD_NAME);
      this.userId = this.getValue<string>(rawValues, UserMessageEntry.USER_ID_FIELD_NAME);
      this.priority = this.getValue<number>(rawValues, UserMessageEntry.PRIORITY_FIELD_NAME);
      this.read = this.getValue<boolean>(rawValues, UserMessageEntry.READ_FIELD_NAME);
      this.message.setValues(rawValues.message, useFormGroupValuesToModel);

      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);

    }
  }

  setFormGroupValues() {
    this.$formGroup.controls[UserMessageEntry.ID_FIELD_NAME].setValue(this.id);
    this.$formGroup.controls[UserMessageEntry.TIME_CREATED_FIELD_NAME].setValue(this.timeCreated);
    this.$formGroup.controls[UserMessageEntry.USER_ID_FIELD_NAME].setValue(this.userId);
    this.$formGroup.controls[UserMessageEntry.PRIORITY_FIELD_NAME].setValue(this.priority);
    this.$formGroup.controls[UserMessageEntry.READ_FIELD_NAME].setValue(this.read);
    this.message.setFormGroupValues();
  }

  protected getFormGroup(): UntypedFormGroup {
    /* eslint-disable no-underscore-dangle */
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        id: new UntypedFormControl(this.id, [Validators.required]),
        timeCreated: new UntypedFormControl(this.timeCreated, [Validators.required]),
        userId: new UntypedFormControl(this.userId, [Validators.required]),
        priority: new UntypedFormControl(this.priority, [Validators.required]),
        read: new UntypedFormControl(this.read, [Validators.required]),
        message: this.message.$formGroup
      });
    }
    return this._formGroup;
    /* eslint-enable no-underscore-dangle */
  }
}

import {Injectable} from '@angular/core';
import {OffNominalReportService} from '../off-nominal-report.service';
import {OffNominalReport} from '../../model/OffNominalReport';
import {Observable, of} from 'rxjs';
import {delay, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MockOffNominalReportService implements OffNominalReportService {

  constructor() {
    // Method not implemented
  }

  submitReport(report: OffNominalReport): Observable<boolean> {
    return of(true).pipe(delay(3000)).pipe(tap((res) => {
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(report));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', 'report.json');
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();

    }));
  }
}

export interface EnvironmentInterface {
//  operationProvider: Type<OperationService>;
//  offNominalReportProvider: Type<OffNominalReportService>;
//  ussProvider: Type<USSService>;
//  authProvider: Type<AuthService>;
  production?: boolean;
//  parserProvider: Type<ParserService>;
//  uvrProvider: Type<UvrService>;
//  envProvider: Type<EnvironmentService>;
  baseUrl: string;
  version: string;
}

export const BaseEnvironment = {
  version: '2.1.0-alpha'
};

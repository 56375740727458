import {Injectable} from '@angular/core';
import {OffNominalReportService} from '../off-nominal-report.service';
import {OffNominalReport} from '../../model/OffNominalReport';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RestOffNominalReportService implements OffNominalReportService{

  constructor() {
    // Method not implemented
  }

  submitReport(report: OffNominalReport): Observable<boolean> {
    throw new Error('Method not implemented.');
  }
}

import {double, integer} from './DataTypes';
import {BaseModel} from './gen/utm/base-model';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export interface IAircraftSpecs {
  serialNumber: string;
  manufacturer: string;
  make: string;
  model: string;
  color: string;
  primaryConstructionMaterial: string;
  dryWeight: double;
  length: double;
  width: double;
  height: double;
  numberOfRotors: integer;
  propLength: double;
  numBatteries: integer;
  batteryCapacity: double;
  batteryVoltage: double;
  batteryWeight: double;
  batteryChemistry: string;
  maxTakeOffWeight: double;
  maxEnduranceTime: integer;
  minOperatingTemp: double;
  maxOperatingTemp: double;
  ipRating: string;
  engineType: string;
  fuelSource: string;
  fuelCapacity: double;
  airframe: string;
  maxRoundTripRange: double;
  maxAirspeed: double;
  maxVerticalSpeed: double;
  maxVerticalAcceleration: double;
  wingspan: double;
  controlLink: string;
  accessoriesInstalled: string[];
  countryOfOrigin: string;
  serviceInterval: integer;
}

export interface TransportAircraftSpecs {
  serialNumber: string;
  manufacturer: string;
  make: string;
  model: string;
  color: string;
  primaryConstructionMaterial: string;
  dryWeight: double;
  length: double;
  width: double;
  height: double;
  numberOfRotors: integer;
  propLength: double;
  numBatteries: integer;
  batteryCapacity: double;
  batteryVoltage: double;
  batteryWeight: double;
  batteryChemistry: string;
  maxTakeOffWeight: double;
  maxEnduranceTime: integer;
  minOperatingTemp: double;
  maxOperatingTemp: double;
  ipRating: string;
  engineType: string;
  fuelSource: string;
  fuelCapacity: double;
  airframe: string;
  maxRoundTripRange: double;
  maxAirspeed: double;
  maxVerticalSpeed: double;
  maxVerticalAcceleration: double;
  wingspan: double;
  controlLink: string;
  accessoriesInstalled: string[];
  countriesOfOrigin: string[];
  serviceInterval: integer;
}

export class AircraftSpecs extends BaseModel implements IAircraftSpecs {
  static SERIAL_NUMBER_FIELD_NAME = 'serialNumber';
  static MANUFACTURER_FIELD_NAME = 'manufacturer';
  static MAKE_FIELD_NAME = 'make';
  static MODEL_FIELD_NAME = 'model';
  static COLOR_FIELD_NAME = 'color';
  static PRIMARY_CONSTRUCTION_MATERIAL_FIELD_NAME = 'primaryConstructionMaterial';
  static DRY_WEIGHT_FIELD_NAME = 'dryWeight';
  static LENGTH_FIELD_NAME = 'length';
  static WIDTH_FIELD_NAME = 'width';
  static HEIGHT_FIELD_NAME = 'height';
  static NUMBER_OF_ROTORS_FIELD_NAME = 'numberOfRotors';
  static PROP_LENGTH_FIELD_NAME = 'propLength';
  static NUM_BATTERIES_FIELD = 'numBatteries';
  static BATTERY_CAPACITY_FIELD_NAME = 'batteryCapacity';
  static BATTERY_VOLTAGE_FIELD_NAME = 'batteryVoltage';
  static BATTERY_WEIGHT_FIELD_NAME = 'batteryWeight';
  static BATTERY_CHEMISTRY_FIELD_NAME = 'batteryChemistry';
  static MAX_TAKE_OFF_WEIGHT_FIELD_NAME = 'maxTakeOffWeight';
  static MAX_ENDURANCE_TIME_FIELD_NAME = 'maxEnduranceTime';
  static MIN_OPERATING_TEMP_FIELD_NAME = 'minOperatingTemp';
  static MAX_OPERATING_TEMP_FIELD_NAME = 'maxOperatingTemp';
  static IP_RATING_FIELD_NAME = 'ipRating';
  static ENGINE_TYPE_FIELD_NAME = 'engineType';
  static FUEL_SOURCE_FIELD_NAME = 'fuelSource';
  static FUEL_CAPACITY_FIELD_NAME = 'fuelCapacity';
  static ACCESSORIES_INSTALLED_FIELD_NAME = 'accessoriesInstalled';
  static AIRFRAME_FIELD_NAME = 'airframe';
  static CONTROL_LINK_FIELD_NAME = 'controlLink';
  static COUNTRY_OF_ORIGIN_FIELD_NAME = 'countryOfOrigin';
  static MAX_AIRSPEED_FIELD_NAME = 'maxAirspeed';
  static MAX_ROUND_TRIP_RANGE_FIELD_NAME = 'maxRoundTripRange';
  static MAX_VERTICAL_ACCELERATION_FIELD_NAME = 'maxVerticalAcceleration';
  static MAX_VERTICAL_SPEED_FIELD_NAME = 'maxVerticalSpeed';
  static WINGSPAN_FIELD_NAME = 'wingspan';
  static SERVICE_INTERVAL_FIELD_NAME = 'serviceInterval';

  serialNumber: string;
  manufacturer: string;
  make: string;
  model: string;
  color: string;
  primaryConstructionMaterial: string;
  dryWeight: double;
  length: double;
  width: double;
  height: double;
  numberOfRotors: integer;
  propLength: double;
  numBatteries: integer;
  batteryCapacity: double;
  batteryVoltage: double;
  batteryWeight: double;
  batteryChemistry: string;
  maxTakeOffWeight: double;
  maxEnduranceTime: integer;
  minOperatingTemp: double;
  maxOperatingTemp: double;
  ipRating: string;
  engineType: string;
  fuelSource: string;
  fuelCapacity: double;
  accessoriesInstalled: string[];
  airframe: string;
  controlLink: string;
  countryOfOrigin: string;
  maxAirspeed: double;
  maxRoundTripRange: double;
  maxVerticalAcceleration: double;
  maxVerticalSpeed: double;
  wingspan: double;
  serviceInterval: integer;

  constructor(values?: Partial<AircraftSpecs>, useFormGroupValuesToModel = false) {
    super();
    this.accessoriesInstalled = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<AircraftSpecs>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.serialNumber = this.getValue<string>(rawValues, AircraftSpecs.SERIAL_NUMBER_FIELD_NAME);
      this.manufacturer = this.getValue<string>(rawValues, AircraftSpecs.MANUFACTURER_FIELD_NAME);
      this.make = this.getValue<string>(rawValues, AircraftSpecs.MAKE_FIELD_NAME);
      this.model = this.getValue<string>(rawValues, AircraftSpecs.MODEL_FIELD_NAME);
      this.color = this.getValue<string>(rawValues, AircraftSpecs.COLOR_FIELD_NAME);
      this.primaryConstructionMaterial = this.getValue<string>(rawValues, AircraftSpecs.PRIMARY_CONSTRUCTION_MATERIAL_FIELD_NAME);
      this.dryWeight = this.getValue<double>(rawValues, AircraftSpecs.DRY_WEIGHT_FIELD_NAME);
      this.length = this.getValue<double>(rawValues, AircraftSpecs.LENGTH_FIELD_NAME);
      this.width = this.getValue<double>(rawValues, AircraftSpecs.WIDTH_FIELD_NAME);
      this.height = this.getValue<double>(rawValues, AircraftSpecs.HEIGHT_FIELD_NAME);
      this.numberOfRotors = this.getValue<integer>(rawValues, AircraftSpecs.NUMBER_OF_ROTORS_FIELD_NAME);
      this.propLength = this.getValue<double>(rawValues, AircraftSpecs.PROP_LENGTH_FIELD_NAME);
      this.numBatteries = this.getValue<integer>(rawValues, AircraftSpecs.NUM_BATTERIES_FIELD);
      this.batteryCapacity = this.getValue<double>(rawValues, AircraftSpecs.BATTERY_CAPACITY_FIELD_NAME);
      this.batteryVoltage = this.getValue<double>(rawValues, AircraftSpecs.BATTERY_VOLTAGE_FIELD_NAME);
      this.batteryWeight = this.getValue<double>(rawValues, AircraftSpecs.BATTERY_WEIGHT_FIELD_NAME);
      this.batteryChemistry = this.getValue<string>(rawValues, AircraftSpecs.BATTERY_CHEMISTRY_FIELD_NAME);
      this.maxTakeOffWeight = this.getValue<double>(rawValues, AircraftSpecs.MAX_TAKE_OFF_WEIGHT_FIELD_NAME);
      this.maxEnduranceTime = this.getValue<integer>(rawValues, AircraftSpecs.MAX_ENDURANCE_TIME_FIELD_NAME);
      this.minOperatingTemp = this.getValue<double>(rawValues, AircraftSpecs.MIN_OPERATING_TEMP_FIELD_NAME);
      this.maxOperatingTemp = this.getValue<double>(rawValues, AircraftSpecs.MAX_OPERATING_TEMP_FIELD_NAME);
      this.ipRating = this.getValue<string>(rawValues, AircraftSpecs.IP_RATING_FIELD_NAME);
      this.engineType = this.getValue<string>(rawValues, AircraftSpecs.ENGINE_TYPE_FIELD_NAME);
      this.fuelSource = this.getValue<string>(rawValues, AircraftSpecs.FUEL_SOURCE_FIELD_NAME);
      this.fuelCapacity = this.getValue<double>(rawValues, AircraftSpecs.FUEL_CAPACITY_FIELD_NAME);

      // Other fields
      this.fillModelArray(this, AircraftSpecs.ACCESSORIES_INSTALLED_FIELD_NAME, rawValues.accessoriesInstalled, useFormGroupValuesToModel);
      this.airframe = this.getValue<string>(rawValues, AircraftSpecs.AIRFRAME_FIELD_NAME);
      this.controlLink = this.getValue<string>(rawValues, AircraftSpecs.CONTROL_LINK_FIELD_NAME);
      this.countryOfOrigin = this.getValue<string>(rawValues, AircraftSpecs.COUNTRY_OF_ORIGIN_FIELD_NAME);
      this.maxAirspeed = this.getValue<double>(rawValues, AircraftSpecs.MAX_AIRSPEED_FIELD_NAME);
      this.maxRoundTripRange = this.getValue<double>(rawValues, AircraftSpecs.MAX_ROUND_TRIP_RANGE_FIELD_NAME);
      this.maxVerticalAcceleration = this.getValue<double>(rawValues, AircraftSpecs.MAX_VERTICAL_ACCELERATION_FIELD_NAME);
      this.maxVerticalSpeed = this.getValue<double>(rawValues, AircraftSpecs.MAX_VERTICAL_SPEED_FIELD_NAME);
      this.wingspan = this.getValue<double>(rawValues, AircraftSpecs.WINGSPAN_FIELD_NAME);
      this.serviceInterval = this.getValue<integer>(rawValues, AircraftSpecs.SERVICE_INTERVAL_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  setFormGroupValues() {
    this.$formGroup.controls[AircraftSpecs.SERIAL_NUMBER_FIELD_NAME].setValue(this.serialNumber);
    this.$formGroup.controls[AircraftSpecs.MANUFACTURER_FIELD_NAME].setValue(this.manufacturer);
    this.$formGroup.controls[AircraftSpecs.MAKE_FIELD_NAME].setValue(this.make);
    this.$formGroup.controls[AircraftSpecs.MODEL_FIELD_NAME].setValue(this.model);
    this.$formGroup.controls[AircraftSpecs.COLOR_FIELD_NAME].setValue(this.color);
    this.$formGroup.controls[AircraftSpecs.PRIMARY_CONSTRUCTION_MATERIAL_FIELD_NAME].setValue(this.primaryConstructionMaterial);
    this.$formGroup.controls[AircraftSpecs.DRY_WEIGHT_FIELD_NAME].setValue(this.dryWeight);
    this.$formGroup.controls[AircraftSpecs.LENGTH_FIELD_NAME].setValue(this.length);
    this.$formGroup.controls[AircraftSpecs.WIDTH_FIELD_NAME].setValue(this.width);
    this.$formGroup.controls[AircraftSpecs.HEIGHT_FIELD_NAME].setValue(this.height);
    this.$formGroup.controls[AircraftSpecs.NUMBER_OF_ROTORS_FIELD_NAME].setValue(this.numberOfRotors);
    this.$formGroup.controls[AircraftSpecs.PROP_LENGTH_FIELD_NAME].setValue(this.propLength);
    this.$formGroup.controls[AircraftSpecs.NUM_BATTERIES_FIELD].setValue(this.numBatteries);
    this.$formGroup.controls[AircraftSpecs.BATTERY_CAPACITY_FIELD_NAME].setValue(this.batteryCapacity);
    this.$formGroup.controls[AircraftSpecs.BATTERY_VOLTAGE_FIELD_NAME].setValue(this.batteryVoltage);
    this.$formGroup.controls[AircraftSpecs.BATTERY_WEIGHT_FIELD_NAME].setValue(this.batteryWeight);
    this.$formGroup.controls[AircraftSpecs.BATTERY_CHEMISTRY_FIELD_NAME].setValue(this.batteryChemistry);
    this.$formGroup.controls[AircraftSpecs.MAX_TAKE_OFF_WEIGHT_FIELD_NAME].setValue(this.maxTakeOffWeight);
    this.$formGroup.controls[AircraftSpecs.MAX_ENDURANCE_TIME_FIELD_NAME].setValue(this.maxEnduranceTime);
    this.$formGroup.controls[AircraftSpecs.MIN_OPERATING_TEMP_FIELD_NAME].setValue(this.minOperatingTemp);
    this.$formGroup.controls[AircraftSpecs.MAX_OPERATING_TEMP_FIELD_NAME].setValue(this.maxOperatingTemp);
    this.$formGroup.controls[AircraftSpecs.IP_RATING_FIELD_NAME].setValue(this.ipRating);
    this.$formGroup.controls[AircraftSpecs.ENGINE_TYPE_FIELD_NAME].setValue(this.engineType);
    this.$formGroup.controls[AircraftSpecs.FUEL_SOURCE_FIELD_NAME].setValue(this.fuelSource);
    this.$formGroup.controls[AircraftSpecs.FUEL_CAPACITY_FIELD_NAME].setValue(this.fuelCapacity);

    // Other fields
    this.fillFormArray<string>(AircraftSpecs.ACCESSORIES_INSTALLED_FIELD_NAME, this.accessoriesInstalled);
    this.$formGroup.controls[AircraftSpecs.AIRFRAME_FIELD_NAME].setValue(this.airframe);
    this.$formGroup.controls[AircraftSpecs.CONTROL_LINK_FIELD_NAME].setValue(this.controlLink);
    this.$formGroup.controls[AircraftSpecs.COUNTRY_OF_ORIGIN_FIELD_NAME].setValue(this.countryOfOrigin);
    this.$formGroup.controls[AircraftSpecs.MAX_AIRSPEED_FIELD_NAME].setValue(this.maxAirspeed);
    this.$formGroup.controls[AircraftSpecs.MAX_ROUND_TRIP_RANGE_FIELD_NAME].setValue(this.maxRoundTripRange);
    this.$formGroup.controls[AircraftSpecs.MAX_VERTICAL_ACCELERATION_FIELD_NAME].setValue(this.maxVerticalAcceleration);
    this.$formGroup.controls[AircraftSpecs.MAX_VERTICAL_SPEED_FIELD_NAME].setValue(this.maxVerticalSpeed);
    this.$formGroup.controls[AircraftSpecs.WINGSPAN_FIELD_NAME].setValue(this.wingspan);
    this.$formGroup.controls[AircraftSpecs.SERVICE_INTERVAL_FIELD_NAME].setValue(this.serviceInterval);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        serialNumber: new UntypedFormControl(this.serialNumber, [Validators.required]),
        manufacturer: new UntypedFormControl(this.manufacturer, [Validators.required]),
        make: new UntypedFormControl(this.make, [Validators.required]),
        model: new UntypedFormControl(this.model, [Validators.required]),
        color: new UntypedFormControl(this.color),
        primaryConstructionMaterial: new UntypedFormControl(this.primaryConstructionMaterial),
        dryWeight: new UntypedFormControl(this.dryWeight),
        length: new UntypedFormControl(this.length),
        width: new UntypedFormControl(this.width),
        height: new UntypedFormControl(this.height),
        numberOfRotors: new UntypedFormControl(this.numberOfRotors),
        propLength: new UntypedFormControl(this.propLength),
        numBatteries: new UntypedFormControl(this.numBatteries),
        batteryCapacity: new UntypedFormControl(this.batteryCapacity),
        batteryVoltage: new UntypedFormControl(this.batteryVoltage),
        batteryWeight: new UntypedFormControl(this.batteryWeight),
        batteryChemistry: new UntypedFormControl(this.batteryChemistry),
        maxTakeOffWeight: new UntypedFormControl(this.maxTakeOffWeight),
        maxEnduranceTime: new UntypedFormControl(this.maxEnduranceTime),
        minOperatingTemp: new UntypedFormControl(this.minOperatingTemp),
        maxOperatingTemp: new UntypedFormControl(this.maxOperatingTemp),
        ipRating: new UntypedFormControl(this.ipRating),
        engineType: new UntypedFormControl(this.engineType),
        fuelSource: new UntypedFormControl(this.fuelSource),
        fuelCapacity: new UntypedFormControl(this.fuelCapacity),
        accessoriesInstalled: new UntypedFormArray([]),
        airframe: new UntypedFormControl(this.airframe),
        controlLink: new UntypedFormControl(this.controlLink),
        countryOfOrigin: new UntypedFormControl(this.countryOfOrigin),
        maxAirspeed: new UntypedFormControl(this.maxAirspeed),
        maxRoundTripRange: new UntypedFormControl(this.maxRoundTripRange),
        maxVerticalAcceleration: new UntypedFormControl(this.maxVerticalAcceleration),
        maxVerticalSpeed: new UntypedFormControl(this.maxVerticalSpeed),
        wingspan: new UntypedFormControl(this.wingspan),
        serviceInterval: new UntypedFormControl(this.serviceInterval),
      });

      // generate FormArray control elements
      this.fillFormArray<string>(AircraftSpecs.ACCESSORIES_INSTALLED_FIELD_NAME, this.accessoriesInstalled);
    }
    return this._formGroup;
  }

}

<clr-modal *ngIf="showSendMessageModal" [(clrModalOpen)]="showSendMessageModal" [clrModalSize]="'md'" class="user-direct-message-modal">
  <h3 class="modal-title">Send User Message</h3>
  <div class="modal-body">
    <div class="clr-row clr-justify-content-center">
      <div class="clr-col-md-12">
        <ng-container [ngSwitch]="messageStatus.state">
          <!-- Message submission form -->
          <div *ngSwitchCase="messageState.NONE" [formGroup]="messageFG">
            <ng-container *ngIf="messageTarget?.targetType === targetEnum.OPERATION">
              <p>Use this form to send a message to the owner of this operation.</p>
            </ng-container>
            <ng-container *ngIf="messageTarget?.targetType === targetEnum.USER">
              <p>Use this form to send a message to another user.</p>
              <clr-select-container>
                <label>Select a Recipient</label>
                <select autocomplete="off" clrSelect formControlName="userID">
                  <option *ngFor="let contact of availableContacts" [value]="contact.userId">{{contact.getName()}}</option>
                </select>
              </clr-select-container>
            </ng-container>
            <clr-textarea-container>
              <label>Message</label>
              <textarea clrTextarea formControlName="message" placeholder="Enter your message here"></textarea>
              <clr-control-error *clrIfError="'required'">Message is required</clr-control-error>
            </clr-textarea-container>
            <button [disabled]="messageFG.invalid" (click)="sendUserDirectMessage()" class="btn btn-primary">
              Send</button>
          </div>
          <!-- Message sending -->
          <ng-container *ngSwitchCase="messageState.PENDING">
            <span class="spinner spinner-inline"> Sending...</span>
            <span> Sending...</span>
          </ng-container>
          <!-- Message sent successfully -->
          <p *ngSwitchCase="messageState.SUCCESS">Message sent successfully</p>
          <!-- Message failed to send -->
          <clr-alerts *ngSwitchCase="messageState.ERROR">
            <clr-alert [clrAlertType]="'danger'" [clrAlertClosable]="false">
              <clr-alert-item>
                <span class="alert-text">{{messageStatus.error}}</span>
              </clr-alert-item>
            </clr-alert>
          </clr-alerts>
        </ng-container>
      </div>
    </div>
  </div>
</clr-modal>

<h1 cds-text="title">View Users</h1>
@if (deviceSize$() !== 'sm') {
  <button class="btn btn-primary" (click)="resetFilters()">Reset Filters</button>
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading"  class="datagrid-compact">
    <clr-dg-column [clrDgColType]="'string'" [clrDgField]="'contact.title'" [clrDgSortOrder]="sortDirections['contact.title']">Title</clr-dg-column>
    <clr-dg-column [clrDgColType]="'string'" [clrDgField]="'contact.firstName'" [clrDgSortOrder]="sortDirections['contact.firstName']">First Name</clr-dg-column>
    <clr-dg-column [clrDgColType]="'string'" [clrDgField]="'contact.lastName'" [clrDgSortOrder]="sortDirections['contact.lastName']">Last Name</clr-dg-column>
    <clr-dg-column [clrDgColType]="'string'" [clrDgField]="'pretty_org'" [clrDgSortOrder]="sortDirections['pretty_org']">Organization</clr-dg-column>
    <clr-dg-column [clrDgColType]="'string'" [clrDgField]="'contact.division'" [clrDgSortOrder]="sortDirections['contact.division']">Division/Unit</clr-dg-column>
    <clr-dg-column [clrDgColType]="'string'" [clrDgField]="'pretty_roles'" [clrDgSortBy]="roleComparator" [clrDgSortOrder]="sortDirections['pretty_roles']">Role</clr-dg-column>
    <clr-dg-column [clrDgColType]="'string'" [clrDgField]="'email'" [clrDgSortOrder]="sortDirections['email']">Email</clr-dg-column>

    <clr-dg-placeholder>No results found. Please try adjusting the filters in the table column headers.</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let user of users" [clrDgItem]="user">
      <clr-dg-cell>{{user.contact.title}}</clr-dg-cell>
      <clr-dg-cell>{{user.contact.firstName}}</clr-dg-cell>
      <clr-dg-cell>{{user.contact.lastName}}</clr-dg-cell>
      <clr-dg-cell>{{user.pretty_org}}</clr-dg-cell>
      <clr-dg-cell>{{user.contact.division}}</clr-dg-cell>
      <clr-dg-cell>{{user.pretty_roles[0]}}</clr-dg-cell>
      <clr-dg-cell>{{user.email}}</clr-dg-cell>

      <clr-dg-action-overflow>
        <button class="action-item" (click)="viewUser(user)">View</button>
        <button class="action-item" *hasPermission="'submit_user'" (click)="editUser(user)">Edit</button>
        <button class="action-item" *hasPermission="'delete_user'" (click)="promptUserDelete(user)">Delete</button>
      </clr-dg-action-overflow>
  <!--    Uncomment this code to enable expandable details section
      <clr-dg-row-detail *clrIfExpanded>
        <app-user-summary [user]="user"></app-user-summary>
      </clr-dg-row-detail>-->
    </clr-dg-row>
    <clr-dg-footer>

      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize" [clrDgPage]="currentPage"
                         [clrDgTotalItems]="totalItems"></clr-dg-pagination>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}} |
      <clr-dg-page-size [clrPageSizeOptions]="pageSizeOptions">Users per page</clr-dg-page-size>
    </clr-dg-footer>
  </clr-datagrid>
} @else {
  <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <clr-dg-column [clrDgColType]="'string'" [clrDgField]="'contact.firstName'" [clrDgSortOrder]="sortDirections['contact.firstName']">First Name</clr-dg-column>
    <clr-dg-column [clrDgColType]="'string'" [clrDgField]="'contact.lastName'" [clrDgSortOrder]="sortDirections['contact.lastName']">Last Name</clr-dg-column>

    <clr-dg-placeholder>No results found. Please try adjusting the filters in the table column headers.</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let user of users" [clrDgItem]="user">
      <clr-dg-cell>{{user.contact.firstName}}</clr-dg-cell>
      <clr-dg-cell>{{user.contact.lastName}}</clr-dg-cell>

      <clr-dg-action-overflow>
        <button class="action-item" (click)="viewUser(user)">View</button>
        <button class="action-item" *hasPermission="'submit_user'" (click)="editUser(user)">Edit</button>
        <button class="action-item" *hasPermission="'delete_user'" (click)="promptUserDelete(user)">Delete</button>
      </clr-dg-action-overflow>
      <!--    Uncomment this code to enable expandable details section
          <clr-dg-row-detail *clrIfExpanded>
            <app-user-summary [user]="user"></app-user-summary>
          </clr-dg-row-detail>-->
    </clr-dg-row>
    <clr-dg-footer>

      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize" [clrDgPage]="currentPage"
                         [clrDgTotalItems]="totalItems"></clr-dg-pagination>
      <span class="datagrid-footer-description">{{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}}</span>
    </clr-dg-footer>
  </clr-datagrid>
}

<app-remove-user [(user)]="userToDelete" (userRemoved)="handleUserDeleted($event)"></app-remove-user>

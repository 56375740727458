import {measureDef, unitMap} from "./base";


const metric: unitMap = {
  N: {
    name: {
      singular: 'Newton',
      plural: 'Newtons',
    },
    to_anchor: 1,
  },
  kN: {
    name: {
      singular: 'Kilonewton',
      plural: 'Kilonewtons',
    },
    to_anchor: 1000,
  },
  lbf: {
    name: {
      singular: 'Pound-force',
      plural: 'Pound-forces',
    },
    to_anchor: 4.44822,
  },
};
export const ForceDef:measureDef = {
  type:'force',
  metric: metric,
  imperial: {},
  _anchors: {
    metric: {
      unit: 'N',
      ratio: 1,
    },
  },
};

import {Component} from '@angular/core';

@Component({
  selector: 'app-operations-approvals',
  templateUrl: './operation-approvals.component.html'
})
export class OperationApprovalsComponent {
  constructor() {
    // Constructor intentionally left empty
  }
}

import {AxPermissionRequest} from '../AxPermissionRequest';
import {Observable} from 'rxjs';
import {User} from '../../model/User';

export interface PermissionContext{
  user?: User;
  roles?: string[];
}

export abstract class PermissionService {

  protected constructor() {
    // Method not implemented
  }

  abstract evaluateRequest<T>(req: AxPermissionRequest<T>, overrides?: PermissionContext): Observable<boolean>;
}

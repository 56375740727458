import {Component, forwardRef, Input, OnDestroy, OnInit} from '@angular/core';
import {
  ConstraintSearchParameters,
  ConstraintSearchSortFieldUtil,
  IConstraintSearchParameters
} from '../../../../shared/services/constraint.service';
import {NG_VALUE_ACCESSOR, UntypedFormBuilder, Validators} from '@angular/forms';
import {IControlValueAccessor, IFormBuilder, IFormGroup} from '@rxweb/types';
import {Subscription} from 'rxjs';
import {ConstraintState} from '../../../../shared/model/utm/UvrExt';
import {Volume4dQuery} from '../../../../shared/models/Volume4dQuery';
import {DateTime} from 'luxon';

interface IConstraintSearchConfig {
  /* eslint-disable @typescript-eslint/naming-convention */
  startTimeAfter?: Date;
  state_PROPOSED: boolean;
  state_ACCEPTED: boolean;
  state_ACTIVE: boolean;
  state_ENDED: boolean;
  sort?: string[];
  sortIncreasing?: boolean;
  /* eslint-enable @typescript-eslint/naming-convention */
}

@Component({
  selector: 'app-constraint-search-options-config',
  templateUrl: './constraint-search-options-config.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ConstraintSearchOptionsConfigComponent),
    }
  ]
})
export class ConstraintSearchOptionsConfigComponent implements IControlValueAccessor<IConstraintSearchParameters>, OnInit, OnDestroy {
  @Input() showUpdateButton: boolean;
  fg: IFormGroup<IConstraintSearchConfig>;
  formBuilder: IFormBuilder;
  sortOptions: { [key: string]: string };
  hasStartTime = false;
  stateOptions: ConstraintState[] = [];
  states: { [key: string]: boolean } = {};
  private change: (value: IConstraintSearchParameters) => void;
  private fgSub: Subscription;

  constructor(formBuilder: UntypedFormBuilder) {
    this.formBuilder = formBuilder;
    this.sortOptions = {
      /* eslint-disable @typescript-eslint/naming-convention */
      effective_time_begin: 'Begin Time',
      time_end: 'End Time',
      uss_name: 'USS',
      reason: 'Reason',
      /* eslint-enable @typescript-eslint/naming-convention */
    };

    this.stateOptions = [
      ConstraintState.ACCEPTED,
      ConstraintState.ACTIVE,
      ConstraintState.ENDED
    ];

    for (const s of this.stateOptions) {
      this.states[s] = false;
    }
  }

  private static toConstraintSearchParameters(raw: IConstraintSearchConfig): IConstraintSearchParameters {
    let volumeQuery: Volume4dQuery|null = null;
    if (raw.startTimeAfter){
      volumeQuery = {
        timeWindow: {
          timeBeginAfter: DateTime.fromJSDate(raw.startTimeAfter)
        }
      };
    }
    return new ConstraintSearchParameters({
        volume4d: volumeQuery || undefined,
        state: [
          raw.state_PROPOSED ? ConstraintState.PROPOSED : null,
          raw.state_ACCEPTED ? ConstraintState.ACCEPTED : null,
          raw.state_ACTIVE ? ConstraintState.ACTIVE : null,
          raw.state_ENDED ? ConstraintState.ENDED : null
        ].filter(s => s),
        sort: raw.sort ? [ConstraintSearchSortFieldUtil.fromString(raw.sort.toString())] : null,
        sortIncreasing: raw.sortIncreasing
      }
    );
  }

  private static toIConstraintSearchConfig(raw: IConstraintSearchParameters): IConstraintSearchConfig {
    return {
      /* eslint-disable @typescript-eslint/naming-convention */
      startTimeAfter: raw?.volume4d?.timeWindow?.timeBeginAfter?.toJSDate() || null,
      state_PROPOSED: raw.state.includes(ConstraintState.PROPOSED),
      state_ACCEPTED: raw.state.includes(ConstraintState.ACCEPTED),
      state_ACTIVE: raw.state.includes(ConstraintState.ACTIVE),
      state_ENDED: raw.state.includes(ConstraintState.ENDED),
      sort: raw.sort,
      sortIncreasing: raw.sortIncreasing
      /* eslint-enable @typescript-eslint/naming-convention */
    };

  }

  ngOnInit(): void {
    this.fg = this.formBuilder.group<IConstraintSearchConfig>({
      /* eslint-disable @typescript-eslint/naming-convention */
      sort: [[], []],
      sortIncreasing: [true, [Validators.required]],
      state_PROPOSED: [false, [Validators.required]],
      state_ACCEPTED: [false, [Validators.required]],
      state_ACTIVE: [false, [Validators.required]],
      state_ENDED: [false, [Validators.required]],
      startTimeAfter: [null, []]
      /* eslint-enable @typescript-eslint/naming-convention */
    });
    this.fg.patchValue(ConstraintSearchOptionsConfigComponent.toIConstraintSearchConfig(ConstraintSearchParameters.defaultSearch()));
    this.fgSub = this.fg.valueChanges.subscribe(() => this.emitUpdate());
  }

  ngOnDestroy(): void {
    if (this.fgSub && !this.fgSub.closed) {
      this.fgSub.unsubscribe();
    }
  }

  registerOnChange(fn: (value: ConstraintSearchParameters) => void): void {
    this.change = fn;
  }

  registerOnTouched(fn: () => void): void {
    // Method not implemented
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.fg.disable();
    } else {
      this.fg.enable();
    }
  }

  writeValue(obj: IConstraintSearchParameters): void {
    this.fg.setValue(ConstraintSearchOptionsConfigComponent.toIConstraintSearchConfig(obj), {emitEvent: false});
  }

  emitUpdate() {
    const update = this.getConfig();
    if (this.change) {
      this.change(update);
    }
  }

  getConfig(): IConstraintSearchParameters {
    return ConstraintSearchOptionsConfigComponent.toConstraintSearchParameters(this.fg.getRawValue());
  }

  unusedSortOptions() {
    return Object.keys(this.sortOptions).filter(k => !this.fg.controls.sort.value.includes(k));
  }

  addSortField(field: string) {
    const tmp = [...this.fg.controls.sort.value, field];
    this.fg.controls.sort.setValue(tmp);
  }

  removeSortField(oldField: string) {
    const tmp = this.fg.controls.sort.value.filter(f => f !== oldField);
    this.fg.controls.sort.setValue(tmp);
  }

  clearStartTime() {
    this.fg.controls.startTimeAfter.reset(null);
  }

  updateState(s: ConstraintState, $event: boolean) {
    this.states[s] = $event;
    this.emitUpdate();
  }

  getPrettyConstraintStateName(s: string) {
    const names = {
      /* eslint-disable @typescript-eslint/naming-convention */
      PROPOSED: 'Proposed',
      ACCEPTED: 'Accepted',
      ACTIVE: 'Active',
      ENDED: 'Closed'
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    return names[s];

  }

  onStateChange($event: Event) {
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IOperatorIntentEntry, IOperatorIntentResultResponse} from '../model/OperatorIntentEntry';

@Injectable({
  providedIn: 'root'
})
export class OperatorIntentService {

  constructor() { }
}
export interface OperatorIntentService {
  getOperatorIntent(intentId: string): Observable<IOperatorIntentEntry>;

  getOperatorIntentResult(intentId: string): Observable<IOperatorIntentResultResponse>;

  getOperatorIntentsByOpId(operationId: string): Observable<IOperatorIntentEntry[]>;
}

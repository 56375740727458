import {Injectable} from '@angular/core';
import {TelemetryStatusService} from "../telemetry-status.service";
import {DateTime} from "luxon";
import {Observable} from "rxjs";
import {ITelemetryStatus} from "../../model/telemetry-status";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {map} from "rxjs/operators";
import {Parser} from "../../model/utm/parser/OperationParser";
import {TransportTelemetryStatus} from "../../model/gen/transport/utm/model/telemetryStatus";

@Injectable({
  providedIn: 'root'
})
export class RestTelemetryStatusService extends TelemetryStatusService {
  constructor(private http: HttpClient) {
    super();
  }

  getTelemetryStatuses(registrationIds?: string[], updatedAfter?: DateTime): Observable<ITelemetryStatus[]> {
    return this.http.get(`${environment.baseUrl}/operator/telemetrystatus`, {
      params: {
        registrationIds: registrationIds?.join() || '',
        updatedAfter: updatedAfter?.toISO() || ''
      }
    }).pipe(map((response: any) => (response.items || []).map(Parser.parseTelemetryStatus)));
  }

  getTelemetryStatus(registrationId: string): Observable<ITelemetryStatus> {
    return this.http.get(`${environment.baseUrl}/operator/telemetrystatus/${registrationId}`)
      .pipe(map((response: TransportTelemetryStatus) => Parser.parseTelemetryStatus(response)));
  }
}

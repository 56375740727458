/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseModel} from './base-model';


export interface IPersonOrOrganization {
  cid: string;
  name: string;
  email_addresses: Array<string>;
  phone_numbers: Array<string>;
  comments?: string;
  title?: string;
}


export class PersonOrOrganization extends BaseModel implements IPersonOrOrganization {

  static CID_FIELD_NAME = 'cid';
  static NAME_FIELD_NAME = 'name';
  static EMAIL_ADDRESSES_FIELD_NAME = 'email_addresses';
  static PHONE_NUMBERS_FIELD_NAME = 'phone_numbers';
  static COMMENTS_FIELD_NAME = 'comments';
  static TITLE_FIELD_NAME = 'title';
  static ISPILOT_FIELD_NAME = 'isPilot';
  cid: string;

  /** The full official name of the Person, State, Organisation, Authority, aircraft operating agency, handling agency etc. [FIXM] */
  name: string;
  /** An array of email addresses. To establish best practices, the order of the email addresses in the array should indicate the order that they should be used.
   Note that we do not include a regular expression for email addresses. Such a RE is quite unweildy if it attempts to be complete.
   The responsibility is on the USS providing the email address to ensure it is valid and operational.
   Several sources on the Internet can be found discussing email REs.
   Limiting email length to 254 per well-supported discussion here:
   https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address */
  email_addresses: Array<string>;
  /** An array of phone numbers. To establish best practices, the order of the phone numbers in the array should indicate the order that they should be used. */
  phone_numbers: Array<string>;
  /** Any additional coments related to contact information. Optional. May not have a clean FIXM equivalent, but may be useful in some UTM contexts. */
  comments: string;
  title: string;
  isPilot: boolean;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IPersonOrOrganization>, useFormGroupValuesToModel = false) {
    super();
    this.email_addresses = [];
    this.phone_numbers = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IPersonOrOrganization>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.cid = this.getValue<string>(rawValues, PersonOrOrganization.CID_FIELD_NAME);
      this.name = this.getValue<string>(rawValues, PersonOrOrganization.NAME_FIELD_NAME);
      this.fillModelArray<string>(this, PersonOrOrganization.EMAIL_ADDRESSES_FIELD_NAME, rawValues.email_addresses, useFormGroupValuesToModel);
      this.fillModelArray<string>(this, PersonOrOrganization.PHONE_NUMBERS_FIELD_NAME, rawValues.phone_numbers, useFormGroupValuesToModel);
      this.comments = this.getValue<string>(rawValues, PersonOrOrganization.COMMENTS_FIELD_NAME);
      this.title = this.getValue<string>(rawValues, PersonOrOrganization.TITLE_FIELD_NAME);
      this.isPilot = this.getValue<boolean>(rawValues, PersonOrOrganization.ISPILOT_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        cid: new UntypedFormControl(this.cid, [Validators.required, Validators.minLength(1), ]),
        name: new UntypedFormControl(this.name, [Validators.required, Validators.minLength(1), Validators.maxLength(60),]),
        email_addresses: new UntypedFormArray([]),
        phone_numbers: new UntypedFormArray([]),
        comments: new UntypedFormControl(this.comments, [Validators.minLength(1), Validators.maxLength(1000),]),
        title: new UntypedFormControl(this.title, []),
        isPilot: new UntypedFormControl(this.isPilot, [Validators.required]),
      });
      // generate FormArray control elements
      this.fillFormArray<string>(PersonOrOrganization.EMAIL_ADDRESSES_FIELD_NAME, this.email_addresses);
      // generate FormArray control elements
      this.fillFormArray<string>(PersonOrOrganization.PHONE_NUMBERS_FIELD_NAME, this.phone_numbers);
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[PersonOrOrganization.CID_FIELD_NAME].setValue(this.cid);
    this.$formGroup.controls[PersonOrOrganization.NAME_FIELD_NAME].setValue(this.name);
    this.fillFormArray<string>(PersonOrOrganization.EMAIL_ADDRESSES_FIELD_NAME, this.email_addresses);
    this.fillFormArray<string>(PersonOrOrganization.PHONE_NUMBERS_FIELD_NAME, this.phone_numbers);
    this.$formGroup.controls[PersonOrOrganization.COMMENTS_FIELD_NAME].setValue(this.comments);
    this.$formGroup.controls[PersonOrOrganization.TITLE_FIELD_NAME].setValue(this.title);
    this.$formGroup.controls[PersonOrOrganization.ISPILOT_FIELD_NAME].setValue(this.isPilot);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

<div *ngIf="!!message" [ngSwitch]="msg.type">
  <div *ngSwitchCase="'OPERATOR_INTENT_RESULT'">
    <p *ngIf="!opIntentMsg.success"><strong>Failure Reason:</strong> {{opIntentMsg.failureReason || 'Not Available'}}</p>
    <ng-container *ngIf="msg.data.intentType.startsWith('OPERATION') && msg.data.entityId">
      <h4>Operation</h4>
      <clr-datagrid class="datagrid-compact">
        <clr-dg-column>Operation</clr-dg-column>
        <clr-dg-column></clr-dg-column>
        <clr-dg-row>
          <clr-dg-cell>{{opIntentMsg.entityName}}</clr-dg-cell>
          <clr-dg-cell>
            <a target="_blank" [routerLink]="'/fuss/operations/view-operation'" [queryParams]="{operationId: opIntentMsg.entityId}">
              <cds-icon shape="link"></cds-icon>
            </a>
          </clr-dg-cell>
          <clr-dg-row-detail *clrIfExpanded>
            <app-ax-operation-ext-summary [operationId]="opIntentMsg.entityId" style="width:100%;"></app-ax-operation-ext-summary>
          </clr-dg-row-detail>
        </clr-dg-row>
      </clr-datagrid>
    </ng-container>
    <ng-container *ngIf="msg.data.intentType.startsWith('CONSTRAINT') && msg.data.entityId">
      <h4>Constraint</h4>
      <clr-datagrid class="datagrid-compact">
        <clr-dg-column>Constraint</clr-dg-column>
        <clr-dg-column></clr-dg-column>
        <clr-dg-row>
          <clr-dg-cell>{{opIntentMsg.entityName}}</clr-dg-cell>
          <clr-dg-cell>
            <a target="_blank" [routerLink]="'/fuss/constraint/view-constraint'" [queryParams]="{constraintId: opIntentMsg.entityId}">
              <cds-icon shape="link"></cds-icon>
            </a>
          </clr-dg-cell>
          <clr-dg-row-detail *clrIfExpanded>
            <app-constraint-ext-summary [constraintId]="opIntentMsg.entityId" style="width:100%;"></app-constraint-ext-summary>
          </clr-dg-row-detail>
        </clr-dg-row>
      </clr-datagrid>
    </ng-container>
    <ng-container *ngIf="!!opIntentMsg.conflictingOperations.length">
      <h4>Conflicting Operations</h4>
      <clr-datagrid class="datagrid-compact">
        <clr-dg-column>Operation</clr-dg-column>
        <clr-dg-column></clr-dg-column>
        <clr-dg-row *ngFor="let operation of opIntentMsg.conflictingOperations" [clrDgItem]="opIntentMsg.conflictingOperations">
          <clr-dg-cell>{{operation}}</clr-dg-cell>
          <clr-dg-cell>
            <a target="_blank" [routerLink]="'/fuss/operations/view-operation'" [queryParams]="{operationId: operation}">
              <cds-icon shape="link"></cds-icon>
            </a>
          </clr-dg-cell>
          <clr-dg-row-detail *clrIfExpanded>
            <app-ax-operation-ext-summary [operationId]="operation" style="width:100%;"></app-ax-operation-ext-summary>
          </clr-dg-row-detail>
        </clr-dg-row>
      </clr-datagrid>
    </ng-container>
    <ng-container *ngIf="!!opIntentMsg.conflictingConstraints.length">
      <h4>Conflicting Constraints</h4>
      <clr-datagrid class="datagrid-compact">
        <clr-dg-column>Constraint</clr-dg-column>
        <clr-dg-column></clr-dg-column>
        <clr-dg-row *ngFor="let constraint of opIntentMsg.conflictingConstraints" [clrDgItem]="opIntentMsg.conflictingConstraints">
          <clr-dg-cell>{{constraint}}</clr-dg-cell>
          <clr-dg-cell>
            <a target="_blank" [routerLink]="'/fuss/constraint/view-constraint'" [queryParams]="{constraintId: constraint}">
              <cds-icon shape="link"></cds-icon>
            </a>
          </clr-dg-cell>
          <clr-dg-row-detail *clrIfExpanded>
            <app-constraint-ext-summary [constraintId]="constraint" style="width:100%;"></app-constraint-ext-summary>
          </clr-dg-row-detail>
        </clr-dg-row>
      </clr-datagrid>
    </ng-container>
  </div>

  <div *ngSwitchCase="'OPERATION_CONFLICT_ALERT'">
    <h4>Operation</h4>
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>Operation</clr-dg-column>
      <clr-dg-column></clr-dg-column>
      <clr-dg-row>
        <clr-dg-cell>{{opConflictMsg.operationId}}</clr-dg-cell>
        <clr-dg-cell>
          <a target="_blank" [routerLink]="'/fuss/operations/view-operation'" [queryParams]="{operationId: opConflictMsg.operationId}">
            <cds-icon shape="link"></cds-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          <app-ax-operation-ext-summary [operationId]="opConflictMsg.operationId" style="width:100%;"></app-ax-operation-ext-summary>
        </clr-dg-row-detail>
      </clr-dg-row>
    </clr-datagrid>
    <h4>Conflicting Operation</h4>
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>Operation</clr-dg-column>
      <clr-dg-column>State</clr-dg-column>
      <clr-dg-column>Priority</clr-dg-column>
      <clr-dg-column></clr-dg-column>
      <clr-dg-row>
        <clr-dg-cell>{{opConflictMsg.conflictingOperationId}}</clr-dg-cell>
        <clr-dg-cell>{{opConflictMsg.conflictingOperationState}}</clr-dg-cell>
        <clr-dg-cell>{{opConflictMsg.conflictingOperationPriority}}</clr-dg-cell>
        <clr-dg-cell>
          <a target="_blank" [routerLink]="'/fuss/operations/view-operation'" [queryParams]="{operationId: opConflictMsg.conflictingOperationId}">
            <cds-icon shape="link"></cds-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          <app-ax-operation-ext-summary [operationId]="opConflictMsg.conflictingOperationId" style="width:100%;"></app-ax-operation-ext-summary>
        </clr-dg-row-detail>
      </clr-dg-row>
    </clr-datagrid>
  </div>

  <div *ngSwitchCase="'CONFORMANCE_ALERT'">
    <ng-container [ngSwitch]="conformanceMsg.conformanceState">
      <h4 *ngSwitchCase="conformanceState.NONCONFORMING">Non-Conforming Operation</h4>
      <h4 *ngSwitchCase="conformanceState.CONTINGENT">Contingent Operation</h4>
      <h4 *ngSwitchDefault>Operation</h4>
    </ng-container>
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>Operation</clr-dg-column>
      <clr-dg-column></clr-dg-column>
      <clr-dg-row>
        <clr-dg-cell>{{conformanceMsg.entityName}}</clr-dg-cell>
        <clr-dg-cell>
          <a target="_blank" [routerLink]="'/fuss/operations/view-operation'" [queryParams]="{operationId: conformanceMsg.operationId}">
            <cds-icon shape="link"></cds-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          <app-ax-operation-ext-summary [operationId]="conformanceMsg.operationId" style="width:100%;"></app-ax-operation-ext-summary>
        </clr-dg-row-detail>
      </clr-dg-row>
    </clr-datagrid>

    <ng-container *ngIf="conformanceMsg.affectedOperations?.length">
      <h4>Affected Operations</h4>
      <clr-datagrid class="datagrid-compact">
        <clr-dg-column>Operation</clr-dg-column>
        <clr-dg-column></clr-dg-column>
        <clr-dg-row *ngFor="let affectedOperation of conformanceMsg.affectedOperations">
          <clr-dg-cell>{{affectedOperation.entityName}}</clr-dg-cell>
          <clr-dg-cell>
            <a target="_blank" [routerLink]="'/fuss/operations/view-operation'" [queryParams]="{operationId: affectedOperation.operationId}">
              <cds-icon shape="link"></cds-icon>
            </a>
          </clr-dg-cell>
          <clr-dg-row-detail *clrIfExpanded>
            <app-ax-operation-ext-summary [operationId]="affectedOperation.operationId" style="width:100%;"></app-ax-operation-ext-summary>
          </clr-dg-row-detail>
        </clr-dg-row>
      </clr-datagrid>
    </ng-container>

    <ng-container *ngIf="conformanceMsg.conformanceState !== 'CONFORMING' && (!!conformanceMsg.volumeViolations?.length)">
      <h4>Volume Violations</h4>
      <ul>
        <li *ngFor="let violation of conformanceMsg.volumeViolations">{{violation}}</li>
      </ul>
    </ng-container>
  </div>

  <div *ngSwitchCase="'CONSTRAINT_ALERT'">
    <h4>Operation</h4>
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>Operation</clr-dg-column>
      <clr-dg-column></clr-dg-column>
      <clr-dg-row>
        <clr-dg-cell>{{constraintAlertMsg.operationId}}</clr-dg-cell>
        <clr-dg-cell>
          <a target="_blank" [routerLink]="'/fuss/operations/view-operation'" [queryParams]="{operationId: constraintAlertMsg.operationId}">
            <cds-icon shape="link"></cds-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          <app-ax-operation-ext-summary [operationId]="constraintAlertMsg.operationId" style="width:100%;"></app-ax-operation-ext-summary>
        </clr-dg-row-detail>
      </clr-dg-row>
    </clr-datagrid>
    <h4>{{constraintAlertMsg.constraintTypePermitted ? '' : 'Conflicting '}}Constraint</h4>
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>Constraint</clr-dg-column>
      <clr-dg-column>Type</clr-dg-column>
      <clr-dg-column>Type Permitted</clr-dg-column>
      <clr-dg-column>Advisory</clr-dg-column>
      <clr-dg-column></clr-dg-column>
      <clr-dg-row>
        <clr-dg-cell>{{constraintAlertMsg.constraintId}}</clr-dg-cell>
        <clr-dg-cell>{{constraintAlertMsg.constraintType || 'Not Available'}}</clr-dg-cell>
        <clr-dg-cell>{{constraintAlertMsg.constraintTypePermitted ? 'Yes' : 'No'}}</clr-dg-cell>
        <clr-dg-cell>{{constraintAlertMsg.advisoryConstraint ? 'Yes' : 'No'}}</clr-dg-cell>
        <clr-dg-cell>
          <a target="_blank" [routerLink]="'/fuss/constraint/view-constraint'" [queryParams]="{constraintId: constraintAlertMsg.constraintId}">
            <cds-icon shape="link"></cds-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          <app-constraint-ext-summary [constraintId]="constraintAlertMsg.constraintId" style="width:100%;"></app-constraint-ext-summary>
        </clr-dg-row-detail>
      </clr-dg-row>
    </clr-datagrid>
  </div>

  <div *ngSwitchCase="'USER_DIRECT'" class="user-direct-message">
    <h6>Message:</h6>
    <p>{{userDirectMsg.text}}</p>
  </div>

  <div *ngSwitchCase="'APPROVAL_ALERT'">
    <p *ngIf="approvalMsg.approval.approvalStatus === REJECTED"><strong>Denial Reason:</strong> {{approvalMsg.approval.approvalMessage || 'Not Available'}}</p>
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>Operation</clr-dg-column>
      <clr-dg-column></clr-dg-column>
      <clr-dg-row>
        <clr-dg-cell>{{approvalMsg.approval.operation.flight_number}}</clr-dg-cell>
        <clr-dg-cell>
          <a target="_blank" [routerLink]="'/fuss/operations/view-operation'" [queryParams]="{operationId: approvalMsg.approval.entityId}">
            <cds-icon shape="link"></cds-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          <app-ax-operation-ext-summary [operationId]="approvalMsg.approval.entityId" style="width:100%;"></app-ax-operation-ext-summary>
        </clr-dg-row-detail>
      </clr-dg-row>
    </clr-datagrid>
  </div>

  <div *ngSwitchCase="'OPERATION_DRAFT_MESSAGE'">
    <div *ngIf="opDraftMsg.success; then successBlock; else failBlock"></div>
    <ng-template #successBlock>
      <p *ngIf="opDraftMsg.draftType === 'OPERATION_CREATE_DRAFT'">Operation draft created successfully.</p>
      <p *ngIf="opDraftMsg.draftType === 'OPERATION_MODIFY_DRAFT'">Operation draft updated successfully.</p>
    </ng-template>
    <ng-template #failBlock>
      <p>Operation draft could not be saved.</p>
    </ng-template>
  </div>

</div>

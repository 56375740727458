import {IUserMessageData, UserMessageData} from './UserMessageData';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export interface IConstraintAlertMessage extends IUserMessageData {
  constraintId: string;
  operationId: string;
  constraintType: string;
  constraintTypePermitted: boolean;
  advisoryConstraint: boolean;
  constraintClosed: boolean;
  replanRequired: boolean;
  replanForbidden: boolean;
  permittedConstraintTypes: string[];
}

export class ConstraintAlertMessage extends UserMessageData implements IConstraintAlertMessage {
  static CONSTRAINT_ID_FIELD_NAME = 'constraintId';
  static OPERATION_ID_FIELD_NAME = 'operationId';
  static CONSTRAINT_TYPE_FIELD_NAME = 'constraintType';
  static CONSTRAINT_TYPE_PERMITTED_FIELD_NAME = 'constraintTypePermitted';
  static ADVISORY_CONSTRAINT_FIELD_NAME = 'advisoryConstraint';
  static CONSTRAINT_CLOSED_FIELD_NAME = 'constraintClosed';
  static REPLAN_REQUIRED_FIELD_NAME = 'replanRequired';
  static REPLAN_FORBIDDEN_FIELD_NAME = 'replanForbidden';
  static PERMITTED_CONSTRAINT_TYPES_FIELD_NAME = 'permittedConstraintTypes';

  constraintId: string;
  operationId: string;
  constraintType: string;
  constraintTypePermitted: boolean;
  advisoryConstraint: boolean;
  constraintClosed: boolean;
  replanRequired: boolean;
  replanForbidden: boolean;
  permittedConstraintTypes: string[];

  constructor(values?: Partial<IConstraintAlertMessage>, useFormGroupValuesToModel = false) {
    super();

    this.permittedConstraintTypes  = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IUserMessageData>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.constraintId = this.getValue<string>(rawValues, ConstraintAlertMessage.CONSTRAINT_ID_FIELD_NAME);
      this.operationId = this.getValue<string>(rawValues, ConstraintAlertMessage.OPERATION_ID_FIELD_NAME);
      this.constraintType = this.getValue<string>(rawValues, ConstraintAlertMessage.CONSTRAINT_TYPE_FIELD_NAME);
      this.constraintTypePermitted = this.getValue<boolean>(rawValues, ConstraintAlertMessage.CONSTRAINT_TYPE_PERMITTED_FIELD_NAME);
      this.advisoryConstraint = this.getValue<boolean>(rawValues, ConstraintAlertMessage.ADVISORY_CONSTRAINT_FIELD_NAME);
      this.constraintClosed = this.getValue<boolean>(rawValues, ConstraintAlertMessage.CONSTRAINT_CLOSED_FIELD_NAME);
      this.replanRequired = this.getValue<boolean>(rawValues, ConstraintAlertMessage.REPLAN_REQUIRED_FIELD_NAME);
      this.replanForbidden = this.getValue<boolean>(rawValues, ConstraintAlertMessage.REPLAN_FORBIDDEN_FIELD_NAME);
      this.fillModelArray<string>(this, ConstraintAlertMessage.PERMITTED_CONSTRAINT_TYPES_FIELD_NAME, rawValues.permittedConstraintTypes, useFormGroupValuesToModel);

      super.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[ConstraintAlertMessage.CONSTRAINT_ID_FIELD_NAME].setValue(this.constraintId);
    this.$formGroup.controls[ConstraintAlertMessage.OPERATION_ID_FIELD_NAME].setValue(this.operationId);
    this.$formGroup.controls[ConstraintAlertMessage.CONSTRAINT_TYPE_FIELD_NAME].setValue(this.constraintType);
    this.$formGroup.controls[ConstraintAlertMessage.CONSTRAINT_TYPE_PERMITTED_FIELD_NAME].setValue(this.constraintTypePermitted);
    this.$formGroup.controls[ConstraintAlertMessage.ADVISORY_CONSTRAINT_FIELD_NAME].setValue(this.advisoryConstraint);
    this.$formGroup.controls[ConstraintAlertMessage.CONSTRAINT_CLOSED_FIELD_NAME].setValue(this.constraintClosed);
    this.$formGroup.controls[ConstraintAlertMessage.REPLAN_REQUIRED_FIELD_NAME].setValue(this.replanRequired);
    this.$formGroup.controls[ConstraintAlertMessage.REPLAN_FORBIDDEN_FIELD_NAME].setValue(this.replanForbidden);
    this.fillFormArray<string>(ConstraintAlertMessage.PERMITTED_CONSTRAINT_TYPES_FIELD_NAME, this.permittedConstraintTypes);

    super.setFormGroupValues();
  }

  getState(): string {
    return '';
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = super.getFormGroup();
      this._formGroup.addControl('constraintId', new UntypedFormControl(this.constraintId, [Validators.required]));
      this._formGroup.addControl('operationId', new UntypedFormControl(this.operationId, [Validators.required]));
      this._formGroup.addControl('constraintType', new UntypedFormControl(this.constraintType, [Validators.required]));
      this._formGroup.addControl('constraintTypePermitted', new UntypedFormControl(this.constraintTypePermitted, [Validators.required]));
      this._formGroup.addControl('advisoryConstraint', new UntypedFormControl(this.advisoryConstraint, [Validators.required]));
      this._formGroup.addControl('constraintClosed', new UntypedFormControl(this.constraintClosed, [Validators.required]));
      this._formGroup.addControl('replanRequired', new UntypedFormControl(this.replanRequired, [Validators.required]));
      this._formGroup.addControl('replanForbidden', new UntypedFormControl(this.replanForbidden, [Validators.required]));
      this._formGroup.addControl('permittedConstraintTypes', new UntypedFormArray([], [Validators.required]));

      this.fillFormArray<string>(ConstraintAlertMessage.PERMITTED_CONSTRAINT_TYPES_FIELD_NAME, this.permittedConstraintTypes);
    }

    return this._formGroup;
  }

}

<div class="clr-row" *ngIf="user">
  <div class="clr-col-md-6">
    <h2 cds-text="section">General Information</h2>
    <table class="table table-vertical" aria-description="General user information">
      <tbody>
        <tr>
          <th scope="row">Title</th>
          <td>{{user.contact.title}}</td>
        </tr>
        <tr>
          <th scope="row">Name</th>
          <td>{{user.contact.getName()}}</td>
        </tr>
        <tr>
          <th scope="row">Email</th>
          <td>{{user.email}}</td>
        </tr>
        <tr>
          <th scope="row">Roles</th>
          <td>
            <ul>
              <li *ngFor="let role of user.roles">
                {{prettyRoleName(role)}}
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th scope="row">Organization</th>
          <td>{{user.getPrettyOrganizationName()}}</td>
        </tr>
        <tr>
          <th scope="row">Division/Unit</th>
          <td>{{user.contact.division}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="clr-col-md-6" *ngIf="user.contact">
    <h2 cds-text="section">Part 107 Information</h2>
    <table class="table table-vertical" aria-label="Part 107 information">
      <tbody>
      <tr>
        <th scope="row">Certified</th>
        <td>
          <ng-container *ngIf="user.contact.hasPart107">Yes</ng-container>
          <ng-container *ngIf="!user.contact.hasPart107">No</ng-container>
        </td>
      </tr>
    </table>
    <h2 cds-text="section">Contact Information</h2>
    <table class="table table-vertical" aria-label="Contact information">
      <tbody>
        <tr>
          <th scope="row">Email Addresses</th>
          <td>
            <ul>
              <li *ngFor="let email of user.contact.emails">
                {{email}}
              </li>
            </ul>
          </td>
        </tr>
        <tr>
          <th scope="row">Phone Numbers</th>
          <td>
            <ng-container *ngIf="!user.contact.phones?.length">Not Available</ng-container>
            <ul>
              <li *ngFor="let phone of user.contact.phones">
                {{phone}}
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

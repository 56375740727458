import {LatLngPoint} from '../model/WaypointParser';
import {
  Altitude,
  IPolygon,
  OperationVolume,
  Polygon as RichPolygon,
  units_of_measure,
  vertical_reference,
  volume_type
} from '../model/gen/utm';
import {bezierSpline, distance, featureCollection, lineString, point, polygon, simplify} from '@turf/turf';
import {Feature, LineString, Point} from 'geojson';
import buffer from '@turf/buffer';
import {DateTime} from 'luxon';
import {AltitudeRange} from '../model/gen/utm/altitude-range-model';
import {TimeRange} from '../model/TimeRange';
import union from '@turf/union';
import midpoint from '@turf/midpoint';

export const GeometricStuff = {
  DEFAULT_OPERATION_VOLUME_HORIZONTAL_BUFFER_DISTANCE_METERS: 25,
  DEFAULT_OPERATION_VOLUME_VERTICAL_BUFFER_DISTANCE_METERS: 10,
  EARTH_RADIUS_FT: 20902230.971129,
  DEFAULT_OPERATION_VOLUME_STEPS: 4,
};
const maxOpVolSpatialDimensionFt = 6000 - (GeometricStuff.DEFAULT_OPERATION_VOLUME_HORIZONTAL_BUFFER_DISTANCE_METERS * 3.28084);

export const Utm = {
  maxAltitude: 100000,
  minAltitude: -8000,

  // We do this because of the edge case where: maxSpatialDimension == 6000, defaultBuffer = 50, and someone has way
  // points with a distance of 5975. After buffering, it would be 6025. This wouldn't be split, but after buffering,
  // would violate UTM spec. Therefore, we should take it into account
  maxOpVolSpatialDimensionFt
}

type BaseFlightPlanComponent = {};

export type SegmentComponent = {
  kind: 'segment',
  index: number,
  a: LatLngPoint,
  b: LatLngPoint
};

export type LoiterComponent = {
  kind: 'loiter',
  index: number,
  center: LatLngPoint,
  radius: number
};

export type GroupComponent = {
  kind: 'group',
  index: number,
  items: FlightPlanComponent[],
};

export type PolygonComponent = {
  kind: 'polygon',
  index: number,
  points: LatLngPoint[],
  minAltitude: number,
  maxAltitude: number
};

export type SplineComponent = {
  kind: 'spline',
  index: number,
  points: LatLngPoint[]
};


export type FlightPlanComponent =
  SegmentComponent
  | LoiterComponent
  | PolygonComponent
  | GroupComponent
  | SplineComponent;

function degrees_to_radians(degrees) {
  return degrees * (Math.PI / 180);
}

function haversine(val: number): number {
  return Math.pow(Math.sin(val / 2), 2);
}

function distanceFt(p1: LatLngPoint, p2: LatLngPoint) {
  const startLat = p1.lat;
  const startLong = p1.lng;
  const endLat = p2.lat;
  const endLong = p2.lng;

  return distance([startLong, startLat], [endLong, endLat], {units: 'feet'});
}


function midPoint(pointA: LatLngPoint, pointB: LatLngPoint): LatLngPoint {


  const lat1 = pointA.lat;
  const lon1 = pointA.lng;
  const lat2 = pointB.lat;
  const lon2 = pointB.lng;


  const m = midpoint([lon1, lat1], [lon2, lat2]);

  return {
    lat: m.geometry.coordinates[1],
    lng: m.geometry.coordinates[0],
    alt: (pointA.alt + pointB.alt) / 2
  };
}


function latLngPointToPoint(pt: LatLngPoint): [number, number] {
  return [pt.lng, pt.lat];
}


function generateSplineVolume(component: SplineComponent, options: FlightPlanComponentOptions) {

  const currentSettings = options;
  const points = [];
  let minAlt = Number.MAX_VALUE;
  let maxAlt = Number.MIN_VALUE;

  for (const point of component.points) {
    points.push(latLngPointToPoint(point));
    minAlt = Math.min(minAlt, point.alt);
    maxAlt = Math.max(maxAlt, point.alt);
  }
  const feature = lineString(points);
  const splined = bezierSpline(feature);
  return generateBufferedVolume(currentSettings, splined, options, minAlt, maxAlt);

}

function generateBufferedVolume(currentSettings: FlightPlanComponentOptions, feature: Feature<Point, any> | Feature<LineString, any>, options: FlightPlanComponentOptions, minAlt: number, maxAlt: number) {
  const bufferDistance = currentSettings.bufferUnits === units_of_measure.M ? currentSettings.bufferDistance : currentSettings.bufferDistance * 3.28084;
  const poly = buffer(feature, bufferDistance, {
    units: 'meters',
    steps: currentSettings.steps
  });


  const geometry = new RichPolygon(poly.geometry as unknown as IPolygon);
  geometry.coordinates.splice(1)


  const timeRange = new TimeRange(
    options.startTime,
    options.endTime
  );

  const scale = options.verticalUnits === units_of_measure.M ? 1 : 3.28084;

  const altitudeRange = new AltitudeRange({
      min_altitude: minAlt - options.verticalBufferMeters * scale,
      max_altitude: maxAlt + options.verticalBufferMeters * scale,
      altitude_vertical_reference: options.verticalReference,
      altitude_units: options.verticalUnits
    }
  );
  const volume = new OperationVolume({
    volume_type: volume_type.TBOV,
    effective_time_begin: options.startTime,
    effective_time_end: options.endTime,
    min_altitude: altitudeRange.getMinAlt(),
    max_altitude: altitudeRange.getMaxAlt(),
    geography: geometry,
    beyond_visual_line_of_sight: false,
  });

  return [volume];
}

function generateSegmentVolumes(segment: SegmentComponent, options: FlightPlanComponentOptions): OperationVolume[] {
  const minAlt = Math.min(segment.a.alt, segment.b.alt);
  const maxAlt = Math.max(segment.a.alt, segment.b.alt);

  const distance = distanceFt(segment.a, segment.b);
  if (distance > maxOpVolSpatialDimensionFt) {
    const midpoint = midPoint(
      segment.a,
      segment.b
    );

    return [].concat(generateSegmentVolumes({
      a: {
        alt: minAlt,
        ...segment.a
      }, b: {
        alt: maxAlt,
        ...midpoint
      }, index: 0, kind: 'segment'

    }, options)).concat(generateSegmentVolumes({
      a: {
        alt: minAlt,
        ...midpoint
      }, b: {
        alt: maxAlt,
        ...segment.b
      }, index: 0, kind: 'segment'

    }, options));
  }
  const currentSettings = options;
  const feature = distance === 0 ?
    point(latLngPointToPoint(segment.a))
    : lineString([
      latLngPointToPoint(segment.a),
      latLngPointToPoint(segment.b)
    ]);
  return generateBufferedVolume(currentSettings, feature, options, minAlt, maxAlt);

}

export type FlightPlanComponentOptions = {
  verticalBufferMeters: number;
  verticalUnits: units_of_measure;
  verticalReference: vertical_reference;
  bufferUnits: units_of_measure;
  bufferDistance: number,
  steps: number,
  startTime: DateTime,
  endTime: DateTime
};

function generateLoiterVolume(component: LoiterComponent, options: FlightPlanComponentOptions) {
  const segment: SegmentComponent = {
    a: {
      alt: component.center.alt,
      ...component.center
    },
    b: {
      alt: component.center.alt,
      ...component.center
    },
    index: component.index,
    kind: 'segment'
  };
  return generateSegmentVolumes(segment, {
    ...options,
    bufferDistance: Math.abs(component.radius) + (options.bufferDistance * (options.bufferUnits === units_of_measure.M ? 1 : 3.28084)),
    bufferUnits: units_of_measure.M
  });
}

function generateGroupVolume(component: GroupComponent, options: FlightPlanComponentOptions) {
  let results: OperationVolume[] = [];
  for (const segment of component.items) {
    results = results.concat(generateFlightPlanComponentVolumes(segment, options));
  }

  const poly = results.map((vol) => polygon(vol.geography.coordinates));

  // const ret = union(...poly);
  const ret = union(featureCollection(poly));
  if (ret.geometry.type !== 'Polygon') {
    return [];
  }
  if (ret.geometry.coordinates.length > 1) {
    ret.geometry.coordinates.splice(1);
  }

  type mins = {
    min_altitude?: number,
    max_altitude?: number,
    startTime?: DateTime,
    endTime?: DateTime
  };
  const minMax: mins = results.reduce((acc: mins, vol) => {
    if (acc.min_altitude === undefined || vol.min_altitude.altitude_value < acc.min_altitude) {
      acc.min_altitude = vol.min_altitude.altitude_value;
    }
    if (acc.max_altitude === undefined || vol.max_altitude.altitude_value > acc.max_altitude) {
      acc.max_altitude = vol.max_altitude.altitude_value;
    }
    if (acc.startTime === undefined || vol.effective_time_begin < acc.startTime) {
      acc.startTime = vol.effective_time_begin;
    }
    if (acc.endTime === undefined || vol.effective_time_end > acc.endTime) {
      acc.endTime = vol.effective_time_end;
    }
    return acc;
  }, {});

  if (minMax.min_altitude === undefined || minMax.max_altitude === undefined) {
    return [];
  }
  const minAltitude = new Altitude({
    altitude_value: minMax.min_altitude!,
    vertical_reference: options.verticalReference,
    units_of_measure: options.verticalUnits,
  });
  const maxAltitude = new Altitude({
    altitude_value: minMax.max_altitude!,
    vertical_reference: options.verticalReference,
    units_of_measure: options.verticalUnits,
  });

  return [
    new OperationVolume({
      volume_type: volume_type.ABOV,
      effective_time_begin: minMax.startTime || options.startTime,
      effective_time_end: minMax.endTime || options.endTime,

      min_altitude: minAltitude,
      max_altitude: maxAltitude,
      geography: new RichPolygon(ret.geometry as unknown as IPolygon),
      beyond_visual_line_of_sight: false,
    })
  ];

  // return [];
}

function generatePolygonVolume(component: PolygonComponent, options: FlightPlanComponentOptions): OperationVolume[] {
  const scale = options.verticalUnits === units_of_measure.M ? 1 : 3.28084;

  const altitudeRange = new AltitudeRange({
      min_altitude: component.minAltitude - options.verticalBufferMeters * scale,
      max_altitude: component.maxAltitude + options.verticalBufferMeters * scale,
      altitude_vertical_reference: options.verticalReference,
      altitude_units: options.verticalUnits
    }
  );
  return [new OperationVolume({
    volume_type: volume_type.ABOV,
    effective_time_begin: options.startTime,
    effective_time_end: options.endTime,
    min_altitude: altitudeRange.getMinAlt(),
    max_altitude: altitudeRange.getMaxAlt(),
    geography: new RichPolygon({
      coordinates: [component.points.map((pt) => [pt.lng, pt.lat])],
    }),
    beyond_visual_line_of_sight: false,
  })];
}

function simplifyVolumes(volumes: OperationVolume[]) {
  for (const volume of volumes) {
    if (!volume.geography) {
      continue;
    }
    const poly = polygon(volume.geography.coordinates);
    const coords = simplify(poly, {tolerance: 0.000001});
    volume.geography.coordinates = coords.geometry.coordinates;

  }


}

export function generateFlightPlanComponentVolumes(component: FlightPlanComponent, options: FlightPlanComponentOptions): OperationVolume[] {

  let volumes: OperationVolume[] = [];

  switch (component.kind) {
    case 'segment':
      volumes = generateSegmentVolumes(component, options);
      break;
    case 'spline':
      volumes = generateSplineVolume(component, options);
      break;
    case 'group':
      volumes = generateGroupVolume(component, options);
      break;
    case 'loiter':
      volumes = generateLoiterVolume(component, options);
      break;
    case 'polygon':
      volumes = generatePolygonVolume(component, options);
      break;
    default:
      console.error('component type not supported');
      volumes = [];
      break;
  }

  simplifyVolumes(volumes);
  return volumes;
}

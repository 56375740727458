import {Component, forwardRef, OnInit} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {LatLngPoint} from '@ax/ax-angular-map-common';
import {debounceTime} from 'rxjs/operators';

@Component({
  selector: 'ax-latlngpoint-input',
  templateUrl: './lat-lng-point-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LatLngPointInputComponent),
    }
  ]
})
export class LatLngPointInputComponent implements OnInit, ControlValueAccessor {
  fg: UntypedFormGroup;
  private onChange: (LatLngPoint) => void;
  private isDisabled: boolean;

  constructor() {
    this.ngOnInit();
  }

  ngOnInit(): void {
    this.fg = new UntypedFormGroup({
      latitude: new UntypedFormControl(null, [Validators.required]),
      longitude: new UntypedFormControl(null, [Validators.required]),
    });
    this.fg.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.sendUpdates();
    });
  }

  registerOnChange(fn: (LatLngPoint) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: LatLngPoint): void {
    let lat: number = null;
    let lon: number = null;
    if (obj) {
      lon = obj.lng;
      lat = obj.lat;
    }
    this.fg.setValue({
      latitude: lat || null,
      longitude: lon || null
    }, {emitEvent: false});
  }

  private sendUpdates() {
    if (!this.fg.valid){
      if (this.onChange) {
        this.onChange(null);
      }
      return;
    }
    const update = {lat: this.fg.controls.latitude.value, lng: this.fg.controls.longitude.value} as LatLngPoint;
    if (this.onChange) {
      this.onChange(update);
    }
  }
}

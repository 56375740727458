import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {OperationExt} from '../../../model/utm/OperationExt';
import {state} from '../../../model/gen/utm';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ColorId, ColorService} from '../../../services/color.service';
import {OperationService} from '../../../services/operation.service';
import {SmartChanges} from '../../../../../typing';

@Component({
  selector: 'app-operation-state-badge',
  templateUrl: './operation-state-badge.component.html',
  styleUrls: ['./operation-state-badge.component.scss']
})
export class OperationStateBadgeComponent implements OnChanges {

  @Input() operation: OperationExt;
  @Input() watchState = true;
  @Input() state: state;

  stateEnum = state;
  styles: {};
  currentStyle: any;

  private stateSubject: BehaviorSubject<state>;
  private watchStateSub: Subscription;

  constructor(private operationService: OperationService, private colorService: ColorService) {
    this.styles = {};
    this.styles[state.PROPOSED] = undefined;
    this.styles[state.ACCEPTED] = undefined;
    this.styles[state.ACTIVATED] = undefined;
    this.styles[state.CLOSED] = undefined;
    this.styles[state.NONCONFORMING] = undefined;
    this.styles[state.ROGUE] = undefined;


    this.stateSubject = new BehaviorSubject<state>(state.ACCEPTED);

    this.stateSubject.subscribe(s => {
      if (s === state.CLOSED) {
        this.clearWatchStateSub();
      }
      if (!this.styles[s]) {
        let colorId: ColorId = 'operation';
        switch (s) {
          case state.PROPOSED:
            colorId = 'operation-proposed';
            break;
          case state.ACCEPTED:
            colorId = 'operation-accepted';
            break;
          case state.ACTIVATED:
            colorId = 'operation-active';
            break;
          case state.CLOSED:
            colorId = 'operation-closed';
            break;
          case state.NONCONFORMING:
            colorId = 'operation-non-conforming';
            break;
          case state.ROGUE:
            colorId = 'operation-contingent';
            break;
          case state.REJECTED:
            colorId = 'operation-rejected';
            break;
        }

        this.colorService.getColorForId(colorId, true).subscribe(color => {
          this.styles[s] = {
            'background-color': color.fill.clone().brighten().lighten().toHexString(), // '#ffffff',
            color: color.fill.clone().darken().toHexString(),
            border: `${color.fill.toHexString()} .05rem solid`,
            // 'border-color': color.fill.toHexString(),
            'font-size': '13px'
          };
          this.state = s;
        });
      } else {
        this.state = s;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.smartChanges(changes as SmartChanges<this>);
  }

  smartChanges(changes: SmartChanges<this>): void {
    if (changes.operation) {
      this.stateSubject.next(this.operation.state);
    }
    if (changes.state){
      this.stateSubject.next(this.state);
    }
    this.watchOperationState();

  }

  private watchOperationState() {
    this.clearWatchStateSub();
    if (this.watchState && this.operation) {
      this.watchStateSub = this.operationService.watchOperationState(this.operation.operationId).subscribe(this.stateSubject);
    }
  }

  private clearWatchStateSub() {
    this.watchStateSub?.unsubscribe();
  }
}

import {IUserMessageData, UserMessageData} from './UserMessageData';
import {BaseModel} from '../gen/utm/base-model';
import {FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {FormControlify} from '../../utils/forms';

export interface IUserDirectMessage extends IUserMessageData {
  sentByUserId: string;
  text: string;
  entityId: string;
  from: IFromUser;
}

export class UserDirectMessage extends UserMessageData implements IUserDirectMessage {
  static SENT_BY_USER_ID_FIELD_NAME = 'sentByUserId';
  static TEXT_FIELD_NAME = 'text';
  static ENTITY_ID_FIELD_NAME = 'entityId';
  static FROM_FIELD_NAME = 'from';

  sentByUserId: string;
  text: string;
  entityId: string;
  from: FromUser;

  constructor(values?: Partial<IUserDirectMessage>, useFormGroupValuesToModel = false) {
    super();
    this.from = new FromUser();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IUserMessageData>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.sentByUserId = this.getValue<string>(rawValues, UserDirectMessage.SENT_BY_USER_ID_FIELD_NAME);
      this.text = this.getValue<string>(rawValues, UserDirectMessage.TEXT_FIELD_NAME);
      this.entityId = this.getValue<string>(rawValues, UserDirectMessage.ENTITY_ID_FIELD_NAME);
      this.from.setValues(rawValues.from, useFormGroupValuesToModel);

      super.setValues(values, useFormGroupValuesToModel);
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  setFormGroupValues() {
    this.$formGroup.controls[UserDirectMessage.SENT_BY_USER_ID_FIELD_NAME].setValue(this.sentByUserId);
    this.$formGroup.controls[UserDirectMessage.TEXT_FIELD_NAME].setValue(this.text);
    this.$formGroup.controls[UserDirectMessage.ENTITY_ID_FIELD_NAME].setValue(this.entityId);
    this.from.setFormGroupValues();

    super.setFormGroupValues();
    super.setFormGroupValuesInAddedFormControls();
  }

  protected  getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      super.getFormGroup();
      this._formGroup.addControl(UserDirectMessage.SENT_BY_USER_ID_FIELD_NAME, new UntypedFormControl(this.sentByUserId, [Validators.required]));
      this._formGroup.addControl(UserDirectMessage.TEXT_FIELD_NAME, new UntypedFormControl(this.text, [Validators.required]));
      this._formGroup.addControl(UserDirectMessage.ENTITY_ID_FIELD_NAME, new UntypedFormControl(this.entityId, [Validators.required]));
      this._formGroup.addControl(UserDirectMessage.FROM_FIELD_NAME, this.from.$formGroup);
    }
    return this._formGroup;
  }
}

export interface IFromUser {
  userId: string;
  name: string;
}

export class FromUser extends BaseModel implements IFromUser {
  static USER_ID_FIELD_NAME = 'userId';
  static NAME_FIELD_NAME = 'name';

  userId: string;
  name: string;

  constructor(values?: Partial<IFromUser>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IFromUser>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.userId = this.getValue<string>(rawValues, FromUser.USER_ID_FIELD_NAME);
      this.name = this.getValue<string>(rawValues, FromUser.NAME_FIELD_NAME);
    }
  }

  setFormGroupValues() {
    this.$formGroup.controls[FromUser.USER_ID_FIELD_NAME].setValue(this.userId);
    this.$formGroup.controls[FromUser.NAME_FIELD_NAME].setValue(this.name);
  }

  protected getFormGroup(): FormGroup<FormControlify<IFromUser>> {
    if (!this._formGroup) {
      this._formGroup = new FormGroup<FormControlify<IFromUser>>({
        userId: new FormControl<string>(this.userId, [Validators.required]),
        name: new FormControl<string>(this.name, [Validators.required])
      });
    }
    return this._formGroup;
  }
}

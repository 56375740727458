import {IStandardResponse, StandardResponse} from './StandardResponse';
import {Contact, IContact} from './Contact';

export interface IContactResponse extends IStandardResponse {
  contact: IContact;
}

export class ContactResponse extends StandardResponse {
  contact: Contact;

  constructor(contactResponse: IContactResponse) {
    super(contactResponse);
    if (contactResponse.contact) {
      this.contact = new Contact(contactResponse.contact);
    }
  }

}

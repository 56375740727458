import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {CurrentUserService} from '../../../shared/services/current-user.service';
import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {ErrorService} from '../../../shared/services/error.service';
import {ClrDatagrid, ClrDatagridColumn, ClrDatagridSortOrder, ClrDatagridStateInterface} from '@clr/angular';
import {SearchResult} from '../../../shared/model/SearchResult';
import {
  IPlatformSearch,
  PlatformSearch,
  PlatformSearchFieldUtil,
  PlatformSearchSortField,
  PlatformService
} from '../../../shared/services/platform.service';
import {UasRegistrationInfo} from '../../../shared/model/UasRegistrationInfo';
import {ManagedSearchComponent} from '../../../shared/utils/ManagedSearchComponent';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {debounceTime, map, skip, switchMap, take} from 'rxjs/operators';
import {ISubmissionStatus, SubmissionState} from '../../../shared/model/SubmissionStatus';
import {HttpErrorResponse} from '@angular/common/http';
import {isInteger, isNil} from "lodash";
import {ResponsiveScreenService} from '../../../shared/services/responsive-screen.service';

@Component({
  selector: 'app-platforms',
  templateUrl: './platforms.component.html',
  styleUrls: ['./platforms.component.scss']
})
export class PlatformsComponent extends ManagedSearchComponent<IPlatformSearch> implements OnInit, AfterViewInit, OnDestroy {
  // @ViewChild('searchConfigComponent') platformSearchOptionsConfigComponent: PlatformSearchOptionsConfigComponent;
  @ViewChildren(ClrDatagridColumn) columns: QueryList<ClrDatagridColumn>;
  @ViewChildren(ClrDatagrid) dg: QueryList<ClrDatagrid>;
  totalItems: number;
  currentPageSize = 10;
  currentPage = 1;
  pageSizeOptions = [5,10,20,50,100];
  loading = false;
  platforms: UasRegistrationInfo[] = [];
  errorMessage: string;
  showErrorMessage: boolean;
  sortDirections: { [key: string]: ClrDatagridSortOrder } = {};
  displayArchiveModal = false;
  requestStatus: ISubmissionStatus;
  requestStateEnum = SubmissionState;

  deviceSize$ = inject(ResponsiveScreenService).deviceSize$;

  private stateSubject: ReplaySubject<ClrDatagridStateInterface> = new ReplaySubject<ClrDatagridStateInterface>(1);
  private state: ClrDatagridStateInterface;
  private archiveSub: Subscription;
  private unArchiveSub: Subscription;
  private errorSub: Subscription;
  private initialSearchConfigSub: Subscription;
  private searchConfigSub: Subscription;
  private stateSub: Subscription;
  private refreshReady = false;

  constructor(private platformService: PlatformService,
              private cuService: CurrentUserService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private errorService: ErrorService,
              private cdrf: ChangeDetectorRef
  ) {
    super(PlatformSearch.defaultSearch(), router, activatedRoute);

    this.errorSub = this.errorService.errors().subscribe(err => {
      this.showErrorMessage = true;
      this.errorMessage = err;
    });
  }

  ngAfterViewInit(): void {
    // Set initial column values & enable refresh
    this.initialSearchConfigSub = this.watchSearchConfig().pipe(take(1))
      .subscribe(config => {
        this.applyColumnChanges(config, true);
        this.refreshReady = true;
        this.refresh();
        this.cdrf.detectChanges();
      });

    // Update column values & refresh datagrid
    this.searchConfigSub = this.watchSearchConfig().pipe(skip(1))
      .pipe(switchMap(config => {
        this.loading = true;
        this.applyColumnChanges(config, false);
        return this.refreshPlatforms(config, true);
    })).subscribe(([results, config]) => {
      this.loading = false;
      this.totalItems = results.total;
      this.platforms = results.results;
      }, () => this.loading = false);

    // Set search config/URL parameters
    this.stateSub = this.stateSubject
      .pipe(debounceTime(500))
      .subscribe(statey => {
        const newPlatformParams: PlatformSearch = this.getPlatformSearchParameters(statey);
        this.setSearchConfig(newPlatformParams, true);
      });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  editPlatform(platform: UasRegistrationInfo) {
    this.router.navigate(['fuss', 'registration', 'edit-platform'], {queryParams: {platformid: platform.uvin}});
  }

  clonePlatform(platform: UasRegistrationInfo) {
    this.router.navigate(['fuss', 'registration', 'new-platform'], {queryParams: {cloneid: platform.uvin}});
  }


  viewPlatform(platform: UasRegistrationInfo) {
    this.router.navigate(['fuss', 'registration', 'platforms', platform.uvin]);
  }

  toggleArchivePlatform(platform: UasRegistrationInfo, archived: boolean) {
    this.requestStatus = {
      state: SubmissionState.PROCESSING,
      modalHeading: 'Archiving Platform',
      summary: 'Archiving platform...'
    };
    this.displayArchiveModal = true;
    if (!archived) {
      this.archiveSub = this.platformService.archivePlatform(platform.uvin).subscribe(() => {
        this.requestStatus = {
          state: SubmissionState.SUCCESS,
          modalHeading: 'Platform Archived',
          summary: 'Platform has been archived successfully'
        };
        this.totalItems = null;
        this.refresh();
      }, (error: HttpErrorResponse) => {
        this.requestStatus = {
          state: SubmissionState.ERROR,
          modalHeading: 'Platform Archive Failed',
          summary: error?.error?.message || 'Unknown error during platform archive'
        };
      });
    } else {
      this.unArchiveSub = this.platformService.unArchivePlatform(platform.uvin).subscribe(() => {
        this.requestStatus = {
          state: SubmissionState.SUCCESS,
          modalHeading: 'Platform Unarchived',
          summary: 'Platform has been unarchived successfully'
        };
        this.totalItems = null;
        this.refresh();
      }, (error: HttpErrorResponse) => {
        this.requestStatus = {
          state: SubmissionState.ERROR,
          modalHeading: 'Platform Unarchive Failed',
          summary: error?.error?.message || 'Unknown error during platform unarchive'
        };
      });
    }
  }

  refresh(statey?: ClrDatagridStateInterface) {
    if (!statey) {
      statey = this.state || {};
    } else {
      this.state = statey;
    }
    if (!this.refreshReady){
      return;
    }
    this.stateSubject.next(statey);
  }

  refreshPlatforms(searchConfig: IPlatformSearch, fetchCount: boolean = true):
    Observable<[SearchResult<UasRegistrationInfo>, IPlatformSearch]> {

    return this.platformService.getPlatforms(searchConfig, searchConfig.pageSize, searchConfig.pageOffset, fetchCount)
      .pipe(map(results => [results, searchConfig]));
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.archiveSub?.unsubscribe();
    this.unArchiveSub?.unsubscribe();
    this.errorSub?.unsubscribe();
    this.initialSearchConfigSub?.unsubscribe();
    this.searchConfigSub?.unsubscribe();
    this.stateSub?.unsubscribe();
  }

  getSearchConfigFromParamMap(params: ParamMap): PlatformSearch {
    let paramMap = params;
    if (!paramMap) {
      paramMap = this.activatedRoute.snapshot.queryParamMap;
    }
    const ret = PlatformSearch.defaultSearch();

    if (paramMap.has('archived')) {
      try {
        const archived = JSON.parse(paramMap.get('archived'));
        if (typeof archived !== 'boolean') {
          throw new Error();
        }
        ret.archived = archived;
      } catch {
        console.error('Invalid value for archived URL parameter: ' + paramMap.get('archived'));
      }
    }
    if (paramMap.has('name')) {
      ret.name = paramMap.get('name');
    }
    if (paramMap.has('organization')) {
      ret.organization = paramMap.get('organization');
    }
    if (paramMap.has('make')) {
      ret.make = paramMap.get('make');
    }
    if (paramMap.has('model')) {
      ret.model = paramMap.get('model');
    }
    if (paramMap.has('serialNumber')) {
      ret.serialNumber = paramMap.get('serialNumber');
    }
    if (paramMap.has('faaRegistrationNumber')) {
      ret.faaRegistrationNumber = paramMap.get('faaRegistrationNumber');
    }
    if (paramMap.has('tailNumber')) {
      ret.tailNumber = paramMap.get('tailNumber');
    }
    if (paramMap.has('sort')) {
      const sort = (paramMap.getAll('sort') || [])
        .map(PlatformSearchFieldUtil.fromString)
        .filter(f => f !== PlatformSearchSortField.unknown);
      if (sort.length) {
        ret.sort = sort;
      }
    }
    if (paramMap.has('sortIncreasing')) {
      try {
        const sortIncreasing = JSON.parse(paramMap.get('sortIncreasing'));
        if (typeof sortIncreasing !== 'boolean') {
          throw new Error();
        }
        ret.sortIncreasing = sortIncreasing;
      } catch {
        console.error('Invalid value for sortIncreasing URL parameter: ' + paramMap.get('sortIncreasing'));
      }
    }
    if (paramMap.has('limit')) {
      const limit = Number(paramMap.get('limit'));
      if (!isInteger(limit) || !this.pageSizeOptions.includes(limit)) {
        console.error(`Invalid value for limit URL parameter: ${paramMap.get('limit')} \n Valid options are ${this.pageSizeOptions.join(', ')}`);
      } else {
        ret.pageSize = limit;
      }
    }
    if (paramMap.has('offset')) {
      const offset = Number(paramMap.get('offset'));
      if (!isInteger(offset) || offset < 0) {
        console.error('Invalid value for offset URL parameter: ' + paramMap.get('offset'));
      } else if((offset % ret.pageSize) !== 0) {
        console.error('offset URL parameter must be divisible by limit parameter');
      } else {
        ret.pageOffset = offset;
      }
    }

    return ret;
  }

  serializeSearchConfigToParams(searchConfig: PlatformSearch): Params {
    return {
      archived: JSON.stringify(searchConfig.archived),
      name: searchConfig.name,
      organization: searchConfig.organization,
      make: searchConfig.make,
      model: searchConfig.model,
      serialNumber: searchConfig.serialNumber,
      faaRegistrationNumber: searchConfig.faaRegistrationNumber,
      tailNumber: searchConfig.tailNumber,
      sort: searchConfig.sort,
      sortIncreasing: JSON.stringify(searchConfig.sortIncreasing),
      limit: searchConfig.pageSize,
      offset: searchConfig.pageOffset
    };
  }

  resetFilters() {
    this.applyColumnChanges(PlatformSearch.defaultSearch(), true);
  }

  private getPlatformSearchParameters(statey: ClrDatagridStateInterface): PlatformSearch {
    // Parse filters from array of objects -> single object with key/value pairs
    const dgFilters: { [prop: string]: string } = {};
    statey.filters?.forEach(filter => dgFilters[this.extractFieldNameFromDotNotation(filter.property)] = filter.value);

    // Parse sort field
    let sort: PlatformSearchSortField[];
    if (statey.sort?.by) {
      sort = [PlatformSearchFieldUtil.fromString(this.extractFieldNameFromDotNotation(statey.sort.by.toString()))];
      if (sort[0] === PlatformSearchSortField.unknown) {
        sort = undefined;
      }
    }

    return new PlatformSearch({
      archived: false,
      name: dgFilters.name,
      organization: dgFilters.organization,
      make: dgFilters.make,
      model: dgFilters.model,
      faaRegistrationNumber: dgFilters.faaRegistrationNumber,
      sort,
      sortIncreasing: !isNil(statey.sort?.reverse) ? !statey.sort?.reverse : undefined,
      pageSize: statey?.page?.size,
      pageOffset: statey.page?.from === -1 ? 0 : statey.page?.from
    } as IPlatformSearch);
  }

  private applyColumnChanges(config: IPlatformSearch, emitChanges: boolean): void {
    if (this.columns) {
      // Set column filter values
      this.columns.forEach(col => {
        const columnFieldName = this.extractFieldNameFromDotNotation(col.field);
        if (columnFieldName in config) {
          col.filterValue = config[columnFieldName];
          col.filterValueChange.emit(config[columnFieldName]);
        }
      });

      // Set column sort directions
      if (config.sort?.length) {
        const columnSortFields = this.columns.map(col => col.field).filter(col => !!col);

        columnSortFields.forEach(field => {
          if (config.sort[0] === this.extractFieldNameFromDotNotation(field)) {
            this.sortDirections[field] = config.sortIncreasing ? ClrDatagridSortOrder.ASC : ClrDatagridSortOrder.DESC;
          } else {
            this.sortDirections[field] = ClrDatagridSortOrder.UNSORTED;
          }
        });
      }
    }

    this.currentPageSize = config.pageSize;
    this.currentPage = Math.floor((config.pageOffset / config.pageSize)) + 1;

    if (this.dg && emitChanges){
      this.dg.notifyOnChanges();
    }
  }

  private extractFieldNameFromDotNotation(field: string): string {
    return field?.includes('.') ? field.split('.').pop() : field;
  }
}

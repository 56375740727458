import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {User} from '../../../shared/model/User';
import {AdminUserService} from '../../../shared/services/admin/admin-user.service';
import {HttpErrorResponse} from '@angular/common/http';
import {UserResponse} from '../../../shared/model/UserResponse';

@Component({
    selector: 'app-view-user',
    templateUrl: './view-user.component.html'
})
export class ViewUserComponent implements OnInit {
    user: User;
    userRequestError: boolean;
    userRequestErrorMsg: string;

    constructor(private userService: AdminUserService,
                private router: Router,
                private route: ActivatedRoute) {
      // Constructor intentionally left empty
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params: Params) => {
          if (params.userid) {
              this.userService.getUser(params.userid).subscribe((res: UserResponse) => {
                this.userRequestError = false;
                this.user = res.user;
              }, (error: HttpErrorResponse) => {
                this.userRequestErrorMsg = error?.error?.message || 'Unknown error retrieving user';
                this.userRequestError = true;
              });
          } else {
              this.router.navigate(['fuss', 'registration', 'users']);
          }
        });
    }
}

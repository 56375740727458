import {Component, forwardRef, OnDestroy} from '@angular/core';
import {NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IControlValueAccessor, IFormArray, IFormBuilder, IFormGroup} from '@rxweb/types';
import {IFederationDomain} from '../../shared/model/FederationPolicy';
import {Subscription} from 'rxjs';

@Component({
  selector: 'edit-federation-domain-list',
  templateUrl: './edit-federation-domain-list.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFederationDomainListComponent),
    }
  ]
})
export class EditFederationDomainListComponent implements IControlValueAccessor<IFederationDomain[]>, OnDestroy {
  isDisabled: boolean;
  private onTouched: () => void;
  private onChange: (value: IFederationDomain[]) => void;

  private fb: IFormBuilder;
  fg: UntypedFormGroup;
  private domainSubs: Subscription[] = [];
  currentPageSize = 10;

  get domainArray(): IFormArray<IFederationDomain> {
    return this.fg.controls.domains as IFormArray<IFederationDomain>;
  }

  get domainFgArray(): IFormGroup<IFederationDomain>[] {
    return this.domainArray.controls as IFormGroup<IFederationDomain>[];
  }

  constructor(fb: UntypedFormBuilder) {
    this.fb = fb;
    this.fg = this.fb.group({
      domains: this.fb.array<IFederationDomain>([], [Validators.required])
    });
  }

  writeValue(obj: IFederationDomain[]): void {
    this.setDomains(obj || []);
  }

  registerOnChange(fn: (value: IFederationDomain[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  initDomain(domain?: IFederationDomain): UntypedFormGroup {
    const fg = this.fb.group({
      id: [domain?.id ?? '', [Validators.required]],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      prettyName: [domain?.prettyName ?? '', [Validators.required]]
    });
    this.domainArray.push(fg);
    this.domainSubs.push(fg.valueChanges.subscribe(() => {
      this.emitChange();
    }));
    this.emitChange();
    return fg;
  }

  setDomains(domains: IFederationDomain[]): void {
    this.clearDomains();
    for (const domain of domains) {
      this.initDomain(domain);
    }
  }

  clearDomains(): void {
    this.domainArray.clear();
    for (const sub of this.domainSubs) {
      sub?.unsubscribe();
    }
    this.domainSubs = [];
  }

  private emitChange() {
    const domains = this.domainFgArray.map(fg => fg.getRawValue());
    if (this.onChange) {
      this.onChange(domains);
    }
  }

  ngOnDestroy(): void {
    for (const sub of this.domainSubs) {
      sub?.unsubscribe();
    }
  }

  onDelete(i: number) {
    const subs = this.domainSubs.splice(i, 1);
    subs.forEach(sub => sub?.unsubscribe());

    this.domainArray.removeAt(i);

  }
}

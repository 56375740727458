import {measureDef, unitMap} from "./base";


const voltage: unitMap = {
  V: {
    name: {
      singular: 'Volt',
      plural: 'Volts',
    },
    to_anchor: 1,
  },
  mV: {
    name: {
      singular: 'Millivolt',
      plural: 'Millivolts',
    },
    to_anchor: 0.001,
  },
  kV: {
    name: {
      singular: 'Kilovolt',
      plural: 'Kilovolts',
    },
    to_anchor: 1000,
  },
};
export const VoltageDef:measureDef = {
  type: 'voltage',
  metric: voltage,
  _anchors: {
    metric: {
      unit: 'V',
      ratio: 1,
    },
  },
};

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren
} from '@angular/core';
import {
  AdminUserService,
  IUserSearch,
  UserSearch,
  UserSearchSortField,
  UserSearchSortFieldUtil
} from '../../../shared/services/admin/admin-user.service';
import {User} from '../../../shared/model/User';
import {ClrDatagrid, ClrDatagridColumn, ClrDatagridSortOrder, ClrDatagridStateInterface} from '@clr/angular';
import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {RoleComparator} from './users.comparators';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {ManagedSearchComponent} from '../../../shared/utils/ManagedSearchComponent';
import {ErrorService} from '../../../shared/services/error.service';
import {debounceTime, map, skip, switchMap, take} from 'rxjs/operators';
import {UserSearchResult} from 'src/app/shared/model/UserSearchResult';
import {isInteger, isNil} from "lodash";
import {ResponsiveScreenService} from '../../../shared/services/responsive-screen.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent extends ManagedSearchComponent<IUserSearch> implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(ClrDatagridColumn) columns: QueryList<ClrDatagridColumn>;
  @ViewChildren(ClrDatagrid) dg: QueryList<ClrDatagrid>;
  totalItems: number;
  currentPageSize = 10;
  currentPage = 1;
  pageSizeOptions = [5,10,20,50,100];
  loading = false;
  users: User[] = [];
  userToDelete: User;
  roleComparator = new RoleComparator();
  errorMessage: string;
  showErrorMessage: boolean;
  sortDirections: { [key: string]: ClrDatagridSortOrder } = {};

  deviceSize$ = inject(ResponsiveScreenService).deviceSize$;

  private stateSubject: ReplaySubject<ClrDatagridStateInterface> = new ReplaySubject<ClrDatagridStateInterface>(1);
  private state: ClrDatagridStateInterface;
  private previousState: ClrDatagridStateInterface;
  private subscriptions: Subscription[] = [];
  private refreshReady = false;

  constructor(private usersService: AdminUserService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private errorService: ErrorService,
              private cdrf: ChangeDetectorRef) {
    super(UserSearch.defaultSearch(), router, activatedRoute);

    this.subscriptions.push(this.errorService.errors().subscribe(err => {
      this.showErrorMessage = true;
      this.errorMessage = err;
    }));
  }

  ngAfterViewInit(): void {
    // Set initial column values & enable refresh
    this.subscriptions.push(
      this.watchSearchConfig()
        .pipe(take(1))
        .subscribe(config => {
          this.applyColumnChanges(config, true);
          this.refreshReady = true;
          this.refresh();
          this.cdrf.detectChanges();
        })
    );

    // Update column values & refresh datagrid
    this.subscriptions.push(this.watchSearchConfig().pipe(skip(1))
      .pipe(switchMap(config => {
        this.loading = true;
        this.applyColumnChanges(config, false);
        return this.refreshUsers(config);
      })).subscribe(([results, config]) => {
        this.loading = false;
        this.totalItems = results.length;
        this.users = results;
      }, () => this.loading = false));

    // Set search config/URL parameters
    this.subscriptions.push(this.stateSubject
      .pipe(debounceTime(500))
      .subscribe(statey => {
        const newUserParams: UserSearch = this.getUserSearchParameters(statey);
        this.setSearchConfig(newUserParams, true);
      }));
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.subscriptions) {
      for (const sub of this.subscriptions) {
        if (!sub.closed) {
          sub.unsubscribe();
        }
      }
    }
  }

  refresh(statey?: ClrDatagridStateInterface) {
    if (!statey) {
      statey = this.state || {};
    } else {
      this.state = statey;
    }
    if (!this.refreshReady){
      return;
    }
    this.stateSubject.next(statey);
  }

  refreshUsers(config: IUserSearch): Observable<[User[], IUserSearch]> {

    return this.usersService.getUsers().pipe(map(allUsers => this.filterUsers(allUsers, config)))
      .pipe(map(results => [results, config]));
  }


  viewUser(user: User) {
    this.router.navigate(['fuss', 'registration', 'view-user'], {queryParams: {userid: user.uid}});
  }

  editUser(user: User) {
    this.router.navigate(['fuss', 'registration', 'edit-user'], {queryParams: {userid: user.uid}});
  }

  promptUserDelete(user: User) {
    this.userToDelete = user;
  }

  handleUserDeleted(user: User) {
    // eslint-disable-next-line no-underscore-dangle
    this.users = this.users.filter(u => u.uid !== user.uid);
    this.refresh(this.previousState);
  }

  resetFilters() {
    this.applyColumnChanges(UserSearch.defaultSearch(), true);
  }

  getSearchConfigFromParamMap(params: ParamMap): UserSearch {
    let paramMap = params;
    if (!paramMap) {
      paramMap = this.activatedRoute.snapshot.queryParamMap;
    }
    const ret = UserSearch.defaultSearch();

    if (paramMap.has('title')) {
      ret.title = paramMap.get('title');
    }
    if (paramMap.has('firstName')) {
      ret.firstName = paramMap.get('firstName');
    }
    if (paramMap.has('lastName')) {
      ret.lastName = paramMap.get('lastName');
    }
    if (paramMap.has('pretty_org')) {
      ret.pretty_org = paramMap.get('pretty_org');
    }
    if (paramMap.has('division')) {
      ret.division = paramMap.get('division');
    }
    if (paramMap.has('pretty_roles')) {
      ret.pretty_roles = paramMap.get('pretty_roles');
    }
    if (paramMap.has('email')) {
      ret.email = paramMap.get('email');
    }
    if (paramMap.has('sort')) {
      const sort = (paramMap.getAll('sort') || [])
        .map(UserSearchSortFieldUtil.fromString)
        .filter(f => f !== UserSearchSortField.unknown);
      if (sort.length) {
        ret.sort = sort;
      }
    }
    if (paramMap.has('sortIncreasing')) {
      try {
        const sortIncreasing = JSON.parse(paramMap.get('sortIncreasing'));
        if (typeof sortIncreasing !== 'boolean') {
          throw new Error();
        }
        ret.sortIncreasing = sortIncreasing;
      } catch {
        console.error('Invalid value for sortIncreasing URL parameter: ' + paramMap.get('sortIncreasing'));
      }
    }
    if (paramMap.has('limit')) {
      const limit = Number(paramMap.get('limit'));
      if (!isInteger(limit) || !this.pageSizeOptions.includes(limit)) {
        console.error(`Invalid value for limit URL parameter: ${paramMap.get('limit')} \n Valid options are ${this.pageSizeOptions.join(', ')}`);
      } else {
        ret.limit = limit;
      }
    }
    if (paramMap.has('offset')) {
      const offset = Number(paramMap.get('offset'));
      if (!isInteger(offset) || offset < 0) {
        console.error('Invalid value for offset URL parameter: ' + paramMap.get('offset'));
      } else if((offset % ret.limit) !== 0) {
        console.error('offset URL parameter must be divisible by limit parameter');
      } else {
        ret.offset = offset;
      }
    }
    return ret;
  }

  serializeSearchConfigToParams(searchConfig: UserSearch): Params {
    return {
      title: searchConfig.title,
      firstName: searchConfig.firstName,
      lastName: searchConfig.lastName,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      pretty_org: searchConfig.pretty_org,
      division: searchConfig.division,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      pretty_roles: searchConfig.pretty_roles,
      email: searchConfig.email,
      sort: searchConfig.sort,
      sortIncreasing: JSON.stringify(searchConfig.sortIncreasing),
      limit: searchConfig.limit,
      offset: searchConfig.offset
    };
  }

  private filterUsers(allUsers: UserSearchResult, config: IUserSearch): User[] {
    const allUsers2 = allUsers.users;
    const activeFilters = {};
    Object.keys(config).forEach(key => {
        if (!!config[key] && (key.toString() !== 'sort' && key.toString() !== 'sortIncreasing' && key !== 'limit'
          && key !== 'offset')) {
          activeFilters[key] = config[key];
        }
    });

    this.loading = true;
    const filteredUsers: User[] = allUsers2.filter(user => {
      if (Object.keys(activeFilters).length === 0) {
        return user;
      }

      const test = Object.keys(activeFilters).map(key => {
        if (key.toString() === 'pretty_roles') {
          return user.pretty_roles.join(',').toLowerCase().includes(config[key].toLowerCase());
        } else {
          let userProperty: string | string[] = '';
          switch (key.toString()) {
            case 'title':
              userProperty = user.contact.title;
              break;
            case 'firstName':
              userProperty = user.contact.firstName;
              break;
            case 'lastName':
              userProperty = user.contact.lastName;
              break;
            case 'pretty_org':
              userProperty = user.pretty_org;
              break;
            case 'division':
              userProperty = user.contact.division;
              break;
            case 'email':
              userProperty = user.email;
              break;
            default:
              break;
          }
          return userProperty.toString().toLowerCase().includes(config[key].toString());
        }
      }).reduce(((previousValue, currentValue) => previousValue && currentValue));
      return test;
    });

    return filteredUsers;
  }

  private getUserSearchParameters(statey: ClrDatagridStateInterface<any>): UserSearch {
    // Parse filters from array of objects -> single object with key/value pairs
    const dgFilters: { [prop: string]: string } = {};
    statey.filters?.forEach(filter => dgFilters[this.extractFieldNameFromDotNotation(filter.property)] = filter.value);

    // Parse sort field
    let sort: UserSearchSortField[];
    if (statey.sort?.by) {
      sort = statey.sort.by === this.roleComparator ? [UserSearchSortField.pretty_roles] :
        [UserSearchSortFieldUtil.fromString(this.extractFieldNameFromDotNotation(statey.sort.by.toString()))];

      if (sort[0] === UserSearchSortField.unknown) {
        sort = undefined;
      }
    }

    return new UserSearch({
      title: dgFilters.title,
      firstName: dgFilters.firstName,
      lastName: dgFilters.lastName,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      pretty_org: dgFilters.pretty_org,
      division: dgFilters.division,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      pretty_roles: dgFilters.pretty_roles,
      email: dgFilters.email,
      sort,
      sortIncreasing: !isNil(statey.sort?.reverse) ? !statey.sort?.reverse : undefined,
      limit: statey.page?.size,
      offset: statey.page?.from === -1 ? 0 : statey.page?.from
    } as IUserSearch);
  }

  private applyColumnChanges(config: IUserSearch, emitChanges: boolean): void {
    if (this.columns) {
      // Set column filter values
      this.columns.forEach(col => {
        const columnFieldName = this.extractFieldNameFromDotNotation(col.field);
        if (columnFieldName in config) {
          col.filterValue = config[columnFieldName];
          col.filterValueChange.emit(config[columnFieldName]);
        }
      });

      // Set column sort directions
      if (config.sort?.length) {
        const columnSortFields = this.columns.map(col => col.field).filter(col => !!col);

        columnSortFields.forEach(field => {
          if (config.sort[0] === this.extractFieldNameFromDotNotation(field)) {
            this.sortDirections[field] = config.sortIncreasing ? ClrDatagridSortOrder.ASC : ClrDatagridSortOrder.DESC;
          } else {
            this.sortDirections[field] = ClrDatagridSortOrder.UNSORTED;
          }
        });
      }
    }

    this.currentPageSize = config.limit;
    this.currentPage = Math.floor((config.offset / config.limit)) + 1;

    if (this.dg && emitChanges){
      this.dg.notifyOnChanges();
    }
  }

  private extractFieldNameFromDotNotation(field: string): string {
    return field?.includes('.') ? field.split('.').pop() : field;
  }
}

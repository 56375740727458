import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../shared/services/auth.service';
import {mergeMap} from 'rxjs/operators';
import {User} from '../shared/model/User';
import {of} from 'rxjs/internal/observable/of';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private auth: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.auth.watchCurrentUser().pipe(
      mergeMap((user: User | null) => {
        if (user) {
          return of(true);
        } else {
          return of(false);
        }

      })
    );

  }


}

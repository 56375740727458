import {Component, ElementRef, ViewChild} from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';
import {ConfigComponent} from '../../config.component';
import {ImportService} from '../../import-export.service';

export type BaseImportConfigType = { file: File };

@Component({
  selector: 'app-base-import-config',
  templateUrl: './base-import-config.component.html',
  styleUrl: './base-import-config.component.scss'
})
export class BaseImportConfigComponent implements ConfigComponent {
  importValue: File;
  private configSubject: ReplaySubject<BaseImportConfigType> = new ReplaySubject<BaseImportConfigType>(1);
  private service: ImportService<any>;
  extensions = '.*';
  @ViewChild('importFileControl') importFileControl: ElementRef;
  private config: BaseImportConfigType;

  constructor() {
    // Method not implemented
  }

  getConfig(): Observable<BaseImportConfigType> {
    return this.configSubject;
  }

  setConfig(config: BaseImportConfigType) {
    this.importValue = config.file;

    if (!config?.file && this.importFileControl) {
      this.importFileControl.nativeElement.value = null;
    }
    this.configSubject.next(config);
  }

  setService(service: ImportService<any>) {
    this.service = service;
    this.extensions = (service.getFileExtensions() || []).join(',');

  }

  updateImportFile($event: any) {
    this.config = {
      file: this.importFileControl.nativeElement.files[0]
    };
    this.configSubject.next(this.config);
  }
}

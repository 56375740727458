import {Component, Injectable, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {AbstractControl, ValidationErrors} from '@angular/forms';
import {Observable, ReplaySubject, Subscription, timer} from 'rxjs';

@Injectable()
export class FormErrorService {
  private errorSubject = new ReplaySubject<ValidationErrors | null>(1);

  get errors(): Observable<ValidationErrors | null> {
    return this.errorSubject;
  }

  next(errors: ValidationErrors | null) {
    this.errorSubject.next(errors);
  }

}

@Component({
  selector: 'error-container',
  templateUrl: './error-container.component.html',
  providers: [FormErrorService]
})
export class ErrorContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: AbstractControl;
  @Input() interval = -1;
  @Input() displayAlways = false;

  private sub: Subscription;
  private intervalSub: Subscription;

  constructor(private errorService: FormErrorService) {
  }

  ngOnDestroy(): void {
    this.clearSubs();
  }

  ngOnInit(): void {
    if (this.control) {
      if (this.interval > 0) {
        this.intervalSub = timer(100, this.interval).subscribe(() => {
          this.errorService.next(this.control.errors);
        });
      }
      this.sub = this.control.valueChanges.subscribe(value => {
        this.errorService.next(this.control.errors);
      });
      this.errorService.next(this.control.errors);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.clearSubs();
    this.ngOnInit();
  }


  private clearSubs() {
    if (this.sub && !this.sub.closed) {
      this.sub.unsubscribe();
      this.sub = null;
    }
  }
}

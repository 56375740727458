import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LatLong} from '../model/LatLong';
import {SearchResult} from '../model/SearchResult';
import {UASVolumeReservation} from '../model/gen/utm';


export declare type SortDirection = 'asc' | 'desc' | '';

export enum UVR_STATE {
  PROPOSED,
  ACCEPTED,
  ACTIVE,
  CLOSED,
  EXPIRED,
  FAILED_PROPOSE,
  FAILED_CLOSE
}

export interface UVRSearchOptions {
  contains?: { [prop: string]: string };
  sort?: { by: string; direction: SortDirection }[];
  limit?: number;
  offset?: number;
}

@Injectable({
  providedIn: 'root'
})
export abstract class UvrService {

  // eslint-disable-next-line max-len
  abstract createUpdateUvr(uss: string, messageId: string, uasVolumeReservation: UASVolumeReservation): Observable<{ success: boolean, error?: any }>;

  abstract getUvrById(messageId: string): Observable<UASVolumeReservation>;

  abstract getUvrsForCoords(coords: LatLong[]): Observable<UASVolumeReservation[]>;

  abstract cancelUvr(uss: string, messageId: string, uasVolumeReservation?: UASVolumeReservation): Observable<boolean>;

  abstract getUssFromUvr(messageId: string, hardCodedUSSs?: string[]): Observable<string[]>;

  abstract getUvrs(options: UVRSearchOptions): Observable<SearchResult<UASVolumeReservation>>;

  abstract getUvrStatus(uvr: UASVolumeReservation, refreshMillis?: number): Observable<UVR_STATE>;

  abstract getUvrCount(options: UVRSearchOptions): Observable<SearchResult<UASVolumeReservation>>;

  abstract createUvr(uasVolumeReservation: UASVolumeReservation,
                     uss: string,
                     messageId?: string): Observable<{ success: true; uvr_id: string } | { success: false; error?: any }>;

  abstract exists(messageId: string): Observable<boolean>;
}

import {BaseModel} from './gen/utm/base-model';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export interface IContact {
  id: string;
  userId: string;
  /**
   * @deprecated
   */
  username: string;
  prefixName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffixName: string;
  organization: string;
  division: string;
  phones: string[];
  emails: string[];
  address: string;
  title: string;
  comments: string;
  userContact: boolean;
  hasPart107?: boolean;
}

export class Contact extends BaseModel implements IContact {
  static ORGANIZATION_ID_FIELD_NAME = 'organizationId';
  static ID_FIELD_NAME = 'id';
  static USERID_FIELD_NAME = 'userId';
  static USERNAME_FIELD_NAME = 'username';
  static PREFIXNAME_FIELD_NAME = 'prefixName';
  static FIRSTNAME_FIELD_NAME = 'firstName';
  static MIDDLENAME_FIELD_NAME = 'middleName';
  static LASTNAME_FIELD_NAME = 'lastName';
  static SUFFIXNAME_FIELD_NAME = 'suffixName';
  static ORGANIZATION_FIELD_NAME = 'organization';
  static DIVISION_FIELD_NAME = 'division';
  static PHONES_FIELD_NAME = 'phones';
  static EMAILS_FIELD_NAME = 'emails';
  static TITLE_FIELD_NAME = 'title';
  static ADDRESS_FIELD_NAME = 'address';
  static COMMENTS_FIELD_NAME = 'comments';
  static USERCONTACT_FIELD_NAME = 'userContact';
  static HASPART107_FIELD_NAME = 'hasPart107';

  id: string;
  userId: string;
  /**
   * @deprecated
   */
  username: string;
  prefixName: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffixName: string;
  organization: string;
  division: string;
  phones: string[];
  emails: string[];
  title: string;
  comments: string;
  address: string;
  userContact: boolean;
  hasPart107: boolean = false;

  constructor(values?: Partial<Contact>, useFormGroupValuesToModel = false) {
    super();
    this.phones = [];
    this.emails = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<Contact>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.id = this.getValue<string>(rawValues, Contact.ID_FIELD_NAME);
      this.userId = this.getValue<string>(rawValues, Contact.USERID_FIELD_NAME);
      this.username = this.getValue<string>(rawValues, Contact.USERNAME_FIELD_NAME);

      this.prefixName = this.getValue<string>(rawValues, Contact.PREFIXNAME_FIELD_NAME);
      this.firstName = this.getValue<string>(rawValues, Contact.FIRSTNAME_FIELD_NAME);
      this.middleName = this.getValue<string>(rawValues, Contact.MIDDLENAME_FIELD_NAME);
      this.lastName = this.getValue<string>(rawValues, Contact.LASTNAME_FIELD_NAME);
      this.suffixName = this.getValue<string>(rawValues, Contact.SUFFIXNAME_FIELD_NAME);

      this.organization = this.getValue<string>(rawValues, Contact.ORGANIZATION_FIELD_NAME);
      this.division = this.getValue<string>(rawValues, Contact.DIVISION_FIELD_NAME);
      this.address = this.getValue<string>(rawValues, Contact.ADDRESS_FIELD_NAME);
      this.title = this.getValue<string>(rawValues, Contact.TITLE_FIELD_NAME);
      this.comments = this.getValue<string>(rawValues, Contact.COMMENTS_FIELD_NAME);
      this.userContact = this.getValue<boolean>(rawValues, Contact.USERCONTACT_FIELD_NAME);
      this.hasPart107 = this.getValue<boolean>(rawValues, 'hasPart107');

      this.fillModelArray<string>(this, Contact.PHONES_FIELD_NAME, rawValues.phones, useFormGroupValuesToModel);
      this.fillModelArray<string>(this, Contact.EMAILS_FIELD_NAME, rawValues.emails, useFormGroupValuesToModel);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[Contact.ID_FIELD_NAME].setValue(this.id);
    this.$formGroup.controls[Contact.USERID_FIELD_NAME].setValue(this.userId);
    this.$formGroup.controls[Contact.USERNAME_FIELD_NAME].setValue(this.username);

    this.$formGroup.controls[Contact.PREFIXNAME_FIELD_NAME].setValue(this.prefixName);
    this.$formGroup.controls[Contact.FIRSTNAME_FIELD_NAME].setValue(this.firstName);
    this.$formGroup.controls[Contact.MIDDLENAME_FIELD_NAME].setValue(this.middleName);
    this.$formGroup.controls[Contact.LASTNAME_FIELD_NAME].setValue(this.lastName);
    this.$formGroup.controls[Contact.SUFFIXNAME_FIELD_NAME].setValue(this.suffixName);

    this.$formGroup.controls[Contact.ORGANIZATION_FIELD_NAME].setValue(this.organization);
    this.$formGroup.controls[Contact.DIVISION_FIELD_NAME].setValue(this.division);
    this.$formGroup.controls[Contact.ADDRESS_FIELD_NAME].setValue(this.address);
    this.$formGroup.controls[Contact.TITLE_FIELD_NAME].setValue(this.title);
    this.$formGroup.controls[Contact.COMMENTS_FIELD_NAME].setValue(this.comments);
    this.$formGroup.controls[Contact.USERCONTACT_FIELD_NAME].setValue(this.userContact);
    this.$formGroup.controls[Contact.HASPART107_FIELD_NAME].setValue(this.hasPart107);

    this.fillFormArray<string>(Contact.PHONES_FIELD_NAME, this.phones);
    this.fillFormArray<string>(Contact.EMAILS_FIELD_NAME, this.emails);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        id: new UntypedFormControl(this.id, [Validators.required]),
        userId: new UntypedFormControl(this.userId, [Validators.required]),
        username: new UntypedFormControl(this.username, [Validators.required]),
        prefixName: new UntypedFormControl(this.prefixName, [Validators.required]),
        firstName: new UntypedFormControl(this.firstName, [Validators.required]),
        middleName: new UntypedFormControl(this.middleName, [Validators.required]),
        lastName: new UntypedFormControl(this.lastName, [Validators.required]),
        suffixName: new UntypedFormControl(this.suffixName, [Validators.required]),
        organization: new UntypedFormControl(this.organization, [Validators.required]),
        division: new UntypedFormControl(this.division, [Validators.required]),
        phones: new UntypedFormArray([]),
        emails: new UntypedFormArray([]),
        address: new UntypedFormControl(this.address, []),
        title: new UntypedFormControl(this.title, [Validators.required]),
        comments: new UntypedFormControl(this.comments, [Validators.required]),
        userContact: new UntypedFormControl(this.userContact, [Validators.required]),
        hasPart107: new UntypedFormControl(this.hasPart107, [Validators.required]),
      });
      // generate FormArray control elements
      this.fillFormArray<string>(Contact.PHONES_FIELD_NAME, this.phones);
      // generate FormArray control elements
      this.fillFormArray<string>(Contact.EMAILS_FIELD_NAME, this.emails);
    }
    return this._formGroup;
  }

  isEmpty(): boolean {
    return !this.username && !this.id;
  }

  getName(reverse = true): string {
    return reverse ? `${this.lastName}, ${this.firstName}` : `${this.firstName} ${this.lastName}`;
  }

  getFullName(): string {
    let ret = '';
    ret += this.lastName;
    if (this.suffixName) {
      ret += ' ' + this.suffixName;
    }
    ret += ', ' + this.firstName;
    if (this.middleName) {
      ret += ' ' + this.middleName;
    }
    return ret;
  }

  clone() {
    return new Contact(this);
  }
}

<form [formGroup]="fg">
  <!-- Full select display is necessary to prevent the select control from retaining focus and
      requiring an initial click to unset the focus before the file browser will open-->
<!--  <div class="clr-form-control">-->
<!--    <label *ngIf="mode===IMPORT" for="service" class="clr-control-label">Import Type</label>-->
<!--    <label *ngIf="mode===EXPORT" for="service" class="clr-control-label">Export Type</label>-->
<!--    <div class="clr-control-container">-->
<!--      <div class="clr-select-wrapper">-->
<!--        <select id="service" class="clr-select" formControlName="service">-->
<!--          <option *ngFor="let t of availableServices" [ngValue]="t">{{t.getFormatName()}}</option>-->
<!--        </select>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->

  <ng-template importExportConfigHost>

  </ng-template>
  <button class="btn btn-primary" *ngIf="mode===IMPORT" (click)="execute()" [disabled]="!this.configData">Import</button>
  <button class="btn btn-primary" *ngIf="mode===EXPORT" (click)="execute()">Export</button>
</form>

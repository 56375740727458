import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImportExportComponent} from './import-export/import-export.component';
import {ClarityModule} from '@clr/angular';
import {ImportExportModule} from '../import-export/import-export.module';
import {LeafletExposeMapComponent} from './leaflet-expose-map/leaflet-expose-map.component';
import {GeomanContainerComponent} from './geoman/geoman-container/geoman-container.component';
import {GeomanControlComponent} from './geoman/geoman-control/geoman-control.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


@NgModule({
  declarations: [ImportExportComponent, LeafletExposeMapComponent, GeomanContainerComponent, GeomanControlComponent],
  exports: [ImportExportComponent, LeafletExposeMapComponent, GeomanContainerComponent, GeomanControlComponent],
    imports: [
        CommonModule,
        ClarityModule,
        ImportExportModule,
        ImportExportModule,
        FormsModule,
        ReactiveFormsModule
    ]
})
export class LeafletModule { }

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, ReplaySubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {DateTime} from 'luxon';
import {JwtUtil} from '../utils/jwt';

let token = localStorage.getItem('auth_token');
const tokenSubject = new ReplaySubject<string>(1);

if (JwtUtil.isTokenExpired(token)) {
  token = null;
}
tokenSubject.next(token);

const blacklist = [
  'https://api.cesium.com'
];

@Injectable()
export class SpringAuthInterceptor implements HttpInterceptor {

  constructor(private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newReq = req;
    const useToken = token && !JwtUtil.isTokenExpired(token);
    let url = req.url;

    if(!useToken || url.startsWith("http")){
      return next.handle(req);
    }



    newReq = req.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: token
      }
    });


    return next.handle(newReq).pipe(tap(() => {
    }, (err) => {
      console.error('Error requesting');
      // tslint:disable-next-line:no-console
      console.debug(err?.message);
      if (err.status === 401) {
        // redirect to the login route
        // or show a modal
        localStorage.removeItem('auth_token');
        this.router.navigateByUrl('/login');
        return;
      }
      if (localStorage.getItem('token_expiry_time')) {
        const expiryTime = DateTime.fromISO(localStorage.getItem('token_expiry_time'));
        if (expiryTime.diffNow().as('milliseconds') <= 0) {
          // redirect to the login route
          // or show a modal
          localStorage.removeItem('auth_token');
          this.router.navigateByUrl('/login');
        }
      }
    }));
  }

  setAuth(newToken: string) {
    token = newToken;
    if (newToken) {
      localStorage.setItem('auth_token', newToken);
    } else if (localStorage.getItem('auth_token')) {
      localStorage.removeItem('auth_token');
    }
    tokenSubject.next(token);
  }

  getAuthToken(): Observable<string | null> {
    return tokenSubject;
  }
}



import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Altitude, units_of_measure, vertical_reference} from '../../model/gen/utm';
import {UserSettingsService} from '../../services/user-settings.service';
import {Subscription} from 'rxjs';
import {MeasurementSystemType} from '../../model/MeasurementSystem';
import {AltitudeUtilService} from '../../model/utm/altitude-util.service';
import {cloneDeep} from 'lodash';
import {Converter} from '../../utils/convert-units';
import {AltitudeService, IAltitudeConversionParameters} from '../../services/altitude.service';

@Component({
  selector: 'app-ax-altitude-summary',
  templateUrl: './ax-altitude-summary.component.html',
  styleUrls: ['./ax-altitude-summary.component.scss'],
  standalone: true
})
export class AxAltitudeSummaryComponent implements OnInit, OnDestroy {
  @Input() convertToPreferredUnitRef = true;
  @Input() altitude: Altitude;
  @Input() referencePoint: number[];
  @Input() roundPlaces = 2;
  units: units_of_measure = null;
  verticalReference: vertical_reference = null;
  convertedAltitude: Altitude;
  private userPreferencesSubscription: Subscription;
  private altitudeConversionSub: Subscription;
  constructor(private userSettingsService: UserSettingsService,
              private altitudeService: AltitudeService,
              private altitudeUtilService: AltitudeUtilService) {

  }

  ngOnInit() {
    if (this.convertToPreferredUnitRef) {
      this.userPreferencesSubscription = this.userSettingsService.getRawSettings().subscribe(rawSettings => {
        this.verticalReference = rawSettings.defaultVerticalReference;
        const convertedAltitude = cloneDeep(this.altitude);

        switch (rawSettings.measurementSystemType) {
          case MeasurementSystemType.METRIC:
            this.units = units_of_measure.M;
            break;
          case MeasurementSystemType.IMPERIAL:
            this.units = units_of_measure.FT;
            break;
        }

        this.convertAltitude(convertedAltitude);
      });
    } else {
      this.convertedAltitude = this.altitude;
    }
  }

  ngOnDestroy(): void {
    this.userPreferencesSubscription?.unsubscribe();
    this.altitudeConversionSub?.unsubscribe();
  }

  private convertAltitude(altitude: Altitude) {
    this.altitudeConversionSub?.unsubscribe();
    let convertedAltitude = altitude;

    // Convert vertical reference if needed
    if (this.referencePoint && this.verticalReference !== convertedAltitude.vertical_reference) {
      // Convert units to meters before using the reference conversion endpoint
      if (convertedAltitude.units_of_measure !== units_of_measure.M) {
        convertedAltitude = this.convertAltitudeUnits(units_of_measure.M, convertedAltitude);
      }

      const conversionParams: IAltitudeConversionParameters = {
        lat: this.referencePoint[1],
        lon: this.referencePoint[0],
        altitude: convertedAltitude.altitude_value,
        input_reference: convertedAltitude.vertical_reference,
        output_reference: this.verticalReference
      };

      // Convert vertical reference
      this.altitudeConversionSub = this.altitudeService.convertAltitude(conversionParams).subscribe(retAltitude => {
        convertedAltitude.altitude_value = retAltitude.altitude;
        convertedAltitude.vertical_reference = retAltitude.reference;

        // Convert to preferred units if needed
        convertedAltitude = this.convertAltitudeUnits(this.units, convertedAltitude);
        this.convertedAltitude = convertedAltitude;
      });
    } else {
      // Convert to preferred units if needed
      convertedAltitude = this.convertAltitudeUnits(this.units, convertedAltitude);
      this.convertedAltitude = convertedAltitude;
    }
  }

  private convertAltitudeUnits(altUnits: units_of_measure, convertedAltitude: Altitude): Altitude {
    if (altUnits !== convertedAltitude.units_of_measure) {
      const convertFrom = this.altitudeUtilService.parseUnitForConversion(convertedAltitude.units_of_measure);
      const convertTo = this.altitudeUtilService.parseUnitForConversion(altUnits);

      convertedAltitude.altitude_value = new Converter(convertedAltitude.altitude_value).from(convertFrom).to(convertTo);
      convertedAltitude.units_of_measure = altUnits;
    }

    return convertedAltitude;
  }
}

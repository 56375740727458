export interface TransportCotTelemetryIntegration {
  cotCallsign: string;
  cotUid: string;
  cotAltitudeUsesMsl: boolean;
}

export interface ICotTelemetryIntegration {
  cotCallsign: string;
  cotUid: string;
  cotAltitudeUsesMsl: boolean;
}

/**
 * Cursor on Target (Used by TAK) integration
 * @param cotCallsign CoT callsign. Required if cotUid is missing.
 * @param cotUid CoT uid. Required if cotCallsign is missing.
 * @param cotAltitudeUsesMsl If vendor is sending MSL instead of HAE against CoT spec. Default is false.
 */
export class CotTelemetryIntegration implements ICotTelemetryIntegration {
  cotCallsign: string;
  cotUid: string;
  cotAltitudeUsesMsl: boolean;

  constructor(values: CotTelemetryIntegration) {
    this.cotCallsign = values.cotCallsign;
    this.cotUid = values.cotUid;
    this.cotAltitudeUsesMsl = values.cotAltitudeUsesMsl || false;
  }
}

/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';

import {replan_suggestion, uasvolumereservation_type} from './enums';

export interface INegotiationMessage {
  message_id: string;
  negotiation_id: string;
  uss_name_of_originator: string;
  type: uasvolumereservation_type;
  replan_suggestion?: replan_suggestion;
  v2v_for_originator?: boolean;
  v2v_for_receiver?: boolean;
  gufi_of_originator: string;
  gufi_of_receiver: string;
  discovery_reference?: string;
  free_text?: string;
}


export class NegotiationMessage extends BaseModel implements INegotiationMessage {

  static MESSAGE_ID_FIELD_NAME = 'message_id';
  static NEGOTIATION_ID_FIELD_NAME = 'negotiation_id';
  static USS_NAME_OF_ORIGINATOR_FIELD_NAME = 'uss_name_of_originator';
  static TYPE_FIELD_NAME = 'type';
  static REPLAN_SUGGESTION_FIELD_NAME = 'replan_suggestion';
  static V_2_V_FOR_ORIGINATOR_FIELD_NAME = 'v2v_for_originator';
  static V_2_V_FOR_RECEIVER_FIELD_NAME = 'v2v_for_receiver';
  static GUFI_OF_ORIGINATOR_FIELD_NAME = 'gufi_of_originator';
  static GUFI_OF_RECEIVER_FIELD_NAME = 'gufi_of_receiver';
  static DISCOVERY_REFERENCE_FIELD_NAME = 'discovery_reference';
  static FREE_TEXT_FIELD_NAME = 'free_text';

  message_id: string;
  /** An identifier held constant across multiple message_ids.  This value represents a single negotiation between two USSs for two specific operations. */
  negotiation_id: string;
  /** A name identifying the originator of this message. NegotiationMessage uss_name_of_originator MUST be the uss_name as known by the authorization server. The maximum and minimum character length is based on a usable domain name, and considering the maximum in RFC-1035. */
  uss_name_of_originator: string;
  /** The type of negotiation message.  Currently just requesting another USS replan an operation, or request that a USS allow an intersection of operations.  In addition, add ability to cancel previous agreed negotiation. */
  type: uasvolumereservation_type;
  /** When 'type' is REPLAN_REQUESTED, the originating USS may supply a suggestion as to how the receiving USS might alter the plan under its management to minimize the conflict.
   This field is optional in all cases.  It should not be sent with any message that does not contain type = REPLAN_REQUESTED.
   The suggestion is truly a suggestion.  The receiving USS may solve (or not solve) a conflict in any manner it deems appropriate. */
  replan_suggestion: replan_suggestion;
  /** A boolean field indicating whether the vehicle supported by the originating USS will be using the approved vehicle-to-vehicle communication approach.
   Required when the negotiation results in any intersection of operation plans between the two operations in the negotiation.
   This assumes that there is only one approved V2V approach in the future operational system.  If this is not the case, this field could need to be more explicit to ensure certainty that the two vehicles will be able to communicate while in flight. */
  v2v_for_originator: boolean;
  /** A boolean field indicating whether the vehicle supported by the receiving USS will be using the approved vehicle-to-vehicle communication approach.
   Required when the negotiation results in any intersection of operation plans between the two operations in the negotiation.
   This assumes that there is only one approved V2V approach in the future operational system.  If this is not the case, this field could need to be more explicit to ensure certainty that the two vehicles will be able to communicate while in flight. */
  v2v_for_receiver: boolean;
  /** The GUFI of the relevant operation of the originating USS. Note that the origin and receiver roles are strictly dependent on which USS is generating this message. May be empty in the case of ATC communication to USS via FIMS. */
  gufi_of_originator: string;
  /** The GUFI of the relevant operation of the receiving USS. Note that the origin and receiver roles are strictly dependent on which USS is generating this message. May be empty in the case of ATC communication to USS via FIMS. The receiving USS MUST return 400 if it does not own this GUFI. */
  gufi_of_receiver: string;
  /** An identifier that supplies information about the USS from the discovery perspective.  This field is currently vague due to research on the appropriate discovery approach.  It will be tightened when an operational system decides on the approach to discovery.
   For Gridded USS Discovery, this should be an x,y,z string where x and y are the coords of the grid and z is the zoom level.  For example:  "110,117,10"
   For FIMS Discovery, this should be the uss_instance_id which is a UUID. */
  discovery_reference: string;
  free_text: string;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<INegotiationMessage>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<INegotiationMessage>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.message_id = this.getValue<string>(rawValues, NegotiationMessage.MESSAGE_ID_FIELD_NAME);
      this.negotiation_id = this.getValue<string>(rawValues, NegotiationMessage.NEGOTIATION_ID_FIELD_NAME);
      this.uss_name_of_originator = this.getValue<string>(rawValues, NegotiationMessage.USS_NAME_OF_ORIGINATOR_FIELD_NAME);
      this.type = this.getValue<uasvolumereservation_type>(rawValues, NegotiationMessage.TYPE_FIELD_NAME);
      this.replan_suggestion = this.getValue<replan_suggestion>(rawValues, NegotiationMessage.REPLAN_SUGGESTION_FIELD_NAME);
      this.v2v_for_originator = this.getValue<boolean>(rawValues, NegotiationMessage.V_2_V_FOR_ORIGINATOR_FIELD_NAME);
      this.v2v_for_receiver = this.getValue<boolean>(rawValues, NegotiationMessage.V_2_V_FOR_RECEIVER_FIELD_NAME);
      this.gufi_of_originator = this.getValue<string>(rawValues, NegotiationMessage.GUFI_OF_ORIGINATOR_FIELD_NAME);
      this.gufi_of_receiver = this.getValue<string>(rawValues, NegotiationMessage.GUFI_OF_RECEIVER_FIELD_NAME);
      this.discovery_reference = this.getValue<string>(rawValues, NegotiationMessage.DISCOVERY_REFERENCE_FIELD_NAME);
      this.free_text = this.getValue<string>(rawValues, NegotiationMessage.FREE_TEXT_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        message_id: new UntypedFormControl(this.message_id, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        negotiation_id: new UntypedFormControl(this.negotiation_id, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        uss_name_of_originator: new UntypedFormControl(this.uss_name_of_originator, [Validators.required, Validators.minLength(4), Validators.maxLength(250),]),
        type: new UntypedFormControl(this.type, [Validators.required, enumValidator(uasvolumereservation_type),]),
        replan_suggestion: new UntypedFormControl(this.replan_suggestion, [enumValidator(replan_suggestion),]),
        v2v_for_originator: new UntypedFormControl(this.v2v_for_originator),
        v2v_for_receiver: new UntypedFormControl(this.v2v_for_receiver),
        gufi_of_originator: new UntypedFormControl(this.gufi_of_originator, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        gufi_of_receiver: new UntypedFormControl(this.gufi_of_receiver, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        discovery_reference: new UntypedFormControl(this.discovery_reference, [Validators.minLength(5), Validators.maxLength(36),]),
        free_text: new UntypedFormControl(this.free_text, [Validators.maxLength(1000),]),
      });
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[NegotiationMessage.MESSAGE_ID_FIELD_NAME].setValue(this.message_id);
    this.$formGroup.controls[NegotiationMessage.NEGOTIATION_ID_FIELD_NAME].setValue(this.negotiation_id);
    this.$formGroup.controls[NegotiationMessage.USS_NAME_OF_ORIGINATOR_FIELD_NAME].setValue(this.uss_name_of_originator);
    this.$formGroup.controls[NegotiationMessage.TYPE_FIELD_NAME].setValue(this.type);
    this.$formGroup.controls[NegotiationMessage.REPLAN_SUGGESTION_FIELD_NAME].setValue(this.replan_suggestion);
    this.$formGroup.controls[NegotiationMessage.V_2_V_FOR_ORIGINATOR_FIELD_NAME].setValue(this.v2v_for_originator);
    this.$formGroup.controls[NegotiationMessage.V_2_V_FOR_RECEIVER_FIELD_NAME].setValue(this.v2v_for_receiver);
    this.$formGroup.controls[NegotiationMessage.GUFI_OF_ORIGINATOR_FIELD_NAME].setValue(this.gufi_of_originator);
    this.$formGroup.controls[NegotiationMessage.GUFI_OF_RECEIVER_FIELD_NAME].setValue(this.gufi_of_receiver);
    this.$formGroup.controls[NegotiationMessage.DISCOVERY_REFERENCE_FIELD_NAME].setValue(this.discovery_reference);
    this.$formGroup.controls[NegotiationMessage.FREE_TEXT_FIELD_NAME].setValue(this.free_text);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

<h1 cds-text="title">User Settings</h1>
<clr-alerts>
  <clr-alert *ngIf="saveSuccess === false" [clrAlertType]="'danger'" [clrAlertClosable]="false">
    <clr-alert-item>
      <span class="alert-text">Failed to save settings</span>
    </clr-alert-item>
  </clr-alert>
  <clr-alert *ngIf="!!saveSuccess" [clrAlertType]="'success'" [clrAlertClosable]="false">
    <clr-alert-item>
      <span class="alert-text">Settings saved successfully</span>
    </clr-alert-item>
  </clr-alert>
</clr-alerts>

<form [formGroup]="fg">
  <div class="clr-row">
    <div class="clr-col-12 clr-col-md-4 clr-col-xl-3 col-gen-settings">
      <h2 cds-text="section">General Settings</h2>
      <cds-form-group layout="vertical" class="scaling-factor-fg">
        <cds-control>
          <label>UI Scaling Factor</label>
          <font-size-button cds-control formControlName="uiScalingFactor"></font-size-button>
        </cds-control>
      </cds-form-group>

      <br/>
      <cds-radio-group layout="vertical">
        <label>Preferred Measurement System</label>
        <cds-radio>
          <label>Metric</label>
          <input autocomplete="off" type="radio" formControlName="measurementSystemType" [value]="measurementSystem.METRIC"/>
        </cds-radio>
        <cds-radio>
          <label>Imperial</label>
          <input autocomplete="off" type="radio" formControlName="measurementSystemType" [value]="measurementSystem.IMPERIAL"/>
        </cds-radio>
      </cds-radio-group>

      <br/>
      <cds-radio-group layout="vertical">
        <label>Preferred Altitude Vertical Reference</label>
        <cds-radio>
          <label>W84</label>
          <input autocomplete="off" type="radio" formControlName="defaultVerticalReference" [value]="verticalReference.W84"/>
        </cds-radio>
        <cds-radio>
          <label>MSL</label>
          <input autocomplete="off" type="radio" formControlName="defaultVerticalReference" [value]="verticalReference.MSL"/>
        </cds-radio>
        <cds-radio>
          <label>AGL</label>
          <input autocomplete="off" type="radio" formControlName="defaultVerticalReference" [value]="verticalReference.AGL"/>
        </cds-radio>
      </cds-radio-group>

      <br/>
      <form formGroupName="clearFormAfterSubmission">
        <cds-toggle-group layout="vertical">
          <label>Clear forms after submission</label>
          <cds-toggle *ngIf="showOperation">
            <label>Operation Creation</label>
            <input autocomplete="off" type="checkbox" checked formControlName="operationSubmission"/>
            <!--<cds-control-message>message text</cds-control-message>-->
            <cds-control-message>
              <span style="color:#E6B000; font-size: initial;" *ngIf="fg.controls.clearFormAfterSubmission.value.operationSubmission">
                Note: If this setting is enabled and an operation fails to be accepted, the user must reenter all operation details.
              </span>
            </cds-control-message>
          </cds-toggle>
          <cds-toggle *ngIf="showConstraint">
            <label>Constraint Creation</label>
            <input autocomplete="off" type="checkbox" checked formControlName="constraintSubmission"/>
            <cds-control-message>
              <span style="color:#E6B000; font-size: initial;" *ngIf="fg.controls.clearFormAfterSubmission.value.constraintSubmission">
                Note: If this setting is enabled and a constraint fails to be accepted, the user must reenter all constraint details.
              </span>
            </cds-control-message>
          </cds-toggle>
          <cds-toggle *ngIf="showPlatform">
            <label>Platform Creation</label>
            <input autocomplete="off" type="checkbox" checked formControlName="platformSubmission"/>
            <!--<cds-control-message>message text</cds-control-message>-->
          </cds-toggle>
          <cds-toggle *ngIf="showUser">
            <label>User Creation</label>
            <input autocomplete="off" type="checkbox" checked formControlName="userSubmission"/>
            <!--<cds-control-message>message text</cds-control-message>-->
          </cds-toggle>
        </cds-toggle-group>
      </form>
    </div>

    <div *ngIf="showOperation" class="clr-col-12 clr-col-md-4 clr-col-xl-3">
      <h2 cds-text="section">Operation Editor Settings</h2>

      <clr-input-container class="input-small">
        <label>Default Operation Start Time <br/>(Minutes in Future)</label>
        <input clrInput autocomplete="off" formControlName="operationStartOffset" type="number" step="1">
        <clr-control-helper>If this field is empty, start time offset will default to {{defaultOperationStartOffset}} minutes.</clr-control-helper>
        <clr-control-error *clrIfError="'min'">Start time cannot be less than {{operationStartOffsetLimits.min}} minutes in the future</clr-control-error>
        <clr-control-error *clrIfError="'max'">Start time cannot be greater than {{operationStartOffsetLimits.max}} minutes in the future</clr-control-error>
        <clr-control-error *clrIfError="'notInteger'">Start time offset must be an integer</clr-control-error>
      </clr-input-container>

      <clr-input-container class="input-small">
        <label>Default Operation Duration (Minutes)</label>
        <input clrInput autocomplete="off" formControlName="operationDuration" type="number" step="1">
        <clr-control-helper>If this field is empty, duration will default to {{defaultOperationDuration}} minutes.</clr-control-helper>
        <clr-control-error *clrIfError="'min'">Duration cannot be less than {{operationDurationLimits.min}} minute</clr-control-error>
        <clr-control-error *clrIfError="'max'">Duration cannot be greater than {{operationDurationLimits.max}} minutes</clr-control-error>
        <clr-control-error *clrIfError="'notInteger'">Duration must be an integer</clr-control-error>
      </clr-input-container>

      <clr-select-container>
        <label>Default Operation Activation</label>
        <select autocomplete="off" clrSelect formControlName="operationActivation">
          <option *ngFor="let option of operationActivationOptions" [value]="option.value">{{option.name}}</option>
        </select>
      </clr-select-container>
    </div>

    <div *ngIf="showConstraint" class="clr-col-12 clr-col-md-4 clr-col-xl-3">
      <h2 cds-text="section">Constraint Editor Settings</h2>
      <clr-input-container class="input-small">
        <label>Default Constraint Start Time <br/>(Minutes in Future)</label>
        <input clrInput autocomplete="off" formControlName="constraintStartOffset" type="number" step="1">
        <clr-control-helper>If this field is empty, start time offset will default to {{defaultConstraintStartOffset}} minutes.</clr-control-helper>
        <clr-control-error *clrIfError="'min'">Start time cannot be less than {{constraintStartOffsetLimits.min}} minutes in the future</clr-control-error>
        <clr-control-error *clrIfError="'max'">Start time cannot be greater than {{constraintStartOffsetLimits.max}} minutes in the future</clr-control-error>
        <clr-control-error *clrIfError="'notInteger'">Start time offset must be an integer</clr-control-error>
      </clr-input-container>

      <clr-input-container class="input-small">
        <label>Default Constraint Duration (Minutes)</label>
        <input clrInput autocomplete="off" formControlName="constraintDuration" type="number" step="1">
        <clr-control-helper>If this field is empty, duration will default to {{defaultConstraintDuration}} minutes.</clr-control-helper>
        <clr-control-error *clrIfError="'min'">Duration cannot be less than {{constraintDurationLimits.min}} minute</clr-control-error>
        <clr-control-error *clrIfError="'notInteger'">Duration must be an integer</clr-control-error>
      </clr-input-container>
    </div>
  </div>

  <cds-button type="submit" class="btn-submit" [attr.disabled]="fg.invalid? '': null" (click)="saveForm()">submit</cds-button>
  <p class="version">USS version {{ussVersion}}</p>
</form>

@if (!supportedIntegrations$()) {
  <div class="spinner-wrapper">
    <span class="spinner"></span>
    <p>Loading integrations</p>
  </div>
}
@if (noSupportedIntegrations) {
  <p>This USS does not support any telemetry integrations.</p>
}
<div class="clr-row">
  <form [formGroup]="fg" class="clr-col-md-6">
    <!-- COT Integrations List -->
    @if (supportedIntegrations$() && (supportedIntegrations$().COT || !!fg.controls.cot.value?.length)) {
      <h6>COT (Cursor on Target)</h6>
      @if (!supportedIntegrations$().COT) {
        <ng-container *ngTemplateOutlet="unsupportedIntegrationMsg"></ng-container>
      }
      @if (fg.controls.cot?.controls?.length) {
        <table class="table table-compact" aria-label="COT integrations">
          <thead>
            <th>COT Call Sign</th>
            <th>COT UID</th>
            <th>Altitude Uses MSL</th>
            <th class="col-actions"></th>
          </thead>

          <tbody>
            @for (cot of fg.controls.cot.controls; track $index) {
              <tr>
                <td>{{cot.controls.cotCallsign.value}}</td>
                <td>{{cot.controls.cotUid.value}}</td>
                <td>{{cot.controls.cotAltitudeUsesMsl.value ? 'Yes' : 'No'}}</td>
                <td class="col-actions">
                  @if (supportedIntegrations$()?.COT) {
                    <cds-icon-button [action]="'flat'" (click)="openFormModal(telemetryType.COT, $index)" class="btn-edit-integration">
                      <cds-icon shape="pencil" size="sm"></cds-icon>
                    </cds-icon-button>
                  }
                  <cds-icon-button [action]="'flat'" (click)="removeIntegration(telemetryType.COT, $index)" class="btn-remove-integration">
                    <cds-icon shape="trash" size="sm"></cds-icon>
                  </cds-icon-button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      }
      <cds-button action="outline" size="sm" class="btn-add-integration" (click)="openFormModal(telemetryType.COT)">
        <cds-icon shape="plus"></cds-icon> Add New COT Integration</cds-button>
    }

    <!-- Rigitech Integrations List -->
    @if (supportedIntegrations$() && (supportedIntegrations$().RIGITECH || !!fg.controls.rigitech.value?.length)) {
      <h6>Rigitech</h6>
      @if (!supportedIntegrations$().RIGITECH) {
        <ng-container *ngTemplateOutlet="unsupportedIntegrationMsg"></ng-container>
      }
      @if (fg.controls.rigitech?.controls?.length) {
        <table class="table table-compact" aria-label="Rigitech integrations">
          <thead>
            <th>Drone ID</th>
            <th>Serial Number</th>
            <th></th>
            <th class="col-actions"></th>
          </thead>

          <tbody>
            @for (rigitech of fg.controls.rigitech.controls; track $index) {
              <tr>
                <td>{{rigitech.controls.rigitechDroneId.value}}</td>
                <td>{{rigitech.controls.rigitechSerialNumber.value}}</td>
                <td></td>
                <td class="col-actions">
                  @if (supportedIntegrations$()?.RIGITECH) {
                    <cds-icon-button [action]="'flat'" (click)="openFormModal(telemetryType.RIGITECH, $index)" class="btn-edit-integration">
                      <cds-icon shape="pencil" size="sm"></cds-icon>
                    </cds-icon-button>
                  }
                  <cds-icon-button [action]="'flat'" (click)="removeIntegration(telemetryType.RIGITECH, $index)" class="btn-remove-integration">
                    <cds-icon shape="trash" size="sm"></cds-icon>
                  </cds-icon-button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      }
      <cds-button action="outline" size="sm" class="btn-add-integration" (click)="openFormModal(telemetryType.RIGITECH)">
        <cds-icon shape="plus"></cds-icon> Add New Rigitech Integration</cds-button>
    }

    <!-- Mavlink Integrations List -->
    @if (supportedIntegrations$() && (supportedIntegrations$().MAVLINK || !!fg.controls.mavlink.value?.length)) {
      <h6>Mavlink</h6>
      @if (!supportedIntegrations$().MAVLINK) {
        <ng-container *ngTemplateOutlet="unsupportedIntegrationMsg"></ng-container>
      }
      @if (fg.controls.mavlink?.controls?.length) {
        <table class="table table-compact" aria-label="mavlink-integrations">
          <thead>
            <th>Protocol</th>
            <th>Port</th>
            <th></th>
            <th class="col-actions"></th>
          </thead>

          <tbody>
            @for (mavlink of fg.controls.mavlink.controls; track $index) {
              <tr>
                <td>{{mavlink.controls.protocol.value}}</td>
                <td>{{mavlink.controls.port.value}}</td>
                <td></td>
                <td class="col-actions">
                  @if (supportedIntegrations$()?.MAVLINK) {
                    <cds-icon-button [action]="'flat'" (click)="openFormModal(telemetryType.MAVLINK, $index)" class="btn-edit-integration">
                      <cds-icon shape="pencil" size="sm"></cds-icon>
                    </cds-icon-button>
                  }
                  <cds-icon-button [action]="'flat'" (click)="removeIntegration(telemetryType.MAVLINK, $index)" class="btn-remove-integration">
                    <cds-icon shape="trash" size="sm"></cds-icon>
                  </cds-icon-button>
                </td>
              </tr>
            }
          </tbody>
        </table>
      }
      <cds-button action="outline" size="sm" class="btn-add-integration" (click)="openFormModal(telemetryType.MAVLINK)">
        <cds-icon shape="plus"></cds-icon> Add New Mavlink Integration</cds-button>
    }
  </form>
</div>

<!--New COT Integration Form-->
<clr-modal [(clrModalOpen)]="modalOpenStatus.cot" (clrModalOpenChange)="handleModalOpenChange($event)">
  <h3 class="modal-title">Add New COT Integration</h3>
  <div class="modal-body">
    <ng-container [formGroup]="cotEditorFg">
      <error-container [control]="cotEditorFg">
        <error-message [has]="'fieldMissing'">Either the COT UID or Call Sign field must have a value</error-message>
      </error-container>
      <clr-input-container>
        <label>Call Sign</label>
        <input clrInput formControlName="cotCallsign"/>
        <clr-control-helper>This field is required if UID is missing<br/>
          {{cotEditorFg.controls.cotCallsign?.value?.length || 0}}/64</clr-control-helper>
        <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{cotEditorFg.controls.cotCallsign.errors.invalidCharacters.characters}}</clr-control-error>
        <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{cotEditorFg.controls.cotCallsign.value.length || 0}}
          /{{cotEditorFg.controls.cotCallsign.errors.maxlength.requiredLength}})</clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>UID</label>
        <input clrInput formControlName="cotUid"/>
        <clr-control-helper>This field is required if call sign is missing<br/>
          {{cotEditorFg.controls.cotUid?.value?.length || 0}}/64</clr-control-helper>
        <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{cotEditorFg.controls.cotUid.errors.invalidCharacters.characters}}</clr-control-error>
        <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{cotEditorFg.controls.cotUid.value.length || 0}}
          /{{cotEditorFg.controls.cotUid.errors.maxlength.requiredLength}})</clr-control-error>
      </clr-input-container>
      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <label>Altitude Uses MSL</label>
          <input type="checkbox" clrCheckbox formControlName="cotAltitudeUsesMsl"/>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="addCotFg(cotEditorFg.getRawValue())" [disabled]="cotEditorFg.invalid">Save</button>
  </div>
</clr-modal>

<!--New Rigitech Integration Form-->
<clr-modal [(clrModalOpen)]="modalOpenStatus.rigitech" (clrModalOpenChange)="handleModalOpenChange($event)">
  <h3 class="modal-title">Add New Rigitech Integration</h3>
  <div class="modal-body">
    <ng-container [formGroup]="rigitechEditorFg">
      <error-container [control]="rigitechEditorFg">
        <error-message [has]="'fieldMissing'">Either the Drone ID or Serial Number field must have a value</error-message>
      </error-container>
      <clr-input-container>
        <label>Drone ID</label>
        <input clrInput formControlName="rigitechDroneId" type="number" step="1"/>
        <clr-control-helper>This field is required if serial number is missing</clr-control-helper>
        <clr-control-error *clrIfError="'notInteger'">An integer must be entered</clr-control-error>
      </clr-input-container>
      <clr-input-container>
        <label>Serial Number</label>
        <input clrInput formControlName="rigitechSerialNumber"/>
        <clr-control-helper>This field is required if drone ID is missing<br/>
          {{rigitechEditorFg.controls.rigitechSerialNumber?.value?.length || 0}}/64</clr-control-helper>
        <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{rigitechEditorFg.controls.rigitechSerialNumber.errors.invalidCharacters.characters}}</clr-control-error>
        <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{rigitechEditorFg.controls.rigitechSerialNumber.value.length || 0}}
          /{{rigitechEditorFg.controls.rigitechSerialNumber.errors.maxlength.requiredLength}})</clr-control-error>
      </clr-input-container>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="addRigitechFg(rigitechEditorFg.getRawValue())" [disabled]="rigitechEditorFg.invalid">Save</button>
  </div>
</clr-modal>

<!--New Mavlink Integration Form-->
<clr-modal [(clrModalOpen)]="modalOpenStatus.mavlink" (clrModalOpenChange)="handleModalOpenChange($event)">
  <h3 class="modal-title">Add New Mavlink Integration</h3>
  <div class="modal-body">
    <ng-container [formGroup]="mavlinkEditorFg">
      <clr-select-container>
        <label class="required-custom">Protocol</label>
        <select autocomplete="off" clrSelect formControlName="protocol">
          @for (protocol of availableProtocols; track $index) {
            <option [value]="protocol.value">{{protocol.label}}</option>
          }
        </select>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
      </clr-select-container>
      <clr-input-container>
        <label class="required-custom">Port</label>
        <input clrInput formControlName="port" type="number" step="1"/>
        <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
        <clr-control-error *clrIfError="'notInteger'">An integer must be entered</clr-control-error>
        <clr-control-error *clrIfError="'min'">Minimum port is {{mavlinkEditorFg.controls.port.errors.min.min}}</clr-control-error>
      </clr-input-container>
    </ng-container>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" (click)="addMavlinkFg(mavlinkEditorFg.getRawValue())" [disabled]="mavlinkEditorFg.invalid">Save</button>
  </div>
</clr-modal>

<ng-template #unsupportedIntegrationMsg>
  <p class="unsupported-integration-msg">
    <cds-icon shape="warning-standard" size="small" solid="true"></cds-icon>This integration is no longer supported by this USS.</p>
</ng-template>

import {Component} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OffNominalReport} from '../../shared/model/OffNominalReport';
import {OffNominalReportService} from '../../shared/services/off-nominal-report.service';

@Component({
  selector: 'app-off-nominal-report',
  templateUrl: './off-nominal-report.component.html'
})
export class OffNominalReportComponent {
  offNominalReportForm: UntypedFormGroup;
  roleResponsibilityOptions: { key: string; text: string }[];
  reportReasonOptions: { key: string; text: string }[];
  scriptedEventOptions: { text: string; key: string }[];
  contingencyOptions: { text: string; key: string }[];
  autopilotMakerOptions: { text: string; key: string }[];
  autopilotModelOptions: { text: string; key: string }[];
  gcsProgramOptions: { text: string; key: string }[];
  commSystemOptions: { text: string; key: string }[];
  flightRuleOptions: { text: string; key: string }[];
  gufiOptions: { text: string; key: string }[];
  dataCollectionOptions: { text: string; key: string }[];
  ussNameOptions: { text: string; key: string }[];
  demoOption: any;

  submittingReport: boolean;

  constructor(private offNominalReportService: OffNominalReportService) {
    // eslint-disable-next-line no-underscore-dangle
    this._initControls();
  }

  submitReport() {
    const rawReportValue = this.offNominalReportForm.getRawValue();
    const report = OffNominalReport.fromValue(rawReportValue);
    this.submittingReport = true;
    this.offNominalReportService.submitReport(report).subscribe(() => {
      this.submittingReport = false;
    });
  }

  applyDemoValues() {
    if (this.demoOption ===  'demo1') {
      const isoStr = new Date().toISOString();

      this.offNominalReportForm.setValue({
        fullName: 'Demo 1',
        email: 'demo@axenterprize.com',
        phoneNumber: '111-111-1111',
        roleResponsibility: 'PILOT',
        reportReason: 'NONCONFORMING',
        scriptedEvent: 'false',
        contingencyId: 'c313fcd5-c4c6-42e4-9c46-37145065c701',
        autopilotMaker: 'DJI',
        autopilotModel: 'DJI',
        gcsProgram: 'Mission_Planner',
        commSystem: 'C2oIP',
        reporterNarrative: 'This is a demo.',
        flightRule: 'PART_107',
        timeOccurance: isoStr.substring(0, isoStr.length - 1),
        gufi: '390a22ad-9f0b-4b5b-b02d-7d1c49f08e0e',
        dataCollection: 'false',
        callSign: 'AX Call Sign',
        ussName: 'AX-UPP2-USS',
        testRun: 3,
      });
    }
    this.demoOption = 'no';
  }

  private _initControls() {
    this.roleResponsibilityOptions = [
      {key: 'PILOT', text: 'Pilot'},
      {key: 'TEST-DIRECTOR', text: 'Test Director'},
      {key: 'MISSION-COMMANDER', text: 'Mission Commander'},
      {key: 'FLIGHT-ENGINEER', text: 'Flight Engineer'},
      {key: 'VISUAL-OBSERVER', text: 'Visual Observer'},
    ];
    this.reportReasonOptions = [
      {key: 'NONCONFORMING', text: 'Flight went non-conforming.'},
      {key: 'ROGUE', text: 'Flight went rogue.'},
      {key: 'NONCONFORMING2ROGUE', text: 'Flight went non-conforming and then rogue.'},
      {key: 'UNPLANNED_RTL', text: 'Flight experienced unplanned return to launch.'},
      {key: 'UNPLANNED_LOITER', text: 'Flight experienced unplanned loiter.'},
      {key: 'UNPLANNED_LANDING', text: 'Flight experienced unplanned landing.'},
      {key: 'VOLUNTEER', text: 'This is a voluntary submission.'},
    ];
    this.scriptedEventOptions = [
      {key: 'true', text: 'Yes'},
      {key: 'false', text: 'No'},
    ];
    this.contingencyOptions = [
      {key: 'c313fcd5-c4c6-42e4-9c46-37145065c701', text: 'c313fcd5-c4c6-42e4-9c46-37145065c701'},
    ];
    this.autopilotMakerOptions = [
      {key: 'ProfiCNC', text: 'ProfiCNC'},
      {key: 'DJI', text: 'DJI'},
    ];
    this.autopilotModelOptions = [
      {key: 'Pixhawk2_1', text: 'Pixhawk 2.1'},
      {key: 'DJI', text: 'DJI'},
    ];
    this.gcsProgramOptions = [
      {key: 'Mission_Planner', text: 'Mission Planner'},
      {key: 'DJI-GSPro', text: 'DJI Ground Station Pro'},
      {key: 'Hand Controller', text: 'Hand Controller'},
    ];
    this.commSystemOptions = [
      {key: 'C2oIP', text: 'C2oIP'},
      {key: '900MHz', text: '900 MHz'},
      {key: 'WiFi-2_4', text: '2.4 GHz WiFi'},
      {key: 'WiFi-5', text: '5 GHz WiFi'},
      {key: 'LTE-VZW', text: 'Verizon LTE'},
      {key: 'LTE-ATT', text: 'AT&T LTE'},
    ];
    this.flightRuleOptions = [
      {key: 'PART_107', text: 'Part 107'},
      {key: 'PART_107x', text: 'Part 107x'},
      {key: 'PART_101e', text: 'Part 101e'},
      {key: 'OTHER', text: 'Other.'},
    ];
    this.gufiOptions = [
      {key: '390a22ad-9f0b-4b5b-b02d-7d1c49f08e0e', text: '390a22ad-9f0b-4b5b-b02d-7d1c49f08e0e'},
    ];
    this.dataCollectionOptions = [
      {key: 'true', text: 'True'},
      {key: 'false', text: 'False'}
    ];
    this.ussNameOptions = [
      {key: 'AX-UPP2-USS', text: 'AX UPP2 USS'},
      {key: 'ANRA', text: 'ANRA Technologies'},
      {key: 'ONESKY', text: 'AGI OneSky'},
      {key: 'AIRXOS', text: 'GE AiRXOS'},
    ];
    this.offNominalReportForm = new UntypedFormGroup({
      fullName: new UntypedFormControl('', [Validators.required]),
      email: new UntypedFormControl('', [Validators.required]),
      phoneNumber: new UntypedFormControl('', [Validators.required]),
      roleResponsibility: new UntypedFormControl('', [Validators.required]),
      reportReason: new UntypedFormControl('', []),
      scriptedEvent: new UntypedFormControl('', []),
      contingencyId: new UntypedFormControl('', []),
      autopilotMaker: new UntypedFormControl('', []),
      autopilotModel: new UntypedFormControl('', []),
      gcsProgram: new UntypedFormControl('', []),
      commSystem: new UntypedFormControl('', []),
      reporterNarrative: new UntypedFormControl('', []),
      flightRule: new UntypedFormControl('', []),
      timeOccurance: new UntypedFormControl('', []),
      gufi: new UntypedFormControl('', []),
      dataCollection: new UntypedFormControl('true', []),
      callSign: new UntypedFormControl('AX-Callsign', []),
      ussName: new UntypedFormControl('AX-UPP2-USS', []),
      testRun: new UntypedFormControl(1, []),
    });
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OperationVolumeDrawerComponent} from './operation-volume-drawer/operation-volume-drawer.component';
import {OperationGeometryDrawerComponent} from './operation-geometry-drawer/operation-geometry-drawer.component';
import {OperationSitrepDrawerComponent} from './operation-sitrep-drawer/operation-sitrep-drawer.component';
import {ConstraintSitrepDrawerComponent} from './constraint-sitrep-drawer/constraint-sitrep-drawer.component';
import {SafireButtonComponent} from './common/safire-button/safire-button.component';
import {SafireButtonContentDirective} from './common/safire-button/safire-button-content.directive';
import {ConstraintVolumeDrawerComponent} from './constraint-volume-drawer/constraint-volume-drawer.component';
import {ConstraintGeometryDrawerComponent} from './constraint-geometry-drawer/constraint-geometry-drawer.component';


@NgModule({
  declarations: [
    OperationVolumeDrawerComponent,
    ConstraintVolumeDrawerComponent,
    OperationGeometryDrawerComponent,
    OperationSitrepDrawerComponent,
    ConstraintSitrepDrawerComponent,
    SafireButtonComponent,
    SafireButtonContentDirective,
    ConstraintGeometryDrawerComponent
  ],
  imports: [
    CommonModule
  ],
    exports: [
        OperationVolumeDrawerComponent,
        ConstraintVolumeDrawerComponent,
        OperationGeometryDrawerComponent,
        OperationSitrepDrawerComponent,
        ConstraintSitrepDrawerComponent,
        SafireButtonComponent,
        SafireButtonContentDirective,
        ConstraintGeometryDrawerComponent
    ]
})
export class CesiumModule { }

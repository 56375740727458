import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BaseImportConfigComponent} from './components/base-import-config/base-import-config.component';
import {ImportExportUtilComponent} from './components/import-export-util/import-export-util.component';
import {ImportExportConfigHostDirective} from './components/import-export-util/import-export-config-host.directive';
import {ClarityModule} from '@clr/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [BaseImportConfigComponent, ImportExportUtilComponent, ImportExportConfigHostDirective],
  exports: [
    BaseImportConfigComponent, ImportExportUtilComponent, ImportExportConfigHostDirective
  ],
  imports: [
    CommonModule,
    ClarityModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class ImportExportModule { }

<form [formGroup]="fg">
  <div class="clr-row">
    <clr-checkbox-container class="clr-col-lg-2">
      <label>States</label>
      <clr-checkbox-wrapper *ngFor="let statOp of stateOptions">
        <input autocomplete="off" type="checkbox" clrCheckbox name="state" [value]="statOp" [formControlName]="'state_' + statOp" (change)="onStateChange($event)" />
        <label>{{getPrettyConstraintStateName(statOp)}}</label>
      </clr-checkbox-wrapper>
    </clr-checkbox-container>
<!--    Uncomment this code to enable the "After start time" filter
    <div class="clr-col-lg-2">
      <label class="clr-control-label">After start time:</label> <br/>
      <mat-form-field>
        <input autocomplete="off" matInput [ngxMatDatetimePicker]="picker" placeholder="Choose a date" formControlName="startTimeAfter">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="true" [hideTime]="false">
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <cds-icon shape="times" (click)="clearStartTime()" *ngIf="!!fg.controls.startTimeAfter.value"></cds-icon>
    </div>-->
  </div>
  <!--    Uncomment this code to enable sort fields
  <div class="clr-row">
    <div class="clr-col-lg-2">
      <h6>Sort by</h6>
      <a *ngFor="let f of fg.controls.sort.value; index as i" class="label label-info clickable noselect" (click)="removeSortField(f)">{{this.sortOptions[f]}}
        <span class="badge">{{i + 1}}</span>
      </a>
      <h6>Available Sort Fields</h6>
      <a *ngFor="let f of unusedSortOptions()" class="label label-info clickable noselect" (click)="addSortField(f)">
        {{this.sortOptions[f]}}</a>
      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <label>Sort Ascending</label>
          <input autocomplete="off" type="checkbox" clrCheckbox formControlName="sortIncreasing"/>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
    </div>
  </div>-->
</form>
<button *ngIf="showUpdateButton" (click)="emitUpdate()" class="btn btn-primary">Update</button>

import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {Router} from '@angular/router';
import {OperationDraft, OperationDraftService} from '../../../services/operation-draft.service';

@Component({
  selector: 'app-draft-selector',
  templateUrl: './draft-selector.component.html',
  styleUrls: ['./draft-selector.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => DraftSelectorComponent),
  }]
})
export class DraftSelectorComponent implements OnInit, OnChanges, OnDestroy, ControlValueAccessor {
  @Input() draftId: string;
  @Output() draftLoaded: EventEmitter<string> = new EventEmitter<string>();
  @Output() draftDeleted: EventEmitter<OperationDraft> = new EventEmitter<OperationDraft>();
  @Input() draftText = 'Select a draft';
  isDisabled = true;
  selectingDraft: boolean;
  drafts: OperationDraft[] = [];
  currentPageSize: number;
  totalItems: number;
  loading = true;
  state: ClrDatagridStateInterface;
  previousState: ClrDatagridStateInterface;
  private onChange: (selectedDraft: OperationDraft) => void;
  private onTouched: () => void;
  private draftSubscription: Subscription;
  private draftSubject = new BehaviorSubject<OperationDraft[]>([]);
  private subscriptions: Subscription[] = [];
  private draftTextSub: Subscription;
  private deleteDraftSub: Subscription;

  constructor(private operationDraftService: OperationDraftService, private router: Router) {
    this.subscriptions.push(this.operationDraftService.getDrafts().subscribe(res => {
      this.drafts = res;
    }));
  }

  registerOnChange(fn: (selectedDraft: OperationDraft) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: string): void {
    this.draftId = obj;
  }

  ngOnInit(): void {
    // Subscribe to watchDrafts, which returns the draftSubject that is populated with the output of operationDraftService.getDrafts()
    this.subscriptions.push(this.watchDrafts().subscribe(drafts => {
      this.isDisabled = drafts.length === 0;
      if (drafts.length > 0) {
        this.totalItems = drafts.length;
      }
      this.drafts = drafts;
      this.selectingDraft = this.selectingDraft && !this.isDisabled;
      this.loading = false;
    }));
    // this.setDraftText();
    // Update the draftSubject w/the current output of operationDraftService.getDrafts()
    this.refreshDrafts();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.draftId) {
      this.refreshDrafts();
    }
  }

  ngOnDestroy(): void {
    if (this.subscriptions) {
      for (const subscription of this.subscriptions) {
        if (!subscription.closed) {
          subscription.unsubscribe();
        }
      }
    }
    this.draftTextSub?.unsubscribe();
    this.deleteDraftSub?.unsubscribe();
  }

  watchDrafts(): Observable<OperationDraft[]> {
    return this.draftSubject.asObservable();
  }

  openDraftSelector($event: MouseEvent) {
    this.selectingDraft = true;
    this.refreshDrafts();

    $event.stopPropagation();
  }

  refresh(statey: ClrDatagridStateInterface) {
    this.loading = true;
    if (!statey) {
      statey = this.state || {};
    }

    this.state = statey;

    if (!statey.page) {
      statey.page = {
        from: 0,
        to: 9,
        size: 10,
      };
    }

    this.refreshDrafts();
    this.previousState = statey;
  }

  refreshDrafts(): void {
    if (this.draftSubscription) {
      this.draftSubscription.unsubscribe();
    }
    // Populate the draftSubject
    this.draftSubscription = this.operationDraftService.getDrafts().subscribe((drafts) => {
      this.draftSubject.next(drafts);
    });
    this.setDraftText();
  }

  selectDraft(draft: OperationDraft) {
    this.selectingDraft = false;
    this.draftLoaded.emit(draft.id);
    this.setDraftText();
    this.router.navigate(['fuss', 'operations', 'newop'], {queryParams: {draftId: draft.id}});
  }

  deleteDraft(draft: OperationDraft): void {
    this.deleteDraftSub?.unsubscribe();
    this.deleteDraftSub = this.operationDraftService.deleteDraft(draft.id).subscribe((res) => {
      if (res){
        this.draftDeleted.emit(draft);
        this.refreshDrafts();
      }
      else{
        console.error(`Error deleting draft: ${draft.id}`);
      }
    });

  }

  setDraftText(): void {
    const defaultText = 'Select a draft';
    if (this.draftId) {
      this.draftTextSub?.unsubscribe();
      this.draftTextSub = this.operationDraftService.getDraftFromUUID(this.draftId).subscribe(draft => {
        if (draft) {
          this.draftText = draft.submission.flight_number;
        } else {
          this.draftText = defaultText;
        }
      });
    } else {
      this.draftText = defaultText;
    }

  }

}

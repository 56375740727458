import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {LeafletDrawerService} from '@ax/ax-angular-map-leaflet';
import {SituationalAwarenessService} from '../../../services/situational-awareness.service';
import * as L from 'leaflet';
import {LatLng} from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import {BehaviorSubject, combineLatest, forkJoin, interval, Observable, Subscription} from 'rxjs';
import {EntityVolume4d, OperationVolume, state, units_of_measure, vertical_reference} from '../../../model/gen/utm';
import {OperationExt} from '../../../model/utm/OperationExt';
import {UvrExt} from '../../../model/utm/UvrExt';
import {of} from 'rxjs/internal/observable/of';
import {ConstraintTypeService} from '../../../services/constraint-type.service';
import {ColorService} from '../../../services/color.service';
import {debounceTime, map, take} from 'rxjs/operators';
import {UserSettingsService} from '../../../services/user-settings.service';
import {MeasurementSystemType} from 'src/app/shared/model/MeasurementSystem';
import {DateTime} from 'luxon';
import {AltitudeUtilService} from '../../../model/utm/altitude-util.service';
import * as _ from 'lodash';
import {Converter} from '../../../utils/convert-units';
import {AltitudeRange} from '../../../model/gen/utm/altitude-range-model';
import {AltitudeService, IAltitudeConversionParameters} from '../../../services/altitude.service';

interface IAltitudeUnitsRefsPair {
  units: units_of_measure;
  ref: vertical_reference;
}

interface EntityLayerGroup {
  id: string;
  new: boolean;
  update_time?: DateTime;
  group: L.FeatureGroup;
}

@Component({
  selector: 'app-leaflet-sitrep-drawer',
  templateUrl: './leaflet-sitrep-drawer.component.html'
})
export class LeafletSitrepDrawerComponent implements OnDestroy, OnChanges, OnInit {
  @Input() enabled = false;
  @Input() altRef: vertical_reference;
  @Input() altUnits: units_of_measure;
  @Output() enabledChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  private system: MeasurementSystemType;
  private sitrepFeatureGroup: L.FeatureGroup;
  private map: L.Map;
  private sitRepBtn: L.PM.Button;
  private entityLayerGroupCache: { [k: string]: EntityLayerGroup } = {};
  private operationCache: { [k: string]: OperationExt } = {};
  private constraintCache: { [k: string]: UvrExt } = {};
  private altitudeUnitsRefsCache: { [key: string]: IAltitudeUnitsRefsPair[] } = {};
  private enabledSubject = new BehaviorSubject<boolean>(false);
  private altRefSubject = new BehaviorSubject(null);
  private altUnitsSubject = new BehaviorSubject(null);
  private leafletInitSubscription: Subscription;
  private sitOpsSub: Subscription;
  private utmFeatSub: Subscription;
  private enabledProcessSub: Subscription;
  private measurementSystemSubscription: Subscription;
  private sitrepRefreshSubscription: Subscription;
  private descriptionSubscriptions: { [k: string]: Subscription[] } = {};
  private altitudeSubscriptions: { [k: string]: Subscription[] } = {};

  constructor(private leafletDrawerService: LeafletDrawerService,
              private colorService: ColorService,
              private situationalAwarenessService: SituationalAwarenessService,
              private constraintTypeService: ConstraintTypeService,
              private userSettingsService: UserSettingsService,
              private altitudeService: AltitudeService,
              private altitudeUtilService: AltitudeUtilService) {

  }

  ngOnInit(): void {
    this.measurementSystemSubscription = this.userSettingsService.getMeasurementSystem().subscribe(system => {
      this.system = system;
      this.leafletInitSubscription = this.leafletDrawerService.watchViewerInit().subscribe((mapy) => {
        this.handleMapInit(mapy);
      });
      this.enabledProcessSub = this.enabledSubject.pipe(debounceTime(100)).subscribe(() => {
        this.processEnabledState();
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.enabled) {
      this.enabledSubject.next(changes.enabled.currentValue);
    }
    if (changes.altRef && changes.altRef.previousValue !== changes.altRef.currentValue) {
      this.altRefSubject.next(changes.altRef.currentValue);
    }
    if (changes.altUnits && changes.altUnits.previousValue !== changes.altUnits.currentValue) {
      this.altUnitsSubject.next(changes.altUnits.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.measurementSystemSubscription?.unsubscribe();
    this.leafletInitSubscription?.unsubscribe();
    this.sitOpsSub?.unsubscribe();
    this.utmFeatSub?.unsubscribe();
    // @ts-ignore
    this.sitRepBtn?.remove();
    this.enabledProcessSub?.unsubscribe();
    this.sitrepRefreshSubscription?.unsubscribe();
    for (const id of Object.keys(this.descriptionSubscriptions)) {
      this.descriptionSubscriptions[id].forEach(sub => sub?.unsubscribe());
    }
    for (const id of Object.keys(this.altitudeSubscriptions)) {
      this.altitudeSubscriptions[id].forEach(sub => sub?.unsubscribe());
    }
  }

  processEnabledState() {
    if (!this.map) {
      return;
    }
    if (this.enabled) {
      this.enableSitRep();
    } else {
      this.disableSitRep();
    }

    const btn = this.sitRepBtn as L.PM.Button & { buttonsDomNode: HTMLElement, _button: { className: string } };
    if (!btn) {
      return;
    }

    btn._button.className = 'control-icon ' + (this.enabled ? 'fa fa-eye' : 'fa fa-eye-slash');
    if (!btn.buttonsDomNode) {
      return;
    }
    const node = btn.buttonsDomNode.querySelector('.control-icon');
    node.className = btn._button.className;
  }

  private getOperationFeatureGroup(operation: OperationExt): Observable<EntityLayerGroup> {
    this.descriptionSubscriptions[operation.operationId]?.forEach(sub => sub?.unsubscribe());
    this.altitudeSubscriptions[operation.operationId]?.forEach(sub => sub?.unsubscribe());
    if (this.altitudeUnitsRefsCache[operation.operationId]) {
      delete this.altitudeUnitsRefsCache[operation.operationId];
    }
    return forkJoin(operation.operation_volumes.map(vol => {
      return this.convertOperationVolumeToPolygons(vol, operation);
    })).pipe(map(volumeGroups => {
      const operationLayerGroup = new L.FeatureGroup();
      for (const grp of volumeGroups) {
        operationLayerGroup.addLayer(grp);
      }
      return {
        id: operation.operationId,
        new: true,
        update_time: operation.update_time,
        group: operationLayerGroup
      };
    }));
  }

  private handleMapInit(mapy: L.Map) {
    this.map = mapy;
    this.createSitRepButton();
    this.enabledSubject.next(this.enabled);
  }

  private createSitRepButton(): void {
    // tslint:disable-next-line:no-any
    if (this.sitRepBtn && this.map && ((this.sitRepBtn as any | undefined)?._map === this.map)) {
      return;
    }
    // @ts-ignore
    this.sitRepBtn = this.map.pm.Toolbar.createCustomControl({
      name: 'SitAware',
      block: 'custom',
      title: 'Show Operations/Constraints',
      className: this.enabled ? 'fa fa-eye' : 'fa fa-eye-slash',
      afterClick: () => {
        this.toggleEnabled();
      },
      toggle: false
    }) || undefined;

  }

  private toggleEnabled() {
    this.enabled = !this.enabled;
    this.enabledChange.emit(this.enabled);
    this.enabledSubject.next(this.enabled);
  }

  private enableSitRep() {
    if (!this.sitrepFeatureGroup) {
      this.sitrepFeatureGroup = new L.FeatureGroup();
    }
    this.sitrepFeatureGroup.pm.setOptions({
      allowEditing: false,
      allowRemoval: false
    });
    this.map.addLayer(this.sitrepFeatureGroup);
    this.sitOpsSub?.unsubscribe();
    this.sitOpsSub = this.situationalAwarenessService.watchAllUtm().subscribe((res) => {
      this.utmFeatSub?.unsubscribe();

      if (!res.operations.length && !res.constraints.length) {
        this.sitrepFeatureGroup.clearLayers();
        this.entityLayerGroupCache = {};
      }

      const groupsObservable: Observable<EntityLayerGroup[]> = forkJoin([
        ...res.operations.map(operation => {
          const id = operation.operationId;
          const ret = {
            id,
            new: false,
            update_time: this.operationCache[id]?.update_time,
            group: this.entityLayerGroupCache[id]?.group
          };
          if (ret.group && operation.update_time.equals(ret.update_time)) {
            return of(ret);
          }
          this.operationCache[id] = operation;
          return this.getOperationFeatureGroup(operation);
        }),
        ...res.constraints.map(constraint => {
          const id = constraint.message_id;

          const ret = {
            id,
            new: false,
            update_time: this.constraintCache[id]?.update_time,
            group: this.entityLayerGroupCache[id]?.group
          };
          if (ret.group && constraint.update_time.equals(ret.update_time)) {
            return of(ret);
          }
          this.constraintCache[id] = constraint;
          return this.getConstraintFeatureGroup(constraint);
        })
      ]);


      this.utmFeatSub = groupsObservable.subscribe((groups) => {
        const newLayerIds = groups.reduceRight((acc, cur) => {
          acc.add(cur.id);
          return acc;
        }, new Set<string>());

        for (const layerId of Object.keys(this.entityLayerGroupCache)) {
          if (!newLayerIds.has(layerId)) {
            this.sitrepFeatureGroup.removeLayer(this.entityLayerGroupCache[layerId].group);
            delete this.entityLayerGroupCache[layerId];
          }
        }

        for (const group of groups.filter(g => g.new)) {
          const id = group.id;
          const oldGroup = this.entityLayerGroupCache[id];
          if (oldGroup && group.update_time.equals(oldGroup.update_time)) {
            continue;
          }
          if (oldGroup && group.update_time.diff(oldGroup.update_time, 'seconds').as('seconds') > 0) {
            this.sitrepFeatureGroup.removeLayer(oldGroup.group);
          }

          this.sitrepFeatureGroup.addLayer(group.group);
          this.entityLayerGroupCache[id] = group;
        }

        this.sitrepFeatureGroup.bringToBack();
      });

    });
    this.sitrepRefreshSubscription?.unsubscribe();
    this.sitrepRefreshSubscription = interval(5000).subscribe(() => {
      this.situationalAwarenessService.refresh();
    });

  }

  private getConstraintFeatureGroup(constraint: UvrExt): Observable<EntityLayerGroup> {
    this.descriptionSubscriptions[constraint.message_id]?.forEach(sub => sub?.unsubscribe());
    this.altitudeSubscriptions[constraint.message_id]?.forEach(sub => sub?.unsubscribe());
    if (this.altitudeUnitsRefsCache[constraint.message_id]) {
      delete this.altitudeUnitsRefsCache[constraint.message_id];
    }
    return forkJoin(constraint.volumes.map(vol => {
      return this.convertConstraintVolumeToPolygons(vol, constraint);
    })).pipe(map(volumeGroups => {
      const constraintLayerGroup = new L.FeatureGroup();
      for (const grp of volumeGroups) {
        constraintLayerGroup.addLayer(grp);
      }
      return {
        id: constraint.message_id,
        new: true,
        update_time: constraint.update_time,
        group: constraintLayerGroup
      };
    }));
  }

  private disableSitRep() {
    this.sitOpsSub?.unsubscribe();
    this.sitrepRefreshSubscription?.unsubscribe();
    if (this.sitrepFeatureGroup && this.map) {
      this.map.removeLayer(this.sitrepFeatureGroup);
    }
    this.sitrepFeatureGroup = null;
    this.entityLayerGroupCache = {};
  }

  private convertOperationVolumeToPolygons(vol: OperationVolume, operation: OperationExt): Observable<L.FeatureGroup> {
    return combineLatest([
      this.constraintTypeService.getPrettyName(operation?.additional_data?.permitted_constraint_types[0]),
      this.colorService.getColorForId(this.colorService.getIdForState(operation.state), true)
    ]).pipe(take(1), map(([operationType, colorConfig]) => {
      const retGroup = L.featureGroup();
      const id = operation.operationId;

      if (!this.descriptionSubscriptions[id]) {
        this.descriptionSubscriptions[id] = [];
      }
      if (!this.altitudeSubscriptions[id]) {
        this.altitudeSubscriptions[id] = [];
      }
      if (!this.altitudeUnitsRefsCache[id]) {
        this.altitudeUnitsRefsCache[id] = [];
      }

      for (const coord of vol.geography.coordinates) {
        const polyLayer = L.polygon(coord.map(arr => new LatLng(arr[1], arr[0])), {
          fillColor: colorConfig.fill.toHexString(),
          fillOpacity: colorConfig.fill.getAlpha() * 0.5,
          color: colorConfig.outline.toHexString(),
          opacity: colorConfig.outline.getAlpha() * 0.5,
          sourceVolume: vol,
          sourceGeography: vol.geography,
          sourceCoords: coord,
          snapIgnore: true
        } as L.PolylineOptions);
        polyLayer.bindPopup('<p></p>');
        this.descriptionSubscriptions[id].push(combineLatest([this.altRefSubject, this.altUnitsSubject])
          .subscribe(([altRef, altUnits]) => {
            // Stop any conversion subscriptions if new changes are detected
            const volIndex = _.indexOf(operation.operation_volumes, vol);
            const cachedAltitudUnitsRefs = this.altitudeUnitsRefsCache[id] ? this.altitudeUnitsRefsCache[id][volIndex] : null;
            if (volIndex === 0 && cachedAltitudUnitsRefs && (altRef !== cachedAltitudUnitsRefs.ref ||
              altUnits !== cachedAltitudUnitsRefs.ref)) {
              this.altitudeSubscriptions[id].forEach(sub => sub?.unsubscribe());
            }

            // Track the current units and vertical reference for each volume
            this.altitudeUnitsRefsCache[id][volIndex] = {units: altUnits, ref: altRef};

            let altitudeRange = new AltitudeRange({
              min_altitude: vol.min_altitude.altitude_value,
              max_altitude: vol.max_altitude.altitude_value,
              altitude_vertical_reference: vol.min_altitude.vertical_reference,
              altitude_units: vol.min_altitude.units_of_measure,
              source: vol.min_altitude.source
            });

            // Convert units to meters before using the reference conversion endpoint
            if (altitudeRange.altitude_units !== units_of_measure.M) {
              altitudeRange = this.convertAltitudeUnits(altitudeRange, units_of_measure.M);
            }

            const referencePoint = vol.circle ? [vol.circle.longitude, vol.circle.latitude]
              : vol.geography.coordinates[0][0];

            // Convert vertical reference
            this.altitudeSubscriptions[id].push(this.convertAltitudeRefs(altitudeRange, referencePoint, altRef).subscribe(range => {
              // Convert to selected units
              altitudeRange = this.convertAltitudeUnits(range, altUnits);
              altitudeRange.min_altitude = _.round(altitudeRange.min_altitude, 2);
              altitudeRange.max_altitude = _.round(altitudeRange.max_altitude, 2);

              const popupHtml = `<p>`
                + `Name: ${operation.flight_number}<br/>`
                + `State: ${operation.state === state.ROGUE ? 'CONTINGENT' : operation.state}<br/>`
                + `${operation.state === state.NONCONFORMING || operation.state === state.ROGUE ? 'Off Nominal: ' + vol.off_nominal + '</br>' : ''}`
                + `Priority: ${operation.priority}<br/>`
                + `Operation Type: ${operationType || 'None'}<br/>`
                + `Begin Time: ${vol.effective_time_begin?.toLocaleString(DateTime.DATETIME_MED)}<br />`
                + `End Time: ${vol.effective_time_end?.toLocaleString(DateTime.DATETIME_MED)}<br />`
                + `Min Altitude: ${altitudeRange.getMinAlt().toString()}<br />`
                + `Max Altitude: ${altitudeRange.getMaxAlt().toString()}<br />`
                + `USS: ${operation.uss_name}<br />`
                + `<a href="/fuss/operations/view-operation?operationId=${operation.operationId}" rel="noreferrer noopener"
                    target="_blank">View Operation Details</a></p>`;
              polyLayer.setPopupContent(popupHtml);
            }));
          }));
        polyLayer.on('pm:dragenable', () => {
          polyLayer.pm.disableLayerDrag();
        });
        polyLayer.addTo(retGroup);
        polyLayer.pm.setOptions({
          allowEditing: false,
          allowRemoval: false,
          allowRotation: false,
          draggable: false
        });
      }
      return retGroup;
    }));
  }

  private convertConstraintVolumeToPolygons(vol: EntityVolume4d, constraint: UvrExt): Observable<L.FeatureGroup> {
    return combineLatest([
      this.constraintTypeService.getPrettyName(constraint?.additional_data?.constraint_type),
      this.colorService.getColorForId('constraint', true)])
      .pipe(take(1), map(([constraintType, constraintColorConfig]) => {
        const retGroup = L.featureGroup();
        const id = constraint.message_id;

        if (!this.descriptionSubscriptions[id]) {
          this.descriptionSubscriptions[id] = [];
        }
        if (!this.altitudeSubscriptions[id]) {
          this.altitudeSubscriptions[id] = [];
        }
        if (!this.altitudeUnitsRefsCache[id]) {
          this.altitudeUnitsRefsCache[id] = [];
        }

        for (const coord of vol.geography.coordinates) {
          const polyLayer = L.polygon(coord.map(arr => new LatLng(arr[1], arr[0])), {
            fillColor: constraintColorConfig.fill.toHexString(),
            fillOpacity: constraintColorConfig.fill.getAlpha() * 0.5,
            color: constraintColorConfig.outline.toHexString(),
            opacity: constraintColorConfig.outline.getAlpha() * 0.5,
            sourceConstraint: constraint,
            sourceCoords: coord,
            snapIgnore: true
          } as L.PolylineOptions);
          polyLayer.bindPopup('<p></p>');
          this.descriptionSubscriptions[id].push(combineLatest([this.altRefSubject, this.altUnitsSubject])
            .subscribe(([altRef, altUnits]) => {
              // Stop any conversion subscriptions if new changes are detected
              const volIndex = _.indexOf(constraint.volumes, vol);
              const cachedAltitudUnitsRefs = this.altitudeUnitsRefsCache[id] ? this.altitudeUnitsRefsCache[id][volIndex] : null;
              if (volIndex === 0 && cachedAltitudUnitsRefs && (altRef !== cachedAltitudUnitsRefs.ref ||
                altUnits !== cachedAltitudUnitsRefs.ref)) {
                this.altitudeSubscriptions[id].forEach(sub => sub?.unsubscribe());
              }

              // Track the current units and vertical reference for each volume
              this.altitudeUnitsRefsCache[id][volIndex] = {units: altUnits, ref: altRef};

              let altitudeRange = new AltitudeRange({
                min_altitude: vol.min_altitude.altitude_value,
                max_altitude: vol.max_altitude.altitude_value,
                altitude_vertical_reference: vol.min_altitude.vertical_reference,
                altitude_units: vol.min_altitude.units_of_measure,
                source: vol.min_altitude.source
              });

              // Convert units to meters before using the reference conversion endpoint
              if (altitudeRange.altitude_units !== units_of_measure.M) {
                altitudeRange = this.convertAltitudeUnits(altitudeRange, units_of_measure.M);
              }

              const referencePoint = vol.circle ? [vol.circle.longitude, vol.circle.latitude]
                : vol.geography.coordinates[0][0];

              // Convert vertical reference
              this.altitudeSubscriptions[id].push(this.convertAltitudeRefs(altitudeRange, referencePoint, altRef).subscribe(range => {
                // Convert to selected units
                altitudeRange = this.convertAltitudeUnits(range, altUnits);
                altitudeRange.min_altitude = _.round(altitudeRange.min_altitude, 2);
                altitudeRange.max_altitude = _.round(altitudeRange.max_altitude, 2);

                const popupHtml = '<p>'
                  + `Name: ${constraint.reason}<br />`
                  + `State: ${constraint.getState()}<br />`
                  + `Constraint Type: ${constraintType}<br />`
                  + `Begin Time: ${vol.effective_time_begin?.toLocaleString(DateTime.DATETIME_MED)}<br />`
                  + `End Time: ${vol.effective_time_end?.toLocaleString(DateTime.DATETIME_MED)}<br />`
                  + `Min Altitude: ${altitudeRange.getMinAlt().toString()}<br />`
                  + `Max Altitude: ${altitudeRange.getMaxAlt().toString()}<br />`
                  + `USS: ${constraint.uss_name}<br />`
                  + `<a href="/fuss/constraint/view-constraint?constraintId=${constraint.message_id}" rel="noreferrer noopener"
                target="_blank">View Constraint Details</a></p>`;
                polyLayer.setPopupContent(popupHtml);
              }));
            }));
          polyLayer.on('pm:dragenable', () => {
            polyLayer.pm.disableLayerDrag();
          });
          polyLayer.addTo(retGroup);
          polyLayer.pm.setOptions({
            allowEditing: false,
            allowRemoval: false,
            allowRotation: false,
            draggable: false
          });
        }
        return retGroup;
      }));

  }

  private convertAltitudeUnits(altitudeRange: AltitudeRange, convertTo: units_of_measure): AltitudeRange {
    const unitsFrom = this.altitudeUtilService.parseUnitForConversion(altitudeRange.altitude_units);
    const unitsTo = this.altitudeUtilService.parseUnitForConversion(convertTo);
    const convertedRange = altitudeRange;

    if (unitsFrom !== unitsTo) {
      convertedRange.min_altitude = new Converter(altitudeRange.min_altitude).from(unitsFrom).to(unitsTo);
      convertedRange.max_altitude = new Converter(altitudeRange.max_altitude).from(unitsFrom).to(unitsTo);
      convertedRange.altitude_units = convertTo;
    }

    return convertedRange;
  }

  private convertAltitudeRefs(altitudeRange: AltitudeRange, referencePoint: number[], altRef: vertical_reference): Observable<AltitudeRange> {
    if (altitudeRange.altitude_vertical_reference !== altRef) {
      const minAlt: IAltitudeConversionParameters = {
        lat: referencePoint[1],
        lon: referencePoint[0],
        altitude: altitudeRange.min_altitude,
        input_reference: altitudeRange.altitude_vertical_reference,
        output_reference: altRef
      };

      // Convert altitudes
      return this.altitudeService.convertAltitude(minAlt).pipe(map((min) => {
        const maxOffset = altitudeRange.max_altitude - altitudeRange.min_altitude;

        return new AltitudeRange({
          min_altitude: min.altitude,
          max_altitude: min.altitude + maxOffset,
          altitude_units: altitudeRange.altitude_units,
          altitude_vertical_reference: altRef,
          source: altitudeRange.source
        });
      }));
    } else {
      return of(altitudeRange);
    }
  }

}

import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {LatLngPoint} from '../../../model/WaypointParser';
import {LeafletDrawerService} from '@ax/ax-angular-map-leaflet';
import * as L from 'leaflet';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-leaflet-polyline-drawer',
  template: ''
})
export class LeafletPolylineDrawerComponent implements OnChanges, OnDestroy {

  @Input() points: LatLngPoint[] = [];
  @Input() color = 'black';
  @Input() fillColor = 'black';
  private map: L.Map;
  private leafletInitSub: Subscription;
  private drawnPath: L.LayerGroup;

  constructor(private leafletDrawerService: LeafletDrawerService) {
    this.drawnPath = new L.LayerGroup();
    this.leafletInitSub = this.leafletDrawerService.watchViewerInit().subscribe(mapy => {
      this.map = mapy;
      this.drawnPath.addTo(mapy);
      this.refreshLeaflet();
    });
  }

  ngOnChanges(): void {
    this.refreshLeaflet();
  }

  ngOnDestroy(): void {
    this.leafletInitSub?.unsubscribe();
    this.drawnPath.remove();
  }


  private refreshLeaflet() {
    this.drawnPath.clearLayers();
    if (this.map && this.points && this.points.length > 0) {
      const pointList = this.points.map(pt => new L.LatLng(pt.lat, pt.lng, pt.alt));
      // @ts-ignore
      const polyLine = new L.polyline(pointList, {color: this.color, fillColor: this.fillColor});
      this.drawnPath.addLayer(polyLine);
    }
  }
}

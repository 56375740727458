import {IUserMessageData, UserMessageData} from './UserMessageData';
import {
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {ConformanceState, Volume4dViolation} from './enums';
import {BaseModel} from '../gen/utm/base-model';
import {FormControlify} from '../../utils/forms';

export interface IConformanceAlertMessage extends IUserMessageData {
  operationId: string;
  conformanceState: ConformanceState;
  volumeViolations: Volume4dViolation[];
  entityName: string;
  ownedByUser: boolean;
  affectedOperations: IAffectedOperation[];
  operationEnded: boolean;
}

export class ConformanceAlertMessage extends UserMessageData implements IConformanceAlertMessage {
  static OPERATION_ID_FIELD_NAME = 'operationId';
  static CONFORMANCE_STATE_FIELD_NAME = 'conformanceState';
  static VOLUME_VIOLATIONS_FIELD_NAME = 'volumeViolations';
  static ENTITY_NAME_FIELD_NAME = 'entityName';
  static OWNED_BY_USER_FIELD_NAME = 'ownedByUser';
  static AFFECTED_OPERATIONS_FIELD_NAME = 'affectedOperations';
  static OPERATION_ENDED_FIELD_NAME = 'operationEnded';

  operationId: string;
  conformanceState: ConformanceState;
  volumeViolations: Volume4dViolation[];
  entityName: string;
  ownedByUser: boolean;
  affectedOperations: AffectedOperation[];
  operationEnded: boolean;


  constructor(values?: Partial<IConformanceAlertMessage>, useFormGroupValuesToModel = false) {
    super();

    this.volumeViolations = [];
    this.affectedOperations = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IUserMessageData>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.operationId = this.getValue<string>(rawValues, ConformanceAlertMessage.OPERATION_ID_FIELD_NAME);
      this.conformanceState = this.getValue<ConformanceState>(rawValues, ConformanceAlertMessage.CONFORMANCE_STATE_FIELD_NAME);
      this.entityName = this.getValue<string>(rawValues, ConformanceAlertMessage.ENTITY_NAME_FIELD_NAME);
      this.ownedByUser = this.getValue<boolean>(rawValues, ConformanceAlertMessage.OWNED_BY_USER_FIELD_NAME);
      this.operationEnded = this.getValue<boolean>(rawValues, ConformanceAlertMessage.OPERATION_ENDED_FIELD_NAME);
      this.fillModelArray<Volume4dViolation>(this, ConformanceAlertMessage.VOLUME_VIOLATIONS_FIELD_NAME, rawValues.volumeViolations, useFormGroupValuesToModel);
      this.fillModelArray<AffectedOperation>(this, ConformanceAlertMessage.AFFECTED_OPERATIONS_FIELD_NAME, rawValues.affectedOperations, useFormGroupValuesToModel);

      super.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[ConformanceAlertMessage.OPERATION_ID_FIELD_NAME].setValue(this.operationId);
    this.$formGroup.controls[ConformanceAlertMessage.CONFORMANCE_STATE_FIELD_NAME].setValue(this.conformanceState);
    this.$formGroup.controls[ConformanceAlertMessage.ENTITY_NAME_FIELD_NAME].setValue(this.entityName);
    this.$formGroup.controls[ConformanceAlertMessage.OWNED_BY_USER_FIELD_NAME].setValue(this.ownedByUser);
    this.$formGroup.controls[ConformanceAlertMessage.OPERATION_ENDED_FIELD_NAME].setValue(this.operationEnded);
    this.fillFormArray<Volume4dViolation>(ConformanceAlertMessage.VOLUME_VIOLATIONS_FIELD_NAME, this.volumeViolations);
    this.fillFormArray<AffectedOperation>(ConformanceAlertMessage.AFFECTED_OPERATIONS_FIELD_NAME, this.affectedOperations);

    super.setFormGroupValues();
  }

  getState(): string {
    return '';
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = super.getFormGroup();
      this._formGroup.addControl(ConformanceAlertMessage.OPERATION_ID_FIELD_NAME, new UntypedFormControl(this.operationId, [Validators.required]));
      this._formGroup.addControl(ConformanceAlertMessage.CONFORMANCE_STATE_FIELD_NAME, new UntypedFormControl(this.conformanceState, [Validators.required]));
      this._formGroup.addControl(ConformanceAlertMessage.ENTITY_NAME_FIELD_NAME, new UntypedFormControl(this.entityName, [Validators.required]));
      this._formGroup.addControl(ConformanceAlertMessage.OWNED_BY_USER_FIELD_NAME, new UntypedFormControl(this.ownedByUser, [Validators.required]));
      this._formGroup.addControl(ConformanceAlertMessage.OPERATION_ENDED_FIELD_NAME, new UntypedFormControl(this.operationEnded, [Validators.required]));
      this._formGroup.addControl(ConformanceAlertMessage.VOLUME_VIOLATIONS_FIELD_NAME, new UntypedFormArray([], [Validators.required]));
      this._formGroup.addControl(ConformanceAlertMessage.AFFECTED_OPERATIONS_FIELD_NAME, new UntypedFormArray([], [Validators.required]));

      this.fillFormArray<Volume4dViolation>(ConformanceAlertMessage.VOLUME_VIOLATIONS_FIELD_NAME, this.volumeViolations);
      this.fillFormArray<AffectedOperation>(ConformanceAlertMessage.AFFECTED_OPERATIONS_FIELD_NAME, this.affectedOperations);
    }

    return this._formGroup;
  }
}

export interface IAffectedOperation {
  operationId: string;
  entityName: string;
}

export class AffectedOperation extends BaseModel implements IAffectedOperation {
  static OPERATION_ID_FIELD_NAME = 'operationId';
  static ENTITY_NAME_FIELD_NAME = 'entityName';

  operationId: string;
  entityName: string;

  constructor(values?: Partial<IAffectedOperation>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IAffectedOperation>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.operationId = this.getValue<string>(rawValues, AffectedOperation.OPERATION_ID_FIELD_NAME);
      this.entityName = this.getValue<string>(rawValues, AffectedOperation.ENTITY_NAME_FIELD_NAME);
    }
  }

  setFormGroupValues() {
    this.$formGroup.controls[AffectedOperation.OPERATION_ID_FIELD_NAME].setValue(this.operationId);
    this.$formGroup.controls[AffectedOperation.ENTITY_NAME_FIELD_NAME].setValue(this.entityName);
  }

  protected getFormGroup(): FormGroup<FormControlify<IAffectedOperation>> {
    if (!this._formGroup) {
      this._formGroup = new FormGroup<FormControlify<IAffectedOperation>>({
        operationId: new FormControl<string>(this.operationId, [Validators.required]),
        entityName: new FormControl<string>(this.entityName, [Validators.required])
      });
    }
    return this._formGroup;
  }
}

<input autocomplete="off" type="text" class="clr-input" id="draft-selector" style="color:black;" disabled="true"
       [value]="draftText">
<button type="button" (click)="openDraftSelector($event)" class="btn btn-primary btn-sm select-btn"
        [disabled]="isDisabled">{{draftId ? 'Change' : 'Select'}}</button>
<clr-modal [(clrModalOpen)]="selectingDraft" [clrModalClosable]="true" [clrModalSize]="'md'">
  <div class="modal-body" *ngIf="selectingDraft">
    <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="datagrid-compact" style="height: 100%;+
">
      <clr-dg-column>Draft Operation Name</clr-dg-column>
      <clr-dg-column>Options</clr-dg-column>

      <clr-dg-row *ngFor="let draft of drafts" [clrDgItem]="draft">
        <clr-dg-cell>{{draft?.submission?.flight_number}}</clr-dg-cell>
        <clr-dg-cell>
          <button type="button" (click)="selectDraft(draft)" class="btn btn-primary btn-sm">Select</button>
          <button type="button" (click)="deleteDraft(draft)" class="btn btn-danger btn-sm">Delete</button>
        </clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize"
                           [clrDgTotalItems]="totalItems"></clr-dg-pagination>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}}
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Drafts per page</clr-dg-page-size>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
</clr-modal>

import {Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import * as L from 'leaflet';
import {EntityVolume4d} from '../../../model/gen/utm';
import {LeafletMapService} from '@ax/ax-angular-map-leaflet';
import {AbstractMapConfigProvider} from '@ax/ax-angular-map-common';
import {ColorId, ColorService} from '../../../services/color.service';
import {from, Subscription} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';


@Component({
  selector: 'app-constraint2d-view',
  templateUrl: './constraint2d-view.component.html',
  styleUrls: ['./constraint2d-view.component.scss']
})
export class Constraint2dViewComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('map', {static: true}) map: ElementRef;
  @Input() volumes: EntityVolume4d[] = [];
  drawnItems: L.FeatureGroup;
  private mapObj: L.Map;
  private mapSub: Subscription;
  private layerConfigSub: Subscription;


  constructor(private leafletMapService: LeafletMapService, private envService: AbstractMapConfigProvider, private colorService: ColorService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.drawnItems) {
      this.ngOnInit();
    } else {
      this.refreshMap();
    }
  }

  ngOnInit() {
    if (this.drawnItems) {
      return;
    }
    this.layerConfigSub = this.envService.getTileLayerConfigurations().subscribe(config => {
      this.mapSub?.unsubscribe();
      this.mapSub = this.leafletMapService.initMapWithConfig(this.map.nativeElement, config, {}, this.mapObj).subscribe((result) => {
        switch (result.type) {
          case 'success':
            this.mapObj = result.map;
            this.drawnItems = L.featureGroup().addTo(this.mapObj);
            this.refreshMap();
            break;
          case 'error':
            console.error(result.message);
            break;
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.layerConfigSub?.unsubscribe();
    this.mapSub?.unsubscribe();
  }

  private refreshMap() {
    if (this.drawnItems && this.drawnItems.getLayers().length > 0) {
      this.drawnItems.clearLayers();
    }
    from(this.volumes).pipe(mergeMap((volume: EntityVolume4d) => {
      const colorId: ColorId = 'constraint';
      return this.colorService.getColorForId(colorId, true).pipe(map((colorConfig) => {
        return {volume, colorConfig};
      }));

    })).subscribe(({volume, colorConfig}) => {
      for (const poly of volume.geography.coordinates) {
        const polygon: any = new L.Polygon(poly.map(point => L.latLng([point[1], point[0]])), {
          fillColor: colorConfig.fill.toHexString(),
          fillOpacity: colorConfig.fill.getAlpha(),
          color: colorConfig.fill.toHexString()
        } as L.PolylineOptions);
        this.drawnItems.addLayer(polygon);
      }
      this.mapObj.fitBounds(this.drawnItems.getBounds());
    }, () => {
    }, () => {

    });

  }
}

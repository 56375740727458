import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ConstraintRoutingModule} from './constraint-routing.module';
import {ConstraintsComponent} from './constraints/constraints.component';
import {
  ConstraintSearchOptionsConfigComponent
} from './constraints/constraint-search-options-config/constraint-search-options-config.component';
import {ClarityModule, ClrComboboxModule} from '@clr/angular';
import {ComponentsModule} from '../../shared/components/components.module';
import {ViewConstraintComponent} from './view-constraint/view-constraint.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {ServicesModule} from '../../shared/services/services.module';
import {CdsModule} from '@cds/angular';
import {PermissionsModule} from '../../shared/permissions/permissions.module';
import {
  ConstraintStatesFilterComponent
} from './constraints/constraints-filters/constraint-states-filter/constraint-states-filter.component';
import {NgxMatDatetimePickerModule} from '../../shared/components/datetime-picker/datepicker-module';


@NgModule({
  declarations: [ConstraintsComponent,
    ConstraintSearchOptionsConfigComponent, ViewConstraintComponent, ConstraintStatesFilterComponent],
    imports: [
        CommonModule,
        ConstraintRoutingModule,
        ClarityModule,
        CdsModule,
        ClrComboboxModule,
        ComponentsModule,
        ReactiveFormsModule,
        NgxMatDatetimePickerModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        ServicesModule,
        PermissionsModule,
        FormsModule

    ]
})
export class ConstraintModule {
}

import {BaseModel} from './gen/utm/base-model';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';


/**
 * @Deprecated
 */
export interface IOwnerContact {
  organizationId: string;
  name: string;
  address: string;
  phoneNumbers: string[];
  mobileNumbers: string[];
  emailAddresses: string[];
}

/**
 * @Deprecated
 */
export class OwnerContact extends BaseModel implements IOwnerContact {
  static ORGANIZATION_ID_FIELD_NAME = 'organizationId';
  static NAME_FIELD_NAME = 'name';
  static ADDRESS_FIELD_NAME = 'address';
  static PHONE_NUMBERS_FIELD_NAME = 'phoneNumbers';
  static MOBILE_NUMBERS_FIELD_NAME = 'mobileNumbers';
  static EMAIL_ADDRESSES_FIELD_NAME = 'emailAddresses';

  organizationId: string;
  name: string;
  address: string;
  phoneNumbers: string[];
  mobileNumbers: string[];
  emailAddresses: string[];

  constructor(values?: Partial<OwnerContact>, useFormGroupValuesToModel = false) {
    super();
    this.phoneNumbers = [];
    this.mobileNumbers = [];
    this.emailAddresses = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<OwnerContact>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.organizationId = this.getValue<string>(rawValues, OwnerContact.ORGANIZATION_ID_FIELD_NAME);
      this.name = this.getValue<string>(rawValues, OwnerContact.NAME_FIELD_NAME);
      this.address = this.getValue<string>(rawValues, OwnerContact.ADDRESS_FIELD_NAME);
      this.fillModelArray<string>(this, OwnerContact.PHONE_NUMBERS_FIELD_NAME, rawValues.phoneNumbers, useFormGroupValuesToModel);
      this.fillModelArray<string>(this, OwnerContact.MOBILE_NUMBERS_FIELD_NAME, rawValues.mobileNumbers, useFormGroupValuesToModel);
      this.fillModelArray<string>(this, OwnerContact.EMAIL_ADDRESSES_FIELD_NAME, rawValues.emailAddresses, useFormGroupValuesToModel);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[OwnerContact.ORGANIZATION_ID_FIELD_NAME].setValue(this.organizationId);
    this.$formGroup.controls[OwnerContact.NAME_FIELD_NAME].setValue(this.name);
    this.$formGroup.controls[OwnerContact.ADDRESS_FIELD_NAME].setValue(this.address);
    this.fillFormArray<string>(OwnerContact.PHONE_NUMBERS_FIELD_NAME, this.phoneNumbers);
    this.fillFormArray<string>(OwnerContact.MOBILE_NUMBERS_FIELD_NAME, this.mobileNumbers);
    this.fillFormArray<string>(OwnerContact.EMAIL_ADDRESSES_FIELD_NAME, this.emailAddresses);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        organizationId: new UntypedFormControl(this.organizationId, [Validators.required]),
        name: new UntypedFormControl(this.name, [Validators.required]),
        address: new UntypedFormControl(this.address, [Validators.required]),
        phoneNumbers: new UntypedFormArray([]),
        mobileNumbers: new UntypedFormArray([]),
        emailAddresses: new UntypedFormArray([]),
      });
      // generate FormArray control elements
      this.fillFormArray<string>(OwnerContact.PHONE_NUMBERS_FIELD_NAME, this.phoneNumbers);
      // generate FormArray control elements
      this.fillFormArray<string>(OwnerContact.MOBILE_NUMBERS_FIELD_NAME, this.mobileNumbers);
      // generate FormArray control elements
      this.fillFormArray<string>(OwnerContact.EMAIL_ADDRESSES_FIELD_NAME, this.emailAddresses);
    }
    return this._formGroup;
  }
}

<form [formGroup]="fg">
  <clr-checkbox-container>
    <clr-checkbox-wrapper>
      <label>Allow All</label>
      <input autocomplete="off" type="checkbox" clrCheckbox formControlName="allowAll"/>
    </clr-checkbox-wrapper>
    <clr-control-helper>If checked, federation to all domains will be enabled.</clr-control-helper>
  </clr-checkbox-container>
  <h5 *ngIf="!allDomainsOn && hasDomains">Permitted Federation Domains</h5>
  <clr-checkbox-container *ngIf="!allDomainsOn && hasDomains">
    <clr-checkbox-wrapper *ngFor="let domain of domains; let i = index" formArrayName="domains">
      <label>{{domain.prettyName}}</label>
      <input autocomplete="off" type="checkbox" clrCheckbox [formControl]="domainFgArray[i]"/>
    </clr-checkbox-wrapper>
    <clr-control-helper>If checked, federation to the checked domains will be enabled.</clr-control-helper>
  </clr-checkbox-container>
</form>

import { Injectable } from '@angular/core';
import {Observable, ReplaySubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SafireButtonService {
  private readonly openNotificationSubject: ReplaySubject<unknown>;

  constructor() {
    this.openNotificationSubject = new ReplaySubject(1);
  }

  listenForOpenNotifications(): Observable<any> {
    return this.openNotificationSubject;
  }

  notifyOfButtonOpen(id: string): void {
    this.openNotificationSubject.next(id);
  }
}

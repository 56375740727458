import { Injectable } from '@angular/core';
import { UserService } from '../user.service';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { Contact, IContact } from '../../model/Contact';
import { Parser } from '../../model/utm/parser/OperationParser';

@Injectable({
  providedIn: 'root'
})
export class RestUserService extends UserService {
  userCache: { [key: string]: ReplaySubject<Contact> } = {};

  constructor(private http: HttpClient) {
    super();
  }

  getUserContactInformation(userId: string): Observable<Contact | null> {
    if (userId in this.userCache) {
      return this.userCache[userId];
    }
    const newSubject = new ReplaySubject<Contact>(1);

    this.http.get(`${environment.baseUrl}/userserv/api/user/${userId}/contact`).pipe(map((res: { success: boolean; contact: IContact }) => {
      if (res.success) {
        return Parser.parseContact(res.contact);
      } else {
        return null;
      }

    }), catchError((err, caught) => {
      console.error(err);
      return of(null);
    })).subscribe(newSubject);

    this.userCache[userId] = newSubject;
    return newSubject;

  }

}

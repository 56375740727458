import {Component, inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {OperationVolume, state, UASVolumeReservation} from '../../../model/gen/utm';
import {OperationExt} from '../../../model/utm/OperationExt';
import {ConstraintTypeService} from '../../../services/constraint-type.service';
import {concat, Observable, ReplaySubject, Subscription} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {filter, reduce, switchMap} from 'rxjs/operators';
import {ContactService} from '../../../services/contact.service';
import {Contact} from '../../../model/Contact';
import {UasRegistrationInfo} from '../../../model/UasRegistrationInfo';
import {PlatformService} from '../../../services/platform.service';
import {UserMessageService} from '../../../services/user-message.service';
import {Router} from '@angular/router';
import {UvrExt} from 'src/app/shared/model/utm/UvrExt';
import {OperationService} from '../../../services/operation.service';
import {ConstraintService} from '../../../services/constraint.service';
import {OperationReplanInfo} from '../../../model/OperationReplanInfo';
import {ConflictInfo} from '../../../model/conflictInfo';
import {User} from '../../../model/User';
import {CurrentUserService} from '../../../services/current-user.service';
import {DirectMessageTargetIdService, TargetEnum} from '../../../services/direct-message-target-id.service';
import {DEFAULT_EXPERIMENTAL_SETTINGS, SettingsService} from '../../../services/settings.service';
import {ContactResponse} from '../../../model/ContactResponse';
import {OperationUtil} from "../../../model/OperationUtil";
import {ResponsiveScreenService} from '../../../services/responsive-screen.service';

@Component({
  selector: 'app-ax-operation-summary',
  templateUrl: './ax-operation-summary.component.html',
  styleUrls: ['./ax-operation-summary.component.scss']
})
export class AxOperationSummaryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() operation: OperationExt;
  @Input() simplifiedDisplay = false;

  operationVolumes: OperationVolume[];
  operationRegistrations: UasRegistrationInfo[] = [];
  showGeometry = {};
  prettyOperationType = 'None';
  prettyActivationPolicy: string;
  submittingUser: Contact;
  conflictInfo: ConflictInfo;
  conflictingOps: Map<string, OperationExt>;
  conflictingConstraints: Map<string, UvrExt>;
  showConflictingOp = false;
  showConflictingConstraint = false;
  openOp: OperationExt;
  openConstraint: UvrExt;
  replanInfo: OperationReplanInfo;
  fallbackText = 'Not Available';
  operationState = state;
  currentUser: User = null;
  enableExperimentalDirectMessagingSupport: boolean;
  state = state;

  deviceSize$ = inject(ResponsiveScreenService).deviceSize$;

  private operationSubject: ReplaySubject<OperationExt> = new ReplaySubject(1);
  private uasRegistrationSub: Subscription;
  private conflictSub: Subscription;
  private replanSub: Subscription;
  private currentUserSub: Subscription;
  private experimentalSettingsSub: Subscription;
  private watchOperationSub: Subscription;
  private prettyOperationFieldsSub: Subscription;
  private userSub: Subscription;

  constructor(private constraintTypeService: ConstraintTypeService,
              private contactService: ContactService,
              private platformService: PlatformService,
              private messageService: UserMessageService,
              private router: Router,
              private operationService: OperationService,
              private constraintService: ConstraintService,
              private currentUserService: CurrentUserService,
              private directMessageTargetIdService: DirectMessageTargetIdService,
              private envService: SettingsService) {
    this.conflictingOps = new Map();
    this.conflictingConstraints = new Map();
  }

  ngOnInit(): void {
    // Set the initial operation
    if (this.operation) {
      this.operationSubject.next(this.operation);
    }

    // Get operation type and activation policy
    this.prettyOperationFieldsSub = this.operationSubject.pipe(filter(op => !!op))
      .pipe(switchMap((operation: OperationExt) => {
        if (operation.activation) {
          this.prettyActivationPolicy = OperationUtil.humanizeOperationActivationPolicy(operation.activation);
        }
        return this.getOperationType(operation);
    })).subscribe((type) => this.prettyOperationType = type);

    // Get submitting user
    this.userSub = this.operationSubject.pipe(switchMap((operation: OperationExt) => {
      this.submittingUser = null;
      return operation?.additional_data?.user_id ? this.contactService.getContactByUserID(operation.additional_data.user_id) :
        of(null);
    })).subscribe((res: ContactResponse | null) => {
      this.submittingUser = res?.contact;
    });

    // Get UAS registrations
    this.uasRegistrationSub = this.operationSubject.pipe(switchMap(operation => {
      if (!operation || !operation.uas_registrations) {
        return of([]);
      }
      return concat(...operation.uas_registrations
        .map(registration => this.platformService.getPlatform(registration.registration_id))).pipe(
        reduce((all, res) => all.concat([res]), [] as UasRegistrationInfo[])
      );
    })).subscribe(regInfo => {
      this.operationRegistrations = regInfo;
    });

    // Get conflicts - this needs to be refactored pending a dedicated conflicts endpoint
    this.conflictSub = this.operationSubject.subscribe(operation => {
      if (!this.simplifiedDisplay && !!operation && operation.state === state.REJECTED) {
        this.messageService.getConflictingEntity(operation.operationId).subscribe(res => {
          this.conflictInfo = res;
          if (this.conflictInfo.conflictingOperations.length > 0) {
            this.conflictInfo.conflictingOperations.forEach(operationId => {
              this.operationService.getOperation(operationId).subscribe(op => {
                this.conflictingOps.set(operationId, op);
              });
            });
          }
          if (this.conflictInfo.conflictingConstraints.length > 0) {
            this.conflictInfo.conflictingConstraints.forEach(constraintId => {
              this.constraintService.getConstraint(constraintId).subscribe(constraint => {
                this.conflictingConstraints.set(constraintId, constraint);
              });
            });
          }
        }, error => {
          console.error('Could not fetch rejection information');
        });
      } else {
        this.clearConflicts();
      }
    });

    // Get current user
    this.currentUserSub = this.currentUserService.currentUser.subscribe(user => {
      this.currentUser = user;
    });

    // Get experimental settings
    this.experimentalSettingsSub = this.envService.getExperimentalSettings().subscribe(settings => {
      this.enableExperimentalDirectMessagingSupport = settings.enableDirectMessagingSupport || DEFAULT_EXPERIMENTAL_SETTINGS.enableDirectMessagingSupport;
    });

    // Get replans
    this.replanSub = this.operationSubject.pipe(switchMap((operation: OperationExt) => {
      return !this.simplifiedDisplay && operation?.operationId ? this.operationService.getOperationReplans(operation.operationId) :
        of(null);
    })).subscribe((replanInfo) => this.replanInfo = replanInfo);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.operation) {
      this.operationSubject.next(this.operation);
    }
  }

  viewVolume(i: number) {
    this.showGeometry[i] = true;
  }

  viewConstraintDetails(constraint: UvrExt) {
    this.openConstraint = constraint;
    this.showConflictingConstraint = true;
  }

  viewOperationDetails(operation: OperationExt) {
    this.openOp = operation;
    this.showConflictingOp = true;
  }

  getVolumeReservation(constraint: UvrExt): UASVolumeReservation {
    return constraint as UASVolumeReservation;
  }

  getOperationVolumes(): OperationVolume[] {
    const opVols: OperationVolume[] = [];
    this.conflictingOps.forEach((value, key) => {
      value.operation_volumes.forEach(vol => opVols.push(vol));
    });
    return opVols;
  }

  getAirspaces(rawAirspaceText: string): string[] {
    if (rawAirspaceText) {
      return rawAirspaceText
        .replace(/class-/g, '')
        .replace(/sua-/g, '').split(',');
    } else {
      return ['N/A'];
    }
  }

  getOperationType(operation: OperationExt): Observable<string> {
    return operation?.additional_data?.permitted_constraint_types?.length ?
      this.constraintTypeService.getPrettyName(operation.additional_data.permitted_constraint_types[0]) : of('None');
  }

  clearConflicts() {
    this.conflictInfo = null;
    this.conflictingConstraints = new Map();
    this.conflictingOps = new Map();
    this.showConflictingOp = false;
    this.showConflictingConstraint = false;
    this.openOp = null;
    this.openConstraint = null;
  }

  openSendMessageModal() {
    this.directMessageTargetIdService.notifyOfTargetChange({targetType: TargetEnum.OPERATION, id: this.operation.operationId});
  }

  ngOnDestroy(): void {
    this.uasRegistrationSub?.unsubscribe();
    this.conflictSub?.unsubscribe();
    this.replanSub?.unsubscribe();
    this.currentUserSub?.unsubscribe();
    this.experimentalSettingsSub?.unsubscribe();
    this.watchOperationSub?.unsubscribe();
    this.prettyOperationFieldsSub?.unsubscribe();
    this.userSub?.unsubscribe();
  }
}

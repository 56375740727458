import {MissionPlannerFlightPlanCustomizer} from './MissionPlannerFlightPlanCustomizer';
import {UTMFlightPlanCustomizer} from './common';
import {PiccoloFlightPlanCustomizer} from './PiccoloFlightPlanCustomizer';
import {SwiftFlightPlanCustomizer} from './SwiftFlightPlanCustomizer';
import {UGCSFlightPlanCustomizer} from './UGCSFlightPlanCustomizer';
import {AltitudeService} from '../../../../altitude.service';
import {QGCSFlightPlanCustomizer} from "./QGCSFlightPlanCustomizer";

class ReviewableUTMFlightPlanFactory {
  parseFile(file: File, altitudeService: AltitudeService): Promise<UTMFlightPlanCustomizer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = reader.result as string;
        const filename = file.name;
        const extension = filename.split('.').pop();
        let flightPlan: UTMFlightPlanCustomizer | null = null;
        switch (extension) {
          case 'waypoints':
            flightPlan = MissionPlannerFlightPlanCustomizer.fromText(text);
            break;
          case 'fp':
            flightPlan = PiccoloFlightPlanCustomizer.fromText(text);
            break;
          case 'mission':
            flightPlan = SwiftFlightPlanCustomizer.fromText(text);
            break;
          case 'json':
            flightPlan = UGCSFlightPlanCustomizer.fromText(text, altitudeService);
            break;
          case 'plan':
            flightPlan = QGCSFlightPlanCustomizer.fromTextToMissionPlanner(text);
            break;
          default:
            reject('Invalid File Type');
        }
        if (!flightPlan) {
          reject('Invalid Flight Plan');
        }
        resolve(flightPlan);
      };
      reader.readAsText(file);
    });
  }
}

export const reviewableFlightPlanFactory = new ReviewableUTMFlightPlanFactory();

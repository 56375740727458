import {BaseModel} from './base-model';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export interface IOperationApprovalInfo {
  approved: boolean;
  approvedByUserId?: string;
  approvedByUserName?: string;
  constraintIds: string[];
}

export class OperationApprovalInfo extends BaseModel implements IOperationApprovalInfo {
  static APPROVED_FIELD_NAME = 'approved';
  static APPROVED_BY_USER_ID_FIELD_NAME = 'approvedByUserId';
  static APPROVED_BY_USER_NAME_FIELD_NAME = 'approvedByUserName';
  static CONSTRAINT_IDS_FIELD_NAME = 'constraintIds';

  /** Indicattes whether the operation has been approved */
  approved: boolean;

  /** The ID of the user that approved the request */
  approvedByUserId?: string;

  /** The username of the user that approved the request */
  approvedByUserName?: string;

  /** List of constraint IDs that the operation overlapped with */
  constraintIds: string[];

  constructor(values?: Partial<IOperationApprovalInfo>, useFormGroupValuesToModel = false) {
    super();
    this.constraintIds = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   *
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IOperationApprovalInfo>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.approved = this.getValue<boolean>(rawValues, OperationApprovalInfo.APPROVED_FIELD_NAME);
      this.approvedByUserId = this.getValue<string>(rawValues, OperationApprovalInfo.APPROVED_BY_USER_ID_FIELD_NAME);
      this.approvedByUserName = this.getValue<string>(rawValues, OperationApprovalInfo.APPROVED_BY_USER_NAME_FIELD_NAME);
      this.fillModelArray<string>(this, OperationApprovalInfo.CONSTRAINT_IDS_FIELD_NAME, rawValues.constraintIds, useFormGroupValuesToModel);

      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[OperationApprovalInfo.APPROVED_FIELD_NAME].setValue(this.approved);
    this.$formGroup.controls[OperationApprovalInfo.APPROVED_BY_USER_ID_FIELD_NAME].setValue(this.approvedByUserId);
    this.$formGroup.controls[OperationApprovalInfo.APPROVED_BY_USER_NAME_FIELD_NAME].setValue(this.approvedByUserName);
    this.fillFormArray<string>(OperationApprovalInfo.CONSTRAINT_IDS_FIELD_NAME, this.constraintIds);

    super.setFormGroupValuesInAddedFormControls();
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        approved: new UntypedFormControl(this.approved, [Validators.required]),
        approvedByUserId: new UntypedFormControl(this.approvedByUserId, [Validators.minLength(36), Validators.maxLength(36),
          Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$')]),
        approvedByUserName: new UntypedFormControl(this.approvedByUserName),
        constraintIds: new UntypedFormArray([], [Validators.required]),
    });
      this.fillFormArray<string>(OperationApprovalInfo.CONSTRAINT_IDS_FIELD_NAME, this.constraintIds);
    }
    return this._formGroup;
  }
}

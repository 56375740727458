import {Component, forwardRef, HostListener, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ValidatorFn, Validators} from '@angular/forms';
import {TimeRange} from '../../model/TimeRange';
import {FormControlify} from '../../utils/forms';
import {DateTime, Duration} from 'luxon';
import {Subscription, timer} from 'rxjs';
import {
  maxTimeDurationValidator,
  maxTimeValidator,
  minTimeDurationValidator,
  minTimeValidator,
  timeRangeAboveValidator
} from '../../utils/Validators';
import {debounceTime} from 'rxjs/operators';


export interface TimeRangeSelectorFG {
  startTime: DateTime;
  endTime: DateTime;
}

@Component({
  selector: 'app-time-range-selector',
  templateUrl: './time-range-selector.component.html',
  styleUrls: ['./time-range-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => TimeRangeSelectorComponent),
    }
  ]
})
export class TimeRangeSelectorComponent implements OnChanges, ControlValueAccessor, OnDestroy {
  // inline determines whether the form fields display horizontally (inline) or vertically (!inline)
  @Input() editable = true;
  @Input() value: TimeRange;
  @Input() startTimeOffsetLimits: {min: Duration | DateTime; max: Duration};
  @Input() durationLimits: {min: Duration; max: Duration};
  @Input() startCheckInterval: Duration;

  lastValue: TimeRange | null = undefined;
  fg: FormGroup<FormControlify<TimeRangeSelectorFG>>;

  private onTouched: () => void;
  private onChange: (TimeRange) => void;

  private timeSubscription: Subscription;
  private endTimeValidationSubscription: Subscription;
  private changeSubscription: Subscription;

  constructor() {
    const startTimeValidators = this.getStartTimeValidators();
    const fgValidators = this.getFGValidators();
    const startTimeControl = new FormControl<DateTime>(null, startTimeValidators);
    this.fg = new FormGroup<FormControlify<TimeRangeSelectorFG>>({
      startTime: startTimeControl,
      endTime: new FormControl<DateTime>(null, [Validators.required, timeRangeAboveValidator(startTimeControl)])
    });
    if (fgValidators) {
      this.fg.setValidators(fgValidators);
    }

    this.endTimeValidationSubscription = startTimeControl.valueChanges.subscribe(() => {
      this.fg.controls.endTime.updateValueAndValidity();
    });
    this.changeSubscription = this.fg.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      this.handleChange();
    });
    this.startIntervalChecker();
  }

  @HostListener('touchend')
  touched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startTimeOffsetLimits) {
      const startTimeValidators = this.getStartTimeValidators();
      this.fg.controls.startTime.setValidators(startTimeValidators);
    }
    if (changes.durationLimits) {
      const fgValidators = this.getFGValidators();
      this.fg.setValidators(fgValidators);
    }
    if (changes.startCheckInterval) {
      this.startIntervalChecker();
    }

    if (changes.value) {
      this.writeValue(this.value);
    }
  }

  registerOnChange(fn: (TimeRange) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.editable = !isDisabled;
    if (!!isDisabled) {
      this.fg.disable();
    } else {
      this.fg.enable();
    }
  }

  writeValue(obj: TimeRange): void {
    if (!obj) {
      this.fg.reset({
        startTime: null,
        endTime: null
      });
    } else {
      this.fg.setValue({
        startTime: obj.start,
        endTime: obj.end
      });
    }

    this.fg.controls.startTime.markAsTouched({onlySelf: true});
    this.fg.updateValueAndValidity();
  }

  handleChange(): void {
    if (this.fg.disabled || this.fg.pending) {
      return;
    }

    let value: TimeRange | null = null;
    if (this.fg.valid) {
      const rawValue = this.fg.value;
      value = new TimeRange(rawValue.startTime, rawValue.endTime);
    }
    if (this.lastValue === value || (value && value.equals(this.lastValue))) {
      return;
    }
    this.lastValue = value;
    this.touched();

    if (this.onChange) {
      this.onChange(value);
    }
  }

  ngOnDestroy(): void {
    this.timeSubscription?.unsubscribe();
    this.endTimeValidationSubscription?.unsubscribe();
    this.changeSubscription?.unsubscribe();
  }

  private getFGValidators(): ValidatorFn[] {
    const ret = [Validators.required];
    if (this.durationLimits?.min) {
      ret.push(minTimeDurationValidator(this.durationLimits.min));
    }
    if (this.durationLimits?.max) {
      ret.push(maxTimeDurationValidator(this.durationLimits.max));
    }
    return ret;
  }

  private getStartTimeValidators(): ValidatorFn[] {
    const ret = [];
    if (this.startTimeOffsetLimits?.min) {
      ret.push(minTimeValidator(this.startTimeOffsetLimits.min));
    }
    if (this.startTimeOffsetLimits?.max) {
      ret.push(maxTimeValidator(this.startTimeOffsetLimits.max));
    }
    return ret;
  }

  private startIntervalChecker() {
    this.timeSubscription?.unsubscribe();
    if (!this.startCheckInterval) {
      return;
    }
    this.timeSubscription = timer(100, this.startCheckInterval.toMillis()).subscribe(() => {
      this.fg.controls.startTime.updateValueAndValidity();
    });
  }
}

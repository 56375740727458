<div cds-layout="horizontal gap:none">
  <cds-icon-button aria-label="Small" [class.active]="state === 0.7" [class.left-btn]="true" (click)="setState(0.7)">
    <cds-icon shape="text"></cds-icon>
  </cds-icon-button>
  <cds-icon-button aria-label="Medium" [class.active]="state === 1.0" [class.middle-btn]="true"
                   (click)="setState(1.0)">
    <cds-icon shape="text"></cds-icon>
  </cds-icon-button>
  <cds-icon-button aria-label="Large" [class.active]="state === 1.3" [class.right-btn]="true"
                   (click)="setState(1.3)">
    <cds-icon shape="text"></cds-icon>
  </cds-icon-button>
</div>

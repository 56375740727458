import {Component, inject} from '@angular/core';
import {ClrDatagridStateInterface} from '@clr/angular';
import {DateTime} from 'luxon';
import {SearchResult} from '../../../model/SearchResult';
import {IUserMessageSearch, UserMessageSearch, UserMessageService} from '../../../services/user-message.service';
import {UserMessageEntry} from '../../../model/UserMessage/UserMessageEntry';
import {UserMessageUtil} from '../../../model/UserMessage/UserMessageUtil';
import {ResponsiveScreenService} from '../../../services/responsive-screen.service';

@Component({
  selector: 'app-user-message-table',
  templateUrl: './user-message-table.component.html',
  styleUrls: ['./user-message-table.component.scss']
})
export class UserMessageTableComponent {
  totalItems: number;
  currentPageSize = 10;
  loading = true;
  messages: UserMessageEntry[];
  errorMessage: string;
  searchConfig: IUserMessageSearch = UserMessageSearch.defaultSearch();
  selectedMessages: UserMessageEntry[] = [];
  currentMessage: UserMessageEntry;
  modalSize: string;

  deviceSize$ = inject(ResponsiveScreenService).deviceSize$;

  private state: ClrDatagridStateInterface<any>;
  private previousState: ClrDatagridStateInterface;
  private previousSearch: IUserMessageSearch;

  constructor(private userMessageService: UserMessageService) {
    this.searchConfig = UserMessageSearch.defaultSearch();
  }

  private static isSearchFilterDifferent(statey: ClrDatagridStateInterface<any>, prevState: ClrDatagridStateInterface) {
    return JSON.stringify(statey.filters) !== JSON.stringify(prevState.filters);
  }

  private static isSearchDifferent(curSearch: IUserMessageSearch, prevSearch: IUserMessageSearch) {
    return JSON.stringify(curSearch) !== JSON.stringify(prevSearch);
  }

  refresh(statey?: ClrDatagridStateInterface, forceRecount: boolean = false) {
    this.loading = true;
    if (!statey) {
      statey = this.state || {};
    }

    this.state = statey;

    if (!statey.page) {
      statey.page = {
        from: 0,
        to: 9,
        size: 10,
      };
    }

    const fetchCount = forceRecount || !this.totalItems || UserMessageTableComponent.isSearchFilterDifferent(this.state,
      this.previousState) || UserMessageTableComponent.isSearchDifferent(this.searchConfig, this.previousSearch);

    this.userMessageService.getMessages(this.searchConfig, statey.page.size, statey.page.from, fetchCount)
      .subscribe((results: SearchResult<UserMessageEntry>) => {
        if (results.total >= 0) {
          this.totalItems = results.total;
        }
        this.loading = false;
        this.messages = results.results;
        statey.page.from = results.offset;
        statey.page.to = results.offset + results.results.length;
        this.userMessageService.refreshUnreadMessageCount();
      });

    this.previousState = statey;
    this.previousSearch = this.searchConfig;

  }

  formatDateTime(dateTime: DateTime): string {
    return dateTime.toLocal().toFormat('LL/dd/yyyy HH:mm');

  }

  updateSearchConfig($event: IUserMessageSearch) {
    this.searchConfig = $event;
    this.totalItems = null;
    this.refresh();
  }

  markMessagesAsRead() {
    const ids = (this.selectedMessages || []).map(msg => msg.id);
    this.userMessageService.markMessagesAsRead(ids).subscribe(() => {
      this.selectedMessages = [];
      this.refresh();
    });
  }

  deleteMessages() {
    const ids = (this.selectedMessages || []).map(msg => msg.id);
    this.userMessageService.deleteMessages(ids).subscribe(() => {
      this.selectedMessages = [];
      this.refresh(this.state, true);
    });
  }

  getMessageSummary(message: UserMessageEntry): string {
    return UserMessageUtil.getSummaryText(message.message);
  }

  getMessageNotes(message: UserMessageEntry): string {
    return UserMessageUtil.getNotesText(message.message);
  }

  getMessageAlertType(messageEntry: UserMessageEntry): string {
    return UserMessageUtil.getAlertType(messageEntry);
  }

  viewMessage(message: UserMessageEntry) {
    this.currentMessage = message;
    this.modalSize = UserMessageUtil.getModalSize(message.message);
  }
}

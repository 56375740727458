import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IOperatorIntentEntry, IOperatorIntentResultResponse} from '../../model/OperatorIntentEntry';
import {map} from 'rxjs/operators';
import {Parser} from '../../model/utm/parser/OperationParser';
import {OperatorIntentService} from '../operator-intent.service';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RestOperatorIntentService extends OperatorIntentService {
  baseIntentsUrl = `${environment.baseUrl}/operator/intents/`;
  baseIntentsSearchUrl = `${environment.baseUrl}/operator/intents/search`;

  constructor(private http: HttpClient) {
    super();
  }

  getOperatorIntent(intentId: string): Observable<IOperatorIntentEntry> {
    return this.http.get(this.baseIntentsUrl + intentId).pipe(map((Parser.parseOperatorIntentEntry)));
  }

  getOperatorIntentResult(intentId: string): Observable<IOperatorIntentResultResponse> {
    return this.http.get(this.baseIntentsUrl + intentId + '/result').pipe(map((Parser.parseOperatorIntentResultResponse)));
  }

  getOperatorIntentsByOpId(operationId: string): Observable<IOperatorIntentEntry[]> {
    return this.http.get(`${this.baseIntentsSearchUrl}?entity_id=${operationId}`)
      .pipe(map((res: any) => (res.operatorIntents || []).map(Parser.parseOperatorIntentEntry)));
  }
}

import {NavigationExtras} from '@angular/router';

export interface NavigationCommand {
  commands: string[];
  extras?: NavigationExtras;
}

export const PREDEFINED_NAVIGATIONS: { [k: string]: NavigationCommand } = {
  Operations: {
    commands: ['fuss', 'operations', 'ops']
  },
  MyActiveOperations: {
    commands: ['fuss', 'operations', 'ops'],
    extras: {
      queryParams: {scopes: ['personal'], state: ['ACCEPTED', 'ACTIVATED']},
      queryParamsHandling: 'merge'
    }
  },
  ViewEditConstraints: {
    commands: ['fuss', 'constraint', 'constraints']
  },
  MannedOperations: {
    commands: ['fuss', 'operations', 'mops']
  },
  Sensors: {
    commands: ['fuss', 'sdsp', 'sensors']
  },
  Platforms: {
    commands: ['fuss', 'registration', 'platforms']
  }
};


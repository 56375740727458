import * as _ from 'lodash';

export function compactObject(data: any): any {
  if (_.isNil(data)){
    return data;
  }

  if (typeof data !== 'object') {
    return data;
  }

  if (data instanceof Array) {
    const _data = data.map(e => compactObject(e)).filter(e => !_.isNil(e));
    if (_.isEmpty(_data)) {
      return null;
    } else {
      return _data;
    }
  }


  return Object.keys(data).reduce((accumulator, key) => {
    const isObject = typeof data[key] === 'object';
    const value = isObject ? compactObject(data[key]) : data[key];
    if (_.isNil(value)){
      return accumulator;
    }
    const isEmptyObject = isObject && !Object.keys(value).length;
    if (value === undefined || isEmptyObject) {
      return accumulator;
    }

    return Object.assign(accumulator, {[key]: value});
  }, {});
}

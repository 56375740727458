import {Injectable} from '@angular/core';
import {SearchResult} from '../model/SearchResult';
import {concat, EMPTY, Observable, of, timer} from 'rxjs';
import {DateTime} from 'luxon';
import {ConstraintState, UvrExt} from '../model/utm/UvrExt';
import {ConstraintSubmission} from '../model/ConstraintSubmission';
import {catchError, exhaustMap, map, mergeMap, reduce} from 'rxjs/operators';
import * as _ from 'lodash';
import {Volume4dQuery} from '../models/Volume4dQuery';
import {ConstraintResponse} from './rest/rest-constraint.service';

export interface IConstraintSearchParameters {
  volume4d?: Volume4dQuery;
  submitTimeAfter?: DateTime;
  submitTimeBefore?: DateTime;
  state: ConstraintState[];
  reason?: string;
  /* eslint-disable @typescript-eslint/naming-convention */
  /* tslint:disable:variable-name */
  constraint_type?: string;
  uss_name?: string;
  /* tslint:enable:variable-name */
  /* eslint-enable @typescript-eslint/naming-convention */

  sort?: ConstraintSearchSortField[];
  sortIncreasing?: boolean;
  limit?: number;
  offset?: number;
  fetchCount?: boolean;
}

export enum ConstraintSearchSortField {
  state = 'state',
  reason = 'reason',
  effective_time_begin = 'effective_time_begin',
  effective_time_end = 'effective_time_end',
  constraint_type = 'constraint_type',
  uss_name = 'uss_name',
  unknown = '__unknown__'
}

export class ConstraintSearchSortFieldUtil {
  static fromString(s: string): ConstraintSearchSortField {
    if (!s) {
      return ConstraintSearchSortField.unknown;
    }
    switch (s.toLowerCase()) {
      // case 'state':
      //   return ConstraintSearchSortField.state;
      case 'reason':
        return  ConstraintSearchSortField.reason;
      case 'effective_time_begin':
        return ConstraintSearchSortField.effective_time_begin;
      case 'effective_time_end':
        return ConstraintSearchSortField.effective_time_end;
      case 'additional_data.constraint_type':
      case 'constraint_type':
        return ConstraintSearchSortField.constraint_type;
      case 'uss_name':
        return ConstraintSearchSortField.uss_name;
      default:
        console.error(`Invalid sort option: ${s}`);
        return ConstraintSearchSortField.unknown;
    }
  }
}

export class ConstraintSearchParameters implements IConstraintSearchParameters {
  volume4d?: Volume4dQuery;
  submitTimeAfter?: DateTime;
  submitTimeBefore?: DateTime;
  state: ConstraintState[];
  reason?: string;
  /* eslint-disable @typescript-eslint/naming-convention */
  /* tslint:disable:variable-name */
  constraint_type?: string;
  uss_name?: string;
  /* tslint:enable:variable-name */
  /* eslint-enable @typescript-eslint/naming-convention */

  sort?: ConstraintSearchSortField[];
  sortIncreasing?: boolean;
  limit?: number;
  offset?: number;
  fetchCount?: boolean;

  public constructor(raw?: IConstraintSearchParameters) {
    if (raw) {
      this.volume4d = raw.volume4d;
      this.submitTimeAfter = raw.submitTimeAfter;
      this.submitTimeBefore = raw.submitTimeBefore;
      this.state = raw.state?.length ? raw.state : [ConstraintState.ACCEPTED, ConstraintState.ACTIVE];
      this.reason = raw.reason;
      this.constraint_type = raw.constraint_type;
      this.uss_name = raw.uss_name;
      this.sort = raw.sort?.length ? raw.sort : [ConstraintSearchSortField.effective_time_begin];
      this.sortIncreasing = !_.isNil(raw.sortIncreasing) ? raw.sortIncreasing : false;
      this.limit = !_.isNil(raw.limit) ? raw.limit : 10;
      this.offset = !_.isNil(raw.offset) ? raw.offset : 0;
      this.fetchCount = !_.isNil(raw.fetchCount) ? raw.fetchCount : false;
    } else {
      this.volume4d = undefined;
      this.submitTimeAfter = undefined;
      this.submitTimeBefore = undefined;
      this.state = [ConstraintState.ACCEPTED, ConstraintState.ACTIVE];
      this.reason = undefined;
      this.constraint_type = undefined;
      this.uss_name = undefined;
      this.sort = [ConstraintSearchSortField.effective_time_begin];
      this.sortIncreasing = false;
      this.limit = 10;
      this.offset = 0;
      this.fetchCount = false;
    }
  }

  public static defaultSearch(): ConstraintSearchParameters {
    return new ConstraintSearchParameters();
  }
}


@Injectable({
  providedIn: 'root'
})
export abstract class ConstraintService {

  constructor() {
    // Method not implemented
  }

  pollConstraint(constraintId: string, interval: number= 5000, dueTime: number= 100): Observable<UvrExt>{
    return timer(dueTime, interval).pipe(exhaustMap(() => this.getConstraint(constraintId)
      .pipe(catchError(() => (EMPTY)))));
  }

  getAllConstraints(search: IConstraintSearchParameters): Observable<UvrExt[]> {
    const stepSize = 100;
    const initialSearch = _.cloneDeep(search);
    initialSearch.limit = stepSize;
    initialSearch.offset = 0;
    initialSearch.fetchCount = true;

    return this.getConstraints(initialSearch).pipe(
      mergeMap(res => {
        const constraints: Observable<SearchResult<UvrExt>>[] = [of(res)];

        if (res.results.length < res.total) {
          const baseSearch = _.cloneDeep(initialSearch);
          baseSearch.fetchCount = false;

          for (let i = res.offset + res.results.length; i < res.total; i += stepSize) {
            const thisSearch = _.cloneDeep(baseSearch);
            thisSearch.offset = i;
            constraints.push(this.getConstraints(thisSearch));
          }
        }
        return concat(...constraints).pipe(
          reduce((all, res) => all.concat([res]), [])
        );
      })
    ).pipe(
      map((results: SearchResult<UvrExt>[]) => _.flatten(results.map(r => r.results)))
    );

  }

  abstract getConstraints(constraintSearchConfigParameters: IConstraintSearchParameters): Observable<SearchResult<UvrExt>>;

  abstract getConstraint(constraintId: string): Observable<UvrExt>;

  abstract updateConstraint(constraintId: string, constraint: UvrExt): Observable<boolean>;

  abstract submitConstraint(constraint: ConstraintSubmission): Observable<ConstraintResponse>;

  abstract closeConstraint(constraintToClose: UvrExt): Observable<boolean>;

  abstract watchConstraints(): Observable<UvrExt>;

  abstract watchConstraint(constraintId: string): Observable<UvrExt>;
}

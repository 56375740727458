/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseModel} from './base-model';

import {IPolygon, Polygon} from './polygon.model';
import {IPersonOrOrganization, PersonOrOrganization} from './person-or-organization.model';
import {DateTime} from "luxon";

export interface IUssInstance {
  uss_instance_id: string;
  uss_name?: string;
  time_submitted?: DateTime;
  time_available_begin: DateTime;
  time_available_end: DateTime;
  coverage_area: IPolygon;
  time_last_modified?: DateTime;
  contact?: IPersonOrOrganization;
  uss_base_callback_url: string;
  uss_informational_url?: string;
  uss_openapi_url?: string;
  uss_registration_url?: string;
  notes?: string;
}


export class UssInstance extends BaseModel implements IUssInstance {

  static USS_INSTANCE_ID_FIELD_NAME = 'uss_instance_id';
  static USS_NAME_FIELD_NAME = 'uss_name';
  static TIME_SUBMITTED_FIELD_NAME = 'time_submitted';
  static TIME_AVAILABLE_BEGIN_FIELD_NAME = 'time_available_begin';
  static TIME_AVAILABLE_END_FIELD_NAME = 'time_available_end';
  static COVERAGE_AREA_FIELD_NAME = 'coverage_area';
  static TIME_LAST_MODIFIED_FIELD_NAME = 'time_last_modified';
  static CONTACT_FIELD_NAME = 'contact';
  static USS_BASE_CALLBACK_URL_FIELD_NAME = 'uss_base_callback_url';
  static USS_INFORMATIONAL_URL_FIELD_NAME = 'uss_informational_url';
  static USS_OPENAPI_URL_FIELD_NAME = 'uss_openapi_url';
  static USS_REGISTRATION_URL_FIELD_NAME = 'uss_registration_url';
  static NOTES_FIELD_NAME = 'notes';

  uss_instance_id: string;
  /** The name of the entity providing UAS Support Services. Populated by the service discovery system based on credential information provided by the USS. UssInstance uss_name MUST be the uss_name as known by the authorization server. The maximum and minimum character length is based on a usable domain name, and considering the maximum in RFC-1035. */
  uss_name: string;
  /** The time at which the submission of this USS Instance was received at this discovery service.  Only modifiable by discovery service. */
  time_submitted: DateTime;
  /** The time at which the USS will begin providing services for active UAS operations for this USS Instance.  Note that the USS may provide planning services prior to the time_available_begin depending on the policies of the USS. */
  time_available_begin: DateTime;
  /** The time at which the USS will cease providing services for active UAS operations for this USS Instance.  This means that there will not be any UAS operations airborne after this time that would be supported by this USS Instance. */
  time_available_end: DateTime;
  coverage_area: Polygon;
  /** The last time there was an update to the data regarding this USS Instance. Only modifiable by discovery service. */
  time_last_modified: DateTime;
  contact: PersonOrOrganization;
  /** A base URL used by the USS Discovery Service and other USS Instances to callback to the USS Instance. USS Discovery will append "/uss" to the base URL in order to PUT a new uss_instance to the USS Instance. It will also do the same to test the URL when it first receives a uss_instance. USS Instances and other clients/services will append "/operations" to the base URL in order to share operations. Similarly with "/utm_messages", "/negotiations", etc. The base URL will also be used in obtaining the websocket for position sharing. This URL may be unique to the USS Instance or may be shared by other USS Instances created by the same USS.  URLs that are common to multiple USS Instances will receive only one callback. */
  uss_base_callback_url: string;
  /** An optional website URL for the USS for human use. */
  uss_informational_url: string;
  /** An optional URL for the USS to supply an OpenAPI specification of its UAS Operator interface. */
  uss_openapi_url: string;
  /** An optional URL for UAS operators to register with this USS for services. */
  uss_registration_url: string;
  /** Any additional free text that would aid consumers of the service discovery API in understanding this USS Instance. */
  notes: string;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IUssInstance>, useFormGroupValuesToModel = false) {
    super();
    this.coverage_area = new Polygon();
    this.contact = new PersonOrOrganization();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IUssInstance>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.uss_instance_id = this.getValue<string>(rawValues, UssInstance.USS_INSTANCE_ID_FIELD_NAME);
      this.uss_name = this.getValue<string>(rawValues, UssInstance.USS_NAME_FIELD_NAME);
      this.time_submitted = this.getValue<DateTime>(rawValues, UssInstance.TIME_SUBMITTED_FIELD_NAME);
      this.time_available_begin = this.getValue<DateTime>(rawValues, UssInstance.TIME_AVAILABLE_BEGIN_FIELD_NAME);
      this.time_available_end = this.getValue<DateTime>(rawValues, UssInstance.TIME_AVAILABLE_END_FIELD_NAME);
      this.coverage_area.setValues(rawValues.coverage_area, useFormGroupValuesToModel);
      this.time_last_modified = this.getValue<DateTime>(rawValues, UssInstance.TIME_LAST_MODIFIED_FIELD_NAME);
      this.contact.setValues(rawValues.contact, useFormGroupValuesToModel);
      this.uss_base_callback_url = this.getValue<string>(rawValues, UssInstance.USS_BASE_CALLBACK_URL_FIELD_NAME);
      this.uss_informational_url = this.getValue<string>(rawValues, UssInstance.USS_INFORMATIONAL_URL_FIELD_NAME);
      this.uss_openapi_url = this.getValue<string>(rawValues, UssInstance.USS_OPENAPI_URL_FIELD_NAME);
      this.uss_registration_url = this.getValue<string>(rawValues, UssInstance.USS_REGISTRATION_URL_FIELD_NAME);
      this.notes = this.getValue<string>(rawValues, UssInstance.NOTES_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        uss_instance_id: new UntypedFormControl(this.uss_instance_id, [Validators.required, Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        uss_name: new UntypedFormControl(this.uss_name, [Validators.minLength(4), Validators.maxLength(250),]),
        time_submitted: new UntypedFormControl(this.time_submitted, [Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'),]),
        time_available_begin: new UntypedFormControl(this.time_available_begin, [Validators.required, Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'),]),
        time_available_end: new UntypedFormControl(this.time_available_end, [Validators.required, Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'),]),
        coverage_area: this.coverage_area.$formGroup,
        time_last_modified: new UntypedFormControl(this.time_last_modified, [Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'),]),
        contact: this.contact.$formGroup,
        uss_base_callback_url: new UntypedFormControl(this.uss_base_callback_url, [Validators.required,]),
        uss_informational_url: new UntypedFormControl(this.uss_informational_url),
        uss_openapi_url: new UntypedFormControl(this.uss_openapi_url),
        uss_registration_url: new UntypedFormControl(this.uss_registration_url),
        notes: new UntypedFormControl(this.notes),
      });
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[UssInstance.USS_INSTANCE_ID_FIELD_NAME].setValue(this.uss_instance_id);
    this.$formGroup.controls[UssInstance.USS_NAME_FIELD_NAME].setValue(this.uss_name);
    this.$formGroup.controls[UssInstance.TIME_SUBMITTED_FIELD_NAME].setValue(this.time_submitted);
    this.$formGroup.controls[UssInstance.TIME_AVAILABLE_BEGIN_FIELD_NAME].setValue(this.time_available_begin);
    this.$formGroup.controls[UssInstance.TIME_AVAILABLE_END_FIELD_NAME].setValue(this.time_available_end);
    this.coverage_area.setFormGroupValues();
    this.$formGroup.controls[UssInstance.TIME_LAST_MODIFIED_FIELD_NAME].setValue(this.time_last_modified);
    this.contact.setFormGroupValues();
    this.$formGroup.controls[UssInstance.USS_BASE_CALLBACK_URL_FIELD_NAME].setValue(this.uss_base_callback_url);
    this.$formGroup.controls[UssInstance.USS_INFORMATIONAL_URL_FIELD_NAME].setValue(this.uss_informational_url);
    this.$formGroup.controls[UssInstance.USS_OPENAPI_URL_FIELD_NAME].setValue(this.uss_openapi_url);
    this.$formGroup.controls[UssInstance.USS_REGISTRATION_URL_FIELD_NAME].setValue(this.uss_registration_url);
    this.$formGroup.controls[UssInstance.NOTES_FIELD_NAME].setValue(this.notes);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

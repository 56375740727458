import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {Observable, ReplaySubject, Subscription} from 'rxjs';
import {ConfigComponent} from '../../../../import-export/config.component';
import {
  BaseImportConfigType
} from '../../../../import-export/components/base-import-config/base-import-config.component';
import {ImportService} from '../../../../import-export/import-export.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormControlify} from '../../../../utils/forms';

@Component({
  selector: 'app-geojson-operation-volume-import-config',
  templateUrl: './geojson-volume-import-config.component.html',
  styleUrls: ['./geojson-volume-import-config.component.scss']
})
export class GeojsonVolumeImportConfigComponent implements AfterViewInit, ConfigComponent, OnDestroy {
  @ViewChild('importFileControl') importFileControl: ElementRef;

  importValue: File;
  extensions = '.*';
  fg: FormGroup<FormControlify<BaseImportConfigType>>;

  private configSubject: ReplaySubject<BaseImportConfigType> = new ReplaySubject<BaseImportConfigType>(1);
  private service: ImportService<any>;
  private config: BaseImportConfigType;
  private fgSub: Subscription;

  constructor() {
    this.fg = new FormGroup<FormControlify<BaseImportConfigType>>({
      file: new FormControl<File>(null, Validators.required)
    });
  }

  ngAfterViewInit() {
    this.fgSub = this.fg.valueChanges.subscribe(() => this.emitNewConfig());
  }

  getConfig(): Observable<BaseImportConfigType> {
    return this.configSubject;
  }

  setConfig(config: BaseImportConfigType) {
    this.importValue = config.file;
    this.fg.setValue(config);

    if (!config?.file && this.importFileControl) {
      this.importFileControl.nativeElement.value = null;
    }
    this.configSubject.next(config);
  }

  setService(service: ImportService<any>) {
    this.service = service;
    this.extensions = (service.getFileExtensions() || []).join(',');
  }

  emitNewConfig() {
    this.config = {
      file: this.importFileControl.nativeElement.files[0]
    };
    this.configSubject.next(this.config);
  }

  ngOnDestroy() {
    this.fgSub?.unsubscribe();
  }
}

import {IUser, User} from './User';

export interface IUserSearchResult {
  offset: number;
  count: number;
  users: IUser[];
}

export class UserSearchResult {
  offset: number;
  count: number;
  users: User[];

  constructor(offset: number,
              count: number,
              users: IUser[]) {
    this.offset = offset;
    this.count = count;
    this.users = (users || []).map(User.from);
  }
}

import {Operation as SwaggerOperation} from '../gen/utm/operation.model';
import {DateTime} from 'luxon';
import {Altitude} from '../gen/utm';

export class Operation extends SwaggerOperation {
  getSubmitTime(): DateTime {
    return this.submit_time;
  }

  getMinimumAltitude(): Altitude {
    let minAltitude = this.operation_volumes[0].min_altitude;
    this.operation_volumes.map(vol => vol.min_altitude).forEach(alt =>{
      if(alt.altitude_value < minAltitude.altitude_value){
        minAltitude = alt;
      }
    })
    return minAltitude;
  }

  getMaximumAltitude(): Altitude {
    let maxAltitude = this.operation_volumes[0].max_altitude;
    this.operation_volumes.map(vol => vol.max_altitude).forEach(alt =>{
      if(alt.altitude_value > maxAltitude.altitude_value){
        maxAltitude = alt;
      }
    })
    return maxAltitude;
  }

}


// To parse this data:
//
//   import { Convert, UGCSFlightPlan } from "./file";
//
//   const uGCSFlightPlan = Convert.toUGCSFlightPlan(json);

export interface UGCSFlightPlan {
  version: Version;
  payloadProfiles: PayloadProfile[];
  vehicleProfiles: VehicleProfile[];
  route: Route;
}

export interface PayloadProfile {
  name: string;
  shortName?: null;
  payloadType: string;
  imageKey: string;
  parameters: { [key: string]: number | null };
}

export interface Route {
  name: string;
  creationTime: number;
  scheduledTime: number | null;
  startDelay: number | null;
  vehicleProfile: string;
  trajectoryType: null;
  safeAltitude: number;
  maxAltitude: number;
  initialSpeed: number | null;
  maxSpeed: number | null;
  failsafes: Failsafes;
  checkAerodromeNfz: boolean;
  checkCustomNfz: boolean;
  segments: Segment[];
  takeoffHeight: number | null;
  cornerRadius?: null;
}

export interface Failsafes {
  rcLost: string;
  gpsLost: string;
  lowBattery: string;
  datalinkLost: string;
}

export interface Segment {
  type: Type;
  actions: Action[];
  point?: Point;
  parameters: Parameters;
  circle?: Circle;
  polygon?: Polygon;
}

export interface Action {
  type: string;
  mode: string;
  latitude: number | null;
  longitude: number | null;
  wgs84Altitude: number | null;
  aglAltitude: null;
  altitudeType: AltitudeType;
}

export enum AltitudeType {
  Agl = 'AGL',
  Amsl = 'AMSL',
  SmartAgl = 'SMART_AGL',
  Wgs84 = 'WGS84',
}

export interface Circle {
  center: Point;
  radius: number;
}

export interface Point {
  latitude: number;
  longitude: number;
  altitude: number;
  altitudeType: AltitudeType;
}

export interface Parameters {
  avoidObstacles: boolean;
  avoidTerrain?: boolean;
  speed?: number;
  altitudeType: AltitudeType;
  wpTurnType?: WpTurnType;
  cornerRadius?: null;
  actionExecution?: string;
  basePointsQnt?: null;
  flightClockwise?: boolean;
  followTerrain?: boolean;
  loops?: number;
  noActionsAtLastPoint?: boolean;
  height?: number;
  sideDistance?: number;
  directionAngle?: number;
  overshoot?: number;
  overshootSpeed?: number;
  areaScanAllowPartialCalculation?: boolean;
  tolerance?: number;
  doubleGrid?: boolean;
}

export enum WpTurnType {
  Spline = 'SPLINE',
  StopAndTurn = 'STOP_AND_TURN',
}

export interface Polygon {
  points: Point[];
}

export enum Type {
  AreaScan = 'AreaScan',
  Circle = 'Circle',
  Landing = 'Landing',
  TakeOff = 'TakeOff',
  Waypoint = 'Waypoint',
}

export interface VehicleProfile {
  name: string;
  vehicleType: string;
  modelKey: string;
  imageKey: string;
  platformCode: string;
  primary: boolean;
  payloadProfiles: string[];
  parameters: { [key: string]: number | null };
}

export interface Version {
  major: number;
  minor: number;
  build: number;
  component: string;
}

// Converts JSON strings to/from your types
export class Convert {
  public static toUGCSFlightPlan(json: string): UGCSFlightPlan {
    return JSON.parse(json);
  }

  public static uGCSFlightPlanToJson(value: UGCSFlightPlan): string {
    return JSON.stringify(value);
  }
}

import {IUserMessageData, UserMessageData} from './UserMessageData';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {state} from '../gen/utm';

export interface IOperationConflictAlertMessage extends IUserMessageData {
  operationId: string;
  conflictingOperationId: string;
  conflictingOperationState: state;
  conflictingOperationPriority: number;
  conflictingOperationPrioritized: boolean;
  replanRequired: boolean;
  replanForbidden: boolean;
}

export class OperationConflictAlertMessage extends UserMessageData implements IOperationConflictAlertMessage {
  /* eslint-disable @typescript-eslint/naming-convention */
  static OPERATION_ID_FIELD_NAME  = 'operationId';
  static CONFLICTING_OPERATION_ID_FIELD_NAME = 'conflictingOperationId';
  static CONFLICTING_OPERATION_STATE_FIELD_NAME = 'conflictingOperationState';
  static CONFLICTING_OPERATION_PRIORITY_FIELD_NAME = 'conflictingOperationPriority';
  static CONFLICTING_OPERATION_PRIORITIZED_FIELD_NAME = 'conflictingOperationPrioritized';
  static REPLAN_REQUIRED_FIELD_NAME = 'replanRequired';
  static REPLAN_FORBIDDEN_FIELD_NAME = 'replanForbidden';

  operationId: string;
  conflictingOperationId: string;
  conflictingOperationState: state;
  conflictingOperationPriority: number;
  conflictingOperationPrioritized: boolean;
  replanRequired: boolean;
  replanForbidden: boolean;

  constructor(values?: Partial<IOperationConflictAlertMessage>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  setValues(values: Partial<IUserMessageData>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.operationId = this.getValue<string>(rawValues, OperationConflictAlertMessage.OPERATION_ID_FIELD_NAME);
      this.conflictingOperationId = this.getValue<string>(rawValues, OperationConflictAlertMessage.CONFLICTING_OPERATION_ID_FIELD_NAME);
      this.conflictingOperationState = this.getValue<state>(rawValues, OperationConflictAlertMessage.CONFLICTING_OPERATION_STATE_FIELD_NAME);
      this.conflictingOperationPriority = this.getValue<number>(rawValues, OperationConflictAlertMessage.CONFLICTING_OPERATION_PRIORITY_FIELD_NAME);
      this.conflictingOperationPrioritized = this.getValue<boolean>(rawValues, OperationConflictAlertMessage.CONFLICTING_OPERATION_PRIORITIZED_FIELD_NAME);
      this.replanRequired = this.getValue<boolean>(rawValues, OperationConflictAlertMessage.REPLAN_REQUIRED_FIELD_NAME);
      this.replanForbidden = this.getValue<boolean>(rawValues, OperationConflictAlertMessage.REPLAN_FORBIDDEN_FIELD_NAME);

      super.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[OperationConflictAlertMessage.OPERATION_ID_FIELD_NAME].setValue(this.operationId);
    this.$formGroup.controls[OperationConflictAlertMessage.CONFLICTING_OPERATION_ID_FIELD_NAME].setValue(this.conflictingOperationId);
    this.$formGroup.controls[OperationConflictAlertMessage.CONFLICTING_OPERATION_STATE_FIELD_NAME].setValue(this.conflictingOperationState);
    this.$formGroup.controls[OperationConflictAlertMessage.CONFLICTING_OPERATION_PRIORITY_FIELD_NAME].setValue(this.conflictingOperationPriority);
    this.$formGroup.controls[OperationConflictAlertMessage.CONFLICTING_OPERATION_PRIORITIZED_FIELD_NAME].setValue(this.conflictingOperationPrioritized);
    this.$formGroup.controls[OperationConflictAlertMessage.REPLAN_REQUIRED_FIELD_NAME].setValue(this.replanRequired);
    this.$formGroup.controls[OperationConflictAlertMessage.REPLAN_FORBIDDEN_FIELD_NAME].setValue(this.replanForbidden);
    super.setFormGroupValues();
  }

  protected getFormGroup(): UntypedFormGroup {
    /* eslint-disable no-underscore-dangle */
    if (!this._formGroup) {
      this._formGroup = super.getFormGroup();
      this._formGroup.addControl('operationId', new UntypedFormControl(this.operationId, [Validators.required]));
      this._formGroup.addControl('conflictingOperationId', new UntypedFormControl(this.conflictingOperationId, [Validators.required]));
      this._formGroup.addControl('conflictingOperationState', new UntypedFormControl(this.conflictingOperationState, [Validators.required]));
      this._formGroup.addControl('conflictingOperationPriority', new UntypedFormControl(this.conflictingOperationPriority, [Validators.required]));
      this._formGroup.addControl('conflictingOperationPrioritized', new UntypedFormControl(this.conflictingOperationPrioritized, [Validators.required]));
      this._formGroup.addControl('replanRequired', new UntypedFormControl(this.replanRequired, [Validators.required]));
      this._formGroup.addControl('replanForbidden', new UntypedFormControl(this.replanForbidden, [Validators.required]));
    }

    return this._formGroup;
    /* eslint-enable no-underscore-dangle */
  }
}

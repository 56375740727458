import {IStandardResponse, StandardResponse} from './StandardResponse';
import {IUser, User} from './User';

export interface IUserResponse extends IStandardResponse {
  userId: string;
  user: IUser;
}

export class UserResponse extends StandardResponse {
  user: User;
  userId: string;

  constructor(userResponse: IUserResponse) {
    super(userResponse);
    this.userId = userResponse.userId;
    if (userResponse.user) {
      this.user = User.from(userResponse.user);
    }
  }

}

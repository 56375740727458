import {DateTime} from 'luxon';
import {
  OperatorIntentFailureReason,
  OperatorIntentType,
  TransportOperatorIntentFailureReasonEnum,
  TransportOperatorIntentTypeEnum
} from './UserMessage/enums';

export interface IOperatorIntentEntry {
  intentId: string;

  userId: string;

  timeCreated: DateTime;

  intentType: OperatorIntentType;

  intentStatus: OperatorIntentStatus;

  entityId: string;

  entitySubmission: any;

  securityFilters: SecurityFilters;

  intentResult: Result;
}

export interface IOperatorIntentResultResponse {
  intentStatus: OperatorIntentStatus;
  intentResult: Result;
}

export enum OperatorIntentStatus {
  RECEIVED = 'RECEIVED',
  PROCESSING = 'PROCESSING',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  FINISHED = 'FINISHED',
}

export interface SecurityFilters {
  user: string;

  org: string;

  userId: string;

  includePublic: boolean;

  orgs: string[];

  categories: string[];
}

export interface Result {
  resultTime: DateTime;

  success: boolean;

  failureReason: OperatorIntentFailureReason;

  errorMessage: string;

  resultEntityId: string;

  conflictingOperations: string[];

  conflictingConstraints: string[];
}

export interface TransportOperatorIntentEntry {
  intentId: string;

  userId: string;

  timeCreated: string;

  intentType: TransportOperatorIntentTypeEnum;

  intentStatus: TransportOperatorIntentStatus;

  entityId: string;

  entitySubmission: any;

  securityFilters: SecurityFilters;

  intentResult: TransportResult;
}

export interface TransportOperatorIntentResultResponse {
  intentStatus: TransportOperatorIntentStatus;
  intentResult: TransportResult;
}

export type TransportOperatorIntentStatus = 'RECEIVED' | 'PROCESSING' | 'PENDING_APPROVAL' | 'FINISHED';

export const TransportOperatorIntentStatus = {
  RECEIVED: 'RECEIVED' as TransportOperatorIntentStatus,
  PROCESSING: 'PROCESSING' as TransportOperatorIntentStatus,
  PENDING_APPROVAL: 'PENDING_APPROVAL' as TransportOperatorIntentStatus,
  FINISHED: 'FINISHED' as TransportOperatorIntentStatus,
};

export interface TransportResult {
  resultTime: string;

  success: boolean;

  failureReason: TransportOperatorIntentFailureReasonEnum;

  errorMessage: string;

  resultEntityId: string;

  conflictingOperations: string[];

  conflictingConstraints: string[];
}

<input autocomplete="off" type="text" class="clr-input" style="color:black;" disabled="true" [value]="platformText">
<button type="button" (click)="openPlatformSelector($event)" class="btn btn-primary btn-sm select-btn" [disabled]="isDisabled">Select</button>

<clr-modal [(clrModalOpen)]="selectingPlatform" [clrModalClosable]="true" [clrModalSize]="'md'">
  <div class="modal-body" *ngIf="selectingPlatform">
    <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="datagrid-compact" style="height: 100%;+
">
      <clr-dg-column></clr-dg-column>
      <clr-dg-column>FAA Registration</clr-dg-column>
      <clr-dg-column>Model</clr-dg-column>

      <clr-dg-row *ngFor="let platform of platforms" [clrDgItem]="platform">
        <clr-dg-cell>
          <button type="button" (click)="selectPlatform(platform)" class="btn btn-primary btn-sm">Select</button>
        </clr-dg-cell>
        <clr-dg-cell>{{platform.faaRegistrationNumber}}</clr-dg-cell>
        <clr-dg-cell>{{platform.aircraftSpecs.model}}</clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize"
                           [clrDgTotalItems]="totalItems"></clr-dg-pagination>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}}
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Platforms per page</clr-dg-page-size>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
</clr-modal>

import {Injectable} from '@angular/core';
import {ConstraintTypeService} from '../constraint-type.service';
import {Observable, of} from 'rxjs';

const baseConstraintTypes = [
  {name: 'Restricted', value: 'RESTRICTED'},
  {name: 'Public Safety', value: 'PUBLIC_SAFETY'},
  {name: 'Security', value: 'SECURITY'},
  {name: 'Training', value: 'TRAINING'},
  {name: 'Advisory', value: 'ADVISORY'},
  {name: 'Approval Required', value: 'APPROVAL_REQUIRED'}
];


@Injectable({
  providedIn: 'root'
})
export class MockConstraintTypeService extends ConstraintTypeService {
  constructor() {
    super();
  }

  fetchConstraintTypes($event: string): Observable<{ name: string; value: string }[]> {
    return of(baseConstraintTypes);
  }

  getPrettyName(constraintValue: string): Observable<string> {

    for (const t of baseConstraintTypes) {
      if (t.value === constraintValue) {
        return of(t.name);
      }
    }
    return of(constraintValue || '');

  }
}

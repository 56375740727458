import {Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, Output, SimpleChanges} from '@angular/core';
import * as L from 'leaflet';
import {FeatureGroup, Layer, LeafletEvent} from 'leaflet';
import {Subscription} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export type GeomanLayer = Layer & { pm };

export type CopyableControls = 'Marker' |
  'Circle' |
  'Polygon' |
  'Rectangle' |
  'Polyline' |
  'Line' |
  'CircleMarker' |
  'Edit' |
  'Drag' |
  'Cut' |
  'Removal';

@Component({
  selector: 'app-leaflet-geoman-control',
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => GeomanControlComponent),
    }
  ]
})
export class GeomanControlComponent implements OnChanges, OnDestroy, ControlValueAccessor {
  @Input() copy: CopyableControls;
  @Input() name: string;
  @Input() block: 'draw' | 'edit' | 'custom' = 'custom';
  @Input() title: string;
  @Input() className: string;
  @Input() hintlineStyle: any;
  @Input() templineStyle: any;
  @Input() toggle: boolean;
  @Input() disabled: boolean;
  @Input() allowSelfIntersection = true;
  @Input() layers: (GeomanLayer)[] = [];
  @Input() pathOptions: any;
  @Input() globalOptions: any;

  @Output() click: EventEmitter<any>;
  @Output() postClick: EventEmitter<any>;
  @Output() layerCreate: EventEmitter<LeafletEvent> = new EventEmitter<LeafletEvent>();
  @Output() layerEdit: EventEmitter<LeafletEvent> = new EventEmitter<LeafletEvent>();
  @Output() layerUpdate: EventEmitter<LeafletEvent> = new EventEmitter<LeafletEvent>();
  @Output() layerDragend: EventEmitter<LeafletEvent> = new EventEmitter<LeafletEvent>();
  @Output() layerDrag: EventEmitter<LeafletEvent> = new EventEmitter<LeafletEvent>();
  @Output() layerRemove: EventEmitter<LeafletEvent> = new EventEmitter<LeafletEvent>();
  @Output() layersChange: EventEmitter<Layer[]> = new EventEmitter<Layer[]>();

  private map: L.Map & { pm };

  private tmpGlobalOptions: any;
  private control: any;
  private subs: Subscription[] = [];
  private onChange: (layers: GeomanLayer[]) => void;


  constructor() {
    // Method not implemented
  }

  handlePmInit(map: L.Map & { pm }): void {
    this.map = map;

    if (this.copy) {
      const options: { name: string; block: 'draw' | 'edit' | 'custom'; title: string; className?: string } = {
        name: this.name,
        block: this.block,
        title: this.title
      };
      if (this.className) {
        options.className = this.className;
      }

      this.control = map.pm.Toolbar.copyDrawControl(this.copy, options);
      map.pm.Draw[this.name].options.allowSelfIntersection = this.allowSelfIntersection;
      map.on('pm:create', (layer: LeafletEvent & { shape: string }) => {
        if (layer.shape === this.name) {
          this.layerCreate.emit(layer);
          this.emitEvent('pm:edit', layer.layer, this.layerEdit);
          this.emitEvent('pm:update', layer.layer, this.layerUpdate);
          this.emitEvent('pm:dragend', layer.layer, this.layerDragend);
          this.emitEvent('pm:drag', layer.layer, this.layerDrag);
          this.emitEvent('pm:remove', layer.layer, this.layerRemove);
        }
      });
      map.on('pm:drawstart', (e: LeafletEvent & { shape: string }) => {
        const shape: string = e.shape;
        if (shape === this.name) {
          this.tmpGlobalOptions = this.map.pm.getGlobalOptions();
          this.map.pm.setGlobalOptions(this.globalOptions);
        }

      });
      map.on('pm:drawend', (e: LeafletEvent & { shape: string }) => {
        const shape: string = e.shape;
        if (shape === this.name && this.tmpGlobalOptions) {
          this.map.pm.setGlobalOptions(this.tmpGlobalOptions);
        }
      });

      map.on('pm:globaldragmodetoggled', (e: LeafletEvent & { enabled: boolean }) => {

        if (!e.enabled) {
          this.emitLayers();
        }
      });

      map.on('pm:globaldrawmodetoggled', (e: LeafletEvent & { enabled: boolean }) => {

        if (!e.enabled) {
          this.emitLayers();
        }
      });

    }
    this.handleLayers();
    this.handlePathOptions();

    this.subs = [
      this.layerCreate.subscribe(() => {
        if (!this.map.pm.globalDrawModeEnabled()) {
          this.emitLayers();
        }
      }),
      this.layerRemove.subscribe(() => {
        this.emitLayers();
      }),
      this.layerUpdate.subscribe(() => {
        this.emitLayers();
      })
    ];

  }

  // handleImport(layers: Layer[]) {
  //
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.layers) {
      this.handleLayers();
    }

    if (changes.pathOptions) {
      this.handlePathOptions();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(s => s?.unsubscribe());
  }

  registerOnChange(fn: (layers: GeomanLayer[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(obj: GeomanLayer[]): void {
    this.layers = obj;
    this.handleLayers();
  }

  private emitEvent(eventType: string, layer: Layer, emitter: EventEmitter<LeafletEvent>) {
    layer.on(eventType, e => {
      emitter.emit(e);
    });
  }

  private handleLayers() {
    if (!this.map) {
      return;
    }

    const layers = this.layers || [];

    const hasNewLayers = layers.length > 0 && !layers.every(l => this.map.hasLayer(l));

    this.clearLayers(this.map, true);

    for (const layer of layers) {
      if (!layer.pm) {
        // reinit
      }
      // eslint-disable-next-line no-underscore-dangle
      layer.pm._shape = this.name;
      this.emitEvent('pm:edit', layer, this.layerEdit);
      this.emitEvent('pm:update', layer, this.layerUpdate);
      this.emitEvent('pm:dragend', layer, this.layerDragend);
      this.emitEvent('pm:drag', layer, this.layerDrag);
      this.emitEvent('pm:remove', layer, this.layerRemove);
      if (this.pathOptions) {
        (layer as any).setStyle(this.pathOptions);
      }
      layer.addTo(this.map);

    }

    if (layers.length > 0) {
      const layerGroup: FeatureGroup = new FeatureGroup();

      for (const layer of this.layers) {
        layerGroup.addLayer(layer);
      }

      if (layerGroup.getLayers().length > 0 && hasNewLayers) {
        this.map.fitBounds(layerGroup.getBounds());
      }
    }


  }

  private getLayers(): GeomanLayer[] {
    // eslint-disable-next-line no-underscore-dangle
    return (L as any).PM.Utils.findLayers(this.map).filter(l => l?.pm?._shape === this.name);
  }

  private clearLayers(map: L.Map, supressEvents = false) {
    const layers = this.getLayers();
    for (const layer of layers) {
      if (supressEvents) {
        layer.off();
      }
      map.removeLayer(layer);
    }
  }

  private handlePathOptions() {
    if (!this.map) {
      return;
    }
    if (this.map.pm && this.pathOptions) {
      this.map.pm.Draw[this.name].setPathOptions(this.pathOptions);
      const layers = this.getLayers();
      for (const layer of layers) {
        (layer as any).setStyle(this.pathOptions);
      }
    }
    if (this.map.pm) {
      if (this.hintlineStyle) {
        this.control.drawInstance.options.hintlineStyle = {...this.control.drawInstance.options.hintlineStyle, ...this.hintlineStyle};
      }
      if (this.templineStyle) {
        this.control.drawInstance.options.templineStyle = {...this.control.drawInstance.options.templineStyle, ...this.templineStyle};
      }
    }
  }

  private emitLayers(): void {
    const layers = this.getLayers();
    if (this.onChange) {
      this.onChange(layers);
    }
    this.layersChange.emit(layers);
  }
}

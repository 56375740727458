<ax-leaflet-container style="height: 500px; width:100%;">
  <geoman-container>
    <app-leaflet-geoman-control
      [layers]="layers"
      [pathOptions]="operationPathOptions"
      [name]="'OperationVolume'" [title]="'Operation Volume'" [block]="'draw'" [copy]="'Polygon'"
      (layerCreate)="handleTest1Event($event)"
      (layerDragend)="handleTest1Event($event)"
      (layerEdit)="handleTest1Event($event)"
      (layerRemove)="handleTest1Event($event)"
      (layerUpdate)="handleTest1Event($event)"></app-leaflet-geoman-control>

    <app-leaflet-geoman-control [name]="'ControllerLocation'" [title]="'Controller Location'" [block]="'draw'"
                    [copy]="'Marker'"
                    [className]="'fa fa-gamepad'"
                    [pathOptions]="controllerLocationPathOptions"
    ></app-leaflet-geoman-control>
    <app-leaflet-geoman-control [name]="'TakeOffLocation'" [title]="'Take Off Location'" [block]="'draw'"
                    [copy]="'Marker'"
                    [className]="'drone-icon'"
                    [pathOptions]="takeOffLocationPathOptions"
    ></app-leaflet-geoman-control>


  </geoman-container>
</ax-leaflet-container>

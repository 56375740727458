import {Component, forwardRef, OnDestroy} from '@angular/core';
import {
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {IControlValueAccessor} from "@rxweb/types";
import {CIRCLE_LIMITS, GeoCircle, units_of_measure} from "../../model/gen/utm";
import {debounceTime, map, pairwise} from "rxjs/operators";
import {Subscription} from "rxjs";
import {
  DMS_REGEX,
  KML_LATLON_REGEX,
  parseDMSCoordinates,
  parseKMLLatLonCoordinates
} from "../../utils/points-editor-utils";
import * as _ from "lodash";
import {MeasurementsUtil} from "../../utils/MeasurementsUtil";
import {SelectOption} from "../../select-option";

type CircleFormGroup = FormGroup<{
  latitude: FormControl<number>;
  longitude: FormControl<number>;
  radius: FormControl<number>;
  units: FormControl<units_of_measure>;
}>;

@Component({
  selector: 'app-manual-circle-input',
  templateUrl: './manual-circle-input.component.html',
  styleUrls: ['./manual-circle-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => ManualCircleInputComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => ManualCircleInputComponent),
    }
  ]
})
export class ManualCircleInputComponent implements OnDestroy, IControlValueAccessor<GeoCircle>, Validator {
  unitLabels = MeasurementsUtil.unitLabels;

  formGroup = new FormGroup({
    latitude: new FormControl(null, [Validators.required, Validators.min(-90), Validators.max(90)]),
    longitude: new FormControl(null, [Validators.required, Validators.min(-180), Validators.max(180)]),
    radius: new FormControl(null, [Validators.required]),
    units: new FormControl(units_of_measure.M, [Validators.required])
  });

  availableUnits: SelectOption[] = (() => {
    const ret = [];
    for (const option of Object.keys(units_of_measure)) {
      ret.push({label: option, value: units_of_measure[option]});
    }
    return ret;
  })();

  private onChange: (value: GeoCircle) => void;
  private onTouch: () => void;
  private onValidatorChange: () => void;
  private latFirst = true;
  private circleSub: Subscription;
  private selectedUnitsSub: Subscription;

  constructor() {
    // Emit circle value changes to the parent component
    this.circleSub = this.formGroup.valueChanges.pipe(
      debounceTime(100),
      map(circle => {
        if (!this.formGroup.valid) {
          return null;
        }
        return new GeoCircle({
          latitude: circle.latitude,
          longitude: circle.longitude,
          radius: circle.radius,
          units: circle.units
        });
      }))
      .subscribe((circle) => {
        this.onChange?.(circle);
      });

    // When the selected units change, convert the circle's radius to the new unit
    this.selectedUnitsSub = this.formGroup.controls.units.valueChanges.pipe(pairwise())
      .subscribe(([previousValue, currentValue]) => {
        if (previousValue && currentValue) {
          let circle = new GeoCircle({
            latitude: this.formGroup.controls.latitude.value,
            longitude: this.formGroup.controls.longitude.value,
            radius: this.formGroup.controls.radius.value,
            units: previousValue
          });
          circle = MeasurementsUtil.convertCircle(circle, currentValue, 4);
          this.setValues(circle, false);
        }
      });
  }

  ngOnDestroy(): void {
    this.circleSub?.unsubscribe();
    this.selectedUnitsSub?.unsubscribe();
  }

  registerOnChange(fn: (value: GeoCircle) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  writeValue(obj: GeoCircle | null): void {
    if (!obj) {
      this.formGroup.reset();
    } else {
      this.setValues(obj);
    }
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onValidatorChange = fn;
  }

  validate(fa: CircleFormGroup): ValidationErrors | null {
    return fa.errors;
  }

  setValues(circle: GeoCircle, emitEvent = true) {
    this.formGroup.setValue({
      latitude: !_.isNil(circle.latitude) ? _.round(circle.latitude, 6) : null,
      longitude: !_.isNil(circle.longitude) ? _.round(circle.longitude, 6) : null,
      radius: circle.radius ? _.round(circle.radius, 4) : null,
      units: circle.units
    }, {emitEvent});
    this.setRadiusValidators();
  }

  /**
   * Sets validators for the radius field based on the selected units of measure
   */
  setRadiusValidators(): void {
    const selectedUnit = this.formGroup.controls.units.value;
    const validators: ValidatorFn[] = [Validators.required];
    switch (selectedUnit) {
      case units_of_measure.M:
        validators.push(Validators.min(CIRCLE_LIMITS.MIN_RADIUS.METERS));
        validators.push(Validators.max(CIRCLE_LIMITS.MAX_RADIUS.METERS));
        break;
      case units_of_measure.FT:
        validators.push(Validators.min(CIRCLE_LIMITS.MIN_RADIUS.FEET));
        validators.push(Validators.max(CIRCLE_LIMITS.MAX_RADIUS.FEET));
        break;
      case units_of_measure.MI:
        validators.push(Validators.min(CIRCLE_LIMITS.MIN_RADIUS.MILES));
        validators.push(Validators.max(CIRCLE_LIMITS.MAX_RADIUS.MILES));
        break;
      case units_of_measure.NM:
        validators.push(Validators.min(CIRCLE_LIMITS.MIN_RADIUS.NAUTICAL_MILES));
        validators.push(Validators.max(CIRCLE_LIMITS.MAX_RADIUS.NAUTICAL_MILES));
        break;
      default:
        throw new Error(`Invalid units of measure: ${selectedUnit}`);
    }
    this.formGroup.controls.radius.setValidators(validators);
    this.formGroup.controls.radius.updateValueAndValidity();
  }

  /**
   * Handles pasting of coordinates into the latitude and longitude input fields
   * @param $event Clipboard paste event
   * @param control The control that was targeted by the paste event
   */
  handlePaste($event: ClipboardEvent, control: FormControl<number>) {
    $event.preventDefault();
    if (!$event.clipboardData) {
      return;
    }

    const text = $event.clipboardData.getData('text');
    const filteredText = text.replace(/[\n\r]+/g,' ');
    let coordinates: {latitude?: number, longitude?: number}[] = [];

    DMS_REGEX.lastIndex = 0;
    KML_LATLON_REGEX.lastIndex = 0;

    // If the pasted values are in DMS, KML, or lat/lon format, parse them accordingly
    if (DMS_REGEX.exec(filteredText)?.length) {
      coordinates = parseDMSCoordinates(filteredText);
    } else if (KML_LATLON_REGEX.exec(filteredText)?.length) {
      coordinates = parseKMLLatLonCoordinates(filteredText);
    }

    // If coordinates have successfully been parsed from the pasted data, set the form group's value to the coordinates.
    // Else, if the pasted data is a single floating number, set the value of the control targeted by the paste event to
    // the pasted value.
    if (coordinates?.length) {
      const latitude = this.latFirst ? coordinates[0].latitude : coordinates[0].longitude;
      const longitude = this.latFirst ? coordinates[0].longitude : coordinates[0].latitude;
      this.formGroup.patchValue({
        latitude,
        longitude,
      });
    } else {
      const value = parseFloat(text);
      if (!isNaN(value) && value !== null && value !== undefined) {
        control.setValue(value);
      }
    }
    this.formGroup.markAsTouched();
  }
}


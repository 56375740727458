import {Component, computed, EventEmitter, input, Input, Output} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() percent: number;
  @Input() label: string;
  @Input() displayDetails: boolean;
  @Input() details: string;
  @Input() displayButton: boolean;
  @Input() buttonText: string;
  @Output() actionBtnClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  success = input<boolean>();

  color = computed(() => {
    const success = this.success();
    switch (success) {
      case true:
        return 'success';
      case false:
        return 'danger';
      default:
        return '';
    }
  });

  emitActionBtnClick() {
    this.actionBtnClick.emit(true);
  }
}

import {Component, effect, inject, input} from '@angular/core';
import {UasRegistrationInfo} from '../../../model/UasRegistrationInfo';
import {FieldLabels, fieldUnits, imperialLabels, metricLabels} from '../platformUnits';
import {MeasurementSystemType} from '../../../model/MeasurementSystem';
import {Converter} from '../../../utils/convert-units';
import {UserSettingsService} from '../../../services/user-settings.service';
import _ from 'lodash';
import {toSignal} from '@angular/core/rxjs-interop';
import {PlatformService} from '../../../services/platform.service';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-view-platform',
  templateUrl: './view-platform.component.html',
  styleUrls: ['./view-platform.component.scss']
})
export class ViewPlatformComponent {
  platform$ = input.required<UasRegistrationInfo>();

  internalPlatform: UasRegistrationInfo;
  unitLabels: FieldLabels = metricLabels;

  // Retrieve the telemetry integrations supported by the USS
  supportedIntegrations$ = toSignal(inject(PlatformService).getSupportedIntegrations()
    .pipe(map(res => res.supportedIntegrationsObject)));

  private measurementSystem$ = toSignal(inject(UserSettingsService).getMeasurementSystem());

  constructor() {
    // Handle preferred measurement system change
    effect(() => {
      this.handleMeasurementSystemChange(this.measurementSystem$());
    });

    // Handle platform ID change
    effect(() => {
      if (this.platform$()) {
        this.internalPlatform = new UasRegistrationInfo(this.platform$());
        this.convertFields();
      } else {
        this.internalPlatform = null;
      }
    });
  }

  wrapWithUnits(value: string | number, suffix: string = '', ifMissing: string = 'Not Available'): string {
    if (!_.isNil(value)) {
      return value + ' ' + suffix;
    } else {
      return ifMissing;
    }
  }

  prettifyAirframe(airframe: string) {
    switch (airframe) {
      case 'rotary':
        return 'Rotary';
      case 'fixed':
        return 'Fixed Wing';
      case 'vtol':
        return 'VTOL';
      default:
        return airframe || 'Not Available';
    }
  }

  private convertFields() {
    const convertUnits = (field: keyof typeof fieldUnits, value: number) => {
      if (MeasurementSystemType.METRIC === this.measurementSystem$() || _.isNil(value)) {
        return value;
      }
      const fieldUnit = fieldUnits[field];
      return new Converter(value).from(fieldUnit[MeasurementSystemType.METRIC]).to(fieldUnit[this.measurementSystem$()]);
    };

    this.internalPlatform.aircraftSpecs.dryWeight = !_.isNil(this.internalPlatform.aircraftSpecs.dryWeight) ?
      _.round(convertUnits('weight', this.internalPlatform.aircraftSpecs.dryWeight), 2) : null;
    this.internalPlatform.aircraftSpecs.maxRoundTripRange = !_.isNil(this.internalPlatform.aircraftSpecs.maxRoundTripRange) ?
      _.round(convertUnits('maxRoundTripRange', this.internalPlatform.aircraftSpecs.maxRoundTripRange), 2) : null;
    this.internalPlatform.aircraftSpecs.maxAirspeed = !_.isNil(this.internalPlatform.aircraftSpecs.maxAirspeed) ?
      _.round(convertUnits('maxAirspeed', this.internalPlatform.aircraftSpecs.maxAirspeed), 2) : null;
    this.internalPlatform.aircraftSpecs.maxVerticalSpeed = !_.isNil(this.internalPlatform.aircraftSpecs.maxVerticalSpeed) ?
      _.round(convertUnits('maxVerticalSpeed', this.internalPlatform.aircraftSpecs.maxVerticalSpeed), 2) : null ;
    this.internalPlatform.aircraftSpecs.maxVerticalAcceleration = !_.isNil(this.internalPlatform.aircraftSpecs.maxVerticalAcceleration) ?
      _.round(convertUnits('maxVerticalAcceleration', this.internalPlatform.aircraftSpecs.maxVerticalAcceleration), 2) : null;
    this.internalPlatform.aircraftSpecs.length = !_.isNil(this.internalPlatform.aircraftSpecs.length) ?
      _.round(convertUnits('lengthWidthHeight', this.internalPlatform.aircraftSpecs.length), 2) : null;
    this.internalPlatform.aircraftSpecs.width = !_.isNil(this.internalPlatform.aircraftSpecs.width) ?
      _.round(convertUnits('lengthWidthHeight', this.internalPlatform.aircraftSpecs.width), 2) : null;
    this.internalPlatform.aircraftSpecs.wingspan = !_.isNil(this.internalPlatform.aircraftSpecs.wingspan) ?
      _.round(convertUnits('lengthWidthHeight', this.internalPlatform.aircraftSpecs.wingspan), 2) : null;
    this.internalPlatform.aircraftSpecs.height = !_.isNil(this.internalPlatform.aircraftSpecs.height) ?
      _.round(convertUnits('lengthWidthHeight', this.internalPlatform.aircraftSpecs.height), 2) : null;
    this.internalPlatform.aircraftSpecs.propLength = !_.isNil(this.internalPlatform.aircraftSpecs.propLength) ?
      _.round(convertUnits('lengthWidthHeight', this.internalPlatform.aircraftSpecs.propLength), 2) : null;
    this.internalPlatform.aircraftSpecs.batteryWeight = !_.isNil(this.internalPlatform.aircraftSpecs.batteryWeight) ?
      _.round(convertUnits('weight', this.internalPlatform.aircraftSpecs.batteryWeight), 2) : null;
    this.internalPlatform.aircraftSpecs.maxTakeOffWeight = !_.isNil(this.internalPlatform.aircraftSpecs.maxTakeOffWeight) ?
      _.round(convertUnits('weight', this.internalPlatform.aircraftSpecs.maxTakeOffWeight), 2) : null;
    this.internalPlatform.aircraftSpecs.minOperatingTemp = !_.isNil(this.internalPlatform.aircraftSpecs.minOperatingTemp) ?
      _.round(convertUnits('operatingTemperature', this.internalPlatform.aircraftSpecs.minOperatingTemp), 2) : null;
    this.internalPlatform.aircraftSpecs.maxOperatingTemp = !_.isNil(this.internalPlatform.aircraftSpecs.maxOperatingTemp) ?
      _.round(convertUnits('operatingTemperature', this.internalPlatform.aircraftSpecs.maxOperatingTemp), 2) : null;
    this.internalPlatform.aircraftSpecs.fuelCapacity = !_.isNil(this.internalPlatform.aircraftSpecs.fuelCapacity) ?
      _.round(convertUnits('fuelCapacity', this.internalPlatform.aircraftSpecs.fuelCapacity), 2) : null;
  }

  private handleMeasurementSystemChange(measurementSystem: MeasurementSystemType) {
    switch (this.measurementSystem$()) {
      case MeasurementSystemType.METRIC:
        this.unitLabels = metricLabels;
        break;
      case MeasurementSystemType.IMPERIAL:
        this.unitLabels = imperialLabels;
        break;
    }

    if (this.internalPlatform) {
      this.convertFields();
    }
  }
}

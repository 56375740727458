import {Component, OnInit} from '@angular/core';
import {Layer, LeafletEvent} from 'leaflet';
import {ColorConfig, ColorService} from '../../shared/services/color.service';
import {blueIcon, greenIcon} from '../../shared/utils/leaflet-color-markers';
import * as L from 'leaflet';
import {delay} from 'rxjs/operators';

@Component({
  selector: 'app-testy',
  templateUrl: './testy.component.html',
  styleUrls: ['./testy.component.scss'],
  providers: []
})
export class TestyComponent implements OnInit {
  layers: Layer[] = [];
  operationPathOptions = {color: 'blue', fillColor: 'blue'};
  operationGlobalOptions = {
    hintlineStyle: {color: 'blue'},
    templineStyle: {color: 'blue'}
  };
  controllerLocationPathOptions = {icon: blueIcon, markerStyle: {icon: blueIcon}, limitMarkersToCount: 1};
  takeOffLocationPathOptions = {icon: greenIcon, markerStyle: {icon: greenIcon}, limitMarkersToCount: 1};

  private colorConfigSub: any;
  private operationColorConfig: ColorConfig;

  constructor(private colorService: ColorService) {
  }

  ngOnInit(): void {
    const latlngs = [[37, -109.05], [41, -109.03], [41, -102.05], [37, -102.04]];

    const polygon = L.polygon(latlngs as any, {color: 'red'});
    this.layers = [polygon];

    this.colorConfigSub = this.colorService.getColorForId('operation', true).pipe(delay(20000)).subscribe(colorConfig => {
      this.operationColorConfig = colorConfig;
      this.operationPathOptions = {
        color: colorConfig.fill.toHexString(),
        fillColor: colorConfig.fill.toHexString()
      };

      this.operationGlobalOptions = {
        hintlineStyle: {color: colorConfig.fill.toHexString()},
        templineStyle: {color: colorConfig.fill.toHexString()}
      };

      const polygon2 = L.polygon([
        [51.509, -0.08],
        [51.503, -0.06],
        [51.51, -0.047]
      ]);

      this.layers = [polygon, polygon2];

    });


  }

  handleTest1Event($event: LeafletEvent) {
    console.log($event);
  }
}

import {Injectable} from '@angular/core';
import {IOperationSearchParameters, OperationService} from '../operation.service';
import {EMPTY, interval, Observable, timer} from 'rxjs';
import {SearchResult} from '../../model/SearchResult';
import {OperationExt, SwaggerOperationExt} from '../../model/utm/OperationExt';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Position, state} from '../../model/gen/utm';
import {catchError, exhaustMap, map, mergeMap} from 'rxjs/operators';
import {Parser} from '../../model/utm/parser/OperationParser';
import {IOperationSubmission, TransportOperationSubmission} from '../../model/OperationSubmission';
import {RxStomp} from '@stomp/rx-stomp';
import {StompService} from '../stomp.service';
import {compactObject} from '../../utils/compact';
import {IOperationModification, TransportOperationModification} from '../../model/OperationModification';
import {UTMSerializer} from '../../model/UTMSerializer';
import {OperationReplanInfo} from '../../model/OperationReplanInfo';

export interface OperationResponse {
  intentId: string;
  operationId?: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class RestOperationService extends OperationService {
  baseOperationsUrl = `${environment.baseUrl}/operator/v2/operations/search`;
  baseOperationUrl = `${environment.baseUrl}/operator/v2/operations`;

  constructor(private stompService: StompService, private http: HttpClient) {
    super();
  }

  createOperation(operation: IOperationSubmission): Observable<OperationResponse> {
    const transport: TransportOperationSubmission = UTMSerializer.serializeOperationSubmission(operation);
    return this.http.post(this.baseOperationUrl, transport).pipe(map(Parser.parseOperationResponse));
  }

  submitOperation(data: any): Observable<boolean> {
    const formData = new FormData();
    Object.keys(data).forEach(k => {
      formData.append(k, data[k]);
    });
    return this.http.post(this.baseOperationUrl, formData).pipe(map(res => false));
  }

  exists(operationId: string): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  getOperations(operationSearchParameters: IOperationSearchParameters): Observable<SearchResult<OperationExt>> {
    if (operationSearchParameters.sortIncreasing === undefined) {
      console.error('sorting shouldn\'t be undefined');
    }

    const _body = UTMSerializer.serializeOperationSearchParameters(operationSearchParameters);
    const body = compactObject(JSON.parse(JSON.stringify(_body)));

    return this.http.post(this.baseOperationsUrl, body)
      .pipe(map((response: any) => new SearchResult((response.operations || [])
        .map(Parser.parseOperationExt), response.count, response.offset)));
  }

  getOperation(operationId: string): Observable<OperationExt> {
    return this.http.get(`${this.baseOperationUrl}/${operationId}`).pipe(map((response: SwaggerOperationExt) => Parser.parseOperationExt(response)));
  }

  getOperationState(operationId: string, refreshInterval?: number): Observable<state> {
    if (refreshInterval) {
      return interval(refreshInterval).pipe(exhaustMap(() => this.getOperation(operationId))).pipe(map(op => op.state));
    } else {
      return this.getOperation(operationId).pipe(map(op => op.state));
    }
  }

  getOperationReplans(operationId: string): Observable<OperationReplanInfo> {
    return this.http.get(`${this.baseOperationUrl}/${operationId}/replans`)
      .pipe(map(Parser.parseOperationReplanInfo));
  }

  modifyOperation(operationId: string, data: IOperationModification): Observable<OperationResponse> {
    const transport: TransportOperationModification = UTMSerializer.serializeOperationModification(data);
    return this.http.put(`${this.baseOperationUrl}/${operationId}`, transport).pipe(map(Parser.parseOperationResponse));
  }

  activateOperation(operationId: string): Observable<boolean> {
    return this.http.post(`${environment.baseUrl}/operator/v2/operations/${operationId}/activate`, {}).pipe(map(() => true));
  }

  watchOperation(operationId: string): Observable<OperationExt> {
    return timer(100, 5000).pipe(exhaustMap(() => this.getOperation(operationId).pipe(catchError(() => (EMPTY)))));
    // STOMP listener
    // return this.stompService.getStompClient()
    //   .pipe(switchMap((stomp: RxStomp) => stomp
    //     .watch(`/exchange/utm_topic/operation.#.${operationId}`).pipe(map((message: IMessage) =>
    //     // return JSON.parse(message as any) as Position;
    //     // return JSON.parse(message as any) as Position;
    //      Parser.parseOperationExt(JSON.parse(message.body))
    //   ))));
  }

  watchPositions(operationId: string): Observable<Position> {
    return this.stompService.getStompClient().pipe(mergeMap((rxStomp: RxStomp | null) => {
      if (rxStomp === null) {
        return EMPTY;
      }
      return rxStomp.watch(`/exchange/utm_topic/position.${operationId}.#`).pipe(map((message) =>
        // return JSON.parse(message as any) as Position;
        null as Position
      ));
    }));
  }

  closeOperation(operationId: string): Observable<boolean> {
    return this.http.post(`${environment.baseUrl}/operator/v2/operations/${operationId}/cancel`, {}).pipe(map(() => true));
  }
}

import {Injectable, InjectionToken, Type} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigComponent} from './config.component';


export const enum ImportExportMode {
  IMPORT = 'IMPORT',
  EXPORT = 'EXPORT',
}

export enum ImportExportType {
  OPERATIONVOLUME = 'OPERATIONVOLUME',
  CONSTRAINT = 'CONSTRAINT',
  UNIVERSAL = 'UNIVERSAL'
}

export interface ImportExportResultListener {
  handleResult(result: any): void;
}

export const ImportExportServiceToken = new InjectionToken<ImportExportService>('ImportExportService');

export interface PreviewComponent {
  setPreviewObject(preview: any);
}

@Injectable({
  providedIn: 'root'
})
export abstract class ImportExportService {

  protected constructor() {
    // Method not implemented
  }

  abstract getMode(): ImportExportMode;

  abstract getType(): ImportExportType;

  abstract getFormatName(): string;


  abstract getDefaultConfig(): any;

  abstract getConfigComponent(): Type<ConfigComponent>;

  getPreviewComponent(): Type<PreviewComponent> {
    return null;
  }

  getPriority(): number {
    return 100;
  }
}

export const enum FileReadMode {
  STRING = 'STRING',
  ARRAYBUFFER = 'ARRAYBUFFER',
}


@Injectable({
  providedIn: 'root'
})
export abstract class ImportService<T> extends ImportExportService {


  protected constructor() {
    super();
  }

  getMode(): ImportExportMode {
    return ImportExportMode.IMPORT;
  }

  abstract getFileExtensions(): string[];


  abstract doImport(config: any): Observable<T>;

  protected readFile(f: File, mode: FileReadMode): Observable<string | ArrayBuffer> {
    return new Observable(subscriber => {
      const reader = new FileReader();

      reader.onload = () => {
        subscriber.next(reader.result);
        subscriber.complete();

      };
      switch (mode) {
        case FileReadMode.STRING:
          reader.readAsText(f);
          break;
        case FileReadMode.ARRAYBUFFER:
          reader.readAsArrayBuffer(f);
          break;
        default:
          subscriber.complete();
      }

    });
  }

  protected readFileJson(f: File): Observable<any> {
    return this.readFile(f, FileReadMode.STRING).pipe(map((text: string) => JSON.parse(text)));
  }
}

@Injectable({
  providedIn: 'root'
})
export abstract class ExportService<T> extends ImportExportService {

  protected constructor() {
    super();
  }

  getMode(): ImportExportMode {
    return ImportExportMode.EXPORT;
  }

  abstract doExport(data: any, config:any): Observable<T>;
}

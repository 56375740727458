import {Component, effect, forwardRef, inject} from '@angular/core';
import {ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {TelemetryIntegrations} from '../../../model/TelemetryIntegrations/TelemetryIntegrations';
import {IMavlinkTelemetryIntegration, Protocol} from '../../../model/TelemetryIntegrations/MavlinkTelemetryIntegration';
import {integer} from '../../../model/DataTypes';
import {ICotTelemetryIntegration} from '../../../model/TelemetryIntegrations/CotTelemetryIntegration';
import {IRigitechTelemetryIntegration} from '../../../model/TelemetryIntegrations/RigitechTelemetryIntegration';
import {PlatformService} from '../../../services/platform.service';
import {takeUntilDestroyed, toSignal} from '@angular/core/rxjs-interop';
import {debounceTime, map} from 'rxjs/operators';
import {TelemetryIntegrationType} from '../../../model/TelemetryIntegrations/SupportedTelemetryIntegrationsResponse';
import {SelectOption} from '../../../select-option';
import {
  forbiddenPatternRegexes,
  invalidCharactersValidator,
  isInteger,
  minOneRequiredFieldValidator
} from '../../../utils/Validators';
import {isNil} from 'lodash';

type cotFg = FormGroup<{cotCallsign: FormControl<string>; cotUid: FormControl<string>; cotAltitudeUsesMsl: FormControl<boolean>}>;
type rigitechFg = FormGroup<{rigitechDroneId: FormControl<integer>; rigitechSerialNumber: FormControl<string>}>;
type mavlinkFg = FormGroup<{protocol: FormControl<Protocol>; port: FormControl<integer>}>;

@Component({
  selector: 'app-edit-telemetry-integrations',
  templateUrl: './edit-telemetry-integrations.component.html',
  styleUrl: './edit-telemetry-integrations.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditTelemetryIntegrationsComponent),
    }
  ]
})
export class EditTelemetryIntegrationsComponent implements ControlValueAccessor {
  fg = new FormGroup({
    cot: new FormArray<cotFg>([]),
    rigitech: new FormArray<rigitechFg>([]),
    mavlink: new FormArray<mavlinkFg>([])
  });

  // Form Groups for creating and editing integrations
  cotEditorFg = new FormGroup({
    cotCallsign: new FormControl<string>(null, [Validators.maxLength(64),
      invalidCharactersValidator(forbiddenPatternRegexes.telemetryIntegrations)]),
    cotUid: new FormControl<string>(null, [Validators.maxLength(64),
      invalidCharactersValidator(forbiddenPatternRegexes.telemetryIntegrations)]),
    cotAltitudeUsesMsl: new FormControl<boolean>(false, {validators: [Validators.required], nonNullable: true})
  }, [minOneRequiredFieldValidator(['cotCallsign', 'cotUid'])]);
  rigitechEditorFg = new FormGroup({
    rigitechDroneId: new FormControl<integer>(null, [isInteger]),
    rigitechSerialNumber: new FormControl<string>(null, [
      Validators.maxLength(64), invalidCharactersValidator(forbiddenPatternRegexes.telemetryIntegrations)]),
  }, [minOneRequiredFieldValidator(['rigitechDroneId', 'rigitechSerialNumber'])]);
  mavlinkEditorFg = new FormGroup({
    protocol: new FormControl<Protocol>(null, [Validators.required]),
    port: new FormControl<integer>(null, [Validators.required, isInteger, Validators.min(1)])
  });

  // Populate the Mavlink protocol select field options
  availableProtocols: SelectOption[] = (() => {
    const ret = [];
    for (const option of Object.keys(Protocol)) {
      if (option !== Protocol.INVALID) {
        ret.push({label: option, value: option});
      }
    }
    return ret;
  })();

  // Tracks the open status of the modals for creating and editing integrations
  modalOpenStatus: {[key: string]: boolean} = {
    cot: false,
    rigitech: false,
    mavlink: false
  };
  noSupportedIntegrations = false;

  // Retrieve the telemetry integrations supported by the USS
  supportedIntegrations$ = toSignal(inject(PlatformService).getSupportedIntegrations()
    .pipe(map(res => (res.supportedIntegrationsObject))));

  telemetryType = TelemetryIntegrationType;
  currentIntegrationIndex: number = null;
  private onChange: (value: TelemetryIntegrations) => void;

  constructor() {
    // Emit changes whenever the form group values change
    this.fg.valueChanges.pipe(takeUntilDestroyed(), debounceTime(300))
      .subscribe(() => {
        this.onChange?.(this.fg.valid ? new TelemetryIntegrations(this.fg.getRawValue()) : null);
      });

    // Check if there are no supported telemetry integrations for the USS
    effect(() => {
      if (this.supportedIntegrations$()) {
        this.noSupportedIntegrations = !Object.values(this.supportedIntegrations$()).some(value => value);
      }
    });
  }

  registerOnChange(fn: (value: TelemetryIntegrations) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  writeValue(obj: TelemetryIntegrations | null): void {
    this.fg.reset();
    this.fg.controls.cot.clear();
    this.fg.controls.rigitech.clear();
    this.fg.controls.mavlink.clear();
    if (obj) {
      this.setValues(obj);
    }
  }

  /**
   * Set the values of the form group fields.
   * @param values The values to set the form group fields to.
   */
  setValues(values: TelemetryIntegrations) {
    values.cot.forEach(cot => this.addCotFg(cot));
    values.rigitech.forEach(rigitech => this.addRigitechFg(rigitech));
    values.mavlink.forEach(mavlink => this.addMavlinkFg(mavlink));
  }

  /**
   * Add a new COT telemetry integration form group to the form array or edit an existing form group in the array.
   * @param values The values to set the form group fields to.
   */
  addCotFg(values: ICotTelemetryIntegration) {
    const fg = new FormGroup({
      cotCallsign: new FormControl<string>(values?.cotCallsign || null, [Validators.maxLength(64),
        invalidCharactersValidator(forbiddenPatternRegexes.telemetryIntegrations)]),
      cotUid: new FormControl<string>(values?.cotUid || null, [Validators.maxLength(64),
        invalidCharactersValidator(forbiddenPatternRegexes.telemetryIntegrations)]),
      cotAltitudeUsesMsl: new FormControl<boolean>(values?.cotAltitudeUsesMsl || false, [Validators.required])
    }, [minOneRequiredFieldValidator(['cotCallsign', 'cotUid'])]);
    if (!isNil(this.currentIntegrationIndex)) {
      this.fg.controls.cot.controls.at(this.currentIntegrationIndex)?.setValue(values);
      this.currentIntegrationIndex = null;
    } else {
      this.fg.controls.cot.push(fg);
    }
    if (!!this.modalOpenStatus.cot) {
      this.modalOpenStatus.cot = false;
    }
  }

  /**
   * Add a new Rigitech telemetry integration form group to the form array or edit an existing form group in the array.
   * @param values The values to set the form group fields to.
   */
  addRigitechFg(values: IRigitechTelemetryIntegration) {
    const fg = new FormGroup({
      rigitechDroneId: new FormControl<integer>(values?.rigitechDroneId || null, [isInteger]),
      rigitechSerialNumber: new FormControl<string>(values?.rigitechSerialNumber || null, [
        Validators.maxLength(64), invalidCharactersValidator(forbiddenPatternRegexes.telemetryIntegrations)]),
    }, [minOneRequiredFieldValidator(['rigitechDroneId', 'rigitechSerialNumber'])]);
    if (!isNil(this.currentIntegrationIndex)) {
      this.fg.controls.rigitech.controls.at(this.currentIntegrationIndex)?.setValue(values);
      this.currentIntegrationIndex = null;
    } else {
      this.fg.controls.rigitech.push(fg);
    }
    if (!!this.modalOpenStatus.rigitech) {
      this.modalOpenStatus.rigitech = false;
    }
  }

  /**
   * Add a new Mavlink telemetry integration form group to the form array or edit an existing form group in the array.
   * @param values The optional values to set the form group to.
   */
  addMavlinkFg(values?: IMavlinkTelemetryIntegration) {
    const fg = new FormGroup({
      protocol: new FormControl<Protocol>(values?.protocol || null, [Validators.required]),
      port: new FormControl<integer>(values?.port || null, [Validators.required, isInteger, Validators.min(1)])
    });
    if (!isNil(this.currentIntegrationIndex)) {
      this.fg.controls.mavlink.controls.at(this.currentIntegrationIndex)?.setValue(values);
      this.currentIntegrationIndex = null;
    } else {
      this.fg.controls.mavlink.push(fg);
    }
    if (!!this.modalOpenStatus.mavlink) {
      this.modalOpenStatus.mavlink = false;
    }
  }

  /**
   * Triggers the integration create/edit form modal to be displayed
   * @param type The type of integration to render the form for
   * @param index An optional index that indicates an integration from the form array to be edited
   */
  openFormModal(type: TelemetryIntegrationType, index?: number) {
    switch (type) {
      case TelemetryIntegrationType.COT:
        this.cotEditorFg.reset();
        if (!isNil(index)) {
          this.currentIntegrationIndex = index;
          const value = this.fg.controls.cot.at(index)?.getRawValue();
          this.cotEditorFg.setValue(value);
        }
        this.modalOpenStatus.cot = true;
        break;
      case TelemetryIntegrationType.RIGITECH:
        this.rigitechEditorFg.reset();
        if (!isNil(index)) {
          this.currentIntegrationIndex = index;
          const value = this.fg.controls.rigitech.at(index)?.getRawValue();
          this.rigitechEditorFg.setValue(value);
        }
        this.modalOpenStatus.rigitech = true;
        break;
      case TelemetryIntegrationType.MAVLINK:
        this.mavlinkEditorFg.reset();
        if (!isNil(index)) {
          this.currentIntegrationIndex = index;
          const value = this.fg.controls.mavlink.at(index)?.getRawValue();
          this.mavlinkEditorFg.setValue(value);
        }
        this.modalOpenStatus.mavlink = true;
        break;
      default:
        console.error(`Cannot open modal for unknown telemetry integration type: ${type}`);
        break;
    }
  }

  /**
   * Removes a form group from the associated form array at the provided index
   * @param type The type of integration to be removed (indicates which form array to remove it from)
   * @param index The index of the form group in the form array to remove
   */
  removeIntegration(type: TelemetryIntegrationType, index: number) {
    switch (type) {
      case TelemetryIntegrationType.COT:
        this.fg.controls.cot.removeAt(index);
        break;
      case TelemetryIntegrationType.RIGITECH:
        this.fg.controls.rigitech.removeAt(index);
        break;
      case TelemetryIntegrationType.MAVLINK:
        this.fg.controls.mavlink.removeAt(index);
        break;
      default:
        console.error(`Cannot remove form group for unknown telemetry integration type: ${type}`);
        break;
    }
  }

  /**
   * Resets the current integration index being edited whenever the modal is closed
   * @param $event Indicates whether the modal has been opened (true) or closed (false)
   */
  handleModalOpenChange($event) {
    if ($event === false) {
      this.currentIntegrationIndex = null;
    }
  }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {OperationService} from '../../../shared/services/operation.service';
import {OperationExt} from '../../../shared/model/utm/OperationExt';
import {state} from '../../../shared/model/gen/utm';
import {of} from 'rxjs/internal/observable/of';
import {catchError, distinctUntilChanged, switchMap, takeWhile} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-operation',
  templateUrl: './operation.component.html'
})
export class OperationComponent implements OnInit, OnDestroy {
  operation: OperationExt;
  notFound: boolean;

  private queryParamsSub: Subscription;

  constructor(private operationService: OperationService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.queryParamsSub = this.route.queryParams
      .pipe(switchMap((params: Params) => {
        return this.operationService.getOperation(params.operationId)
          .pipe(catchError(() => {
            this.notFound = true;
            return of(null);
          }))
          .pipe(switchMap((op) => {
            // Watch operation
            if (op && (op.state !== state.CLOSED && op.state !== state.REJECTED)) {
              return this.operationService.watchOperation(op.operationId)
                .pipe(takeWhile(operation => operation.state !== state.CLOSED && operation.state !== state.REJECTED, true));
            } else {
              return of(op);
            }}));
      }))
      .pipe(distinctUntilChanged((a: OperationExt, b: OperationExt) => a?.operationId === b?.operationId && a?.version === b?.version))
      .subscribe((operation: OperationExt) => {
        if (!operation) {
          this.notFound = true;
          return;
        }

        this.operation = operation;
        this.notFound = false;
      });
  }

  ngOnDestroy(): void {
    this.queryParamsSub?.unsubscribe();
  }

}

import {Injectable} from '@angular/core';
import {DateTime} from "luxon";
import {Observable} from "rxjs";
import {ITelemetryStatus} from "../model/telemetry-status";

@Injectable({
  providedIn: 'root'
})
export abstract class TelemetryStatusService {

  abstract getTelemetryStatuses(registrationIds?: string[], updatedAfter?: DateTime): Observable<ITelemetryStatus[]>;

  abstract getTelemetryStatus(registrationId: string): Observable<ITelemetryStatus>;
}

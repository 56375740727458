export enum MeasurementSystemType {
  METRIC = 'METRIC',
  IMPERIAL = 'IMPERIAL'
}

export enum UnitType {
  VELOCITY = 'VELOCITY'
}

export namespace MeasurementSystemType {
  export function from(v: string | null): MeasurementSystemType {
    if (v === null) {
      return null;
    }

    switch (v.toUpperCase()) {
      case 'METRIC':
        return MeasurementSystemType.METRIC;
      case 'IMPERIAL':
        return MeasurementSystemType.IMPERIAL;
    }
  }
}


export namespace MeasurementSystemUtil {
  export const conversions = 4;
}

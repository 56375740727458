import {enumValidator, permitted_uas} from '../gen/utm';
import {BaseModel} from '../gen/utm/base-model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {IUASVolumeReservation} from '../gen/transport/utm';
import {OrganizationUtil} from '../../utils/OrganizationUtil';

export interface SwaggerAdditionalOperationData {
  airspace: string;
  category: string;
  division: string;
  operation_type: IUASVolumeReservation.PermittedUasEnum;
  organization: string;
  public_access: boolean;
  security_tags: Array<string>;
  user: string;
  user_id: string;
  permitted_constraint_types: Array<string>;
}

export interface IAdditionalOperationData {
  airspace: string;
  category: string;
  division: string;
  operation_type: permitted_uas;
  organization: string;
  public_access: boolean;
  security_tags: Array<string>;
  user: string;
  user_id: string;
  permitted_constraint_types: Array<string>;
}

export class AdditionalOperationData extends BaseModel implements IAdditionalOperationData {

  /* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
  /* eslint-disable max-len */
  static AIRSPACE_FIELD_NAME = 'airspace';
  static CATEGORY_FIELD_NAME = 'category';
  static DIVISION_FIELD_NAME = 'division';
  static OPERATIONTYPE_FIELD_NAME = 'operation_type';
  static ORGANIZATION_FIELD_NAME = 'organization';
  static PUBLICACCESS_FIELD_NAME = 'public_access';
  static SECURITYTAGS_FIELD_NAME = 'security_tags';
  static USER_FIELD_NAME = 'user';
  static USERID_FIELD_NAME = 'user_id';
  static PERMITTEDCONSTRAINTTYPES_FIELD_NAME = 'permitted_constraint_types';

  airspace: string;
  category: string;
  division: string;
  operation_type: permitted_uas;
  organization: string;
  public_access: boolean;
  security_tags: Array<string>;
  user: string;
  user_id: string;
  permitted_constraint_types: Array<string>;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IAdditionalOperationData>, useFormGroupValuesToModel = false) {
    super();
    this.security_tags = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }


  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IAdditionalOperationData>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.airspace = this.getValue<string>(rawValues, AdditionalOperationData.AIRSPACE_FIELD_NAME);
      this.category = this.getValue<string>(rawValues, AdditionalOperationData.CATEGORY_FIELD_NAME);
      this.division = this.getValue<string>(rawValues, AdditionalOperationData.DIVISION_FIELD_NAME);
      this.operation_type = this.getValue<permitted_uas>(rawValues, AdditionalOperationData.OPERATIONTYPE_FIELD_NAME);
      this.organization = this.getValue<string>(rawValues, AdditionalOperationData.ORGANIZATION_FIELD_NAME);
      this.public_access = this.getValue<boolean>(rawValues, AdditionalOperationData.PUBLICACCESS_FIELD_NAME);
      this.security_tags = this.getValue<string[]>(rawValues, AdditionalOperationData.SECURITYTAGS_FIELD_NAME);
      this.user = this.getValue<string>(rawValues, AdditionalOperationData.USER_FIELD_NAME);
      this.user_id = this.getValue<string>(rawValues, AdditionalOperationData.USERID_FIELD_NAME);
      this.permitted_constraint_types = this.getValue<string[]>(rawValues, AdditionalOperationData.PERMITTEDCONSTRAINTTYPES_FIELD_NAME);

      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[AdditionalOperationData.AIRSPACE_FIELD_NAME].setValue(this.airspace);
    this.$formGroup.controls[AdditionalOperationData.CATEGORY_FIELD_NAME].setValue(this.category);
    this.$formGroup.controls[AdditionalOperationData.DIVISION_FIELD_NAME].setValue(this.division);
    this.$formGroup.controls[AdditionalOperationData.OPERATIONTYPE_FIELD_NAME].setValue(this.operation_type);
    this.$formGroup.controls[AdditionalOperationData.ORGANIZATION_FIELD_NAME].setValue(this.organization);
    this.$formGroup.controls[AdditionalOperationData.PUBLICACCESS_FIELD_NAME].setValue(this.public_access);
    this.$formGroup.controls[AdditionalOperationData.SECURITYTAGS_FIELD_NAME].setValue(this.security_tags);
    this.$formGroup.controls[AdditionalOperationData.USER_FIELD_NAME].setValue(this.user);
    this.$formGroup.controls[AdditionalOperationData.USERID_FIELD_NAME].setValue(this.user_id);
    this.$formGroup.controls[AdditionalOperationData.PERMITTEDCONSTRAINTTYPES_FIELD_NAME].setValue(this.permitted_constraint_types);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  getPrettyOrganizationName(): string {
    return OrganizationUtil.getPrettyOrganizationName(this.organization);
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        airspace: new UntypedFormControl(this.airspace, [Validators.required]),
        category: new UntypedFormControl(this.category, [Validators.required]),
        division: new UntypedFormControl(this.division, [Validators.required]),
        operation_type: new UntypedFormControl(this.operation_type, [Validators.required, enumValidator(permitted_uas)]),
        organization: new UntypedFormControl(this.organization, [Validators.required]),
        public_access: new UntypedFormControl(this.public_access, [Validators.required]),
        security_tags: new UntypedFormControl(this.security_tags, [Validators.required]),
        user: new UntypedFormControl(this.user, [Validators.required]),
        user_id: new UntypedFormControl(this.user_id, [Validators.required]),
        permitted_constraint_types: new UntypedFormControl(this.permitted_constraint_types, [Validators.required]),
      });
      // generate FormArray control elements
      // this.fillFormArray<string>(AdditionalOperationData.SECURITYTAGS_FIELD_NAME, this.security_tags);
      // this.fillFormArray<string>(AdditionalOperationData.PERMITTEDCONSTRAINTTYPES_FIELD_NAME, this.permitted_constraint_types);
    }
    return this._formGroup;
  }

  getPrettyDivision() {
    return OrganizationUtil.getPrettyOrganizationName(this.division);
  }
}

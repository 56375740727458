/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';
import {SubTypeFactory} from './sub-type-factory';
import {IUasRegistration, UasRegistration} from './uas-registration.model';
import {IPersonOrOrganization, PersonOrOrganization} from './person-or-organization.model';
import {faa_rule, OperationActivationPolicy, PendingReason, state} from './enums';
import {IPoint, Point} from './point.model';
import {ContingencyPlan, IContingencyPlan} from './contingency-plan.model';
import {INegotiationAgreement, NegotiationAgreement} from './negotiation-agreement.model';
import {IPriorityElements, PriorityElements} from './priority-elements.model';
import {IOperationVolume, OperationVolume} from './operation-volume.model';
import {EventMetadata, IEventMetadata} from './event-metadata.model';
import {DateTime} from 'luxon';
import {IOperationApprovalInfo, OperationApprovalInfo} from './operation-approval-info';

export interface IOperation {
  operationId: string;
  operator_intent_id: string;
  uss_name: string;
  discovery_reference?: string;
  submit_time: DateTime;
  update_time: DateTime;
  effective_time_begin: DateTime;
  effective_time_end: DateTime;
  version: number;
  aircraft_comments?: string;
  flight_comments?: string;
  volumes_description?: string;
  uas_registrations: Array<IUasRegistration>;
  airspace_authorization?: string;
  flight_number?: string;
  contact: IPersonOrOrganization;
  state: state;
  controller_location?: IPoint;
  takeoff_location?: IPoint;
  gcs_location?: IPoint;
  contingency_plans: Array<IContingencyPlan>;
  negotiation_agreements?: Array<INegotiationAgreement>;
  faa_rule: faa_rule;
  priority_elements?: IPriorityElements;
  operation_volumes: Array<IOperationVolume>;
  metadata: IEventMetadata;
  contact_id: string;
  priority: number;
  managed: boolean;
  replan_required: boolean;
  pendingReason?: PendingReason;
  approval?: IOperationApprovalInfo;
  activation?: OperationActivationPolicy;
}


export class Operation extends BaseModel implements IOperation {

  static OPERATION_ID_FIELD_NAME = 'operationId';
  static OPERATOR_INTENT_ID_FIELD_NAME = 'operator_intent_id';
  static USS_NAME_FIELD_NAME = 'uss_name';
  static DISCOVERY_REFERENCE_FIELD_NAME = 'discovery_reference';
  static SUBMIT_TIME_FIELD_NAME = 'submit_time';
  static UPDATE_TIME_FIELD_NAME = 'update_time';
  static BEGIN_TIME_FIELD_NAME = 'effective_time_begin';
  static END_TIME_FIELD_NAME = 'effective_time_end';
  static VERSION_FIELD_NAME = 'version';
  static AIRCRAFT_COMMENTS_FIELD_NAME = 'aircraft_comments';
  static FLIGHT_COMMENTS_FIELD_NAME = 'flight_comments';
  static VOLUMES_DESCRIPTION_FIELD_NAME = 'volumes_description';
  static UAS_REGISTRATIONS_FIELD_NAME = 'uas_registrations';
  static AIRSPACE_AUTHORIZATION_FIELD_NAME = 'airspace_authorization';
  static FLIGHT_NUMBER_FIELD_NAME = 'flight_number';
  static CONTACT_FIELD_NAME = 'contact';
  static STATE_FIELD_NAME = 'state';
  static CONTROLLER_LOCATION_FIELD_NAME = 'controller_location';
  static TAKEOFF_LOCATION_FIELD_NAME = 'takeoff_location';
  static GCS_LOCATION_FIELD_NAME = 'gcs_location';
  static CONTINGENCY_PLANS_FIELD_NAME = 'contingency_plans';
  static NEGOTIATION_AGREEMENTS_FIELD_NAME = 'negotiation_agreements';
  static FAA_RULE_FIELD_NAME = 'faa_rule';
  static PRIORITY_ELEMENTS_FIELD_NAME = 'priority_elements';
  static OPERATION_VOLUMES_FIELD_NAME = 'operation_volumes';
  static METADATA_FIELD_NAME = 'metadata';
  static CONTACT_ID_FIELD_NAME = 'contact_id';
  static PRIORTIY_FIELD_NAME = 'priority';
  static MANAGED_FIELD_NAME = 'managed';
  static REPLAN_REQUIRED_FIELD_NAME = 'replan_required';
  static PENDING_REASON_FIELD_NAME = 'pendingReason';
  static APPROVAL_FIELD_NAME = 'approval';
  static ACTIVATION_FIELD_NAME = 'activation';

  operationId: string;

  /**
   * ID of the intent that triggered the current version of the operation.
   */
  operator_intent_id: string;

  /**
   * Identity of the USS.  The maximum and minimum character length is based on a usable domain name, and considering
   * the maximum in RFC-1035.
   */
  uss_name: string;

  /**
   * An identifier that supplies information about the USS from the discovery perspecitve.  This field is currently
   * vague due to research on the appropriate discovery approach.  It will be tightened when an operational system
   * decides on the approach to discovery.
   * For Gridded USS Discovery, this should be an x,y,z string where x and y are the coords of the grid and z is the
   * zoom level.  For example:  "110,117,10"
   * For FIMS Discovery, this should be the uss_instance_id which is a UUID.
   */
  discovery_reference: string;

  /**
   * Time that this Operation was first announced to the USS Network in any way.  For GUD, this means when you first
   * write to the grid.  For traditional discovery, this means the first time you announce to your LUN.
   * The submit_time value MUST remain constant for each recipient of the announcement since this value is potentially
   * part of a signature of the Operation plan in some cases.  So, when data (or references to the data) are written to
   * the grid, the same data are to be sent, as required, to other stakeholders (e.g. other USSs).  This means that if
   * there is a 500ms difference in the time that you send and get confirmation from the grid, you DO NOT update the
   * submit_time when sending to the USSs after that. When the operation is announced for the first time, update_time
   * MUST be equal to submit_time. When an operation is modified (updated), update_time MUST be greater than submit_time.
   * Similarly, for traditional discovery, if there is a delay in sending the data to one LUN member versus another,
   * this field is NOT updated in between as we want each stakeholder to ultimately receive the same data and
   * (potentially) the same signature of the data.
   */
  submit_time: DateTime;

  /**
   * A timestamp set by the USS any time the state of the operation is updated within the USS Network. An update may be
   * minor or major, but if/when te Operation is shared in the USS Network as a PUT to its LUN, update_time must reflect
   * the time that update was provided.
   * When the operation is announced for the first time, update_time MUST be equal to submit_time. When an operation is
   * modified (updated), update_time MUST be greater than submit_time. THe update_time value MUST be constant for each
   * update data exchange.
   * This means that all stakeholder systems have the same value for update_time even if, for example, one USS receives
   * the update later than others due to HTTP retrys or is provided as a GET for this Operation.  This field is set
   * maintained by the USS managing the Operation and is communicated to other USSs.
   */
  update_time: DateTime;

  /**
   * The earliest time that any of the operation volumes will begin.
   * The following MUST hold: effective_time_begin < effective_time_end MUST be true.
   */
  effective_time_begin: DateTime;

  /**
   * The latest time that any of the operation volumes will end.
   * The following MUST hold: effective_time_begin < effective_time_end
   */
  effective_time_end: DateTime;

  /**
   * The current version of the operation. This changes whenever the operation is updated in the DSS.
   */
  version: number;

  /** Informative text about the aircraft. Not used by the UTM System. Only for human stakeholders. */
  aircraft_comments: string;

  /** Informative text about the operation. Not used by the UTM System. Only for human stakeholders. */
  flight_comments: string;

  /** Informative text about the operational volumes. Not used by the UTM System. Only for human stakeholders. */
  volumes_description: string;

  /**
   * The registration data for the vehicle(s) to be used in this Operation. Note that this is an array to allow for
   * future operations involving multiple vehicles (e.g. 'swarms' or tandem inspections).
   * The uas_registrations array MUST NOT be used as a list of potential vehicles for this Operation.
   * If the vehicle data changes prior to an Operation, an update to the plan may be submitted with the updated vehicle
   * information. Providing multiple uas_registrations in this manner implies that all vehicles will conform to the
   * provided operation volumes.
   */
  uas_registrations: Array<UasRegistration>;

  airspace_authorization: string;

  /** Optional. For use by USS for identification purposes. */
  flight_number: string;

  /** Contact information for this Operation. */
  contact: PersonOrOrganization;

  /**
   * The current state of the operation.  Must be maintained by the USS. Some additional details in the USS Specification.
   *
   * 0. PROPOSED
   *  This operation is not yet ACCEPTED.  It may be awaiting information from the operator, it may be in conflict with
   *  another ACCEPTED or ACTIVATED operation and undergoing a negotiation process, or for some other reason it is not
   *  yet able to be declared ACCEPTED.
   *
   * 1. ACCEPTED
   *  This operation has been deemed ACCEPTED by the supporting USS. This implies that the operation meets the
   *  requirements for operating in the airspace based on the type of operation submitted.
   *
   * 2. ACTIVATED
   *  This operation is active. The transition from ACCEPTED to ACTIVATED is not an announced transition. The transition
   *  is implied based on the submitted start time of the operation (i.e. the effective_time_begin of the first
   *  OperationVolume). Note that an ACTIVATED operation is not necessarily airborne, but is assumed to be "using" the
   *  OperationVolumes that it has announced.
   *
   * 3. CLOSED
   *  This operation is closed. It is not airborne and will not become airborne again. If the UAS and the crew will fly
   *  again, it would need to be as a new operation. A USS may announce the closure of any operation, but is not
   *  required to announce unless the operation was ROGUE or NONCONFORMING.
   *
   * 4. NONCONFORMING
   *  See USS Specification for requirements to transition to this state.
   *
   * 5. ROGUE
   *  See USS Specification for requirements to transition to this state.
   *
   * If ordering of states is required for any purpose, they shall be ordered as follows:
   * PROPOSED < ACCEPTED < ACTIVATED < CLOSED < NONCONFORMING < ROGUE
   */
  state: state;

  controller_location?: Point;
  takeoff_location?: Point;
  gcs_location: Point;

  /**
   * An array of ContingencyPlans wherein this operation may land if needed/required during operation. Aids in planning
   * and communication during the execution of a contingency. An Operation MUST have least one Contingency Plan (CP) per
   * Operation Volume, therefore the CP list size must be greater than or equal to the OpVol list size.
   */
  contingency_plans: Array<ContingencyPlan>;

  /** An array of NegotiationAgreements to which the USS supporting this operation (in conjunction with the operator) has agreed. */
  negotiation_agreements: Array<NegotiationAgreement>;

  /**
   * 1. PART_107
   *  The operation follows FAA rule 107. Submission of such operations is mandatory
   *
   * 2. PART_107X
   *  In general, operations are 107X if they are doing something that would require a waiver under current 107 rules.
   *  Submission of such operations is mandatory.
   *
   * 3. PART_101E
   *  Submission of 101E would be required if operation is within 5 statute miles of an airport. Optional otherwise.
   *
   * OTHER
   *  Placeholder for other types of operations.
   */
  faa_rule: faa_rule;

  priority_elements: PriorityElements;

  /** The actual geographical information for the operation.
   * A note on "intersection":
   *  Note 2: All of the terms "crosses", "within", "touches" imply intersection. I can track down a source for this
   *  definition (probably OGC), but for now, you can see it illustrated in the description for PostGIS intersection:
   *  https://postgis.net/docs/ST_Intersects.html
   *
   * Validation of the array must include the following checks:
   *  1. The array of operation volumes MUST have minimum length of 1.
   *  2. The array of operation volumes MUST have maximum length of 250.
   *  3. Each operation volume MUST have non-zero 4D volume (i.e. each of the 4 dimensions much be > 0 in magnitude).
   *  4. Volume intersection must pass the following checks:
   *    a. When ordered by ordinal values, a succeeding operation volume must have a 2D or 3D intersection in 3D space
   *        with its immediately preceding operation volume. Note that a 2D intersection in 3D space implies two volumes
   *        that "touch" and the intersection has 2D area. Sharing just an edge would not qualify. 3D volumes that don't
   *        touch at all would not qualify, even if they would intersect when projected into 2D space (e.g. if "looking
   *        down" on the two volumes).
   *    b. When ordered by ordinal values, a succeeding operation volume must have a non-negative temporal intersection
   *        with its immediately preceding operation volume (Note we'd calculate this by t1-t2 where t1 is the preceding
   *        operation volume end time and t2 is the succeeding operation volume start time.).
   *    c. When ordered by ordinal values, a succeeding operation volume must have either a non-zero volume (3D)
   *        intersection OR a positive temporal intersection. (Note this is a logical "OR" so it may have both
   *        intersection types... i.e. it is not an "exclusive OR").
   *  5. Each spatial dimension of an op vol's bounding box must have length less than 6000ft (should discuss with
   *      partners this value). This is a sanity check against excessively large volumes. Need to be careful here as
   *      there may be legitimate use cases wherein large volumes are required/allowed, but we want to encourage
   *      efficient planning and protect against misuse of the shared airspace.
   *  6. The planned duration of an op vol must be less than 120 minutes. Again, need to be careful to not damage
   *      legitimate use cases, but need to protect against misuse/poor planning. For long duration missions, it may be
   *      reasonable to have them replan or to have volumes with the same geog and long time values that slightly
   *      intersect.
   *  7. The start time of an operation volume other than the first in the array must be greater than or equal to the
   *      start time of its immediately preceding operation volume. (note this probalby should be #4 or so, bumping the
   *      others down, but I didn't want to cause confusion in case implementers are referencing these checks somewhere
   *      by number.)
   */
  operation_volumes: Array<OperationVolume>;

  metadata: EventMetadata;
  contact_id: string;
  priority: number;

  /**
   * Indicates whether the operation is managed by an AX Enterprize USS.
   */
  managed: boolean;


  /**
   * Indicates whether the operation requires replanning. This typically only applies to accepted operations that have
   * failed activation.
   */
  replan_required: boolean;

  /**
   * Indicates the reason for the operation being in a pending state.
   */
  pendingReason?: PendingReason;

  /**
   * For operations that have participated in the approval process, this stores some details about the approval.
   */
  approval?: OperationApprovalInfo;

  /**
   * The operation's activation policy.
   *
   * 1. MANUAL
   *  The operation must be manually activated by the user.
   *
   * 2. ON_OPERATION_START
   *  The operation will automatically become active when it's start time is reached.
   *
   * 3. ON_TELEMETRY_START
   *  The operation will automatically become activate when telemetry from the associated aircraft is received.
   */
  activation?: OperationActivationPolicy;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IOperation>, useFormGroupValuesToModel = false) {
    super();
    this.uas_registrations = [];
    this.contact = new PersonOrOrganization();
    this.controller_location = new Point();
    this.takeoff_location = new Point();
    this.gcs_location = new Point();
    this.contingency_plans = [];
    this.negotiation_agreements = [];
    this.priority_elements = new PriorityElements();
    this.operation_volumes = [];
    this.metadata = new EventMetadata();
    this.contact_id = null;
    this.priority = 0;
    this.approval = null;

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IOperation>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.operationId = this.getValue<string>(rawValues, Operation.OPERATION_ID_FIELD_NAME);
      this.operator_intent_id = this.getValue<string>(rawValues, Operation.OPERATOR_INTENT_ID_FIELD_NAME);
      this.uss_name = this.getValue<string>(rawValues, Operation.USS_NAME_FIELD_NAME);
      this.discovery_reference = this.getValue<string>(rawValues, Operation.DISCOVERY_REFERENCE_FIELD_NAME);
      this.submit_time = this.getValue<DateTime>(rawValues, Operation.SUBMIT_TIME_FIELD_NAME);
      this.update_time = this.getValue<DateTime>(rawValues, Operation.UPDATE_TIME_FIELD_NAME);
      this.effective_time_begin = this.getValue<DateTime>(rawValues, Operation.BEGIN_TIME_FIELD_NAME);
      this.effective_time_end = this.getValue<DateTime>(rawValues, Operation.END_TIME_FIELD_NAME);
      this.version = this.getValue<number>(rawValues, Operation.VERSION_FIELD_NAME);
      this.aircraft_comments = this.getValue<string>(rawValues, Operation.AIRCRAFT_COMMENTS_FIELD_NAME);
      this.flight_comments = this.getValue<string>(rawValues, Operation.FLIGHT_COMMENTS_FIELD_NAME);
      this.volumes_description = this.getValue<string>(rawValues, Operation.VOLUMES_DESCRIPTION_FIELD_NAME);
      this.fillModelArray<IUasRegistration>(this, Operation.UAS_REGISTRATIONS_FIELD_NAME, rawValues.uas_registrations,
        useFormGroupValuesToModel, UasRegistration, SubTypeFactory.createSubTypeInstance);
      this.airspace_authorization = this.getValue<string>(rawValues, Operation.AIRSPACE_AUTHORIZATION_FIELD_NAME);
      this.flight_number = this.getValue<string>(rawValues, Operation.FLIGHT_NUMBER_FIELD_NAME);
      this.contact.setValues(rawValues.contact, useFormGroupValuesToModel);
      this.state = this.getValue<state>(rawValues, Operation.STATE_FIELD_NAME);
      this.controller_location.setValues(rawValues.controller_location, useFormGroupValuesToModel);
      this.takeoff_location.setValues(rawValues.takeoff_location, useFormGroupValuesToModel);
      this.gcs_location.setValues(rawValues.gcs_location, useFormGroupValuesToModel);
      this.fillModelArray<IContingencyPlan>(this, Operation.CONTINGENCY_PLANS_FIELD_NAME, rawValues.contingency_plans,
        useFormGroupValuesToModel, ContingencyPlan, SubTypeFactory.createSubTypeInstance);
      this.fillModelArray<INegotiationAgreement>(this, Operation.NEGOTIATION_AGREEMENTS_FIELD_NAME,
        rawValues.negotiation_agreements, useFormGroupValuesToModel, NegotiationAgreement,
        SubTypeFactory.createSubTypeInstance);
      this.faa_rule = this.getValue<faa_rule>(rawValues, Operation.FAA_RULE_FIELD_NAME);
      this.priority_elements.setValues(rawValues.priority_elements, useFormGroupValuesToModel);
      this.fillModelArray<IOperationVolume>(this, Operation.OPERATION_VOLUMES_FIELD_NAME, rawValues.operation_volumes,
        useFormGroupValuesToModel, OperationVolume, SubTypeFactory.createSubTypeInstance);
      this.metadata.setValues(rawValues.metadata, useFormGroupValuesToModel);
      this.contact_id = this.getValue<string>(rawValues, Operation.CONTACT_ID_FIELD_NAME);
      this.priority = this.getValue<number>(rawValues, Operation.PRIORTIY_FIELD_NAME);
      this.managed = this.getValue<boolean>(rawValues, Operation.MANAGED_FIELD_NAME);
      this.replan_required = this.getValue<boolean>(rawValues, Operation.REPLAN_REQUIRED_FIELD_NAME);
      this.pendingReason = this.getValue<PendingReason>(rawValues, Operation.PENDING_REASON_FIELD_NAME);
      this.approval = this.getValue<OperationApprovalInfo>(rawValues, Operation.APPROVAL_FIELD_NAME);
      this.activation = this.getValue<OperationActivationPolicy>(rawValues, Operation.ACTIVATION_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        operationId: new UntypedFormControl(this.operationId, [Validators.required, Validators.minLength(36),
          Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'), ]),
        operator_intent_id: new UntypedFormControl(this.operator_intent_id, [Validators.required, Validators.minLength(36),
          Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'), ]),
        uss_name: new UntypedFormControl(this.uss_name, [Validators.required, Validators.minLength(4),
          Validators.maxLength(250), ]),
        discovery_reference: new UntypedFormControl(this.discovery_reference, [Validators.minLength(5),
          Validators.maxLength(36), ]),
        submit_time: new UntypedFormControl(this.submit_time, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'), ]),
        update_time: new UntypedFormControl(this.update_time, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'), ]),
        effective_time_begin: new UntypedFormControl(this.effective_time_begin, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'), ]),
        effective_time_end: new UntypedFormControl(this.effective_time_end, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'), ]),
        version: new UntypedFormControl(this.version, []),
        aircraft_comments: new UntypedFormControl(this.aircraft_comments, [Validators.maxLength(1000), ]),
        flight_comments: new UntypedFormControl(this.flight_comments, [Validators.maxLength(1000), ]),
        volumes_description: new UntypedFormControl(this.volumes_description, [Validators.maxLength(1000)]),
        uas_registrations: new UntypedFormArray([]),
        airspace_authorization: new UntypedFormControl(this.airspace_authorization, [Validators.minLength(36),
          Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'), ]),
        flight_number: new UntypedFormControl(this.flight_number, [Validators.maxLength(100), ]),
        contact: this.contact.$formGroup,
        state: new UntypedFormControl(this.state, [Validators.required, enumValidator(state), ]),
        controller_location: this.controller_location.$formGroup,
        takeoff_location: this.takeoff_location.$formGroup,
        gcs_location: this.gcs_location.$formGroup,
        contingency_plans: new UntypedFormArray([]),
        negotiation_agreements: new UntypedFormArray([]),
        faa_rule: new UntypedFormControl(this.faa_rule, [Validators.required, enumValidator(faa_rule), ]),
        priority_elements: this.priority_elements.$formGroup,
        operation_volumes: new UntypedFormArray([]),
        metadata: this.metadata.$formGroup,
        contact_id: new UntypedFormControl(this.contact_id, []),
        priority: new UntypedFormControl(this.priority, [Validators.min(0), Validators.max(100)]),
        managed: new UntypedFormControl(this.managed),
        replan_required: new UntypedFormControl(this.replan_required),
        pendingReason: new UntypedFormControl(this.pendingReason, [enumValidator(PendingReason)]),
        activation: new UntypedFormControl(this.activation, [enumValidator(OperationActivationPolicy)]),
      });
      // generate FormArray control elements
      this.fillFormArray<UasRegistration>(Operation.UAS_REGISTRATIONS_FIELD_NAME, this.uas_registrations, UasRegistration);
      this.fillFormArray<ContingencyPlan>(Operation.CONTINGENCY_PLANS_FIELD_NAME, this.contingency_plans, ContingencyPlan);
      this.fillFormArray<NegotiationAgreement>(Operation.NEGOTIATION_AGREEMENTS_FIELD_NAME, this.negotiation_agreements,
        NegotiationAgreement);
      this.fillFormArray<OperationVolume>(Operation.OPERATION_VOLUMES_FIELD_NAME, this.operation_volumes, OperationVolume);
      if (this.approval) {
        this._formGroup.addControl(Operation.APPROVAL_FIELD_NAME, this.approval.$formGroup);
      }
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[Operation.OPERATION_ID_FIELD_NAME].setValue(this.operationId);
    this.$formGroup.controls[Operation.OPERATOR_INTENT_ID_FIELD_NAME].setValue(this.operator_intent_id);
    this.$formGroup.controls[Operation.USS_NAME_FIELD_NAME].setValue(this.uss_name);
    this.$formGroup.controls[Operation.DISCOVERY_REFERENCE_FIELD_NAME].setValue(this.discovery_reference);
    this.$formGroup.controls[Operation.SUBMIT_TIME_FIELD_NAME].setValue(this.submit_time);
    this.$formGroup.controls[Operation.UPDATE_TIME_FIELD_NAME].setValue(this.update_time);
    this.$formGroup.controls[Operation.BEGIN_TIME_FIELD_NAME].setValue(this.effective_time_begin);
    this.$formGroup.controls[Operation.END_TIME_FIELD_NAME].setValue(this.effective_time_end);
    this.$formGroup.controls[Operation.VERSION_FIELD_NAME].setValue(this.version);
    this.$formGroup.controls[Operation.AIRCRAFT_COMMENTS_FIELD_NAME].setValue(this.aircraft_comments);
    this.$formGroup.controls[Operation.FLIGHT_COMMENTS_FIELD_NAME].setValue(this.flight_comments);
    this.$formGroup.controls[Operation.VOLUMES_DESCRIPTION_FIELD_NAME].setValue(this.volumes_description);
    this.fillFormArray<UasRegistration>(Operation.UAS_REGISTRATIONS_FIELD_NAME, this.uas_registrations, UasRegistration);
    this.$formGroup.controls[Operation.AIRSPACE_AUTHORIZATION_FIELD_NAME].setValue(this.airspace_authorization);
    this.$formGroup.controls[Operation.FLIGHT_NUMBER_FIELD_NAME].setValue(this.flight_number);
    this.contact.setFormGroupValues();
    this.$formGroup.controls[Operation.STATE_FIELD_NAME].setValue(this.state);
    this.controller_location.setFormGroupValues();
    this.takeoff_location.setFormGroupValues();
    this.gcs_location.setFormGroupValues();
    this.fillFormArray<ContingencyPlan>(Operation.CONTINGENCY_PLANS_FIELD_NAME, this.contingency_plans, ContingencyPlan);
    this.fillFormArray<NegotiationAgreement>(Operation.NEGOTIATION_AGREEMENTS_FIELD_NAME, this.negotiation_agreements,
      NegotiationAgreement);
    this.$formGroup.controls[Operation.FAA_RULE_FIELD_NAME].setValue(this.faa_rule);
    this.priority_elements.setFormGroupValues();
    this.fillFormArray<OperationVolume>(Operation.OPERATION_VOLUMES_FIELD_NAME, this.operation_volumes, OperationVolume);
    this.metadata.setFormGroupValues();
    this.$formGroup.controls[Operation.CONTACT_ID_FIELD_NAME].setValue(this.contact_id);
    this.$formGroup.controls[Operation.PRIORTIY_FIELD_NAME].setValue(this.priority);
    this.$formGroup.controls[Operation.MANAGED_FIELD_NAME].setValue(this.managed);
    this.$formGroup.controls[Operation.REPLAN_REQUIRED_FIELD_NAME].setValue(this.replan_required);
    this.$formGroup.controls[Operation.PENDING_REASON_FIELD_NAME].setValue(this.pendingReason);
    this.approval?.setFormGroupValues();
    this.$formGroup.controls[Operation.ACTIVATION_FIELD_NAME].setValue(this.activation);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

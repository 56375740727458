import {Injectable} from '@angular/core';
import {AuthenticationState, AuthService, RoleResponse} from '../auth.service';
import {Observable, ReplaySubject, Subscription, timer} from 'rxjs';
import {User} from '../../model/User';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {catchError, map, take} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {SpringAuthInterceptor} from '../spring-auth-interceptor.service';
import {DateTime} from 'luxon';
import {ILoginResponse, Token} from '../../model/LoginResponse';

@Injectable({
  providedIn: 'root'
})
export class RestAuthService extends AuthService {

  private USER_LOGIN_URL = `${environment.baseUrl}/loginserv/api/token`;
  private USER_ASSERT_ROLE_URL = `${environment.baseUrl}/loginserv/api/token`;
  private user: User = null;
  private userObservable: ReplaySubject<User> = new ReplaySubject<User>(1);
  private logoutSub: Subscription;
  private refreshSub: Subscription;

  constructor(private http: HttpClient, private springAuthInjector: SpringAuthInterceptor) {
    super();

    this.checkLoginStatus();
  }

  checkLoginStatus(): void {
    if (localStorage.getItem('access_token')) {
      this.user = this.extractUser(Token.parseJWT(localStorage.getItem('access_token')));
      if (this.user && this.user.getExpiration() > (Date.now() + 1000)) {
        this.springAuthInjector.setAuth('Bearer ' + localStorage.getItem('access_token'));
        this.initLogoutSubscription();
        this.refreshSub = this.refreshLogin().subscribe(() => {
          this.refreshSub?.unsubscribe();
        });
      } else {
        this.user = null;
      }
    }

    this.userObservable.next(this.user);
  }

  watchCurrentUser(): Observable<User | null> {
    if (this.user) {

      const dt = DateTime.fromSeconds(this.user.getExpiration());
      const now = DateTime.fromMillis(Date.now());
      if (dt.diff(now).as('milliseconds') <= 0) {
        this.user = null;
        this.userObservable.next(this.user);
        this.logoutSub?.unsubscribe();
      }
    } /*else if (this.lastGetUserTime.diffNow().milliseconds < -1000) {
      this.lastGetUserTime = DateTime.local();
      this.user = null;
      this.userObservable.next(this.user);
    }*/

    return this.userObservable;
  }

  login(email: string, password: string): Observable<User | ILoginResponse> {
    this.springAuthInjector.setAuth(null);
    this.logoutSub?.unsubscribe();

    return this.http.post(this.USER_LOGIN_URL, null, {
      observe: 'response', headers: {
        Authorization: `Basic ${btoa(email + ':' + (password))}`
      }
    }).pipe(map((response: HttpResponse<ILoginResponse>) => {
      if (response.body.success) {
        return this.updateUser(response);
      } else {
        return response.body as ILoginResponse;
      }

    }), catchError((err: HttpErrorResponse) => {
      console.error(err);
      return of({ success: false, message: err.message } as ILoginResponse);
    }));
  }

  logout(): Observable<boolean> {
    this.springAuthInjector.setAuth(null);
    this.logoutSub?.unsubscribe();
    this.refreshSub?.unsubscribe();
    this.user = null;
    this.userObservable.next(this.user);
    localStorage.removeItem('access_token');
    localStorage.removeItem('token_expiry_time');
    return of(true);
  }

  refreshLogin(): Observable<boolean> {
    return this.assertRole(this.user, this.user.assertedRole).pipe(map(u => true));
  }

  getRoles(user: User): Observable<RoleResponse> {

    return of({
      status: 'SUCCESS',
      roles: user.roles
    } as RoleResponse);
  }

  assertRole(user: User, newRole: string): Observable<User> {
    return this.http.get(this.USER_ASSERT_ROLE_URL, {
      params: {
        role: newRole,
      },
      observe: 'response'

    }).pipe(map((response: HttpResponse<ILoginResponse>) => {
      return this.updateUser(response);
    }));
  }

  private updateUser(response: HttpResponse<ILoginResponse>) {
    const accessToken = response.body.access_token;
    this.springAuthInjector.setAuth(`Bearer ${accessToken}`);

    this.user = this.extractUser(Token.parseJWT(accessToken));
    this.userObservable.next(this.user);
    //TODO: Update to more secure storage
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('token_expiry_time', '' + this.user.expiration);
    this.initLogoutSubscription();
    return this.user;
  }

  private extractUser(token: Token) {
    return User.fromToken(token);
  }

  authenticationState(): Observable<AuthenticationState> {
    return this.watchCurrentUser().pipe(map(u => u !== null ? AuthenticationState.AUTHENTICATED : AuthenticationState.UNAUTHENTICATED));
  }

  private initLogoutSubscription() {
    this.logoutSub?.unsubscribe();
    this.logoutSub = timer(this.user.getExpiration() - Date.now() - 1000).pipe(take(1)).subscribe(() => {
      this.logout();
    });
  }
}

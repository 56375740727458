/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import {BaseModel} from './gen/utm/base-model';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

export interface IUasStatistics {
  last_flight: string;
  last_maintenance: string;
  total_operational_time: number;
  total_number_of_operations: number;
}

export class UasStatistics extends BaseModel implements IUasStatistics {

  static LAST_FLIGHT_FIELD_NAME = 'last_flight';
  static LAST_MAINTENANCE_FIELD_NAME = 'last_maintenance';
  static TOTAL_NUMBER_OF_OPERATIONS_FIELD_NAME = 'total_number_of_operations';
  static TOTAL_OPERATIONAL_TIME_FIELD_NAME = 'total_operational_time';

  last_flight: string;
  last_maintenance: string;
  total_number_of_operations: number;
  total_operational_time: number;

  constructor(values?: Partial<UasStatistics>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }

  }

  static parse(statistics: IUasStatistics): UasStatistics {
    const ret = new UasStatistics();
    ret.last_flight = statistics.last_flight;
    ret.last_maintenance = statistics.last_maintenance;
    ret.total_number_of_operations = statistics.total_number_of_operations;
    ret.total_operational_time = statistics.total_operational_time;
    return ret;
  }

  setValues(values: Partial<UasStatistics>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.last_flight = this.getValue<string>(rawValues, UasStatistics.LAST_FLIGHT_FIELD_NAME);
      this.last_maintenance = this.getValue<string>(rawValues, UasStatistics.LAST_MAINTENANCE_FIELD_NAME);
      this.total_number_of_operations = this.getValue<number>(rawValues, UasStatistics.TOTAL_NUMBER_OF_OPERATIONS_FIELD_NAME);
      this.total_operational_time = this.getValue<number>(rawValues, UasStatistics.TOTAL_OPERATIONAL_TIME_FIELD_NAME);

      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }

  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[UasStatistics.LAST_FLIGHT_FIELD_NAME].setValue(this.last_flight);
    this.$formGroup.controls[UasStatistics.LAST_MAINTENANCE_FIELD_NAME].setValue(this.last_maintenance);
    this.$formGroup.controls[UasStatistics.TOTAL_NUMBER_OF_OPERATIONS_FIELD_NAME].setValue(this.total_number_of_operations);
    this.$formGroup.controls[UasStatistics.TOTAL_OPERATIONAL_TIME_FIELD_NAME].setValue(this.total_operational_time);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        last_flight: new UntypedFormControl(this.last_flight, [Validators.required]),
        last_maintenance: new UntypedFormControl(this.last_maintenance, [Validators.required]),
        total_number_of_operations: new UntypedFormControl(this.total_number_of_operations, [Validators.required]),
        total_operational_time: new UntypedFormControl(this.total_operational_time, [Validators.required]),
      });
    }
    return this._formGroup;

  }


}

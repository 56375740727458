import {Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges} from '@angular/core';
import {IControlValueAccessor} from '@rxweb/types';
import {FormArray, FormControl, NG_VALUE_ACCESSOR, ValidatorFn, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {enforcedPatternRegexes} from '../../utils/Validators';

export enum InputType {
  TEXT = 'text',
  EMAIL = 'email',
  PHONE = 'phone'
}

@Component({
  selector: 'app-string-array-input',
  templateUrl: './string-array-input.component.html',
  styleUrls: ['./string-array-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => StringArrayInputComponent),
    }
  ]
})
export class StringArrayInputComponent implements IControlValueAccessor<string[]>, OnChanges, OnDestroy {
  @Input() inputName: string = undefined;
  @Input() inputType: InputType = undefined;

  formArray: FormArray<FormControl<string>>;
  controlValidators: ValidatorFn[] = [];
  inputTypeAttribute = 'text';
  inputTypeEnum = InputType;

  private onChange: (value: string[]) => void;
  private onTouched: () => void;
  private valueChangesSub: Subscription;

  constructor() {
    this.formArray = new FormArray<FormControl<string>>([]);

    this.valueChangesSub = this.formArray.valueChanges.pipe(debounceTime(500)).subscribe(() => this.emitChange());
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(obj: string[]): void {
    this.formArray.clear();
    obj.forEach(item => this.formArray.push(new FormControl(item, this.controlValidators)));
    this.formArray.markAsPristine();
  }

  addElement() {
    this.formArray.push(new FormControl(null, this.controlValidators));
    this.formArray.markAsTouched();
  }

  removeElement(index: number) {
    this.formArray.removeAt(index);
    this.formArray.markAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputType) {
      this.refreshControlValidators();
      this.getInputTypeAttribute();
    }
  }

  ngOnDestroy(): void {
    this.valueChangesSub?.unsubscribe();
  }

  private emitChange() {
    const value: string[] = !this.formArray.valid ? null : this.formArray.value.filter(item => !!item);

    if (this.onTouched) {
      this.onTouched();
    }

    if (this.onChange) {
      this.onChange(value);
    }
  }

  private getInputTypeAttribute() {
    switch (this.inputType) {
      case InputType.EMAIL:
        this.inputTypeAttribute = 'email';
        break;
      case InputType.PHONE:
        this.inputTypeAttribute = 'tel';
        break;
      default:
        this.inputTypeAttribute = 'text';
    }
  }

  private refreshControlValidators(): void {
    // We may want to support passing an array of validators as a bound input for this component to support varying use cases.
    this.controlValidators = [];
    switch (this.inputType) {
      case InputType.EMAIL:
        this.controlValidators.push(Validators.email);
        this.controlValidators.push(Validators.minLength(5));
        this.controlValidators.push(Validators.maxLength(50));
        break;
      case InputType.PHONE:
        this.controlValidators.push(Validators.pattern(enforcedPatternRegexes.phone));
        break;
      case InputType.TEXT:
      default:
    }
    this.formArray.controls.forEach(control => {
      control.setValidators(this.controlValidators);
    });
  }
}

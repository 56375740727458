import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {LeafletDrawerService} from '@ax/ax-angular-map-leaflet';
import {Subscription} from 'rxjs';
import {LatLng, LeafletEvent} from 'leaflet';
import {Polygon as UTMPolygon} from '../../../model/gen/utm';
import {ColorConfig} from '../../../services/color.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-leaflet-volume-creator',
  template: '',
  styleUrls: []
})
export class LeafletVolumeCreatorComponent implements OnDestroy {
  @Input() name = 'CustomPolygon';
  @Input() title = 'Custom Polygon';
  @Input() allowSelfIntersection = false;
  @Input() colorConfig: ColorConfig;
  @Output() newVolume: EventEmitter<UTMPolygon> = new EventEmitter<UTMPolygon>();

  private leafletInitSub: Subscription;
  private cb: (e: (LeafletEvent & { shape: string })) => void;
  private map: L.Map;

  constructor(private leafletDrawerService: LeafletDrawerService) {
    this.leafletInitSub = this.leafletDrawerService.watchViewerInit().subscribe(mapy => {
      this.map = mapy;
      mapy.pm.Toolbar.copyDrawControl('Polygon', {
        name: this.name, block: 'draw', title: this.title
      });
      mapy.pm.Draw[this.name].options.templineStyle = {color: this.colorConfig.fill.toHexString()};
      mapy.pm.Draw[this.name].options.hintlineStyle = {color: this.colorConfig.fill.toHexString()};
      mapy.pm.Draw[this.name].options.allowSelfIntersection = false;

      this.cb = (e: LeafletEvent & { shape: string }) => {
        if (e.shape !== this.name) {
          return;
        }
        if (!_.isEqual(e.layer.getLatLngs()[0][0], e.layer.getLatLngs()[0][e.layer.getLatLngs()[0].length - 1])) {
          e.layer.getLatLngs()[0].push(e.layer.getLatLngs()[0][0]);
        }
        const newVolume = new UTMPolygon({
          coordinates: [(e.layer.getLatLngs() as LatLng[][])[0].map(pt => [pt.lng, pt.lat])]
        });
        this.map.removeLayer(e.layer);
        this.newVolume.next(newVolume);

      };
      mapy.on('pm:create', this.cb);
    });
  }

  ngOnDestroy(): void {
    this.leafletInitSub?.unsubscribe();
    if (this.map && this.cb) {
      this.map.off('pm:create', this.cb);
    }
  }

}

<form [formGroup]="fg">
  <div class="clr-row">
    <clr-input-container class="clr-col-lg-2">
      <label>Name</label>
      <input autocomplete="off" clrInput [formControlName]="'name'"/>
    </clr-input-container>
    <clr-input-container class="clr-col-lg-2">
      <label>Organization</label>
      <input autocomplete="off" clrInput [formControlName]="'organization'"/>
    </clr-input-container>
    <clr-input-container class="clr-col-lg-2">
      <label>Make</label>
      <input autocomplete="off" clrInput [formControlName]="'make'"/>
    </clr-input-container>
    <clr-input-container class="clr-col-lg-2">
      <label>Model</label>
      <input autocomplete="off" clrInput [formControlName]="'model'"/>
    </clr-input-container>
    <clr-input-container class="clr-col-lg-2">
      <label>Serial Number</label>
      <input autocomplete="off" clrInput [formControlName]="'serialNumber'"/>
    </clr-input-container>
    <clr-input-container class="clr-col-lg-2">
      <label>FAA Registration Number</label>
      <input autocomplete="off" clrInput [formControlName]="'faaRegistrationNumber'"/>
    </clr-input-container>
  </div>

  <div class="clr-row">
    <div class="clr-col-lg-2">
      <app-label-select [prettyName]="'Sort Options'" [options]="sortOptions" [formControlName]="'sort'"></app-label-select>
      <clr-checkbox-container>
        <clr-checkbox-wrapper>
          <label>Sort Ascending</label>
          <input autocomplete="off" type="checkbox" clrCheckbox formControlName="sortIncreasing"/>
        </clr-checkbox-wrapper>
      </clr-checkbox-container>
    </div>
  </div>
</form>
<button *ngIf="showUpdateButton" (click)="emitUpdate()" class="btn btn-primary">Update</button>

/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator, maxValueValidator, minValueValidator} from './validators';
import {BaseModel} from './base-model';

import {contingency_cause, contingency_location_description, contingency_response} from './enums';
import {IPolygon, Polygon} from './polygon.model';
import {Altitude, IAltitude} from './altitude.model';
import {DateTime} from "luxon";

export interface IContingencyPlan {
  contingency_id: number;
  name: string;
  contingency_cause: Array<contingency_cause>;
  contingency_response: contingency_response;
  contingency_polygon: IPolygon;
  loiter_altitude?: IAltitude;
  relative_preference?: number;
  contingency_location_description: contingency_location_description;
  relevant_operation_volumes?: Array<number>;
  valid_time_begin: DateTime;
  valid_time_end: DateTime;
  free_text?: string;
}


export class ContingencyPlan extends BaseModel implements IContingencyPlan {

  static CONTINGENCY_ID_FIELD_NAME = 'contingency_id';
  static NAME_FIELD_NAME = 'name';
  static CONTINGENCY_CAUSE_FIELD_NAME = 'contingency_cause';
  static CONTINGENCY_RESPONSE_FIELD_NAME = 'contingency_response';
  static CONTINGENCY_POLYGON_FIELD_NAME = 'contingency_polygon';
  static LOITER_ALTITUDE_FIELD_NAME = 'loiter_altitude';
  static RELATIVE_PREFERENCE_FIELD_NAME = 'relative_preference';
  static CONTINGENCY_LOCATION_DESCRIPTION_FIELD_NAME = 'contingency_location_description';
  static RELEVANT_OPERATION_VOLUMES_FIELD_NAME = 'relevant_operation_volumes';
  static VALID_TIME_BEGIN_FIELD_NAME = 'valid_time_begin';
  static VALID_TIME_END_FIELD_NAME = 'valid_time_end';
  static FREE_TEXT_FIELD_NAME = 'free_text';

  /** A positive integer unique amongst the set of Contingencies for this operation. The integers may be ordered by some scheme, but this is not required. */
  contingency_id: number;
  name: string;
  contingency_cause: Array<contingency_cause>;
  /** The type of contingency response. 1. LANDING
   The operation will be landing by targeting the contingency_polygon.
   2. LOITERING
   The operation will loiter at the contingency_polygon at the specified
   loiter_altitude.
   3. RETURN_TO_BASE
   The operation will return to base as specified by the
   contingency_polygon. The USS may issue an update to the operation plan
   to support this maneuver.
   4. OTHER
   Additional details should be provided in free_text. If this gets
   used often for similar events, API will be updated with new enum
   value. */
  contingency_response: contingency_response;
  /** If LANDING or RETURN_TO_BASE contingency_repsonse, then this is the area on the ground that the UA will be targeting for a landing.  The polygon should be large enough to provide high confidence (for some TBD value of "high") that the vehicle will land within it, but hopefully be no larger (for some TBD value of hopefully).
   If LOITERING, this is the polygon the UA will stay within at the loiter_altitude. */
  contingency_polygon: Polygon;
  /** The altitude for this contingency. Relevant and required for the LOITERING contingency_type, ignored for all other types.
   Note: may need to split this into a min_loiter_altitude and a max_loiter_altitide in the future to provide a full 4D loiter volume. */
  loiter_altitude: Altitude;
  /** Optional numerical value that can be used in ranking the preference of this Contingency versus any other within the set of Contingency for this operation. This may be thought of as a ranking of the potential landing sites with all other factors being held equal, though dynamic conditions will likely play a role in adjusting this ranking in real time by the USS or Operator.  For example, one Contingency may be significantly further from the operation at a given time and, thus, would be less preferred than it might be otherwise. Further interpretation of this field is left to the operator and USS. */
  relative_preference: number;
  /** Description of the contingency location.
   PREPROGRAMMED: location that is determined prior to launch and
   programmed onto the UA
   OPERATOR_UPDATED: location that is (or will be) updated during
   operation by operator (e.g., sent to UA)
   UA_IDENTIFIED: location that is identified to be safe to land by
   the UA itself
   OTHER: location does not fit any of the defined categories */
  contingency_location_description: contingency_location_description;
  /** In the planning stage of an operation, this array may be populated with ordinals that correspond to the ordinal values supplied with each OperationVolume. This is an indicator that this particular ContingencyPlan is valid for use when the operation is active in any of the particular noted OperationVolumes. For example, you may define 1 operation volume and 3 different contingency plans specifying the causes that would transition the operation into LANDING, LOITERING or RETURN_TO_BASE. See also ContingencyPlan.relevant_preference and Operation.contingency_plans. */
  relevant_operation_volumes: Array<number>;
  /** Time that this location is expected to be first available. For example, if an operation begins at 1100, but this location is not available until 1105 at the earliest, then this field could indicate that fact. */
  valid_time_begin: DateTime;
  /** Time that this location is expected to become unavailable. For example, if an operation begins at 1100, but this location becomes closed for some reason at 1105, then this field could indicate that fact. */
  valid_time_end: DateTime;
  /** To be used for additional comments as needed. For human use, not for automating any process. */
  free_text: string;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IContingencyPlan>, useFormGroupValuesToModel = false) {
    super();
    this.contingency_cause = [];
    this.contingency_polygon = new Polygon();
    this.loiter_altitude = new Altitude();
    this.relevant_operation_volumes = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IContingencyPlan>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.contingency_id = this.getValue<number>(rawValues, ContingencyPlan.CONTINGENCY_ID_FIELD_NAME);
      this.name = this.getValue<string>(rawValues, ContingencyPlan.NAME_FIELD_NAME);
      this.fillModelArray<contingency_cause>(this, ContingencyPlan.CONTINGENCY_CAUSE_FIELD_NAME, rawValues.contingency_cause, useFormGroupValuesToModel);
      this.contingency_response = this.getValue<contingency_response>(rawValues, ContingencyPlan.CONTINGENCY_RESPONSE_FIELD_NAME);
      this.contingency_polygon.setValues(rawValues.contingency_polygon, useFormGroupValuesToModel);
      this.loiter_altitude.setValues(rawValues.loiter_altitude, useFormGroupValuesToModel);
      this.relative_preference = this.getValue<number>(rawValues, ContingencyPlan.RELATIVE_PREFERENCE_FIELD_NAME);
      this.contingency_location_description = this.getValue<contingency_location_description>(rawValues, ContingencyPlan.CONTINGENCY_LOCATION_DESCRIPTION_FIELD_NAME);
      this.fillModelArray<number>(this, ContingencyPlan.RELEVANT_OPERATION_VOLUMES_FIELD_NAME, rawValues.relevant_operation_volumes, useFormGroupValuesToModel);
      this.valid_time_begin = this.getValue<DateTime>(rawValues, ContingencyPlan.VALID_TIME_BEGIN_FIELD_NAME);
      this.valid_time_end = this.getValue<DateTime>(rawValues, ContingencyPlan.VALID_TIME_END_FIELD_NAME);
      this.free_text = this.getValue<string>(rawValues, ContingencyPlan.FREE_TEXT_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        contingency_id: new UntypedFormControl(this.contingency_id, [Validators.required, minValueValidator(1), maxValueValidator(1000),]),
        name: new UntypedFormControl(),
        contingency_cause: new UntypedFormArray([]),
        contingency_response: new UntypedFormControl(this.contingency_response, [Validators.required, enumValidator(contingency_response),]),
        contingency_polygon: this.contingency_polygon.$formGroup,
        loiter_altitude: this.loiter_altitude.$formGroup,
        relative_preference: new UntypedFormControl(this.relative_preference, [minValueValidator(0),]),
        contingency_location_description: new UntypedFormControl(this.contingency_location_description, [Validators.required, enumValidator(contingency_location_description),]),
        relevant_operation_volumes: new UntypedFormArray([]),
        valid_time_begin: new UntypedFormControl(this.valid_time_begin, [Validators.required, Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'),]),
        valid_time_end: new UntypedFormControl(this.valid_time_end, [Validators.required, Validators.minLength(24), Validators.maxLength(24), Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$'),]),
        free_text: new UntypedFormControl(this.free_text, [Validators.minLength(0), Validators.maxLength(1000),]),
      });
      // generate FormArray control elements
      this.fillFormArray<contingency_cause>(ContingencyPlan.CONTINGENCY_CAUSE_FIELD_NAME, this.contingency_cause);
      // generate FormArray control elements
      this.fillFormArray<number>(ContingencyPlan.RELEVANT_OPERATION_VOLUMES_FIELD_NAME, this.relevant_operation_volumes);
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[ContingencyPlan.CONTINGENCY_ID_FIELD_NAME].setValue(this.contingency_id);
    this.$formGroup.controls[ContingencyPlan.NAME_FIELD_NAME].setValue(this.name);
    this.fillFormArray<contingency_cause>(ContingencyPlan.CONTINGENCY_CAUSE_FIELD_NAME, this.contingency_cause);
    this.$formGroup.controls[ContingencyPlan.CONTINGENCY_RESPONSE_FIELD_NAME].setValue(this.contingency_response);
    this.contingency_polygon.setFormGroupValues();
    this.loiter_altitude.setFormGroupValues();
    this.$formGroup.controls[ContingencyPlan.RELATIVE_PREFERENCE_FIELD_NAME].setValue(this.relative_preference);
    this.$formGroup.controls[ContingencyPlan.CONTINGENCY_LOCATION_DESCRIPTION_FIELD_NAME].setValue(this.contingency_location_description);
    this.fillFormArray<number>(ContingencyPlan.RELEVANT_OPERATION_VOLUMES_FIELD_NAME, this.relevant_operation_volumes);
    this.$formGroup.controls[ContingencyPlan.VALID_TIME_BEGIN_FIELD_NAME].setValue(this.valid_time_begin);
    this.$formGroup.controls[ContingencyPlan.VALID_TIME_END_FIELD_NAME].setValue(this.valid_time_end);
    this.$formGroup.controls[ContingencyPlan.FREE_TEXT_FIELD_NAME].setValue(this.free_text);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }


}

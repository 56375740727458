import {Injectable, OnDestroy} from '@angular/core';
import {OperationSearchParameters, OperationService} from './operation.service';
import {ConstraintSearchParameters, ConstraintService} from './constraint.service';
import {catchError, debounceTime, map, switchMap, throttleTime} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, EMPTY, Observable, ReplaySubject, Subscription} from 'rxjs';
import {OperationExt} from '../model/utm/OperationExt';
import {InvalidArgumentException} from '../utils/invalid-argument.exception';
import {UvrExt} from '../model/utm/UvrExt';
import {Volume4dQuery} from '../models/Volume4dQuery';

export interface UtmState {
  operations: OperationExt[];
  constraints: UvrExt[];
}

export interface SituationalAwarenessWatchOptions {
  interval?: number;
  // tslint:disable-next-line:no-any
  triggerObservable?: Observable<any>;
}

@Injectable({
  providedIn: 'root'
})
export class SituationalAwarenessService implements OnDestroy {

  private constraintSubject: ReplaySubject<UvrExt[]> = new ReplaySubject<UvrExt[]>(1);
  private operationSubject: ReplaySubject<OperationExt[]> = new ReplaySubject<OperationExt[]>(1);
  private volQuery: ReplaySubject<Volume4dQuery> = new ReplaySubject<Volume4dQuery>(1);
  private refreshSubject: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private volQueryObs: Observable<Volume4dQuery>;
  private queryCache: string = null;
  private operationVolumeSubscription: Subscription;
  private constraintVolumeSubscription: Subscription;

  constructor(private operationService: OperationService, private constraintService: ConstraintService) {

    this.volQueryObs = combineLatest([
      this.volQuery,
      this.refreshSubject.pipe(throttleTime(2000))
    ]).pipe(debounceTime(100)).pipe(map(([query]) => query));

    this.operationVolumeSubscription = this.volQueryObs.pipe(switchMap((query) => {
      const search = OperationSearchParameters.defaultSearch();
      search.volume4d = query;
      return this.operationService.getAllOperations(search).pipe(catchError(() => (EMPTY)));
    })).subscribe((operations) => {
      this.operationSubject.next(operations);
    });

    this.constraintVolumeSubscription = this.volQueryObs.pipe(switchMap((query) => {
      const search = ConstraintSearchParameters.defaultSearch();
      search.volume4d = query;
      return this.constraintService.getAllConstraints(search).pipe(catchError(() => (EMPTY)));
    })).subscribe((constraints) => {
      this.constraintSubject.next(constraints);
    });

  }

  setVolume4dQuery(volume4dQuery: Volume4dQuery, forceUpdate?: boolean): void {
    if (!volume4dQuery) {
      throw new InvalidArgumentException('Volume4dQuery must be supplied');
    }

    if (JSON.stringify(volume4dQuery) === this.queryCache && !forceUpdate) {
      return;
    }
    this.queryCache = JSON.stringify(volume4dQuery);
    this.volQuery.next(volume4dQuery);
  }

  watchOperations(): Observable<OperationExt[]> {
    return this.operationSubject.asObservable();
  }

  watchConstraints(): Observable<UvrExt[]> {
    return this.constraintSubject.asObservable();
  }

  refresh(): void {
    this.refreshSubject.next(null);
  }

  watchAllUtm(): Observable<UtmState> {
    return combineLatest([
      this.watchOperations(),
      this.watchConstraints()
    ]).pipe(map(([operations, constraints]) => ({
      operations,
      constraints
    })));
  }

  ngOnDestroy(): void {
    this.operationVolumeSubscription?.unsubscribe();
    this.constraintVolumeSubscription?.unsubscribe();
  }
}

<div class="helper-wrapper">
    <p>Instructions: Enter the circle's lat/lon coordinates and radius in the fields below. A pair of lat/lon, DMS,
      or KML coordinates can be pasted into the latitude or longitude fields and will be converted accordingly.</p>
</div>
<form [formGroup]="formGroup">
    <clr-input-container>
        <label>Latitude</label>
        <input clrInput [formControl]="formGroup.controls.latitude" type="number" (paste)="handlePaste($event, formGroup.controls.latitude)"/>
        <clr-control-error *clrIfError="'required'">Latitude is required</clr-control-error>
        <clr-control-error *clrIfError="'min'">Minimum latitude is {{formGroup.controls.latitude.errors.min.min}}</clr-control-error>
        <clr-control-error *clrIfError="'max'">Maxmimum latitude is {{formGroup.controls.latitude.errors.max.max}}</clr-control-error>
    </clr-input-container>

    <clr-input-container>
        <label>Longitude</label>
        <input clrInput [formControl]="formGroup.controls.longitude" type="number" (paste)="handlePaste($event, formGroup.controls.longitude)"/>
        <clr-control-error *clrIfError="'required'">Longitude is required</clr-control-error>
        <clr-control-error *clrIfError="'min'">Minimum longitude is {{formGroup.controls.longitude.errors.min.min}}</clr-control-error>
        <clr-control-error *clrIfError="'max'">Maximum longitude is {{formGroup.controls.longitude.errors.max.max}}</clr-control-error>
    </clr-input-container>

  <div class="radius-wrapper">
    <clr-input-container>
        <label>Radius</label>
        <input clrInput [formControl]="formGroup.controls.radius" type="number"/>
        <clr-control-error *clrIfError="'required'">Radius is required</clr-control-error>
      <clr-control-error *clrIfError="'min'">Minimum radius is {{formGroup.controls.radius.errors.min.min}} {{unitLabels[formGroup.controls.units.value]}}</clr-control-error>
      <clr-control-error *clrIfError="'max'">Maximum radius is {{formGroup.controls.radius.errors.max.max}} {{unitLabels[formGroup.controls.units.value]}}</clr-control-error>
    </clr-input-container>

    <clr-select-container>
      <label>Units</label>
      <select clrSelect formControlName="units">
        <option *ngFor="let unit of availableUnits" [value]="unit.value">{{unit.label}}</option>
      </select>
    </clr-select-container>
  </div>
</form>

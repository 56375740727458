import {
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { LeafletDrawerService } from '@ax/ax-angular-map-leaflet';
import * as L from 'leaflet';
import { LatLng } from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Polygon as UTMPolygon } from '../../../model/gen/utm';
import { ColorConfig, ColorService } from '../../../services/color.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import pointOnFeature from '@turf/point-on-feature';

@Component({
  selector: 'app-leaflet-polygon-editor',
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => LeafletPolygonEditorComponent),
    },
  ],
})
export class LeafletPolygonEditorComponent
  implements OnChanges, OnDestroy, ControlValueAccessor
{
  @Input() colorConfig: ColorConfig;
  @Input() index: number;

  private map: L.Map;
  private onChange: (v: UTMPolygon | null) => void;
  private onTouched: () => void;
  private layerGroup: L.LayerGroup;
  private value: UTMPolygon | null;
  private layer: L.Polygon;
  private tooltip: L.Tooltip;
  private indexSubject = new BehaviorSubject<number>(null);
  private indexSub: Subscription;
  private leafletInitSub: Subscription;

  constructor(
    private leafletDrawerService: LeafletDrawerService,
    private colorService: ColorService,
  ) {
    this.layerGroup = L.layerGroup([]);

    this.leafletInitSub = this.leafletDrawerService
      .watchViewerInit()
      .subscribe((mapy) => {
        this.map = mapy;
        this.refreshLeaflet();
      });
    if (this.colorConfig) {
      this.refreshLeaflet();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.colorConfig) {
      this.refreshLeaflet();
    }
    if (changes.index) {
      this.indexSubject.next(this.index);
    }
  }

  ngOnDestroy(): void {
    this.leafletInitSub.unsubscribe();
    this.indexSub?.unsubscribe();
    this.layerGroup.remove();
  }

  registerOnChange(fn: (v: UTMPolygon | null) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    // Method not implemented
  }

  writeValue(obj: UTMPolygon | null): void {
    this.layerGroup.clearLayers();
    this.value = obj;
    if (!obj) {
      return;
    }
    this.refreshLeaflet();
  }

  private refreshLeaflet() {
    if (!this.map) {
      return;
    }
    this.layerGroup.addTo(this.map);
    if (this.value && this.colorConfig) {
      for (const coord of this.value.coordinates) {
        const polyLayer = L.polygon(
          coord.map((arr) => new LatLng(arr[1], arr[0])),
          {
            fillColor: this.colorConfig.fill.toHexString(),
            fillOpacity: this.colorConfig.fill.getAlpha(),
            color: this.colorConfig.fill.toHexString(),
          } as L.PolylineOptions,
        );

        this.layer = polyLayer;

        this.enablePmEdit(polyLayer, (e, eventType) => {
          if (eventType === 'pm:remove') {
            this.value = null;
            this.emitChange();
            return;
          }
          const newGeometry = this.extractGeometryFromLayer(polyLayer);
          if (_.isEqual(this.value, newGeometry)) {
            return;
          }
          this.value = newGeometry;
          this.setTooltip(polyLayer);
          this.emitChange();
        });

        polyLayer.addTo(this.layerGroup);
        this.setTooltip(polyLayer);
      }
    }
  }

  private enablePmEdit(
    l: L.Polygon,
    cb: (
      e: L.LeafletEvent,
      eventType: 'pm:update' | 'pm:dragend' | 'pm:remove',
    ) => void,
  ) {
    l.pm.enable({
      allowSelfIntersection: false,
    });
    l.on('pm:edit', (e: L.LeafletEvent) => {
      const points = l.getLatLngs()[0] as LatLng[];
      if (points.length < 3) {
        cb(e, 'pm:remove');
        this.touched();
      }
    });
    l.on('pm:update', (e: L.LeafletEvent) => {
      const points = l.getLatLngs()[0] as LatLng[];
      if (points.length < 3) {
        cb(e, 'pm:remove');
        this.touched();
        return;
      }
      cb(e, 'pm:update');
      this.touched();
    });
    l.on('pm:dragend', (e: L.LeafletEvent) => {
      cb(e, 'pm:dragend');
      this.touched();
    });
    l.on('pm:remove', (e: L.LeafletEvent) => {
      cb(e, 'pm:remove');
      this.touched();
    });
  }

  private touched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  private extractGeometryFromLayer(target: L.Polygon): UTMPolygon {
    const poly: L.LatLng[] = target.getLatLngs()[0] as L.LatLng[];
    const ret = new UTMPolygon({
      coordinates: [
        (target.getLatLngs() as LatLng[][])[0].map((pt) => [pt.lng, pt.lat]),
      ],
    });

    if (!_.isEqual(poly[0], poly[poly.length - 1])) {
      ret.coordinates[0].push(cloneDeep(ret.coordinates[0][0]));
    }
    return ret;
  }

  private emitChange() {
    if (this.onChange) {
      this.onChange(this.value);
    }
  }

  private setTooltip(polyLayer: L.Polygon) {
    this.indexSub?.unsubscribe();
    this.tooltip?.remove();

    // Add tooltip within the bounds of the polygon
    const centerFeature = pointOnFeature(polyLayer.toGeoJSON());
    const centerLatLng = new LatLng(
      centerFeature.geometry.coordinates[1],
      centerFeature.geometry.coordinates[0],
    );
    const tooltip = new L.Tooltip(centerLatLng, { permanent: true });
    this.tooltip = tooltip;
    tooltip.addTo(this.layerGroup);

    this.indexSub = this.indexSubject.subscribe((index) => {
      tooltip.setContent((index + 1).toString());
      this.tooltip = tooltip;
    });
  }
}

import {Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {User} from '../model/User';
import {PermissionService} from './service/permission.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {AxPermissionRequest} from './AxPermissionRequest';
import {switchMap} from 'rxjs/operators';
import {PermissionParser} from './PermissionParser';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasPermission]'
})
export class HasPermissionDirective implements OnInit, OnDestroy {

  private request: AxPermissionRequest<any>;
  private currentUser: User;
  private requestSubject: BehaviorSubject<AxPermissionRequest<any>> = new BehaviorSubject<AxPermissionRequest<any>>(null);
  private permissionSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private permSub: Subscription;
  private permissionSub: Subscription;
  private requestSub: Subscription;
  prevPerm: boolean = null;
  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissionService: PermissionService
  ) {
  }

  ngOnInit(): void {
    this.requestSub = this.requestSubject.pipe(switchMap((req) => {
      return this.permissionService.evaluateRequest(req);
    })).subscribe(this.permissionSubject);


    this.permissionSub = this.permissionSubject.subscribe(perm => {
      if (perm === this.prevPerm) {
        return;
      }
      this.prevPerm = perm;
      if (perm) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }

  @Input('hasPermission')
  set hasPermission(val: string | { action: string, resourceType: string, resource:any }) {
    let request: AxPermissionRequest<any>;
    if (typeof val === 'string') {
      request = PermissionParser.parseAxPermissionRequest(val);
    } else {
      request = new AxPermissionRequest<any>();
      request.setAction(val.action);
      request.setResourceType(val.resourceType);
      request.setResource(val.resource);
    }

    this.request = request;
    this.requestSubject.next(this.request);
  }

  ngOnDestroy(): void {
    this.permSub?.unsubscribe();
    this.permissionSub?.unsubscribe();
  }
}

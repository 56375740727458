/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';

import {polygon_type, uasvolumereservation_type} from './enums';

export interface IPolygon {
  type: polygon_type;
  coordinates: Array<Array<Array<number>>>;
}


export class Polygon extends BaseModel implements IPolygon {

  static TYPE_FIELD_NAME = 'type';
  static COORDINATES_FIELD_NAME = 'coordinates';

  /** The type of Geometry. In this case, must be 'Polygon' per GeoJSON
   spec.  Note that the "coordinates" member is validated to be be an
   array of size one. This implies there are no interior rings allowed
   currently.
   */
  type: polygon_type = polygon_type.Polygon;
  /** The number of polygons allowed.  Currently 1, implying no interior rings.  If this number is changes, the maxItems should be considered.
   From RFC7946: o  For type "Polygon", the "coordinates" member MUST be an array of linear ring coordinate arrays.
   o  For Polygons with more than one of these rings, the first MUST be the exterior ring, and any others MUST be interior rings.  The exterior ring bounds the surface, and the interior rings (if present) bound holes within the surface.
   Again, note we only allow a single set of coordinates, implying no interior rings. */
  coordinates: Array<Array<Array<number>>>;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IPolygon>, useFormGroupValuesToModel = false) {
    super();
    this.coordinates = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IPolygon>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.type = this.getValue<polygon_type>(rawValues, Polygon.TYPE_FIELD_NAME);
      this.fillModelArray<Array<Array<number>>>(this, Polygon.COORDINATES_FIELD_NAME, rawValues.coordinates, useFormGroupValuesToModel);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        type: new UntypedFormControl(this.type, [Validators.required, enumValidator(uasvolumereservation_type),]),
        coordinates: new UntypedFormArray([]),
      });
      // generate FormArray control elements
      this.fillFormArray<Array<Array<number>>>(Polygon.COORDINATES_FIELD_NAME, this.coordinates);
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[Polygon.TYPE_FIELD_NAME].setValue(this.type);
    this.fillFormArray<Array<Array<number>>>(Polygon.COORDINATES_FIELD_NAME, this.coordinates);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export enum TargetEnum {
  OPERATION = 'Operation',
  USER = 'User'
}

export interface IMessageTarget {
  targetType: TargetEnum;
  id: string;
}

@Injectable({
  providedIn: 'root'
})
export class DirectMessageTargetIdService {
  targetSubject = new Subject<IMessageTarget>();

  constructor() { }

  notifyOfTargetChange(targetID: IMessageTarget) {
    this.targetSubject.next(targetID);
  }

  watchTarget(): Subject<IMessageTarget> {
    return this.targetSubject;
  }
}

import {
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  ValidationErrors,
  Validator
} from '@angular/forms';
import {SelectOption} from '../../select-option';
import {Altitude, units_of_measure, vertical_reference} from '../../model/gen/utm';


@Component({
  selector: 'app-altitude-selector',
  templateUrl: './altitude-selector.component.html',
  styleUrls: ['./altitude-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AltitudeSelectorComponent),
    }, {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => AltitudeSelectorComponent),
    }
  ]
})
export class AltitudeSelectorComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  @Input() inline = false;
  @Input() editable = true;
  @Output() update = new EventEmitter<Altitude>();
  fg: UntypedFormGroup;
  VerticalReferenceIter: SelectOption[] = (() => {
    const ret = [];
    for (const option of Object.keys(vertical_reference)) {
      ret.push({label: option, value: vertical_reference[option]});
    }
    return ret;
  })();
  UnitsOfMeasureIter: SelectOption[] = (() => {
    const ret = [];
    for (const option of Object.keys(units_of_measure)) {
      if (option === "M" || option === "FT") {
        ret.push({label: option, value: units_of_measure[option]});
      }
    }
    return ret;
  })();
  @Input() value: Altitude;
  private onTouched: () => void;
  private onChange: any;
  private altitude: Altitude = new Altitude();

  constructor() {
    this.fg = this.altitude.$formGroup;
  }

  @HostListener('touchend')
  touched() {
    if (this.onTouched) {
      this.onTouched();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.fg) {
      this.ngOnInit();
    }
    if (changes.value) {
      this.writeValue(this.value);
    }
  }

  ngOnInit() {
    this.fg.valueChanges.subscribe(() => {
      this.handleChange();
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.editable = !isDisabled;
    this.ngOnInit();
  }

  writeValue(obj: Altitude): void {
    this.altitude.setValues(obj);
    this.altitude.setFormGroupValues();
  }


  handleChange(): void {
    this.touched();
    if (this.fg.controls.altitude_value === null || this.fg.controls.altitude_value === undefined) {
      return;
    }
    if (this.onChange) {
      this.onChange(this.fg.getRawValue());
    }
    this.update.emit(new Altitude(this.fg.getRawValue()));
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const ret: { required?: string } = {};
    const value: Altitude = control.value;

    if (!value || value.altitude_value === null || value.altitude_value === undefined) {
      ret.required = 'Missing altitude value';
    }

    return Object.keys(ret).length > 0 ? ret : null;
  }
}

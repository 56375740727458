import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AltitudeSelectorComponent} from './altitude-selector/altitude-selector.component';
import {PrettyPrintPipe} from './pretty-print.pipe';
import {ClarityModule, ClrTabsModule} from '@clr/angular';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AxOperationSummaryComponent} from './operation/ax-operation-summary/ax-operation-summary.component';
import {AxAltitudeSummaryComponent} from './ax-altitude-summary/ax-altitude-summary.component';
import {AxOperationExtSummaryComponent} from './operation/ax-operation-ext-summary/ax-operation-ext-summary.component';
import {PlatformSelectorComponent} from './platforms/platform-selector/platform-selector.component';
import {CreateOperationComponent} from './operation/create-operation/create-operation.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {DatetimePickerComponent} from './generic-controls/datetime-picker/datetime-picker.component';
import {ViewPlatformComponent} from './platforms/view-platform/view-platform.component';
import {EditPlatformComponent} from './platforms/edit-platform/edit-platform.component';
import {UserMessageTableComponent} from './user-message/user-message-table/user-message-table.component';
import {UserMessageAlertBarComponent} from './user-message/user-message-alert-bar/user-message-alert-bar.component';
import {ConstraintExtSummaryComponent} from './constraint/constraint-ext-summary/constraint-ext-summary.component';
import {ConstraintSummaryComponent} from './constraint/constraint-summary/constraint-summary.component';
import {EditConstraintComponent} from './constraint/edit-constraint/edit-constraint.component';
import {WorkInProgressComponent} from './work-in-progress/work-in-progress.component';
import {Constraint2dViewComponent} from './constraint/constraint-2d-view/constraint2d-view.component';
import {
  OperationGeometryEditorComponent
} from './operation/operation-geometry-editor/operation-geometry-editor.component';

import {UserMessageSummaryV2Component} from './user-message/user-message-summary-v2/user-message-summary-v2.component';
import {RouterModule} from '@angular/router';
import {AxAngularMapLeafletModule} from '@ax/ax-angular-map-leaflet';
import {AxAngularMapCesiumModule} from '@ax/ax-angular-map-cesium';
import {CesiumModule} from '../cesium/cesium.module';
import {EditUserComponent} from './edit-user/edit-user.component';
import {OperationStateBadgeComponent} from './operation/operation-state-badge/operation-state-badge.component';
import {ContactSelectorComponent} from './owner-contact-selector/contact-selector.component';
import {
  AdditionalRegistrationInputComponent
} from './additional-registration-input/additional-registration-input.component';
import {StringArrayInputComponent} from './string-array-input/string-array-input.component';
import {UserSummaryComponent} from './user-summary/user-summary.component';
import {RemoveUserComponent} from './remove-user/remove-user.component';
import {LabelSelectComponent} from './label-select/label-select.component';

import {ErrorContainerComponent} from './generic-controls/error-container/error-container.component';
import {ErrorMessageComponent} from './generic-controls/error-message/error-message.component';
import {CdsModule, CdsToggleModule} from '@cds/angular';
import {UserSettingsComponent} from './user-settings/user-settings.component';
import {FontSizeButtonComponent} from './user-settings/font-size-button/font-size-button.component';
import {ConstraintStateBadgeComponent} from './constraint/constraint-state-badge/constraint-state-badge.component';
import {LeafletModule as AXLeafletModule} from '../leaflet/leaflet.module';
import {EditContactComponent} from './edit-contact/edit-contact.component';
import {ErrorGroupComponent} from './generic-controls/error-group/error-group.component';
import {EditFederationPolicyComponent} from './edit-federation-policy/edit-federation-policy.component';
import {DraftSelectorComponent} from './drafts/draft-selector/draft-selector.component';
import {
  ConstraintGeometryEditorComponent
} from './constraint/constraint-geometry-editor/constraint-geometry-editor.component';
import {AltitudeRangeSelectorComponent} from './altitude-range-selector/altitude-range-selector.component';
import {LatLngPointInputComponent} from './latlngpoint-input/lat-lng-point-input.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {PermissionsModule} from '../permissions/permissions.module';
import {TimeRangeSelectorComponent} from './time-range-selector/time-range-selector.component';
import {LeafletPolygonEditorComponent} from './leaflet/leaflet-polygon-editor/leaflet-polygon-editor.component';
import {LeafletSitrepDrawerComponent} from './leaflet/leaflet-sitrep-drawer/leaflet-sitrep-drawer.component';
import {LeafletPolylineDrawerComponent} from './leaflet/leaflet-polyline-drawer/leaflet-polyline-drawer.component';
import {LeafletVolumeCreatorComponent} from './leaflet/leaflet-volume-creator/leaflet-volume-creator.component';
import {LeafletPointCreatorComponent} from './leaflet/leaflet-point-creator/leaflet-point-creator.component';
import {SendDirectMessageModalComponent} from './send-direct-message-modal/send-direct-message-modal.component';
import {CopyToClipboardComponent} from './copy-to-clipboard/copy-to-clipboard.component';
import {ErrorScreenComponent} from './error-screen/error-screen.component';
import {ManualPolygonInputComponent} from './manual-polygon-input/manual-polygon-input.component';
import {NgxMatDatetimePickerModule} from './datetime-picker/datepicker-module';
import {NgxMatNativeDateModule} from './datetime-picker/core/native-date.module';
import {LeafletCircleCreatorComponent} from './leaflet/leaflet-circle-creator/leaflet-circle-creator.component';
import {LeafletCircleEditorComponent} from './leaflet/leaflet-circle-editor/leaflet-circle-editor.component';
import {ManualCircleInputComponent} from './manual-circle-input/manual-circle-input.component';
import {
  EditTelemetryIntegrationsComponent
} from './platforms/edit-telemetry-integrations/edit-telemetry-integrations.component';

@NgModule({
  declarations: [
    AltitudeSelectorComponent,
    PrettyPrintPipe,
    AxOperationSummaryComponent,
    AxOperationExtSummaryComponent,
    PlatformSelectorComponent,
    CreateOperationComponent,
    DatetimePickerComponent,
    ViewPlatformComponent,
    EditPlatformComponent,
    UserMessageTableComponent,
    UserMessageAlertBarComponent,
    ConstraintExtSummaryComponent,
    ConstraintSummaryComponent,
    EditConstraintComponent,
    WorkInProgressComponent,
    Constraint2dViewComponent,
    OperationGeometryEditorComponent,
    UserMessageSummaryV2Component,
    EditUserComponent,
    OperationStateBadgeComponent,
    ConstraintStateBadgeComponent,
    ContactSelectorComponent,
    AdditionalRegistrationInputComponent,
    StringArrayInputComponent,
    UserSummaryComponent,
    RemoveUserComponent,
    LabelSelectComponent,
    ErrorContainerComponent,
    ErrorMessageComponent,
    UserSettingsComponent,
    FontSizeButtonComponent,
    EditContactComponent,
    ErrorGroupComponent,
    EditFederationPolicyComponent,
    DraftSelectorComponent,
    ConstraintGeometryEditorComponent,
    AltitudeRangeSelectorComponent,
    LatLngPointInputComponent,
    ProgressBarComponent,
    TimeRangeSelectorComponent,
    LeafletPolygonEditorComponent,
    LeafletSitrepDrawerComponent,
    LeafletPolylineDrawerComponent,
    LeafletVolumeCreatorComponent,
    SendDirectMessageModalComponent,
    CopyToClipboardComponent,
    ErrorScreenComponent,
    ManualPolygonInputComponent,
    LeafletCircleCreatorComponent,
    LeafletCircleEditorComponent,
    ManualCircleInputComponent,
    EditTelemetryIntegrationsComponent
  ],
    exports: [
        AxOperationExtSummaryComponent,
        CreateOperationComponent,
        ViewPlatformComponent,
        EditPlatformComponent,
        UserMessageTableComponent,
        UserMessageAlertBarComponent,
        EditConstraintComponent,
        WorkInProgressComponent,
        ConstraintExtSummaryComponent,
        Constraint2dViewComponent,
        OperationGeometryEditorComponent,
        AxOperationSummaryComponent,
        EditUserComponent,
        OperationStateBadgeComponent,
        ConstraintStateBadgeComponent,
        ContactSelectorComponent,
        AdditionalRegistrationInputComponent,
        StringArrayInputComponent,
        UserSummaryComponent,
        RemoveUserComponent,
        UserSettingsComponent,
        LabelSelectComponent,
        EditContactComponent,
        EditFederationPolicyComponent,
        ErrorContainerComponent,
        ErrorMessageComponent,
        LatLngPointInputComponent,
        TimeRangeSelectorComponent,
        SendDirectMessageModalComponent,
        ManualPolygonInputComponent,
        LeafletPointCreatorComponent
    ],
    imports: [
        CommonModule,
        ClarityModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        MatIconModule,
        MatDatepickerModule,
        AxAngularMapLeafletModule,
        AxAngularMapCesiumModule,
        RouterModule,
        CesiumModule,
        CdsModule,
        ClrTabsModule,
        AXLeafletModule,
        CdsToggleModule,
        PermissionsModule,
        LeafletPointCreatorComponent,
        AxAltitudeSummaryComponent,
        // AngularCesiumModule,
    ]
})
export class ComponentsModule {
}

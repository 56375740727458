import {Injectable} from '@angular/core';
import {vertical_reference} from '../model/gen/utm';
import {Observable} from 'rxjs';

export interface IAltitudeConversionParameters {
  lat: number;
  lon: number;
  altitude: number;
  input_reference: vertical_reference;
  output_reference: vertical_reference;
}

export interface TransportAltitudeConversionResponse {
  altitude: number;
  reference: string;
}

export interface AltitudeConversionResponse {
  altitude: number;
  reference: vertical_reference;
}

@Injectable()
export abstract class AltitudeService {
  abstract convertAltitude(altitudeConversionParams: IAltitudeConversionParameters): Observable<AltitudeConversionResponse>;
  async convertAltitudeAsync(altitudeConversionParams: IAltitudeConversionParameters): Promise<AltitudeConversionResponse> {
    return this.convertAltitude(altitudeConversionParams).toPromise();
  }
}

/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseModel} from './base-model';


export interface IVehicleOperationData {
  registration_id?: string;
  vehicle_type?: string;
  owner_name?: string;
  owner_contact?: string;
  n_number?: string;
  "faa_registration-number"?: string;
  uss_name?: string;
  uss_instance_id?: string;
  gufi?: string;
  operation_state?: string;
  additional_details?: string;
}


export class VehicleOperationData extends BaseModel implements IVehicleOperationData {

  static REGISTRATION_ID_FIELD_NAME = 'registration_id';
  static VEHICLE_TYPE_FIELD_NAME = 'vehicle_type';
  static OWNER_NAME_FIELD_NAME = 'owner_name';
  static OWNER_CONTACT_FIELD_NAME = 'owner_contact';
  static N_NUMBER_FIELD_NAME = 'n_number';
  static FAA_REGISTRATION_NUMBER_FIELD_NAME = 'faa_registration-number';
  static USS_NAME_FIELD_NAME = 'uss_name';
  static USS_INSTANCE_ID_FIELD_NAME = 'uss_instance_id';
  static GUFI_FIELD_NAME = 'gufi';
  static OPERATION_STATE_FIELD_NAME = 'operation_state';
  static ADDITIONAL_DETAILS_FIELD_NAME = 'additional_details';

  registration_id: string;
  vehicle_type: string;
  owner_name: string;
  owner_contact: string;
  n_number: string;
  "faa_registration-number": string;
  uss_name: string;
  uss_instance_id: string;
  gufi: string;
  operation_state: string;
  additional_details: string;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IVehicleOperationData>, useFormGroupValuesToModel = false) {
    super();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IVehicleOperationData>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.registration_id = this.getValue<string>(rawValues, VehicleOperationData.REGISTRATION_ID_FIELD_NAME);
      this.vehicle_type = this.getValue<string>(rawValues, VehicleOperationData.VEHICLE_TYPE_FIELD_NAME);
      this.owner_name = this.getValue<string>(rawValues, VehicleOperationData.OWNER_NAME_FIELD_NAME);
      this.owner_contact = this.getValue<string>(rawValues, VehicleOperationData.OWNER_CONTACT_FIELD_NAME);
      this.n_number = this.getValue<string>(rawValues, VehicleOperationData.N_NUMBER_FIELD_NAME);
      this["faa_registration-number"] = this.getValue<string>(rawValues, VehicleOperationData.FAA_REGISTRATION_NUMBER_FIELD_NAME);
      this.uss_name = this.getValue<string>(rawValues, VehicleOperationData.USS_NAME_FIELD_NAME);
      this.uss_instance_id = this.getValue<string>(rawValues, VehicleOperationData.USS_INSTANCE_ID_FIELD_NAME);
      this.gufi = this.getValue<string>(rawValues, VehicleOperationData.GUFI_FIELD_NAME);
      this.operation_state = this.getValue<string>(rawValues, VehicleOperationData.OPERATION_STATE_FIELD_NAME);
      this.additional_details = this.getValue<string>(rawValues, VehicleOperationData.ADDITIONAL_DETAILS_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        registration_id: new UntypedFormControl(this.registration_id),
        vehicle_type: new UntypedFormControl(this.vehicle_type),
        owner_name: new UntypedFormControl(this.owner_name),
        owner_contact: new UntypedFormControl(this.owner_contact, [Validators.maxLength(100),]),
        n_number: new UntypedFormControl(this.n_number, [Validators.maxLength(100),]),
        "faa_registration-number": new UntypedFormControl(this["faa_registration-number"], [Validators.maxLength(100),]),
        uss_name: new UntypedFormControl(this.uss_name, [Validators.minLength(4), Validators.maxLength(250),]),
        uss_instance_id: new UntypedFormControl(this.uss_instance_id, [Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        gufi: new UntypedFormControl(this.gufi, [Validators.minLength(36), Validators.maxLength(36), Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$'),]),
        operation_state: new UntypedFormControl(this.operation_state),
        additional_details: new UntypedFormControl(this.additional_details),
      });
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[VehicleOperationData.REGISTRATION_ID_FIELD_NAME].setValue(this.registration_id);
    this.$formGroup.controls[VehicleOperationData.VEHICLE_TYPE_FIELD_NAME].setValue(this.vehicle_type);
    this.$formGroup.controls[VehicleOperationData.OWNER_NAME_FIELD_NAME].setValue(this.owner_name);
    this.$formGroup.controls[VehicleOperationData.OWNER_CONTACT_FIELD_NAME].setValue(this.owner_contact);
    this.$formGroup.controls[VehicleOperationData.N_NUMBER_FIELD_NAME].setValue(this.n_number);
    this.$formGroup.controls[VehicleOperationData.FAA_REGISTRATION_NUMBER_FIELD_NAME].setValue(this["faa_registration-number"]);
    this.$formGroup.controls[VehicleOperationData.USS_NAME_FIELD_NAME].setValue(this.uss_name);
    this.$formGroup.controls[VehicleOperationData.USS_INSTANCE_ID_FIELD_NAME].setValue(this.uss_instance_id);
    this.$formGroup.controls[VehicleOperationData.GUFI_FIELD_NAME].setValue(this.gufi);
    this.$formGroup.controls[VehicleOperationData.OPERATION_STATE_FIELD_NAME].setValue(this.operation_state);
    this.$formGroup.controls[VehicleOperationData.ADDITIONAL_DETAILS_FIELD_NAME].setValue(this.additional_details);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

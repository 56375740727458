import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Contact} from '../model/Contact';
import {SearchResult} from '../model/SearchResult';
import {ContactResponse} from '../model/ContactResponse';

export interface IContactSearch {
  isPilot?: boolean;
  isUserContact?: boolean;
  sort?: ContactSearchSortField;
  sortIncreasing?: boolean;
}

export enum ContactSearchSortField {
  firstName = 'firstName',
  lastName = 'lastName'
}

export class ContactSearch implements IContactSearch {
  isPilot: boolean;
  isUserContact: boolean;
  sort: ContactSearchSortField;
  sortIncreasing: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public constructor() {
    // Method not implemented
  }

  public static defaultSearch(): ContactSearch {
    return new ContactSearch(
    );
  }
}

@Injectable({
  providedIn: 'root'
})
export abstract class ContactService {
  abstract getContacts(contactSearch: IContactSearch,
                       limit?: number,
                       offset?: number,
                       fetchCount?: boolean): Observable<SearchResult<Contact>>;

  abstract getContactByUserID(userId: string): Observable<ContactResponse>;
}

import {DateTime} from 'luxon';
import {BaseModel} from './base-model';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {OperationExt} from '../../utm/OperationExt';
import {ApprovalStatus} from './enums';
import {enumValidator} from './validators';

export interface IApprovalInfo {
  approvalRequestId: string;
  entityId: string;
  operatorIntentId: string;
  timeOfRequest: DateTime;
  expiresAt: DateTime;
  operation: OperationExt;
  constraintIds: string[];
  approvalStatus: ApprovalStatus;
  timeOfApproval?: DateTime;
  approvedByUserId?: string;
  approvedByUserName?: string;
  approvalMessage?: string;
}

export class ApprovalInfo extends BaseModel implements IApprovalInfo {
  static APPROVAL_REQUEST_ID_FIELD_NAME = 'approvalRequestId';
  static ENTITY_ID_FIELD_NAME = 'entityId';
  static OPERATOR_INTENT_ID_FIELD_NAME = 'operatorIntentId';
  static TIME_OF_REQUEST_FIELD_NAME = 'timeOfRequest';
  static EXPIRES_AT_FIELD_NAME = 'expiresAt';
  static OPERATION_FIELD_NAME = 'operation';
  static CONSTRAINT_IDS_FIELD_NAME = 'constraintIds';
  static APPROVAL_STATUS_FIELD_NAME = 'approvalStatus';
  static TIME_OF_APPROVAL_FIELD_NAME = 'timeOfApproval';
  static APPROVED_BY_USER_ID_FIELD_NAME = 'approvedByUserId';
  static APPROVED_BY_USER_NAME_FIELD_NAME = 'approvedByUserName';
  static APPROVAL_MESSAGE_FIELD_NAME = 'approvalMessage';

  /** Unique identifier for the approval request */
  approvalRequestId: string;

  /** ID of the operation that the approval request was made for */
  entityId: string;

  /** Intent ID of the operation that the approval request was made for */
  operatorIntentId: string;

  /** Time that the approval request was submitted */
  timeOfRequest: DateTime;

  /** Time that the approval request expires */
  expiresAt: DateTime;

  /** Operation that the approval request was submitted for */
  operation: OperationExt;

  /** List of constraint IDs that the operation overlapped with */
  constraintIds: string[];

  /** Status of the approval request */
  approvalStatus: ApprovalStatus;

  /** Time that the approval request was approved */
  timeOfApproval?: DateTime;

  /** The ID of the user that approved the request */
  approvedByUserId?: string;

  /** The username of the user that approved the request */
  approvedByUserName?: string;

  /** Optional text-based message that the reviewing user can supply when approving or denying an operation */
  approvalMessage?: string;

  constructor(values?: Partial<IApprovalInfo>, useFormGroupValuesToModel = false) {
    super();
    this.operation = new OperationExt();
    this.constraintIds = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   *
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IApprovalInfo>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.approvalRequestId = this.getValue<string>(rawValues, ApprovalInfo.APPROVAL_REQUEST_ID_FIELD_NAME);
      this.entityId = this.getValue<string>(rawValues, ApprovalInfo.ENTITY_ID_FIELD_NAME);
      this.operatorIntentId = this.getValue<string>(rawValues, ApprovalInfo.OPERATOR_INTENT_ID_FIELD_NAME);
      this.timeOfRequest = this.getValue<DateTime>(rawValues, ApprovalInfo.TIME_OF_REQUEST_FIELD_NAME);
      this.expiresAt = this.getValue<DateTime>(rawValues, ApprovalInfo.EXPIRES_AT_FIELD_NAME);
      this.operation.setValues(rawValues.operation, useFormGroupValuesToModel);
      this.fillModelArray<string>(this, ApprovalInfo.CONSTRAINT_IDS_FIELD_NAME, rawValues.constraintIds, useFormGroupValuesToModel);
      this.approvalStatus = this.getValue<ApprovalStatus>(rawValues, ApprovalInfo.APPROVAL_STATUS_FIELD_NAME);
      this.timeOfApproval = this.getValue<DateTime>(rawValues, ApprovalInfo.TIME_OF_APPROVAL_FIELD_NAME);
      this.approvedByUserId = this.getValue<string>(rawValues, ApprovalInfo.APPROVED_BY_USER_ID_FIELD_NAME);
      this.approvedByUserName = this.getValue<string>(rawValues, ApprovalInfo.APPROVED_BY_USER_NAME_FIELD_NAME);
      this.approvalMessage = this.getValue<string>(rawValues, ApprovalInfo.APPROVAL_MESSAGE_FIELD_NAME);

      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[ApprovalInfo.APPROVAL_REQUEST_ID_FIELD_NAME].setValue(this.approvalRequestId);
    this.$formGroup.controls[ApprovalInfo.ENTITY_ID_FIELD_NAME].setValue(this.entityId);
    this.$formGroup.controls[ApprovalInfo.OPERATOR_INTENT_ID_FIELD_NAME].setValue(this.operatorIntentId);
    this.$formGroup.controls[ApprovalInfo.TIME_OF_REQUEST_FIELD_NAME].setValue(this.timeOfRequest);
    this.$formGroup.controls[ApprovalInfo.EXPIRES_AT_FIELD_NAME].setValue(this.expiresAt);
    this.operation.setFormGroupValues();
    this.fillFormArray<string>(ApprovalInfo.CONSTRAINT_IDS_FIELD_NAME, this.constraintIds);
    this.$formGroup.controls[ApprovalInfo.APPROVAL_STATUS_FIELD_NAME].setValue(this.approvalStatus);
    this.$formGroup.controls[ApprovalInfo.TIME_OF_APPROVAL_FIELD_NAME].setValue(this.timeOfApproval);
    this.$formGroup.controls[ApprovalInfo.APPROVED_BY_USER_ID_FIELD_NAME].setValue(this.approvedByUserId);
    this.$formGroup.controls[ApprovalInfo.APPROVED_BY_USER_NAME_FIELD_NAME].setValue(this.approvedByUserName);
    this.$formGroup.controls[ApprovalInfo.APPROVAL_MESSAGE_FIELD_NAME].setValue(this.approvalMessage);
    super.setFormGroupValuesInAddedFormControls();
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        approvalRequestId: new UntypedFormControl(this.approvalRequestId, [Validators.required,
          Validators.minLength(36), Validators.maxLength(36),
          Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$')]),
        entityId: new UntypedFormControl(this.entityId, [Validators.required,
          Validators.minLength(36), Validators.maxLength(36),
          Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$')]),
        operatorIntentId: new UntypedFormControl(this.operatorIntentId, [Validators.required,
          Validators.minLength(36), Validators.maxLength(36),
          Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$')]),
        timeOfRequest: new UntypedFormControl(this.timeOfRequest, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24),
          Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$')]),
        expiresAt: new UntypedFormControl(this.expiresAt, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24),
          Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$')]),
        operation: this.operation.$formGroup,
        constraintIds: new UntypedFormArray([]),
        approvalStatus: new UntypedFormControl(this.approvalStatus, [Validators.required, enumValidator(ApprovalStatus)]),
        timeOfApproval: new UntypedFormControl(this.timeOfApproval, [
          Validators.minLength(24), Validators.maxLength(24),
          Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$')]),
        approvedByUserId: new UntypedFormControl(this.approvedByUserId, [Validators.minLength(36),
          Validators.maxLength(36),
          Validators.pattern('^[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-4[0-9a-fA-F]{3}\-[8-b][0-9a-fA-F]{3}\-[0-9a-fA-F]{12}$')]),
        approvedByUserName: new UntypedFormControl(this.approvedByUserName),
        approvalMessage: new UntypedFormControl(this.approvalMessage)
      });
      this.fillFormArray<string>(ApprovalInfo.CONSTRAINT_IDS_FIELD_NAME, this.constraintIds);
    }
    return this._formGroup;
  }
}

import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {OffNominalReport} from '../model/OffNominalReport';

@Injectable({
  providedIn: 'root'
})
export abstract class OffNominalReportService {
  abstract submitReport(report:OffNominalReport): Observable<boolean>;
}

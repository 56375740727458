import {Observable} from 'rxjs';
import {OperationGeometry} from '../../../../../model/operation.geometry';
import {map, shareReplay, startWith} from 'rxjs/operators';
import {Type} from '@angular/core';
import {UTMFlightPlanCustomizer} from './common';
import {UGCSUTMFlightPlan} from './formats/UGCSFlightPlan';
import {AltitudeService} from '../../../../altitude.service';
import {BufferStepCustomizerComponent} from './BufferStepCustomizer';
import {QGCSUTMFlightPlan} from "./formats/QGCSFlightPlan";
import {MissionPlannerFlightPlanCustomizer} from "./MissionPlannerFlightPlanCustomizer";
import {QGCSFlightPlanConverter} from "./formats/QGCSFlightPlanConverter";


export class QGCSFlightPlanCustomizer extends UTMFlightPlanCustomizer {

  constructor(private flightPlan: QGCSUTMFlightPlan) {
    super();

  }

  status$: Observable<boolean> = this.flightPlan.options.statusChanges.pipe(
    map(() => this.flightPlan.options.valid),
    startWith(this.flightPlan.options.valid),
    shareReplay(1)
  );


  static fromText(text: string, altitudeService: AltitudeService): QGCSFlightPlanCustomizer {
    return new QGCSFlightPlanCustomizer(QGCSUTMFlightPlan.fromText(text, altitudeService));
  }

  static fromTextToMissionPlanner(text: string): MissionPlannerFlightPlanCustomizer {
    return new MissionPlannerFlightPlanCustomizer(QGCSFlightPlanConverter.convertTextToWaypoints(text));
  }

  getConfigurationComponent(): { component: Type<any>; inputs: any } {
    return {
      component: BufferStepCustomizerComponent as Type<any>,
      inputs: {
        formGroup: this.flightPlan.options
      }
    };
  }

  toOperationGeometry(): Observable<OperationGeometry> {
    return this.flightPlan.toOperationGeometry();
  }


}

import {measureDef, unitMap} from "./base";


const current: unitMap = {
  A: {
    name: {
      singular: 'Ampere',
      plural: 'Amperes',
    },
    to_anchor: 1,
  },
  mA: {
    name: {
      singular: 'Milliampere',
      plural: 'Milliamperes',
    },
    to_anchor: 0.001,
  },
  kA: {
    name: {
      singular: 'Kiloampere',
      plural: 'Kiloamperes',
    },
    to_anchor: 1000,
  },
};
export const CurrentDef:measureDef = {
  type:"current",
  metric: current,
  _anchors: {
    metric: {
      unit: 'A',
      ratio: 1,
    },
  },
};

<form [formGroup]="form">
  <table class="ngx-mat-timepicker-table">
    <tbody class="ngx-mat-timepicker-tbody">
      <tr *ngIf="showSpinners">
        <td>
          <button type="button" mat-icon-button aria-label="expand_less icon" (click)="change('hour', true)"
            [disabled]="disabled">
            <mat-icon>expand_less</mat-icon>
          </button>
        </td>
        <td></td>
        <td>
          <button type="button" mat-icon-button aria-label="expand_less icon" (click)="change('minute', true)"
            [disabled]="disabled || disableMinute">
            <mat-icon>expand_less</mat-icon>
          </button> </td>
        <td></td>
        <td *ngIf="showSeconds">
          <button type="button" mat-icon-button aria-label="expand_less icon" (click)="change('second', true)"
            [disabled]="disabled || disableMinute">
            <mat-icon>expand_less</mat-icon>
          </button>
        </td>
        <td *ngIf="enableMeridian" class="ngx-mat-timepicker-spacer"></td>
        <td *ngIf="enableMeridian"></td>
      </tr>

      <tr>
        <td>
          <mat-form-field appearance="fill" [color]="color">
            <input type="text" matInput (input)="formatInput($any($event).target)" maxlength="2" formControlName="hour"
              (keydown.ArrowUp)="change('hour', true); $event.preventDefault()"
              (keydown.ArrowDown)="change('hour', false); $event.preventDefault()" (blur)="change('hour')">
          </mat-form-field>
        </td>
        <td class="ngx-mat-timepicker-spacer">&#58;</td>
        <td>
          <mat-form-field appearance="fill" [color]="color">
            <input type="text" matInput (input)="formatInput($any($event).target)" maxlength="2"
              formControlName="minute" (keydown.ArrowUp)="change('minute', true); $event.preventDefault()"
              (keydown.ArrowDown)="change('minute', false); $event.preventDefault()" (blur)="change('minute')">
          </mat-form-field>
        </td>
        <td *ngIf="showSeconds" class="ngx-mat-timepicker-spacer">&#58;</td>
        <td *ngIf="showSeconds">
          <mat-form-field appearance="fill" [color]="color">
            <input type="text" matInput (input)="formatInput($any($event).target)" maxlength="2"
              formControlName="second" (keydown.ArrowUp)="change('second', true); $event.preventDefault()"
              (keydown.ArrowDown)="change('second', false); $event.preventDefault()" (blur)="change('second')">
          </mat-form-field>
        </td>

        <td *ngIf="enableMeridian" class="ngx-mat-timepicker-spacer"></td>
        <td *ngIf="enableMeridian" class="ngx-mat-timepicker-meridian">
          <button mat-button (click)="toggleMeridian()" mat-stroked-button [color]="color" [disabled]="disabled">
            {{meridian}}
          </button>
        </td>
      </tr>

      <tr *ngIf="showSpinners">
        <td>
          <button type="button" mat-icon-button aria-label="expand_more icon" (click)="change('hour', false)"
            [disabled]="disabled">
            <mat-icon>expand_more</mat-icon>
          </button> </td>
        <td></td>
        <td>
          <button type="button" mat-icon-button aria-label="expand_more icon" (click)="change('minute', false)"
            [disabled]="disabled || disableMinute">
            <mat-icon>expand_more</mat-icon>
          </button> </td>
        <td *ngIf="showSeconds"></td>
        <td *ngIf="showSeconds">
          <button type="button" mat-icon-button aria-label="expand_more icon" (click)="change('second', false)"
            [disabled]="disabled || disableMinute">
            <mat-icon>expand_more</mat-icon>
          </button>
        </td>
        <td *ngIf="enableMeridian" class="ngx-mat-timepicker-spacer"></td>
        <td *ngIf="enableMeridian"></td>
      </tr>
    </tbody>
  </table>
</form>
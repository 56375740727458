import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  FlightPlanOperationVolumeImportService
} from './import/operation-volume/flightplan-import/flight-plan-operation-volume-import.service';
import {
  FlightplanOperationVolumeImportConfigComponent
} from './import/operation-volume/flightplan-import/flightplan-operation-volume-import-config/flightplan-operation-volume-import-config.component';
import {
  GeojsonOperationVolumeImportService
} from './import/operation-volume/geojson-import/geojson-operation-volume-import.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClarityModule} from '@clr/angular';
import {ImportExportServiceToken} from '../../import-export/import-export.service';
import {
  GeojsonOperationVolumeExportService
} from './export/operation-volume/geojson-export/geojson-operation-volume-export.service';
import {GeojsonConstraintExportService} from './export/constraint/geojson-export/geojson-constraint-export.service';
import {
  GeojsonConstraintVolumeImportService
} from './import/constraint/geojson-import/geojson-constraint-volume-import.service';
import {
  KmlOperationVolumeExportService
} from './export/operation-volume/kml-export/kml-operation-volume-export.service';
import {
  GeojsonVolumeImportConfigComponent
} from './import/geojson-volume-import-config/geojson-volume-import-config.component';
import {KmlImportService} from './import/kml-import/kml-import.service';
import {LeafletComponentsModule} from '@ax/ax-angular-map-leaflet';


export const importExportServiceProviders = [
  {provide: ImportExportServiceToken, useClass: GeojsonOperationVolumeImportService, multi: true},
  {provide: ImportExportServiceToken, useClass: GeojsonOperationVolumeExportService, multi: true},
  {provide: ImportExportServiceToken, useClass: KmlOperationVolumeExportService, multi: true},
  {provide: ImportExportServiceToken, useClass: GeojsonConstraintVolumeImportService, multi: true},
  {provide: ImportExportServiceToken, useClass: GeojsonConstraintExportService, multi: true},
  {provide: ImportExportServiceToken, useClass: FlightPlanOperationVolumeImportService, multi: true},
  {provide: ImportExportServiceToken, useClass: KmlImportService, multi: true}
];

@NgModule({
  declarations: [FlightplanOperationVolumeImportConfigComponent, GeojsonVolumeImportConfigComponent],
  imports: [
    CommonModule,
    FormsModule,
    ClarityModule,
    ReactiveFormsModule,
    LeafletComponentsModule
  ],
  providers: importExportServiceProviders
})
export class ImportExportServiceModule {
}

<input autocomplete="off" type="text" class="clr-input" style="color:black;" disabled="true" [value]="getContactText()">
<button type="button" (click)="openContactSelector()" class="btn btn-primary btn-sm" [disabled]="isDisabled">Select</button>
<clr-modal [(clrModalOpen)]="selectingContact" [clrModalClosable]="true" [clrModalSize]="'md'">
  <div class="modal-body" *ngIf="selectingContact">
    <clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="datagrid-compact" style="height: 100%;+
">
      <clr-dg-column></clr-dg-column>
      <clr-dg-column>Title</clr-dg-column>
      <clr-dg-column>Name</clr-dg-column>

      <clr-dg-row *ngFor="let contact of contacts" [clrDgItem]="contact">
        <clr-dg-cell>
          <button type="button" (click)="selectContact(contact)" class="btn btn-primary btn-sm">Select</button>
        </clr-dg-cell>
        <clr-dg-cell>{{contact.title}}</clr-dg-cell>
        <clr-dg-cell>{{contact.getName()}}</clr-dg-cell>
      </clr-dg-row>

      <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize"
                           [clrDgTotalItems]="totalItems"></clr-dg-pagination>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}}
        <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Contacts per page</clr-dg-page-size>
      </clr-dg-footer>
    </clr-datagrid>
  </div>
</clr-modal>

/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */

// generate enum based on strings instead of numbers
// (see https://blog.rsuter.com/how-to-implement-an-enum-with-string-values-in-typescript/)

export enum data_accessibility {
  PUBLIC = <any> 'PUBLIC',
  OPERATIONAL = <any> 'OPERATIONAL',
  SAFETY = <any> 'SAFETY',
  SPECIAL = <any> 'SPECIAL',
}

export enum severity {
  EMERGENCY = <any> 'EMERGENCY',
  ALERT = <any> 'ALERT',
  CRITICAL = <any> 'CRITICAL',
  WARNING = <any> 'WARNING',
  NOTICE = <any> 'NOTICE',
  INFORMATIONAL = <any> 'INFORMATIONAL',
}

export enum vertical_reference {
  W84 = <any> 'W84',
  MSL = <any> 'MSL',
  AGL = <any> 'AGL'
}

export enum units_of_measure {
  FT = <any> 'FT',
  M = <any> 'M',
  MI = <any> 'MI',
  NM = <any> 'NM'
}

export enum source {
  ONBOARD_SENSOR = <any> 'ONBOARD_SENSOR',
  OTHER = <any> 'OTHER',
}

export enum event_source {
  HWITL = <any> 'HWITL',
  SWITL = <any> 'SWITL',
}

export enum state {
  DRAFT = <any> 'DRAFT',
  PROPOSED = <any> 'PROPOSED',
  ACCEPTED = <any> 'ACCEPTED',
  ACTIVATED = <any> 'ACTIVATED',
  CLOSED = <any> 'CLOSED',
  NONCONFORMING = <any> 'NONCONFORMING',
  ROGUE = <any> 'ROGUE',
  REJECTED = <any> 'REJECTED',
}

export enum PendingReason {
  APPROVAL_REQUIRED = <any> 'APPROVAL_REQUIRED'
}

export enum ApprovalStatus {
  PENDING = <any> 'PENDING',
  APPROVED = <any> 'APPROVED',
  REJECTED = <any> 'REJECTED',
  EXPIRED = <any> 'EXPIRED',
  CANCELLED = <any> 'CANCELLED'
}

export enum faa_rule {
  PART_107 = <any> 'PART_107',
  PART_107X = <any> 'PART_107X',
  PART_101E = <any> 'PART_101E',
  OTHER = <any> 'OTHER',
}

export enum priority_status {
  NONE = <any> 'NONE',
  PUBLIC_SAFETY = <any> 'PUBLIC_SAFETY',
  EMERGENCY_AIRBORNE_IMPACT = <any> 'EMERGENCY_AIRBORNE_IMPACT',
  EMERGENCY_GROUND_IMPACT = <any> 'EMERGENCY_GROUND_IMPACT',
  EMERGENCY_AIR_AND_GROUND_IMPACT = <any> 'EMERGENCY_AIR_AND_GROUND_IMPACT',
}

export enum volume_type {
  TBOV = <any> 'TBOV',
  ABOV = <any> 'ABOV',
}

export enum contingency_cause {
  LOST_C2_UPLINK = <any> 'LOST_C2_UPLINK',
  LOST_C2_DOWNLINK = <any> 'LOST_C2_DOWNLINK',
  LOST_NAV = <any> 'LOST_NAV',
  LOST_SAA = <any> 'LOST_SAA',
  LOW_FUEL = <any> 'LOW_FUEL',
  NO_FUEL = <any> 'NO_FUEL',
  MECHANICAL_PROBLEM = <any> 'MECHANICAL_PROBLEM',
  SOFTWARE_PROBLEM = <any> 'SOFTWARE_PROBLEM',
  ENVIRONMENTAL = <any> 'ENVIRONMENTAL',
  SECURITY = <any> 'SECURITY',
  TRAFFIC = <any> 'TRAFFIC',
  LOST_USS = <any> 'LOST_USS',
  OTHER = <any> 'OTHER',
  ANY = <any> 'ANY',
}

export enum contingency_response {
  LANDING = <any> 'LANDING',
  LOITERING = <any> 'LOITERING',
  RETURN_TO_BASE = <any> 'RETURN_TO_BASE',
  OTHER = <any> 'OTHER',
}

export enum contingency_location_description {
  PREPROGRAMMED = <any> 'PREPROGRAMMED',
  OPERATOR_UPDATED = <any> 'OPERATOR_UPDATED',
  UA_IDENTIFIED = <any> 'UA_IDENTIFIED',
  OTHER = <any> 'OTHER',
}

export enum track_bearing_reference {
  TRUE_NORTH = <any> 'TRUE_NORTH',
  MAGNETIC_NORTH = <any> 'MAGNETIC_NORTH',
}

export enum track_bearing_uom {
  DEG = <any> 'DEG',
}

export enum track_ground_speed_units {
  KT = <any> 'KT',
}

export enum OperationActivationPolicy {
  MANUAL = <any> 'MANUAL',
  ON_OPERATION_START = <any> 'ON_OPERATION_START',
  ON_TELEMETRY_START = <any> 'ON_TELEMETRY_START',
}

export enum message_type {
  UNPLANNED_LANDING = <any> 'UNPLANNED_LANDING',
  UNCONTROLLED_LANDING = <any> 'UNCONTROLLED_LANDING',
  OPERATION_NONCONFORMING = <any> 'OPERATION_NONCONFORMING',
  OPERATION_ROGUE = <any> 'OPERATION_ROGUE',
  OPERATION_CONFORMING = <any> 'OPERATION_CONFORMING',
  OPERATION_CLOSED = <any> 'OPERATION_CLOSED',
  CONTINGENCY_PLAN_INITIATED = <any> 'CONTINGENCY_PLAN_INITIATED',
  CONTINGENCY_PLAN_CANCELLED = <any> 'CONTINGENCY_PLAN_CANCELLED',
  PERIODIC_POSITION_REPORTS_START = <any> 'PERIODIC_POSITION_REPORTS_START',
  PERIODIC_POSITION_REPORTS_END = <any> 'PERIODIC_POSITION_REPORTS_END',
  UNAUTHORIZED_AIRSPACE_PROXIMITY = <any> 'UNAUTHORIZED_AIRSPACE_PROXIMITY',
  UNAUTHORIZED_AIRSPACE_ENTRY = <any> 'UNAUTHORIZED_AIRSPACE_ENTRY',
  OTHER_SEE_FREE_TEXT = <any> 'OTHER_SEE_FREE_TEXT',
}

export enum point_type {
  Point = <any> 'Point'
}

export enum polygon_type {
  Polygon = <any> 'Polygon'
}

export enum uasvolumereservation_type {
  DYNAMIC_RESTRICTION = <any> 'DYNAMIC_RESTRICTION',
  STATIC_ADVISORY = <any> 'STATIC_ADVISORY',
}

export enum permitted_uas {
  NOT_SET = <any> 'NOT_SET',
  PUBLIC_SAFETY = <any> 'PUBLIC_SAFETY',
  SECURITY = <any> 'SECURITY',
  NEWS_GATHERING = <any> 'NEWS_GATHERING',
  VLOS = <any> 'VLOS',
  SUPPORT_LEVEL = <any> 'SUPPORT_LEVEL',
  PART_107 = <any> 'PART_107',
  PART_101E = <any> 'PART_101E',
  PART_107X = <any> 'PART_107X',
  RADIO_LINE_OF_SIGHT = <any> 'RADIO_LINE_OF_SIGHT',
  RECONNAISSANCE = <any> 'RECONNAISSANCE',
  TRAINING = <any> 'TRAINING',
  SEARCH_AND_RESCUE = <any> 'SEARCH_AND_RESCUE',
  RESUPPLY = <any> 'RESUPPLY',
  RESTRICTED = <any> 'RESTRICTED',
  ADVISORY = <any> 'ADVISORY',
  APPROVAL_REQUIRED = <any> 'APPROVAL_REQUIRED'
}

export enum required_support {
  V2V = <any> 'V2V',
  DAA = <any> 'DAA',
  ADSB_OUT = <any> 'ADSB_OUT',
  ADSB_IN = <any> 'ADSB_IN',
  CONSPICUITY = <any> 'CONSPICUITY',
  ENHANCED_NAVIGATION = <any> 'ENHANCED_NAVIGATION',
  ENHANCED_SAFE_LANDING = <any> 'ENHANCED_SAFE_LANDING',
}

export enum cause {
  WEATHER = <any> 'WEATHER',
  ATC = <any> 'ATC',
  SECURITY = <any> 'SECURITY',
  SAFETY = <any> 'SAFETY',
  MUNICIPALITY = <any> 'MUNICIPALITY',
  OTHER = <any> 'OTHER',
}

export enum replan_suggestion {
  ALTITUDE_CHANGE = <any> 'ALTITUDE_CHANGE',
  DEPART_LATER = <any> 'DEPART_LATER',
  LAND_EARLIER = <any> 'LAND_EARLIER',
  HORIZONTAL_CHANGE = <any> 'HORIZONTAL_CHANGE',
}

export enum test_type {
  GROUND = <any> 'GROUND',
  FLIGHT = <any> 'FLIGHT',
}

export enum setting {
  LAB = <any> 'LAB',
  FIELD = <any> 'FIELD',
}


export enum negotiation_agreement {
  INTERSECTION = <any> 'INTERSECTION',
  REPLAN = <any> 'REPLAN',
}

export enum TelemetryStatusEnum {
  ACTIVE = <any> 'ACTIVE',
  INACTIVE = <any> 'INACTIVE',
  ERROR = <any> 'ERROR',
  INVALID = <any> 'INVALID'
}

export enum GeometryTypeEnum {
  POLYGON = <any> 'POLYGON',
  CIRCLE = <any> 'CIRCLE',
}

/**
 * bundle of all enums for databinding to options, radio-buttons etc.
 * usage in component:
 *   import { AllEnums, minValueValidator, maxValueValidator } from '../../models/webapi';
 *
 *   @Component({
 *       ...
 *   })
 *   export class xxxComponent implements OnInit {
 *       allEnums = AllEnums;
 *       ...
 *       ngOnInit() {
 *           this.allEnums = AllEnums.instance;
 *       }
 *   }
 */
export class AllEnums {
  private static _instance: AllEnums = new AllEnums();

  constructor() {
    if (AllEnums._instance) {
      throw new Error('Error: Instantiation failed: Use AllEnums.instance instead of new');
    }
    AllEnums._instance = this;
  }

  static get instance(): AllEnums {
    return AllEnums._instance;
  }

  data_accessibility = data_accessibility;
  severity = severity;
  vertical_reference = vertical_reference;
  units_of_measure = units_of_measure;
  source = source;
  state = state;
  faa_rule = faa_rule;
  priority_status = priority_status;
  volume_type = volume_type;
  contingency_cause = contingency_cause;
  contingency_response = contingency_response;
  contingency_location_description = contingency_location_description;
  track_bearing_reference = track_bearing_reference;
  track_bearing_uom = track_bearing_uom;
  track_ground_speed_units = track_ground_speed_units;
  message_type = message_type;
  uasvolumereservation_type = uasvolumereservation_type;
  permitted_uas = permitted_uas;
  required_support = required_support;
  cause = cause;
  replan_suggestion = replan_suggestion;
  test_type = test_type;
  setting = setting;
  point_type = point_type;
  polygon_type = polygon_type;
  negotiation_agreement = negotiation_agreement;
}

/**
 * union type of all enums.
 * Useful for typing params and variables in generic components.
 */
export type AllEnumsType =
  | data_accessibility
  | severity
  | vertical_reference
  | units_of_measure
  | source
  | state
  | faa_rule
  | priority_status
  | volume_type
  | contingency_cause
  | contingency_response
  | contingency_location_description
  | track_bearing_reference
  | track_bearing_uom
  | track_ground_speed_units
  | message_type
  | uasvolumereservation_type
  | permitted_uas
  | required_support
  | cause
  | replan_suggestion
  | test_type
  | setting
  | point_type
  | polygon_type
  ;

import {Directive, TemplateRef} from '@angular/core';

@Directive({
  selector: '[libSafireButtonContent]'
})
export class SafireButtonContentDirective {

  constructor(public template: TemplateRef<any>) {
  }

}

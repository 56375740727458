import {integer} from '../DataTypes';

export enum Protocol {
  UDP = 'UDP',
  TCP = 'TCP',
  INVALID = 'INVALID'
}

export interface TransportMavlinkTelemetryIntegration {
  protocol: TransportMavlinkTelemetryIntegration.Protocol;
  port: integer;
}

export interface IMavlinkTelemetryIntegration {
  protocol: Protocol;
  port: integer;
}

/**
 * Mavlink integration
 */
export class MavlinkTelemetryIntegration implements IMavlinkTelemetryIntegration {
  protocol: Protocol;
  port: integer;

  constructor(values: MavlinkTelemetryIntegration) {
    this.protocol = values.protocol;
    this.port = values.port;
  }
}

export namespace TransportMavlinkTelemetryIntegration {
  export type Protocol = 'UDP' | 'TCP';
  export const Protocol = {
    UDP: 'UDP' as Protocol,
    TCP: 'TCP' as Protocol
  }
}

<form [formGroup]="fg">
  <button class="btn btn-primary" [disabled]="isDisabled" (click)="initDomain()">Add Domain</button>
  <clr-datagrid>
    <clr-dg-column>ID</clr-dg-column>
    <clr-dg-column>Name</clr-dg-column>

    <clr-dg-row *clrDgItems="let domainForm of domainFgArray; let i = index" [clrDgItem]="domainForm">
      <clr-dg-action-overflow>
        <button class="action-item" (click)="onDelete(i)">Delete</button>
      </clr-dg-action-overflow>
      <ng-container [formGroup]="domainForm">
        <clr-dg-cell>
          <cds-input control-width="shrink">
            <label></label>
            <input autocomplete="off" placeholder="ID" formControlName="id" />
          </cds-input>
        </clr-dg-cell>
        <clr-dg-cell>
          <cds-input control-width="shrink">
            <label></label>
            <input autocomplete="off" placeholder="Name" formControlName="prettyName" />
          </cds-input>
        </clr-dg-cell>
      </ng-container>
    </clr-dg-row>

    <clr-dg-footer>
      <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize"
                         [clrDgTotalItems]="domainFgArray.length"></clr-dg-pagination>
      {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{domainFgArray.length}}
      <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Domains per page</clr-dg-page-size>
    </clr-dg-footer>
  </clr-datagrid>
</form>

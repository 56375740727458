import * as L from 'leaflet';


export const blueIcon = new L.Icon({
  iconUrl: 'assets/markers/marker-icon-2x-blue.png',
  shadowUrl: 'assets/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const goldIcon = new L.Icon({
  iconUrl: 'assets/markers/marker-icon-2x-gold.png',
  shadowUrl: 'assets/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const redIcon = new L.Icon({
  iconUrl: 'assets/markers/marker-icon-2x-red.png',
  shadowUrl: 'assets/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const greenIcon = new L.Icon({
  iconUrl: 'assets/markers/marker-icon-2x-green.png',
  shadowUrl: 'assets/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const orangeIcon = new L.Icon({
  iconUrl: 'assets/markers/marker-icon-2x-orange.png',
  shadowUrl: 'assets/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const yellowIcon = new L.Icon({
  iconUrl: 'assets/markers/marker-icon-2x-yellow.png',
  shadowUrl: 'assets/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const violetIcon = new L.Icon({
  iconUrl: 'assets/markers/marker-icon-2x-violet.png',
  shadowUrl: 'assets/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const greyIcon = new L.Icon({
  iconUrl: 'assets/markers/marker-icon-2x-grey.png',
  shadowUrl: 'assets/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const blackIcon = new L.Icon({
  iconUrl: 'assets/markers/marker-icon-2x-black.png',
  shadowUrl: 'assets/markers/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

export const controllerIcon = new L.Icon({
  iconUrl: 'assets/markers/controller-marker.png',
  shadowUrl: 'assets/markers/marker-shadow.png',
  iconSize: [50, 50],
  iconAnchor: [25, 50],
  popupAnchor: [1, -34],
  shadowSize: [50, 50],
  shadowAnchor: [15, 50]
});

export const takeOffIcon = new L.Icon({
  iconUrl: 'assets/markers/take-off-marker.png',
  shadowUrl: 'assets/markers/marker-shadow.png',
  iconSize: [50, 50],
  iconAnchor: [25, 50],
  popupAnchor: [1, -34],
  shadowSize: [50, 50],
  shadowAnchor: [15, 50]
});

import { Injectable } from '@angular/core';


export interface ComponentCanDeactivate {
  canDeactivate: () => boolean;
}

@Injectable()
export class ChangesGuard  {
  canDeactivate(component: ComponentCanDeactivate): boolean {
    return component.canDeactivate() ?
      true :
      confirm('Are you sure you want to leave this page? Your changes will be lost.');
  }
}

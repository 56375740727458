import {Observable} from 'rxjs';
import {Event} from '@cesium/engine';

export const toObservable = (e: Event): Observable<any[]> => {
  return new Observable(observer => {
    const removeCallback = e.addEventListener((...args) => {
      observer.next(args);
    });
    return () => {
      removeCallback();
    };
  });
};

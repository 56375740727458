import {IPolygon} from '../../utm';
import {TransportGeoCircle} from "./circle";

/**
 * UTM models
 * This file contains the core UTM models as OpenAPI 2.0 compliant definitions. Some key caveats when using/referencing
 * this API:
 *  1. The API on its own is insufficient to develop a USS or other UTM component. For example, the USS Specification is
 *      needed to understand how and when to use the various endpoints and provides more details on security features
 *      that cannot be captured in the API docs.
 *  2. There is not a unified time synchronization approach in UTM currently. As such, times provided from one server
 *      MUST NOT be validated against each other. For example, if a sending server states that a data element was sent
 *      at 10:00:23, but the receiving server thinks it is currently 10:00:22, the receiving server must not reject the
 *      data submission. As we develop and roll out the time synchronization scheme for the USS Network, we will add
 *      appropriate validation requirements to the API documentation.
 *  3. On the contrary, times must be validated for consistency within a given data submission. For example, if a server
 *      states that it sends a data instance at 10:00:23 and one of the other data elements states that a measurement
 *      was performed at 10:00:24, then the receiving server must reject that data submission. Some of these checks are
 *      explictly stated in the descriptions below. If you recognize any such checks that are not documented, please
 *      submit an issue report on github.
 *  4. These token SUBJECT validations are required for security. For PUT Operation to modify an existing operation, the
 *      token SUBJECT claim upon modification MUST equal the token SUBJECT claim for the initial Operation. For PUT
 *      Message associated with an Operation gufi, the token SUBJECT claim used for PUT Message MUST equal the token
 *      SUBJECT claim for the initial operation. This check is performed if the initial operation exists, so as to not
 *      preclude receiving emergency messages. For PUT Position associated with an Operation gufi, the token SUBJECT
 *      claim used for PUT Position MUST equal the token SUBJECT claim for the initial operation. This check is
 *      performed only if the initial operation exists, so as to not preclude receiving emergency position reports.
 *
 * The version of the OpenAPI document: v4
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface IUvrVolumeSubmission {
  /**
   * Earliest time the constraint will use this constraint volume. It must be less than effectiveTimeEnd.
   * The following MUST hold: effectiveTimeBegin < effectiveTimeEnd MUST be true.
   */
  effective_time_begin?: string;

  /**
   * Latest time the constraint will use this constraint volume. It must be greater than effectiveTimeBegin.
   * The following MUST hold: effectiveTimeBegin < effectiveTimeEnd MUST be true.
   */
  effective_time_end: string;

  /**
   * The numeric value of the minimum altitude for this constraint volume.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: min_altitude < max_altitude
   */
  min_altitude: number;

  /**
   * The numeric value of the maximum altitude for this constraint volume.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: min_altitude < max_altitude
   */
  max_altitude: number;

  // A code indicating the reference for a vertical distance. See AIXM 5.1 and FIXM 4.1.0.
  // Currently, UTM only allows WGS84 with no immediate plans to allow other options. FIXM and AIXM allow for 'SFC'
  // which is equivalent to AGL.
  //
  // altitude_vertical_reference: IAltitude.VerticalReferenceEnum;

  // The reference quantities used to express the value of altitude. See FIXM 4.1.
  // Currently, UTM only allows feet with no immediate plans to allow other options.  FIXM allows for feet or meters.
  //
  // altitude_units: IAltitude.UnitsOfMeasureEnum;

  geography: IPolygon;

  circle: TransportGeoCircle;
}

export namespace IUvrVolumeSubmission {
  export type SourceEnum = 'ONBOARD_SENSOR' | 'OTHER';
  export const SourceEnum = {
    ONBOARDSENSOR: 'ONBOARD_SENSOR' as SourceEnum,
    OTHER: 'OTHER' as SourceEnum
  };
}



import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormBuilder} from '@angular/forms';
import {ClrDatagridFilter, ClrDatagridFilterInterface} from '@clr/angular';
import {Subject} from 'rxjs';
import {ConstraintState} from '../../../../../shared/model/utm/UvrExt';
import {IFormBuilder, IFormGroup} from '@rxweb/types';

@Component({
  selector: 'app-constraint-states-filter',
  templateUrl: './constraint-states-filter.component.html',
  styleUrls: ['./constraint-states-filter.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => ConstraintStatesFilterComponent),
  }]
})
export class ConstraintStatesFilterComponent implements ClrDatagridFilterInterface<any>, ControlValueAccessor {
  fg: IFormGroup<any>;
  formBuilder: IFormBuilder;
  changes = new Subject<any>();
  active = false;
  stateOptions: ConstraintState[] = [];
  states: { [key: string]: boolean } = {};

  private onChange: any;

  constructor(private filterContainer: ClrDatagridFilter, formBuilder: UntypedFormBuilder) {
    this.formBuilder = formBuilder;
    this.stateOptions = [
      ConstraintState.ACCEPTED,
      ConstraintState.ACTIVE,
      ConstraintState.ENDED
    ];
    this.fg = this.formBuilder.group<any>({
      /* eslint-disable @typescript-eslint/naming-convention */
      state_ACCEPTED: [false],
      state_ACTIVE: [false],
      state_ENDED: [false]
      /* eslint-enable @typescript-eslint/naming-convention */
    });

    this.fg.valueChanges.subscribe(() => {
      const rawValues = this.fg.getRawValue();
      const states: ConstraintState[] = [];

      Object.keys(rawValues).forEach(k => {
        const s = k.replace('state_', '') as ConstraintState;
        if (rawValues[k]) {
          states.push(s);
        }
      });

      // If only one checkbox is checked, disable it
      if (states.length === 1) {
        this.fg.controls['state_' + states[0]].disable({emitEvent: false});
      } else {
        // Otherwise, all controls should be enabled
        Object.keys(this.fg.controls).forEach(key => {
          this.fg.controls[key].enable({emitEvent: false});
        });
      }

      if (this.onChange) {
        this.onChange(states);
      }
    });
  }

  isActive(): boolean {
    return !!this.active;
  }

  accepts(val: any): boolean {
    return true;
  }

  getPrettyConstraintStateName(s: string) {
    const names = {
      /* eslint-disable @typescript-eslint/naming-convention */
      ACCEPTED: 'Accepted',
      ACTIVE: 'Active',
      ENDED: 'Closed'
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    return names[s];
  }

  toggleSelectAll() {
    // This only works one way (enable). Disabling all checkboxes is prevented by the
    // minimum requirement of one box being checked at any given time.
    this.fg.setValue({
      /* eslint-disable @typescript-eslint/naming-convention */
      state_ACCEPTED: true,
      state_ACTIVE: true,
      state_ENDED: true
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  writeValue(obj: ConstraintState[]): void {
    this.states = {
      /* eslint-disable @typescript-eslint/naming-convention */
      ACCEPTED: false,
      ACTIVE: false,
      ENDED: false
      /* eslint-enable @typescript-eslint/naming-convention */
    };
    let control: string;
    this.fg.reset({
      /* eslint-disable @typescript-eslint/naming-convention */
      state_ACCEPTED: false,
      state_ACTIVE: false,
      state_ENDED: false
      /* eslint-enable @typescript-eslint/naming-convention */
    }, {emitEvent: false, onlySelf: true});
    for (const s of obj){
      control = 'state_' + s;
      this.fg.controls[control].setValue(true, {emitEvent: false, onlySelf: true});
      this.states[s] = true;
    }
  }
}

import {Injectable} from '@angular/core';
import {ConstraintService, IConstraintSearchParameters} from '../constraint.service';
import {Observable} from 'rxjs';
import {SearchResult} from '../../model/SearchResult';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {filter, map, share, switchMap} from 'rxjs/operators';
import {Parser} from '../../model/utm/parser/OperationParser';
import {SwaggerUvrExt, UvrExt} from '../../model/utm/UvrExt';
import {ConstraintSubmission, TransportConstraintSubmission} from '../../model/ConstraintSubmission';
import {UTMSerializer} from '../../model/UTMSerializer';
import {StompService} from '../stomp.service';
import {RxStomp} from '@stomp/rx-stomp';
import {IMessage} from '@stomp/stompjs/esm6';
import {Volume4dQueryUtil} from '../../models/Volume4dQuery';
import {compactObject} from '../../utils/compact';

export interface ConstraintResponse {
  intentId: string;
  constraintId?: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class RestConstraintService extends ConstraintService {
  baseConstraintsUrl = `${environment.baseUrl}/operator/v2/uvrs/search`;
  baseMakeConstraintUrl = `${environment.baseUrl}/operator/v2/uvrs`;
  baseGetConstraintUrl = `${environment.baseUrl}/operator/v2/uvrs/`;
  private watchObservable: Observable<UvrExt>;


  constructor(private stompService: StompService, private http: HttpClient) {
    super();
  }

  getConstraints(constraintSearchParameters: IConstraintSearchParameters): Observable<SearchResult<UvrExt>> {
    if (constraintSearchParameters.sortIncreasing === undefined) {
      console.error('sorting shouldn\'t be undefined');
    }
    /* eslint-disable no-underscore-dangle */
    /* tslint:disable:variable-name */
    const _body = this.serializeConstraintSearchParameters(constraintSearchParameters);
    const body = compactObject(JSON.parse(JSON.stringify(_body)));
    /* eslint-enable no-underscore-dangle */
    /* tslint:enable:variable-name */
    return this.http.post(this.baseConstraintsUrl, body)
      .pipe(map((response: any) => new SearchResult((response.constraints || [])
          .map(Parser.parseUASVolumeReservationExt), response.count, response.offset)));
  }

  getConstraint(constraintId: string): Observable<UvrExt> {
    return this.http.get(this.baseGetConstraintUrl + constraintId)
      .pipe(map((res: SwaggerUvrExt) => Parser.parseUASVolumeReservationExt(res)));
  }

  updateConstraint(constraintId: string, constraint: UvrExt): Observable<boolean> {
    return undefined;
  }

  submitConstraint(constraint: ConstraintSubmission): Observable<ConstraintResponse> {
    const transport: TransportConstraintSubmission = UTMSerializer.serializeConstraintSubmission(constraint);
    return this.http.post(this.baseMakeConstraintUrl, transport).pipe(map(Parser.parseConstraintResponse));
  }

  closeConstraint(constraintToClose: UvrExt): Observable<boolean> {
    return this.http.post(`${environment.baseUrl}/operator/v2/uvrs/${constraintToClose.message_id}/cancel`, {})
      .pipe(map(() => true));
  }

  watchConstraints(): Observable<UvrExt> {
    if (!this.watchObservable) {
      this.watchObservable = this.stompService.getStompClient()
        .pipe(switchMap((stomp: RxStomp) => stomp.watch(`/exchange/utm_topic/uvr.#`)
          .pipe(map((message: IMessage) =>
          // return JSON.parse(message as any) as Position;
          // return JSON.parse(message as any) as Position;
           Parser.parseUASVolumeReservationExt(JSON.parse(message.body))
        ), share())));
    }
    return this.watchObservable;
  }

  watchConstraint(constraintId: string): Observable<UvrExt> {
    return this.watchConstraints().pipe(filter((constraint: UvrExt) => constraint.message_id === constraintId));
  }

  private serializeConstraintSearchParameters(constraintSearchParameters: IConstraintSearchParameters) {
    return {
      volume4d: Volume4dQueryUtil.serializeVolume4DQuery(constraintSearchParameters.volume4d),
      submitTimeAfter: constraintSearchParameters.submitTimeAfter?.toUTC()?.toISO(),
      submitTimeBefore: constraintSearchParameters.submitTimeBefore?.toUTC()?.toISO(),
      state: constraintSearchParameters.state,
      sort: constraintSearchParameters.sort,
      sortIncreasing: constraintSearchParameters.sortIncreasing,
      limit: constraintSearchParameters.limit,
      offset: constraintSearchParameters.offset,
      fetchCount: constraintSearchParameters.fetchCount,
      reason: constraintSearchParameters.reason,
      /* eslint-disable @typescript-eslint/naming-convention */
      /* tslint:disable:variable-name */
      constraint_type: constraintSearchParameters.constraint_type,
      uss_name: constraintSearchParameters.uss_name
      /* tslint:enable:variable-name */
      /* eslint-enable @typescript-eslint/naming-convention */
    };
  }
}

import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {EntityVolume4d} from '../../model/gen/utm';
import {CesiumService} from '@ax/ax-angular-map-cesium';
import {ColorService} from '../../services/color.service';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {CustomDataSource, Entity, Event} from '@cesium/engine';
import {CesiumDrawerUtilService} from '../common/cesium-drawer-util.service';
import {Viewer} from '@cesium/widgets';

@Component({
  selector: 'app-constraint-volume-drawer',
  template: ''
})
export class ConstraintVolumeDrawerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() volumes: EntityVolume4d[] = [];

  private viewer: Viewer;
  private dataSource: CustomDataSource = new CustomDataSource('constraint-volume-drawer');
  private terrainTileListenerRemove: Event.RemoveCallback;
  private entityObservable: Observable<Entity[]>;
  private volumeSubject = new BehaviorSubject<EntityVolume4d[]>([]);
  private drawSub: Subscription;

  constructor(private drawerService: CesiumService,
              private cesiumDrawerUtilService: CesiumDrawerUtilService,
              private colorService: ColorService) {
  }

  ngOnInit(): void {
    this.entityObservable = combineLatest([
      this.volumeSubject,
      this.colorService.getColorForId('constraint', true)
    ]).pipe(switchMap(([volumes, colorConfig]) => {
      const ret: Observable<Entity>[] = [];

      for (const vol of volumes) {
        const entity = this.cesiumDrawerUtilService.getConvertedConstraintVolumeEntity(vol, colorConfig);
        ret.push(entity);
      }

      return combineLatest(ret);
    }));

    this.drawSub = combineLatest([
      this.drawerService.watchViewerInit(),
      this.entityObservable
    ]).subscribe(([viewer, entities]) => {
      this.viewer = viewer;
      viewer.dataSources.remove(this.dataSource);
      this.dataSource.entities.removeAll();
      entities.forEach(entity => this.dataSource.entities.add(entity));
      viewer.dataSources.add(this.dataSource).then(() => {
        if (viewer.scene.globe.tilesLoaded) {
          viewer.zoomTo(this.dataSource).then(() => {});
        } else if (!this.terrainTileListenerRemove){
          this.terrainTileListenerRemove = viewer.scene.globe.tileLoadProgressEvent.addEventListener(() => {
            if (viewer.scene.globe.tilesLoaded){
              viewer.zoomTo(this.dataSource).then(() => {});
              this.terrainTileListenerRemove();
            }
          });
        }
      });
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.volumeSubject.next(this.volumes);
  }

  ngOnDestroy(): void {
    this.drawSub?.unsubscribe();
    this.viewer?.dataSources.remove(this.dataSource);
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApprovalInfo} from '../../../../shared/model/gen/utm/approval-info';
import {IUserMessageSearch, UserMessageSearch} from '../../../../shared/services/user-message.service';
import {ClrDatagridStateInterface} from '@clr/angular';
import {BehaviorSubject, combineLatest, EMPTY, Subscription, timer} from 'rxjs';
import {ApprovalService} from '../../../../shared/services/approval.service';
import {ApprovalStatus} from '../../../../shared/model/gen/utm';
import {SearchResult} from '../../../../shared/model/SearchResult';
import {AdminUserService} from '../../../../shared/services/admin/admin-user.service';
import {User} from '../../../../shared/model/User';
import {HttpErrorResponse} from '@angular/common/http';
import {UserResponse} from '../../../../shared/model/UserResponse';
import {catchError, exhaustMap} from 'rxjs/operators';
import * as _ from 'lodash';
import {UvrExt} from '../../../../shared/model/utm/UvrExt';
import {ConstraintService} from '../../../../shared/services/constraint.service';
import {SceneMode} from '@cesium/engine';

@Component({
  selector: 'app-closed-approvals-datagrid',
  templateUrl: './closed-approvals-datagrid.component.html',
  styleUrls: ['./closed-approvals-datagrid.component.scss']
})
export class ClosedApprovalsDatagridComponent implements OnInit, OnDestroy {
  totalItems: number;
  currentPageSize = 10;
  loading = true;
  approvalRequests: ApprovalInfo[];
  searchConfig: IUserMessageSearch = UserMessageSearch.defaultSearch();
  selectedApproval: ApprovalInfo;
  showApprovalDetails = false;
  selectedUser: User;
  showUserDetails = false;
  getUserErrorMsg: string;
  view2D = SceneMode.SCENE2D;
  constraintCache: { [key: string]: UvrExt } = {};

  private previousState: ClrDatagridStateInterface;
  private state: ClrDatagridStateInterface<any>;
  private stateSubject: BehaviorSubject<ClrDatagridStateInterface> = new BehaviorSubject<ClrDatagridStateInterface>(null);
  private approvalsSub: Subscription;
  private userSub: Subscription;
  private constraintCacheSubscriptions: { [key: string]: Subscription } = {};

  constructor(private approvalService: ApprovalService,
              private userService: AdminUserService,
              private constraintService: ConstraintService) {
    // Constructor intentionally left empty
  }

  ngOnInit(): void {
    this.approvalsSub = combineLatest([timer(100, 5000), this.stateSubject])
      .pipe(exhaustMap(([iterator, statey]) => {
        // this.loading = true;
        const from = statey.page.from === -1 ? 0 : statey.page.from;
        return this.approvalService.getOperationApprovals([ApprovalStatus.APPROVED, ApprovalStatus.REJECTED,
          ApprovalStatus.EXPIRED, ApprovalStatus.CANCELLED], statey.page.size, from, true)
          .pipe(catchError(() => (EMPTY)))
      })).subscribe((results: SearchResult<ApprovalInfo>) => {
        this.totalItems = results.total;
        this.loading = false;
        if (!_.isEqual(this.approvalRequests, results.results)) {
          this.approvalRequests = results.results;
        }
      });
  }

  refresh(statey?: ClrDatagridStateInterface, forceRecount: boolean = false) {
    this.loading = true;
    if (!statey) {
      statey = this.state || {};
    }

    this.state = statey;

    if (!statey.page) {
      statey.page = {
        from: 0,
        to: 9,
        size: 10,
      };
    }

    this.stateSubject.next(statey);
  }

  viewApprovalDetails(approval: ApprovalInfo) {
    approval.constraintIds.forEach(id => this.cacheConstraint(id));
    this.selectedApproval = approval;
    this.showApprovalDetails = true;
  }

  cacheConstraint(constraintId: string) {
    if (constraintId in this.constraintCache) {
      return;
    }
    this.constraintCacheSubscriptions[constraintId] = this.constraintService.getConstraint(constraintId)
      .subscribe(constraint => {
        this.constraintCache[constraintId] = constraint;
        delete this.constraintCacheSubscriptions[constraintId];
      });
  }

  viewUser(userId: string) {
    this.userSub?.unsubscribe();
    this.selectedUser = null;
    this.userSub = this.userService.getUser(userId).subscribe((res: UserResponse) => {
      this.selectedUser = res.user;
      this.showUserDetails = true;
    }, (error: HttpErrorResponse) => {
      this.showUserDetails = true;
      this.getUserErrorMsg = error?.error?.message || 'Unknown error retrieving user';
    });
  }

  ngOnDestroy(): void {
    this.approvalsSub?.unsubscribe();
    this.userSub?.unsubscribe();
    Object.values(this.constraintCacheSubscriptions).forEach(sub => sub.unsubscribe());
  }
}

import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ClarityModule} from '@clr/angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {HomeComponent} from './home/home.component';
import {SharedModule} from './shared/shared.module';
import {MessagesComponent} from './messages/messages.component';
import {PermissionsModule} from './shared/permissions/permissions.module';
import {SettingsComponent} from './settings/settings.component';
import {AxAngularMapAdminModule} from '@ax/ax-angular-map-admin';
import {CdsModule} from '@cds/angular';
import {ServicesModule} from './shared/services/services.module';
import {LeafletComponentsModule} from '@ax/ax-angular-map-leaflet';
import {TestyComponent} from './home/testy/testy.component';
import {LeafletModule as AXLeafletModule} from './shared/leaflet/leaflet.module';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import { EditFederationDomainListComponent } from './settings/edit-federation-domain-list/edit-federation-domain-list.component';
import {ChangesGuard} from './guards/changes-guard/changes-guard.guard';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    MessagesComponent,
    SettingsComponent,
    TestyComponent,
    EditFederationDomainListComponent,
  ],
  imports: [
    LeafletComponentsModule,
    BrowserModule,
    AppRoutingModule,
    ClarityModule,
    CdsModule,
    BrowserAnimationsModule,
    CommonModule,
    RouterModule,
    FormsModule,
    SharedModule,
    ServicesModule.forRoot(),

    PermissionsModule,
    AxAngularMapAdminModule,
    ReactiveFormsModule,
    AXLeafletModule,

  ],
  providers: [
    ChangesGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}

<div class="clr-row clr-flex-nowrap clr-align-items-end datetime-wrapper" [ngClass]="{'picker-disabled': disabled}">
  <clr-input-container>
    <label [ngClass]="{'required-custom': required}">{{label}}</label>
    <input clrInput [ngxMatDatetimePicker]="picker" autocomplete="off" [ngModel]="internalValue"
           (ngModelChange)="onValueChange($event)" [disabled]="disabled" (click)="touched()"
           placeholder="{{placeholder()}}">
  </clr-input-container>
  <ngx-mat-datepicker-toggle matSuffix [for]="picker" (click)="touched()"></ngx-mat-datepicker-toggle>
  <cds-icon-button *ngIf="value" [action]="'flat'" [status]="'neutral'" [disabled]="disabled" (click)="clearDateTime()"
                   class="btn-clear-field" title="Clear selected date and time">
    <cds-icon shape="times"></cds-icon>
  </cds-icon-button>

  <ngx-mat-datetime-picker #picker [showSpinners]="true" [showSeconds]="true" [hideTime]="false">
    <ngx-mat-datepicker-actions>
      <cds-icon-button ngxMatDatepickerApply [action]="'outline'" aria-label="accept">
        <cds-icon shape="check"></cds-icon>
      </cds-icon-button>
    </ngx-mat-datepicker-actions>
  </ngx-mat-datetime-picker>
</div>

import {Altitude, EntityVolume4d, OperationVolume, units_of_measure, vertical_reference} from '../../model/gen/utm';
import {ColorConfig} from '../../services/color.service';
import {
  Cartesian3,
  Color,
  ColorMaterialProperty,
  ConstantProperty,
  Entity,
  PolygonGraphics,
  PolygonHierarchy
} from '@cesium/engine';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {AltitudeUtilService} from '../../model/utm/altitude-util.service';
import {map} from 'rxjs/operators';
import tinycolor from "tinycolor2";
import {LatLng} from "leaflet";
import {MeasurementsUtil} from "../../utils/MeasurementsUtil";

@Injectable({
  providedIn: 'root'
})
export class CesiumDrawerUtilService {
  constructor(private altitudeUtilService: AltitudeUtilService) {
  }

  /**
   * Converts an operation volume to a Cesium Entity
   * Note: Units must be converted to the values expected by Cesium prior to calling this method. To return an entity
   * with converted units, use the getConvertedOperationVolumeEntity method.
   * @param volume The operation volume to convert to a Cesium Entity
   * @param colorConfig The color configuration to use for the entity
   */
  getOperationVolumeEntity(volume: OperationVolume, colorConfig: ColorConfig): Entity {
    const fill = colorConfig.fill.toRgb();
    const outline = (colorConfig.outline ? colorConfig.outline : tinycolor('black')).toRgb();

    if (volume.circle) {
      const length = volume.max_altitude.altitude_value - volume.min_altitude.altitude_value;
      return new Entity({
        name: `Operation Volume`,
        position: Cartesian3.fromDegrees(volume.circle.longitude, volume.circle.latitude, (volume.min_altitude.altitude_value + (length / 2))),
        cylinder: {
          bottomRadius: volume.circle.radiusMeters,
          topRadius: volume.circle.radiusMeters,
          material: new ColorMaterialProperty(new Color(fill.r / 255, fill.g / 255, fill.b / 255, fill.a)),
          outline: true,
          outlineWidth: 2,
          outlineColor: new Color(outline.r / 255, outline.g / 255, outline.b / 255, outline.a),
          length: new ConstantProperty(length),
        }
      });
    } else {
      const points: number[] = [];
      volume.geography.coordinates[0].forEach(point => {
        points.push(...point);
      });

      return new Entity({
        name: `Operation Volume`,
        polygon: new PolygonGraphics({
          hierarchy: new PolygonHierarchy(Cartesian3.fromDegreesArray(points), []),
          height: new ConstantProperty(volume.min_altitude.altitude_value),
          extrudedHeight: new ConstantProperty(volume.max_altitude.altitude_value),
          material: new ColorMaterialProperty(new Color(fill.r / 255, fill.g / 255, fill.b / 255, fill.a)),
          outline: true,
          outlineWidth: 2,
          outlineColor: new Color(outline.r / 255, outline.g / 255, outline.b / 255, outline.a),
        })
      });
    }
  }

  /**
    * Converts an operation volume to a Cesium Entity and performs the necessary conversions to the expected units.
    * @param volume The operation volume to convert to a Cesium Entity
    * @param colorConfig The color configuration to use for the entity
    */
  getConvertedOperationVolumeEntity(volume: OperationVolume, colorConfig: ColorConfig): Observable<Entity> {
    const coordinates = volume.circle ? new LatLng(volume.circle.latitude, volume.circle.longitude) :
      new LatLng(volume.geography.coordinates[0][0][1], volume.geography.coordinates[0][0][0]);

    // Convert altitudes to WGS84 & meters
    const maxOffset = MeasurementsUtil.convertUnits(volume.min_altitude.units_of_measure, units_of_measure.M,
      (volume.max_altitude.altitude_value - volume.min_altitude.altitude_value));
    return this.altitudeUtilService.convertToWGS84Meters(volume.min_altitude, coordinates)
      .pipe(map((minAlt) => {
        const tmpVolume = new OperationVolume(volume);
        tmpVolume.min_altitude = minAlt;
        tmpVolume.max_altitude = new Altitude({
          altitude_value: minAlt.altitude_value + maxOffset,
          units_of_measure: units_of_measure.M,
          vertical_reference: vertical_reference.W84
        });

        return this.getOperationVolumeEntity(tmpVolume, colorConfig);
    }));
  }

  /**
   * Converts a constraint volume to a Cesium Entity
   * Note: Units must be converted to the values expected by Cesium prior to calling this method. To return an entity
   * with converted units, use the getConvertedConstraintVolumeEntity method.
   * @param volume The constraint volume to convert to a Cesium Entity
   * @param colorConfig The color configuration to use for the entity
   */
  getConstraintVolumeEntity(volume: EntityVolume4d, colorConfig: ColorConfig): Entity {
    const fill = colorConfig.fill.toRgb();
    const outline = (colorConfig.outline ? colorConfig.outline : tinycolor('black')).toRgb();

    if (volume.circle) {
      const length = volume.max_altitude.altitude_value - volume.min_altitude.altitude_value;
      return new Entity({
        name: `Constraint Volume`,
        position: Cartesian3.fromDegrees(volume.circle.longitude, volume.circle.latitude, (volume.min_altitude.altitude_value + (length / 2))),
        cylinder: {
          bottomRadius: volume.circle.radiusMeters,
          topRadius: volume.circle.radiusMeters,
          material: new ColorMaterialProperty(new Color(fill.r / 255, fill.g / 255, fill.b / 255, fill.a)),
          outline: true,
          outlineWidth: 2,
          outlineColor: new Color(outline.r / 255, outline.g / 255, outline.b / 255, outline.a),
          length: new ConstantProperty(length),
        }
      });
    } else {
      const points: number[] = [];
      volume.geography.coordinates[0].forEach(point => {
        points.push(...point);
      });

      return new Entity({
        name: 'Constraint Volume',
        polygon: new PolygonGraphics({
          hierarchy: new PolygonHierarchy(Cartesian3.fromDegreesArray(points), []),
          height: new ConstantProperty(volume.min_altitude.altitude_value),
          extrudedHeight: new ConstantProperty(volume.max_altitude.altitude_value),
          material: new ColorMaterialProperty(new Color(fill.r / 255, fill.g / 255, fill.b / 255, fill.a)),
          outline: true,
          outlineWidth: 2,
          outlineColor: new Color(outline.r / 255, outline.g / 255, outline.b / 255, outline.a),
        })
      });
    }
  }

  /**
   * Converts a constraint volume to a Cesium Entity and performs the necessary conversions to the expected units.
   * @param volume The constraint volume to convert to a Cesium Entity
   * @param colorConfig The color configuration to use for the entity
   */
  getConvertedConstraintVolumeEntity(volume: EntityVolume4d, colorConfig: ColorConfig): Observable<Entity> {
    const coordinates = volume.circle ? new LatLng(volume.circle.latitude, volume.circle.longitude) :
      new LatLng(volume.geography.coordinates[0][0][1], volume.geography.coordinates[0][0][0]);

    // Convert altitudes to WGS84 & meters
    const maxOffset = MeasurementsUtil.convertUnits(volume.min_altitude.units_of_measure, units_of_measure.M,
      (volume.max_altitude.altitude_value - volume.min_altitude.altitude_value));
    return this.altitudeUtilService.convertToWGS84Meters(volume.min_altitude, coordinates)
      .pipe(map((minAlt) => {
        const tmpVolume = new EntityVolume4d(volume);
        tmpVolume.min_altitude = minAlt;
        tmpVolume.max_altitude = new Altitude({
          altitude_value: minAlt.altitude_value + maxOffset,
          units_of_measure: units_of_measure.M,
          vertical_reference: vertical_reference.W84
        });

        return this.getConstraintVolumeEntity(tmpVolume, colorConfig);
    }));
  }
}

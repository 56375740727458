import {FormControl, FormGroup, Validators} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';
import {source, units_of_measure, vertical_reference} from './enums';
import {Altitude} from './altitude.model';

export interface IAltitudeRange {
  min_altitude: number;
  max_altitude: number;
  altitude_vertical_reference: vertical_reference;
  altitude_units: units_of_measure;
  source?: source;
}

export class AltitudeRange extends BaseModel implements IAltitudeRange {

  static MIN_ALTITUDE_VALUE_FIELD_NAME = 'min_altitude';
  static MAX_ALTITUDE_VALUE_FIELD_NAME = 'max_altitude';
  static VERTICAL_REFERENCE_FIELD_NAME = 'altitude_vertical_reference';
  static UNITS_OF_MEASURE_FIELD_NAME = 'altitude_units';
  static SOURCE_FIELD_NAME = 'source';
  /**
   * The numeric value of the minimum altitude. Note that min and max values are added as a sanity check. As use cases
   * evolve and more options are made available in terms of units of measure or reference systems, these bounds should
   * be re-evaluated.
   * The following MUST hold: min_altitude < max_altitude
   */
  min_altitude: number;
  /**
   * The numeric value of the maximum altitude. Note that min and max values are added as a sanity check. As use cases
   * evolve and more options are made available in terms of units of measure or reference systems, these bounds should
   * be re-evaluated.
   * The following MUST hold: min_altitude < max_altitude
   */
  max_altitude: number;
  /**
   * A code indicating the reference for a vertical distance. See AIXM 5.1 and FIXM 4.1.0.
   * Currently, UTM only allows WGS84 with no immediate plans to allow other options. FIXM and AIXM allow for 'SFC' which is equivalent to AGL.
   */
    // tslint:disable-next-line:variable-name
  altitude_vertical_reference: vertical_reference;
  /**
   * The reference quantities used to express the value of altitude. See FIXM 4.1.
   * Currently, UTM only allows feet with no immediate plans to allow other options.  FIXM allows for feet or meters.
   */
    // tslint:disable-next-line:variable-name
  altitude_units: units_of_measure;
  /** Experimental field for testing and discussion to determine applicability. */
  source: source = source.OTHER;

  /**
   * constructor
   *
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @param useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IAltitudeRange>, useFormGroupValuesToModel = false) {
    super();

    this.altitude_vertical_reference = vertical_reference.W84;
    this.altitude_units = units_of_measure.FT;
    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  static equals(range1?: AltitudeRange, range2?: AltitudeRange): boolean {
    return range1 && range2
      && range1.min_altitude === range2.min_altitude
      && range1.max_altitude === range2.max_altitude
      && range1.altitude_vertical_reference === range2.altitude_vertical_reference
      && range1.altitude_units === range2.altitude_units;
  }

  /**
   * set the values.
   *
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IAltitudeRange>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.min_altitude = this.getValue<number>(rawValues, AltitudeRange.MIN_ALTITUDE_VALUE_FIELD_NAME);
      this.max_altitude = this.getValue<number>(rawValues, AltitudeRange.MAX_ALTITUDE_VALUE_FIELD_NAME);
      this.altitude_vertical_reference = this.getValue<vertical_reference>(rawValues, AltitudeRange.VERTICAL_REFERENCE_FIELD_NAME);
      this.altitude_units = this.getValue<units_of_measure>(rawValues, AltitudeRange.UNITS_OF_MEASURE_FIELD_NAME);
      this.source = this.getValue<source>(rawValues, AltitudeRange.SOURCE_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues(emitEvent: boolean = true) {
    this.$formGroup.controls[AltitudeRange.MIN_ALTITUDE_VALUE_FIELD_NAME].setValue(this.min_altitude, {emitEvent});
    this.$formGroup.controls[AltitudeRange.MAX_ALTITUDE_VALUE_FIELD_NAME].setValue(this.max_altitude, {emitEvent});
    this.$formGroup.controls[AltitudeRange.VERTICAL_REFERENCE_FIELD_NAME].setValue(this.altitude_vertical_reference, {emitEvent});
    this.$formGroup.controls[AltitudeRange.UNITS_OF_MEASURE_FIELD_NAME].setValue(this.altitude_units, {emitEvent});
    this.$formGroup.controls[AltitudeRange.SOURCE_FIELD_NAME].setValue(this.source, {emitEvent});
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  getMinAlt(): Altitude {
    const minAltitude: Altitude = new Altitude();
    minAltitude.altitude_value = this.min_altitude;
    minAltitude.vertical_reference = this.altitude_vertical_reference;
    minAltitude.units_of_measure = this.altitude_units;

    return minAltitude;
  }

  getMaxAlt(): Altitude {
    const maxAltitude: Altitude = new Altitude();
    maxAltitude.altitude_value = this.max_altitude;
    maxAltitude.vertical_reference = this.altitude_vertical_reference;
    maxAltitude.units_of_measure = this.altitude_units;

    return maxAltitude;
  }

  protected getFormGroup(): FormGroup {
    if (!this._formGroup) {
      this._formGroup = new FormGroup({
        min_altitude: new FormControl(this.min_altitude, [Validators.required]),
        max_altitude: new FormControl(this.max_altitude, [Validators.required]),
        altitude_vertical_reference: new FormControl(this.altitude_vertical_reference, [Validators.required,
          enumValidator(vertical_reference)]),
        altitude_units: new FormControl(this.altitude_units, [Validators.required,
          enumValidator(units_of_measure)]),
        source: new FormControl(this.source, [enumValidator(source)]),
      });
    }

    return this._formGroup;
  }
  equals(altitudeRange: AltitudeRange): boolean {
    return AltitudeRange.equals(this, altitudeRange);
  }
}

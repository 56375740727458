import {
  IAltitude,
  IUASVolumeReservation as TransportIUasVolumeReservation,
  IUvrVolumeSubmission as TransportUvrVolumeSubmission,
  TransportUnitsOfMeasure,
} from './gen/transport/utm';
import {
  cause,
  enumValidator,
  IUvrVolumeSubmission,
  permitted_uas,
  required_support,
  units_of_measure,
  UvrVolumeSubmission,
  vertical_reference
} from './gen/utm';
import {BaseModel} from './gen/utm/base-model';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import TransportPermittedUasEnum = TransportIUasVolumeReservation.PermittedUasEnum;
import TransportRequiredSupportEnum = TransportIUasVolumeReservation.RequiredSupportEnum;
import TransportCauseEnum = TransportIUasVolumeReservation.CauseEnum;

export interface TransportConstraintSubmission {
  type: string;
  permitted_uas: Array<TransportPermittedUasEnum>;
  required_support?: Array<TransportRequiredSupportEnum>;
  permitted_gufis?: Array<string>;
  cause: TransportCauseEnum;
  altitude_vertical_reference: IAltitude.VerticalReferenceEnum;
  altitude_units: TransportUnitsOfMeasure.LengthUnits;
  volumes: Array<TransportUvrVolumeSubmission>;
  reason?: string;
  constraint_type?: string;
}

export interface IConstraintSubmission {
  type: string;
  permitted_uas: Array<permitted_uas>;
  required_support: Array<required_support>;
  permitted_gufis: Array<string>;
  cause: cause;
  altitude_vertical_reference: vertical_reference;
  altitude_units_of_measure: units_of_measure;
  volumes: Array<UvrVolumeSubmission>;
  reason?: string;
  constraint_type?: string;
}

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export class ConstraintSubmission extends BaseModel implements IConstraintSubmission{
  static TYPE_FIELD_NAME = 'type';
  static PERMITTED_UAS_FIELD_NAME = 'permitted_uas';
  static REQUIRED_SUPPORT_FIELD_NAME = 'required_support';
  static PERMITTED_GUFIS_FIELD_NAME = 'permitted_gufis';
  static CAUSE_FIELD_NAME = 'cause';
  static altVertReferenceFieldName = 'altitude_vertical_reference';
  static altUnitsMeasureFieldName = 'altitude_units_of_measure';
  static volumesFieldName = 'volumes';
  static REASON_FIELD_NAME = 'reason';
  static CONSTRAINT_TYPE_FIELD_NAME = 'constraint_type';

  type: string;
  permitted_uas: Array<permitted_uas>;
  required_support: Array<required_support>;
  permitted_gufis: Array<string>;
  cause: cause;
  altitude_vertical_reference: vertical_reference;
  altitude_units_of_measure: units_of_measure;
  volumes: Array<UvrVolumeSubmission>;
  reason?: string;
  constraint_type?: string;

  /**
   * constructor
   *
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @param useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IConstraintSubmission>, useFormGroupValuesToModel = false) {
    super();
    this.permitted_uas = [];
    this.required_support = [];
    this.permitted_gufis = [];
    this.altitude_vertical_reference = vertical_reference.W84;
    this.altitude_units_of_measure = units_of_measure.FT;
    this.volumes = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }
  /**
   * set the values.
   *
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IConstraintSubmission>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.type = this.getValue<string>(rawValues, ConstraintSubmission.TYPE_FIELD_NAME);
      this.fillModelArray<permitted_uas>(this, ConstraintSubmission.PERMITTED_UAS_FIELD_NAME, rawValues.permitted_uas, useFormGroupValuesToModel);
      this.fillModelArray<required_support>(this, ConstraintSubmission.REQUIRED_SUPPORT_FIELD_NAME, rawValues.required_support, useFormGroupValuesToModel);
      this.fillModelArray<string>(this, ConstraintSubmission.PERMITTED_GUFIS_FIELD_NAME, rawValues.permitted_gufis, useFormGroupValuesToModel);
      this.cause = this.getValue<cause>(rawValues, ConstraintSubmission.CAUSE_FIELD_NAME);
      this.altitude_vertical_reference = this.getValue<vertical_reference>(rawValues, ConstraintSubmission.altVertReferenceFieldName);
      this.altitude_units_of_measure = this.getValue<units_of_measure>(rawValues, ConstraintSubmission.altUnitsMeasureFieldName);
      this.fillModelArray<IUvrVolumeSubmission>(this, ConstraintSubmission.volumesFieldName, rawValues.volumes, useFormGroupValuesToModel);
      this.reason = this.getValue<string>(rawValues, ConstraintSubmission.REASON_FIELD_NAME);
      this.constraint_type = this.getValue<string>(rawValues, ConstraintSubmission.CONSTRAINT_TYPE_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

/**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[ConstraintSubmission.TYPE_FIELD_NAME].setValue(this.type);
    this.fillFormArray<permitted_uas>(ConstraintSubmission.PERMITTED_UAS_FIELD_NAME, this.permitted_uas);
    this.fillFormArray<required_support>(ConstraintSubmission.REQUIRED_SUPPORT_FIELD_NAME, this.required_support);
    this.fillFormArray<string>(ConstraintSubmission.PERMITTED_GUFIS_FIELD_NAME, this.permitted_gufis);
    this.$formGroup.controls[ConstraintSubmission.CAUSE_FIELD_NAME].setValue(this.cause);
    this.$formGroup.controls[ConstraintSubmission.altVertReferenceFieldName].setValue(this.altitude_vertical_reference);
    this.$formGroup.controls[ConstraintSubmission.altUnitsMeasureFieldName].setValue(this.altitude_units_of_measure);
    this.fillFormArray<UvrVolumeSubmission>(ConstraintSubmission.volumesFieldName, this.volumes);
    this.$formGroup.controls[ConstraintSubmission.REASON_FIELD_NAME].setValue(this.reason);
    this.$formGroup.controls[ConstraintSubmission.CONSTRAINT_TYPE_FIELD_NAME].setValue(this.constraint_type);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        type: new UntypedFormControl(this.type, [Validators.required, ]),
        permitted_uas: new UntypedFormArray([]),
        required_support: new UntypedFormArray([]),
        permitted_gufis: new UntypedFormArray([]),
        cause: new UntypedFormControl(this.cause, [Validators.required, enumValidator(cause)]),
        altitude_vertical_reference: new UntypedFormControl(this.altitude_vertical_reference, [Validators.required,
          enumValidator(vertical_reference)]),
        altitude_units_of_measure: new UntypedFormControl(this.altitude_units_of_measure, [Validators.required,
          enumValidator(units_of_measure)]),
        volumes: new UntypedFormArray([]),
        reason: new UntypedFormControl(this.reason, [Validators.maxLength(1000)]),
        constraint_type: new UntypedFormControl(this.constraint_type, [Validators.required]),
      });
      // generate FormArray control elements
      this.fillFormArray<permitted_uas>(ConstraintSubmission.PERMITTED_UAS_FIELD_NAME, this.permitted_uas);
      // generate FormArray control elements
      this.fillFormArray<required_support>(ConstraintSubmission.REQUIRED_SUPPORT_FIELD_NAME, this.required_support);
      // generate FormArray control elements
      this.fillFormArray<string>(ConstraintSubmission.PERMITTED_GUFIS_FIELD_NAME, this.permitted_gufis);
      // generate FormArray control elements
      this.fillFormArray<UvrVolumeSubmission>(ConstraintSubmission.volumesFieldName, this.volumes);
    }
    return this._formGroup;
  }
}

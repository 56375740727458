/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator, maxValueValidator, minValueValidator} from './validators';
import {BaseModel} from './base-model';

import {source, units_of_measure, vertical_reference} from './enums';
import {MeasurementSystemType} from "../../MeasurementSystem";

export interface IAltitude {
  altitude_value: number;
  vertical_reference: vertical_reference;
  units_of_measure: units_of_measure;
  source?: source;
}


export class Altitude extends BaseModel implements IAltitude {

  static ALTITUDE_VALUE_FIELD_NAME = 'altitude_value';
  static VERTICAL_REFERENCE_FIELD_NAME = 'vertical_reference';
  static UNITS_OF_MEASURE_FIELD_NAME = 'units_of_measure';
  static SOURCE_FIELD_NAME = 'source';

  /** The numeric value of the altitude. Note that min and max values are added as a sanity check. As use cases evolve and more options are made available in terms of units of measure or reference systems, these bounds should be re-evaluated. */
  altitude_value: number;
  /** A code indicating the reference for a vertical distance. See AIXM 5.1 and FIXM 4.1.0.
   Currently, UTM only allows WGS84 with no immediate plans to allow other options. FIXM and AIXM allow for 'SFC' which is equivalent to AGL. */
  vertical_reference: vertical_reference;
  /** The reference quantities used to express the value of altitude. See FIXM 4.1.
   Currently, UTM only allows feet with no immediate plans to allow other options.  FIXM allows for feet or meters. */
  units_of_measure: units_of_measure;
  /** Experimental field for testing and discussion to determine applicability. */
  source: source;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IAltitude>, useFormGroupValuesToModel = false) {
    super();

    this.vertical_reference = vertical_reference.W84;
    this.units_of_measure = units_of_measure.FT;
    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IAltitude>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.altitude_value = this.getValue<number>(rawValues, Altitude.ALTITUDE_VALUE_FIELD_NAME);
      this.vertical_reference = this.getValue<vertical_reference>(rawValues, Altitude.VERTICAL_REFERENCE_FIELD_NAME);
      this.units_of_measure = this.getValue<units_of_measure>(rawValues, Altitude.UNITS_OF_MEASURE_FIELD_NAME);
      this.source = this.getValue<source>(rawValues, Altitude.SOURCE_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[Altitude.ALTITUDE_VALUE_FIELD_NAME].setValue(this.altitude_value);
    this.$formGroup.controls[Altitude.VERTICAL_REFERENCE_FIELD_NAME].setValue(this.vertical_reference);
    this.$formGroup.controls[Altitude.UNITS_OF_MEASURE_FIELD_NAME].setValue(this.units_of_measure);
    this.$formGroup.controls[Altitude.SOURCE_FIELD_NAME].setValue(this.source);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  toString(decimalRound: number = 2, system?: MeasurementSystemType): string {
    let altitudeValue = (this.getAltitudeValue(system));
    altitudeValue = (Math.abs(altitudeValue) <  0.01) ? 0 : altitudeValue;
    return `${altitudeValue.toFixed(decimalRound)} ${this.getUnits(system)} ${this.vertical_reference}`;
  }

  getUnits(system?: MeasurementSystemType): units_of_measure {
    switch (system) {
      case MeasurementSystemType.METRIC:
        return units_of_measure.M;
      case MeasurementSystemType.IMPERIAL:
        return units_of_measure.FT;
      default:
        return this.units_of_measure;
    }
  }

  getAltitudeValue(system?: MeasurementSystemType): number {
    let ret = this.altitude_value;
    if (system) {
      if (system === MeasurementSystemType.METRIC && this.units_of_measure === units_of_measure.FT) {
        ret /= 3.28084;
      } else if (system === MeasurementSystemType.IMPERIAL && this.units_of_measure === units_of_measure.M) {
        ret *= 3.28084;
      }
    }
    return ret;
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        altitude_value: new UntypedFormControl(this.altitude_value, [Validators.required, minValueValidator(-8000), maxValueValidator(100000),]),
        vertical_reference: new UntypedFormControl(this.vertical_reference, [Validators.required, enumValidator(vertical_reference),]),
        units_of_measure: new UntypedFormControl(this.units_of_measure, [Validators.required, enumValidator(units_of_measure),]),
        source: new UntypedFormControl(this.source, [enumValidator(source),]),
      });
    }
    return this._formGroup;
  }
}

/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {BaseModel} from './gen/utm/base-model';
import * as _ from 'lodash';
import {circle} from '@turf/turf';
import {point} from '@turf/turf';
import {enumValidator, units_of_measure} from './gen/utm';
import {MeasurementsUtil} from '../utils/MeasurementsUtil';

export interface IGeoCircle {
  latitude: number;
  longitude: number;
  radius: number;
  units: units_of_measure;
}

export interface IRadius {
  units_of_measure: units_of_measure;
  radius: number;
}

export const CIRCLE_LIMITS = {
  MIN_RADIUS: {
    METERS: 1,
    FEET: 3.2809,
    MILES: 0.0007,
    NAUTICAL_MILES: 0.0006
  },
  MAX_RADIUS: {
    METERS: 28000,
    FEET: 91863.517,
    MILES: 17.3983,
    NAUTICAL_MILES: 15.1187
  }
}

export class GeoCircle extends BaseModel implements IGeoCircle {

  static LATITUDE_FIELD_NAME = 'latitude';
  static LONGITUDE_FIELD_NAME = 'longitude';
  static RADIUS_FIELD_NAME = 'radius';
  static UNITS_FIELD_NAME = 'units';

  /** The latitude coordinate for the center of the circle. */
  latitude: number;

  /** The longitude coordinate for the center of the circle. */
  longitude: number;

  /** The radius of the circle in units specified in the units field */
  radius: number;

  /** The units used to measure the circle's radius  */
  units: units_of_measure;

  /**
   * Constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values: IGeoCircle, useFormGroupValuesToModel = false) {
    super();
    this.setValues(values, useFormGroupValuesToModel);
  }

  /**
   * Sets the model values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IGeoCircle>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.latitude = this.getValue<number>(rawValues, GeoCircle.LATITUDE_FIELD_NAME);
      this.longitude = this.getValue<number>(rawValues, GeoCircle.LONGITUDE_FIELD_NAME);
      this.radius = this.getValue<number>(rawValues, GeoCircle.RADIUS_FIELD_NAME);
      this.units = this.getValue<units_of_measure>(rawValues, GeoCircle.UNITS_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        latitude: new UntypedFormControl(this.latitude, [Validators.required, Validators.min(-90), Validators.max(90)]),
        longitude: new UntypedFormControl(this.longitude, [Validators.required, Validators.min(-180), Validators.max(180)]),
        radius: new UntypedFormControl(this.radius, [Validators.required]),
        units: new UntypedFormControl(this.units, [Validators.required, enumValidator(units_of_measure)]),
      });
      if (this.units === units_of_measure.M) {
        this._formGroup.controls[GeoCircle.RADIUS_FIELD_NAME].addValidators([Validators.min(CIRCLE_LIMITS.MIN_RADIUS.METERS),
          Validators.max(CIRCLE_LIMITS.MAX_RADIUS.METERS)]);
      } else if (this.units === units_of_measure.FT) {
        this._formGroup.controls[GeoCircle.RADIUS_FIELD_NAME].addValidators([Validators.min(CIRCLE_LIMITS.MIN_RADIUS.FEET),
          Validators.max(CIRCLE_LIMITS.MAX_RADIUS.FEET)]);
      }
    }
    return this._formGroup;
  }

  /**
   * Sets the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[GeoCircle.LATITUDE_FIELD_NAME].setValue(this.latitude);
    this.$formGroup.controls[GeoCircle.LONGITUDE_FIELD_NAME].setValue(this.longitude);
    this.$formGroup.controls[GeoCircle.RADIUS_FIELD_NAME].setValue(this.radius);
    this.$formGroup.controls[GeoCircle.UNITS_FIELD_NAME].setValue(this.units);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }

  /**
   * Returns the circle as an array of longitude/latitude coordinates
   */
  toLonLatCoordinates(): [number,number][] {
    const turfCircle = circle(point([this.longitude, this.latitude]), (this.radiusMeters / 1000), {units: 'kilometers'});
    return turfCircle.geometry.coordinates[0].map(coord => ([coord[0], coord[1]]));
  }

  /**
   * Returns whether all fields in the model are populated (not null or undefined)
   */
  isCircleComplete(): boolean {
    return !(_.isNil(this.latitude) || _.isNil(this.longitude) || _.isNil(this.radius) || _.isNil(this.units));
  }

  /**
   * Returns the circle's radius in meters
   */
  get radiusMeters(): number {
    return MeasurementsUtil.convertUnits(this.units, units_of_measure.M, this.radius);
  }

  /**
   * Sets the radius field to the result of converting the supplied radius parameter in meters to the units field value
   * @param radius The radius in meters to convert to the unit present in the units field
   */
  set radiusMeters(radius: number) {
    this.radius = MeasurementsUtil.convertUnits(units_of_measure.M, this.units, radius);
  }
}

export class AxPermissionRequest<T> {
  private action: string;
  private resourceType: string;
  private resource?: T;


  getAction(): string {
    return this.action;
  }

  setAction(value: string) {
    this.action = value;
  }

  getResourceType(): string {
    return this.resourceType;
  }

  setResourceType(value: string) {
    this.resourceType = value;
  }

  getResource(): T | undefined {
    return this.resource;
  }

  setResource(value: T) {
    this.resource = value;
  }

  toString(): string {
    return `${this.action}_${this.resourceType}`;
  }
}

<clr-alerts *ngIf="showAlerts">
  <clr-alert [clrAlertType]="'info'" [clrAlertClosable]="false">
    <clr-alert-item>
      <span class="alert-text">
        <ng-container [ngSwitch]="approvalSubmissionStatus.status">
          <ng-container *ngSwitchCase="approvalSubmissionStatusEnum.PROCESSING">
            Submitting operation {{approvalSubmissionStatus.approved ? 'approval' : 'denial'}}...
          </ng-container>
          <ng-container *ngSwitchCase="approvalSubmissionStatusEnum.COMPLETE">
            Operation has been {{approvalSubmissionStatus.approved ? 'approved' : 'denied'}}
          </ng-container>
          <ng-container *ngSwitchCase="approvalSubmissionStatusEnum.ERROR">
            There was an unknown error submitting your {{approvalSubmissionStatus.approved ? 'approval' : 'denial'}}.
          </ng-container>
        </ng-container>
      </span>
    </clr-alert-item>
  </clr-alert>
</clr-alerts>
<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="datagrid-compact">
  <clr-dg-action-bar>
    <div class="btn-group">
      <button type="button" class="btn btn-sm btn-secondary" (click)="refresh()">
        Refresh
      </button>
    </div>
  </clr-dg-action-bar>
  <clr-dg-column>Operation</clr-dg-column>
  <clr-dg-column>Request Time</clr-dg-column>
  <clr-dg-column>Request Expiration</clr-dg-column>
  <clr-dg-column>Approval Status</clr-dg-column>
  @if(canSubmitApprovals$()){<clr-dg-column>Approve/Deny</clr-dg-column>}
  <clr-dg-column class="col-approval">Approval Request Details</clr-dg-column>

  <clr-dg-placeholder>No results found</clr-dg-placeholder>

  <clr-dg-row *ngFor="let request of approvalRequests" [clrDgItem]="request" class="datagrid-body-row">
    <clr-dg-cell>{{request.operation.flight_number}}</clr-dg-cell>
    <clr-dg-cell>{{request.timeOfRequest}}</clr-dg-cell>
    <clr-dg-cell>{{request.expiresAt}}</clr-dg-cell>
    <clr-dg-cell>{{request.approvalStatus}}</clr-dg-cell>
    @if(canSubmitApprovals$()){
      <clr-dg-cell class="approval-buttons">
      <button type="button" class="btn btn-sm btn-success" (click)="approveDenyOperation(request.operation.operationId,true)">
        <cds-icon shape="check"></cds-icon>Approve
      </button>
      <button type="button" class="btn btn-sm btn-danger" (click)="showDenyModal(request.operation)">
        <cds-icon shape="times"></cds-icon>Deny
      </button>
    </clr-dg-cell>
    }
    <clr-dg-cell>
      <button type="button" class="btn btn-sm btn-secondary" (click)="viewApprovalDetails(request)">View Request Details
      </button>
    </clr-dg-cell>
  </clr-dg-row>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="currentPageSize"
                       [clrDgTotalItems]="totalItems"></clr-dg-pagination>
    {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItems}}
    <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]">Approval requests per page</clr-dg-page-size>
  </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="showDenyApprovalModal" [clrModalSize]="'md'" *ngIf="showDenyApprovalModal" class="pending-deny-modal">
  <div class="modal-title">Request Denial Details</div>
  <div class="modal-body">
    <form [formGroup]="denyFg">
      <clr-textarea-container>
        <label>Denial Reason for {{pendingDenyOperation.flight_number}}</label>
        <textarea clrTextarea [formControlName]="'denyReason'" name="denyReason"></textarea>
        <clr-control-helper *ngIf="(denyFg.controls.denyReason.value === null)">Optional denial reason</clr-control-helper>
        <clr-control-helper *ngIf="(denyFg.controls.denyReason.value !== null)">{{denyFg.controls.denyReason.value.length  || 0}}/255</clr-control-helper>
        <clr-control-error *clrIfError="'invalidCharacters'">Invalid character(s): {{denyFg.controls.denyReason.errors.invalidCharacters.characters}}</clr-control-error>
        <clr-control-error *clrIfError="'maxlength'">Maximum length exceeded ({{denyFg.controls.denyReason.value.length || 0}}
          /{{denyFg.controls.denyReason.errors.maxlength.requiredLength}})</clr-control-error>
      </clr-textarea-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger"
            (click)="approveDenyOperation(pendingDenyOperation.operationId, false, denyFg.controls.denyReason.value)"
            [disabled]="denyFg.invalid">
      <cds-icon shape="times"></cds-icon>Deny
    </button>
  </div>
</clr-modal>


<!-- Approval Details Modal -->
<clr-modal [(clrModalOpen)]="showApprovalDetails" [clrModalSize]="'xl'" *ngIf="showApprovalDetails">
  <div class="modal-title">Approval Request Details</div>
  <div class="modal-body" style="min-height: 500px">
    <div class="cesium-wrapper">
      <ax-cesium-container [timeline]="false"
                           [navigationInstructionsInitiallyVisible]="false"
                           [toolbarZIndex]="'1000'"
                           [projectionPicker]="true"
                           [sceneModePicker]="false">
        <app-operation-volume-drawer [operation]="selectedApproval.operation"></app-operation-volume-drawer>
        <ng-container *ngFor="let constraintId of selectedApproval.constraintIds">
          <app-constraint-volume-drawer *ngIf="constraintCache[constraintId]" [volumes]="constraintCache[constraintId].volumes">
          </app-constraint-volume-drawer>
        </ng-container>
      </ax-cesium-container>
    </div>
    <h4>Proposed Operation</h4>
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>Operation</clr-dg-column>
      <clr-dg-row>
        <clr-dg-cell>{{selectedApproval.operation.flight_number}}</clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          <app-ax-operation-ext-summary [operation]="selectedApproval.operation" style="width:100%;"></app-ax-operation-ext-summary>
        </clr-dg-row-detail>
      </clr-dg-row>
    </clr-datagrid>
    <h4>Conflicting Approval Areas</h4>
    <clr-datagrid class="datagrid-compact">
      <clr-dg-column>Approval Area</clr-dg-column>
      <clr-dg-column></clr-dg-column>
      <clr-dg-row *ngFor="let constraintId of selectedApproval.constraintIds" [clrDgItem]="selectedApproval.constraintIds">
        <clr-dg-cell>{{constraintCache[constraintId]?.reason || ''}}</clr-dg-cell>
        <clr-dg-cell>
          <a target="_blank" [routerLink]="'/fuss/constraint/view-constraint'" [queryParams]="{constraintId: constraintId}">
            <cds-icon shape="link"></cds-icon>
          </a>
        </clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
          <app-constraint-ext-summary *ngIf="constraintCache[constraintId]" [constraint]="constraintCache[constraintId]"
                                      style="width:100%;"></app-constraint-ext-summary>
        </clr-dg-row-detail>
      </clr-dg-row>
    </clr-datagrid>
  </div>
</clr-modal>

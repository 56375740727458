import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {LeafletDrawerService} from '@ax/ax-angular-map-leaflet';
import {Subscription} from 'rxjs';

@Component({
  selector: 'leaflet-expose-map',
  template: ''
})
export class LeafletExposeMapComponent implements OnInit, OnDestroy {
  private mapSub: Subscription;
  @Output() mapInit: EventEmitter<L.Map> = new EventEmitter<L.Map>();

  constructor(private leafletDrawerService: LeafletDrawerService) { }

  ngOnInit(): void {
    this.mapSub = this.leafletDrawerService.watchViewerInit().subscribe((map: L.Map) => {
      this.mapInit.emit(map);
    });
  }

  ngOnDestroy(): void {
    this.mapSub?.unsubscribe();
  }

}

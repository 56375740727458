// To parse this data:
//
//   import { Convert, SwiftFlightPlan } from "./file";
//
//   const swiftFlightPlan = Convert.toSwiftFlightPlan(json);

export interface SwiftFlightPlan {
  fileType: FileType;
  version: number;
  groundStation: GroundStation;
  groundStationVersion: GroundStationVersion;
  groundStationDate: GroundStationDate;
  mission: Mission;
}

export enum FileType {
  Plan = 'Plan',
}

export enum GroundStation {
  SwiftGCS = 'SwiftGCS',
}

export enum GroundStationDate {
  FriJun24140032EDT2022 = 'Fri Jun 24 14:00:32 EDT 2022',
  WedNov02202957EDT2022 = 'Wed Nov 02 20:29:57 EDT 2022',
}

export enum GroundStationVersion {
  The0110Snapshot = '0.11.0-SNAPSHOT',
}

export interface Mission {
  firmwareType: FirmwareType;
  vehicleType: VehicleType;
  homePosition: HomePosition;
  items: MissionItem[];
  rallies: Rally[];
}

export enum FirmwareType {
  Ardupilotmega = 'ardupilotmega',
}

export interface HomePosition {
  latitude: number;
  longitude: number;
  altitude: number;
}

export interface MissionItem {
  type: MissionItemType;
  command?: Command;
  frame?: number;
  params?: number[];
  latitude?: number;
  longitude?: number;
  altitude?: number;
  leadIn?: number;
  overlap?: number;
  windFromWest?: boolean;
  overshoot?: number;
  complexItemType?: string;
  kml?: string;
  camera?: Camera;
  items?: ItemItem[];
  kmlName?: string;
  sidelap?: number;
  heading?: number;
  approachAltitude?: number;
  landAltitude?: number;
}

export interface Camera {
  name: string;
  focalLength: number;
  imageWidth: number;
  imageHeight: number;
  sensorWidth: number;
  sensorHeight: number;
}

export enum Command {
  MavCmdDoJump = 'mav-cmd-do-jump',
  MavCmdNavLoiterTurns = 'mav-cmd-nav-loiter-turns',
  MavCmdNavLoiterUnlim = 'mav-cmd-nav-loiter-unlim',
  MavCmdNavVtolLand = 'mav-cmd-nav-vtol-land',
  MavCmdNavVtolTakeoff = 'mav-cmd-nav-vtol-takeoff',
  MavCmdNavWaypoint = 'mav-cmd-nav-waypoint',
}

export interface ItemItem {
  type: MissionItemType;
  command: string;
  frame: number;
  params: number[];
  latitude: number;
  longitude: number;
  altitude: number;
}

export enum MissionItemType {
  ComplexItem = 'ComplexItem',
  SimpleItem = 'SimpleItem',
}

export interface Rally {
  type: MissionItemType;
  complexItemType: string;
  latitude: number;
  longitude: number;
  altitude: number;
}

export enum VehicleType {
  MavTypeFixedWing = 'mav-type-fixed-wing',
}

// Converts JSON strings to/from your types
export class Convert {
  public static toSwiftFlightPlan(json: string): SwiftFlightPlan {
    return JSON.parse(json);
  }

  public static swiftFlightPlanToJson(value: SwiftFlightPlan): string {
    return JSON.stringify(value);
  }
}

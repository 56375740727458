import {Injectable, Type} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ExportService, ImportExportType} from 'src/app/shared/import-export/import-export.service';
import {ConfigComponent} from 'src/app/shared/import-export/config.component';
import {IOpGeoSubmissionFG} from '../../../../../components/operation/create-operation/create-operation.component';
import {
  IOpVolumeSubmissionFG
} from 'src/app/shared/components/operation/operation-geometry-editor/operation-geometry-editor.component';


@Injectable({
  providedIn: 'root'
})
export class KmlOperationVolumeExportService extends ExportService<Blob | null> {
  constructor() {
    super();
  }

  doExport(geometry: IOpGeoSubmissionFG): Observable<Blob | null> {
    const kml = `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2">
    <Document>
        <Style id="red">
            <IconStyle>
                <color>ff0000ff</color>
            </IconStyle>
            <LineStyle>
                <color>ff0000ff</color>
            </LineStyle>
            <PolyStyle>
                <color>ff0000ff</color>
            </PolyStyle>
        </Style>

        <Folder>
            <name>Operation</name>
                ${this.toPlacemarks(geometry)}
        </Folder>
    </Document>
</kml>`;
    return of(new Blob([kml], {type: 'application/vnd.google-earth.kml+xml'}));
  }

  getFormatName(): string {
    return 'KML';
  }

  getType(): ImportExportType {
    return ImportExportType.OPERATIONVOLUME;
  }

  getFileExtensions(): string[] {
    return ['.kml'];
  }

  getConfigComponent(): Type<ConfigComponent> {
    return null;
  }

  getDefaultConfig(): any {
    return {};
  }

  toCoordString(vol: IOpVolumeSubmissionFG): string {
    let coordStr = '';
    if (vol.circle) {
      vol.circle.toLonLatCoordinates().forEach(coord => {
        coordStr += (coord[0] + ',' + coord[1] + ',' + vol.altitudeRange.max_altitude + ' ');
      });
    } else if (vol.geography?.coordinates?.length) {
      vol.geography.coordinates[0].forEach(coord => {
        coordStr += (coord[0] + ',' + coord[1] + ',' + vol.altitudeRange.max_altitude + ' ');
      });
    }
    return coordStr;
  }

  toPlacemarks(geometry: IOpGeoSubmissionFG): string {
    let placemarkStr = '';

    geometry.volumes.forEach((vol, index) => {
      placemarkStr += `
              <Placemark>
                <name>Operation Volume ${index}</name>
                <visibility>1</visibility>
                <styleUrl>#red</styleUrl>
                <Polygon>
                    <extrude>1</extrude>
                    <altitudeMode>absolute</altitudeMode>
                    <outerBoundaryIs>
                        <LinearRing>
                            <coordinates>
                                ${this.toCoordString(vol)}
                            </coordinates>
                        </LinearRing>
                    </outerBoundaryIs>
                </Polygon>
              </Placemark>`;
    });

    return placemarkStr;
  }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {User} from '../../model/User';
import {UserSubmission} from '../../model/UserSubmission';
import {UserResponse} from '../../model/UserResponse';
import {UserSearchResult} from '../../model/UserSearchResult';
import * as _ from 'lodash';

export interface IUserSearch {
  title?: string;
  firstName?: string;
  lastName?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  pretty_org?: string;
  division?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  pretty_roles?: string;
  email?: string;

  sort?: UserSearchSortField[];
  sortIncreasing?: boolean;
  limit?: number;
  offset?: number;
}

export enum UserSearchSortField {
  title = 'title',
  firstName = 'firstName',
  lastName = 'lastName',
  pretty_org = 'pretty_org',
  division = 'division',
  pretty_roles = 'pretty_roles',
  email = 'email',
  unknown = '__unknown__'
}

export class UserSearchSortFieldUtil {
  static fromString(s: string): UserSearchSortField {
    if (!s) {
      return UserSearchSortField.unknown;
    }
    switch (s) {
      case 'title':
        return UserSearchSortField.title;
      case 'firstName':
        return UserSearchSortField.firstName;
      case 'lastName':
        return UserSearchSortField.lastName;
      case 'pretty_org':
        return UserSearchSortField.pretty_org;
      case 'division':
        return UserSearchSortField.division;
      case 'pretty_roles':
        return UserSearchSortField.pretty_roles;
      case 'email':
        return UserSearchSortField.email;
      default:
        console.error(`Invalid sort option: ${s}`);
        return UserSearchSortField.unknown;
    }
  }
}

export class UserSearch implements IUserSearch {
  /* eslint-disable @typescript-eslint/naming-convention */
  /* tslint:disable:variable-name */
  title?: string;
  firstName?: string;
  lastName?: string;
  pretty_org?: string;
  division?: string;
  pretty_roles?: string;
  email?: string;

  sort?: UserSearchSortField[];
  sortIncreasing?: boolean;
  limit?: number;
  offset?: number;
  /* tslint:enable:variable-name */
  /* eslint-enable @typescript-eslint/naming-convention */

  public constructor(raw?: IUserSearch) {
    if (raw) {
      this.title = raw.title;
      this.firstName = raw.firstName;
      this.lastName = raw.lastName;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.pretty_org = raw.pretty_org;
      this.division = raw.division;
      this.pretty_roles = raw.pretty_roles;
      this.email = raw.email;
      this.sort = raw.sort?.length ? raw.sort : [UserSearchSortField.firstName];
      this.sortIncreasing = !_.isNil(raw.sortIncreasing) ? raw.sortIncreasing : true;
      this.limit = !_.isNil(raw.limit) ? raw.limit : 10;
      this.offset = !_.isNil(raw.offset) ? raw.offset : 0;
    } else {
      this.title = null;
      this.firstName = null;
      this.lastName = null;
      this.pretty_org = null;
      this.division = null;
      this.pretty_roles = null;
      this.email = null;
      this.sort = [UserSearchSortField.firstName];
      this.sortIncreasing = true;
      this.limit = 10;
      this.offset = 0;
    }
  }

  public static defaultSearch(): UserSearch {
    return new UserSearch();
  }
}

@Injectable({
  providedIn: 'root'
})
export abstract class AdminUserService {

  protected constructor() {
    // Method not implemented
  }

  abstract getUsers(): Observable<UserSearchResult>;

  abstract getUser(userId: string): Observable<UserResponse>;

  abstract updateUser(userId: string, userSubmission: Partial<UserSubmission>): Observable<UserResponse>;

  abstract createUser(userSubmission: UserSubmission): Observable<UserResponse>;

  abstract deleteUser(user: User): Observable<UserResponse>;
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApprovalInfo} from '../../../../shared/model/gen/utm/approval-info';
import {IUserMessageSearch, UserMessageSearch} from '../../../../shared/services/user-message.service';
import {ClrDatagridStateInterface} from '@clr/angular';
import {BehaviorSubject, combineLatest, EMPTY, Subscription, timer} from 'rxjs';
import {ApprovalService} from '../../../../shared/services/approval.service';
import {ApprovalStatus} from '../../../../shared/model/gen/utm';
import {SearchResult} from '../../../../shared/model/SearchResult';
import {catchError, exhaustMap} from 'rxjs/operators';
import * as _ from 'lodash';
import {SceneMode} from '@cesium/engine';
import {UvrExt} from '../../../../shared/model/utm/UvrExt';
import {ConstraintService} from '../../../../shared/services/constraint.service';
import {OperationExt} from '../../../../shared/model/utm/OperationExt';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {invalidCharactersValidator} from '../../../../shared/utils/Validators';
import {PermissionService} from 'src/app/shared/permissions/service/permission.service';
import {PermissionParser} from '../../../../shared/permissions/PermissionParser';
import {toSignal} from '@angular/core/rxjs-interop';

interface IApprovalSubmissionStatus {
  status: ApprovalSubmissionState;
  approved?: boolean;
}
enum ApprovalSubmissionState {
  NONE = 'NONE',
  PROCESSING = 'PROCESSING',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR'
}

@Component({
  selector: 'app-pending-approvals-datagrid',
  templateUrl: './pending-approvals-datagrid.component.html',
  styleUrls: ['./pending-approvals-datagrid.component.scss']
})
export class PendingApprovalsDatagridComponent implements OnInit, OnDestroy {
  totalItems: number;
  currentPageSize = 10;
  loading = true;
  approvalRequests: ApprovalInfo[];
  searchConfig: IUserMessageSearch = UserMessageSearch.defaultSearch();
  selectedApproval: ApprovalInfo;
  showApprovalDetails = false;
  showAlerts = false;
  approvalSubmissionStatus: IApprovalSubmissionStatus = {status: ApprovalSubmissionState.NONE};
  approvalSubmissionStatusEnum = ApprovalSubmissionState;
  view2D = SceneMode.SCENE2D;
  constraintCache: { [key: string]: UvrExt } = {};

  private state: ClrDatagridStateInterface<any>;
  private stateSubject: BehaviorSubject<ClrDatagridStateInterface> = new BehaviorSubject<ClrDatagridStateInterface>(null);
  private approvalsSub: Subscription;
  private approveOperationSub: Subscription;
  private constraintCacheSubscriptions: { [key: string]: Subscription } = {};
  showDenyApprovalModal = false;

  pendingDenyOperation: OperationExt;
  denyFg = new FormGroup({
    denyReason: new FormControl<string>('', [
      invalidCharactersValidator(/[^ a-zA-Z0-9#$%&!?'()*+.,:;\/@_~-]+/),
      Validators.maxLength(255)
    ])
  });
  canSubmitApprovals$ = toSignal(this.permissionService.evaluateRequest(PermissionParser.parseAxPermissionRequest('submit_approvals')));


  constructor(private approvalService: ApprovalService,
              private constraintService: ConstraintService,
              private permissionService: PermissionService) {
    // Constructor intentionally left empty
  }

  ngOnInit(): void {
    this.approvalsSub = combineLatest([timer(100, 5000), this.stateSubject])
      .pipe(exhaustMap(([iterator, statey]) => {
        // this.loading = true;
        const from = statey.page.from === -1 ? 0 : statey.page.from;
        return this.approvalService.getOperationApprovals([ApprovalStatus.PENDING], statey.page.size, from, true)
          .pipe(catchError(() => (EMPTY)));
      }))
      .subscribe((results: SearchResult<ApprovalInfo>) => {
        this.loading = false;
        this.totalItems = results.total;
        if (!_.isEqual(this.approvalRequests, results.results)) {
          this.approvalRequests = results.results;
        }
      });
  }

  refresh(statey?: ClrDatagridStateInterface, forceRecount: boolean = false) {
    this.loading = true;
    if (!statey) {
      statey = this.state || {};
    }

    this.state = statey;

    if (!statey.page) {
      statey.page = {
        from: 0,
        to: 9,
        size: 10,
      };
    }

    this.stateSubject.next(statey);
  }

  viewApprovalDetails(approval: ApprovalInfo) {
    approval.constraintIds.forEach(id => this.cacheConstraint(id));
    this.selectedApproval = approval;
    this.showApprovalDetails = true;
  }

  cacheConstraint(constraintId: string) {
    if (constraintId in this.constraintCache) {
      return;
    }
    this.constraintCacheSubscriptions[constraintId] = this.constraintService.getConstraint(constraintId)
      .subscribe(constraint => {
        this.constraintCache[constraintId] = constraint;
        delete this.constraintCacheSubscriptions[constraintId];
      });
  }

  approveDenyOperation(operationId: string, approved: boolean, denyReason?: string) {
    this.approvalSubmissionStatus = {status: ApprovalSubmissionState.PROCESSING, approved};
    this.showAlerts = true;
    this.showDenyApprovalModal = false;
    this.approveOperationSub = this.approvalService.approveOperation({entityId: operationId, approved, message: denyReason})
      .subscribe(() => {
        this.approvalSubmissionStatus.status = ApprovalSubmissionState.COMPLETE;
        this.refresh();
        this.setAlertsTimeout();
      }, () => {
        this.approvalSubmissionStatus.status = ApprovalSubmissionState.ERROR;
        this.setAlertsTimeout();
      });
  }

  ngOnDestroy(): void {
    this.approvalsSub?.unsubscribe();
    this.approveOperationSub?.unsubscribe();
    Object.values(this.constraintCacheSubscriptions).forEach(sub => sub.unsubscribe());
  }

  private setAlertsTimeout() {
    setTimeout(() => {
      this.showAlerts = false;
      this.approvalSubmissionStatus = {status: ApprovalSubmissionState.NONE};
    }, 5000);
  }

  showDenyModal(operation: OperationExt) {
    this.denyFg.reset();
    this.showDenyApprovalModal = true;
    this.pendingDenyOperation = operation;
  }
}

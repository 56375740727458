/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';
import {volume_type} from './enums';
import {Altitude, IAltitude} from './altitude.model';
import {IPolygon, Polygon} from './polygon.model';
import {DateTime} from 'luxon';
import {GeoCircle, IGeoCircle, IRadius} from '../../Circle.model';

export interface IOperationVolume {
  volume_type: volume_type;
  near_structure?: boolean;
  effective_time_begin: DateTime;
  effective_time_end: DateTime;
  min_altitude: IAltitude;
  max_altitude: IAltitude;
  submitted_min_altitude?: IAltitude;
  submitted_max_altitude?: IAltitude;
  geography: IPolygon;
  circle?: IGeoCircle;
  submitted_radius?: IRadius;
  beyond_visual_line_of_sight: boolean;
  off_nominal: boolean;
}


export class OperationVolume extends BaseModel implements IOperationVolume {

  static VOLUME_TYPE_FIELD_NAME = 'volume_type';
  static NEAR_STRUCTURE_FIELD_NAME = 'near_structure';
  static EFFECTIVE_TIME_BEGIN_FIELD_NAME = 'effective_time_begin';
  static EFFECTIVE_TIME_END_FIELD_NAME = 'effective_time_end';
  static MIN_ALTITUDE_FIELD_NAME = 'min_altitude';
  static MAX_ALTITUDE_FIELD_NAME = 'max_altitude';
  static GEOGRAPHY_FIELD_NAME = 'geography';
  static CIRCLE_FIELD_NAME = 'circle';
  static SUBMITTED_RADIUS_FIELD_NAME = 'submitted_radius';
  static BEYOND_VISUAL_LINE_OF_SIGHT_FIELD_NAME = 'beyond_visual_line_of_sight';
  static OFF_NOMINAL_FIELD_NAME = 'off_nominal';

  /** More description later. */
  volume_type: volume_type;

  /** Is this operation volume within 400' of a structure? */
  near_structure: boolean;

  /** Earliest time the operation will use the operation volume.  It must be less than effective_time_end.
   effective_time_begin < effective_time_end MUST be true. */
  effective_time_begin: DateTime;

  /** Latest time the operation will done with the operation volume. It must be greater than effective_time_begin.
   effective_time_begin < effective_time_end MUST be true. */
  effective_time_end: DateTime;

  /**
   * The minimum altitude for this operation volume.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: min_altitude.altitude_value < max_altitude.altitude_value
   */
  min_altitude: Altitude;

  /**
   * The maximum altitude for this operation volume.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: min_altitude.altitude_value < max_altitude.altitude_value
   */
  max_altitude: Altitude;

  /**
   * The minimum altitude for this operation volume, using the originally supplied vertical reference and unit of measure.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: submitted_min_altitude.altitude_value < submitted_max_altitude.altitude_value
   */
  submitted_min_altitude?: Altitude;

  /**
   * The maximum altitude for this operation volume, using the originally supplied vertical reference and unit of measure.
   * Note that min and max values are added as a sanity check. As use cases evolve and more options are made available
   * in terms of units of measure or reference systems, these bounds should be re-evaluated.
   * The following MUST hold: submitted_min_altitude.altitude_value < submitted_max_altitude.altitude_value
   */
  submitted_max_altitude?: Altitude;

  geography: Polygon;

  /**
   * A circular geometry consisting of a center pair of lat/lon coordinates and a radius in meters.
   */
  circle?: GeoCircle;

  /**
   * User submitted radius value with units of measure
   */
  submitted_radius?: IRadius;

  /** Describes whether any portion of the operation volume is beyond the visual line of sight of the RPIC. */
  beyond_visual_line_of_sight: boolean;

  /**
   * Indicates whether the volume is off nominal. Off nominal volumes are drawn around a non-conforming or contingent
   * platform.
   */
  off_nominal: boolean;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @param useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IOperationVolume>, useFormGroupValuesToModel = false) {
    super();
    this.min_altitude = new Altitude();
    this.max_altitude = new Altitude();
    this.submitted_min_altitude = new Altitude();
    this.submitted_max_altitude = new Altitude();
    this.geography = new Polygon();
    this.circle = null;

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IOperationVolume>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.volume_type = this.getValue<volume_type>(rawValues, OperationVolume.VOLUME_TYPE_FIELD_NAME);
      this.near_structure = this.getValue<boolean>(rawValues, OperationVolume.NEAR_STRUCTURE_FIELD_NAME);
      this.effective_time_begin = this.getValue<DateTime>(rawValues, OperationVolume.EFFECTIVE_TIME_BEGIN_FIELD_NAME);
      this.effective_time_end = this.getValue<DateTime>(rawValues, OperationVolume.EFFECTIVE_TIME_END_FIELD_NAME);
      this.min_altitude.setValues(rawValues.min_altitude, useFormGroupValuesToModel);
      this.max_altitude.setValues(rawValues.max_altitude, useFormGroupValuesToModel);
      this.submitted_min_altitude.setValues(rawValues.submitted_min_altitude, useFormGroupValuesToModel);
      this.submitted_max_altitude.setValues(rawValues.submitted_max_altitude, useFormGroupValuesToModel);
      this.geography.setValues(rawValues.geography, useFormGroupValuesToModel);
      this.circle = this.getValue<GeoCircle>(rawValues, OperationVolume.CIRCLE_FIELD_NAME);
      this.submitted_radius = this.getValue<IRadius>(rawValues, OperationVolume.SUBMITTED_RADIUS_FIELD_NAME);
      this.beyond_visual_line_of_sight = this.getValue<boolean>(rawValues, OperationVolume.BEYOND_VISUAL_LINE_OF_SIGHT_FIELD_NAME);
      this.off_nominal = this.getValue<boolean>(rawValues, OperationVolume.OFF_NOMINAL_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        volume_type: new UntypedFormControl(this.volume_type, [Validators.required, enumValidator(volume_type)]),
        near_structure: new UntypedFormControl(this.near_structure),
        effective_time_begin: new UntypedFormControl(this.effective_time_begin, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24),
          Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$')]),
        effective_time_end: new UntypedFormControl(this.effective_time_end, [Validators.required,
          Validators.minLength(24), Validators.maxLength(24),
          Validators.pattern('^([0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\.[0-9]{3})Z$')]),
        min_altitude: this.min_altitude.$formGroup,
        max_altitude: this.max_altitude.$formGroup,
        submitted_min_altitude: this.submitted_min_altitude.$formGroup,
        submitted_max_altitude: this.submitted_max_altitude.$formGroup,
        operation_geography: this.geography.$formGroup,
        submitted_radius: new UntypedFormControl(this.submitted_radius),
        beyond_visual_line_of_sight: new UntypedFormControl(this.beyond_visual_line_of_sight, [Validators.required]),
        off_nominal: new UntypedFormControl(this.off_nominal, [Validators.required]),
      });
      if (this.circle) {
        this._formGroup.addControl(OperationVolume.CIRCLE_FIELD_NAME, this.circle.$formGroup);
      }
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[OperationVolume.VOLUME_TYPE_FIELD_NAME].setValue(this.volume_type);
    this.$formGroup.controls[OperationVolume.NEAR_STRUCTURE_FIELD_NAME].setValue(this.near_structure);
    this.$formGroup.controls[OperationVolume.EFFECTIVE_TIME_BEGIN_FIELD_NAME].setValue(this.effective_time_begin);
    this.$formGroup.controls[OperationVolume.EFFECTIVE_TIME_END_FIELD_NAME].setValue(this.effective_time_end);
    this.min_altitude.setFormGroupValues();
    this.max_altitude.setFormGroupValues();
    this.submitted_min_altitude.setFormGroupValues();
    this.submitted_max_altitude.setFormGroupValues();
    this.geography.setFormGroupValues();
    this.circle?.setFormGroupValues();
    this.$formGroup.controls[OperationVolume.SUBMITTED_RADIUS_FIELD_NAME].setValue(this.submitted_radius);
    this.$formGroup.controls[OperationVolume.BEYOND_VISUAL_LINE_OF_SIGHT_FIELD_NAME].setValue(this.beyond_visual_line_of_sight);
    this.$formGroup.controls[OperationVolume.OFF_NOMINAL_FIELD_NAME].setValue(this.off_nominal);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {catchError, map, shareReplay, switchMap} from 'rxjs/operators';
import {OrganizationService} from '../organization.service';
import {OrganizationMetadata} from '../../model/OrganizationMetadata';
import {of} from 'rxjs/internal/observable/of';


interface OrganizationGetResponse {
  organizations: OrganizationMetadata[];
}

@Injectable({
  providedIn: 'root'
})
export class RestOrganizationService extends OrganizationService {
  private http = inject(HttpClient);

  private refreshSubject = new BehaviorSubject<void>(null);
  organizationMetadata$ = this.refreshSubject.pipe(switchMap(() => {
    return this.http.get(`${environment.baseUrl}/userserv/api/organizations`).pipe(map((res: OrganizationGetResponse) => {
        return res?.organizations || [];
      }),
      catchError((e) => {
        console.error(e);
        return of(null);
      }));
  }), shareReplay(1));

  refresh(): void {
    this.refreshSubject.next();
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {RegistrationRoutingModule} from './registration-routing.module';
import {PlatformsComponent} from './platforms/platforms.component';
import {ClarityModule} from '@clr/angular';
import {PlatformComponent} from './platform/platform.component';
import {ComponentsModule} from '../../shared/components/components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {UsersComponent} from './users/users.component';
import {NewUserComponent} from './new-user/new-user.component';
import {NewPlatformComponent} from './new-platform/new-platform.component';
import {PermissionsModule} from '../../shared/permissions/permissions.module';
import {ViewUserComponent} from './view-user/view-user.component';
import {UpdateUserComponent} from './update-user/update-user.component';
import {CdsIconModule, CdsModule} from '@cds/angular';
import {
  PlatformSearchOptionsConfigComponent
} from './platforms/platform-search-options-config/platform-search-options-config.component';


@NgModule({
  declarations: [PlatformsComponent, PlatformSearchOptionsConfigComponent, PlatformComponent, UsersComponent,
                  NewUserComponent, NewPlatformComponent, ViewUserComponent, UpdateUserComponent],
  imports: [
    CommonModule,
    RegistrationRoutingModule,
    ClarityModule,
    CdsIconModule,
    ComponentsModule,
    FormsModule,
    PermissionsModule,
    ReactiveFormsModule,
    CdsModule
  ]
})
export class RegistrationModule {
}

import {
  AdditionalConstraintData,
  IAdditionalConstraintData,
  SwaggerAdditionalConstraintData
} from './AdditionalConstraintData';
import {Altitude, IUASVolumeReservation, UASVolumeReservation} from '../gen/utm';
import {IUASVolumeReservation as SwaggerUASVolumeReservation} from '../gen/transport/utm';
import {UntypedFormGroup} from '@angular/forms';
import {DateTime} from 'luxon';

export interface SwaggerUvrExt extends SwaggerUASVolumeReservation {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  additional_data?: SwaggerAdditionalConstraintData;
}

export interface IUvrExt extends IUASVolumeReservation {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  additional_data?: IAdditionalConstraintData;
}

export enum ConstraintState {
  /* eslint-disable @typescript-eslint/naming-convention */
  PROPOSED = 'PROPOSED',
  ACCEPTED = 'ACCEPTED',
  ACTIVE = 'ACTIVE',
  ENDED = 'ENDED'
  /* eslint-enable @typescript-eslint/naming-convention */
}

export class UvrExt extends UASVolumeReservation implements IUvrExt {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ADDITIONALDATA_FIELD_NAME = 'additional_data';
  // tslint:disable-next-line:variable-name
  additional_data: AdditionalConstraintData;
  /* eslint-enable @typescript-eslint/naming-convention */

  constructor(values?: Partial<IUvrExt>, useFormGroupValuesToModel = false) {
    super();
    this.additional_data = new AdditionalConstraintData();

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   *
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IUvrExt>, useFormGroupValuesToModel = false): void {
    super.setValues(values, useFormGroupValuesToModel);
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.additional_data.setValues(rawValues.additional_data, useFormGroupValuesToModel);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    super.setFormGroupValues();
    this.additional_data.setFormGroupValues();
    super.setFormGroupValuesInAddedFormControls();
  }

  getState(): ConstraintState {
    if ((this.actual_time_end || this.effective_time_end).diffNow().as('millisecond') <= 0) {
      return ConstraintState.ENDED;
    } else if (this.effective_time_begin.diffNow().as('millisecond') <= 0) {
      return ConstraintState.ACTIVE;
    } else {
      return ConstraintState.ACCEPTED;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  get time_end(): DateTime{
    return this.actual_time_end || this.effective_time_end;
  }

  getMinimumAltitude(): Altitude {
    let minAltitude = this.volumes[0].min_altitude;
    this.volumes.map(vol => vol.min_altitude).forEach(alt => {
      if (alt.altitude_value < minAltitude.altitude_value){
        minAltitude = alt;
      }
    });
    return minAltitude;
  }

  getMaximumAltitude(): Altitude {
    let maxAltitude = this.volumes[0].max_altitude;
    this.volumes.map(vol => vol.max_altitude).forEach(alt => {
      if (alt.altitude_value > maxAltitude.altitude_value) {
        maxAltitude = alt;
      }
    });
    return maxAltitude;
  }

  protected getFormGroup(): UntypedFormGroup {
    /* eslint-disable no-underscore-dangle */
    if (!this._formGroup) {

      this._formGroup = super.getFormGroup();
      this._formGroup.addControl('additional_data', this.additional_data.$formGroup);
    }
    return this._formGroup;
    /* eslint-enable no-underscore-dangle */
  }
}

import {Injectable} from '@angular/core';
import {IPlatformSearch, PlatformService} from '../platform.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {UasRegistrationInfo} from '../../model/UasRegistrationInfo';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {SearchResult} from '../../model/SearchResult';
import {Parser} from '../../model/utm/parser/OperationParser';
import {TransportUASRegistrationSubmission} from '../../model/IUasSubmission';
import {TelemetryIntegrationsEntry} from '../../model/TelemetryIntegrations/TelemetryIntegrationsEntry';
import {
  TelemetryIntegrations,
  TransportTelemetryIntegrations
} from '../../model/TelemetryIntegrations/TelemetryIntegrations';
import {
  SupportedTelemetryIntegrationsResponse
} from '../../model/TelemetryIntegrations/SupportedTelemetryIntegrationsResponse';
import {
  CotTelemetryIntegration,
  TransportCotTelemetryIntegration
} from '../../model/TelemetryIntegrations/CotTelemetryIntegration';
import {
  MavlinkTelemetryIntegration,
  TransportMavlinkTelemetryIntegration
} from '../../model/TelemetryIntegrations/MavlinkTelemetryIntegration';
import {
  RigitechTelemetryIntegration,
  TransportRigitechTelemetryIntegration
} from '../../model/TelemetryIntegrations/RigitechTelemetryIntegration';
import Protocol = TransportMavlinkTelemetryIntegration.Protocol;

@Injectable({
  providedIn: 'root'
})
export class RestPlatformService extends PlatformService {

  basePlatformsUrl = `${environment.baseUrl}/uasregistration/nuas`;
  basePlatformUrl = `${environment.baseUrl}/uasregistration/uas`;
  baseIntegrationsUrl = `${environment.baseUrl}/uasregistration/integrations`;

  constructor(private http: HttpClient) {
    super();
  }

  getPlatforms(searchConfig: IPlatformSearch, limit: number, offset: number, fetchCount: boolean): Observable<SearchResult<UasRegistrationInfo>> {
    const params = {
      /* eslint-disable @typescript-eslint/naming-convention */
      name: searchConfig.name,
      archived: searchConfig.archived === null ? undefined : searchConfig.archived.toString(),
      organization: searchConfig.organization,
      make: searchConfig.make,
      model: searchConfig.model,
      serial_number: searchConfig.serialNumber,
      faa_registration_number: searchConfig.faaRegistrationNumber,
      tail_number: searchConfig.tailNumber,
      sort: searchConfig.sort,
      sort_ascending: (searchConfig.sortIncreasing !== undefined && searchConfig.sort?.length > 0) ? searchConfig.sortIncreasing.toString() : null,
      limit: (limit ? limit : 10).toString(),
      offset: (offset >= 0 ? offset : 0).toString(),
      fetch_count: fetchCount.toString(),
      /* eslint-enable @typescript-eslint/naming-convention */
    };

    Object.keys(params).forEach(key => (params[key] === undefined || params[key] === null || params[key] === '') && delete params[key]);

    return this.http.get(this.basePlatformsUrl, {params})
      .pipe(map((platforms: any) => new SearchResult<UasRegistrationInfo>((platforms.uas || [])
      .map(Parser.parseUasRegistrationInfo), platforms.count, platforms.offset)));
  }

  getPlatform(platformId: string): Observable<UasRegistrationInfo> {
    return this.http.get(`${this.basePlatformUrl}/${platformId}`)
      .pipe(map((platform: any) => Parser.parseUasRegistrationInfo(platform)));
  }

  updatePlatform(platformId: string, submission: UasRegistrationInfo): Observable<boolean> {
    const transport = this.serializeUasRegistrationSubmission(submission);
    return this.http.put(`${this.basePlatformUrl}/${platformId}`, transport).pipe(map(() => true));
  }

  submitPlatform(submission: UasRegistrationInfo): Observable<boolean> {
    const transport = this.serializeUasRegistrationSubmission(submission);
    return this.http.post(this.basePlatformUrl, transport).pipe(map(() => true));
  }

  archivePlatform(platformId: string): Observable<UasRegistrationInfo> {
    return this.http.post(`${this.basePlatformUrl}/${platformId}/archive`, null, {params: {archive: true}})
      .pipe(map((platform: any) => Parser.parseUasRegistrationInfo(platform)));
  }

  unArchivePlatform(platformId: string): Observable<UasRegistrationInfo> {
    return this.http.post(`${this.basePlatformUrl}/${platformId}/archive`, null, {params: {archive: false}})
      .pipe(map((platform: any) => Parser.parseUasRegistrationInfo(platform)));
  }

  getIntegrationsForPlatform(platformId: string): Observable<TelemetryIntegrationsEntry> {
    return this.http.get(`${this.baseIntegrationsUrl}/registration/${platformId}`)
      .pipe(map((integrations: any) => Parser.parseTelemetryIntegrationsEntry(integrations)));
  }

  submitIntegrationsForPlatform(platformId: string, integrations: TelemetryIntegrations): Observable<boolean> {
    const transport = this.serializeTelemetryIntegrations(integrations);
    return this.http.put(`${this.baseIntegrationsUrl}/registration/${platformId}`, transport).pipe(map(() => true));
  }

  getSupportedIntegrations(): Observable<SupportedTelemetryIntegrationsResponse> {
    return this.http.get(`${this.baseIntegrationsUrl}/supported`)
      .pipe(map(Parser.parseSupportedTelemetryIntegrationsResponse));
  }

  private serializeUasRegistrationSubmission(values: UasRegistrationInfo): TransportUASRegistrationSubmission {
    const {countryOfOrigin, ...specs} = values.aircraftSpecs;
    return {
      archived: values.archived,
      name: values.name,
      tailNumber: values.tailNumber,
      faaRegistrationNumber: values.faaRegistrationNumber,
      ownerContact: values.ownerContact,
      ownerIsOrganization: values.ownerIsOrganization,
      aircraftSpecs: {...specs, countriesOfOrigin: countryOfOrigin ? [countryOfOrigin] : []},
      additionalRegistrations: values.additionalRegistrations,
      integrations: values.integrations ? this.serializeTelemetryIntegrations(values.integrations) : null
    }
  }

  private serializeTelemetryIntegrations(integrations: TelemetryIntegrations): TransportTelemetryIntegrations {
    return {
      cot: integrations.cot.map(cot => this.serializeCotTelemetryIntegration(cot)),
      mavlink: integrations.mavlink.map(mavlink => this.serializeMavlinkTelemetryIntegration(mavlink)),
      rigitech: integrations.rigitech.map(rigitech => this.serializeRigitechTelemetryIntegration(rigitech))
    }
  }

  private serializeCotTelemetryIntegration(cot: CotTelemetryIntegration): TransportCotTelemetryIntegration {
    return {
      cotCallsign: cot.cotCallsign,
      cotUid: cot.cotUid,
      cotAltitudeUsesMsl: cot.cotAltitudeUsesMsl,
    }
  }

  private serializeMavlinkTelemetryIntegration(mavlink: MavlinkTelemetryIntegration): TransportMavlinkTelemetryIntegration {
    return {
      protocol: Protocol[mavlink.protocol],
      port: mavlink.port
    }
  }

  private serializeRigitechTelemetryIntegration(rigitech: RigitechTelemetryIntegration): TransportRigitechTelemetryIntegration {
    return {
      rigitechDroneId: rigitech.rigitechDroneId,
      rigitechSerialNumber: rigitech.rigitechSerialNumber
    }
  }
}

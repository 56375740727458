import {Component, inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UvrExt} from '../../../model/utm/UvrExt';
import {UserService} from '../../../services/user.service';
import {Contact} from '../../../model/Contact';
import {OrganizationUtil} from '../../../utils/OrganizationUtil';
import {AdditionalConstraintData} from '../../../model/utm/AdditionalConstraintData';
import {Observable, Subscription} from 'rxjs';
import {of} from 'rxjs/internal/observable/of';
import {ConstraintTypeService} from '../../../services/constraint-type.service';
import {ConstraintService} from '../../../services/constraint.service';
import {ResponsiveScreenService} from '../../../services/responsive-screen.service';

@Component({
  selector: 'app-constraint-ext-summary',
  templateUrl: './constraint-ext-summary.component.html',
  styleUrls: ['./constraint-ext-summary.component.scss']
})
export class ConstraintExtSummaryComponent implements OnInit, OnChanges {
  @Input() constraintId: string;
  @Input() constraint: UvrExt;
  constraintUser: Contact|null;
  showGeometry = {};
  fallbackText = 'Not Available';

  deviceSize$ = inject(ResponsiveScreenService).deviceSize$;

  private constraintSub: Subscription;

  constructor(private userService: UserService, private constraintTypeService: ConstraintTypeService,
              private constraintService: ConstraintService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.constraint?.additional_data?.user_id) {
      this.userService.getUserContactInformation(this.constraint.additional_data.user_id).subscribe((contact) => {
        this.constraintUser = contact;
      });
    }
    if (changes.constraintId){
      this.loadConstraintId();
    }
  }

  ngOnInit(): void {
    this.loadConstraintId();
  }

  getPrettyOrganizationName(organization: string) {
    return OrganizationUtil.getPrettyOrganizationName(organization);
  }

  getPermittedOperations(additionalConstraintData: AdditionalConstraintData): Observable<string> {
    if (
      additionalConstraintData && additionalConstraintData.constraint_type) {
      return this.constraintTypeService.getPrettyName(additionalConstraintData.constraint_type);
    } else {
      return of('');
    }
  }

  viewVolume(i: number) {
    this.showGeometry[i] = true;
  }

  private loadConstraintId() {
    if (this.constraintId && !this.constraint){
      this.constraintSub?.unsubscribe();
      this.constraintSub = this.constraintService.getConstraint(this.constraintId).subscribe(constraint => {
        this.constraint = constraint;
      });
    }
  }
}

<div class="alert alert-danger" role="alert">
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
      </div>


      <span class="alert-text" *ngIf="(!page && !component) || (page && component)">This page/component is a work in progress. </span>
      <span class="alert-text" *ngIf="page">This page is a work in progress.</span>
      <span class="alert-text" *ngIf="component">This component is a work in progress.</span>
    </div>
  </div>
</div>

<p *ngIf="purpose"><span style="font-weight: bold">Purpose:</span> {{purpose}}</p>
<br/>

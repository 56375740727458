import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import tinycolor from 'tinycolor2';
import {state} from '../model/gen/utm';


export type ColorId =
  'default'
  | 'operation-accepted'
  | 'operation-active'
  | 'operation-closed'
  | 'operation-ended'
  | 'operation-non-conforming'
  | 'operation-contingent'
  | 'operation-rejected'
  | 'operation'
  | 'constraint'
  | 'constraint-accepted'
  | 'constraint-active'
  | 'constraint-ended'
  | string;

export interface ColorConfig {
  id: ColorId;
  fill: tinycolor.Instance;
  outline?: tinycolor.Instance;
}

export type ColorConfigCollection = Map<ColorId, ColorConfig>;

@Injectable({
  providedIn: 'root'
})
export abstract class ColorService {

  protected constructor() {
    // Method not implemented
  }

  abstract hasColor(id: string): Observable<boolean>;

  abstract getColorForId(id: ColorId, useDefault: boolean): Observable<ColorConfig | null>;

  abstract getIdForState(opState: state): ColorId;

}


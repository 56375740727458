<div [formGroup]="fg" class="clr-form-control clr-row file-upload-wrapper">
  <div class="clr-control-container clr-col-12 clr-col-md-9">
    <clr-input-container>
      <label>Load from file</label>
      <input autocomplete="off"  #importFileControl clrInput type="file" [accept]="extensions"
             formControlName="file"/>
      <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
    </clr-input-container>
  </div>
</div>

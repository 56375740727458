import {unit} from '../../utils/convert-units/definitions/base';

function unitPair(m: unit, i: unit): {
  METRIC: unit;
  IMPERIAL: unit;
} {
  return {
    METRIC: m,
    IMPERIAL: i
  };
}

export const fieldUnits = {
  weight: unitPair('kg', 'lb'),
  maxRoundTripRange: unitPair('km', 'mi'),
  maxAirspeed: unitPair('km/h', 'knot'),
  maxVerticalSpeed: unitPair('m/s', 'ft/s'),
  maxVerticalAcceleration: unitPair('m/s2', 'ft/s2'),
  lengthWidthHeight: unitPair('cm', 'in'),
  operatingTemperature: unitPair('C', 'F'),
  fuelCapacity: unitPair('l', 'gal')
};

export interface FieldLabels {
  weight: string;
  maxRoundTripRange: string;
  maxAirspeed: string;
  maxVerticalSpeed: string;
  maxVerticalAcceleration: string;
  lengthWidthHeight: string;
  operatingTemperature: string;
  fuelCapacity: string;
}

export const metricLabels: FieldLabels = {
  weight: 'Kilograms',
  maxRoundTripRange: 'Kilometers',
  maxAirspeed: 'Kilometers/Hour',
  maxVerticalSpeed: 'Meters/Second',
  maxVerticalAcceleration: 'Meters/Second',
  lengthWidthHeight: 'Centimeters',
  operatingTemperature: 'Celsius',
  fuelCapacity: 'Liters'
};

export const imperialLabels: FieldLabels = {
  weight: 'Pounds',
  maxRoundTripRange: 'Miles',
  maxAirspeed: 'Knots',
  maxVerticalSpeed: 'Feet/Second',
  maxVerticalAcceleration: 'Feet/Second',
  lengthWidthHeight: 'Inches',
  operatingTemperature: 'Fahrenheit',
  fuelCapacity: 'Gallons'
};

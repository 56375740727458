import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IOperationSubmission} from '../model/OperationSubmission';
import {IStandardResponse} from '../model/StandardResponse';

export interface OperationDraft{
  id: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  user_id: string;
  submission: Partial<IOperationSubmission>;
}

export interface OperationDraftResponse extends IStandardResponse{
  id: string;
}

export interface IOperationDraftSearchParameters {
  ids?: string[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  user_id?: string;
  limit?: number;
  offset?: number;
  fetchCount?: boolean;
}

export interface OperationDraftSearchResults{
  offset: number;
  count: number;
  drafts: OperationDraft[];
}

export class OperationDraftSearchParameters implements IOperationDraftSearchParameters {
  ids?: string[];
  // eslint-disable-next-line @typescript-eslint/naming-convention
  user_id?: string;
  limit?: number;
  offset?: number;
  fetchCount?: boolean;

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  public constructor() {
    // Method not implemented
  }

  public static defaultSearch(): OperationDraftSearchParameters {
    const tmp = new OperationDraftSearchParameters();
    tmp.limit = 100;
    tmp.offset = 0;
    return tmp;
  }
}




@Injectable({
  providedIn: 'root'
})
export abstract class OperationDraftService {

  abstract saveDraftOperation(id: string|null, draft: Partial<IOperationSubmission>): Observable<OperationDraftResponse>;

  abstract deleteDraft(draftId: string): Observable<boolean>;

  abstract getDraftFromUUID(draftId: string): Observable<OperationDraft | null>;

  abstract getDrafts(): Observable<OperationDraft[]>;



}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.scss']
})
export class CopyToClipboardComponent {
  @Input() text: string;

  constructor() {
    // Constructor intentionally left empty
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.text).then(() => {
      console.log('Copied to clipboard:', this.text);
    }).catch(error => {
        console.error('Failed to copy to clipboard:', error);
    });
  }
}

import {computed, inject, Injectable, signal} from '@angular/core';
import {toSignal} from "@angular/core/rxjs-interop";
import {UserSettingsService} from "./user-settings.service";
import {MeasurementSystemType} from "../model/MeasurementSystem";

@Injectable({
  providedIn: 'root'
})
export class MeasurementSystemPreferenceService {
  private userSettingsService = inject(UserSettingsService);

  userPreferenceMeasurementSystem$ = toSignal(this.userSettingsService.getMeasurementSystem(), {
    initialValue: MeasurementSystemType.METRIC
  });

  overrideUserPreferenceMeasurementSystem$ = signal<MeasurementSystemType>(null);

  measurementSystem$ = computed(() => {
    const preference = this.userPreferenceMeasurementSystem$();
    const override = this.overrideUserPreferenceMeasurementSystem$();
    return override||preference;
  });

}

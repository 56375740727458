import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ConstraintsComponent} from './constraints/constraints.component';
import {NewConstraintComponent} from './new-constraint/new-constraint.component';
import {ViewConstraintComponent} from './view-constraint/view-constraint.component';
import {PermissionGuardService} from '../../shared/permissions/PermissionGuardService';
import {AuthGuard} from '../../auth/auth.guard';


const routes: Routes = [
  {path: 'constraints', component: ConstraintsComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('view_constraint')]},
  {path: 'new-constraint', component: NewConstraintComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('submit_constraint')]},
  {path: 'view-constraint', component: ViewConstraintComponent, canActivate: [AuthGuard, PermissionGuardService.forPermission('view_constraint')]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConstraintRoutingModule { }

import {Component, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FormControlify} from '../../utils/forms';
import {UserMessageService} from '../../services/user-message.service';
import {
  DirectMessageTargetIdService,
  IMessageTarget,
  TargetEnum
} from '../../services/direct-message-target-id.service';
import {combineLatest, Subscription} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {CurrentUserService} from '../../services/current-user.service';
import {ContactSearchSortField, ContactService} from '../../services/contact.service';
import {Contact} from '../../model/Contact';

interface IMessageStatus {
  state: MessageSubmissionState;
  error?: string;
}

interface IMessageFG {
  message: string;
  userID?: string;
}

enum MessageSubmissionState {
  NONE = 'None',
  PENDING = 'Pending',
  SUCCESS = 'Success',
  ERROR = 'Error'
}

@Component({
  selector: 'app-send-direct-message-modal',
  templateUrl: './send-direct-message-modal.component.html',
  styleUrls: ['./send-direct-message-modal.component.scss']
})
export class SendDirectMessageModalComponent implements OnDestroy {
  messageState = MessageSubmissionState;
  messageStatus: IMessageStatus = {state: MessageSubmissionState.NONE};
  messageFG: FormGroup<FormControlify<IMessageFG>>;
  showSendMessageModal = false;
  messageTarget: IMessageTarget;
  targetEnum = TargetEnum;
  availableContacts: Contact[] = [];

  private messageTargetIDSub: Subscription;
  private usersSub: Subscription;

  constructor(private userMessageService: UserMessageService,
              private directMessageTargetIdService: DirectMessageTargetIdService,
              private contactService: ContactService,
              private currentUserService: CurrentUserService) {
    this.messageFG = new FormGroup<FormControlify<IMessageFG>>({
      message: new FormControl<string>('', [Validators.required])
    });

    this.messageTargetIDSub = this.directMessageTargetIdService.watchTarget().subscribe(target => {
      this.messageTarget = target;
      if (target) {
        this.messageFG.reset();
        this.messageStatus = {state: MessageSubmissionState.NONE};
        this.availableContacts = [];

        if (this.messageTarget.targetType === TargetEnum.USER) {
          // If the send message modal is in user mode:
          // 1. Add the userID control if it doesn't exist
          if (!this.messageFG.contains('userID')) {
            this.messageFG.addControl('userID', new FormControl<string>(null, Validators.required));
          }

          // 2. Get the list of available users
          this.usersSub = combineLatest([
            this.contactService.getContacts({isUserContact: true, sort: ContactSearchSortField.lastName}),
            this.currentUserService.currentUser
          ]).subscribe(([contactsResult, currentUser]) => {
            this.availableContacts = contactsResult.results.filter(contact => contact.userId !== currentUser.uid);
          });
        } else if (this.messageFG.contains('userID')) {
          // If the send message modal is not in user mode, but the userID control exists, remove it
          this.messageFG.removeControl('userID');
        }

        this.showSendMessageModal = true;
      }
    });
  }

  sendUserDirectMessage() {
    this.messageStatus = {state: MessageSubmissionState.PENDING};
    const rawValues = this.messageFG.getRawValue();
    if (this.messageTarget.targetType === TargetEnum.OPERATION) {
      // Send message to operation
      this.userMessageService.sendMessageForOperation(this.messageTarget.id, rawValues).subscribe(() => {
        this.messageStatus = {state: MessageSubmissionState.SUCCESS};
        this.clearMessageForm();
      }, (error: HttpErrorResponse) => {
        this.messageStatus = {
          state: MessageSubmissionState.ERROR,
          error: 'Error: ' + (error?.error?.message || 'Unknown error sending message')
        };
        this.clearMessageForm();
      });
    } else if (this.messageTarget.targetType === TargetEnum.USER) {
      // Send message to user
      this.userMessageService.sendMessageToUser(rawValues.userID, {message: rawValues.message}).subscribe(() => {
        this.messageStatus = {state: MessageSubmissionState.SUCCESS};
        this.clearMessageForm();
      }, (error: HttpErrorResponse) => {
        this.messageStatus = {
          state: MessageSubmissionState.ERROR,
          error: 'Error: ' + (error?.error?.message || 'Unknown error sending message')
        };
        this.clearMessageForm();
      });
    }
  }

  ngOnDestroy(): void {
    this.messageTargetIDSub?.unsubscribe();
    this.usersSub?.unsubscribe();
  }

  private clearMessageForm() {
    this.messageFG.reset();
    this.messageTarget = null;
  }
}

import {Component, OnInit} from '@angular/core';
import {UvrExt} from '../../../shared/model/utm/UvrExt';
import {ActivatedRoute} from '@angular/router';
import {ConstraintService} from '../../../shared/services/constraint.service';

@Component({
  selector: 'app-view-constraint',
  templateUrl: './view-constraint.component.html'
})
export class ViewConstraintComponent implements OnInit {
  constraint: UvrExt;
  notFound: boolean;

  constructor(private route: ActivatedRoute, private constraintService: ConstraintService) {
  }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
          if (params.constraintId) {
            this.constraintService.getConstraint(params.constraintId).subscribe((constraint) => {
              this.constraint = constraint;
              this.notFound = false;
            }, () => this.notFound = true);
            this.constraintService.watchConstraint(params.constraintId).subscribe(() => {
            });
          }
        }
      );
  }

}

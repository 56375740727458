import {AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {DateTime, Duration} from 'luxon';
import {Altitude, CIRCLE_LIMITS, units_of_measure} from '../model/gen/utm';
import * as _ from 'lodash';
import {isNil} from 'lodash';
import {LatLngPoint, Point} from '@ax/ax-angular-map-common';
import {distinctUntilChanged, first, map} from 'rxjs/operators';
import {AltitudeRange} from '../model/gen/utm/altitude-range-model';
import {OperationUtil} from '../model/OperationUtil';
import {HeightUnitsPair} from '../components/altitude-range-selector/altitude-range-selector.component';
import {TimeRangeSelectorFG} from '../components/time-range-selector/time-range-selector.component';
import {unicodeControlChars} from "./unicode-control-chars";
import {kinks} from '@turf/turf';
import {polygon as turfPolygon} from '@turf/turf';

export const getPrettyDuration = (duration: Duration): string => {
  let prettyDuration = '';
  let newDuration: Duration = duration;

  if (duration.toMillis() < 0) {
    newDuration = Duration.fromMillis(duration.toMillis() * -1);
  }

  newDuration = newDuration.shiftTo('days', 'hours', 'minutes', 'seconds', 'milliseconds');
  if (newDuration.days) {
    if (newDuration.days > 1) {
      prettyDuration += `${newDuration.days} days `;
    } else {
      prettyDuration += `${newDuration.days} day `;
    }
  }
  if (newDuration.hours) {
    prettyDuration += `${newDuration.hours} hr. `;
  }
  if (newDuration.minutes) {
    prettyDuration += `${newDuration.minutes} min. `;
  }
  if (newDuration.seconds) {
    prettyDuration += `${newDuration.seconds} sec.`;
  }
  return prettyDuration;
};

// Time Validators

export const timeRangeAboveValidator = (beginTimeControl: AbstractControl<DateTime>) => (control: AbstractControl<DateTime>) => {
  let start: DateTime = beginTimeControl.value || DateTime.now();
  const end: DateTime = control.value;
  if (!end) {
    return null;
  }
  const rangeError = (Math.floor(end.diff(start).as('seconds')) <= 0);
  if (rangeError) {
    return {range: true};
  } else {
    return null;
  }
};

export function validateMinTime(offset: Duration | DateTime, time: DateTime): ValidationErrors | null {
  if (!time) {
    return null;
  }

  if (offset instanceof Duration) {
    if (time >= DateTime.now().plus(offset)) {
      return null;
    } else {
      if (offset.toMillis() >= 0) {
        return {startTime: `Start time must be at least ${getPrettyDuration(offset)} in the future.`};
      } else {
        return {startTime: `Start time cannot be more than ${getPrettyDuration(offset)} in the past.`};
      }
    }
  } else {
    if (Math.floor(offset.diff(time).as('seconds')) > 0) {
      return {startTime: `Replanned start time cannot be earlier than the original start time (${offset.toString()}).`};
    } else {
      return null;
    }
  }
}

export const minTimeValidator = (time: Duration | DateTime): ValidatorFn => (control) => {
  return validateMinTime(time, control.value);
};

export function validateMaxTime(offset: Duration, time: DateTime): ValidationErrors | null {
  if (!time) {
    return null;
  }

  if (time <= DateTime.now().plus(offset)) {
    return null;
  } else {
    return {startTime: `Start time cannot be more than ${getPrettyDuration(offset)} in the future.`};
  }
}

export const maxTimeValidator = (time: Duration): ValidatorFn => (control) => {
  return validateMaxTime(time, control.value);
};

export function validateMinTimeDuration(duration: Duration, timeRange: TimeRangeSelectorFG): ValidationErrors | null {
  if (!timeRange.endTime) {
    return null;
  }

  if (timeRange.endTime.diff(timeRange.startTime || DateTime.now()).toMillis() >= duration.toMillis()) {
    return null;
  } else {
    return {duration: `Volume duration must be at least ${getPrettyDuration(duration)}`};
  }
}

export const minTimeDurationValidator = (duration: Duration): ValidatorFn => (control): ValidationErrors | null => {
  return validateMinTimeDuration(duration, control.value);
};

export function validateMaxTimeDuration(duration: Duration, timeRange: TimeRangeSelectorFG): ValidationErrors | null {
  if (!timeRange.startTime || !timeRange.endTime) {
    return null;
  }

  if (timeRange.endTime.diff(timeRange.startTime).toMillis() <= duration.toMillis()) {
    return null;
  } else {
    return {duration: `Volume duration cannot be more than ${getPrettyDuration(duration)}`};
  }
}

export const maxTimeDurationValidator = (duration: Duration): ValidatorFn => (control): ValidationErrors | null => {
  return validateMaxTimeDuration(duration, control.value);
};

export const timeAfterValidatorASync = (beginTimeControl: FormControl<DateTime>) => {
  const fn: AsyncValidatorFn = (control: FormControl<DateTime>) => control.valueChanges
    .pipe(
      distinctUntilChanged(),
      map((end: DateTime) => {
          const start: DateTime = beginTimeControl.value;
          if (start === null || end === null) {
            return null;
          }
          const rangeError = (Math.floor(end.diff(start).as('seconds')) <= 0);
          if (rangeError) {
            return {range: true};
          } else {
            return null;
          }

        }
      ),
      first()
    );
  return fn;
};

// Altitude Validators

export const altitudeRangeAboveValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const range: AltitudeRange = control.value;

  if ((!isNil(range.min_altitude) && !isNil(range.max_altitude)) && (range.min_altitude > range.max_altitude)) {
    return {range: 'Minimum altitude must be lower than the maximum.'};
  }
  return null;
};

export const minVolHeightValidator = (minHeight: HeightUnitsPair): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
  const range: AltitudeRange = control.value;

  if ((!range.min_altitude && range.min_altitude !== 0) || (!range.max_altitude && range.max_altitude !== 0) ||
    !range.altitude_units) {
    return null;
  }

  if (minHeight.units !== units_of_measure.M) {
    minHeight.height = OperationUtil.toM(minHeight.height, minHeight.units);
    minHeight.units = units_of_measure.M;
  }

  if (OperationUtil.toM(range.max_altitude, range.altitude_units) -
      OperationUtil.toM(range.min_altitude, range.altitude_units) < minHeight.height) {
    return{minHeight: 'Volume height must be at least 5 M/16.4 FT'};
  } else {
    return null;
  }
};

export const altitudeAboveValidator = (minAltitudeControl: AbstractControl) => (maxAltitudeControl: AbstractControl) => {
  const start: Altitude = minAltitudeControl.value;
  const end: Altitude = maxAltitudeControl.value;
  if (!start || !end) {
    return null;
  }
  if (_.isNil(start.altitude_value) || _.isNil(end.altitude_value)) {
    return null;
  }
  return (
    start.units_of_measure === end.units_of_measure &&
    start.vertical_reference === end.vertical_reference &&
    end.altitude_value > start.altitude_value
  ) ? null : {range: 'Altitude mismatch'};

};

// Geometry Validators
/**
 * Checks if the operation volume has either a geometry or circle defined
 */
export function geometryRequiredValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const ret: ValidationErrors = {};
    const vols = control.value;

    if (vols.length) {
      vols.forEach((vol, i) => {
        if (!vol.geography && !vol.circle) {
          ret[`volume_${i+1}_geometry_missing`] = `Geometry for volume ${i+1} is missing`;
        } else {
          if ((vol.geography && !vol.geography.coordinates?.length) ||
              (vol.circle && (_.isNil(vol.circle.latitude) || _.isNil(vol.circle.longitude)))) {
            ret[`volume_${i+1}_coordinates_missing`] = `Coordinates for volume ${i+1} are missing`;
          }
          if (vol.circle && _.isNil(vol.circle.radius)) {
            ret[`volume_${i+1}_radius_missing`] = `Radius for volume ${i+1} is missing`;
          }
        }
      });
    }
    return ret;
  }
}

export function geometrySelfIntersectionValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const ret: ValidationErrors = {};
    const vols = control.value

    if (vols.length) {
      vols.forEach((vol, i) => {
        if (vol.geography && vol.geography.coordinates?.length) {
          const polygon = turfPolygon(vol.geography.coordinates);
          const intersections = kinks(polygon);
          if (!!intersections.features.length) {
            ret[`volume_${i+1}_self_intersecting`] = `Volume ${i+1} is self-intersecting`;
          }
        }
      });
    }

    return ret;
  }
}

/**
 * Checks whether the circle radius is within the defined min and max limits
 */
export function volumeRadiusRangeValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    const ret: ValidationErrors = {};
    const vols = control.value;

    if (vols.length) {
      vols.forEach((vol, i) => {
        if (vol.circle && !_.isNil(vol.circle.radius)) {
          const radiusMeters = vol.circle.radiusMeters;

          if (radiusMeters < CIRCLE_LIMITS.MIN_RADIUS.METERS) {
            ret[`volume_${i + 1}_min_radius`] = `Radius of volume ${i + 1} cannot be less than ${CIRCLE_LIMITS.MIN_RADIUS.METERS} M/${CIRCLE_LIMITS.MIN_RADIUS.FEET} FT`;
          } else if (radiusMeters > CIRCLE_LIMITS.MAX_RADIUS.METERS) {
            ret[`volume_${i + 1}_max_radius`] = `Radius of volume ${i + 1} cannot be more than ${CIRCLE_LIMITS.MAX_RADIUS.METERS} M/${CIRCLE_LIMITS.MAX_RADIUS.FEET} FT`;
          }
        }
      });
    }
    return ret;
  }
}

// General Validators

export const isInteger: ValidatorFn = (ac: AbstractControl) => {
  const value = ac.value;
  // eslint-disable-next-line eqeqeq
  if ((value === null || value === undefined) || ((parseFloat(value) === parseInt(value, 10)) && !Number.isNaN(value))) {
    return null;
  } else {
    return {
      notInteger: true
    };
  }
};


export const conditionalValidator = (predicate: () => boolean, validator: ValidatorFn | ValidatorFn[]): ValidatorFn => (formControl => {
  if (!formControl.parent) {
    return null;
  }
  if (predicate()) {
    if (typeof validator === 'function') {
      return validator(formControl);
    } else {
      const validators: ValidatorFn[] = validator;

      const errors = validators.map(fn => fn(formControl)).filter(res => res);
      if (errors.length > 0) {
        return Object.assign({}, ...errors);
      } else {
        return null;
      }
    }
  }
  return null;
});

export const condVal = conditionalValidator;

/**
 * Checks if at least one field in the provided array of field names has a value in the respective form group
 * @param fields The fields to check
 */
export const minOneRequiredFieldValidator = (fields: string[]) => (control: AbstractControl) => {
  const fg = control as FormGroup;
  if (!fields.length || !fg.getRawValue()) {
    return null;
  }
  const truthyFieldFound = fields.some((field) => (!isNil(fg.controls[field]?.value) && fg.controls[field]?.value !== ""));
  return truthyFieldFound ? null : {fieldMissing: true};
}

export const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password: string = control.value?.password;
  const passwordConfirmation: string = control.value?.passwordConfirmation;
  if (!password || !passwordConfirmation) {
    return null;
  }
  return (password === passwordConfirmation) ? null : {passwordConfirmation: 'Passwords do not match'};
};

export const isGeoPoint: ValidatorFn = (ac: AbstractControl) => {
  const value: Point = ac.value;
  // eslint-disable-next-line eqeqeq
  if ((value === null || value === undefined) || (value?.coordinates?.length === 2) && value.coordinates.every(v => !Number.isNaN(v) && (v <= 180 && v >= -180))) {
    return null;
  } else {
    return {
      notPoint: true
    };
  }
};

export const isCoordinate = (v?: number) => !Number.isNaN(v) && (v <= 180 && v >= -180);
export const isLatLngPoint: ValidatorFn = (ac: AbstractControl) => {
  const value: LatLngPoint = ac.value;
  // eslint-disable-next-line eqeqeq
  if ((value === null || value === undefined) || (isCoordinate(value.lat) && isCoordinate(value.lng))) {
    return null;
  } else {
    return {
      notPoint: true
    };
  }
};

export const greaterThan = (min: number): ValidatorFn => (ac: AbstractControl): ValidationErrors => {
  const value = ac.value;
  if (_.isNil(value)) {
    return null;
  } else {
    return value > min ? null : {greaterThan: {min, actual: value}};
  }
};

/**
 * Checks whether a form control's value is null
 * This is used to communicate whether a child component using ControlValueAccessor is valid to parent form.
 * For this to work, the child component should emit a null value for the control when invalid.
 */
export const isNullValidator: ValidatorFn = (ac: AbstractControl) => {
  return ac.value === null ? {isNull: true} : null;
};

// Input Character Validators

/**
 * These negated regex patterns are used to check input values against the set of allowed characters.
 */
export const forbiddenPatternRegexes = {
  upperAlphanumeric: /[^A-Z0-9]/g,
  alphanumericWithSpecialCharsSpaces: /[^ a-zA-Z0-9#$%&!?'()*+./@_~-]/g,
  alphanumericWithSpecialCharsSpacesCommas: /[^ a-zA-Z0-9#$%&!?'()*+.,/@_~-]/g,
  alphaOnly: /[^a-zA-Z.]/,
  firstMiddleName: /[^a-zA-Z0-9-]/g,
  lastName: /[^a-zA-Z0-9'-]/g,
  suffixName: /[^a-zA-Z0-9.]/g,
  division: /[^ a-zA-Z0-9!@#$%^&*(),.-]/g,
  title: /[^ a-zA-Z0-9!@#$%^&*(),.-]/g,
  password: /[^a-zA-Z0-9"#$%&!?'()*+./=@_~-]/g,
  serialNumber: /[^A-Z0-9.-]/g,
  telemetryIntegrations: /[^ a-zA-Z0-9#$%&!?'()*+./@_~-]/g
};

/**
 * These regex patterns are used to check input values against a specified pattern.
 */
export const enforcedPatternRegexes = {
  phone: /^(\+\d{1,3}( )?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{4}$|^(\+\d{1,3}( )?)?(\d{3}[ ]?){2}\d{3}$|^(\+\d{1,3}( )?)?(\d{3}[ ]?)(\d{2}[ ]?){2}\d{2}$/,
  ipRating: /^[0-9]{2}$/,
};

/**
 * Checks if the input value contains any invalid characters
 * @param regex The regex of invalid characters to test the input value against
 */
export const invalidCharactersValidator = (regex: RegExp): ValidatorFn => (ac: AbstractControl): ValidationErrors  => {
  if (ac.value) {
    let invalidChars = ac.value.match(regex);
    if (invalidChars?.length) {
      invalidChars = invalidChars.map(char => {
        const charCode = char.charCodeAt(0);
        if (unicodeControlChars[charCode]) {
          return `[${unicodeControlChars[charCode]}]`;
        } else {
          return char;
        }
      });
      return {invalidCharacters: {characters: _.uniq(invalidChars).join(' ')}};
    }
  }
  return null;
};

export const decimalPrecisionValidator = (places: number): ValidatorFn => (ac: AbstractControl): ValidationErrors => {
  if (ac.value) {
    const regex = new RegExp('^-?[0-9]*(\.[0-9]{0,' + places + '})?$');
    if (!regex.test(ac.value)) {
      return {decimalPrecision: {allowedPlaces: places}};
    }
  }
  return null;
};

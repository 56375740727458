import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators,} from "@angular/forms";
import {Component, computed, effect, forwardRef, inject, input, signal, Type,} from "@angular/core";
import {ClarityModule, ClrCommonFormsModule, ClrInputModule,} from "@clr/angular";
import {MeasurementSystemType} from "../../../../../model/MeasurementSystem";
import {toObservable, toSignal} from "@angular/core/rxjs-interop";
import {OperationGeometry} from "../../../../../model/operation.geometry";
import {concat, Observable} from "rxjs";
import {
  IOpVolumeSubmissionFG
} from "../../../../../components/operation/operation-geometry-editor/operation-geometry-editor.component";
import {AltitudeRange} from "../../../../../model/gen/utm/altitude-range-model";
import {TimeRange} from "../../../../../model/TimeRange";
import {map, startWith, switchMap, tap} from "rxjs/operators";
import {MeasurementSystemPreferenceService} from "../../../../measurement-system-preference.service";
import {of} from "rxjs/internal/observable/of";

export interface UTMFlightPlan {
  toOperationGeometry(): Observable<OperationGeometry>;
}

export interface UTMFlightPlanCustomizerComponent {
}

export abstract class UTMFlightPlanCustomizer {
  abstract status$: Observable<boolean>;

  abstract getConfigurationComponent(): { component: Type<any>; inputs: any };

  abstract toOperationGeometry(): Observable<OperationGeometry>;

  toOperationVolumes(): Observable<IOpVolumeSubmissionFG[]> {
    const geomObs = this.toOperationGeometry();
    return geomObs.pipe(
      map((geom) => {
        const volumes = geom.getVolumes();
        const results: IOpVolumeSubmissionFG[] = [];

        for (const volume of volumes) {
          results.push({
            geography: volume.geography,
            circle: volume.circle,
            altitudeRange: new AltitudeRange({
              altitude_vertical_reference:
              volume.min_altitude.vertical_reference,
              altitude_units: volume.min_altitude.units_of_measure,
              min_altitude: volume.min_altitude.altitude_value,
              max_altitude: volume.max_altitude.altitude_value,
            }),
            timeRange: new TimeRange(
              volume.effective_time_begin,
              volume.effective_time_end,
            ),
          });
        }

        return results;
      }),
    );
  }
}


@Component({
  selector: "app-buffer-distance-input",
  imports: [
    ClrInputModule as Type<any>,
    ClarityModule as Type<any>,
    ReactiveFormsModule as Type<any>,
    ClrCommonFormsModule,
  ],
  template: `
    <clr-input-container>
      <label>{{ label() }} Buffer Distance ({{ units() }})</label>
      <input
        autocomplete="off"
        clrInput
        type="number"
        [formControl]="bufferDistanceControl$()"
      />
      <clr-control-helper>{{ label() }} distance from the flight path to generate a buffer.</clr-control-helper>
      <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
      <clr-control-error *clrIfError="'min'">
        Value must be greater than {{ bufferDistanceControl$().errors.min.min }} {{ units() }}.
      </clr-control-error>
    </clr-input-container>
  `,
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => BufferDistanceInput),
    },
  ],
})
export class BufferDistanceInput implements ControlValueAccessor {
  horizontal = input<boolean>(true)
  label = computed(() => {
    return this.horizontal() ? 'Horizontal' : 'Vertical';
  });

  measurementSystem$ = inject(MeasurementSystemPreferenceService).measurementSystem$;
  units = computed(() => {
    return this.measurementSystem$() === MeasurementSystemType.IMPERIAL ? 'Feet' : 'Meters';
  });

  protected readonly measurementSystemType = MeasurementSystemType;
  private previousControl: FormControl<number> = new FormControl<number>(0, [
    Validators.required,
    Validators.min(1),

  ]);

  bufferDistanceControl$ = computed(() => {
    const validators = this.validators$();
    this.previousControl = new FormControl<number>(this.previousControl.value, validators);
    return this.previousControl;
  });

  minValue$ = computed(() => {
    const measurementSystem = this.measurementSystem$();
    const horizontal = this.horizontal();
    let min = horizontal ? 1 : 5;
    if (measurementSystem === MeasurementSystemType.IMPERIAL) {
      min = min * 3.28084;
    }
    return min;
  });

  validators$ = computed(() => {
    const min = this.minValue$();

    return [
      Validators.required,
      Validators.min(min),
    ];
  });
  private value = toSignal(toObservable(this.bufferDistanceControl$).pipe(
    switchMap((control) => control.valueChanges.pipe(startWith(control.value)))
  ));


  private onChange = signal<(number) => void>(null);
  private onTouched: () => void;

  constructor() {

    effect(() => {
      const onChange = this.onChange();
      const control = this.bufferDistanceControl$();
      let value = this.value();

      if (!onChange) {
        return;
      }

      if (control.invalid) {
        onChange(null);
        return;
      }

      const measurementSystem = this.measurementSystem$();
      if (measurementSystem === MeasurementSystemType.IMPERIAL) {
        value = value / 3.28084;
      }

      onChange(value);
    });
  }

  registerOnChange(fn: (number) => void): void {
    this.onChange.set(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: number): void {
    let writtenValue = value;
    const measurementSystem = this.measurementSystem$();

    if (writtenValue === null) {
      return;
    }
    if (measurementSystem === MeasurementSystemType.IMPERIAL) {
      writtenValue = writtenValue * 3.28084;
    }
    this.bufferDistanceControl$().setValue(writtenValue, {emitEvent: true});
  }
}

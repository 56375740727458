import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {Subscription} from 'rxjs';
import {OperationExt} from '../../../model/utm/OperationExt';
import {OperationService} from '../../../services/operation.service';

@Component({
  selector: 'app-ax-operation-ext-summary',
  templateUrl: './ax-operation-ext-summary.component.html',
  styleUrls: ['./ax-operation-ext-summary.component.scss']
})
export class AxOperationExtSummaryComponent implements OnInit {
  @Input() operationId: string;
  @Input() operation: OperationExt;
  @Input() simplifiedDisplay = false;

  private operationSub: Subscription;

  constructor(private operationService: OperationService) {

  }

  ngOnInit(): void {
    this.loadOperationId();
  }

  ngOnChanges(changes: SimpleChanges): void{
    if(changes.operationId){
      this.loadOperationId();
    }
  }

  getAirspaces(rawAirspaceText: string): string[] {
    if (rawAirspaceText) {
      return rawAirspaceText
        .replace(/class-/g, '')
        .replace(/sua-/g, '').split(',');
    } else {
      return ['N/A'];
    }
  }

  private loadOperationId() {
    if(!this.operation && this.operationId){
      this.operationSub?.unsubscribe();
      this.operationSub = this.operationService.getOperation(this.operationId).subscribe(op =>{
        this.operation = op;
      });
    }
  }
}

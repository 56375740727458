import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {ColorConfig} from "../../../services/color.service";
import {CIRCLE_LIMITS, GeoCircle, units_of_measure} from "../../../model/gen/utm";
import {Subscription} from "rxjs";
import {LeafletEvent} from "leaflet";
import {LeafletDrawerService} from "@ax/ax-angular-map-leaflet";

@Component({
  selector: 'app-leaflet-circle-creator',
  template: '',
  styleUrls: []
})
export class LeafletCircleCreatorComponent implements OnDestroy {
  @Input() name = 'CustomCircle';
  @Input() title = 'Custom Circle';
  @Input() colorConfig: ColorConfig;
  @Input() selectedUnits: units_of_measure;
  @Output() newVolume: EventEmitter<GeoCircle> = new EventEmitter<GeoCircle>();

  private leafletInitSub: Subscription;
  private cb: (e: (LeafletEvent & { shape: string })) => void;
  private map: L.Map;

  constructor(private leafletDrawerService: LeafletDrawerService) {
    this.leafletInitSub = this.leafletDrawerService.watchViewerInit().subscribe(mapy => {
      this.map = mapy;
      mapy.pm.Toolbar.copyDrawControl('Circle', {
        name: this.name, block: 'draw', title: this.title
      });
      mapy.pm.Draw[this.name].options.templineStyle = {color: this.colorConfig.fill.toHexString()};
      mapy.pm.Draw[this.name].options.hintlineStyle = {color: this.colorConfig.fill.toHexString()};
      mapy.pm.Draw[this.name].options.minRadiusCircle = CIRCLE_LIMITS.MIN_RADIUS.METERS;
      mapy.pm.Draw[this.name].options.maxRadiusCircle = CIRCLE_LIMITS.MAX_RADIUS.METERS;

      this.cb = (e: LeafletEvent & { shape: string }) => {
        if (e.shape !== this.name) {
          return;
        }
        const latLon = e.layer.getLatLng();
        const newCircle = new GeoCircle({
          latitude: latLon.lat,
          longitude: latLon.lng,
          radius: 0,
          units: this.selectedUnits || units_of_measure.M
        });
        newCircle.radiusMeters = e.layer.getRadius();

        this.map.removeLayer(e.layer);
        this.newVolume.next(newCircle);

      };
      mapy.on('pm:create', this.cb);
    });
  }

  ngOnDestroy(): void {
    this.leafletInitSub?.unsubscribe();
    if (this.map && this.cb) {
      this.map.off('pm:create', this.cb);
    }
  }

}

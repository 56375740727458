import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {ClrDatagrid, ClrDatagridColumn, ClrDatagridSortOrder, ClrDatagridStateInterface} from '@clr/angular';
import {DateTime} from 'luxon';
import {SearchResult} from '../../../shared/model/SearchResult';
import {
  ConstraintSearchParameters,
  ConstraintSearchSortField,
  ConstraintSearchSortFieldUtil,
  ConstraintService,
  IConstraintSearchParameters
} from '../../../shared/services/constraint.service';
import {ActivatedRoute, ParamMap, Params, Router} from '@angular/router';
import {ConstraintState, UvrExt} from '../../../shared/model/utm/UvrExt';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {combineLatest, concat, EMPTY, interval, Observable, ReplaySubject, Subscription} from 'rxjs';
import {AdditionalConstraintData} from '../../../shared/model/utm/AdditionalConstraintData';
import {of} from 'rxjs/internal/observable/of';
import {ConstraintTypeService} from '../../../shared/services/constraint-type.service';
import {ManagedSearchComponent} from '../../../shared/utils/ManagedSearchComponent';
import {Parser} from '../../../shared/model/utm/parser/OperationParser';
import {catchError, debounceTime, exhaustMap, map, skip, switchMap, take} from 'rxjs/operators';
import {UserSettingsService} from '../../../shared/services/user-settings.service';
import {isInteger, isNil} from "lodash";
import {ResponsiveScreenService} from '../../../shared/services/responsive-screen.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

interface FilterValues {
  states: ConstraintState[];
}

@Component({
  selector: 'app-constraints',
  templateUrl: './constraints.component.html',
  styleUrls: ['./constraints.component.scss']
})
export class ConstraintsComponent extends ManagedSearchComponent<ConstraintSearchParameters> implements OnInit,
  AfterViewInit, OnDestroy {
  // @ViewChild('searchConfigComponent') constraintSearchOptionsConfigComponent: ConstraintSearchOptionsConfigComponent;
  @ViewChildren(ClrDatagridColumn) columns: QueryList<ClrDatagridColumn>;
  @ViewChildren(ClrDatagrid) dg: QueryList<ClrDatagrid>;
  @ViewChild(ClrDatagrid, {read: ElementRef}) dgElement: ElementRef;
  totalItems: number;
  currentPageSize = 10;
  currentPage = 1;
  pageSizeOptions = [5,10,20,50,100];
  loading = true;
  constraints: UvrExt[];
  searchConfig: ConstraintSearchParameters;
  constraintToClose: UvrExt;
  waitingForConstraintToClose: boolean;
  constraintCloseSubmitted: boolean;
  filterFG: UntypedFormGroup = new UntypedFormGroup({
    states: new UntypedFormControl([ConstraintState.ACCEPTED, ConstraintState.ACTIVE])
  });
  sortDirections: { [key: string]: ClrDatagridSortOrder } = {};

  deviceSize$ = inject(ResponsiveScreenService).deviceSize$;

  private state: ClrDatagridStateInterface<any>;
  private stateSubject: ReplaySubject<ClrDatagridStateInterface> = new ReplaySubject<ClrDatagridStateInterface>(1);
  private refreshReady = false;
  private closeConstraintSub: Subscription;

  constructor(private constraintService: ConstraintService,
              private constraintTypeService: ConstraintTypeService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private userSettingsService: UserSettingsService,
              private cdrf: ChangeDetectorRef
  ) {
    super(ConstraintSearchParameters.defaultSearch(), router, activatedRoute);

    // Set initial column values & enable refresh
    this.watchSearchConfig()
      .pipe(take(1), takeUntilDestroyed())
      .subscribe(config => {
        this.applyColumnChanges(config, true);
        this.refreshReady = true;
        this.refresh();
        this.cdrf.detectChanges();
      });

    // Update column values & refresh datagrid
    this.watchSearchConfig()
      .pipe(skip(1), takeUntilDestroyed())
      .pipe(switchMap(config => {
        this.loading = true;
        this.applyColumnChanges(config, false);
        config.fetchCount = true;
        return this.refreshConstraints(config, true);
      }))
      .subscribe(([results, config]) => {
        this.loading = false;
        this.totalItems = results.total;
        this.constraints = results.results;
      }, () => this.loading = false);

    // Set search config/URL parameters
    combineLatest([this.stateSubject, concat(of(this.filterFG.value), this.filterFG.valueChanges)])
      .pipe(debounceTime(500), takeUntilDestroyed())
      .subscribe(([statey, filterValues]) => {
        const newConstraintParams: ConstraintSearchParameters = this.getConstraintSearchParameters(statey, filterValues);
        this.setSearchConfig(newConstraintParams, true);
      });

    // Refresh constraints every 5 seconds
    interval(5000).pipe(takeUntilDestroyed(), exhaustMap(() => {
      return this.refreshConstraints(this.searchConfig).pipe(catchError(() => (EMPTY)));
    })).subscribe(([results, config]) => {
      this.constraints = results.results;
    });
  }

  ngAfterViewInit(): void {
    // Sorting by state must be disabled due to the inability of the constraint search endpoint to properly sort this field.
    // Warning: This is hacky; however, Clarity currently doesn't support disabling datagrid sorting without also
    // disabling filtering, so there isn't a proper means of doing this.
    this.dgElement.nativeElement.querySelector('.datagrid-header .col-state button.datagrid-column-title')?.setAttribute('disabled', '');
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refresh();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.closeConstraintSub?.unsubscribe();
  }

  refresh(statey?: ClrDatagridStateInterface) {
    if (!statey) {
      statey = this.state || {};
    } else {
      this.state = statey;
    }
    if (!this.refreshReady) {
      return;
    }
    this.stateSubject.next(statey);
  }

  cloneConstraint(constraint: UvrExt) {
    this.router.navigate(['fuss', 'constraint', 'new-constraint'], {queryParams: {constraintId: constraint.message_id}});
  }

  viewConstraint(constraint: UvrExt) {
    this.router.navigate(['fuss', 'constraint', 'view-constraint'], {queryParams: {constraintId: constraint.message_id}});
  }

  closeConstraint(constraint: UvrExt) {
    this.constraintToClose = constraint;
    this.waitingForConstraintToClose = false;
  }

  confirmConstraintClose() {
    this.waitingForConstraintToClose = false;
    this.closeConstraintSub = this.constraintService.closeConstraint(this.constraintToClose).subscribe(() => {
      this.constraintToClose = null;
      this.constraintCloseSubmitted = true;
    });
  }

  getEndTime(constraint: UvrExt): DateTime {
    if (constraint.actual_time_end) {
      return constraint.actual_time_end;
    } else {
      return constraint.effective_time_end;
    }
  }

  getPermittedOperations(additionalConstraintData: AdditionalConstraintData): Observable<string> {
    if (
      additionalConstraintData && additionalConstraintData.constraint_type) {
      return this.constraintTypeService.getPrettyName(additionalConstraintData.constraint_type);
    } else {
      return of('None');
    }
  }

  truncateWithEllip(str: string, maxLength: number = 120, useWordBoundary: boolean = true): string {
    if (!str) {
      return '';
    }
    if (str.length <= maxLength) {
      return str;
    }
    const subString = str.substring(0, maxLength);
    const isWordBoundary = str[maxLength] === ' ';
    if (useWordBoundary && !isWordBoundary) {
      return subString.substring(0, subString.lastIndexOf(' ') >= 0 ? subString.lastIndexOf(' ') : subString.length) + '...';
    } else {
      return subString + '...';
    }
  }

  formatDateTime(dateTime: DateTime): string {
    return dateTime.toLocal().toFormat('LL/dd/yyyy HH:mm');
  }

  refreshConfig(forceRefresh: boolean = false) {
    // if (this.constraintSearchOptionsConfigComponent) {
    //   this.setSearchConfig(this.constraintSearchOptionsConfigComponent.getConfig(), forceRefresh);
    // } else {
    this.refresh();
    // }
  }

  updateSearchConfig($event: ConstraintSearchParameters) {
    this.searchConfig = $event;
    this.totalItems = null;
    this.refresh();
  }

  getSearchConfigFromParamMap(params: ParamMap): ConstraintSearchParameters {
    let paramMap = params;
    if (!paramMap) {
      paramMap = this.activatedRoute.snapshot.queryParamMap;
    }
    const ret = ConstraintSearchParameters.defaultSearch();

    if (paramMap.has('startTime')) {
      if (!ret.volume4d) {
        ret.volume4d = {};
      }
      if (!ret.volume4d.timeWindow) {
        ret.volume4d.timeWindow = {};
      }
      ret.volume4d.timeWindow.timeBeginAfter = DateTime.fromISO(paramMap.get('startTime'));
    }
    if (paramMap.has('state')) {
      const parsedState = (paramMap.getAll('state') || [])
        .map(s => Parser.parseConstraintState(s as ConstraintState))
        .filter(s2 => !!s2);
      if (parsedState.length) {
        ret.state = parsedState;
      }
    }
    if (paramMap.has('reason')) {
      ret.reason = paramMap.get('reason');
    }
    if (paramMap.has('constraintType')) {
      ret.constraint_type = paramMap.get('constraintType');
    }
    if (paramMap.has('ussName')) {
      ret.uss_name = paramMap.get('ussName');
    }
    if (paramMap.has('sort')) {
      const sort = (paramMap.getAll('sort') || [])
        .map(ConstraintSearchSortFieldUtil.fromString)
        .filter(f => f !== ConstraintSearchSortField.unknown);
      if (sort.length) {
        ret.sort = sort;
      }
    }
    if (paramMap.has('sortIncreasing')) {
      try {
        const sortIncreasing = JSON.parse(paramMap.get('sortIncreasing'));
        if (typeof sortIncreasing !== 'boolean') {
          throw new Error();
        }
        ret.sortIncreasing = sortIncreasing;
      } catch {
        console.error('Invalid sortIncreasing parameter value: ' + paramMap.get('sortIncreasing'));
      }
    }
    if (paramMap.has('limit')) {
      const limit = Number(paramMap.get('limit'));
      if (!isInteger(limit) || !this.pageSizeOptions.includes(limit)) {
        console.error(`Invalid value for limit URL parameter: ${paramMap.get('limit')} \n Valid options are ${this.pageSizeOptions.join(', ')}`);
      } else {
        ret.limit = limit;
      }
    }
    if (paramMap.has('offset')) {
      const offset = Number(paramMap.get('offset'));
      if (!isInteger(offset) || offset < 0) {
        console.error('Invalid value for offset URL parameter: ' + paramMap.get('offset'));
      } else if((offset % ret.limit) !== 0) {
        console.error('offset URL parameter must be divisible by limit parameter');
      } else {
        ret.offset = offset;
      }
    }

    return ret;
  }

  serializeSearchConfigToParams(searchConfig: ConstraintSearchParameters): Params {
    return {
      startTime: searchConfig?.volume4d?.timeWindow?.timeBeginAfter?.toUTC()?.toISO(),
      state: searchConfig.state,
      reason: searchConfig.reason,
      constraintType: searchConfig.constraint_type,
      ussName: searchConfig.uss_name,
      sort: searchConfig.sort,
      sortIncreasing: JSON.stringify(searchConfig.sortIncreasing),
      limit: searchConfig.limit,
      offset: searchConfig.offset
    };
  }

  resetFilters() {
    this.applyColumnChanges(ConstraintSearchParameters.defaultSearch(), true);
  }

  private refreshConstraints(searchConfig: IConstraintSearchParameters, fetchCount: boolean = true):
    Observable<[SearchResult<UvrExt>, IConstraintSearchParameters]> {

    return this.constraintService.getConstraints(searchConfig).pipe(map(results => [results, searchConfig]));
  }

  private getConstraintSearchParameters(statey: ClrDatagridStateInterface, filters: FilterValues): ConstraintSearchParameters {
    // Parse filters from array of objects -> single object with key/value pairs
    const dgFilters: { [prop: string]: string } = {};
    statey.filters?.forEach(filter => dgFilters[this.extractFieldNameFromDotNotation(filter.property)] = filter.value);

    // Parse sort field
    let sort: ConstraintSearchSortField[];
    if (statey.sort?.by) {
      sort = [ConstraintSearchSortFieldUtil.fromString(this.extractFieldNameFromDotNotation(statey.sort.by.toString()))];
      if (sort && sort[0] === ConstraintSearchSortField.unknown) {
        sort = undefined;
      }
    }

    return new ConstraintSearchParameters({
      state: filters.states,
      reason: dgFilters?.reason,
      /* eslint-disable @typescript-eslint/naming-convention */
      constraint_type: dgFilters.constraint_type,
      uss_name: dgFilters?.uss_name,
      sort,
      sortIncreasing: !isNil(statey.sort?.reverse) ? !statey.sort?.reverse : undefined,
      limit: statey.page?.size,
      offset: statey.page?.from === -1 ? 0 : statey.page?.from
      /* eslint-enable @typescript-eslint/naming-convention */
    });
  }

  private applyColumnChanges(config: IConstraintSearchParameters, emitChanges: boolean) {
    if (this.columns) {
      // Set column filter values
      this.columns.forEach(col => {
        const columnFieldName = this.extractFieldNameFromDotNotation(col.field);
        if (columnFieldName in config) {
          col.filterValue = config[columnFieldName];
          col.filterValueChange.emit(config[columnFieldName]);
        }
      });

      // Set column sort directions
      if (config.sort?.length) {
        const columnSortFields = this.columns.map(col => col.field).filter(col => !!col);
        columnSortFields.push('effective_time_begin', 'effective_time_end');

        columnSortFields.forEach(field => {
          if (config.sort[0] === this.extractFieldNameFromDotNotation(field)) {
            this.sortDirections[field] = config.sortIncreasing ? ClrDatagridSortOrder.ASC : ClrDatagridSortOrder.DESC;
          } else {
            this.sortDirections[field] = ClrDatagridSortOrder.UNSORTED;
          }
        });
      }
    }

    if (config.state?.length) {
      this.filterFG.controls.states.setValue(config.state, {emitEvent: false, onlySelf: true});
    }

    this.currentPageSize = config.limit;
    this.currentPage = Math.floor((config.offset / config.limit)) + 1;

    if (this.dg && emitChanges) {
      this.dg.notifyOnChanges();
      this.filterFG.updateValueAndValidity();
    }
  }

  private extractFieldNameFromDotNotation(field: string): string {
    return field?.includes('.') ? field.split('.').pop() : field;
  }
}

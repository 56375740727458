import {Component} from '@angular/core';
import {SubmissionMode} from '../../../shared/model/SubmissionStatus';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html'
})
export class NewUserComponent {
  submissionMode = SubmissionMode;

 constructor() {
   // Method not implemented
  }

}

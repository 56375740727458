import {DateTime} from 'luxon';
import {Polygon} from 'geojson';

export interface TimeWindowQuery {
  timeBeginAfter?: DateTime;
  timeBeginBefore?: DateTime;
  timeEndAfter?: DateTime;
  timeEndBefore?: DateTime;
}

export interface RadialQuery {
  latitude: number;
  longitude: number;
  radius: number;
}

export interface EnvelopeQuery {
  north: number;
  west: number;
  south: number;
  east: number;
}

export interface Volume3dQuery {
  maxAltitude?: number;
  minAltitude?: number;
  radial?: RadialQuery;
  envelope?: EnvelopeQuery;
  polygonRing?: number[][];
  polygonGeoJson?: Polygon;
}

export interface Volume4dQuery {
  timeWindow?: TimeWindowQuery;
  spatial?: Volume3dQuery;
}

export class Volume4dQueryUtil{
  static serializeVolume4DQuery(volume: Volume4dQuery) {
    return volume ? {
      spatial: volume.spatial,
      timeWindow: volume.timeWindow ? {
        timeBeginAfter: volume.timeWindow?.timeBeginAfter?.toUTC()?.toISO(),
        timeBeginBefore: volume.timeWindow?.timeBeginBefore?.toUTC()?.toISO(),
        timeEndAfter: volume.timeWindow?.timeEndAfter?.toUTC()?.toISO(),
        timeEndBefore: volume.timeWindow?.timeEndBefore?.toUTC()?.toISO(),
      } : undefined,
    } : undefined;
  }
}

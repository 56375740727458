import {Component, forwardRef} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'font-size-button',
  templateUrl: './font-size-button.component.html',
  styleUrls: ['./font-size-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FontSizeButtonComponent),
    }
  ]
})
export class FontSizeButtonComponent implements ControlValueAccessor {
  state = 1.0;
  private onchange: (num) => void;

  constructor() {
    // Method not implemented
  }

  registerOnChange(fn: (num) => void): void {
    this.onchange = fn;
  }

  registerOnTouched(fn: any): void {
    // Method not implemented
  }

  writeValue(obj: number): void {
    this.state = obj;
  }

  setState(newState: number) {
    this.state = newState;
    if (this.onchange){
      this.onchange(newState);
    }
  }
}

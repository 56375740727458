/**
 * This file is generated by the SwaggerTSGenerator.
 * Do not edit.
 */
/* eslint-disable */
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {enumValidator} from './validators';
import {BaseModel} from './base-model';

import {test_type} from './enums';

export interface IPerformanceAuthorizationSchema {
  event_id: string;
  test_identifiers?: Array<string>;
  test_type?: test_type;
  free_text?: string;
  data_quality_engineer: string;
  modified?: boolean;
}


export class PerformanceAuthorizationSchema extends BaseModel implements IPerformanceAuthorizationSchema {

  static EVENT_ID_FIELD_NAME = 'event_id';
  static TEST_IDENTIFIERS_FIELD_NAME = 'test_identifiers';
  static TEST_TYPE_FIELD_NAME = 'test_type';
  static FREE_TEXT_FIELD_NAME = 'free_text';
  static DATA_QUALITY_ENGINEER_FIELD_NAME = 'data_quality_engineer';
  static MODIFIED_FIELD_NAME = 'modified';

  /** A string tag indicating which event this operation is associated. Will be used to select a set of rules for validation. */
  event_id: string;
  /** Specific tests ids supported by these data. These ids should map to specific tests as outlined in the overall event description. As an example, Event "TCL3_Demonstration" at a test site may have a test_card called "CARD_44". The operation associated with this metadata block may support "DAT2", "CNS1", and "CON4" specific tests. If any one of those elements are missing (event_id, test_card, test_identifiers), then data analysis may become difficult if not impossible. */
  test_identifiers: Array<string>;
  /** Test type. */
  test_type: test_type;
  /** Free text may be included with the original submission and/or added by a data_quality_engineer.  In the latter case, all previous free_text should be preserved, i.e. the data_quality_engineer should only append to the free_text field. */
  free_text: string;
  /** Email address of author handling data quality issue. If multiple individuals modify this metadata, this field will only hold the last such individual.  In that case, the engineer should make an effort to document as much as possible in the free_text field. */
  data_quality_engineer: string;
  /** This metadata was modified from its original submission by a data_quality_engineer. */
  modified: boolean;

  /**
   * constructor
   * @param values Can be used to set a webapi response or formValues to this newly constructed model
   * @useFormGroupValuesToModel if true use formValues
   */
  constructor(values?: Partial<IPerformanceAuthorizationSchema>, useFormGroupValuesToModel = false) {
    super();
    this.test_identifiers = [];

    if (values) {
      this.setValues(values, useFormGroupValuesToModel);
    }
  }

  /**
   * set the values.
   * @param values Can be used to set a webapi response to this newly constructed model
   */
  setValues(values: Partial<IPerformanceAuthorizationSchema>, useFormGroupValuesToModel = false): void {
    if (values) {
      const rawValues = this.getValuesToUse(values, useFormGroupValuesToModel);
      this.event_id = this.getValue<string>(rawValues, PerformanceAuthorizationSchema.EVENT_ID_FIELD_NAME);
      this.fillModelArray<string>(this, PerformanceAuthorizationSchema.TEST_IDENTIFIERS_FIELD_NAME, rawValues.test_identifiers, useFormGroupValuesToModel);
      this.test_type = this.getValue<test_type>(rawValues, PerformanceAuthorizationSchema.TEST_TYPE_FIELD_NAME);
      this.free_text = this.getValue<string>(rawValues, PerformanceAuthorizationSchema.FREE_TEXT_FIELD_NAME);
      this.data_quality_engineer = this.getValue<string>(rawValues, PerformanceAuthorizationSchema.DATA_QUALITY_ENGINEER_FIELD_NAME);
      this.modified = this.getValue<boolean>(rawValues, PerformanceAuthorizationSchema.MODIFIED_FIELD_NAME);
      // set values in model properties for added formControls
      super.setValuesInAddedPropertiesOfAttachedFormControls(values, useFormGroupValuesToModel);
    }
  }

  protected getFormGroup(): UntypedFormGroup {
    if (!this._formGroup) {
      this._formGroup = new UntypedFormGroup({
        event_id: new UntypedFormControl(this.event_id, [Validators.required, Validators.minLength(1), Validators.maxLength(100),]),
        test_identifiers: new UntypedFormArray([]),
        test_type: new UntypedFormControl(this.test_type, [enumValidator(test_type),]),
        free_text: new UntypedFormControl(this.free_text, [Validators.maxLength(3000),]),
        data_quality_engineer: new UntypedFormControl(this.data_quality_engineer, [Validators.required, Validators.maxLength(150),]),
        modified: new UntypedFormControl(this.modified),
      });
      // generate FormArray control elements
      this.fillFormArray<string>(PerformanceAuthorizationSchema.TEST_IDENTIFIERS_FIELD_NAME, this.test_identifiers);
    }
    return this._formGroup;
  }

  /**
   * set the FormGroup values to the model values.
   */
  setFormGroupValues() {
    this.$formGroup.controls[PerformanceAuthorizationSchema.EVENT_ID_FIELD_NAME].setValue(this.event_id);
    this.fillFormArray<string>(PerformanceAuthorizationSchema.TEST_IDENTIFIERS_FIELD_NAME, this.test_identifiers);
    this.$formGroup.controls[PerformanceAuthorizationSchema.TEST_TYPE_FIELD_NAME].setValue(this.test_type);
    this.$formGroup.controls[PerformanceAuthorizationSchema.FREE_TEXT_FIELD_NAME].setValue(this.free_text);
    this.$formGroup.controls[PerformanceAuthorizationSchema.DATA_QUALITY_ENGINEER_FIELD_NAME].setValue(this.data_quality_engineer);
    this.$formGroup.controls[PerformanceAuthorizationSchema.MODIFIED_FIELD_NAME].setValue(this.modified);
    // set formValues in added formControls
    super.setFormGroupValuesInAddedFormControls();
  }
}

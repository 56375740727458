<form [formGroup]="fg" class="clr-row">
  <clr-input-container [ngClass]="{'clr-col-auto': !simpleRange, 'clr-col-md-6 clr-col-12': simpleRange}">
    <label class="required-custom">Minimum Altitude</label>
    <input clrInput formControlName="min_altitude" type="number" class="alt-input">
    <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
    <clr-control-error *clrIfError="'min'">Minimum value is {{fg.controls.min_altitude.errors.min.min}}</clr-control-error>
    <clr-control-error *clrIfError="'max'">Maximum value is {{fg.controls.min_altitude.errors.max.max}}</clr-control-error>
  </clr-input-container>
  <clr-input-container [ngClass]="{'clr-col-auto': !simpleRange, 'clr-col-md-6 clr-col-12': simpleRange}">
    <label class="required-custom">Maximum Altitude</label>
    <input clrInput formControlName="max_altitude" type="number" class="alt-input">
    <clr-control-error *clrIfError="'required'">This field is required.</clr-control-error>
    <clr-control-error *clrIfError="'min'">Minimum value is {{fg.controls.max_altitude.errors.min.min}}</clr-control-error>
    <clr-control-error *clrIfError="'max'">Maximum value is {{fg.controls.max_altitude.errors.max.max}}</clr-control-error>
  </clr-input-container>
  <ng-container *ngIf="!simpleRange">
    <clr-select-container class="clr-col-auto">
      <label class="clr-col-md-12">Vertical Reference</label>
      <select  class="clr-col-md-12" clrSelect formControlName="altitude_vertical_reference">
        <option *ngFor="let option of verticalReferenceIter" [value]="option.value">{{option.label}}
        </option>
      </select>
    </clr-select-container>
    <clr-select-container class="clr-col-auto">
      <label class="clr-col-md-12">Units of Measure</label>
      <select class="clr-col-md-12" clrSelect formControlName="altitude_units">
        <option *ngFor="let option of unitsOfMeasureIter" [value]="option.value">{{option.label}}
        </option>
      </select>
    </clr-select-container>
  </ng-container>
  <error-container [control]="fg">
    <error-message [has]="'range'"></error-message>
    <error-message [has]="'minHeight'"></error-message>
  </error-container>
</form>

import {UTMFlightPlan} from "../common";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LatLngPoint} from "../../../../../../model/WaypointParser";
import {
  FlightPlanComponent,
  FlightPlanComponentOptions,
  generateFlightPlanComponentVolumes,
  GeometricStuff
} from "../../../../../../utils/PointBufferer2";

import {AltitudeService} from "../../../../../altitude.service";
import {Convert, Item, QGCSFlightPlan, Type} from "./QGCSParser";
import {Observable, of} from "rxjs";
import {OperationGeometry} from "../../../../../../model/operation.geometry";
import {OperationVolume, units_of_measure, vertical_reference} from "../../../../../../model/gen/utm";

export enum QGCSAltitudeMode {

  AltitudeModeMixed = 0,              // Used by global altitude mode for mission planning
  AltitudeModeRelative = 1,           // MAV_FRAME_GLOBAL_RELATIVE_ALT
  AltitudeModeAbsolute = 2,           // MAV_FRAME_GLOBAL
  AltitudeModeCalcAboveTerrain = 3,   // Absolute altitude above terrain calculated from terrain data
  AltitudeModeTerrainFrame = 4,       // MAV_FRAME_GLOBAL_TERRAIN_ALT
  AltitudeModeNone = 5,               // Being used as distance value unrelated to ground (for example distance to structure)
}


export enum QGCSCommandEnum {
  NAV_WAYPOINT = 16,
  NAV_LOITER_UNLIMITED = 17,
  NAV_LOITER_TURNS = 18,
  NAV_LOITER_TIME = 19,
  NAV_LAND = 21,
  NAV_TAKEOFF = 22,
  NAV_LOITER_TO_ALT = 31,
  DO_FLIGHTTERMINATION = 189,

}

export class QGCSUTMFlightPlan implements UTMFlightPlan {
  readonly options = new FormGroup({
    homePosition: new FormControl<LatLngPoint>(null, [Validators.required]),
    horizontalBufferDistanceMeters: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_HORIZONTAL_BUFFER_DISTANCE_METERS, [
      Validators.required,
      Validators.min(1)
    ]),
    verticalBufferDistanceMeters: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_VERTICAL_BUFFER_DISTANCE_METERS, [
      Validators.required,
      Validators.min(1)
    ]),
    // bufferUnits: new FormControl<units_of_measure>(units_of_measure.M, [Validators.required]),
    steps: new FormControl<number>(GeometricStuff.DEFAULT_OPERATION_VOLUME_STEPS, [
      Validators.required,
      Validators.min(3)
    ])
  });

  constructor(private flightPlan: QGCSFlightPlan, private altitudeService: AltitudeService) {
    const estimatedHome = this.estimateHomePosition();
    this.options.controls.homePosition.setValue(estimatedHome);
  }

  private estimateHomePosition(): LatLngPoint {
    const plannedHomePosition = this.flightPlan.mission.plannedHomePosition;
    if (plannedHomePosition) {
      return {
        lat: plannedHomePosition[0],
        lng: plannedHomePosition[1],
        alt: plannedHomePosition[2] //Meters
      };
    }
    console.error("No planned home position found in QGCS flight plan");
    return null;
  }

  static fromText(text: string, altitudeService: AltitudeService): QGCSUTMFlightPlan {
    let rawFlightPlan = Convert.toQGCSFlightPlan(text)

    return new QGCSUTMFlightPlan(rawFlightPlan, altitudeService);
  }

  private getFlightPlanComponents(): FlightPlanComponent[] {
    const volumes: FlightPlanComponent[] = [];
    const home = this.options.controls.homePosition.value;
    let lastPoint = home;
    let point: LatLngPoint | null = null;

    for (const item of this.flightPlan.mission.items) {
      switch (item.command) {
        case QGCSCommandEnum.NAV_WAYPOINT:
        case QGCSCommandEnum.NAV_LAND:
        case QGCSCommandEnum.NAV_TAKEOFF:
          point = this.getCoordinate(item, home);
          if (!point) {
            break;
          }
          point.lat = point.lat || lastPoint.lat;
          point.lng = point.lng || lastPoint.lng;
          volumes.push({
            kind: 'segment',
            index: item.doJumpID,
            a: lastPoint,
            b: point
          });
          lastPoint = point;
          break;
        case QGCSCommandEnum.NAV_LOITER_TO_ALT:
          point = this.getCoordinate(item, home);
          if (!point) {
            break;
          }
          volumes.push({
            kind: 'segment',
            index: item.doJumpID,
            a: lastPoint,
            b: point
          });
          volumes.push({
            kind: 'loiter',
            index: item.doJumpID,
            center: point,
            radius: item.params[1]
          });
          lastPoint = point;
          break;
        case QGCSCommandEnum.DO_FLIGHTTERMINATION:
          //Ignore? Indicates start of landing sequence
          break;
        default:
          console.error("Unsupported command in QGCS flight plan: " + item.command);
      }
    }
    return volumes;
  }

  toOperationGeometry(): Observable<OperationGeometry> {
    const volumes = this.getFlightPlanComponents();
    const rawOptions = this.options.getRawValue();
    const options: FlightPlanComponentOptions = {
      steps: rawOptions.steps,
      bufferDistance: rawOptions.horizontalBufferDistanceMeters,
      bufferUnits: units_of_measure.M,
      endTime: undefined,
      startTime: undefined,
      verticalBufferMeters: rawOptions.verticalBufferDistanceMeters,
      verticalReference: vertical_reference.AGL,
      verticalUnits: units_of_measure.M
    };

    let results: OperationVolume[] = [];
    for (const segment of volumes) {
      results = results.concat(generateFlightPlanComponentVolumes(segment, options));
    }

    const res = new OperationGeometry(
      results,
      undefined,
      undefined,
      this.options.controls.homePosition.value
    );

    return of(res);
  }

  private getCoordinate(item: Item, home: LatLngPoint): LatLngPoint | null {
    if (item.type !== Type.SimpleItem) {
      console.error("Unsupported item type: " + item.type);
      return null;
    }
    // if(item.frame === 2 && item.command === 189){
    //   return{
    //     lat: home.lat,
    //     lng: home.lng,
    //     alt: item.altitude
    //   };
    // }

    if(item.altitudeMode === QGCSAltitudeMode.AltitudeModeRelative){
      return{
        lat: item.params[4],
        lng: item.params[5],
        alt: home.alt + item.altitude
      };
    }

    console.error("Unsupported altitude mode: " + item.altitudeMode);
    return null;

  }
}

